import React, { useContext, useState } from "react";
import FormGenerator from "antd-form-generator";
import { useApolloClient } from "react-apollo";
import { useHistory } from "react-router-dom";

import { CREATE_PROJECT_MUTATION } from "./queries";
import { Container, TeamDetails } from "./styles";
import { Button } from "../Button";
import { projectSchema } from "../CheckoutForm/checkoutFormSchema";
import { AuthContext } from "../../context/AuthProvider";
import { MessageContext } from "../../context/MessageContext";
import Checkbox from "antd/lib/checkbox";
import AntModal from "../AntModal";

const CreateProjectModal = ({
  modalState,
  onComplete,
  onClose = null,
  redirectOnComplete = false
}) => {
  const { user } = useContext(AuthContext);
  const { showMessage } = useContext(MessageContext);
  const [shareWithTeam, setShareWithTeam] = useState(true);
  const client = useApolloClient();
  const history = useHistory();

  const createProject = async project => {
    showMessage({
      type: "loading",
      duration: 5000,
      title: "Creating Project",
      description: "Your project is being created. Please wait..."
    });
    try {
      const res = await client.mutate({
        mutation: CREATE_PROJECT_MUTATION,
        variables: {
          ...project,
          creator: user.id,
          team: user.team && shareWithTeam ? user.team.id : null
        }
      });
      if (res) {
        showMessage({
          type: "success",
          duration: 5000,
          title: "Project Created",
          description:
            "You can now create project playlists, custom mixes, purchase project licenses and more."
        });
        onComplete(res.data.createProject);
        redirectOnComplete &&
          history.push(`/project/${res.data.createProject.id}`);
        modalState.close();
        setShareWithTeam(true);
      }
    } catch (error) {
      console.log("create project ", error);
    }
  };
  return (
    <AntModal modalState={modalState} onClose={onClose}>
      <Container>
        <h2 className="title h2">Create Project</h2>
        <FormGenerator
          onSubmit={createProject}
          formSchema={projectSchema}
          renderFooter={handleSubmit => {
            return (
              <div style={{ marginTop: user.team ? -12 : 0 }}>
                {user.team && (
                  <TeamDetails>
                    <img
                      src={`${process.env.REACT_APP_FILES_URL}${user.team.image}`}
                      alt={user.team.name}
                      className="cover"
                    />
                    <div style={{ marginLeft: 9 }}>
                      <div className="row">
                        <p className="p4b mr-12">{user.team.name}</p>
                        <p className="p4m grey mr-24">
                          ({user.team.teamMembers.length} member
                          {user.team.teamMembers.length === 1 ? "" : "s"})
                        </p>
                      </div>

                      <Checkbox
                        className="checkbox"
                        checked={shareWithTeam}
                        onChange={e => {
                          setShareWithTeam(e.target.checked);
                        }}
                      >
                        Share this project with your team.
                      </Checkbox>
                    </div>
                  </TeamDetails>
                )}
                <Button onClick={handleSubmit}>Create</Button>
              </div>
            );
          }}
        />
      </Container>
    </AntModal>
  );
};

export default CreateProjectModal;
