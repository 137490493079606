import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_PAN, SetPanAction } from "../../actions/actionTypes";
import { MainStore } from "../../store";
import { theme } from "../../../globalStyles";
import { PanKnobContainer } from "./styles";
import FLStandardKnob from "../Knob/fl-controls/fl-standard-knob";

export interface PanKnobProps {
  laneId: string;
}

export const PanKnob = (props: PanKnobProps) => {
  const panValue = useSelector<MainStore, number>(
    state => state.project.lanes.byId[props.laneId].laneStatus.pan
  );
  const dispatch = useDispatch();

  const [knob, setKnob] = useState(null);

  const panKnobRef = useRef(null);

  useEffect(() => {
    // wait for container dom ele to be set up
    // check knob hasn't already been instantiated (failsafe)
    // ensure redux store has been populated with saved values
    if (panKnobRef.current && knob === null) {
      const knob = new FLStandardKnob(panKnobRef.current, {
        indicatorDot: true,
        min: 0,
        max: 1,
        initial: 0.5,
        indicatorRingType: "split",
        angleRange: 280,
        stickyCriticalPoints: true,
        color: theme.editor.highlight
      });
      knob._input.addEventListener("change", function(e) {
        onChange(e.target.value);
      });
      setKnob(knob);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panKnobRef, knob]);

  // this ensures value reflects redux store
  useEffect(() => {
    if (knob) {
      knob._input.value = panValue;
      knob.updateToInputValue();
    }
  }, [knob, panValue]);

  // val in [0,1]. Centered at 0.5. redux store save
  const onChange = val => {
    dispatch<SetPanAction>({
      panValue: val,
      laneId: props.laneId,
      type: SET_PAN
    });
  };

  return (
    <PanKnobContainer
      ref={panKnobRef}
      className="pan-knob-container"
    ></PanKnobContainer>
  );
};
