// TODO: move to different files
export interface MainStore {
  uiState: UIState;
  project: Project;
  editorState: EditorState;
  waveformBuffers: WaveformBuffers;
}

export enum EditorMode {
  NORMAL,
  SPLIT
}

export enum PlaybackState {
  PAUSED,
  PLAYING
}

export enum InstrumentCategories {
  MELODIC = "Melodic",
  HARMONIC = "Harmonic",
  RHYTHMIC = "Rhythmic",
  BASS = "Bass",
  ATMOSPHERIC = "Atmospheric",
  OTHER = "Other"
}

export interface EditorState {
  mode: EditorMode;
  playbackState: PlaybackState;
  lastPlaySongTime: number;
  lastPlayGlobalTime: number;
  locatorTime: number;
  isRendering: boolean;
  renderProgressPercentage: number;
  nthProjectLoaded: number; // this is so we can cancel project loads
}

export interface VolumeIndicators {
  currentPeak: number;
  recentPeak: number;
}

export enum SnapMode {
  BAR = "BAR",
  BEAT = "BEAT",
  NONE = "NONE"
}

export enum ZoomLevels {
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR"
}
export interface UIState {
  activeFxLane: string;
  projectScrollX: number;
  volumeIndicators: {
    [laneid: string]: VolumeIndicators;
  };
  masterVolumeIndicator: VolumeIndicators;
  masterVolume: number;
  masterMute: boolean;
  metronomeVolume: number;
  metronomeMute: boolean;
  alert: AlertMessage;
  snap: SnapMode;
  zoomLevel: number;
}

// Error information to display to user as notification
export interface AlertMessage {
  id: string;
  role: string; // {error, success, info, warning}
  title: string;
  message: string;
}

export interface WaveformBuffers {
  byAudioPath: { [key: string]: Waveform };
  hdLoaded: Boolean; // null = not loaded, false = standard, true = hd
}

export interface Waveform {
  audioBuffer: AudioBuffer;
  envelopes: Envelopes;
}

export interface Envelopes {
  channelAmplitudeSamples: number[][]; //[channel][sample]
  windowSize: number;
  hopSize: number;
  sampleRate: number;
}

export interface InitialLanesData {
  id: string;
  gainCompensation: number;
  name: string;
  instrument: Instrument;
  audioPath: string;
  audioPathHQ?: string;
}

export interface InitialBlockFiles {
  name: string;
  audioPath: string;
  audioPathHQ?: string;
  length: number;
  id: string;
  instrument: Instrument;
}

export interface InitialTrackData {
  title: string;
  variationTitle: String;
  id: string;
  gain: number;
  bpm: number;
  beatsPerMeasure?: number;
  signature?: number; // Track::signature is field for beatsPerMeasure
  initialBeat: number;
  key: string;
  duration: number;
  createdAt: Date;
  updatedAt: Date;
  lanes: InitialLanesData[];
  blockFiles: InitialBlockFiles[];
  tags: Tag[];
  systemTags: String[];
  instruments: Instrument[];
  thematicCategories: ThematicCategory[];
  ensemble: number;
  mood: number;
  gravity: number;
  energy: number;
  melody: number;
  tension: number;
  rhythm: number;
  density: number;
  editorOpenable: boolean;
  editorDemo: boolean;
  freeStemFiles: boolean;
  albumName: string;
  albumId: string;
  editorDataDump: SaveSerialization;
  thumbnail: string;
}

export interface Tag {
  id: string;
  name: string;
}

export interface Instrument {
  id: string;
  name: string;
  category: InstrumentCategories;
}

export interface ThematicCategory {
  id: string;
  name: string;
}

export interface ProjectLanes {
  lanes: InitialLanesData[];
}

export interface Project {
  lanes: Lanes;
  regions: Regions;
  customMixInfo: CustomMixInfo;
  initialTrackData: InitialTrackData;
}

export interface SaveSerialization {
  lanes: Lanes;
  regions: Regions;
}

export interface CustomMixVersion {
  id: string;
  versionNumber: number;
  editorDataDumpHash: string;
  createdAt: Date;
  updatedAt: Date;
}

// User record with no private data attached
export interface PublicUser {
  id: string;
  fullName: string;
  profileColor: string;
  profileIcon: string;
}
export interface CustomMixInfo {
  id: string;
  projectId: string;
  trackId: string;
  title: string;
  length: number;
  numVersions: number;
  createdAt: Date;
  updatedAt: Date;
  user: PublicUser;
  version: CustomMixVersion;
  currentDataHash: string;
}

export interface ByIdLane {
  [key: string]: Lane;
}

export interface Lanes {
  byId: ByIdLane;
  laneIds: string[];
  solodLaneIds: string[]; // keep lane for performance reasons
  muteLaneIds: string[]; // keep lane for performance reasons
  soloRecall: string[];
  muteRecall: string[];
}

export interface Regions {
  byId: {
    [key: string]: Region;
  };
}

export interface Region {
  id: string;
  visualStartTime: number; // second the region audio starts in the current mix
  visualDuration: number; // how long the region is. This changes based on user input
  startFadeTime: number;
  endFadeTime: number;
  startFadeTimeOverlap: boolean;
  endFadeTimeOverlap: boolean;
  _forceRerender: number; // number we can increment to force rererender
  audioPath: string;
  audioPathHQ: string;
  audioStartTime: number; // Second the region original audio starts. Moving the region left to right, this won't change.
  audioSrcDuration: number; // This doesn't change - based on audio path's original duration.
  audioSrcColors: string[];
  audioSrcSeed: number;
}

export enum MuteSoloState {
  DEFAULT,
  MUTE,
  SOLO
}

// Data structure for the internal effect chain of an insert or send effect
// E.g., Overdrive is: Overdrive -> LP Filter -> Gain -> Send Reverb -> out
// effectId: prefix tuna.<tunaEffect> instantiates a tuna effect node
export interface InternalFX {
  effectId: string;
  params: any;
  mapping?: any;
  tempoLock?: any;
}

// Data structure for an insert or send effect
// Insert or send effects selected by the user have an underlying daisy chain of fx
export interface FX {
  id: string;
  category: string;
  name: string;
  isSend: boolean;
  internalFXChain: InternalFX[];
  val: number;
}

export interface LaneParameters {
  muteSoloState: MuteSoloState;
  volume: number;
  pan: number;
  gainCompensation: number;
  fxSlot: FX[];
}

export interface Lane {
  id: string;
  originalId: string;
  name: string;
  instrument: Instrument;
  color: string;
  regionIds: string[];
  laneLoadingStatus: LaneLoadingStatus;
  laneStatus: LaneParameters;
}

export interface LaneLoadingStatus {
  isLoading: boolean;
  loadingProgressPercentage: number;
}

export interface ProjectCustomMix {
  createdAt: string;
  editorDataDump: Project;
  id: string;
  project: {
    name: string;
  };
  projectId: string;
  title: string;
  track: {
    title: string;
    name: string;
  };
  trackId: string;
  updatedAt: string;
  userId: string;
  user: PublicUser;
}
