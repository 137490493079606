import React, { useState } from "react";
import { Container } from "./styles";
import { Slider } from "antd";
import { timeFormatterNum } from "../../utils/timeFormatter";

const formatter = value => `${value}`;

const LongSlider = ({ rangeValue, onChange, min, max, length = false }) => {
  const [value, setValue] = useState(rangeValue);
  const handleChange = value => setValue(value);

  return (
    <Container>
      <Slider
        step={length ? 15 : 1}
        range
        min={min}
        max={max}
        onChange={handleChange}
        onAfterChange={onChange}
        value={value}
        tipFormatter={length ? timeFormatterNum : formatter}
      />
      <div className="label-container">
        <p>{min}</p> <p>{length ? `6 min+` : max}</p>{" "}
      </div>
    </Container>
  );
};

export default LongSlider;
