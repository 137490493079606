import React from "react";
import { Container } from "./styles";

const Spinner = props => {
  return (
    <Container height={props.height}>
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    </Container>
  );
};

export default Spinner;
