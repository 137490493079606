import { FX } from "../../store";

/*
 * Declaration of all send effect parameters and controls. Any of these can be connected to
 * by specifying send.id in the insertFX:internalFXChain
 */
export const sendFX: FX[] = [
  // Reverbs
  {
    id: "Reverb_Hall",
    category: "Reverb",
    name: "Hall",
    isSend: true,
    internalFXChain: [
      {
        effectId: "tuna.Convolver",
        params: {
          impulse: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Hall.wav`,
          dryLevel: 0,
          wetLevel: 1
        }
      }
    ],
    val: 1
  },
  {
    id: "Reverb_Church",
    category: "Reverb",
    name: "Church",
    isSend: true,
    internalFXChain: [
      {
        effectId: "tuna.Convolver",
        params: {
          impulse: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Church.wav`,
          dryLevel: 0,
          wetLevel: 1
        }
      }
    ],
    val: 1
  },
  {
    id: "Reverb_Room",
    category: "Reverb",
    name: "Room",
    isSend: true,
    internalFXChain: [
      {
        effectId: "tuna.Convolver",
        params: {
          impulse: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Room.wav`,
          dryLevel: 0,
          wetLevel: 1
        }
      }
    ],
    val: 1
  },
  {
    id: "Reverb_Spring",
    category: "Reverb",
    name: "Spring",
    isSend: true,
    internalFXChain: [
      {
        effectId: "tuna.Convolver",
        params: {
          impulse: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Spring.wav`,
          dryLevel: 0,
          wetLevel: 1
        }
      }
    ],
    val: 1
  },
  {
    id: "Reverb_Cave",
    category: "Reverb",
    name: "Cave",
    isSend: true,
    internalFXChain: [
      {
        effectId: "tuna.Convolver",
        params: {
          impulse: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Cave.wav`,
          dryLevel: 0,
          wetLevel: 1
        }
      }
    ],
    val: 1
  },
  {
    id: "Delay_Texture",
    category: "Delay",
    name: "Texture",
    isSend: true,
    internalFXChain: [
      {
        effectId: "tuna.Convolver",
        params: {
          impulse: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Delay_Texture.wav`,
          dryLevel: 0,
          wetLevel: 1
        }
      }
    ],
    val: 1
  },
  {
    id: "Special_Clouds",
    category: "Special",
    name: "Clouds",
    isSend: true,
    internalFXChain: [
      {
        effectId: "tuna.Convolver",
        params: {
          impulse: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Clouds.wav`,
          dryLevel: 0,
          wetLevel: 1
        }
      }
    ],
    val: 1
  },
  {
    id: "Special_Sewer",
    category: "Special",
    name: "Sewer",
    isSend: true,
    internalFXChain: [
      {
        effectId: "tuna.Convolver",
        params: {
          impulse: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Sewer.wav`,
          dryLevel: 0,
          wetLevel: 1
        }
      }
    ],
    val: 1
  },
  {
    id: "Special_Rain",
    category: "Special",
    name: "Rain",
    isSend: true,
    internalFXChain: [
      {
        effectId: "tuna.Filter",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L967
          frequency: 2000,
          Q: 1,
          gain: 0,
          filterType: "highpass"
        }
      },
      {
        effectId: "tuna.Convolver",
        params: {
          impulse: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Rain.wav`,
          dryLevel: 0,
          wetLevel: 1
        }
      }
    ],
    val: 1
  },
  {
    id: "Special_Glitch",
    category: "Special",
    name: "Rain",
    isSend: true,
    internalFXChain: [
      {
        effectId: "tuna.Convolver",
        params: {
          impulse: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Glitch.wav`,
          dryLevel: 0,
          wetLevel: 1
        }
      }
    ],
    val: 1
  },
  {
    id: "Special_ChoralOo",
    category: "Special",
    name: "Choral Oo",
    isSend: true,
    internalFXChain: [
      {
        effectId: "tuna.Convolver",
        params: {
          impulse: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Choral.wav`,
          dryLevel: 0,
          wetLevel: 1
        }
      }
    ],
    val: 1
  },
  {
    id: "Special_ChoralAh",
    category: "Special",
    name: "Choral Ah",
    isSend: true,
    internalFXChain: [
      {
        effectId: "tuna.Convolver",
        params: {
          impulse: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Choral2.wav`,
          dryLevel: 0,
          wetLevel: 1
        }
      }
    ],
    val: 1
  },
  {
    id: "Special_Machine",
    category: "Special",
    name: "Machine",
    isSend: true,
    internalFXChain: [
      {
        effectId: "tuna.Convolver",
        params: {
          impulse: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Machine.wav`,
          dryLevel: 0,
          wetLevel: 1
        }
      }
    ],
    val: 1
  }
];

/*
 * Below are all of the fx options that can be selected by a user in the lane fx slots
 * Some of these insert fx might involve a send to an aux (send) fx as well as a connection
 * through to the next insert fx in the chain.
 */
export const insertFX: FX[] = [
  // Reverbs
  {
    id: "Reverb_Hall",
    category: "Reverb",
    name: "Hall",
    isSend: true,
    internalFXChain: [
      {
        effectId: "send.Reverb_Hall",
        params: {
          send: 1,
          dry: 1
        },
        mapping: {
          send: {
            stopsY: [0.1, 1, 1],
            stopsX: [0, 0.75, 1],
            stopsInterp: ["linear", "linear"]
          },
          dry: {
            stopsY: [1, 1, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Reverb_Church",
    category: "Reverb",
    name: "Church",
    isSend: true,
    internalFXChain: [
      {
        effectId: "send.Reverb_Church",
        params: {
          send: 1,
          dry: 1
        },
        mapping: {
          send: {
            stopsY: [0.1, 1, 1],
            stopsX: [0, 0.75, 1],
            stopsInterp: ["linear", "linear"]
          },
          dry: {
            stopsY: [1, 1, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Reverb_Room",
    category: "Reverb",
    name: "Room",
    isSend: true,
    internalFXChain: [
      {
        effectId: "send.Reverb_Room",
        params: {
          send: 1,
          dry: 1
        },
        mapping: {
          send: {
            stopsY: [0.1, 1, 1],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          },
          dry: {
            stopsY: [1, 1, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Reverb_Spring",
    category: "Reverb",
    name: "Spring",
    isSend: true,
    internalFXChain: [
      {
        effectId: "send.Reverb_Spring",
        params: {
          send: 1,
          dry: 1
        },
        mapping: {
          send: {
            stopsY: [0, 1, 1],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "exp"]
          },
          dry: {
            stopsY: [1, 1, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Reverb_Cave",
    category: "Reverb",
    name: "Cave",
    isSend: true,
    internalFXChain: [
      {
        effectId: "send.Reverb_Cave",
        params: {
          send: 1,
          dry: 1
        },
        mapping: {
          send: {
            stopsY: [0, 1, 1],
            stopsX: [0, 0.65, 1],
            stopsInterp: ["linear", "linear"]
          },
          dry: {
            stopsY: [1, 1, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  // Delays
  // tempoLock params are in fractions of beats
  // i.e., 1 = quarter, 0.75 = dotted eighth, 0.5 = eighth, 0.25 = sixteenth
  {
    id: "Delay_Simple",
    category: "Delay",
    name: "Simple",
    isSend: false,
    internalFXChain: [
      {
        effectId: "tuna.Delay",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L852
          feedback: 0.2,
          cutoff: 2000,
          wetLevel: 0.2,
          dryLevel: 1
        },
        mapping: {
          feedback: {
            stopsY: [0, 0.2, 0.7],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          },
          wetLevel: {
            stopsY: [0, 1, 1],
            stopsX: [0, 0.99, 1],
            stopsInterp: ["linear", "linear"]
          },
          dryLevel: {
            stopsY: [1.0, 1.0, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        },
        tempoLock: {
          delayTime: 1
        }
      }
    ],
    val: 0
  },
  {
    id: "Delay_Ping-Pong",
    category: "Delay",
    name: "Ping-Pong",
    isSend: false,
    internalFXChain: [
      {
        effectId: "tuna.PingPongDelay",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L1620
          feedback: 0.2,
          cutoff: 2000,
          wetLevel: 0.2,
          dryLevel: 1
        },
        mapping: {
          feedback: {
            stopsY: [0.2, 0.7],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          },
          wetLevel: {
            stopsY: [0.2, 1.0],
            stopsX: [0, 1],
            stopsInterp: ["log"]
          },
          dryLevel: {
            stopsY: [1.0, 1.0, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        },
        tempoLock: {
          delayTimeLeft: 1,
          delayTimeRight: 0.75
        }
      }
    ],
    val: 0
  },
  {
    id: "Delay_Stereo",
    category: "Delay",
    name: "Stereo",
    isSend: false,
    internalFXChain: [
      {
        effectId: "bds.StereoDelay",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L852
          feedbackLeft: 0.08,
          cutoffLeft: 1500,
          wetLevelLeft: 0.04,
          dryLevelLeft: 1,
          feedbackRight: 0.3,
          cutoffRight: 2000,
          wetLevelRight: 0.04,
          dryLevelRight: 1,
          crossmix: 0.2
        },
        mapping: {
          feedbackLeft: {
            stopsY: [0.08, 0.4],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          },
          wetLevelLeft: {
            stopsY: [0, 0.1, 1.2],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          },
          dryLevelLeft: {
            stopsY: [1.0, 1.0, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          },
          feedbackRight: {
            stopsY: [0.08, 0.4],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          },
          wetLevelRight: {
            stopsY: [0, 0.1, 1.2],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          },
          dryLevelRight: {
            stopsY: [1.0, 1.0, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        },
        tempoLock: {
          delayTimeLeft: 2,
          delayTimeRight: 3
        }
      }
    ],
    val: 0
  },
  {
    id: "Delay_Slapback",
    category: "Delay",
    name: "Slapback",
    isSend: false,
    internalFXChain: [
      {
        effectId: "tuna.PingPongDelay",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L1620
          feedback: 0.2,
          cutoff: 1000,
          wetLevel: 0.2,
          dryLevel: 1
        },
        mapping: {
          feedback: {
            stopsY: [0.2, 0.7],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          },
          wetLevel: {
            stopsY: [0.2, 1.0],
            stopsX: [0, 1],
            stopsInterp: ["log"]
          },
          dryLevel: {
            stopsY: [1.0, 1.0, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        },
        tempoLock: {
          delayTimeLeft: 0.14,
          delayTimeRight: 0.1
        }
      }
    ],
    val: 0
  },
  {
    id: "Delay_Texture",
    category: "Delay",
    name: "Texture",
    isSend: true,
    internalFXChain: [
      {
        effectId: "send.Delay_Texture",
        params: {
          send: 1,
          dry: 1
        },
        mapping: {
          send: {
            stopsY: [0.1, 1, 1],
            stopsX: [0, 0.75, 1],
            stopsInterp: ["linear", "linear"]
          },
          dry: {
            stopsY: [1, 1, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  // EQ
  {
    id: "EQ_Low-Pass",
    category: "EQ",
    name: "Low-Pass",
    isSend: false,
    internalFXChain: [
      {
        effectId: "tuna.Filter",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L967
          frequency: 4000,
          Q: 1,
          gain: 0,
          filterType: "lowpass"
        },
        mapping: {
          frequency: {
            stopsY: [4500, 45],
            stopsX: [0, 1],
            stopsInterp: ["exp"]
          }
        }
      },
      {
        effectId: "tuna.Gain",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L1062
          gain: 1
        },
        mapping: {
          gain: {
            stopsY: [1, 1, 1.25],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "EQ_High-Pass",
    category: "EQ",
    name: "High-Pass",
    isSend: false,
    internalFXChain: [
      {
        effectId: "tuna.Filter",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L967
          frequency: 200,
          Q: 1,
          gain: 0,
          filterType: "highpass"
        },
        mapping: {
          frequency: {
            stopsY: [20, 6000],
            stopsX: [0, 1],
            stopsInterp: ["exp"]
          }
        }
      },
      {
        effectId: "tuna.Gain",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L1062
          gain: 1
        },
        mapping: {
          gain: {
            stopsY: [1, 1, 1.35],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "EQ_Band-Pass",
    category: "EQ",
    name: "Band-Pass",
    isSend: false,
    internalFXChain: [
      {
        effectId: "tuna.Filter",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L967
          frequency: 200,
          Q: 20,
          gain: 12,
          filterType: "bandpass"
        },
        mapping: {
          frequency: {
            stopsY: [20, 2500],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          }
        }
      },
      {
        effectId: "tuna.Gain",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L1062
          gain: 1
        },
        mapping: {
          gain: {
            stopsY: [1, 1.2, 1.4],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "EQ_Cabinet",
    category: "EQ",
    name: "Cabinet",
    isSend: false,
    internalFXChain: [
      {
        effectId: "tuna.Cabinet",
        params: {
          // https://github.com/Theodeus/tuna/blob/master/tuna.js#L330
          makeupGain: 6,
          impulsePath: `${process.env.PUBLIC_URL}/audio/IR/BDS_FX_Cabinet_Amp.wav`
        }
      },
      {
        effectId: "tuna.Overdrive",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L1213
          outputGain: -9.154,
          drive: 0.6,
          curveAmount: 0.3,
          algorithmIndex: 0
        },
        mapping: {
          curveAmount: {
            stopsY: [0, 0, 0.65],
            stopsX: [0, 0.6, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      },
      {
        effectId: "tuna.Filter",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L967
          frequency: 4000,
          Q: 1,
          gain: 0,
          filterType: "lowpass"
        },
        mapping: {
          frequency: {
            stopsY: [10000, 10000, 650],
            stopsX: [0, 0.4, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  // Tone
  {
    id: "Tone_Compressor",
    category: "Tone",
    name: "Compressor",
    isSend: false,
    internalFXChain: [
      {
        effectId: "tuna.Compressor",
        params: {
          // https://github.com/Theodeus/tuna/blob/master/tuna.js#L533
          threshold: -15,
          makeupGain: 0,
          attack: 1,
          release: 250,
          ratio: 2,
          knee: 5,
          automakeup: true
        },
        mapping: {
          threshold: {
            stopsY: [-15, -30],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          },
          ratio: {
            stopsY: [2, 8],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          }
        }
      },
      {
        effectId: "tuna.Gain",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L1062
          gain: 1
        },
        mapping: {
          gain: {
            stopsY: [1, 0.6],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Tone_Overdrive",
    category: "Tone",
    name: "Overdrive",
    isSend: false,
    internalFXChain: [
      {
        effectId: "tuna.Overdrive",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L1213
          outputGain: -9.154,
          drive: 1,
          curveAmount: 0.6,
          algorithmIndex: 0
        },
        mapping: {
          curveAmount: {
            stopsY: [0.6, 0.98],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          }
        }
      },
      {
        effectId: "tuna.Filter",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L967
          frequency: 3000,
          Q: 1,
          gain: 0,
          filterType: "lowpass"
        },
        mapping: {
          frequency: {
            stopsY: [3000, 1800],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          }
        }
      },
      {
        effectId: "tuna.Gain",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L1062
          gain: 1
        },
        mapping: {
          gain: {
            stopsY: [1, 0.25],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          }
        }
      },
      {
        effectId: "send.Reverb_Hall",
        params: {
          send: 0
        },
        mapping: {
          send: {
            stopsY: [0, 0, 0.15],
            stopsX: [0, 0.25, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Tone_Phaser",
    category: "Tone",
    name: "Phaser",
    isSend: false,
    internalFXChain: [
      {
        effectId: "tuna.Phaser",
        params: {
          // https://github.com/Theodeus/tuna/blob/master/tuna.js#L1470
          rate: 0.3,
          depth: 0.1,
          feedback: 0.1,
          stereoPhase: 40,
          baseModulationFrequency: 400
        },
        mapping: {
          depth: {
            stopsY: [0.1, 0.7],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          },
          feedback: {
            stopsY: [0.1, 0.8],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          }
        }
      },
      {
        effectId: "tuna.Gain",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L1062
          gain: 1
        },
        mapping: {
          gain: {
            stopsY: [1, 0.5],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Tone_Tremolo",
    category: "Tone",
    name: "Tremolo",
    isSend: false,
    internalFXChain: [
      {
        effectId: "bds.Tremolo",
        params: {
          frequency: 0.25,
          depth: 0
        },
        mapping: {
          frequency: {
            stopsY: [3, 6],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          },
          depth: {
            stopsY: [0, 0.7],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Tone_WahWah",
    category: "Tone",
    name: "WahWah",
    isSend: false,
    internalFXChain: [
      {
        effectId: "bds.WahWah",
        params: {
          frequency: 0.25,
          depth: 0
        },
        mapping: {
          frequency: {
            stopsY: [0.25, 20],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          },
          depth: {
            stopsY: [0, 4],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          },
          Q: {
            stopsY: [10, 20],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          }
        }
      },
      {
        effectId: "send.Reverb_Hall",
        params: {
          send: 0
        },
        mapping: {
          send: {
            stopsY: [0, 0.05, 0.15],
            stopsX: [0, 0.25, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Tone_Chorus",
    category: "Tone",
    name: "Chorus",
    isSend: false,
    internalFXChain: [
      {
        effectId: "bds.Chorus",
        params: {
          frequency: 0.5,
          depth: 0.0005,
          dryLevel: 1,
          wetLevel: 0
        },
        mapping: {
          frequency: {
            stopsY: [0.3, 4],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          },
          depth: {
            stopsY: [0.00005, 0.0004],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          },
          dryLevel: {
            stopsY: [1, 0],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          },
          wetLevel: {
            stopsY: [0, 0.8, 1],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Tone_Bitcrusher",
    category: "Tone",
    name: "Bitcrusher",
    isSend: false,
    internalFXChain: [
      {
        effectId: "tuna.Bitcrusher",
        params: {
          // https://github.com/Theodeus/tuna/blob/master/tuna.js#L258
          bits: 8,
          normfreq: 0.8,
          bufferSize: 4096
        },
        mapping: {
          bits: {
            stopsY: [8, 4],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          }
        }
      }
    ],
    val: 0
  },
  // Special
  {
    id: "Special_Clouds",
    category: "Special",
    name: "Clouds",
    isSend: true,
    internalFXChain: [
      {
        effectId: "send.Special_Clouds",
        params: {
          send: 1,
          dry: 1
        },
        mapping: {
          send: {
            stopsY: [0.1, 1, 1],
            stopsX: [0, 0.75, 1],
            stopsInterp: ["linear", "linear"]
          },
          dry: {
            stopsY: [1, 1, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Special_Sewer",
    category: "Special",
    name: "Sewer",
    isSend: true,
    internalFXChain: [
      {
        effectId: "send.Special_Sewer",
        params: {
          send: 1,
          dry: 1
        },
        mapping: {
          send: {
            stopsY: [0.1, 1, 1],
            stopsX: [0, 0.75, 1],
            stopsInterp: ["linear", "linear"]
          },
          dry: {
            stopsY: [1, 1, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Special_Rain",
    category: "Special",
    name: "Rain",
    isSend: true,
    internalFXChain: [
      {
        effectId: "send.Special_Rain",
        params: {
          send: 1,
          dry: 1
        },
        mapping: {
          send: {
            stopsY: [0.1, 1, 1],
            stopsX: [0, 0.75, 1],
            stopsInterp: ["linear", "linear"]
          },
          dry: {
            stopsY: [1, 1, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Special_ChoralOo",
    category: "Special",
    name: "Choral Oo",
    isSend: true,
    internalFXChain: [
      {
        effectId: "send.Special_ChoralOo",
        params: {
          send: 1,
          dry: 1
        },
        mapping: {
          send: {
            stopsY: [0.1, 0.7, 0.8],
            stopsX: [0, 0.75, 1],
            stopsInterp: ["linear", "linear"]
          },
          dry: {
            stopsY: [1, 1, 0],
            stopsX: [0, 0.5, 0.8],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Special_ChoralAh",
    category: "Special",
    name: "Choral Ah",
    isSend: true,
    internalFXChain: [
      {
        effectId: "send.Special_ChoralAh",
        params: {
          send: 1,
          dry: 1
        },
        mapping: {
          send: {
            stopsY: [0.1, 0.7, 0.8],
            stopsX: [0, 0.75, 1],
            stopsInterp: ["linear", "linear"]
          },
          dry: {
            stopsY: [1, 1, 0],
            stopsX: [0, 0.5, 0.8],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },

  {
    id: "Special_Machine",
    category: "Special",
    name: "Machine",
    isSend: true,
    internalFXChain: [
      {
        effectId: "send.Special_Machine",
        params: {
          send: 1,
          dry: 1
        },
        mapping: {
          send: {
            stopsY: [0.1, 1, 1],
            stopsX: [0, 0.75, 1],
            stopsInterp: ["linear", "linear"]
          },
          dry: {
            stopsY: [1, 1, 0],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Special_Glitch",
    category: "Special",
    name: "Glitch",
    isSend: true,
    internalFXChain: [
      {
        effectId: "send.Special_Glitch",
        params: {
          send: 1,
          dry: 1
        },
        mapping: {
          send: {
            stopsY: [0, 1, 1],
            stopsX: [0, 0.5, 1],
            stopsInterp: ["linear", "linear"]
          },
          dry: {
            stopsY: [1, 1, 0],
            stopsX: [0, 0.5, 0.8],
            stopsInterp: ["linear", "linear"]
          }
        }
      }
    ],
    val: 0
  },
  {
    id: "Special_PitchShifter",
    category: "Special",
    name: "Pitch Down",
    isSend: false,
    internalFXChain: [
      {
        effectId: "bds.PitchShifter",
        params: {
          pitchOffset: -1,
          dryLevel: 1,
          wetLevel: 0
        },
        mapping: {
          dryLevel: {
            stopsY: [1, 0],
            stopsX: [0, 0.8],
            stopsInterp: ["linear"]
          },
          wetLevel: {
            stopsY: [0.1, 1],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          }
        }
      },
      {
        effectId: "tuna.Filter",
        params: {
          // see https://github.com/Theodeus/tuna/blob/master/tuna.js#L967
          frequency: 3000,
          Q: 1,
          gain: 0,
          filterType: "lowpass"
        },
        mapping: {
          frequency: {
            stopsY: [2000, 400],
            stopsX: [0, 1],
            stopsInterp: ["linear"]
          }
        }
      }
    ],
    val: 0
  }
];

/*
 * Return the current values of the effectId.
 * If no effectId found. It will return undefined.
 */
export const getFxValue = fxName => {
  return insertFX.find(val => val.name === fxName);
};

/*
 * This references the UI dropdowns for the fx slots where there's a category and each
 * category has a list of subcategories.
 */
export const getDistinctFxCategoryTypes = (): string[] => {
  return insertFX.reduce((acc, curr) => {
    if (acc.includes(curr.category)) {
      return acc;
    }
    return [...acc, curr.category];
  }, []);
};

/*
 * This gets all the fx subcategories for each category.
 *
 * Parameters:
 *  parentCategory (string): the category to return all subcategories for
 */
export const getAllFxSubCategoryTypesByParentCategory = (
  parentCategory: string
): FX[] => {
  return insertFX.reduce((acc, curr) => {
    if (curr.category === parentCategory) {
      return [...acc, curr];
    }
    return acc;
  }, []);
};
