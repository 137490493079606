import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";

const SignUp = lazy(() => import("../../pages/Guest/SignUp"));
const SetUpPassword = lazy(() => import("../../pages/Guest/SetUpPassword"));
const ResetEmail = lazy(() => import("../../pages/Guest/ResetEmail"));
const SignIn = lazy(() => import("../../pages/Guest/SignIn"));
const NotFound = lazy(() => import("../../components/404"));

/*
 * Add all routes specific to authentication here
 */
const RoutesAuth = () => {
  return (
    <Switch>
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/signup/:email" component={SignUp} />
      <Route exact path="/reset" component={ResetEmail} />
      <Route exact path="/signin" component={SignIn} />
      <Route
        exact
        path="/set-password/:email/:password"
        component={SetUpPassword}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default RoutesAuth;
