import styled from "styled-components";

export const KnobContainer = styled.div`
  &&.knob-input__container {
    position: relative;
  }

  &&.knob-input__visual {
    pointer-events: none;
    width: 100%;
    height: 100%;
  }

  &&.fl-standard-knob {
    .fl-standard-knob__svg {
      overflow: visible;
    }

    &:hover,
    &.drag-active {
      transform: scale(1.3);

      .fl-standard-knob__dial {
        transform: scale(0.9);
        transition: all 300ms cubic-bezier(0.39, 0.575, 0.565, 1);
      }
      .fl-standard-knob__dial-highlight {
        opacity: 0.05;
      }
      .fl-standard-knob__indicator-dot {
        opacity: 1;
      }
    }

    &.focus-active {
      .fl-standard-knob__focus-indicator {
        opacity: 0.8;
      }
    }
  }

  .knob-input__base {
    cursor: grab;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.001;
  }

  .fl-standard-knob__focus-indicator {
    display: none;
    opacity: 0;
    transition: all 300ms cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  .fl-standard-knob__indicator-dot {
    transform-origin: 20px 20px;
    fill: #d8d8d8;
    opacity: 0;
  }

  .fl-standard-knob__dial {
    transform-origin: 20px 20px;
    transition: all 600ms cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  .fl-standard-knob__dial-highlight {
    display: none;
    opacity: 0;
    transition: all 300ms cubic-bezier(0.39, 0.575, 0.565, 1);
  }
`;
