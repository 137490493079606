import React from "react";
import { Modal } from "antd";
import { Container } from "./styles";

/*
 * Use this component for modal skins across the site for consistent UI/UX
 * props:
 *  modalState (useModal result): encapsulated state returned from hook useModal (visible, close, etc.)
 *  onClose (callback): called immediately before modal is closed
 *  Antd props: see https://3x.ant.design/components/modal/#components-modal-demo-button-props
 */
const AntModal = props => {
  const closeIcon = (
    <img
      src={require("../../assets/close.svg")}
      width="16px"
      alt="Close Modal"
    />
  );

  return (
    <Modal
      closable={props.closable || true}
      closeIcon={props.closeIcon || closeIcon}
      destroyOnClose={props.destroyOnClose || true}
      footer={props.footer || null}
      maskClosable={props.maskClosable || true}
      visible={props.modalState.visible}
      width={props.width || "fit-content"}
      wrapClassName={props.wrapClassName}
      onCancel={() => {
        if (props.onClose) props.onClose();
        props.modalState.close();
      }}
    >
      <Container
        backgroundColor={props.backgroundColor}
        isBlue={props.isBlue}
        padding={props.padding}
        maxHeight={props.maxHeight || "75vh"}
      >
        {props.children}
      </Container>
    </Modal>
  );
};

export default AntModal;
