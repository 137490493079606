import * as React from "react";
import { theme } from "../globalStyles";

export const VolumeMuted = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={theme.editor.iconLight}
      d="M12.6,1.6v16.3c0,0.6-0.5,1.2-1.2,1.2h-0.7c-0.3,0-0.6-0.1-0.8-0.3l-4.5-4.5c-0.7-0.7-1.5-1-2.5-1 c-1.1,0-2-0.9-2-2V8.3c0-1.1,0.9-2,2-2c0.9,0,1.8-0.4,2.5-1l4.5-4.5c0.2-0.2,0.5-0.3,0.8-0.3h0.7C12.1,0.5,12.6,1,12.6,1.6z"
    />
    <text 
      transform="matrix(1 0 0 1 15.0156 13.0346)" 
      fontSize="12px"
      fontWeight="bold"
      fill={theme.editor.iconLight}>
        x
    </text>
  </svg>
);
