import { Reducer } from "redux";
import {
  CHANGE_REGION_DURATION,
  CHANGE_REGION_START,
  ChangeRegionDurationAction,
  ChangeRegionStartAction,
  CREATE_REGION,
  CreateRegionAction,
  DELETE_REGION,
  DeleteRegionAction,
  DUPLICATE_REGION,
  DuplicateRegionAction,
  MOVE_REGION,
  MOVE_REGION_DIFFERENT_LANE,
  MoveRegionAction,
  MoveRegionToDifferentLaneAction,
  SET_FADE_END,
  SET_FADE_START,
  SetFadeEndAction,
  SetFadeStartAction,
  SPLIT_REGION,
  SplitRegionAction
} from "../actions/actionTypes";
import { Regions } from "../store";

const initialRegions: Regions = {
  byId: {}
};

export const regionsReducer: Reducer<
  Regions,
  | MoveRegionAction
  | ChangeRegionDurationAction
  | ChangeRegionStartAction
  | DeleteRegionAction
  | SplitRegionAction
  | DuplicateRegionAction
  | SetFadeStartAction
  | SetFadeEndAction
  | MoveRegionToDifferentLaneAction
  | CreateRegionAction
> = (state = initialRegions, action) => {
  switch (action.type) {
    case SET_FADE_START:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.regionId]: {
            ...state.byId[action.regionId],
            startFadeTime: action.fadeTime
          }
        }
      };
    case SET_FADE_END:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.regionId]: {
            ...state.byId[action.regionId],
            endFadeTime: action.fadeTime
          }
        }
      };
    case MOVE_REGION_DIFFERENT_LANE:
    case MOVE_REGION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.regionId]: {
            ...state.byId[action.regionId],
            visualStartTime: action.newStartTime
          }
        }
      };
    case CHANGE_REGION_DURATION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.regionId]: {
            ...state.byId[action.regionId],
            visualDuration: action.newDuration
          }
        }
      };
    case CHANGE_REGION_START:
      const deltaStartTime =
        -action.newStartTime + state.byId[action.regionId].visualStartTime;
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.regionId]: {
            ...state.byId[action.regionId],
            visualStartTime: action.newStartTime,
            visualDuration:
              deltaStartTime + state.byId[action.regionId].visualDuration,
            audioStartTime:
              -deltaStartTime + state.byId[action.regionId].audioStartTime
          }
        }
      };
    case DELETE_REGION:
      const { [action.regionId]: value, ...without } = state.byId;
      return {
        ...state,
        byId: without
      };
    case DUPLICATE_REGION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.newRegionId]: {
            ...state.byId[action.regionId],
            id: action.newRegionId,
            visualStartTime:
              state.byId[action.regionId].visualStartTime +
              state.byId[action.regionId].visualDuration
          }
        }
      };
    case SPLIT_REGION:
      const secondHalfDuration =
        state.byId[action.regionId].visualDuration -
        action.split_offset_seconds;
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.regionId]: {
            ...state.byId[action.regionId],
            visualDuration: action.split_offset_seconds,
            endFadeTime: 0,
            startFadeTime: Math.min(
              action.split_offset_seconds,
              state.byId[action.regionId].startFadeTime
            )
          },
          [action.newRegionId]: {
            ...state.byId[action.regionId],
            id: action.newRegionId,
            visualStartTime:
              state.byId[action.regionId].visualStartTime +
              action.split_offset_seconds,
            visualDuration: secondHalfDuration,
            audioStartTime:
              state.byId[action.regionId].audioStartTime +
              action.split_offset_seconds,
            startFadeTime: 0,
            endFadeTime: Math.min(
              secondHalfDuration,
              state.byId[action.regionId].endFadeTime
            ),
            startTimeOffsetPixels: 0
          }
        }
      };
    case CREATE_REGION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.newId]: {
            id: action.newId,
            visualStartTime: action.visualStartTime,
            visualDuration: action.visualDuration,
            startFadeTime: 0,
            endFadeTime: 0,
            startFadeTimeOverlap: false,
            endFadeTimeOverlap: false,
            _forceRerender: 0,
            audioPath: action.audioPath,
            audioPathHQ: action.audioPathHQ,
            audioStartTime: action.audioStartTime,
            audioSrcDuration: action.audioSrcDuration,
            startTimeOffsetPixels: 0,
            audioSrcColors: action.audioSrcColors,
            audioSrcSeed: action.audioSrcSeed
          }
        }
      };
    default:
      return state;
  }
};
