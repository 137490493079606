import { Dispatch } from "redux";
import {
  getCustomMix,
  getFile,
  InitialTrackDataAPI,
  postTrackEditorCache,
  postTrackStemEnvelopes,
  saveCustomMix
} from "../api/WebAPI";
import { AudioMaster } from "../audio/AudioMaster";
import { RegionSplitter, SubRegion } from "../audio/RegionSplitter";
import {
  InitialBlockFiles,
  InitialTrackData,
  InitialLanesData,
  MainStore,
  Instrument,
  Project,
  Waveform,
  Envelopes
} from "../store";
import { genNewId } from "../util/ids";
import { getRandomInt } from "../util/randomize";
import { getInstrumentColorCombo } from "../util/lane_color_helpers";
import {
  ADD_REGION_TO_LANE,
  AddRegionToLaneAction,
  CREATE_REGION,
  CREATE_LANE,
  CreateRegionAction,
  CreateLaneAction,
  REHYDRATE_INITIAL_MIX,
  RehydrateInitialMixAction,
  FINISH_LANE_LOADING,
  FinishLaneLoading,
  INITIALIZE_LANE_LOADING,
  InitializeLaneLoading,
  UPDATE_CUSTOM_MIX_META,
  UpdateCustomMixMetaAction,
  REHYDRATE_CUSTOM_MIX,
  RehydrateCustomMixAction,
  UPDATE_LANE_LOADING_PROGRESS,
  UpdateLaneLoadingProgress,
  WAVEFORM_BUFFER_LOADED,
  WaveformBufferLoadedAction,
  SetAlertAction,
  SET_ALERT,
  UpdateCustomMixVersionAction,
  UPDATE_CUSTOM_MIX_VERSION,
  SetHDBuffersLoadedAction,
  SET_HD_BUFFERS_LOADED,
  ResetStoreAction,
  RESET_STORE,
  SetEditorDataDumpAction,
  SET_EDITOR_DATA_DUMP,
  WaveformEnvelopesLoadedAction,
  WAVEFORM_ENVELOPES_LOADED
} from "./actionTypes";
import { getInitialTrack } from "../api/WebAPI";
import { calculateDataHash } from "../store/store_processors";
import { isObjectEmpty } from "../util/isObjectEmpty";
import {
  calcDynamicWindowSize,
  envelopeGenerator
} from "../audio/EnvelopeGenerator";

function getCurrentProjectNumber(getState) {
  return getState().editorState.nthProjectLoaded;
}

function resolvePath(audioPath: string) {
  if (audioPath.includes("https")) {
    return audioPath;
  } else if (audioPath.includes("blob")) {
    return audioPath;
  }
  return getFile(audioPath);
}

async function fetchAudio(audioPath: string, audioMaster: AudioMaster) {
  const path = await resolvePath(audioPath);
  return await fetch(path)
    .then(r => r.arrayBuffer())
    .then(arrayBuffer => audioMaster.context.decodeAudioData(arrayBuffer));
}

async function fetchAudioWithProgress(
  laneId: string,
  audioPath: string,
  audioMaster: AudioMaster,
  dispatch: Dispatch<any>
) {
  // Starting to lane progress
  dispatch<InitializeLaneLoading>({
    type: INITIALIZE_LANE_LOADING,
    laneId
  });

  const path = await resolvePath(audioPath);
  const audioBuffer = await fetch(path);

  const reader = audioBuffer.body.getReader();
  const contentLength = audioBuffer.headers.get("Content-Length");

  let receivedLength = 0;
  const chunks = [];
  while (true) {
    const { done, value } = await reader.read();

    if (done) {
      break;
    }

    chunks.push(value);
    receivedLength += value.length;

    dispatch<UpdateLaneLoadingProgress>({
      // @ts-ignore
      progress: receivedLength / contentLength,
      laneId,
      type: UPDATE_LANE_LOADING_PROGRESS
    });
  }

  const chunksAll = new Uint8Array(receivedLength);
  let position = 0;
  for (const chunk of chunks) {
    chunksAll.set(chunk, position);
    position += chunk.length;
  }

  const result = await new Response(chunksAll).arrayBuffer();

  dispatch<FinishLaneLoading>({
    type: FINISH_LANE_LOADING,
    laneId
  });

  return audioMaster.context.decodeAudioData(result);
}

/*
 * Called when paid user bounces a track, loads HD stems on demand if not in audio cache before
 * bounce
 */
export function loadAllHDAudioFiles(
  audioMaster: AudioMaster,
  dispatch: Dispatch<any>,
  getState: () => MainStore
) {
  const project = getState().project;
  const regions = Object.keys(project.regions.byId).map(
    k => project.regions.byId[k]
  );
  const audioFilesHQ = new Set<string>(regions.map(r => r.audioPathHQ));
  // envelopes are calculated and linked to the sd stems
  // for the hd stems, just load them up in the audio waveform cache but don't need envelopes
  const hdStemEnvelopes = new Map<string, Envelopes>();
  audioFilesHQ.forEach(hqPath => hdStemEnvelopes.set(hqPath, null));

  loadAllAudioFiles(
    audioFilesHQ,
    audioMaster,
    dispatch,
    getState,
    null,
    hdStemEnvelopes
  ).then(() => {
    dispatch<SetHDBuffersLoadedAction>({
      type: SET_HD_BUFFERS_LOADED,
      hdLoaded: true
    });
  });
}

function loadAllAudioFiles(
  audioFiles: Set<string>,
  audioMaster: AudioMaster,
  dispatch: Dispatch<any>,
  getState: () => MainStore,
  trackId: string,
  audioEnvelopes?: Map<string, Envelopes>
) {
  return Promise.all(
    Array.from(audioFiles.values()).map(async audioPath => {
      const waveformBuffers = getState().waveformBuffers;
      let needAudio = true;
      let needEnvelope = !audioEnvelopes?.has(audioPath);

      if (audioPath in waveformBuffers.byAudioPath) {
        const waveform: Waveform = waveformBuffers.byAudioPath[audioPath];
        needAudio =
          waveform.audioBuffer === null || !audioMaster.hasAudio(audioPath);
        needEnvelope =
          needEnvelope &&
          (waveform.envelopes === null ||
            waveform.envelopes.channelAmplitudeSamples.length === 0);
      }

      if (!needEnvelope) {
        dispatch<WaveformEnvelopesLoadedAction>({
          type: WAVEFORM_ENVELOPES_LOADED,
          audioPath,
          envelopes: audioEnvelopes?.get(audioPath)
        });
      }

      if (needAudio) {
        await fetchAudio(audioPath, audioMaster).then(audioBuffer => {
          dispatch<WaveformBufferLoadedAction>({
            type: WAVEFORM_BUFFER_LOADED,
            audioBuffer,
            audioPath
          });
          audioMaster.saveAudio(audioPath, audioBuffer);

          // calculate waveform envelopes if necessary
          if (needEnvelope) {
            generateAndUpdateStemEnvelopes(
              audioPath,
              audioBuffer,
              dispatch,
              trackId
            );
          }
        });
      } else {
        // we already have audio cached
        const audioBuffer = audioMaster.getAudio(audioPath);
        dispatch<WaveformBufferLoadedAction>({
          type: WAVEFORM_BUFFER_LOADED,
          audioBuffer,
          audioPath
        });

        if (needEnvelope) {
          // no envelopes cached or availabe in redux, update
          generateAndUpdateStemEnvelopes(
            audioPath,
            audioBuffer,
            dispatch,
            trackId
          );
        }
      }
    })
  );
}

/*
 * Helper function that updates stem envelopes in redux store
 * and optionally updates stem envelopes cache in database
 *
 * Parameters:
 *  audioPath (string): path to stem audio file and redux key
 *  audioBuffer (AudioBuffer): audio waveform data to calculate envelopes from
 *  dispatch (Dispatch): redux action dispatcher
 *  updateTrackIdStemCache? (string): trackId - if set, refreshes Track::stemEnvelopes with this stem envelope
 */
async function generateAndUpdateStemEnvelopes(
  audioPath: string,
  audioBuffer: AudioBuffer,
  dispatch: Dispatch<WaveformEnvelopesLoadedAction>,
  updateTrackIdStemCache?: string
) {
  // calculate stem envelopes for all channels
  const envelopes: Envelopes = envelopeGenerator(
    audioBuffer,
    calcDynamicWindowSize(audioBuffer.sampleRate, 0.5),
    true,
    false,
    true
  );

  // disseminate to redux store
  dispatch<WaveformEnvelopesLoadedAction>({
    type: WAVEFORM_ENVELOPES_LOADED,
    audioPath,
    envelopes
  });

  // update envelope cache in db for future loads of this waveform
  if (updateTrackIdStemCache) {
    postTrackStemEnvelopes(updateTrackIdStemCache, audioPath, envelopes);
  }
}

/*
 * Load a saved custom mix
 *
 * Parameters:
 *  customMixId (uuid string): ID of custom mix to load
 *  versionNumber (int in [1, ...]): version number of custom mix to load
 *    if version number not set, loads current (latest) version
 *  audioMaster (AudioMaster object): reference to encapsulated web audio instance
 *  onLoaded (Callback Function): (Project) => {}
 */
export function loadCustomMix(
  customMixId: string,
  versionNumber: Number,
  paidAccess: boolean,
  audioMaster: AudioMaster,
  onRehydrated: (customMix: Project) => void,
  onLoaded: (customMix: Project) => void
) {
  // tslint:disable-next-line:only-arrow-functions
  return async function(dispatch: Dispatch<any>, getState: () => MainStore) {
    try {
      await getCustomMix(customMixId, versionNumber)
        .then(customMix => {
          dispatch<RehydrateCustomMixAction>({
            type: REHYDRATE_CUSTOM_MIX,
            project: customMix
          });

          // audio file loading runs asynchronously after rehydration
          if (onRehydrated) onRehydrated(customMix);

          const regions = Object.keys(customMix.regions.byId).map(
            k => customMix.regions.byId[k]
          );
          const audioFiles = new Set<string>(regions.map(r => r.audioPath));

          // load normal quality only
          loadAllAudioFiles(
            audioFiles,
            audioMaster,
            dispatch,
            getState,
            customMix.initialTrackData.id,
            customMix.initialTrackData.stemEnvelopes
          ).then(() => {
            dispatch<SetHDBuffersLoadedAction>({
              type: SET_HD_BUFFERS_LOADED,
              hdLoaded: false
            });
            if (onLoaded) onLoaded(customMix);
          });
        })
        .catch(() => {
          dispatch<SetAlertAction>({
            type: SET_ALERT,
            id: "LOAD_CUSTOM_MIX_ERROR",
            role: "error",
            title: "Error Loading",
            message:
              "We were unable to load the selected custom mix. If the problem persists, please contact our support team."
          });
        });
    } catch (e) {
      dispatch<SetAlertAction>({
        type: SET_ALERT,
        id: "LOAD_CUSTOM_MIX_ERROR",
        role: "error",
        title: "Error Loading",
        message:
          "We were unable to load the selected custom mix. If the problem persists, please contact our support team."
      });
    }
  };
}

/*
 * Save a custom mix from current redux data store
 *
 * Parameters:
 *  customMixId (uuid string): ID of custom mix
 *  projectId (uuid string): ID of corresponding project
 *  userId (uuid string): ID of user saving the mix
 *  onSuccess (Function): ({id, version: {versionNumber}}) => {}
 *  onFail (Function): () => {}
 */
export function saveCustomMixAction(
  customMixId: string,
  projectId: string,
  userId: string,
  onSuccess: Function = null,
  onFail: Function = null
) {
  // tslint:disable-next-line:only-arrow-functions
  return async function(dispatch: Dispatch<any>, getState: () => MainStore) {
    const state = getState();
    const lastSaveDataHash =
      state.project.customMixInfo.version.editorDataDumpHash;
    const currentDataHash = state.project.customMixInfo.currentDataHash;

    if (
      state.project.customMixInfo.version.versionNumber > 0 &&
      currentDataHash === lastSaveDataHash
    ) {
      // mimic a successful save but keep the same version number we're currently at since nothing really changed
      if (onSuccess) {
        onSuccess({
          id: state.project.customMixInfo.id,
          version: {
            versionNumber: state.project.customMixInfo.version.versionNumber
          }
        });
      }
      return;
    }

    /*
     * Prepare data for serialization and storage on server
     * Prune out the CustomMixInfo & InitialTrackData in the editorDataDump json blob stored on the server
     * we want the Track data tied to this CustomMix in the local redux store so it can be easily accessed,
     * but we don't want that data serialized and saved as a JSON blob on the server because it
     * can become stale if the Track is updated. Same with CustomMix, we don't want those uuids serialized
     * and stored in a JSON blob where it can become stale.
     */
    const editorDataDump = { ...state.project };
    delete editorDataDump.customMixInfo;
    delete editorDataDump.initialTrackData;

    saveCustomMix(
      customMixId,
      projectId,
      state.project.customMixInfo.trackId,
      userId,
      state.project.customMixInfo.title,
      state.project.customMixInfo.length,
      editorDataDump
    )
      .then(result => {
        if (result.name === "error") {
          if (onFail) onFail();
          dispatch<SetAlertAction>({
            type: SET_ALERT,
            id: "SAVE_CUSTOM_MIX_ERROR",
            role: "error",
            title: "Error Saving",
            message:
              "There was an error saving your custom mix. Please try again or contact our support team for assistance."
          });
        } else {
          dispatch<UpdateCustomMixMetaAction>({
            type: UPDATE_CUSTOM_MIX_META,
            id: result.id,
            projectId: result.projectId,
            trackId: result.trackId,
            title: result.title,
            length: result.version.duration,
            numVersions: result.numVersions
          });
          dispatch<UpdateCustomMixVersionAction>({
            type: UPDATE_CUSTOM_MIX_VERSION,
            id: result.version.id,
            versionNumber: result.version.versionNumber,
            editorDataDumpHash: result.version.editorDataDumpHash,
            createdAt: result.version.created_at,
            updatedAt: result.version.updated_at
          });

          dispatch<SetAlertAction>({
            type: SET_ALERT,
            id: "SAVE_SUCCESS",
            role: "success",
            title: "Saved",
            message: "Your custom mix has been saved successfully."
          });

          if (onSuccess) onSuccess(result);
        }
      })
      .catch(() => {
        if (onFail) onFail();
        dispatch<SetAlertAction>({
          type: SET_ALERT,
          id: "SAVE_CUSTOM_MIX_ERROR",
          role: "error",
          title: "Error Saving",
          message:
            "There was an error saving your custom mix. Please try again or contact our support team for assistance."
        });
      });
  };
}

/*
 * Saves a slice of the redux store necessary for (de)serialization of the track initial state
 * on Track::editorDataDump field
 */
export function saveInitialTrackCache(
  trackId: string,
  onSuccess: Function = null,
  onFail: Function = null
) {
  return async (dispatch: Dispatch<any>, getState: () => MainStore) => {
    /*
     * Prune out the CustomMixInfo & InitialTrackData in the editorDataDump json blob stored on the server
     * we want the Track data tied to this CustomMix in the local redux store so it can be easily accessed,
     * but we don't want that data serialized and saved as a JSON blob on the server because it
     * can become stale if the Track is updated. Same with CustomMix, we don't want those uuids serialized
     * and stored in a JSON blob where it can become stale.
     */
    const editorDataDump = { ...getState().project };
    delete editorDataDump.customMixInfo;
    delete editorDataDump.initialTrackData;

    postTrackEditorCache(trackId, editorDataDump).then(res => {
      if (res !== null && res !== undefined && !isObjectEmpty(res)) {
        dispatch<SetEditorDataDumpAction>({
          type: SET_EDITOR_DATA_DUMP,
          editorDataDump: editorDataDump
        });
        if (onSuccess) onSuccess();
      } else {
        if (onFail) onFail();
      }
    });
  };
}

/*
 * Loads up the editor with Track editor cache (Track::editorDataDump)
 * This is the main loader for users & admins loading as a normal user
 *
 * Parameters
 *  trackId (string): uuid of Track
 *  audioMaster (AudioMaster object): reference to web audio encapsulation
 *  onLoaded (function): (Project) => {}
 */
export function loadMixFromInitialTrackCache(
  trackId: string,
  paidAccess: boolean,
  audioMaster: AudioMaster,
  onRehydrated: (project: Project) => void,
  onLoaded: (project: Project) => void
) {
  return async (dispatch: Dispatch<any>, getState: () => MainStore) => {
    // Completely empty state so there's no chance of stale data.
    dispatch<ResetStoreAction>({
      type: RESET_STORE
    });

    const initialTrackData: InitialTrackDataAPI = await getInitialTrack(
      trackId
    );
    const mixCache = initialTrackData.editorDataDump;

    // rehydrate using Track::editorDataDump JSON serialization
    dispatch<RehydrateInitialMixAction>({
      type: REHYDRATE_INITIAL_MIX,
      lanes: mixCache ? mixCache.lanes : null,
      regions: mixCache ? mixCache.regions : null,
      initialTrackData: initialTrackData
    });

    // we're supposed to be loading from Track::editorDataDump but it hasn't been set, show loading error
    if (mixCache === null) {
      dispatch<SetAlertAction>({
        type: SET_ALERT,
        id: "LOAD_MIXFROMCACHE_ERROR",
        role: "error",
        title: "Error",
        message:
          "We are unable to load the editor for this track. Please try again or contact our support team for assistance."
      });
    } else {
      // audio file loading runs asynchronously after rehydration
      if (onRehydrated) onRehydrated(getState().project);

      const regions = Object.keys(mixCache.regions.byId).map(
        k => mixCache.regions.byId[k]
      );
      const audioFiles = new Set<string>(regions.map(r => r.audioPath));

      // load normal quality stems for editor only
      loadAllAudioFiles(
        audioFiles,
        audioMaster,
        dispatch,
        getState,
        trackId,
        initialTrackData.stemEnvelopes
      ).then(() => {
        dispatch<SetHDBuffersLoadedAction>({
          type: SET_HD_BUFFERS_LOADED,
          hdLoaded: false
        });
        if (onLoaded) onLoaded(getState().project);
      });
    }
  };
}

/*
 * Loads up the editor with the mix & stems from the referenced Track, no modifications
 *
 * Parameters:
 *  trackId (string): uuid of Track
 *  audioMaster (AudioMaster object): reference to web audio encapsulation
 *  onLoaded (function): (Project) => {}
 */
export function loadMixFromInitialTrack(
  trackId: string,
  audioMaster: AudioMaster,
  onRehydrated: (project: Project) => void,
  onLoaded: (project: Project) => void
) {
  return async (dispatch: Dispatch<any>, getState: () => MainStore) => {
    // Completely empty state so there's no chance of stale data.
    dispatch<ResetStoreAction>({
      type: RESET_STORE
    });

    const initialTrackData = await getInitialTrack(trackId);

    dispatch<RehydrateInitialMixAction>({
      type: REHYDRATE_INITIAL_MIX,
      lanes: null,
      regions: null,
      initialTrackData: initialTrackData
    });
    if (onRehydrated) onRehydrated(getState().project);

    const nthProject = getCurrentProjectNumber(getState);

    for (const laneInfo of initialTrackData.lanes) {
      // escapes out of loading tracks if user has started loading a different track
      if (getState().project.initialTrackData.id !== trackId) break;

      const loadLane = loadProjectLaneAction(
        initialTrackData,
        laneInfo,
        audioMaster,
        nthProject
      );
      await dispatch(loadLane);
    }

    // escapes out of loading tracks if user has started loading a different track
    if (getState().project.initialTrackData.id !== trackId) return;

    // when loading from initial track (admins) always use HQ audio stems
    dispatch<SetHDBuffersLoadedAction>({
      type: SET_HD_BUFFERS_LOADED,
      hdLoaded: true
    });

    // set initial data hash after load complete
    const projectState = getState().project;
    dispatch<UpdateCustomMixVersionAction>({
      type: UPDATE_CUSTOM_MIX_VERSION,
      ...projectState.customMixInfo.version,
      editorDataDumpHash: calculateDataHash(projectState)
    });

    if (onLoaded) onLoaded(getState().project);
  };
}

function loadRegions(
  regions: SubRegion[],
  audioPath: string,
  audioPathHQ: string,
  laneId: string,
  length: number,
  laneSeed: number,
  laneColors: string[],
  dispatch: Dispatch
) {
  regions.forEach(region => {
    const regionId = genNewId();

    dispatch<CreateRegionAction>({
      type: CREATE_REGION,
      newId: regionId,
      visualStartTime: region.visualStartTime,
      visualDuration: region.visualEndTime - region.visualStartTime,
      audioPath,
      audioPathHQ,
      audioStartTime: region.audioStartTime,
      audioSrcDuration: length,
      audioSrcColors: laneColors,
      audioSrcSeed: laneSeed
    });

    dispatch<AddRegionToLaneAction>({
      type: ADD_REGION_TO_LANE,
      laneId,
      regionId
    });
  });
}

export function loadLaneAction(
  name: string,
  bpm: number,
  beatsPerMeasure: number,
  gainCompensation: number,
  audioPath: string,
  audioPathHQ: string,
  laneId: string,
  instrument: Instrument,
  audioMaster: AudioMaster,
  nthProject: number
) {
  return async function(dispatch: Dispatch<any>, getState: () => MainStore) {
    if (nthProject !== getCurrentProjectNumber(getState)) {
      return;
    }

    const numInInstrumentCategory = Object.values(
      getState().project.lanes.byId
    ).reduce((categorySum, lane) => {
      return (
        categorySum + (lane.instrument.category === instrument.category ? 1 : 0)
      );
    }, 0);
    const laneColors = getInstrumentColorCombo(
      instrument.category,
      numInInstrumentCategory
    );

    const newLaneId = genNewId();
    dispatch<CreateLaneAction>({
      type: CREATE_LANE,
      newId: newLaneId,
      name,
      originalId: laneId,
      instrument,
      color: laneColors[0],
      gainCompensation
    });

    // if we have audio cached, use it, otherwise load it
    const audioBuffer = audioMaster.hasAudio(audioPath)
      ? audioMaster.getAudio(audioPath)
      : await fetchAudioWithProgress(
          newLaneId,
          audioPath,
          audioMaster,
          dispatch
        );

    // if we had to load, cache buffer and update redux store
    if (!audioMaster.hasAudio(audioPath)) {
      dispatch<WaveformBufferLoadedAction>({
        type: WAVEFORM_BUFFER_LOADED,
        audioPath,
        audioBuffer
      });
      audioMaster.saveAudio(audioPath, audioBuffer);
    }

    // now check envelopes, see if we have it cached in redux, otherwise we should calc and load
    if (!getState().waveformBuffers.byAudioPath[audioPath]?.envelopes) {
      // don't update envelope cache on server here, this could be user uploaded track
      // just generate envelopes for it and disseminate to redux store
      // if we're loading another
      generateAndUpdateStemEnvelopes(audioPath, audioBuffer, dispatch);
    }

    const initialBeat = getState().project.initialTrackData.initialBeat;
    const regions = RegionSplitter(
      audioBuffer,
      bpm,
      beatsPerMeasure,
      initialBeat,
      gainCompensation
    );

    const laneSeed = getRandomInt(1, 1000000);
    const audioLength = audioBuffer.length / audioBuffer.sampleRate;
    loadRegions(
      regions,
      audioPath,
      audioPathHQ,
      newLaneId,
      audioLength,
      laneSeed,
      laneColors,
      dispatch
    );
  };
}

export function loadProjectLaneAction(
  projectSpec: InitialTrackData,
  laneInfo: InitialLanesData | InitialBlockFiles,
  audioMaster: AudioMaster,
  nthProject: number
) {
  // @ts-ignore
  const laneGainCompensation = laneInfo.gainCompensation || 0;
  return loadLaneAction(
    laneInfo.name,
    projectSpec.bpm,
    projectSpec.beatsPerMeasure,
    laneGainCompensation,
    laneInfo.audioPath,
    laneInfo.audioPathHQ,
    laneInfo.id,
    laneInfo.instrument,
    audioMaster,
    nthProject
  );
}
