import gql from "graphql-tag";
import { fragments } from "../../utils/fragments";

export const GET_FAVORITES = gql`
  query($userId: ID!) {
    favorites(user: $userId) {
      id
      track {
        ...TrackData
      }
    }
  }
  ${fragments.trackQuery}
`;

export const ADD_TO_FAVORITES = gql`
  mutation($userId: ID!, $trackId: ID!) {
    createFavorite(data: { user: $userId, track: $trackId }) {
      track {
        ...TrackData
      }
    }
  }
  ${fragments.trackQuery}
`;
export const REMOVE_FROM_FAVORITES = gql`
  mutation($userId: ID!, $trackId: ID!) {
    deleteFavorite(userId: $userId, trackId: $trackId)
  }
`;
