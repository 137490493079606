import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 64px;

  @keyframes verticalPop {
    0% {
      transform: scaleY(0.1);
    }
    100% {
      transform: scaleY(1);
    }
  }

  .seekbar {
    transition: width 0.25s ease-out;
    -moz-transition: none;
  }

  .waveform {
    /* Has to be the same width with .waveform-container */
    cursor: pointer;
    width: 100%;
    height: 64px;
    position: absolute;
    grid-column: 3/4;
  }

  #background-waveform {
    -webkit-filter: ${props =>
      props.hovered
        ? props.isCurrentTrack
          ? "grayscale(0) opacity(0.55)"
          : "grayscale(0) opacity(1)"
        : "grayscale(1) opacity(0.3)"};

    filter: ${props =>
      props.hovered
        ? props.isCurrentTrack
          ? "grayscale(0.25) opacity(0.55)"
          : "grayscale(0) opacity(1)"
        : "grayscale(1) opacity(0.3)"};

    transition: filter 0.2s ease-out;
    transition: transform 0.2s cubic-bezier(0.87, -0.29, 0.7, 1.64);
    animation: 0.7s cubic-bezier(0.87, -0.29, 0.7, 1.64) verticalPop;
  }
  &:hover {
    filter: drop-shadow(2px 2px 8px #5d95aa50);
  }
  &:active {
    filter: drop-shadow(2px 2px 8px #5d95aa50) brightness(1.2);
  }

  .waveform-container {
    /* Has to be the same width with .waveform */
    width: 100%;
  }

  .waveform:nth-child(2) {
    z-index: ${props => (props.isMainPlayer === "main-player" ? 160 : 9)};
  }
`;
