import React, { useContext } from "react";
import { QueueContext } from "../../context/QueueProvider";
import { ToastContainer } from "./styles";
const Toast = props => {
  const queue = useContext(QueueContext);

  return (
    <div>
      {queue.addToQueueMessage ? (
        <ToastContainer>
          <div onClick={props.openQueue} className="toast">
            <p> Track Added To Queue</p>
          </div>

          <img
            className="triangle"
            src={require("../../assets/triangle.svg")}
            alt="Track Menu"
          />
        </ToastContainer>
      ) : null}
    </div>
  );
};

export default Toast;
