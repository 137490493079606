export const timeFormatter = value => {
  const minutes = Math.floor(value / 60);
  const seconds = value - minutes * 60;
  if (value === 360) {
    return `${minutes}:${seconds}0`;
  }
  if (value > 60) {
    return `${minutes}:${seconds}`;
  }
  if (seconds === 0) return `${minutes}:00`;
  // x;
  // return `0:${value}`;
};

export const timeFormatterNum = value => {
  const minutes = Math.floor(value / 60);
  const seconds = Math.floor(value - minutes * 60);

  if (seconds / 10 < 1) {
    return `${minutes}:0${seconds}`;
  }
  if (seconds === 0) {
    return `${minutes}:${seconds}0`;
  }

  return `${minutes}:${seconds}`;
};
