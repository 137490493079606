import { saveAs } from "file-saver";
import * as InlineWorker from "inline-worker";
import { Middleware, Store } from "redux";
import {
  FINISH_OFFLINE_RENDER,
  FinishOfflineRenderAction,
  SET_PLAYBACK_STATE,
  UPDATE_OFFLINE_RENDER_PROGRESS,
  UpdateOfflineRenderProgressAction
} from "../actions/actionTypes";
import { MainStore, PlaybackState } from "../store";
import { exportWavWorkerFunction } from "../util/exportWavWorkerFunction";
import { AudioMaster } from "./AudioMaster";

/**
 * Syncs state with WebAudio clock
 *
 */
export function AudioMiddleWareOffline(
  audioMaster: AudioMaster,
  masterStore: Store<MainStore>
): Middleware {
  // let loop = () => {
  //     requestAnimationFrame(loop);
  // };
  // requestAnimationFrame(loop);

  return store => next => action => {
    switch (action.type) {
      case SET_PLAYBACK_STATE:
        if (action.state === PlaybackState.PLAYING) {
          audioMaster.play(action.actionSongTime);

          const context = audioMaster.context as OfflineAudioContext;

          setTimeout(() => {
            const progressInterval = setInterval(() => {
              const currentTime = audioMaster.context.currentTime;
              const progress = currentTime / audioMaster.offLineLengthSeconds;
              masterStore.dispatch<UpdateOfflineRenderProgressAction>({
                type: UPDATE_OFFLINE_RENDER_PROGRESS,
                progress
              });
            }, 100);

            context.startRendering().then(renderedBuffer => {
              const worker = new InlineWorker(exportWavWorkerFunction);
              worker.postMessage({
                length: renderedBuffer.length,
                numberOfChannels: renderedBuffer.numberOfChannels,
                sampleRate: renderedBuffer.sampleRate,
                left: renderedBuffer.getChannelData(0),
                right: renderedBuffer.getChannelData(1)
              });
              clearInterval(progressInterval);

              worker.onmessage = e => {
                const blob = new window.Blob([new DataView(e.data)], {
                  type: "audio/wav"
                });
                const state: MainStore = store.getState();
                const trackTitle = state.project.initialTrackData.title;
                const trackVariationTitle =
                  state.project.initialTrackData.variationTitle;
                const customMixTitle = state.project.customMixInfo.title;
                const fileStem = (customMixTitle
                  ? customMixTitle
                  : `${trackTitle}${
                      trackVariationTitle ? "_" + trackVariationTitle : ""
                    }`
                ).replace(/\s/g, "");
                const filename = `${fileStem}.wav`;
                saveAs(blob, filename);

                masterStore.dispatch<FinishOfflineRenderAction>({
                  type: FINISH_OFFLINE_RENDER
                });
              };
            });
          }, 1000);
        }
        break;
    }

    const result = next(action);
    return result;
  };
}
