import styled from "styled-components";

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.color};
`;

export const Container = styled.div`
  background-color: ${props => props.theme.backgroundLight};
  color: ${props => props.theme.textLight};
  width: 580px;

  .modal-header {
    font-family: ${props => props.theme.h2.ff};
    font-size: ${props => props.theme.h2.fs};
    padding-bottom: 36px;
    display: flex;
    align-items: center;

    img {
      padding-right: 12px;
    }
  }
  .project {
    padding-top: 32px;
    &:first-child {
      padding-top: 19px;
    }
  }
  .project-name {
    font-family: ${props => props.theme.p2b.ff};
    font-size: ${props => props.theme.p2b.fs};
  }
  .playlist-name {
    display: flex;
    font-family: ${props => props.theme.p3r.ff};
    font-size: ${props => props.theme.p3r.fs};
    text-decoration-line: underline;
    margin-top: 18px;
    cursor: pointer;
    color: ${props => props.theme.highlight};
  }
  .team-indicator {
    width: 26px;
    display: flex;
    margin-left: 4px;
    align-items: center;
  }
`;
