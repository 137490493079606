import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_SNAP_MODE, SetSnapModeAction } from "../../actions/actionTypes";
import { Container, SnapIcon, SnapSelect } from "./styles";
import { InitialTrackData, MainStore, SnapMode } from "../../store";
import { SnapMagnetBar } from "../../../assets/SnapMagnetBar";
import { SnapMagnetBeat } from "../../../assets/SnapMagnetBeat";
import { SnapMagnetNone } from "../../../assets/SnapMagnetNone";
import { Tooltip } from "antd";
import { EditorContext } from "../../context/EditorContext/EditorProvider";

export function SnapControls() {
  const initialTrackData = useSelector<MainStore, InitialTrackData>(
    state => state.project.initialTrackData
  );
  const snap = useSelector<MainStore, SnapMode>(state => state.uiState.snap);
  const dispatch = useDispatch();

  const [dropdownActive, setDropdownActive] = useState(false);

  const editor = useContext(EditorContext);

  const snapOptions = [SnapMode.BAR, SnapMode.BEAT, SnapMode.NONE];

  useEffect(() => {
    if (initialTrackData.bpm in [0, 1]) {
      dispatch<SetSnapModeAction>({
        type: SET_SNAP_MODE,
        snapMode: SnapMode.NONE
      });
    }
    // eslint-disable-next-line
  }, [initialTrackData.bpm]);

  const shouldBeDisabled = value => {
    if (value === SnapMode.NONE) {
      return false;
    }

    if (initialTrackData.bpm !== 1 && initialTrackData.bpm !== 0) {
      return false;
    }

    if (value === SnapMode.BAR || value === SnapMode.BEAT) {
      return true;
    }
  };

  function setSnapValue(value) {
    dispatch<SetSnapModeAction>({
      type: SET_SNAP_MODE,
      snapMode: value
    });
  }

  // this will cycle through the available snap options
  const onSnapButtonClick = value => {
    const currentSnapValue = snap;
    const nextSnapValue =
      snapOptions[
        (snapOptions.indexOf(currentSnapValue) + 1) % snapOptions.length
      ];
    setSnapValue(nextSnapValue);
  };

  const onDropdownVisible = open => {
    setDropdownActive(open);
  };

  if (editor.tightLayout) {
    return (
      <Tooltip
        title={
          snap === SnapMode.NONE
            ? `No snapping`
            : `Snapping to ${snap.toLowerCase()}`
        }
        placement="bottom"
        getPopupContainer={trigger => trigger}
        align={{ offset: [0, 0] }}
      >
        <Container className="snap-button tight">
          <SnapIcon className="snap-icon" onClick={onSnapButtonClick}>
            {snap === SnapMode.NONE ? (
              <SnapMagnetNone />
            ) : snap === SnapMode.BAR ? (
              <SnapMagnetBar />
            ) : (
              <SnapMagnetBeat />
            )}
          </SnapIcon>
        </Container>
      </Tooltip>
    );
  } else {
    return (
      <Container className={`thicc ${dropdownActive ? "active" : null}`}>
        <SnapIcon className="snap-icon" onClick={onSnapButtonClick}>
          {snap === SnapMode.NONE ? (
            <SnapMagnetNone />
          ) : snap === SnapMode.BAR ? (
            <SnapMagnetBar />
          ) : (
            <SnapMagnetBeat />
          )}
        </SnapIcon>
        <SnapSelect
          value={snap}
          onSelect={setSnapValue}
          onDropdownVisibleChange={onDropdownVisible}
          showArrow={true}
          dropdownMatchSelectWidth={false}
          getPopupContainer={trigger => trigger}
        >
          {snapOptions.map(value => (
            <SnapSelect.Option
              key={value}
              value={value}
              disabled={shouldBeDisabled(value)}
            >
              {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
            </SnapSelect.Option>
          ))}
        </SnapSelect>
      </Container>
    );
  }
}
