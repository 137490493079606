import React, { useState, useEffect, useContext } from "react";
import { Alert, Input } from "antd";
import { useSelector } from "react-redux";
import { Container, FlexRow } from "./styles";
import AntModal from "../../../components/AntModal";
import { Button } from "../Button";
import { lightInputStyle } from "../../../globalStyles";
import { CustomMixPicker } from "../CustomMixPicker";
import { MainStore, Project } from "../../store";
import { EditorContext } from "../../context/EditorContext/EditorProvider";

export const LoadModal = ({ loadModalState, saveModalState }) => {
  const [selectedCustomMix, setSelectedCustomMix] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [customMixPickerData, setCustomMixPickerData] = useState([]);
  const [loadWarningMessage, setLoadWarningMessage] = useState("");

  const currentDataHash = useSelector<MainStore, string>(
    state => state.project.customMixInfo.currentDataHash
  );
  const lastSaveDataHash = useSelector<MainStore, string>(
    state => state.project.customMixInfo.version.editorDataDumpHash
  );

  const editor = useContext(EditorContext);

  useEffect(() => {
    if (currentDataHash !== lastSaveDataHash) {
      setLoadWarningMessage(
        "You have unsaved changes to your custom mix. Please save prior to loading or your changes will be lost."
      );
    } else {
      setLoadWarningMessage("");
    }
  }, [selectedCustomMix, currentDataHash, lastSaveDataHash]);

  const loadCustomMix = () => {
    if (selectedCustomMix && selectedProject) {
      const customMixId = selectedCustomMix.key;
      editor.requestCustomMixIdOpen(customMixId, undefined, customMixLoaded);
    }
  };

  const customMixLoaded = (customMix: Project) => {
    closeAndResetModal();
  };

  const closeAndResetModal = () => {
    setSelectedCustomMix(null);
    setCustomMixPickerData(null);
    loadModalState.close();
  };

  const spawnSaveModal = () => {
    closeAndResetModal();
    saveModalState.open();
  };

  return (
    <>
      <AntModal modalState={loadModalState} close={closeAndResetModal}>
        <Container>
          <div id="load-title">
            <h2>Load Custom Mix</h2>
          </div>
          {customMixPickerData !== null && customMixPickerData.length === 0 && (
            <div id="no-custom-mixes">
              <p>
                There are no custom mixes saved in your projects or your team
                members' projects.
              </p>
              <Button onClick={spawnSaveModal}>Save Custom Mix</Button>
            </div>
          )}
          <CustomMixPicker
            onDataLoaded={setCustomMixPickerData}
            onSelectProject={setSelectedProject}
            onSelectCustomMix={setSelectedCustomMix}
            titleQuery={null}
            highlightDirs={false}
          ></CustomMixPicker>
          {loadWarningMessage && (
            <Alert
              message={"Warning"}
              description={loadWarningMessage}
              type="warning"
              showIcon
            />
          )}
          {customMixPickerData !== null && selectedCustomMix !== null && (
            <FlexRow>
              <div className="input-container">
                <div id="load-modal-custom-mix-name-label">Custom Mix Name</div>
                <Input
                  value={selectedCustomMix ? selectedCustomMix.title : ""}
                  style={{ ...lightInputStyle }}
                  disabled={true}
                />
              </div>
              <FlexRow>
                <Button
                  secondary
                  width="100"
                  styles="margin-right: 14px;"
                  onClick={loadModalState.close}
                >
                  Cancel
                </Button>
                <Button width="100" onClick={loadCustomMix}>
                  Load
                </Button>
              </FlexRow>
            </FlexRow>
          )}
        </Container>
      </AntModal>
    </>
  );
};
