import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  from{
    transform:translateX(300px)
  }
  to{
    transform:translateX(0)
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.backgroundDark};
  animation: ${slideIn} 0.7s ease-in-out;

  .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;

    p {
      color: ${props => props.theme.colorLight};
      font-size: ${props => props.theme.p1r.fs};
    }
    
  .break-line {
    opacity: 0.2;
    width: 85%;
    margin: auto;
    margin-bottom: 14px;
    margin-top: -4px;
    background-color: ${props => props.theme.textLight};
    height: 1.5px;
  }
    * {
    margin-bottom: 14px;
  }
`;
