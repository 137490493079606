import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.backgroundDark};

  .editor-drawer {
    transform: translateY(0) !important;

    .ant-drawer-content-wrapper {
      transition-property: height, transform;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.7, 0.3, 0.1, 1);
    }

    .ant-drawer-wrapper-body {
      overflow-y: auto;

      .ant-drawer-body {
        height: 100%;
      }

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background: ${({ theme }) => theme.editor.backgroundDark};
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.editor.backgroundHighlight};
        border-radius: 20px;
        border: 2px solid ${({ theme }) => theme.editor.backgroundDark};
      }
    }
  }
`;
