import styled from "styled-components";
import { Select } from "antd";

export const Container = styled.div`
  color: ${({ theme }) => theme.editor.textLight};
  background-color: ${({ theme }) => theme.editor.backgroundDark};
  width: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.25s ease-in-out;

  &&.tight {
    background-color: ${({ theme }) => theme.editor.backgroundLight};
    height: 100%;
    padding: 0 4px;

    && > .snap-icon {
      justify-content: center;

      svg path {
        fill: ${({ theme }) => theme.editor.textLight};
      }
    }

    .ant-tooltip-inner {
      bottom: 0;
    }
  }

  &&.thicc {
    height: 24px;
    width: 30px;
    overflow-x: clip;
    padding: 2px 0 2px 8px;
    margin-right: 4px;

    &&:hover {
      background-color: ${({ theme }) => theme.editor.backgroundLight};
      width: 102px;
      overflow-x: visible;

      .snap-icon > svg * {
        fill: ${({ theme }) => theme.editor.textLight};
      }
    }

    &&.active {
      width: 102px;
      overflow-x: visible;
      background-color: ${({ theme }) => theme.editor.backgroundLight};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &&.active > .snap-icon {
      transform: rotate(90deg);
      fill: ${({ theme }) => theme.editor.textLight};
    }
  }

  &&.active > .snap-icon > svg * {
    fill: ${({ theme }) => theme.editor.textLight};
  }
`;

export const SnapIcon = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  width: 22px;
  transition: all 0.25s ease-in-out;

  && > svg * {
    fill: ${({ theme }) => theme.editor.textMedium};
  }
`;

export const SnapSelect = styled(Select)`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontMedium};
  background-color: transparent;
  height: 22px;

  .ant-select-selection:focus,
  .ant-select-selection:active {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-select-arrow {
    margin-top: -3px !important;
  }

  .ant-select-arrow > * {
    line-height: 5px !important;
  }

  .ant-select-arrow, .ant-select-arrow * {
    width: 10px;
    height: 6px;
  }

  .ant-select-arrow > i > svg {
      background: url(${require("../../../assets/select-dropdown-filled.svg")})
        no-repeat 0% center !important;
      fill: ${({ theme }) => theme.textLight};
    }
  }

  .ant-select-arrow > i > svg > path {
    display: none;
  }

  .ant-select-selection__rendered {
    padding-right: 15px;
    line-height: 20px;
  }

  .ant-select-selection.ant-select-selection--single {
    display: block;
    width: 80px;
    background-color: transparent;
    color: ${({ theme }) => theme.editor.textLight};
    height: 22px;
  }

  .ant-select-dropdown {
    min-width: 102px !important;
    left: -22px !important;
    top: 22px !important;
  }

  .ant-select-dropdown > div {
    border-radius: ${({ theme }) => theme.borderRadiusContainers};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .ant-select-dropdown-menu-item {
    background-color: ${({ theme }) => theme.editor.backgroundDark} !important;
    color: ${({ theme }) => theme.editor.textLight} !important;
  }

  .ant-select-dropdown-menu-item-selected {
    background-color: ${({ theme }) =>
      theme.editor.backgroundHighlight} !important;
  }

  .ant-select-dropdown-menu-item-active {
    background-color: ${({ theme }) =>
      theme.editor.backgroundHighlight} !important;
  }

  li {
    line-height: 16px;
  }
`;
