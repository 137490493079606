import React from "react";
import { useHistory } from "react-router-dom";
import { useSearch } from "../../context/SearchContext/SearchProvider";

import Aggregate from "../Aggregate";
import ClosableTag from "../ClosableTag";

import { convertToFrontend } from "../../utils/rangeConversion";

const AggregateView = ({ aggregate }) => {
  const search = useSearch();
  const history = useHistory();

  const isBPMorLength = filterName => {
    return filterName === "BPM" || filterName === "Length";
  };

  return (
    <>
      <div className="aggregate-tag">
        <Aggregate
          aggregate={aggregate}
          name={aggregate.aggregateName}
          type="aggregate"
        />
      </div>

      {aggregate.filters.map((item, index) => {
        if (item.hidden) return null;
        if (item.filterType !== "range") {
          return (
            <div
              className={`filter this-is-an-aggregate-filter ${index ===
                aggregate.filters.length - 1 && "last-filter"}`}
              key={item.filterValue}
            >
              <ClosableTag
                onSearchFilter={true}
                onClose={() => {
                  search.removeFilter(
                    item.filterValue,
                    "selectable",
                    aggregate
                  );
                  if (item.filterName === "track") {
                    history.push("/browse");
                  }
                }}
                range={[1, 5]}
                isActive={true}
                isKey={item.filterName === "key" ? true : false}
                text={item.filterValue}
              />
            </div>
          );
        }

        return (
          <div
            className={`filter this-is-an-aggregate-filter ${index ===
              aggregate.filters.length - 1 && "last-filter"}`}
            key={item.filterName}
          >
            <ClosableTag
              onSearchFilter={true}
              onClose={() => {
                search.removeFilter(item.filterName, "range", aggregate);
              }}
              range={
                isBPMorLength(item.filterName)
                  ? [item.min, item.max]
                  : [convertToFrontend(item.min), convertToFrontend(item.max)]
              }
              text={item.filterName}
              minWidth={124}
              isActive={true}
              background={
                item.filterName.toLowerCase() === "duration" ||
                item.filterName === "Length" ||
                item.filterName === "BPM"
                  ? "#5D95AA"
                  : "#363636"
              }
            />
          </div>
        );
      })}
    </>
  );
};

export default AggregateView;
