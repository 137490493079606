import React, { Component, useContext } from "react";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { fragments } from "../utils/fragments";
import Spinner from "../components/Spinner";
import { quickFilters as allAggregates } from "../utils/quickFilters";
import { convertToFrontend } from "../utils/rangeConversion";
import { newTagFilter } from "../components/Tag/newTagFilter";
import { getCustomMix, getTrackInfo } from "../editor/api/WebAPI";
import { Link } from "react-router-dom";

export const AuthContext = React.createContext();

const poolData = {
  UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};
const userPool = new CognitoUserPool(poolData);

const GET_USER_QUERY = gql`
  query {
    currentUser {
      ...UserData
    }
  }
  ${fragments.userQuery}
`;

export const FETCH_TRACK_MANY_TO_MANY_RELATIONS = gql`
  query {
    tags
    instruments
    thematicCategories
  }
`;

class AuthProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: { role: "loading" },
      aggregates: [],
      allTags: [],
      allInstruments: [],
      allThematicCategories: []
    };
  }

  async componentDidMount() {
    await this.checkAuthentication();
  }

  checkAuthentication = async () => {
    this.setState({ user: { role: "loading" } });
    await this.fetchTrackManyToManyRelations();
    let cognitoUser = userPool.getCurrentUser();
    if (cognitoUser === null) {
      this.setState({
        checkingAuth: false,
        user: { role: "guest" }
      });
      return;
    }

    cognitoUser.getSession(async (err, session) => {
      if (err) {
        this.setState({ checkingAuth: false, user: { role: "guest" } });
        return;
      }
      if (!session || !session.isValid()) {
        this.setState({ checkingAuth: false, user: { role: "guest" } });
        return;
      }
    });
    this.fetchUser();
  };

  fetchUser = async () => {
    try {
      const user = await this.props.client.query({
        query: GET_USER_QUERY
      });

      this.setState({
        user: user.data.currentUser
          ? { ...user.data.currentUser, role: "private" }
          : { role: "guest" }
      });
    } catch (err) {
      this.setState({ checkingAuth: false });
    }
  };

  fetchTrackManyToManyRelations = async () => {
    try {
      const { data } = await this.props.client.query({
        query: FETCH_TRACK_MANY_TO_MANY_RELATIONS
      });
      const aggregatesConverted = allAggregates.map(item => {
        let updatedData = {};
        Object.keys(item).forEach(key => {
          if (key !== "aggregateName") {
            if (key === "ranges") {
              item.ranges.forEach(range => {
                updatedData = {
                  ...updatedData,
                  [range.name]: {
                    min: convertToFrontend(range.min),
                    max: convertToFrontend(range.max)
                  }
                };
              });
            } else if (key === "instruments" || key === "tags") {
              item[key].forEach(item => {
                updatedData = {
                  ...updatedData,
                  [item]: {
                    filterName: key,
                    filterValue: item,
                    filterType: "selectable"
                  }
                };
              });
            } else if (key === "BPM" || key === "Length") {
              updatedData = {
                ...updatedData,
                [key]: {
                  filterType: "range",
                  filterName: key,
                  min: item[key].min,
                  max: item[key].max
                }
              };
            }
          }
        });
        return { aggregateName: item.aggregateName, ...updatedData };
      });

      this.setState({
        aggregates: aggregatesConverted,
        allTags: [...data.tags, { ...newTagFilter }],
        allInstruments: data.instruments,
        allThematicCategories: data.thematicCategories
      });
    } catch (err) {
      console.log(err);
    }
  };

  isUserPrivate = () => this.state.user.role === "private";

  setUser = data => {
    this.setState({
      checkingAuth: false,
      user: { ...this.state.user, ...data }
    });
  };

  RemoveUser = data => {
    this.setState({
      user: { role: "guest" }
    });
  };

  /*
   * Checks whether a user has paid for access to this track, either
   * through a blanket subscription or a one-off track license
   *
   * Parameters:
   *  track (Track object): Track
   *  projectId (string or null): Project::id
   *    if = null, checks whether any project could access track editor
   *
   * Returns:
   *  (bool): whether access to track granted for user
   */
  hasPaidTrackAccess = (track, projectId = null) => {
    const user = this.state.user;

    // check User permissions
    if (user.role !== "private") {
      return {
        granted: false,
        track: track,
        accessDeniedMsg: (
          <>
            You need to <Link to="/signup">create an account</Link> or{" "}
            <Link to="/signin">sign in</Link> to access this content.
          </>
        )
      };
    } else if (
      user.adminUser ||
      user.editorAlpha ||
      user.fullDownloadAccess ||
      (user.team && user.team.enterprise)
    ) {
      return { granted: true, track: track };
    }

    // check whether user's projects can give access to track
    // limit permissions search to specific project if provided
    let trackLicensed = false;
    const userProjects = projectId
      ? user.projects.filter(project => project.id === projectId)
      : user.projects;
    userProjects.forEach(project => {
      if (
        project.downloadAccess ||
        project.subscription ||
        (project.team && project.team.enterprise)
      ) {
        trackLicensed = true;
        return;
      } else if (project.purchasedTracks) {
        const projectHasTrackLicense = project.purchasedTracks.some(
          purchasedTrackId => {
            return purchasedTrackId === track.id;
          }
        );
        if (projectHasTrackLicense) {
          trackLicensed = true;
          return;
        }
      }
    });

    if (trackLicensed) {
      return { granted: trackLicensed, track: track };
    }
    return {
      granted: false,
      track: track,
      accessDeniedMsg: (
        <>
          You need to purchase a license for this track or
          <Link to="/subscription">subscribe</Link> for a blank license to
          access this content.
        </>
      )
    };
  };

  /*
   * Checks whether a user has access to the stem editor for a given track
   * either through user permissions, project permissions, or team permissions
   *
   * Parameters:
   *  track (Track object): Track
   *  projectId (string or null): Project::id
   *    if = null, checks whether any project could access track editor
   *
   * Returns:
   *  granted (bool): whether access to content granted for user
   *  track (Track object): the provided track object appended for reference
   *  accessDeniedMsg (string): message detailing why access denied
   *    undefined if access granted
   */
  canAccessTrackEditor = (track, projectId = null) => {
    // check Track permissions: admins bypass these track permission checks
    if (!track.editorOpenable || track.editorDataDump === null) {
      return {
        granted: false,
        track: track,
        accessDeniedMsg:
          "The editor is not available for this track. Check back soon!"
      };
    }
    // unauthenticated users can access if editorOpenable && editorDataDump && freeStemFiles
    if (track.freeStemFiles) {
      return { granted: true, track: track };
    }

    const trackLicensed = this.hasPaidTrackAccess(track, projectId);
    return trackLicensed.granted
      ? { granted: true, track: track }
      : {
          granted: false,
          track: track,
          accessDeniedMsg:
            "You don't have a license for this track and therefore you can't access the track editor with its stems."
        };
  };

  /*
   * Checks whether a user has access to the stem editor for a given track
   * either through user permissions, project permissions, or team permissions
   *
   * Parameters:
   *  trackId (string): Track::id
   *  projectId (string or null): Project::id
   *    if = null, checks whether any project could access track editor
   *
   * Returns:
   *  granted (bool): whether access to content granted for user
   *  track (Track object): the track object queried from api appended for reference
   *  accessDeniedMsg (string): message detailing why access denied
   *    undefined if access granted
   */
  canAccessTrackEditorFromId = async (trackId, projectId = null) => {
    const trackData = await getTrackInfo(trackId);
    return this.canAccessTrackEditor(trackData, projectId);
  };

  /*
   * Checks whether a user has access to the custom mix and track
   * either through user permissions, project permissions, or team permissions
   *
   * Parameters:
   *  customMix (CustomMix object): CustomMix
   *
   * Returns:
   *  granted (bool): whether access to content granted for user
   *  track (Track object): the corresponding track object
   *  customMix (CustomMix object): the provided customMix object appended for reference
   *  accessDeniedMsg (string): message detailing why access denied
   *    undefined if access granted
   */
  canAccessCustomMix = async customMix => {
    const user = this.state.user;
    const trackId = customMix.customMixInfo.trackId;
    const projectId = customMix.customMixInfo.projectId;
    const trackAccessResult = await this.canAccessTrackEditorFromId(
      trackId,
      projectId
    );
    // continue on and check whether user can access this custom mix through linked project
    const userCanAccessProject =
      user.projects && user.projects.some(project => project.id === projectId);
    // explicitly allow custommixes created by a user to be openable by a user, bypassing all paid permissions
    const userCanAccessCustomMix =
      (trackAccessResult.granted ||
        customMix.customMixInfo.user.id === user.id) &&
      userCanAccessProject;
    return {
      granted: userCanAccessCustomMix,
      customMix: customMix,
      track: trackAccessResult.track,
      accessDeniedMsg: !trackAccessResult.granted
        ? trackAccessResult.accessDeniedMsg
        : "You don't have access to the project that contains this custom mix."
    };
  };

  /*
   * Checks whether a user has access to the custom mix and track
   * either through user permissions, project permissions, or team permissions
   *
   * Parameters:
   *  customMixId (UUID string): CustomMix ID
   *
   * Returns:
   *  granted (bool): whether access to content granted for user
   *  track (Track object): the corresponding track object
   *  customMix (CustomMix object): the customMix object queried from API for reference
   *  accessDeniedMsg (string): message detailing why access denied
   *    undefined if access granted
   */
  canAccessCustomMixFromId = async customMixId => {
    const customMixData = await getCustomMix(customMixId);
    return this.canAccessCustomMix(customMixData);
  };

  render() {
    if (this.state.user.role !== "public") {
      return (
        <AuthContext.Provider
          value={{
            user: this.state.user,
            isUserPrivate: this.isUserPrivate,
            checkAuthentication: this.checkAuthentication,
            setUser: this.setUser,
            RemoveUser: this.RemoveUser,
            fetchUser: this.fetchUser,
            hasPaidTrackAccess: this.hasPaidTrackAccess,
            canAccessTrackEditor: this.canAccessTrackEditor,
            canAccessTrackEditorFromId: this.canAccessTrackEditorFromId,
            canAccessCustomMix: this.canAccessCustomMix,
            canAccessCustomMixFromId: this.canAccessCustomMixFromId,
            allTags: this.state.allTags,
            allInstruments: this.state.allInstruments,
            allThematicCategories: this.state.allThematicCategories,
            aggregates: this.state.aggregates
          }}
        >
          {this.props.children}
        </AuthContext.Provider>
      );
    } else {
      return <Spinner />;
    }
  }
}

export default withApollo(AuthProvider);

export const useAuth = () => useContext(AuthContext);
