import * as React from "react";
import { theme } from "../globalStyles";

export const Volume = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5949 1.61395V17.8861C12.5949 18.528 12.0739 19.0483 11.4313 19.0483H10.7449C10.4396 19.0471 10.1471 18.926 9.93041 18.7113L5.47415 14.2597C4.82018 13.6056 3.93294 13.2377 3.0075 13.2369C1.87654 13.2369 0.959717 12.321 0.959717 11.1912V8.30876C0.959717 7.17899 1.87654 6.26312 3.0075 6.26312C3.93294 6.26231 4.82018 5.89441 5.47415 5.24031L9.93041 0.788725C10.1471 0.573998 10.4396 0.452944 10.7449 0.45166H11.4313C12.0739 0.45166 12.5949 0.972036 12.5949 1.61395ZM19.5762 3.6247C19.4643 3.51153 19.3159 3.44154 19.1573 3.42711C18.9958 3.42299 18.8399 3.48615 18.7268 3.60145L17.9007 4.42668C17.6938 4.64958 17.6938 4.99414 17.9007 5.21704C20.1353 7.82679 20.1353 11.6732 17.9007 14.2829C17.6938 14.5058 17.6938 14.8504 17.9007 15.0733L18.7268 15.8985C18.8399 16.0138 18.9958 16.077 19.1573 16.0729C19.3165 16.0605 19.4655 15.9902 19.5762 15.8753C22.6496 12.3688 22.6496 7.13115 19.5762 3.6247ZM15.4455 6.90242C15.5665 6.78468 15.7305 6.72166 15.8993 6.72807C16.0672 6.7479 16.2212 6.83106 16.3298 6.96053C17.5709 8.61357 17.5709 10.8865 16.3298 12.5395C16.2233 12.6698 16.0673 12.7498 15.8993 12.7604C15.732 12.7713 15.5677 12.7124 15.4455 12.5977L14.6194 11.7608C14.4211 11.5673 14.387 11.2608 14.538 11.0286C15.0494 10.2528 15.0494 9.2473 14.538 8.47151C14.387 8.23923 14.4211 7.93278 14.6194 7.73927L15.4455 6.90242Z"
      fill={theme.editor.iconLight}
    />
  </svg>
);
