import styled from "styled-components";
import { PlaybackState } from "../../../store";

export const Container = styled.div`
  position: absolute;
  background-color: white;
  width: 2px;
  height: 100%;
  top: 0;
  z-index: 10;
  ${({ playbackState }) =>
    playbackState === PlaybackState.PAUSED &&
    "transition: left 0.1s ease-in-out;"};
`;
