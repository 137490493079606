import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StateButton = styled.div`
  margin: 0 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`;
