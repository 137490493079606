import gql from "graphql-tag";
import { fragments } from "../../../utils/fragments";

export const GET_CUSTOM_ORDER = gql`
  query Order($id: ID!) {
    order(id: $id) {
      id
      user
      licenseType
      level
      status
      project {
        id
        name
        url
        description
      }
      discount {
        id
        percent
      }
      cartItems {
        price
        album {
          id
          price
          name
          thumbnail
          tracks {
            id
          }
        }
        track {
          ...TrackData
        }
      }
    }
  }
  ${fragments.trackQuery}
`;

export const GET_CUSTOM_SUBSCRIPTION = gql`
  query GetSubscription($id: ID!) {
    getSubscription(id: $id) {
      id
      user
      planId
      level
      licenseType
      status
      customLicenseStatement
      summary
      project {
        id
        name
        url
        description
      }
      price
      interval
      intervalCount
    }
  }
`;

export const GET_SUBSCRIPTION_FEE = gql`
  query GetPrice {
    getPrice {
      planId
      price
      license
      interval
      intervalCount
    }
  }
`;
