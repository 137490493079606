import * as React from "react";
import { useDispatch } from "react-redux";
import { IconButton } from "../Buttons/IconButton";
import { Dropdown } from "antd";
import { Container, LaneMenu, LaneMenuItem } from "./styles";
import { Hamburger } from "../../../assets/Hamburger";
import {
  DeleteLaneAction,
  DELETE_LANE,
  DuplicateLaneAction,
  DUPLICATE_LANE
} from "../../actions/actionTypes";

export function LaneMenuControls({ laneId, startLaneRenameMode }) {
  const dispatch = useDispatch();

  const deleteLane = () => {
    dispatch<DeleteLaneAction>({
      type: DELETE_LANE,
      laneId: laneId
    });
  };

  const onLaneMenuClick = ({ key }) => {
    switch (key) {
      case "rename":
        startLaneRenameMode();
        break;
      case "delete":
        deleteLane();
        break;
      case "duplicate":
        dispatch<DuplicateLaneAction>({
          type: DUPLICATE_LANE,
          laneId: laneId
        });
        break;
    }
  };

  const LaneMenuDropdown = (
    <LaneMenu onClick={onLaneMenuClick}>
      <LaneMenuItem key="rename">Rename</LaneMenuItem>
      <LaneMenuItem key="delete">Delete</LaneMenuItem>
      <LaneMenuItem key="duplicate">Duplicate</LaneMenuItem>
    </LaneMenu>
  );

  return (
    <Container>
      <Dropdown
        overlay={LaneMenuDropdown}
        placement="bottomLeft"
        getPopupContainer={triggerNode => triggerNode}
        trigger={["click"]}
      >
        <IconButton
          className="lane-menu-icon hide-no-hover"
          svgWidth="16px"
          svgHeight="16px"
        >
          <Hamburger />
        </IconButton>
      </Dropdown>
    </Container>
  );
}
