import { createGlobalStyle, css } from "styled-components";

// IMPORTANT: please don't hard code
// colors,font-sizes,font-weights, and line-heights.
// Instead use the below values as a prop.
// Everything here is organized as the design.
export const lightTheme = {};

export const theme = {
  // colors
  highlight: "#5D95AA",
  highlightDark: "#2B6A9D",
  highlightAlt: "#5E5DAA",
  subscribeAlt: "#9F6CB1",
  subscribe: "#AA65AB",
  backgroundLight: "#282828",
  backgroundDark: "#1F1F1F",
  backgroundTag: "#363636",
  backgroundTag2: "#484848",
  grey1: "#D5D5DC",
  grey2: "#979797",
  grey3: "#606060",
  grey4: "#484848",
  grey5: "#2E2E2E",
  link: "#cccccc",
  textLight: "#EEEEEE", // default p tag color
  textDark: "#979797",
  textGrey: "#858E97",
  textExtraGrey: "#414141",
  white: "#D5D5DC",
  realWhite: "#EEEEEE",
  whiteTransparent: "rgba(238, 238, 238, 0.4)",
  black: "#2E2E2E",
  success: "#3DD598",
  dangerTwo: "#EB5757",
  danger: "#FC5A5A",
  formFont:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
  borderRadius: "16px",
  borderRadiusContainers: "5px",
  boxShadow: "2px 0px 8px rgba(40, 40, 40, 0.79)",
  boxShadowBottom: "-4px 8px 8px rgba(40, 40, 40, 0.79)",
  mobile: "799px",
  mobileBreakpoint: "800px",
  tabletBreakpoint: "1000px",
  desktopBrowseBreakpoint: "1351",
  desktopBreakpoint: "1300px",
  fontLight: "AvenirLTPro-Light",
  fontMedium: "AvenirLTPro-Medium",
  fontHeavy: "AvenirLTPro-Heavy",

  editor: {
    backgroundDark: "#1F1F1F",
    backgroundMedium: "#282828",
    backgroundLight: "#363636",
    backgroundHighlight: "#4E4E4E",
    backgroundSuperHighlight: "#5A5A5A",
    backgroundSuperDuperHighlight: "#626262",

    // any icons should use the text properties
    textDark: "#727272",
    textMedium: "#979797",
    textLight: "#D8D8D8",

    iconLight: "#D8D8D8",
    iconMedium: "#363636",
    iconDark: "",

    highlight: "#5D95AA",
    highlightDark: "#465C64",

    success: "#3DD598",
    danger: "#EB5757",

    buttonRadius: "16px",
    circleRadius: "50%",
    containerRadius: "3px",
    headerHeight: "96px",
    editorHeaderHeight: "110px",
    editorHeaderHeightNoPx: "110",
    rulerHeight: "30px",
    rulerHeightNoPx: "30",
    laneControlsW: "240px",
    laneControlsWNoPx: "240",
    tightLaneControlsW: "380px",
    tightLaneControlsWNoPx: "380",
    laneHeight: "80px",
    laneHeightNoPx: "80",
    tightLaneHeight: "60px",
    tightLaneHeightNoPx: "60"
  },

  // ff: font-family, fs: font-size, fw: font-weight

  /******HEADERS*****/
  h1: { ff: "AvenirLTPro-Heavy", fs: "32px", letterSpacing: "0.1px" },
  h2: { ff: "AvenirLTPro-Heavy", fs: "24px", letterSpacing: "0.1px" },
  h3: {
    ff: "AvenirLTPro-Heavy",
    fs: "18px",
    letterSpacing: "0px",
    fw: 800
  },

  // h1 variations
  h1b: { fs: "24px", fw: 800 }, // default for h1

  /****PARAGRAPHS***/
  //    P1 Variations
  p1r: { ff: "AvenirLTPro-Roman", fs: "18px", letterSpacing: "0.1px" },
  p1m: { ff: "AvenirLTPro-Medium", fs: "18px", letterSpacing: "0.1px" },
  p1b: {
    ff: "AvenirLTPro-Heavy",
    fs: "18px",
    letterSpacing: "0.1px",
    fw: 800
  },

  //    P2 Variations
  p2r: { ff: "AvenirLTPro-Roman", fs: "16px", letterSpacing: "0.1px" },
  p2m: {
    ff: "AvenirLTPro-Medium",
    fs: "17px",
    letterSpacing: "0.1px",
    fw: 500
  },
  p2b: {
    ff: "AvenirLTPro-Heavy",
    fs: "16px",
    letterSpacing: "0.1px",
    fw: 800
  },

  //    P3 Variations
  p3l: { ff: "AvenirLTPro-Light", fs: "14px", letterSpacing: "0px" },
  p3r: { ff: "AvenirLTPro-Roman", fs: "14px", letterSpacing: "0px" },
  p3m: { ff: "AvenirLTPro-Medium", fs: "14px", letterSpacing: "0px" },
  p3b: {
    ff: "AvenirLTPro-Heavy",
    fs: "14px",
    letterSpacing: "0px",
    fw: 800
  },

  //    P4 Variations
  p4r: { ff: "AvenirLTPro-Roman", fs: "12px", letterSpacing: "0px" },
  p4m: { ff: "AvenirLTPro-Medium", fs: "12px", letterSpacing: "0px" },
  p4b: {
    ff: "AvenirLTPro-Heavy",
    fs: "12px",
    letterSpacing: "0px",
    fw: 800
  },

  p2: { fs: "16px", fw: "normal " },
  p3: { fs: "14px", fw: "normal" }, // default p tag props
  p4: { fs: "12px", fw: "normal" },
  // LINE HEIGHT VARIATIONS

  lineHeightS: "16px",
  lineHeightM: "19px", // default for p tags
  lineHeightL: "24px", // default for h tags

  // Padding Variations
  paddingS: "10px",
  paddingM: "16px",
  paddingL: "24px",
  paddingContainersS: "24px",
  paddingContainersM: "40px",
  checkoutContainerMargin: "30px"
};

export const GlobalStyle = createGlobalStyle`
  html { 
    font-family: ${props => props.theme.p2r.ff};     
    background-color:${props => props.theme.bg1}; 
  }
    
  @supports (font-variation-settings: normal) {
    html {  
        box-sizing: border-box;
    
    }
  }

    /*
   border width also accounts for the box width.
*/
*,
*:before,
*:after {
  box-sizing: inherit;
}




    body{
        margin: 0px;
        padding: 0px;
        font-family: ${theme.p2r.ff};     
        letter-spacing: 0.1px;
        color: ${theme.textLight};
        background-color:${theme.backgroundLight};


    }

    h1, h2, h3, h4, h5, h6, p {
        padding: 0px;
        font-family: ${
          theme.h1.ff
        }, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        word-break: break-word;
       
    }

    h1, h2, h3, h4, h5, h6 {
        line-height: 24px;
    }

    h1 {
        font-size: ${theme.h1.fs};
        font-family: ${theme.h1.ff};
        color: ${theme.textLight};
    }

    h3 {
        font-family: ${theme.h3.ff};
        font-size: ${theme.h3.fs};
    }

    p {
        margin-bottom:0px ;
        padding:0px;
        font-family: ${
          theme.p3r.ff
        }, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        font-size: ${theme.p3r.fs};
        line-height: ${theme.lineHeightM};
        color: ${theme.textLight};;
    }

    button {
        border: none;
        outline: none;
        cursor: pointer;
        /* font-family: ${theme.p3r.ff}; */
        font-size: ${theme.p3r.fs};  

    }

    a {
      text-decoration: none;
    }


    /* Text Tag Variations */
    .p1b{
      font-size: ${theme.p1b.fs};
      font-family: ${theme.p1b.ff};
    }

    .p2b {
      font-size: ${theme.p2b.fs};
      font-family: ${theme.p2b.ff};
     }

     .p4m {
        font-size: ${theme.p4m.fs};
        font-family: ${theme.p4m.ff};
      }
      .p4b {
        font-size: ${theme.p4b.fs};
        font-family: ${theme.p4b.ff};
      }

      .p3m{
        font-size: ${theme.p3m.fs};
        font-family: ${theme.p3m.ff};
        line-height: 19px;
      }

      .p3r {
        font-size: ${theme.p3r.fs};
        font-family: ${theme.p3r.ff}; 
      }

      .p3b{
        font-size: ${theme.p3b.fs};
        font-family: ${theme.p3b.ff}; 
      }
      
      .h2 {
        font-family: "AvenirLTPro-Heavy";
        font-size: ${theme.h2.fs};
        line-height: 25px;
      }

      .h3 {
        font-family: "AvenirLTPro-Heavy";
        font-size: ${theme.h3.fs};
        line-height: 25px;
      }

      
      .grey {
        color: ${theme.textDark};
      }

      .highlight {
        color: ${theme.highlight};

      }

      .pointer {
        cursor: pointer;
      }

      .underline{
        text-decoration: underline;
      }

      .ws-nowrap{
        white-space: nowrap;
      }

      .width-fc{
        width: fit-content;
      }

      .flex {
        display: flex; 
      }
      .align-c {
        align-items: center;
      }
    .row {
      display: flex;
      align-items: center;
    }

    .image-border-radius{
      border-radius: 5px;
    }


/***********************************************/



/* GLOBAL INPUT STYLES */
  input:-webkit-autofill, 
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    ${"" /* -webkit-transition-delay: 99999999s; */}
    -webkit-box-shadow: 0 0 0 30px #1F1F1F inset ;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.7);
    border: none;
    caret-color: white;
    transition: background-color 5000s ease-in-out 0s;

  }

  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    transition: all 0.4s;
  }
  .ant-form .ant-input::-webkit-input-placeholder {
      color: ${props => props.theme.textGrey};
    }
     .ant-form .ant-input::-moz-placeholder {
      /* Firefox 19+ */
      color: ${props => props.theme.textGrey};
    }
     .ant-form .ant-input:-ms-input-placeholder {
      /* IE 10+ */
      color: ${props => props.theme.textGrey};
    }
    .ant-form .ant-input:-moz-placeholder {
      /* Firefox 18- */
      color: ${props => props.theme.textGrey};
    }
   .ant-form .ant-input{
      border: none;
    }
    .has-error .ant-form-explain,
  .has-error .ant-form-split {
    color: ${props => props.theme.danger};
    margin-bottom: 8px;
    margin-top: 4px;
  }

  .ant-form-item-control-wrapper  .has-error .ant-input:hover input {
    border: 2px ${props => props.theme.danger} solid;
    background-color: ${props => props.theme.backgroundDark};
  }
  .ant-form-item-control-wrapper .has-error .ant-input-affix-wrapper .ant-input:hover {
    border: 2px ${props => props.theme.danger} solid;
  }

  .ant-form-item-control-wrapper .has-error .ant-form-item-children .ant-input {
    border: 1px ${props => props.theme.danger} solid;
    background-color: ${props => props.theme.backgroundDark};

  }

/*******************************************************************/


/* MODAL GLOBAL STYLES */

  .ant-modal .ant-modal-body {
    padding: 0px;
  }

  .ant-alert-warning, .ant-alert-with-description {
    padding: 15px 25px 15px 64px;
    color:${props => props.theme.textLight};
    border: 0px;
    background-color: #b79455;
  }
  
  .ant-alert-with-description .ant-alert-message {
    color:${props => props.theme.textLight};
    font-family: 'AvenirLTPro-Heavy';
  }
  .ant-alert-warning .ant-alert-icon {
    color:${props => props.theme.textLight};
  }

  .ant-modal {
    width: fit-content;
  }
  .ant-modal .ant-modal-content{
    background-color: ${props => props.theme.backgroundDark};
    max-width: max-content;
    margin: 0 auto;
  }

  .ant-modal-close-x {
    cursor: pointer;
  }

  .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
    color:${props => props.theme.textDark};
    font-family:${props => props.theme.p3r.ff};
    font-size:${props => props.theme.p3r.fs};
  }


  .has-error .ant-form-explain, .has-error .ant-form-split{
    max-width:320px;
  }

  .ant-modal-confirm-body > .anticon {
    background: url(${require("./assets/confirmation-icon.svg")}) no-repeat center;
    background-size: 18px;
    margin-top: 10px;
    > svg {
      fill: none;
    }
  }

  .ant-modal-confirm-body .ant-modal-confirm-title{
    color:${props => props.theme.textLight} !important;
    font-family:${props => props.theme.p2m.ff};
    font-size:${props => props.theme.p2m.fs};
  }
  .ant-modal-confirm .ant-modal-confirm-btns button:first-child{
    background:${props => props.theme.backgroundTag};
    color:${props => props.theme.textLight};
  }

  .ant-modal-confirm .ant-modal-confirm-btns button {
    border:none;
    border-radius: ${props => props.theme.borderRadius};
    font-family:${props => props.theme.p3b.ff};
    font-size:${props => props.theme.p3b.fs};
  }

  .ant-modal-confirm .ant-modal-confirm-btns button + button{
    background:${props => props.theme.highlight};
  }

  .ant-modal-close {
    cursor: default !important;
  }

  .ant-modal  .ant-modal-close-icon {
    color: ${theme.textLight};
    font-size: 16px;
    margin-top: 30px;
    display: none;
  }

/**********************************************/

.ant-dropdown-menu-sub {
  background-color: ${theme.editor.backgroundMedium};
}

/**************************************** */

.ant-drawer  .ant-drawer-body {
  padding: 0px;
}

  .ant-drawer .ant-drawer-wrapper-body {
    background: ${theme.backgroundDark};
  }


  .ant-drawer-content-wrapper{
    width: 420px;
  }
  
  .ant-modal-confirm .ant-modal-body {
    padding: 32px 32px 24px !important;
  }

@media (max-width: 430px ) {
  .ant-drawer-content-wrapper{
    width: 100%;
  }
}
/***************************** */


/*********  ant notification styles ******/

.ant-notification .ant-notification-notice {
  background-color: ${props => props.theme.backgroundDark} ;
}
.ant-message-notice-content {
  background: #232323 !important;
  color:${props => props.theme.textLight}
}

.ant-message-custom-content .anticon svg{
  fill:${props => props.theme.highlight};
}
.ant-notification .ant-notification-notice-with-icon .ant-notification-notice-message {
  color:  ${props => props.theme.textLight} ;
}

.ant-notification .ant-notification-close-icon{
  color:  ${props => props.theme.textLight} ;
}

/*****************************************/


/************** tool tip style*******************/

.ant-tooltip.tool-tip-class.ant-tooltip-placement-right .ant-tooltip-inner {
    max-height: calc(100vh - 110px - 96px);
    overflow-y: auto;
    width: 340px;
    padding: 0px;



  ::-webkit-scrollbar {
    width: 8px;
    opacity: 0;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    opacity: 0;
    width: 15px;
    background-color: ${props => props.theme.backgroundDark};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb { 
    background: rgba(255, 255, 255, 0.06);
    opacity: 0.6;
    border-radius: 2px;
  }
}

.ant-tooltip.tool-tip-class.ant-tooltip-placement-right .ant-tooltip-inner .tooltip-filter-container {
    background: red;
    height: 100%;
    background-color: ${props => props.theme.backgroundDark};
    width: 100%;
    padding: 24px 24px 3px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ant-tooltip.tool-tip-class.ant-tooltip-placement-right .ant-tooltip-arrow{
  display: none;
}

.ant-tooltip.tool-tip-class.large.instruments.ant-tooltip-placement-right{ 
  top: 96px !important;
  position: fixed;
}
.ant-tooltip.tool-tip-class.large.tags.ant-tooltip-placement-right{ 
  top: 96px !important;
  position: fixed;

}
.ant-tooltip.tool-tip-class.large.keys.ant-tooltip-placement-right{ 
  top: 96px !important;
  position: fixed;
}

.ant-tooltip.tool-tip-class.small.ant-tooltip-placement-right{
  position: fixed;
}

.ant-tooltip.tool-tip-class.small.ant-tooltip-placement-right .ant-tooltip-inner {
  top: 8px;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled){
  background-color:${props => props.theme.backgroundLight} !important;
}


.ant-tooltip.small.ant-tooltip-placement-right .ant-tooltip-inner{
  height: 80px;
  position: relative;
  display: block;
  overflow: hidden;
  padding: 24px;

}

.range-tooltip-browse {
  left: 188px !important;
}

.range-tooltip-setting {
  left: 416px !important; 
}

.range-tooltip  .ant-tooltip-arrow {
  width: 24px;
  height: 24px;
}

.range-tooltip  .ant-tooltip-arrow  {
  top: 0px !important;
}

.range-tooltip .ant-tooltip-arrow::before {
  width: 24px !important; 
  height: 24px !important;
  background-color: ${theme.backgroundDark};

}

.range-tooltip .ant-tooltip-inner {
  background: ${theme.backgroundDark};
  position: relative;
  top: -17px;
}

.ant-tooltip-inner {
  position: relative;
  bottom: -17px;
}

.ant-tooltip-inner, 
.ant-tooltip-arrow::before,
.ant-tooltip-inner {
  background: ${theme.backgroundDark};
}

.palette-tooltip .ant-tooltip-inner {
  background: ${theme.backgroundDark};
  bottom: -5px;
  padding: 6px 18px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .65);
}

.avatar-tooltip  .ant-tooltip-arrow {
  width: 24px;
  height: 24px;
}

.avatar-tooltip .ant-tooltip-arrow::before {
  width: 24px !important; 
  height: 24px !important;
  background-color: ${theme.backgroundDark};
  top: -18px !important;
}

.avatar-tooltip .ant-tooltip-inner {
  background: ${theme.backgroundDark};
  position: relative;
  top: -10px;
}

/************** popover style*******************/

.ant-popover-inner, .ant-popover-arrow {
  background-color: ${props => props.theme.backgroundDark} !important;
  border-top-color: ${props => props.theme.backgroundDark} !important;
  border-right-color: ${props => props.theme.backgroundDark} !important;
  border-bottom-color: ${props => props.theme.backgroundDark} !important;
  border-left-color: ${props => props.theme.backgroundDark} !important;
}

/*********************************************/

  .ant-select-dropdown.ant-select-dropdown--single.ant-select-dropdown-placement-bottomLeft {
    width: fit-content !important; 
    background:${props => props.theme.backgroundDark} !important;
  }


  .ant-select-selection--single{
    background:${props => props.theme.backgroundDark};
    border:none !important; 
    margin-top:4px;
    display:flex;
    height:100%;
    align-items:center;

    .ant-select-selection-selected-value{
      max-width: 215px;
      padding-top:0.1em;
    }
  }
  .ant-select-arrow{
    color:${props => props.theme.textLight} !important;
    top:52% !important;
  }
  .ant-select-dropdown-placement-bottomLeft{
    /* width:300px !important; */
    color:${props => props.theme.textLight}
  }

  .ant-select-dropdown-menu-item{
    color:${props => props.theme.textLight} !important;
  }

  .ant-select-dropdown-menu-item-selected{
    background-color:${props => props.theme.backgroundLight} !important;
    width:100% !important;
  }

  .ant-select-selection--single .ant-select-selection__rendered {
      margin-top: -4px !important;
  }

  .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-radius: 3px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-select-selection__rendered {
    padding-right: 15px;
    line-height: 20px;
  }

  .ant-select-selection.ant-select-selection--multiple {
    display: block;
    background-color: ${({ theme }) => theme.editor.backgroundLight};
    color: ${({ theme }) => theme.editor.textLight};
  }

  .ant-select-selection__placeholder {
    margin-top: -4px;
  }

  .ant-select-selection__choice {
    border-radius: 12px !important;
    color: ${({ theme }) => theme.editor.textLight} !important;
    border: 1px solid ${({ theme }) => theme.editor.highlightDark} !important;
    background-color: ${({ theme }) => theme.editor.highlightDark} !important;
  }

  .ant-select-selection__choice__remove {
    color: ${({ theme }) => theme.editor.textLight} !important;
  }

  .ant-select-dropdown {
    background-color: transparent;
  }

  .ant-select-dropdown > div {
    border-radius: ${({ theme }) => theme.borderRadiusContainers};
  }

  .ant-select-dropdown-menu-item {
    background-color: ${({ theme }) => theme.editor.backgroundDark} !important;
    color: ${({ theme }) => theme.editor.textLight} !important;
  }

  .ant-select-dropdown-menu-item-selected {
    background-color: ${({ theme }) => theme.editor.highlightDark} !important;
  }

  .ant-select-dropdown-menu-item-active {
    background-color: ${({ theme }) =>
      theme.editor.backgroundHighlight} !important;
  }

  .ant-calendar{
     background:${props => props.theme.backgroundDark} !important;
     color:${props => props.theme.textLight};
     width:334px;
     border:none !important;


    .ant-calendar-my-select a {
      color:${props => props.theme.textLight};
    }

    .ant-calendar-date{
      color:${props => props.theme.textLight};

      &:hover{
        background:${props => props.theme.highlight};
        color:${props => props.theme.textLight};
      }
    }
    .ant-calendar-header, .ant-calendar-input-wrap{
      border-bottom:1px solid ${props => props.theme.backgroundTag};
    }




    .ant-calendar-footer{
        border-top:1px solid ${props => props.theme.backgroundTag};
    }


    .ant-calendar-input{
      background:${props => props.theme.backgroundDark} !important;
     color:${props => props.theme.textLight};

     &:hover .anticon{
       display:none !important;
     }
    }

    .ant-calendar-today-btn{
        color:${props => props.theme.textLight};
    }

    .ant-calendar-selected-day .ant-calendar-date{
      background:${props => props.theme.highlight};
      outline:none;
    }

    .ant-calendar-selected-day .ant-calendar-date:hover{
      background:${props => props.theme.highlight}
    }


  }


   form .ant-input-number-handler-wrap{
     opacity:0 !important; 
     display:none !important;
   }

   .ant-input-number{
     display:flex;
     align-items:center;
   }

    .ant-form-item-label > label {
      color: ${props => props.theme.textLight};
      font-family:${props => props.theme.p2m.ff};
    }
    .ant-form-item-label > label::after {
      content: "";
    }

/******************************************************************************** */

  .project-select-dropdown ul {
    width: 250px;
  }

  .license-select ul{
    width:199px;
  }

/******************************************************************************** */
/***** SELECT OPTIONS *****/
.ant-select-dropdown-menu  {
  color: ${props => props.theme.textLight};
  font-size: ${props => props.theme.p3b.fs};
  font-family: ${props => props.theme.p3r.ff};
  padding:0px !important;

  li{
      display:flex;
      align-items:center;
    }
}
.ant-select-dropdown-menu-item-selected{
  font-family: ${props => props.theme.p3b.ff};
  font-weight: normal !important;

}
/******************************************************** */


.clickable-area {
    padding: 30px;
    margin: -30px;
    cursor: pointer;

  }
`;

/** Can this replace src/pages/Private/Settings/GeneralSettings/formSchema.js  ??
 * Can this replace src/components/InviteForm/schema.js ??
 * Can this replace src/components/InviteForm/index.js ??
 * They are all the same but for different components
 **/
export const inputStyle = {
  width: "100%",
  height: "40px",
  border: "none",
  borderRadius: theme.borderRadiusContainers,
  background: theme.backgroundDark,
  fontSize: "16px",
  color: theme.textDark,
  fontFamily: theme.formFont,
  padding: "4px 11px"
};

export const InputStyleStyled = css`
  width: 100%;
  height: 40px;
  border-radius: ${props => props.theme.borderRadiusContainers};
  background: ${props => props.theme.backgroundDark};
  font-size: 16px;
  color: ${props => props.theme.textDark};
  font-family: ${props => props.theme.formFont};
  padding: 4px 11px;
`;

export const lightInputStyle = {
  background: theme.backgroundTag,
  border: "none",
  borderRadius: theme.editor.containerRadius,
  color: theme.editor.textLight,
  fontFamily: theme.formFont,
  fontSize: "14px",
  padding: "4px 11px"
};
