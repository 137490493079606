import * as Tuna from "tunajs";

export class AudioMaster {
  get tuna() {
    // have to create a new Tuna everytime or run into issues with multiple contexts
    return new Tuna(this.context);
  }

  public context: AudioContext | OfflineAudioContext;
  // tuna: any;
  public t0_song: number;
  public isOffline: boolean;
  public offLineLengthSeconds: number;

  private audioCache: Map<string, AudioBuffer> = new Map();
  // Whenever user plays, need to remember actually audio context time that action was taken at
  private t0_absolute: number;

  constructor(isOffline = false, offLineLengthSeconds: number = 1) {
    this.context = isOffline
      ? new OfflineAudioContext(2, offLineLengthSeconds * 44100, 44100)
      : new AudioContext();
    this.isOffline = isOffline;
    this.offLineLengthSeconds = offLineLengthSeconds;
  }

  public saveAudio(key: string, audioBuffer: AudioBuffer) {
    this.audioCache.set(key, audioBuffer);
  }

  public copyAudioFiles(audioMaster: AudioMaster) {
    this.audioCache = audioMaster.audioCache;
  }

  public getAudio(key: string) {
    return this.audioCache.get(key);
  }

  public hasAudio(key: string) {
    return this.audioCache.has(key);
  }

  public play(time: number) {
    this.t0_song = time;
    this.t0_absolute = this.context.currentTime;
  }

  public getCurrentSongTime() {
    return this.context.currentTime - this.t0_absolute + this.t0_song;
  }

  public pause() {
    this.t0_absolute = null;
  }

  public getLastPlayAbsoluteTime() {
    return this.t0_absolute;
  }

  public isPlaying() {
    return this.t0_absolute != null;
  }
}
