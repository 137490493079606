import * as Tuna from "tunajs";
import { AudioMaster } from "../../AudioMaster";
import { EncapsulatedAudioNode } from "../EncapsulatedAudioNode";

interface StereoDelayFXParams {
  feedbackLeft?: number;
  cutoffLeft?: number;
  dryLevelLeft?: number;
  wetLevelLeft?: number;
  feedbackRight?: number;
  cutoffRight?: number;
  dryLevelRight?: number;
  wetLevelRight?: number;
  crossmix?: number;
}

/*
 * A custom audio effect that mixes down to mono and routes the mono signal through two different
 * tuna.Delay fx, one panned hard left and one panned hard right, each with their own low-pass
 * filter. The right delay output is crossmixed with the left delay to avoid a jarring extreme-pan
 * effect.
 */
export class StereoDelay extends EncapsulatedAudioNode {
  private delayLeft: Tuna.Delay;
  private delayRight: Tuna.Delay;
  private gainLeft_1MinusCrossmix: GainNode;
  private gainLeft_Crossmix: GainNode;
  private gainRight_1MinusCrossmix: GainNode;
  private gainRight_Crossmix: GainNode;

  /*
   * Construct the StereoDelay effect
   *
   * Parameters:
   *  audioMaster: contains audio context
   *  fxParams: json serialization of effect parameters and controls
   */
  constructor(audioMaster: AudioMaster, fxParams: StereoDelayFXParams) {
    super(audioMaster.context);

    const splitter = audioMaster.context.createChannelSplitter(2);
    const stereoToMonoMix = audioMaster.context.createGain();
    this.delayLeft = new audioMaster.tuna.Delay({
      feedback: fxParams.feedbackLeft || 0.3,
      cutoff: fxParams.cutoffLeft || 1500,
      dryLevel: fxParams.dryLevelLeft || 1,
      wetLevel: fxParams.wetLevelLeft || 0.5
    });
    this.delayRight = new audioMaster.tuna.Delay({
      feedback: fxParams.feedbackRight || 0.3,
      cutoff: fxParams.cutoffRight || 2000,
      dryLevel: fxParams.dryLevelRight || 1,
      wetLevel: fxParams.wetLevelRight || 0.5
    });

    // mixdown to mono
    stereoToMonoMix.gain.value = 0.5;
    this._inputConnect(splitter);
    splitter.connect(stereoToMonoMix, 0, 0);
    splitter.connect(stereoToMonoMix, 1, 0);

    // run through L&R delays w/ separate low-pass filters & wet/dry control
    stereoToMonoMix.connect(this.delayLeft);
    stereoToMonoMix.connect(this.delayRight);
    stereoToMonoMix.gain.value = 0.5;

    // crossmix: feed delay L a bit into delay R and vice versa
    this.gainLeft_1MinusCrossmix = new GainNode(audioMaster.context, {
      gain: 1 - fxParams.crossmix
    });
    this.gainLeft_Crossmix = new GainNode(audioMaster.context, {
      gain: fxParams.crossmix
    });
    this.gainRight_1MinusCrossmix = new GainNode(audioMaster.context, {
      gain: 1 - fxParams.crossmix
    });
    this.gainRight_Crossmix = new GainNode(audioMaster.context, {
      gain: fxParams.crossmix
    });
    const gainLeftOut = audioMaster.context.createGain();
    const gainRightOut = audioMaster.context.createGain();
    this.delayLeft.output.connect(this.gainLeft_Crossmix).connect(gainRightOut);
    this.delayRight.output
      .connect(this.gainRight_Crossmix)
      .connect(gainLeftOut);
    this.delayLeft.output
      .connect(this.gainLeft_1MinusCrossmix)
      .connect(gainLeftOut);
    this.delayRight.output
      .connect(this.gainRight_1MinusCrossmix)
      .connect(gainRightOut);

    // merge mono channels into stereo panned
    const merger = audioMaster.context.createChannelMerger(2);
    gainLeftOut.connect(merger, 0, 0);
    gainRightOut.connect(merger, 0, 1);

    // merger to output
    merger.connect(this.outputNode);
  }

  public get feedbackLeft() {
    return this.delayLeft.feedback;
  }

  public set feedbackLeft(value: number) {
    this.delayLeft.feedback = value;
  }

  public get cutoffLeft() {
    return this.delayLeft.cutoff;
  }

  public set cutoffLeft(value: number) {
    this.delayLeft.cutoff = value;
  }

  public get wetLevelLeft() {
    return this.delayLeft.wetLevel;
  }

  public set wetLevelLeft(value: number) {
    this.delayLeft.wetLevel = value;
  }

  public get dryLevelLeft() {
    return this.delayLeft.dryLevel;
  }

  public set dryLevelLeft(value: number) {
    this.delayLeft.dryLevel = value;
  }

  public get feedbackRight() {
    return this.delayRight.feedback;
  }

  public set feedbackRight(value: number) {
    this.delayRight.feedback = value;
  }

  public get cutoffRight() {
    return this.delayRight.cutoff;
  }

  public set cutoffRight(value: number) {
    this.delayRight.cutoff = value;
  }

  public get wetLevelRight() {
    return this.delayRight.wetLevel;
  }

  public set wetLevelRight(value: number) {
    this.delayRight.wetLevel = value;
  }

  public get dryLevelRight() {
    return this.delayRight.dryLevel;
  }

  public set dryLevelRight(value: number) {
    this.delayRight.dryLevel = value;
  }

  public get delayTimeLeft() {
    return this.delayLeft.delayTime.value;
  }

  public set delayTimeLeft(value: number) {
    this.delayLeft.delayTime.value = value / 1000;
  }

  public get delayTimeRight() {
    return this.delayRight.delayTime.value;
  }

  public set delayTimeRight(value: number) {
    this.delayRight.delayTime.value = value / 1000;
  }

  public get crossmix() {
    return this.gainLeft_Crossmix.gain.value;
  }

  /*
   * Sets the amount of crossmix between L/R channels
   *
   * Parameters:
   *  value: [0,1] E.g., 0.2 means route 20% of R channel back into L channel to stabilize
   */
  public set crossmix(value: number) {
    const clampedValue = Math.max(0, Math.min(value, 1));
    const context = this.gainLeft_Crossmix.context;
    this.gainLeft_Crossmix.gain.setTargetAtTime(
      clampedValue,
      context.currentTime,
      0.01
    );
    this.gainLeft_1MinusCrossmix.gain.setTargetAtTime(
      1 - clampedValue,
      context.currentTime,
      0.01
    );
    this.gainRight_Crossmix.gain.setTargetAtTime(
      clampedValue,
      context.currentTime,
      0.01
    );
    this.gainRight_1MinusCrossmix.gain.setTargetAtTime(
      1 - clampedValue,
      context.currentTime,
      0.01
    );
  }
}
