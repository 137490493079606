import React, { useState, useContext, useEffect } from "react";
import { Container, Dot } from "./styles";
import AntModal from "../AntModal";
import playlistsBold from "../../assets/playlists-bold.svg";
import CreateEditPlaylistModal from "../CreateEditPlaylistModal";
import gql from "graphql-tag";
import { useQuery, useMutation } from "react-apollo";
import { AuthContext } from "../../context/AuthProvider";
import { MessageContext } from "../../context/MessageContext";
import Spinner from "../Spinner";
import { Button } from "../Button";
import useModal from "../../hooks/useModal";

const GET_USER_PROJECTS = gql`
  query userProjects($user: ID!) {
    userProjects(user: $user) {
      id
      name
      playlists {
        id
        name
      }
      team {
        color
      }
    }
  }
`;

const ADD_TO_PLAYLIST = gql`
  mutation createPlaylistTrack($playlistId: ID, $trackId: ID) {
    createPlaylistTrack(data: { playlistId: $playlistId, trackId: $trackId }) {
      id
      playlist_id
    }
  }
`;

const AddToPlaylistModal = ({ modalState, track }) => {
  const [userProjects, setProjects] = useState([]);
  const createPlaylistModal = useModal();

  const auth = useContext(AuthContext);
  const { showMessage } = useContext(MessageContext);

  const { data, loading } = useQuery(GET_USER_PROJECTS, {
    fetchPolicy: "network-only",
    variables: {
      user: auth && auth.user.id
    }
  });

  useEffect(() => {
    if (data) {
      const sortedProjects = data.userProjects.sort((a, b) => {
        if (a.team) return 1;
        if (!a.team) return -1;
        return 0;
      });
      setProjects(sortedProjects);
    }
  }, [data]);

  const [
    createPlaylistTrack,
    { data: cp_Data, loading: cp_Loading, error: cpError }
  ] = useMutation(ADD_TO_PLAYLIST, {
    refetchQueries: ["playlist"]
  });

  useEffect(() => {
    if (cp_Data) {
      showMessage({
        type: "success",
        duration: 5000,
        title: "Track added to playlist",
        description: "You can access this track in the projects screen.",
        redirectLink: `/playlist/${cp_Data.createPlaylistTrack.playlist_id}`
      });
      modalState.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cp_Data]);

  useEffect(() => {
    if (cpError) {
      showMessage({
        type: "error",
        duration: 5000,
        title: "Duplicate track",
        description: "This track is already in your playlist."
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpError]);

  return (
    <AntModal modalState={modalState}>
      <Container>
        <div>
          <div className="modal-header">
            <img src={playlistsBold} alt="Playlist Icon" /> Add to Playlist
          </div>
          <Button onClick={createPlaylistModal.open} px={14}>
            + Create a Playlist
          </Button>

          {(loading || !data) && <Spinner height={"50px"} />}

          {userProjects &&
            userProjects.map(
              project =>
                project.playlists.length > 0 && (
                  <div key={project.id} className="project">
                    <div className="project-name">{project.name}</div>
                    <div>
                      {project.playlists.map(playlist => (
                        <div
                          key={playlist.id}
                          className="playlist-name"
                          onClick={() =>
                            !cp_Loading &&
                            !cp_Data &&
                            createPlaylistTrack({
                              variables: {
                                playlistId: playlist.id,
                                trackId: track.id
                              }
                            })
                          }
                        >
                          <div className="team-indicator">
                            {project.team && <Dot color={project.team.color} />}
                          </div>
                          {playlist.name}
                        </div>
                      ))}
                    </div>
                  </div>
                )
            )}
        </div>
      </Container>
      {(modalState.visible || createPlaylistModal.visible) && (
        <CreateEditPlaylistModal
          modalState={createPlaylistModal}
          parentModalState={modalState}
          type="create"
          track={track}
        />
      )}
    </AntModal>
  );
};

export default AddToPlaylistModal;
