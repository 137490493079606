import React from "react";
import EditorProvider from "../../editor/context/EditorContext/EditorProvider";
import EditorDrawer from "../../editor/components/EditorDrawer";
import { QuickTip } from "../../editor/components/QuickTip";

/*
 * This is a basic editor page template that is barebones but provides editor drawer access
 */
const EditorLayout = props => {
  return (
    <EditorProvider>
      {props.children}
      <QuickTip />
      <EditorDrawer
        mask={props.drawerMask ?? true}
        maskClosable={props.drawerMaskClosable ?? true}
      />
    </EditorProvider>
  );
};

export default EditorLayout;
