import React, { useEffect } from "react";

const AutoComplete = ({
  setActiveSuggestion,
  activeSuggestion,
  value,
  suggestions,
  children,
  onEnter,
  setShowSuggestions,
  textSearching,
  setTextSearching
}) => {
  useEffect(() => {
    if (!value.length) {
      setShowSuggestions(false);
    }
  }, [value, setShowSuggestions]);

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      if (textSearching) {
        onEnter(value);
        setShowSuggestions(false);
      } else {
        onEnter(suggestions[activeSuggestion - 1]);
        setShowSuggestions(false);
      }
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        setTextSearching(true);
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion === suggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
      setTextSearching(false);
    }
  };

  return <React.Fragment>{children(onKeyDown, suggestions)}</React.Fragment>;
};

export default AutoComplete;
