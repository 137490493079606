import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MainStore, PlaybackState } from "../store";
import { dbToLinear } from "../util/extra_math";
import { bpm_to_seconds_per_measure, bpm_to_spb } from "../util/quantization";
import { AudioMaster } from "./AudioMaster";

export interface MetronomeNodeProps {
  audioMaster: AudioMaster;
  outputNode: any;
}

export function MetronomeNode(props: MetronomeNodeProps) {
  const output = props.outputNode;

  // eslint-disable-next-line
  const [stateMetronomeNodes, setStateMetronomeNodes] = useState(() => {
    const gainNode = props.audioMaster.context.createGain();
    return {
      gainNode
    };
  });
  const [metronomeBuffer, setMetronomeBuffer] = useState(null);
  const [scheduledBlip, setScheduledBlip] = useState(null);

  const bpm = useSelector<MainStore, number>(
    state => state.project.initialTrackData.bpm
  );
  const beatsPerMeasure = useSelector<MainStore, number>(
    state => state.project.initialTrackData.beatsPerMeasure
  );
  const volume = useSelector<MainStore, number>(
    state => state.uiState.metronomeVolume
  );
  const isMuted = useSelector<MainStore, boolean>(
    state => state.uiState.metronomeMute
  );
  const playbackState = useSelector<MainStore, PlaybackState>(
    state => state.editorState.playbackState
  );
  const lastPlaySongTime = useSelector<MainStore, number>(
    state => state.editorState.lastPlaySongTime
  );
  const locatorTime = useSelector<MainStore, number>(
    state => state.editorState.locatorTime
  );

  const measureTimeSeconds = bpm_to_seconds_per_measure(bpm, beatsPerMeasure);
  const beatTimeSeconds = bpm_to_spb(bpm);

  /*
   * Caches the metronome blip sound in an audio buffer on AudioMaster so it's
   * ready to playback in the editor
   */
  const cacheMetronomeBlip = async audioPath => {
    fetch(audioPath)
      .then(r => r.arrayBuffer())
      .then(arrayBuffer =>
        props.audioMaster.context.decodeAudioData(arrayBuffer)
      )
      .then(audioBuffer => {
        setMetronomeBuffer(audioBuffer);
      });
  };

  useEffect(() => {
    if (!metronomeBuffer) {
      cacheMetronomeBlip(`${process.env.PUBLIC_URL}/audio/metronome.wav`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    stateMetronomeNodes.gainNode.connect(output);

    return () => {
      stateMetronomeNodes.gainNode.disconnect();
    };
    // eslint-disable-next-line
  }, [props.outputNode]);

  let finalVolume = isMuted ? 0 : dbToLinear(volume);
  stateMetronomeNodes.gainNode.gain.value = finalVolume;

  useEffect(() => {
    // if buffer is loaded, we're playing, and there's no metronome blip schedule, schedule a blip
    if (
      metronomeBuffer &&
      playbackState === PlaybackState.PLAYING &&
      scheduledBlip === null
    ) {
      // calculate time to schedule metronome blip
      const currentSongTime = props.audioMaster.getCurrentSongTime();
      const nextBeatTimeSong =
        currentSongTime < 1e-2
          ? 0
          : Math.floor(currentSongTime / beatTimeSeconds) * beatTimeSeconds +
            beatTimeSeconds;
      const nextBeatTimeDelta = Math.max(0, nextBeatTimeSong - currentSongTime);
      const nextBeatTimeAudioContext =
        props.audioMaster.context.currentTime + nextBeatTimeDelta;
      const nextBeatIsDownbeat =
        Math.abs(
          nextBeatTimeSong / measureTimeSeconds -
            Math.round(nextBeatTimeSong / measureTimeSeconds)
        ) < 1e-6;

      if (!isNaN(nextBeatTimeAudioContext)) {
        const audioBufferSourceNode = new AudioBufferSourceNode(
          props.audioMaster.context,
          {
            buffer: metronomeBuffer,
            detune: nextBeatIsDownbeat ? 800 : 0
          }
        );
        audioBufferSourceNode.connect(stateMetronomeNodes.gainNode);
        audioBufferSourceNode.start(nextBeatTimeAudioContext);
        setScheduledBlip(audioBufferSourceNode);
        audioBufferSourceNode.addEventListener("ended", () => {
          audioBufferSourceNode.disconnect();
          setScheduledBlip(null);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.outputNode,
    props.audioMaster,
    stateMetronomeNodes,
    metronomeBuffer,
    playbackState,
    scheduledBlip,
    measureTimeSeconds,
    beatTimeSeconds
  ]);

  useEffect(() => {
    // if a blip is scheduled but a seek action occured during playback, clear the previously queued blip
    const seekHappened = locatorTime !== 0 && lastPlaySongTime === locatorTime;
    const clearBlipAfterSeekWhilePlaying =
      playbackState === PlaybackState.PLAYING && seekHappened;

    // if a blip is scheduled but playback stopped, clear the queued blip
    const clearBlipAfterStop = playbackState !== PlaybackState.PLAYING;
    if (
      (clearBlipAfterSeekWhilePlaying || clearBlipAfterStop) &&
      scheduledBlip !== null
    ) {
      scheduledBlip.stop();
    }
  }, [playbackState, scheduledBlip, lastPlaySongTime, locatorTime]);

  return null;
}
