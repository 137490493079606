import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Tooltip } from "antd";
import {
  UI_SHOW_FX_PANEL,
  UIShowFXPanelAction
} from "../../actions/actionTypes";
import { FXSlot } from "./FXSlot";
import { Container } from "./styles";
import { EditorContext } from "../../context/EditorContext/EditorProvider";

export const NUM_FX_SLOTS = 3;

export const FXPanel = props => {
  const editor = useContext(EditorContext);
  const dispatch = useDispatch();

  const fxSlots = Array.from(Array(NUM_FX_SLOTS))
    .map((_, i) => i)
    .map(i => {
      return <FXSlot laneId={props.laneId} slotId={i} key={i} />;
    });

  const onPopoverChange = isVisible => {
    if (isVisible) {
      dispatch<UIShowFXPanelAction>({
        type: UI_SHOW_FX_PANEL,
        laneId: props.laneId
      });
      return;
    }
    dispatch<UIShowFXPanelAction>({
      type: UI_SHOW_FX_PANEL,
      laneId: null
    });
  };

  return (
    <Container tightLayout={editor.tightLayout}>
      <Tooltip
        onVisibleChange={value => onPopoverChange(value)}
        overlayClassName={"fx-panel"}
        trigger="click"
        getPopupContainer={trigger => {
          return trigger.parentElement;
        }}
        title={() => <div className="fx-panel-children">{fxSlots}</div>}
        placement="bottom"
      >
        {props.children}
      </Tooltip>
    </Container>
  );
};
