import reduceReducers from "reduce-reducers";
import { combineReducers, Reducer } from "redux";
import {
  CLONE_PROJECT_FOR_OFFLINE,
  CloneProjectForOfflineAction,
  REHYDRATE_CUSTOM_MIX,
  RehydrateCustomMixAction,
  ResetStoreAction,
  RESET_STORE
} from "../actions/actionTypes";
import { MainStore } from "../store";
import { initialState } from "../store/test_initial_store";
import { defaultEditorState, editorStateReducer } from "./editorState";
import { projectReducer } from "./project";
import { initialUiState, uiStateReducer } from "./uiState";
import {
  initialWaveFormBufferState,
  waveformBufferReducer
} from "./waveformImages";

// TODO: extract to separate reducer files

export const offlineCloneReducer: Reducer<MainStore> = (
  state,
  action: CloneProjectForOfflineAction
) => {
  if (action.type === CLONE_PROJECT_FOR_OFFLINE) {
    return {
      ...action.project
    };
  } else {
    return state;
  }
};

export const rehydrateReducer: Reducer<MainStore> = (
  state,
  action: RehydrateCustomMixAction
) => {
  if (action.type === REHYDRATE_CUSTOM_MIX) {
    return {
      editorState: { ...defaultEditorState },
      project: action.project,
      uiState: { ...initialUiState },
      waveformBuffers: { ...initialWaveFormBufferState }
    };
  } else {
    return state;
  }
};

export const storeReducer: Reducer<MainStore> = (
  state = initialState,
  action: ResetStoreAction
) => {
  switch (action.type) {
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export const rootReducer: Reducer<MainStore> = reduceReducers(
  combineReducers({
    editorState: editorStateReducer,
    project: projectReducer,
    uiState: uiStateReducer,
    waveformBuffers: waveformBufferReducer
  }),
  offlineCloneReducer,
  rehydrateReducer,
  storeReducer
);
