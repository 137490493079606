import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";
import { guestMenuItems, privateMenuItems, adminMenuItems } from "../MenuPopover";
import { Button } from "../Button";
import { AuthContext } from "../../context/AuthProvider";

const Drawer = ({ closeDrawer }) => {
  const drawerRef = useRef(null);
  const { push } = useHistory();
  const { user } = useContext(AuthContext);

  const toggleDrawer = useCallback(
    e => {
      if (drawerRef.current && !drawerRef.current.contains(e.target))
        closeDrawer(false);
    },
    [closeDrawer]
  );

  useEffect(() => {
    document.addEventListener("mousedown", toggleDrawer, false);
    return () => {
      document.addEventListener("mousedown", toggleDrawer, false);
      closeDrawer();
    };
  }, [toggleDrawer, closeDrawer]);

  const getLinks = () => {
    switch (user.adminUser ? "admin" : user.role) {
      case "guest":
        return guestMenuItems;
      case "private":
        return privateMenuItems;
      case "admin":
        return adminMenuItems;
      default:
        return [];
    }
  };

  return (
    <Container ref={drawerRef}>
      <div className="links">
        {getLinks().map(link => {
          if (link.title === "breakLine") {
            return <div key={link.title} className="break-line" />;
          }
          return (
            <p
              key={Math.random()}
              onClick={() => {
                if (link.to.startsWith("http")) {
                  window.location.href = link.to;
                } else {
                  push(link.to);
                }
              }}
              style={{
                cursor: "pointer"
              }}
            >
              {link.title}
            </p>
          );
        })}
        {user.role === "guest" && (
          <Button onClick={() => push("/signup")}>Sign up</Button>
        )}
        {!user.paidUser && !user.adminUser && (
          <Button subscribe onClick={() => push("/subscription")}>
            Subscribe
          </Button>
        )}
      </div>
    </Container>
  );
};

export default Drawer;
