import React, { useEffect, useState, useContext } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { applyMiddleware, createStore, Store } from "redux";
import {
  SET_PLAYBACK_STATE,
  SetPlaybackStateAction,
  SetAlertAction,
  SET_ALERT,
  FinishOfflineRenderAction,
  FINISH_OFFLINE_RENDER,
  LoadHDStems,
  LOAD_HD_STEMS
} from "../actions/actionTypes";
import { clearOfflineSendFXBank } from "../context/EditorContext/MixingBoardProvider";
import { rootReducer } from "../reducers";
import {
  InitialTrackData,
  Lanes,
  MainStore,
  PlaybackState,
  Region
} from "../store";
import { initialState } from "../store/test_initial_store";
import { genNewId } from "../util/ids";
import { bpm_to_seconds_per_measure, bpm_to_spb } from "../util/quantization";
import { AudioMaster } from "./AudioMaster";
import { AudioMiddleWareOffline } from "./AudioMiddlewareOffline";
import { MasterNode } from "./MasterNode";
import {
  EditorContext,
  EditorState
} from "../context/EditorContext/EditorProvider";
import { AuthContext } from "../../context/AuthProvider";

const RENDER_SLOP_TIME_SECONDS = 0.5;

/**
 * Essentially a snapshot of the project that handles rendering only
 * Needs to communicate with the original store/audioMaster
 * @param store
 * @param mainAudioMaster
 */
export const genOfflineAudio = (
  store: Store<MainStore>,
  mainAudioMaster: AudioMaster
) => {
  const OfflineAudioComponent = () => {
    const hdAudioLoaded = useSelector<MainStore, Boolean>(
      state => state.waveformBuffers.hdLoaded
    );
    const isRendering = useSelector<MainStore, boolean>(
      state => state.editorState.isRendering
    );
    const initialTrackData = useSelector<MainStore, InitialTrackData>(
      state => state.project.initialTrackData
    );
    const songLength = useSelector<MainStore, number>(
      state => state.project.customMixInfo.length
    );
    const lanes = useSelector<MainStore, Lanes>(state => state.project.lanes);
    const regions = useSelector<MainStore, Region[]>(state =>
      Object.values(state.project.regions.byId)
    );
    const bpm = useSelector<MainStore, number>(
      state => state.project.initialTrackData.bpm
    );
    const beatsPerMeasure = useSelector<MainStore, number>(
      state => state.project.initialTrackData.beatsPerMeasure
    );
    const initialBeat = useSelector<MainStore, number>(
      state => state.project.initialTrackData.initialBeat
    );
    const secondsPerBeat = bpm_to_spb(bpm);
    const secondsPerMeasure = bpm_to_seconds_per_measure(bpm, beatsPerMeasure);

    const { hasPaidTrackAccess } = useContext(AuthContext);
    const editor = useContext(EditorContext);

    const dispatch = useDispatch();

    function createOfflineStore(state, audioMaster) {
      return createStore(
        rootReducer,
        state,
        applyMiddleware(AudioMiddleWareOffline(audioMaster, store))
      );
    }

    const [state, setState] = useState(() => {
      const audioMaster = new AudioMaster(true);
      // before creating the offline redux store, clear global send fx bank to use new context
      clearOfflineSendFXBank();
      return {
        audioMaster,
        store: createOfflineStore(initialState, audioMaster),
        id: genNewId()
      };
    });

    /*
     *  Calculates intelligent start and stop time render markers for the mix.
     *  Set export bounds by defaulting to initial track offset so everything's aligned
     *  ... unless there would be more than a bar of silence at the beginning
     *  (meaning the user has moved regions around and deviated from the initial track
     *  in a significant way), in which case we set the start export time to 500ms before
     *  their first region.
     *
     *  Returns:
     *    startTimeSeconds (number): time to begin render (start locater position)
     *    endTimeSeconds (number): time to end render (end locater position)
     */
    const calculateDefaultMixBounds = () => {
      // get muted regions (from mute/solo status of parent lane) to filter these out
      const audibleLaneIds =
        lanes.solodLaneIds.length > 0
          ? lanes.solodLaneIds
          : lanes.laneIds.filter(laneId => !lanes.muteLaneIds.includes(laneId));

      let regionIdToLaneIdMap = {};
      for (let laneId in lanes.byId) {
        lanes.byId[laneId].regionIds.forEach(regionId => {
          regionIdToLaneIdMap[regionId] = laneId;
        });
      }

      // calculate min audible region start and max region end
      let minAudibleRegionStartTime = songLength;
      let maxAudibleRegionEndTime = 0;
      regions.forEach(region => {
        // if region is not audible, skip it
        if (!audibleLaneIds.includes(regionIdToLaneIdMap[region.id])) return;

        // update min/max if region exceeds current bounds
        if (region.visualStartTime < minAudibleRegionStartTime) {
          minAudibleRegionStartTime = region.visualStartTime;
        }
        const regionEndTime = region.visualStartTime + region.visualDuration;
        if (regionEndTime > maxAudibleRegionEndTime) {
          maxAudibleRegionEndTime = regionEndTime;
        }
      });

      // if first audible region is <= 1 bar of time then set start bound to initialBeat time
      // (if initialBeat set properly, this will keep export beat synced in external DAW).
      // Otherwise, or if free time (no bpm), set audible region to 500ms before first
      // audible region starts
      let exportStartTime = (beatsPerMeasure - initialBeat) * secondsPerBeat;
      if (bpm in [0, 1] || minAudibleRegionStartTime > secondsPerMeasure) {
        exportStartTime = Math.max(
          0,
          minAudibleRegionStartTime - RENDER_SLOP_TIME_SECONDS
        );
      }
      const exportEndTime = maxAudibleRegionEndTime + RENDER_SLOP_TIME_SECONDS;
      exportStartTime = Math.min(exportStartTime, exportEndTime);

      return {
        exportStartTime,
        exportEndTime
      };
    };

    useEffect(() => {
      if (isRendering && editor.state === EditorState.LOADED) {
        const paidAccess = hasPaidTrackAccess(initialTrackData);
        if (paidAccess && !hdAudioLoaded) {
          // user has paid access to this track and we need to load hd audio files for bounce
          dispatch<LoadHDStems>({
            type: LOAD_HD_STEMS
          });
          // useEffect will load again when hdAudioLoaded = true and then render will occur
          return;
        }

        const { exportStartTime, exportEndTime } = calculateDefaultMixBounds();
        const exportDuration = exportEndTime - exportStartTime;
        if (exportDuration > 0) {
          const newAudioMaster = new AudioMaster(true, exportDuration);
          newAudioMaster.copyAudioFiles(mainAudioMaster);
          const currentState = store.getState();
          const newState = {
            ...currentState,
            editorState: {
              ...currentState.editorState,
              playbackState: PlaybackState.PAUSED,
              lastPlaySongTime: exportStartTime
            }
          };
          // before creating the offline redux store, clear global send fx bank to use new context
          clearOfflineSendFXBank();
          const newStore = createOfflineStore(newState, newAudioMaster);
          setState({
            audioMaster: newAudioMaster,
            store: newStore,
            id: genNewId()
          });

          newStore.dispatch<SetPlaybackStateAction>({
            type: SET_PLAYBACK_STATE,
            state: PlaybackState.PLAYING,
            actionSongTime: exportStartTime
          });
        } else {
          store.dispatch<FinishOfflineRenderAction>({
            type: FINISH_OFFLINE_RENDER
          });
          store.dispatch<SetAlertAction>({
            type: SET_ALERT,
            id: "RENDER_ERROR",
            role: "error",
            title: "Error Bouncing Audio",
            message:
              "We couldn't bounce an audio file for you because there are no audible regions."
          });
        }
      }
      // eslint-disable-next-line
    }, [hdAudioLoaded, isRendering, songLength, editor.state]);

    function createRenders() {
      // this is to force tear down when state changes
      return [
        <Provider key={state.id} store={state.store}>
          <MasterNode audioMaster={state.audioMaster} />
        </Provider>
      ];
    }

    return <>{createRenders()}</>;
  };
  return OfflineAudioComponent;
};
