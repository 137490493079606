import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { MainStore } from "../../store";
import { IconButton } from "./IconButton";
import { Volume } from "../../../assets/Volume";
import { VolumeMuted } from "../../../assets/VolumeMuted";
import { VolumeSlider } from "../VolumeSlider";
import {
  SetMetronomeMuteAction,
  SetMetronomeVolumeAction,
  SET_METRONOME_MUTE,
  SET_METRONOME_VOLUME
} from "../../actions/actionTypes";
import { valToDb } from "../LaneVolumeSlider";

export function MetronomeVolumeControl() {
  const isMuted = useSelector<MainStore, boolean>(
    state => state.uiState.metronomeMute
  );
  const volume = useSelector<MainStore, number>(
    state => state.uiState.metronomeVolume
  );
  const dispatch = useDispatch();

  const toggleMute = () => {
    dispatch<SetMetronomeMuteAction>({
      type: SET_METRONOME_MUTE,
      isMuted: !isMuted
    });
  };

  const onChange = val => {
    dispatch<SetMetronomeVolumeAction>({
      type: SET_METRONOME_VOLUME,
      volumeValue: valToDb(-0.5 + (val / 100) * 1.5)
    });
  };

  return (
    <Popover
      placement="right"
      content={
        <VolumeSlider
          onChange={onChange}
          onAfterChange={onChange}
          currentVolume={volume}
        />
      }
      getPopupContainer={trigger => trigger}
      {...(isMuted && { visible: false })}
    >
      <IconButton onClick={toggleMute}>
        {isMuted ? <VolumeMuted /> : <Volume />}
      </IconButton>
    </Popover>
  );
}
