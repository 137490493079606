import styled from "styled-components";

export const MobileContainer = styled.div`
  .playlist-items-container {
    max-height: 255px;
    padding-bottom: 24px;
    overflow-y: scroll;
  }

  .playlist-items-container::-webkit-scrollbar {
    width: 8px;
    opacity: 0;
  }

  /* Track */
  .playlist-items-container::-webkit-scrollbar-track {
    opacity: 0;
    width: 15px;
    background-color: ${props => props.theme.backgroundDark};
  }

  /* Handle */
  .playlist-items-container::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.06);
    opacity: 0.6;
    border-radius: 2px;
  }
  .queue-header {
    padding-left: 38px;
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: ${props => props.theme.p4b.fs};
    font-family: ${props => props.theme.p4b.ff};
    color: ${props => props.theme.highlight};
  }
  .playlist-item {
    border-bottom: 1px solid #36363633;
    padding: 3px 38px;
    transition: background-color 2s, box-shadow 2s ease;
    color: ${props => props.theme.textLight};
    cursor: pointer;

    .now-playing {
      padding-right: 14px;
    }
    div:nth-child(1) {
      font-family: ${props => props.theme.p4b.ff};
      font-size: ${props => props.theme.p4b.fs};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .dragging-true {
    background-color: ${props => props.theme.backgroundDark};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .played-track {
    color: ${props => props.theme.textDark};
  }
  .played-track-actions {
    opacity: 0.5;
  }
`;

export const Container = styled.div`
  /* .playlist { */
  position: absolute;
  place-self: center;
  bottom: 129px;
  height: 530px;
  width: 80vw;
  box-sizing: content-box;
  padding-bottom: 60px;
  background-color: ${props => props.theme.backgroundDark};
  @media only screen and (max-width: 1200px) {
    width: 90vw;
  }
  @media only screen and (min-width: 1920px) {
    margin-left: 10vw;
  }
  /* } */
  .playlist-close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      padding: 18px 28px 18px 18px;
      cursor: pointer;
    }
  }
  .playlist-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: ${props => props.theme.p3b.fs};
    font-family: ${props => props.theme.p3b.ff};
    padding: 16px 62px 16px 16px;
  }
  .save-button {
    margin-right: 24px;
    padding: 4px 10px;
    cursor: pointer;
    color: ${props => props.theme.textLight};

    &:hover {
      background-color: ${props => props.theme.backgroundTag};
    }
  }
  .clear-button {
    display: flex;
    align-items: center;
    height: 22px;
    padding: 4px 10px;
    cursor: pointer;
    color: ${props => props.theme.textLight};
    box-sizing: content-box;

    &:hover {
      background-color: ${props => props.theme.backgroundTag};
    }

    img {
      width: 18px;
      height: 18px;
      box-sizing: content-box;
      padding-left: 18px;
      padding-right: 2px;
    }
  }

  .playlist-items-container {
    padding-left: 32px;
    padding-right: 63px;
    height: 410px;
    overflow-y: scroll;
  }
  .empty-playlist {
    display: grid;
    place-content: center;
  }
  .playlist-items-container::-webkit-scrollbar {
    width: 8px;
    opacity: 0;
  }

  /* Track */
  .playlist-items-container::-webkit-scrollbar-track {
    opacity: 0;
    width: 15px;
    background-color: ${props => props.theme.backgroundDark};
  }

  /* Handle */
  .playlist-items-container::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.06);
    opacity: 0.6;
    border-radius: 2px;
  }

  .queue-header {
    line-height: 100%;
    padding-left: 45px;
    padding-top: 46px;
    padding-bottom: 20px;
    font-size: ${props => props.theme.p1b.fs};
    font-family: ${props => props.theme.p1b.ff};
    color: ${props => props.theme.highlight};

    &:first-child {
      padding-top: 0;
    }
  }
  .playlist-item {
    display: grid;
    grid-template-columns: 30px repeat(11, 1fr);
    grid-column-gap: 15px;
    border-bottom: 1px solid #36363633;
    padding: 3px 0;
    transition: background-color 2s, box-shadow 2s ease;
    color: ${props => props.theme.textLight};
    cursor: pointer;

    .now-playing {
      padding-right: 14px;
    }
    div:nth-child(1) {
      grid-column: 1/2;
      justify-self: center;
    }
    div:nth-child(2) {
      grid-column: 2/5;
      font-family: ${props => props.theme.p2b.ff};
      font-size: ${props => props.theme.p2b.fs};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    div:nth-child(3) {
      grid-column: 5/7;
      font-size: ${props => props.theme.p3r.fs};
      font-family: ${props => props.theme.p3r.ff};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    div:nth-child(4) {
      grid-column: 7/8;
      font-size: ${props => props.theme.p3r.fs};
      font-family: ${props => props.theme.p3r.ff};
    }
    div:nth-child(5) {
      grid-column: 8/13;
      justify-self: right;
      display: flex;
    }
    .reorder-handle {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: content-box;
      width: 100%;
      height: 100%;
      padding: 0 5px;
      cursor: pointer;
      border-radius: 2px;

      &:hover {
        border: 2px solid ${props => props.theme.backgroundLight};
        background-color: ${props => props.theme.backgroundTag};
      }

      &:first-child {
        width: 8px;
      }
    }
  }
  .remove-button {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    padding-right: 3px;
    > img {
      width: 80%;
      height: 80%;
    }
  }
  .dragging-true {
    background-color: ${props => props.theme.backgroundDark};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .played-track {
    color: ${props => props.theme.textDark};
  }
  .played-track-actions {
    opacity: 0.5;
  }
`;
