import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { MainStore, PlaybackState } from "../../../store";
import { seconds_to_pixels } from "../../../util/time_position_conversion";
import { getZoomProportion } from "../../../util/zoom_levels";
import { Container } from "./styles";

export let Locator = () => {
  const zoomLevel = useSelector<MainStore, number>(
    state => state.uiState.zoomLevel
  );
  const songTime = useSelector<MainStore, number>(
    state => state.editorState.locatorTime
  );
  const playbackState = useSelector<MainStore, PlaybackState>(
    state => state.editorState.playbackState
  );

  // this handles scrolling along with the locator position, screen by screen
  useEffect(() => {
    const locatorElement = document.getElementById("locator");
    const lanesAreaElement = document.getElementById("lanes-area-container");
    const xLocator = seconds_to_pixels(songTime) * getZoomProportion(zoomLevel);
    const xScreenLeft = lanesAreaElement.scrollLeft;
    const xScreenRight = xScreenLeft + lanesAreaElement.clientWidth;
    const screenWidth = lanesAreaElement.clientWidth;

    if (xLocator < xScreenLeft) {
      if (Math.abs(xLocator - xScreenLeft) <= screenWidth) {
        lanesAreaElement.scrollBy({
          left: -0.85 * screenWidth,
          behavior: "smooth"
        });
      } else {
        locatorElement.scrollIntoView({ behavior: "auto" });
      }
    } else if (xLocator > xScreenRight) {
      if (Math.abs(xLocator - xScreenRight) <= screenWidth) {
        if (playbackState === PlaybackState.PLAYING) {
          lanesAreaElement.scrollLeft = xScreenLeft + 0.85 * screenWidth;
        } else {
          lanesAreaElement.scrollBy({
            left: screenWidth,
            behavior: "smooth"
          });
        }
      } else {
        locatorElement.scrollIntoView({ behavior: "auto" });
      }
    }
  }, [songTime, playbackState, zoomLevel]);

  return (
    <Container
      id="locator"
      style={{
        left: seconds_to_pixels(songTime) * getZoomProportion(zoomLevel)
      }}
      playbackState={playbackState}
    ></Container>
  );
};
