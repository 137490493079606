import React, { useState, useContext } from "react";
import MenuPopover from "../MenuPopover";
import { Icon, CloseIcon, ProfileIcon } from "./styles";
import Popover from "../Popover";
import { useMediaQuery } from "react-responsive";
import { AuthContext } from "../../context/AuthProvider";
import Profile from "../Profile";

const Menu = ({ onMenuClick, isDrawerOpen, showProfile, menuIconStyle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const isDesktop = useMediaQuery({
    query: "(min-width: 801px)"
  });

  const ArrowIcon = (
    <ProfileIcon showProfile={showProfile}>
      {showProfile && (
        <div style={{ marginLeft: 20, minWidth: 100 }}>
          <Profile />
        </div>
      )}
      <Icon
        isOpen={isOpen}
        src={require("../../assets/arrow-right.svg")}
        alt="menu"
        style={menuIconStyle}
      />
    </ProfileIcon>
  );

  const BurgerIcon = (
    <img
      onClick={onMenuClick}
      src={require("../../assets/menu_burger.svg")}
      alt="burger-menu-icon"
      style={{ marginLeft: isDesktop ? 20 : 28 }}
    />
  );

  if (isDesktop) {
    return (
      <Popover
        right={user.role === "guest" ? "-8px" : "4px"}
        triggerComponent={user.role === "guest" ? BurgerIcon : ArrowIcon}
        dropdown={<MenuPopover />}
        onChange={value => setIsOpen(value)}
      />
    );
  }
  if (!isDrawerOpen) {
    return BurgerIcon;
  }

  return (
    <CloseIcon className="close-icon" onClick={onMenuClick}>
      <img src={require("../../assets/close-white.svg")} alt={"close-drawer"} />
    </CloseIcon>
  );
};

export default Menu;
