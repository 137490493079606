import { combineReducers, Reducer } from "redux";
import {
  ADD_REGION_TO_LANE,
  AddRegionToLaneAction,
  CHANGE_REGION_DURATION,
  CHANGE_REGION_START,
  ChangeRegionDurationAction,
  ChangeRegionStartAction,
  DUPLICATE_REGION,
  DuplicateRegionAction,
  EMPTY_PROJECT,
  EmptyProjectAction,
  MOVE_REGION,
  MOVE_REGION_DIFFERENT_LANE,
  MoveRegionAction,
  MoveRegionToDifferentLaneAction,
  ReorderLanesAction,
  SET_FADE_END,
  SET_FADE_START,
  SetFadeEndAction,
  SetFadeStartAction,
  SetMuteSoloAction,
  SetSnapModeAction,
  SPLIT_REGION,
  SplitRegionAction,
  ZoomChangeAction,
  REHYDRATE_INITIAL_MIX,
  RehydrateInitialMixAction,
  DUPLICATE_LANE,
  DuplicateLaneAction
} from "../actions/actionTypes";
import { Project } from "../store";
import {
  applyCrossFades,
  applyNewSongLength,
  calculateDataHash,
  isValidState
} from "../store/store_processors";
import {
  emptyInitialTrackData,
  initialState
} from "../store/test_initial_store";
import { initialTrackDataReducer } from "./initialTrackData";
import { initialCustomMixInfo, projectInfoReducer } from "./projectInfo";
import { regionsReducer } from "./regions";
import { lanesReducer } from "./lanes";
import {
  UpdateBarAction,
  UpdateBpmAction,
  UpdateInitialBarAction,
  UPDATE_BAR,
  UPDATE_BPM,
  UPDATE_INITIAL_BEAT
} from "../actions/EditorStateTypes";
import { beats_to_spb } from "../util/quantization";
import { genNewId } from "../util/ids";
import { appendToList, reorder } from "../util/util";
import { initialLaneParamters } from "./laneStatus";

/*
 * Helper function that updates the time offsets of regions given new parameters,
 * like when initialBeat, beatsPerMeasure, or bpm changes.
 * For freetime tracks (when bpm is 0 or 1), remove any visual offsets
 *
 * Parameters:
 *  state: current redux state
 *  action: action to modify redux state
 *
 * Returns:
 *  newState: new redux state
 */
const updateRegionOffsets = (state, action) => {
  const currentVisualOffsetSeconds =
    state.initialTrackData.bpm in [0, 1]
      ? 0
      : beats_to_spb(
          state.initialTrackData.beatsPerMeasure -
            state.initialTrackData.initialBeat,
          state.initialTrackData.bpm
        );
  // perform the action that changes the state (and potentially changes visual offsets)
  const newState = regularReducer(state, action);
  const newVisualOffsetSeconds =
    newState.initialTrackData.bpm in [0, 1]
      ? 0
      : beats_to_spb(
          newState.initialTrackData.beatsPerMeasure -
            newState.initialTrackData.initialBeat,
          newState.initialTrackData.bpm
        );

  // remove current visual offset from all regions before adding new visual offset
  // visual offset is the horizontal offset from a pickup measure (Track::initialBeat)
  for (let regionId in newState.regions.byId) {
    const region = newState.regions.byId[regionId];
    region.visualStartTime +=
      -currentVisualOffsetSeconds + newVisualOffsetSeconds;

    // handle edge case of region moving left beyond t=0 and there's some audio preroll silence before the clip plays
    // i.e., we've quantized back a measure beyond the bounds of the underlying stem file
    // this basically sucks up that preroll silence time
    if (region.visualStartTime < 0 && region.audioStartTime < 0) {
      region.audioStartTime -= region.visualStartTime;
    }
    region.visualStartTime = Math.max(0, region.visualStartTime);
  }

  return newState;
};

const specialReducer: Reducer<
  Project,
  | DuplicateRegionAction
  | DuplicateLaneAction
  | SplitRegionAction
  | SetFadeEndAction
  | SetFadeStartAction
  | ReorderLanesAction
  | SetSnapModeAction
  | MoveRegionAction
  | ZoomChangeAction
  | SetMuteSoloAction
  | ChangeRegionDurationAction
  | ChangeRegionStartAction
  | MoveRegionToDifferentLaneAction
  | EmptyProjectAction
  | AddRegionToLaneAction
  | RehydrateInitialMixAction
  | UpdateInitialBarAction
  | UpdateBarAction
  | UpdateBpmAction
> = (state, action) => {
  // hold onto the new state instead of immediately updating so we can
  // calculate and inject the data hash based on the modified data
  let newState = null;

  switch (action.type) {
    case CHANGE_REGION_DURATION:
    case CHANGE_REGION_START:
    case MOVE_REGION:
    case MOVE_REGION_DIFFERENT_LANE:
    case SET_FADE_START:
    case SET_FADE_END:
    case ADD_REGION_TO_LANE:
    case DUPLICATE_REGION:
    case SPLIT_REGION:
      // STEP 1: apply change
      // STEP 2: verify if valid state
      // STEP 3: apply crossfades
      const temp = regularReducer(state, action);
      const valid = isValidState(temp);
      if (valid) {
        newState = applyNewSongLength(applyCrossFades(temp));
      } else {
        // force rerender
        newState = {
          ...state,
          regions: {
            ...state.regions,
            byId: {
              ...state.regions.byId,
              [action.regionId]: {
                ...state.regions.byId[action.regionId],
                _forceRerender:
                  state.regions.byId[action.regionId]._forceRerender + 1
              }
            }
          }
        };
      }
      break;
    case DUPLICATE_LANE:
      // get reference lane to duplicate and insert duplicate into list with new id
      const iLane = state.lanes.laneIds.indexOf(action.laneId);
      if (iLane === -1) return state;
      const newLaneId = genNewId();
      let newLaneList = appendToList(state.lanes.laneIds, newLaneId);
      newLaneList = reorder(newLaneList, newLaneList.length - 1, iLane + 1);

      // copy reference lane's regions and generate new region ids for each of them
      const refLaneRegionIds = state.lanes.byId[action.laneId].regionIds;
      const newRegions = refLaneRegionIds.map(rid => {
        return { ...state.regions.byId[rid], id: genNewId() };
      });
      const newRegionIds = newRegions.map(region => region.id);
      const newRegionMap = newRegions.reduce(function(obj, region) {
        obj[region.id] = region;
        return obj;
      }, {});

      // create the new state
      newState = {
        ...state,
        lanes: {
          ...state.lanes,
          byId: {
            ...state.lanes.byId,
            [newLaneId]: {
              ...state.lanes.byId[action.laneId],
              id: newLaneId,
              regionIds: newRegionIds,
              laneStatus: initialLaneParamters
            }
          },
          laneIds: newLaneList
        },
        regions: {
          ...state.regions,
          byId: {
            ...state.regions.byId,
            ...newRegionMap
          }
        }
      };
      break;
    case UPDATE_INITIAL_BEAT:
    case UPDATE_BAR:
    case UPDATE_BPM:
      // this is called when admins update fields in TrackInfo modal that influence visual offset of regions
      // it will shift over all regions to reflect the new setting
      newState = updateRegionOffsets(state, action);
      break;
    case EMPTY_PROJECT:
      newState = {
        lanes: {
          ...state.lanes,
          byId: {},
          laneIds: [],
          solodLaneIds: []
        },
        regions: {
          ...state.regions,
          byId: {}
        },
        customMixInfo: {
          ...initialCustomMixInfo
        },
        initialTrackData: {
          ...emptyInitialTrackData
        }
      };
      break;
    case REHYDRATE_INITIAL_MIX:
      newState = {
        lanes: action.lanes || initialState.project.lanes,
        regions: action.regions || initialState.project.regions,
        customMixInfo: {
          ...initialCustomMixInfo,
          id: null,
          projectId: null,
          trackId: action.initialTrackData.id,
          title: action.initialTrackData.title,
          length: action.initialTrackData.duration,
          numVersions: 0
        },
        initialTrackData: action.initialTrackData || emptyInitialTrackData
      };
      // calculate data hash of hydrated initial mix
      newState.customMixInfo.version.editorDataDumpHash = calculateDataHash(
        newState
      );
      break;
    default:
      newState = regularReducer(state, action);
  }

  // Recalculate mix data hash after state update and inject into redux data store
  newState.customMixInfo.currentDataHash = calculateDataHash(newState);

  // finally return the new state with updated data hash injected
  return newState;
};

const regularReducer = combineReducers({
  customMixInfo: projectInfoReducer,
  initialTrackData: initialTrackDataReducer,
  regions: regionsReducer,
  lanes: lanesReducer
});

export const projectReducer: Reducer<
  Project,
  | ReorderLanesAction
  | SetSnapModeAction
  | MoveRegionAction
  | ZoomChangeAction
  | SetMuteSoloAction
> = specialReducer;
