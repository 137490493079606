import React from "react";
import { CreativeCommonsContainer } from "./styles";

const CreativeCommonsLicense = () => {
  return (
    <CreativeCommonsContainer>
      <h3>Creative Commons License</h3>
      <p>
        Our mp3 files are available under a CCBYNC license. This means that you
        can use the music for free so long as you give us credit and your
        project is in essence non-commercial. &nbsp;
        <span
          onClick={() => {
            window.location = `${process.env.REACT_APP_LANDING_PAGE_URL}/licensing`;
          }}
          className="more-info"
        >
          More info.
        </span>
      </p>
    </CreativeCommonsContainer>
  );
};

export default CreativeCommonsLicense;
