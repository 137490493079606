import gql from "graphql-tag";

export const CONFIRMED_ATTRIBUTION_ON_DOWNLOAD = gql`
  mutation($id: ID, $confirmed_attribution: Boolean) {
    confirmAttribution(
      data: { id: $id, confirmed_attribution: $confirmed_attribution }
    ) {
      id
    }
  }
`;
