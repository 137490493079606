import gql from "graphql-tag";
import { fragments } from "../../utils/fragments";

export const FETCH_FAVORITES = gql`
  query($id: ID!) {
    favorites(user: $id) {
      id
      user
      track {
        id
        name
      }
    }
  }
`;
export const FILTER_QUERY = gql`
  query($filters: [filterQuery], $size: Int, $from: JSON) {
    filter(q: $filters, size: $size, from: $from)
  }
`;

export const SUGGEST_QUERY = gql`
  query(
    $filters: [suggestQuery]
    $randomSortMode: String
    $from: JSON
    $size: Int
    $seed: Int
  ) {
    suggest(
      q: $filters
      randomSortMode: $randomSortMode
      from: $from
      size: $size
      seed: $seed
    )
  }
`;
export const CREATE_TRACK = gql`
  mutation CreateTrack(
    $title: String
    $name: String
    $variationTitle: String
    $album: ID
    $team: ID
    $variation: Boolean
    $type: TrackType
    $duration: Float
    $thumbnail: String
    $associatedImages: JSON
    $key: String
    $initialBeat: Float
    $bpm: Float
    $mood: Float
    $ensemble: Float
    $density: Float
    $gravity: Float
    $energy: Float
    $melody: Float
    $rhythm: Float
    $tension: Float
    $tags: JSON
    $systemTags: JSON
    $instruments: JSON
    $stem: [StemInput]
    $waveform: String
    $blockFile: [StemInput]
    $freeStemFiles: Boolean
    $description: String
    $publisher: ID
    $parentTrackId: ID
    $order: Int
    $isrc: String
    $price: Float
    $proAffiliation: String
    $stemZip: String
    $trackZip: String
    $mp3File: String
    $wavFile: String
  ) {
    createTrack(
      data: {
        title: $title
        name: $name
        variationTitle: $variationTitle
        album: $album
        team: $team
        variation: $variation
        type: $type
        duration: $duration
        thumbnail: $thumbnail
        associatedImages: $associatedImages
        key: $key
        initialBeat: $initialBeat
        bpm: $bpm
        mood: $mood
        ensemble: $ensemble
        density: $density
        gravity: $gravity
        energy: $energy
        melody: $melody
        rhythm: $rhythm
        tension: $tension
        tags: $tags
        systemTags: $systemTags
        instruments: $instruments
        stem: $stem
        waveform: $waveform
        mp3File: $mp3File
        wavFile: $wavFile
        blockFile: $blockFile
        freeStemFiles: $freeStemFiles
        description: $description
        publisher: $publisher
        parentTrackId: $parentTrackId
        order: $order
        isrc: $isrc
        price: $price
        proAffiliation: $proAffiliation
        stemZip: $stemZip
        trackZip: $trackZip
      }
    ) {
      ...TrackData
    }
  }
  ${fragments.trackQuery}
`;

export const FETCH_TRACKS = gql`
  {
    tracks {
      ...TrackData
    }
  }
  ${fragments.trackQuery}
`;

export const FETCH_SEARCH_TEXT_RESULT = gql`
  query TextSearch($q: String!) {
    textSearch(q: $q) {
      ... on Track {
        #team
        #associatedImages
        #stemZip
        #trackZip

        ...TrackData
      }
      ... on Album {
        id
        name
        price
        tracks {
          ...TrackData
        }
      }
    }
  }
  ${fragments.trackQuery}
`;

export const FETCH_VARIATIONS = gql`
  query($id: ID!) {
    track(id: $id) {
      variationTracks {
        ...TrackData
      }
    }
  }
  ${fragments.trackQuery}
`;

export const GET_AUTOCOMPLETE = gql`
  query AutoComplete($q: String!) {
    autocomplete(q: $q) {
      ... on Track {
        id
        title
        #team
        variation
        variationTitle
        type
        duration
        thumbnail
        #associatedImages
        key
        initialBeat
        bpm
        album {
          id
          name
        }
        mood
        name
        ensemble
        density
        gravity
        energy
        melody
        rhythm
        tension
        tags
        systemTags
        instruments
        stem {
          name
        }
        waveform
        blockFile {
          name
          wavFile
          mp3File
          #waveform
          #order
        }
        freeStemFiles
        description
        publisher
        parentTrackId
        trackOrder
        isrc
        price
        proAffiliation
        variationTracks {
          id
        }
        #stemZip
        #trackZip
      }
      ... on Album {
        id
        name
        price
      }
    }
  }
`;

export const GET_ALBUM = gql`
  query Album($id: ID!) {
    album(id: $id) {
      id
      name
      description
      tracks {
        ...TrackData
      }
    }
  }
  ${fragments.trackQuery}
`;

export const GET_TRACK = gql`
  query Track($id: ID!) {
    track(id: $id) {
      ...TrackData
    }
  }
  ${fragments.trackQuery}
`;
