const purple = { val: 0x8b63ff, str: "#8b63ff" };
const blue = { val: 0x4eccff, str: "#4eccff" };
const green = { val: 0x83eb42, str: "#83eb42" };
const yellow = { val: 0xf5cc47, str: "#f5cc47" };
const red = { val: 0xff4e60, str: "#ff4e60" };
const orange = { val: 0xffa830, str: "#ffa830" };

export default {
  purple,
  blue,
  green,
  yellow,
  red,
  orange,
  // functional names
  panning: purple,
  volume: blue,
  modX: green,
  modY: yellow,
  pitch: red,
  misc: orange,
  default: orange
};
