import * as React from "react";
import { theme } from "../globalStyles";

export const PlayCircle = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
      fill={theme.editor.highlight}
    />
    <path
      d="M29 20.8199V21.1763C28.999 21.5155 28.8203 21.8295 28.5291 22.0036L17.1382 28.6345C16.4509 29.0417 16.0945 29.0417 15.7891 28.8636L15.4709 28.6854C15.1873 28.516 15.0098 28.2137 15 27.8836V14.1127C15.001 13.7734 15.1797 13.4595 15.4709 13.2854L15.7891 13.1072C16.0945 12.929 16.4509 12.929 17.3418 13.4508L28.5291 19.9927C28.8203 20.1667 28.999 20.4807 29 20.8199Z"
      fill={theme.editor.iconLight}
    />
  </svg>
);
