import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
    from{
        opacity:0;
    }
    to{
        opacity: 0.25;
    }
`;

export const Container = styled.div`
  min-width: ${props => `${props.minWidth}px`};
  min-height: ${props => `${props.minHeight}px` || "none"};
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.background || props.theme.backgroundLight};
  display: flex;
  align-items: center;
  position: relative;
  height: 24px;
  padding: 0px 20px;
  cursor: pointer;
  opacity: ${props => (props.faded ? 0.25 : 1)};
  animation: ${props => (props.faded ? fadeIn : "")} 0.5s ease-in;
  .range {
    position: absolute;
    background-color: ${props => props.theme.highlight};
    height: 100%;
    z-index: 1;
    left: ${props => props.starting};
    right: ${props => props.ending};
    width: ${props => props.width};
    border-radius: ${props => (props.width === "30%" ? "100%" : "16px")};
  }

  .text {
    width: 100%;
    height: 24px;
    text-align: center;
    display: flex;
    padding-top: 1px;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-family: ${props => props.theme.p3b.ff};
    font-size: ${props => props.theme.p3b.fs};
    color: ${props =>
      props.isActive ? props.theme.textLight : props.theme.textDark};
    white-space: nowrap;
    margin-left: ${({ closable }) => (closable ? "-5px" : "0px")};
  }

  .value {
    white-space: nowrap;
    margin-right: 8px;
    font-family: ${props => props.theme.p3b.ff};
    font-size: ${props => props.theme.p3b.fs};
    margin-left: ${({ closable }) => (closable ? "5px" : "0px")};
  }

  .close {
    position: absolute;
    right: ${props => (props.moveToRight ? "-6.4px" : "0px")};
    cursor: pointer;
    z-index: 1;
    width: 35px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-top: 2px;
    img {
      width: 10px;
      height: 10px;
      margin-bottom: 2px;
      margin-right: 2px;
    }
  }

  .listener {
    position: absolute;
    /* background: yellow; */
    width: 100%;
    height: 100%;
    z-index: 1000;
    left: 0;
  }
  /* @media (max-width: 800px) {
    height: 20px;
  } */
`;
