import React, { useContext, Suspense } from "react";
import { AuthContext } from "../context/AuthProvider";
import { Route, Switch } from "react-router-dom";
import Spinner from "../components/Spinner";
import { BareLayout, MainLayout, EditorLayout } from "../layouts";
import { AdminRoute, GuestRoute } from "./permissions";
import { RoutesAdmin, RoutesMain, RoutesAuth, RoutesLanding } from "./routes";

// Following https://github.com/xavivzla/multi-layout-react-router-v5 for basic structure
const AppRouter = () => {
  const { user } = useContext(AuthContext);

  // preload indeterminite spinner
  if (user === null || user === undefined || user.role === "loading") {
    return <Spinner />;
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {/* Admin Routes (user.adminUser only) */}
        <AdminRoute path="/admin">
          <MainLayout showPlayer={false}>
            <RoutesAdmin root="/admin" />
          </MainLayout>
        </AdminRoute>

        {/* Authentication Routes (guest users only) */}
        <GuestRoute path={["/signup", "/signin", "/reset", "/set-password"]}>
          <BareLayout>
            <RoutesAuth />
          </BareLayout>
        </GuestRoute>

        {/* Landing Page Routes (authenticated and guest) */}
        <Route path="/landing">
          <EditorLayout drawerMask={false} drawerMaskClosable={false}>
            <RoutesLanding root="/landing" />
          </EditorLayout>
        </Route>

        {/* Main Site Routes (authenticated and guest) */}
        <Route path="/">
          <MainLayout>
            <RoutesMain />
          </MainLayout>
        </Route>
      </Switch>
    </Suspense>
  );
};

export default AppRouter;
