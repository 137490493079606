import { Reducer } from "redux";
import {
  SET_FX_EFFECT,
  SET_FX_VALUE,
  SET_MUTE_SOLO,
  SET_PAN,
  SET_VOLUME,
  LaneActions
} from "../actions/actionTypes";
import { MuteSoloState, LaneParameters } from "../store";
import { initFXSlots } from "../store/test_initial_store";
import { replace } from "../util/util";

// if gainCompensation is null, it will flood fill from parent Track
export let initialLaneParamters: LaneParameters = {
  fxSlot: initFXSlots,
  gainCompensation: null,
  muteSoloState: MuteSoloState.DEFAULT,
  pan: 0.5,
  volume: 0
};

export const laneStatusReducer: Reducer<LaneParameters, LaneActions> = (
  state: LaneParameters = initialLaneParamters,
  action
) => {
  switch (action.type) {
    case SET_MUTE_SOLO:
      return {
        ...state,
        muteSoloState: action.muteSoloStatus
      };
    case SET_PAN:
      return {
        ...state,
        pan: action.panValue
      };
    case SET_VOLUME:
      return {
        ...state,
        volume: action.volumeValue
      };
    case SET_FX_EFFECT:
      return {
        ...state,
        fxSlot: replace(state.fxSlot, action.slotNumber, action.effect)
      };
    case SET_FX_VALUE:
      const originalSlot = state.fxSlot[action.slotId];
      return {
        ...state,
        fxSlot: replace(state.fxSlot, action.slotId, {
          ...originalSlot,
          val: action.value
        })
      };
    default:
      return state;
  }
};
