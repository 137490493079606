import styled, { keyframes } from "styled-components";

const switchAnim = keyframes`
  from{
    transform:rotate(40deg)
  }
  to{
    transform:rotate(0deg)
  }
`;

export const ProfileIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  width: fit-content;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    box-shadow: 2px 2px 2px 1px #1a1a1a;
    background-color: #282828;
    border-radius: 4px;
  }
`;

export const Icon = styled.img`
  margin: 0 19px;
  cursor: pointer;
  transform: ${props => (props.isOpen ? "rotate(270deg)" : "rotate(90deg)")};
  transition: transform 0.18s ease;

  @media (max-width: 500px) {
    margin-right: 0px;
  }
`;

export const CloseIcon = styled.div`
  img {
    width: 16px;
    height: 16px;
    margin-left: 28px;
    animation: ${switchAnim} 0.4s ease-in-out;
  }
`;
