import React, { Component } from "react";
import { Container } from "./styles";
import Spinner from "../SpinnerSmall";
import { withRouter } from "react-router-dom";
import Icon from "../Icon";
import { Icon as AntIcon } from "antd";

class Message extends Component {
  getIcon = () => {
    switch (this.props.type) {
      case "default":
        return <span />;
      case "error":
        return <Icon src={require("../../assets/warning.svg")} />;
      case "success":
        return (
          <AntIcon
            style={{ color: "#5D95AA", fontWeight: "800" }}
            type="check"
            twoToneColor="#5D95AA"
          />
        );
      case "warning":
        return <Icon src={require("../../assets/warning.svg")} />;
      case "loading":
        return <Spinner />;
      default:
        return <span />;
    }
  };

  render() {
    return (
      <Container
        type={this.props.type}
        clickable={!!this.props.redirectLink}
        onClick={
          this.props.redirectLink
            ? () => this.props.history.push(this.props.redirectLink)
            : undefined
        }
      >
        <div className="message-header">
          <div className="title-container">
            <span className="icon-container">{this.getIcon()}</span>
            <p className="title">{this.props.description}</p>
          </div>
          <img
            onClick={event => {
              this.props.closeMessage();
              event.stopPropagation();
            }}
            src={require("../../assets/close.svg")}
            className="close"
            alt="close"
          />
        </div>
      </Container>
    );
  }
}

export default withRouter(Message);
