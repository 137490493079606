export function array_helpers(arr: number[]): number {
    return arr.reduce((prev, current) => {
        return prev + current
    }, 0)
}

export function max_array(arr: number[]): number {
    return arr.reduce((prev, current) => {
        return Math.max(prev, current)
    }, Number.NEGATIVE_INFINITY)
}
export function max_amplitude(arr: Float32Array): number {
    let max=Number.NEGATIVE_INFINITY;
    for (let a=0;a<arr.length;a++){
        let abs = Math.abs(arr[a]);
        if(abs>max){
            max = abs;
        }
    }
    return max;
}