import React, { Component } from "react";
import { SUGGEST_QUERY } from "./SearchContext/queries";
import { withApollo } from "react-apollo";

export const QueueContext = React.createContext();

const formatData = (data, noFormat) => {
  if (!noFormat) {
    const formattedData = data.map(item => ({ track: item }));
    return formattedData;
  }
  return data;
};
class QueueProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percentagePlayed: 0,
      // The current page's track listing
      initialPlaylist: null,
      // The shared track listing for the App
      playlist: [],
      loading: false,
      playing: false,
      currentTracker: 0,
      queueTracker: 0,
      seeked: false,
      addToQueueMessage: false,
      audioLoaded: false,
      setPlaying: isPlaying => {
        this.setState({ playing: isPlaying });
      },
      setPercentagePlayed: percentage => {
        this.setState({ percentagePlayed: percentage });
      },
      setAudioLoaded: isAudioLoaded => {
        this.setState({ audioLoaded: isAudioLoaded });
      },
      moveCurrentTracker: step => {
        this.setState(prevState => ({
          currentTracker: prevState.currentTracker + step
        }));
      },
      moveQueueTracker: step => {
        this.setState(prevState => ({
          queueTracker: prevState.queueTracker + step
        }));
      },
      playNext: () => {
        this.state.moveCurrentTracker(1);
        this.state.queueTracker && this.state.moveQueueTracker(-1);
        this.state.setPercentagePlayed(0);
      },
      playPrevious: () => {
        this.state.moveCurrentTracker(-1);
        this.state.queueTracker && this.moveQueueTracker(1);
        this.state.setPercentagePlayed(0);
      },
      addToQueueAtCurrent: track => {
        let playlist = Object.assign([], this.state.playlist);

        playlist.splice(this.state.currentTracker, 0, { track: track });

        this.setState({ playlist: playlist });
      },
      addToQueue: track => {
        let playlist = Object.assign([], this.state.playlist);

        playlist.splice(
          this.state.currentTracker + this.state.queueTracker + 1,
          0,
          { track: track }
        );

        this.state.moveQueueTracker(1);

        //This is the playlist including the songs on queue
        this.setState({ playlist: playlist });
        this.setState({ addToQueueMessage: true });
        setTimeout(() => {
          this.setState({ addToQueueMessage: false });
        }, 3000);
      },
      playVariation: track => {
        let playlist = Object.assign([], this.state.playlist);

        playlist.splice(this.state.currentTracker + 1, 0, { track: track });
        this.state.moveCurrentTracker(1);

        this.setState({ playlist: playlist });
      },

      removeFromQueue: index => {
        let playlist = Object.assign([], this.state.playlist);
        playlist.splice(index, 1);

        // Before currentTracker
        if (index < this.state.currentTracker) {
          this.state.moveCurrentTracker(-1);
        }

        // INCLUSIVE - Between currentTracker and currentTracker + queueTracker
        if (
          this.state.queueTracker &&
          index >= this.state.currentTracker &&
          index <= this.state.currentTracker + this.state.queueTracker
        ) {
          this.state.moveQueueTracker(-1);
        }

        // Last item
        if (!this.state.playlist[index + 1]) {
          index === this.state.currentTracker &&
            this.state.setCurrent(this.state.currentTracker - 1);
        }

        this.setState({ playlist: playlist });
      },
      setCurrent: index => {
        this.setState({ currentTracker: index });
      },
      setPlaylist: (data, noFormat) => {
        const formattedData = formatData(data, noFormat);

        if (this.state.queueTracker > 0) {
          let playlist = Object.assign([], this.state.playlist);

          //This is the list of a playlist without the queue
          let initialPlaylist = Object.assign([], this.state.initialPlaylist);

          //Taking out only the songs in queue
          let songsinqueue = playlist.slice(
            this.state.currentTracker + 1,
            this.state.currentTracker + this.state.queueTracker + 1
          );

          // This adds the queues to the list of songs
          // initialPlaylist.splice(...[ct.index + 1, 0].concat(songsinqueue));
          initialPlaylist.splice(
            this.state.currentTracker + 1,
            0,
            ...songsinqueue
          );

          this.setState({ playlist: initialPlaylist });
        }

        //Set a copy of the track list
        this.state.initialPlaylist ||
          this.setState({ initialPlaylist: formattedData });

        this.setState({ playlist: formattedData });
      },
      clearPlaylist: () => {
        //Removes
        let playlist = Object.assign([], this.state.playlist);

        playlist.splice(
          this.state.currentTracker + 1,
          playlist.length - this.state.currentTracker + 1
        );

        this.setState({
          playlist: playlist,
          queueTracker: 0,
          loading: false
        });
      },
      setSeeked: isSeeked => {
        this.setState({ seeked: isSeeked });
      }
    };
  }

  async componentDidMount() {
    // seed is set in search provider file
    const seed = parseInt(localStorage.getItem("seed"));
    try {
      this.setState({ loading: true });
      const res = await this.props.client.query({
        query: SUGGEST_QUERY,
        fetchPolicy: "network-only",
        variables: {
          filters: [],
          from: undefined,
          size: 10,
          seed
        }
      });

      const tracks = res.data.suggest.map(item => ({
        track: { ...item._source, id: item._source.id.toString() }
      }));

      this.setState({ playlist: tracks });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <QueueContext.Provider
        value={{
          currentTracker: this.state.currentTracker,
          queueTracker: this.state.queueTracker,
          moveCurrentTracker: this.state.moveCurrentTracker,
          moveQueueTracker: this.state.moveQueueTracker,
          playlist: this.state.playlist,
          currentTrack:
            this.state.playlist.length === 0
              ? undefined
              : this.state.playlist[this.state.currentTracker],
          playNext: this.state.playNext,
          playing: this.state.playing,
          setPlaying: this.state.setPlaying,
          playPrevious: this.state.playPrevious,
          setCurrent: this.state.setCurrent,
          percentagePlayed: this.state.percentagePlayed,
          seeked: this.state.seeked,
          setPercentagePlayed: this.state.setPercentagePlayed,
          setSeeked: this.state.setSeeked,
          addToQueue: this.state.addToQueue,
          addToQueueAtCurrent: this.state.addToQueueAtCurrent,
          playVariation: this.state.playVariation,
          removeFromQueue: this.state.removeFromQueue,
          setPlaylist: this.state.setPlaylist,
          clearPlaylist: this.state.clearPlaylist,
          setAudioLoaded: this.state.setAudioLoaded,
          audioLoaded: this.state.audioLoaded,
          addToQueueMessage: this.state.addToQueueMessage
        }}
      >
        {this.props.children}
      </QueueContext.Provider>
    );
  }
}

export default withApollo(QueueProvider);
