import styled, { keyframes } from "styled-components";
import ReactSVG from "react-svg";

const menuAnimation = keyframes`
  0%{
    opacity: 0.6;
    transform: translateY(-15px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const MobileContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 99vw;
  max-height: 380px;
  z-index: ${props => (props.hasAutoCompleteResults ? 0 : 150)};
  color: ${props => props.theme.white};

  .mobile-visualization {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 64px;
    background: linear-gradient(#1f1f1f00, #1f1f1f, #1f1f1f);
  }
  .mobile-waveform {
    grid-column: 1/6;
    grid-row: 1/2;
  }
  .playback-control {
    grid-column: 1/2;
    grid-row: 1/2;
    /* place-self: center; */
    padding-left: 19px;
    /* Check Waveform component and added 1 more z-index value */
    z-index: 161;

    .toggle-playback {
      max-width: 42px;
      cursor: pointer;
      padding: 10px;
      box-sizing: content-box;
    }
  }
  .details-and-queue {
    background-color: ${props => props.theme.backgroundDark};
  }

  .mobile-details-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  .mobile-details {
    grid-column: 1/5;
    padding-left: 34px;
  }
  .separator {
    width: auto;
    height: 2px;
    display: block;
    background-color: ${props => props.theme.backgroundTag};
    margin: 17px 20px 0;
  }
  .mobile-title {
    font-size: ${props => props.theme.p4b.fs};
    font-family: ${props => props.theme.p4b.ff};
    color: ${props => props.theme.textLight};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
  .mobile-album {
    font-size: ${props => props.theme.p4r.fs};
    font-family: ${props => props.theme.p4r.ff};
    color: ${props => props.theme.textDark};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.textLight};
    }
  }
  .mobile-playlist-toggle {
    grid-column: 5/6;
    max-width: 21px;
    height: auto;
    cursor: pointer;
    padding-right: 66px;
    justify-self: right;
    opacity: 0.55;
    transition: 0.2s opacity ease-out;
  }
  .active-playlist-button {
    opacity: 1;
  }
`;

export const FavoriteSVG = styled(ReactSVG)`
  max-width: 18px;
  margin-right: 11px;
  & svg path {
    fill: ${props => (props.activeicon ? props.theme.white : null)};
    stroke: ${props => (props.activeicon ? props.theme.white : null)};
  }
  > div {
    display: flex;
    padding-bottom: 2px;
  }
`;

export const DrawerContainer = styled.div`
  .ant-drawer-content {
    overflow: visible;
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-rows: auto;

  .toast-container {
    margin-bottom: 4px;
  }
  .toast {
    position: relative;
  }
  .player-container {
    display: grid;
    grid-column-gap: 32px;
    background-color: ${props => props.theme.backgroundDark};
    padding: 23px 40px;

    @media only screen and (min-width: 780px) {
      grid-template-columns: 186px auto 1fr 100px auto auto;
    }
    @media only screen and (min-width: 1200px) {
      grid-template-columns: 186px auto 1fr 120px auto auto;
    }

    audio {
      display: none;
    }

    .details {
      display: grid;
      grid-template-columns: 54px auto;
      grid-template-rows: 32px 32px;
      grid-column-gap: 10px;
      justify-self: start;
      place-items: center;
      width: 186px;

      .thumbnail {
        grid-row: 1/3;
        justify-self: start;
        width: 54px;
        height: 54px;
        border-radius: 4px;
      }

      .title {
        grid-row: 1/2;
        grid-column: 2/3;
        justify-self: start;
        align-self: end;
        font-size: ${props => props.theme.p3b.fs};
        font-family: ${props => props.theme.p3b.ff};
        color: ${props => props.theme.textLight};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        width: 100%;

        &:hover {
          text-decoration: underline;
        }
      }

      .album {
        grid-row: 2/3;
        grid-column: 2/3;
        justify-self: start;
        align-self: start;
        font-size: ${props => props.theme.p3r.fs};
        font-family: ${props => props.theme.p3r.ff};
        color: ${props => props.theme.textGrey};
        cursor: pointer;
        width: 100%;

        .album-name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &:hover {
          text-decoration: underline;
          color: ${props => props.theme.textLight};
        }
      }
    }

    .playback-controls {
      grid-column: 2/3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 118px;
    }

    .change-track {
      cursor: pointer;
      max-width: 22px;
      opacity: 0.15;
      &:hover {
        opacity: 0.7;
      }
      &:active {
        transform: scale(0.95);
      }
    }

    .active-control {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }

    .toggle-playback {
      max-width: 42px;
      cursor: pointer;

      &:hover {
        filter: brightness(1.2);
      }
      &:active {
        transform: scale(0.95);
      }
    }

    .duration {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 70px;
      font-size: ${props => props.theme.p3r.fs};
      font-family: ${props => props.theme.p3m.ff};
      color: ${props => props.theme.textDark};
    }
    .extra-controls {
      display: flex;
      align-items: center;
      position: relative;
      gap: 12px;

      @media only screen and (min-width: 780px) {
        justify-content: space-between;
      }
      @media only screen and (min-width: 1200px) {
        justify-content: space-evenly;
      }
    }

    .volume {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      img {
        max-width: 22px;
        height: auto;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .slider {
      padding: 16px 12px;
      height: 180px;
      background-color: ${props => props.theme.backgroundDark};
      position: absolute;
      bottom: 56px;
    }

    .ant-slider-track {
      background-color: ${props => props.theme.highlight};
      &:hover {
        background-color: ${props => props.theme.highlight};
      }
    }
    .ant-slider {
      &:hover {
        .ant-slider-track {
          background-color: ${props => props.theme.highlight};
        }
      }
    }

    .playlist-button {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 21px;
      height: auto;
      cursor: pointer;

      opacity: 0.55;
      transition: 0.2s opacity ease-out;
    }
    .active-playlist-button {
      opacity: 1;
    }

    .editor-admin-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 21px;
      height: auto;
      cursor: pointer;

      opacity: 0.55;
      transition: 0.2s opacity ease-out;
    }

    input[type="range"] {
      box-sizing: content-box;
      background-color: ${props => props.theme.backgroundDark};
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
      height: 40px;
      -webkit-appearance: none;
      padding: 4px 18px;
      width: 150px;
    }
    input[type="range"]:focus {
      outline: none;
    }
    input[type="range"]::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      background: #2b6a9d;
      border-radius: 0px;
    }
    input[type="range"]::-webkit-slider-thumb {
      border: 2px solid #2b6a9d;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background: #ffffff;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -6px;
    }
    input[type="range"]:focus::-webkit-slider-runnable-track {
      background: #2b6a9d;
    }
    input[type="range"]::-moz-range-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      background: #2b6a9d;
      border-radius: 0px;
    }
    input[type="range"]::-moz-range-thumb {
      box-shadow: 0px 0px 0px #000031;
      border: 2px solid #2b6a9d;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background: #ffffff;
      cursor: pointer;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > img {
        padding: 12px;
        cursor: pointer;
      }
    }

    .actions-popover {
      animation: ${menuAnimation} 0.5s ease;
      animation-direction: alternate;
      position: absolute;
      width: 200px;
      z-index: 10;
      bottom: 60px;
      @media only screen and (min-width: 780px) {
        right: 30px;
      }
      @media only screen and (min-width: 1200px) {
        right: 75px;
      }
      padding: 10px 0 60px;
      font-size: ${props => props.theme.p3m.fs};
      font-family: ${props => props.theme.p3m.ff};
      color: ${props => props.theme.white};
      min-width: 160px;

      .actions-wrapper {
        background-color: ${props => props.theme.backgroundDark};
        padding: 10px 0;
      }

      .triangle {
        display: flex;
        justify-content: flex-end;
        margin-top: -1px;
        width: 100%;

        > img {
          width: auto;
          transform: rotateX(180deg);
          margin-right: 10px;
        }
      }
    }
    .action-item {
      display: flex;
      align-items: center;
      padding: 12px 25px;
      cursor: pointer;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
    .action-item img {
      max-width: 18px;
      margin-right: 11px;
    }
  }
`;
