import styled from "styled-components";

export const Container = styled.div`
  height: ${({ theme }) => theme.editor.editorHeaderHeight};
  background-color: ${({ theme }) => theme.editor.backgroundDark};
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  align-items: center;

  .player-container {
    display: grid;
    width: 100%;
    grid-column-gap: 32px;
    align-items: center;
    background-color: ${props => props.theme.backgroundDark};
    padding: 23px 40px;
    grid-template-columns: 150px auto 0 ${({ tightLayout }) =>
      tightLayout ? "190px" : "124px"} auto;

    @media only screen and (min-width: 1000px) {
      grid-template-columns: 186px auto 1fr ${({ tightLayout }) =>
        tightLayout ? "190px" : "124px"} auto;
    }

    audio {
      display: none;
    }

    .details {
      display: grid;
      grid-template-columns: 54px auto;
      grid-template-rows: 32px 32px;
      grid-column-gap: 10px;
      justify-self: start;
      place-items: center;
      width: 186px;

      .thumbnail {
        grid-row: 1/3;
        justify-self: start;
        width: 54px;
        height: 54px;
        border-radius: 4px;
      }

      .title {
        grid-row: 1/2;
        grid-column: 2/3;
        justify-self: start;
        align-self: end;
        font-size: ${props => props.theme.p3b.fs};
        font-family: ${props => props.theme.p3b.ff};
        color: ${props => props.theme.textLight};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        width: 100%;

        &:hover {
          text-decoration: underline;
        }
      }

      .album {
        grid-row: 2/3;
        grid-column: 2/3;
        justify-self: start;
        align-self: start;
        font-size: ${props => props.theme.p3r.fs};
        font-family: ${props => props.theme.p3r.ff};
        color: ${props => props.theme.textGrey};
        cursor: pointer;
        width: 100%;

        .album-name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &:hover {
          text-decoration: underline;
          color: ${props => props.theme.textLight};
        }
      }
    }

  #demo-subscribe-popover {
    background: ${({ theme }) => theme.editor.backgroundDark};
    width: 300px;
    padding: 24px;
    border-radius: ${({ theme }) => theme.editor.containerRadius};
    box-shadow: ${({ theme }) => theme.boxShadowBottom};

    > *:not(:first-child) {
      margin-top: 8px;
    }

    > p {
      font-family: ${({ theme }) => theme.editor.fontLight};
    }

    .btn-subscribe {
      background-color: ${({ theme }) => theme.subscribe};
      color: ${({ theme }) => theme.textLight};
      padding: 0 20px;
      margin-top: 12px;
    }
  }

  button.btn-demo:hover {
    cursor: default !important;
  }
`;

export const RightControls = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: flex-end;

  button:not(.btn-master-volume) {
    transition: transform 0.3s ease-in-out;
  }

  button:not(.btn-master-volume):hover {
    transform: scale(1.1);
  }

  button > svg {
    width: 18px;
    height: 18px;
  }
`;
