import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Arrow, Button, Container, Inner } from "./styles";
import ClosableTag from "../ClosableTag";
import { SearchContext } from "../../context";
import useModal from "../../hooks/useModal";
import FilterDrawer from "../FilterDrawer";
import AggregateView from "../AggregateView";

const SearchFilters = props => {
  const search = useContext(SearchContext);
  const [showMore, toggleShowMore] = useState(false);
  const [heightBreakPoint, setBreakPoint] = useState(48);
  const [height, setHeight] = useState(0);
  const filterDrawer = useModal();

  const history = useHistory();

  const tagsContainer = useRef(null);

  const tagsContainerHeight =
    tagsContainer.current && tagsContainer.current.getBoundingClientRect();

  useEffect(() => {
    if (tagsContainer.current) {
      const height = tagsContainer.current.getBoundingClientRect().height;
      setHeight(height);
    }
  }, [tagsContainerHeight]);

  useEffect(() => {
    if (!showMore && tagsContainer.current) {
      tagsContainer.current.scrollTo(0, 0); //TODO: not working for some reason
    }
  }, [showMore]);

  useEffect(() => {
    if (props.mobileView) setBreakPoint(75);
  }, [props.mobileView]);

  return (
    <>
      <Container mobileView={props.mobileView}>
        <Inner
          mobileView={props.mobileView}
          containerHeight={height}
          showMore={showMore}
          heightBreakPoint={heightBreakPoint}
          onMouseLeave={
            props.mobileView
              ? () => {}
              : () => {
                  tagsContainer.current.scrollTo(0, 0); //TODO: not working for some reason

                  toggleShowMore(false);
                }
          }
          onMouseEnter={
            props.mobileView
              ? () => {}
              : () => {
                  toggleShowMore(true);
                }
          }
        >
          <div className={`selected-tags `} ref={tagsContainer}>
            {props.mobileView && !props.static && (
              <Button
                style={{
                  width: 98,
                  height: 24,
                  marginRight: 15,
                  marginBottom: 10
                }}
                onClick={filterDrawer.open}
              >
                <img
                  className="icon"
                  src={require("../../assets/plus.svg")}
                  alt="blue-dot"
                />
                <p className="p4b">Add Filter</p>
              </Button>
            )}
            {search.selectedAggregates.length !== 0 &&
              search.selectedAggregates.map(item => {
                return <AggregateView aggregate={item} />;
              })}
            {search.searchFilters
              .filter(item => !item.aggregate)
              .map(item => {
                if (item.hidden) return null;
                if (item.filterType === "range") {
                  return (
                    <div className="filter" key={item.filterName}>
                      <ClosableTag
                        onSearchFilter={true}
                        onClose={() => {
                          search.removeFilter(item.filterName, "range");
                        }}
                        range={[item.min, item.max]}
                        text={item.filterName}
                        minWidth={124}
                        minHeight={props.mobileView ? 20 : null}
                        isActive={true}
                        background={
                          item.filterName.toLowerCase() === "duration" ||
                          item.filterName === "Length" ||
                          item.filterName === "BPM"
                            ? "#5D95AA"
                            : "#363636"
                        }
                      />
                    </div>
                  );
                }
                if (item.filterType === "dateRange") {
                  return (
                    <div className="filter" key={item.filterName}>
                      <ClosableTag
                        onSearchFilter={true}
                        onClose={() => {
                          search.removeFilter(item.filterName, "dateRange");
                        }}
                        width="auto"
                        range={[1, 5]}
                        text={item.filterName}
                        isActive={true}
                      />
                    </div>
                  );
                }

                return (
                  <div className="filter" key={item.filterValue}>
                    <ClosableTag
                      onSearchFilter={true}
                      onClose={() => {
                        search.removeFilter(item.filterValue, "selectable");
                        if (item.filterName === "track") {
                          history.push("/browse");
                        }
                      }}
                      range={[1, 5]}
                      isActive={true}
                      isKey={item.filterName === "key"}
                      text={item.filterValue}
                    />
                  </div>
                );
              })}

            {search.hoveredFilter &&
            (search.hoveredFilter.filterType === "range" ||
              search.hoveredFilter.filterType === "dateRange") ? (
              <div className="filter">
                <ClosableTag
                  faded={true}
                  onClose={() => {}}
                  range={[search.hoveredFilter.min, search.hoveredFilter.max]}
                  text={search.hoveredFilter.filterName}
                  minWidth={
                    search.hoveredFilter.filterType === "range" ? 124 : 93
                  }
                  minHeight={props.mobileView ? 20 : null}
                  isActive={true}
                  background={
                    search.hoveredFilter.filterName === "Length" ||
                    search.hoveredFilter.filterName === "BPM"
                      ? "#5D95AA"
                      : "#363636"
                  }
                />
              </div>
            ) : (
              search.hoveredFilter && (
                <div className="filter">
                  <ClosableTag
                    onClose={() => {}}
                    range={[1, 5]}
                    isActive={true}
                    text={search.hoveredFilter.filterValue}
                    faded={true}
                    isKey={
                      search.hoveredFilter.filterName.toLowerCase() === "key"
                    }
                  />
                </div>
              )
            )}
          </div>
        </Inner>
        {height > heightBreakPoint + 1 && (
          <>
            {
              <div
                onMouseLeave={
                  props.mobileView
                    ? () => {}
                    : () => {
                        toggleShowMore(false);
                      }
                }
                onMouseEnter={
                  props.mobileView
                    ? () => {}
                    : () => {
                        toggleShowMore(true);
                      }
                }
                onClick={
                  props.mobileView
                    ? () => toggleShowMore(prev => !prev)
                    : () => {}
                }
                className={`more-icon-container icon-${showMore}`}
              >
                <Arrow
                  src={require("../../assets/arrow-right.svg")}
                  alt="more"
                />
              </div>
            }
          </>
        )}
      </Container>
      <FilterDrawer drawerState={filterDrawer} />
    </>
  );
};

export default withRouter(SearchFilters);
