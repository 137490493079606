import { Reducer } from "redux";
import {
  LOAD_INITIAL_TRACK_DATA,
  SET_EDITOR_DATA_DUMP,
  SET_EDITOR_OPENABLE,
  SET_EDITOR_DEMO,
  SET_FREE_STEMS,
  LoadInitialTrackDataAction,
  SetEditorDataDumpAction,
  SetEditorOpenableAction,
  SetEditorDemoAction,
  SetFreeStemsAction
} from "../actions/actionTypes";
import { InitialTrackData } from "../store";
import { emptyInitialTrackData } from "../store/test_initial_store";
import {
  UPDATE_DENSITY,
  UPDATE_ENERGY,
  UPDATE_ENSEMBLE,
  UPDATE_GRAVITY,
  UPDATE_MELODY,
  UPDATE_MOOD,
  UPDATE_RHYTHM,
  UPDATE_TENSION,
  UpdateDensityAction,
  UpdateEnergyAction,
  UpdateEnsembleAction,
  UpdateGravityAction,
  UpdateMelodyAction,
  UpdateMoodAction,
  UpdateRhythmAction,
  UpdateTensionAction,
  SET_TAGS,
  SetTagsAction,
  SET_SYSTEM_TAGS,
  SET_INSTRUMENTS,
  SET_THEMATIC_CATEGORIES,
  SetInstrumentsAction,
  SetThematicCategoriesAction,
  SetSystemTagsAction
} from "../actions/InitialTrackDataTypes";
import {
  UpdateBarAction,
  UpdateBpmAction,
  UpdateGainAction,
  UpdateInitialBarAction,
  UpdateKeyAction,
  UPDATE_BAR,
  UPDATE_BPM,
  UPDATE_GAIN,
  UPDATE_INITIAL_BEAT,
  UPDATE_KEY
} from "../actions/EditorStateTypes";

export const initialTrackDataReducer: Reducer<
  InitialTrackData,
  | LoadInitialTrackDataAction
  | SetEditorDataDumpAction
  | SetEditorOpenableAction
  | SetEditorDemoAction
  | SetFreeStemsAction
  | UpdateMoodAction
  | UpdateDensityAction
  | UpdateEnergyAction
  | UpdateGravityAction
  | UpdateEnsembleAction
  | UpdateMelodyAction
  | UpdateTensionAction
  | UpdateRhythmAction
  | UpdateKeyAction
  | UpdateBpmAction
  | UpdateBarAction
  | UpdateInitialBarAction
  | UpdateGainAction
  | SetTagsAction
  | SetSystemTagsAction
  | SetInstrumentsAction
  | SetThematicCategoriesAction
> = (state = emptyInitialTrackData, action) => {
  switch (action.type) {
    case LOAD_INITIAL_TRACK_DATA:
      return { ...action.initialTrackData };
    case SET_EDITOR_DATA_DUMP:
      return {
        ...state,
        editorDataDump: action.editorDataDump
      };
    case SET_EDITOR_OPENABLE:
      return {
        ...state,
        editorOpenable: action.editorOpenable
      };
    case SET_EDITOR_DEMO:
      return {
        ...state,
        editorDemo: action.editorDemo
      };
    case SET_FREE_STEMS:
      return {
        ...state,
        freeStemFiles: action.freeStems
      };
    case UPDATE_MOOD:
      return {
        ...state,
        mood: action.mood
      };
    case UPDATE_DENSITY:
      return {
        ...state,
        density: action.density
      };
    case UPDATE_ENERGY:
      return {
        ...state,
        energy: action.energy
      };
    case UPDATE_GRAVITY:
      return {
        ...state,
        gravity: action.gravity
      };
    case UPDATE_ENSEMBLE:
      return {
        ...state,
        ensemble: action.ensemble
      };
    case UPDATE_MELODY:
      return {
        ...state,
        melody: action.melody
      };
    case UPDATE_TENSION:
      return {
        ...state,
        tension: action.tension
      };
    case UPDATE_RHYTHM:
      return {
        ...state,
        rhythm: action.rhythm
      };
    case UPDATE_BAR:
      return {
        ...state,
        beatsPerMeasure: action.bar
      };
    case UPDATE_BPM:
      return {
        ...state,
        bpm: action.bpm
      };
    case UPDATE_INITIAL_BEAT:
      return {
        ...state,
        initialBeat: action.initialBeat
      };
    case UPDATE_GAIN:
      return {
        ...state,
        gain: action.gain
      };
    case UPDATE_KEY:
      return {
        ...state,
        key: action.key
      };
    case SET_TAGS:
      return {
        ...state,
        tags: action.tags
      };
    case SET_SYSTEM_TAGS:
      return {
        ...state,
        systemTags: action.systemTags
      };
    case SET_INSTRUMENTS:
      return {
        ...state,
        instruments: action.instruments
      };
    case SET_THEMATIC_CATEGORIES:
      return {
        ...state,
        thematicCategories: action.thematicCategories
      };
    default:
      return state;
  }
};
