import gql from "graphql-tag";
import { fragments } from "../../../utils/fragments";

export const CREATE_PROJECT_MUTATION = gql`
  mutation(
    $projectName: String
    $description: String
    $projectUrl: String
    $creator: ID!
    $team: ID
  ) {
    createProject(
      data: {
        name: $projectName
        description: $description
        url: $projectUrl
        creator: $creator
        team: $team
      }
    ) {
      id
    }
  }
`;

export const PROJECT_DETAIL_QUERY = gql`
  query project($id: ID!) {
    project(id: $id) {
      ...ProjectData
    }
  }
  ${fragments.projectQuery}
`;

export const PROJECTS_QUERY = gql`
  query($id: ID!) {
    userProjects(user: $id) {
      ...ProjectData
    }
  }
  ${fragments.projectQuery}
`;

export const CREATE_PROJECT_MEMBER_MUTATION = gql`
  mutation($userEmails: [String], $projectId: ID) {
    createProjectMember(
      data: { userEmails: $userEmails, project: $projectId }
    ) {
      id
      user {
        email
        id
      }
    }
  }
`;
