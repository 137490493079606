import React, { useContext, useEffect, useState } from "react";
import { Tree, Icon } from "antd";
import { useDispatch } from "react-redux";
import { SetAlertAction, SET_ALERT } from "../../actions/actionTypes";
import { getAvailableProjects, getProjectTracks } from "../../api/WebAPI";
import { ProjectCustomMix, PublicUser } from "../../store";
import { Container } from "./styles";
import { AuthContext } from "../../../context/AuthProvider";
import Spinner from "../../../components/Spinner";
import AvatarTooltipName from "../../../components/Avatar";
import { formatFullDate } from "../../../utils/formatDateHelper";

export interface FileDataNode {
  title: string;
  key: string;
  originalTrack?: string;
  originalTrackId?: string;
  lastModified?: string;
  lastModifiedUser?: PublicUser;
  isLeaf?: boolean;
  children?: FileDataNode[];
}

export const CustomMixPicker = ({
  onDataLoaded,
  onSelectProject,
  onSelectCustomMix,
  titleQuery,
  highlightDirs = true
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedCustomMix, setSelectedCustomMix] = useState(null);

  const user = useContext(AuthContext);

  // Setup File Directory View
  const initProjectTreeData: FileDataNode[] = [];
  const [projectTreeData, setProjectTreeData] = useState(initProjectTreeData);
  const [expandedProjectKeys, setExpandedProjectKeys] = useState([]);
  const [selectedFileDataNodeKeys, setSelectedFileDataNodeKeys] = useState([]);
  const { TreeNode, DirectoryTree } = Tree;

  const onLoadData = clickedNode =>
    new Promise<void>(resolve => {
      const children = clickedNode.props.children;
      const key = clickedNode.props.eventKey;
      if (children) {
        resolve();
        return;
      }

      getCustomMixesForProject(key)
        .then(customMixes => {
          const customMixFileDataNodes = customMixes.map(
            (customMix: ProjectCustomMix) => {
              return {
                title: customMix.title,
                key: customMix.id,
                originalTrack: customMix.track.name,
                originalTrackId: customMix.trackId,
                lastModified: formatFullDate(new Date(customMix.updatedAt)),
                lastModifiedUser: {
                  ...customMix.user
                },
                isLeaf: true
              };
            }
          );
          clickedNode.props.dataRef.children = customMixFileDataNodes;
          setProjectTreeData([...projectTreeData]);
          resolve();
        })
        .catch(error => {
          dispatch<SetAlertAction>({
            type: SET_ALERT,
            id: "SAVE_CUSTOM_MIX_ERROR",
            role: "error",
            title: "Error Saving",
            message:
              "There was an error retrieving your custom mixes. Please try again or contact our support team for assistance."
          });
          resolve();
        });
    });

  // Get the project list on render
  useEffect(() => {
    getAvailableProjects(user.user.id)
      .then(projects => {
        const projectFileDataNodes: FileDataNode[] = projects.map(project => {
          return {
            title: project.name.toString(),
            key: project.id.toString()
          };
        });
        setProjectTreeData(projectFileDataNodes);
        setLoading(false);
      })
      .catch(error => {
        dispatch<SetAlertAction>({
          type: SET_ALERT,
          id: "SAVE_CUSTOM_MIX_ERROR",
          role: "error",
          title: "Error Saving",
          message:
            "There was an error retrieving your projects. Please try again or contact our support team for assistance."
        });
      });
  }, [user, dispatch]);

  // check if props.titleQuery already exists within project and highlight
  useEffect(() => {
    if (selectedProject) {
      const projectsCustomMixes = getChildNodes(
        selectedProject.key,
        projectTreeData
      );

      let foundMatchingCustomMix = false;
      for (
        let iCustomMix = 0;
        iCustomMix < projectsCustomMixes.length;
        iCustomMix++
      ) {
        const theCustomMix = projectsCustomMixes[iCustomMix];
        if (theCustomMix.title === titleQuery) {
          foundMatchingCustomMix = true;
          setSelectedCustomMix(theCustomMix);
          setSelectedFileDataNodeKeys([theCustomMix.key]);
          break;
        }
      }

      if (!foundMatchingCustomMix) {
        setSelectedCustomMix(null);
        if (highlightDirs) setSelectedFileDataNodeKeys([selectedProject.key]);
      }
    }
  }, [titleQuery, projectTreeData, selectedProject, highlightDirs]);

  useEffect(() => {
    onSelectProject(selectedProject);
  }, [selectedProject, onSelectProject]);

  useEffect(() => {
    onSelectCustomMix(selectedCustomMix);
  }, [selectedCustomMix, onSelectCustomMix]);

  useEffect(() => {
    onDataLoaded(projectTreeData);
  }, [projectTreeData, onDataLoaded]);

  const getCustomMixesForProject = async projectId => {
    return getProjectTracks(projectId);
  };

  const getParentNode = (key: string, tree: FileDataNode[]) => {
    let parentNode;
    for (let iNode = 0; iNode < tree.length; iNode++) {
      const node = tree[iNode];
      if (node.children) {
        if (node.children.some(item => item.key === key)) {
          parentNode = node;
        } else if (getParentNode(key, node.children)) {
          parentNode = getParentNode(key, node.children);
        }
      }
    }
    return parentNode;
  };

  const getChildNodes = (key: string, tree: FileDataNode[]) => {
    for (let iNode = 0; iNode < tree.length; iNode++) {
      const node = tree[iNode];
      if (node.key === key) return node.children || [];
    }
    return [];
  };

  const selectProjectOrCustomMix = (fileDataNode: FileDataNode) => {
    let project = fileDataNode;
    const isCustomMix = fileDataNode.originalTrack != null;
    if (isCustomMix || highlightDirs)
      setSelectedFileDataNodeKeys([fileDataNode.key]);

    if (isCustomMix) {
      // get projectId from parent node
      const projectNode: FileDataNode = getParentNode(
        fileDataNode.key,
        projectTreeData
      );
      project = projectNode;
      setSelectedCustomMix(fileDataNode);
    } else {
      setSelectedCustomMix(null);
    }
    setSelectedProject(project);
  };

  const onSelectProjectOrCustomMix = (_, event) => {
    const fileDataNode: FileDataNode = event.node.props.dataRef;
    selectProjectOrCustomMix(fileDataNode);
  };

  const onProjectExpanded = (_, details) => {
    const fileDataNode: FileDataNode = details.node.props.dataRef;
    const expandedKey = fileDataNode.key;
    const wasExpanded = details.expanded;
    if (wasExpanded) {
      // compress any other open project folders and just view this one
      setExpandedProjectKeys([expandedKey]);
    } else {
      setExpandedProjectKeys([]);
    }
    selectProjectOrCustomMix(fileDataNode);
  };

  const renderTreeNodes = data =>
    data.map((item: FileDataNode) => {
      const fileNodeRender = (
        <table>
          <tbody>
            <tr>
              <td className="file-detail file-title ellipsis">
                <span>{item.title}</span>
              </td>
              <td className="file-detail file-original-track ellipsis">
                <span>{item.originalTrack}</span>
              </td>
              <td className="file-detail file-last-modified">
                <div className="file-detail-last-modified-container">
                  <span className="last-modified-date">
                    {item.lastModified ? item.lastModified : ""}
                  </span>
                  {item.lastModifiedUser && (
                    <AvatarTooltipName
                      name={item.lastModifiedUser.fullName}
                      className={"icon"}
                      profileColor={item.lastModifiedUser.profileColor}
                      profileIcon={item.lastModifiedUser.profileIcon}
                      size={22}
                    />
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      );

      if (item.children) {
        return (
          <TreeNode title={fileNodeRender} key={item.key} dataRef={item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          title={fileNodeRender}
          isLeaf={item.isLeaf}
          key={item.key}
          dataRef={item}
        />
      );
    });

  return (
    <>
      {loading && <Spinner height={"50px"} />}
      {!loading && projectTreeData.length > 0 && (
        <Container>
          <>
            <table id="project-selector-headers">
              <thead>
                <tr>
                  <th style={{ width: "37%" }}>Project</th>
                  <th
                    style={{
                      width: "30.8%",
                      visibility:
                        expandedProjectKeys.length > 0 ? "visible" : "hidden"
                    }}
                  >
                    Original Track
                  </th>
                  <th
                    style={{
                      visibility:
                        expandedProjectKeys.length > 0 ? "visible" : "hidden"
                    }}
                  >
                    Last Modified
                  </th>
                </tr>
              </thead>
            </table>
            <DirectoryTree
              className={"project-selector-tree"}
              switcherIcon={<Icon type="down" />}
              loadData={onLoadData}
              onSelect={onSelectProjectOrCustomMix}
              onExpand={onProjectExpanded}
              selectedKeys={selectedFileDataNodeKeys}
              expandedKeys={expandedProjectKeys}
              multiple={false}
            >
              {renderTreeNodes(projectTreeData)}
            </DirectoryTree>
          </>
        </Container>
      )}
    </>
  );
};
