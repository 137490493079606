import React from "react";
import { Drawer } from "antd";
import styled from "styled-components";
import MobileFilters from "../Filters/MobileFilters";

const Container = styled.div`
  /* ant-drawer-left ant-drawer-open */
`;

const Content = styled.div`
  padding: 34px 70px 100px 50px;

  .header {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  @media (max-width: 600px) {
    padding: 34px 30px 100px 30px;
  }
`;

const FilterDrawer = ({ drawerState }) => {
  // fix an antd issue where css props added to body to prevent scrolling are not removed
  const onDrawerClose = () => {
    document.body.style = {};
    document.body.classList.remove("ant-scrolling-effect");
    drawerState.close();
  };

  return (
    <Container>
      <Drawer
        title="Filter Drawer"
        placement="left"
        closable={true}
        maskClosable={true}
        onClose={onDrawerClose}
        visible={drawerState.visible}
        headerStyle={{ display: "none" }}
        width={320}
      >
        <Content>
          <div className="row header">
            <p className="p2b">Filter By</p>
            <div style={{ padding: 8 }} onClick={drawerState.close}>
              <img
                src={require("../../assets/close.svg")}
                alt="blue-dot"
                style={{
                  width: 12,
                  height: 12,
                  marginTop: -8,
                  marginRight: -8
                }}
              />
            </div>
          </div>
          <MobileFilters />
        </Content>
      </Drawer>
    </Container>
  );
};

export default FilterDrawer;
