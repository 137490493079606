import * as React from "react";
import { theme } from "../globalStyles";

export const SnapMagnetBeat = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill={theme.editor.textLight}
        shapeRendering="geometricPrecision"
        d="M1,8c0,4,0,4.4,0.1,5.2c0.3,1.9,1.2,3.8,2.4,5.3c0.9,1,1.9,1.8,3,2.4c3.9,2,8.6,1.1,11.6-2.3
	c1.5-1.7,2.4-3.7,2.7-6C21,12.2,21,11.4,21,8V4h-1.8H17v3.6c0,4,0,4-0.3,4.9c-0.6,2.1-2,3.8-3.9,4.4c-0.8,0.3-1.1,0.3-2,0.3
	c-0.7,0-0.9,0-1.3-0.1c-1.1-0.3-2-0.8-2.7-1.6c-0.9-0.9-1.5-2.2-1.8-3.6C5,11.5,5,11.2,5,7.7L5,4H3.1H1L1,8z"
      />
      <rect
        x="1"
        fill={theme.editor.textLight}
        shapeRendering="geometricPrecision"
        width="4"
        height="3"
      />
      <rect
        x="17"
        fill={theme.editor.textLight}
        shapeRendering="geometricPrecision"
        width="4"
        height="3"
      />
      <rect
        x="9"
        fill={theme.editor.textLight}
        shapeRendering="geometricPrecision"
        width="4"
        height="3"
      />
    </g>
  </svg>
);
