import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext
} from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import _ from "lodash";
import {
  SUBSCRIPTION,
  GET_USER_SUBSCRIPTIONS,
  PAYMENT,
  GET_PAYMENTS,
  VALIDATE_COUPON,
  VALIDATE_ORDER_DISCOUNT_CODE
} from "./queries";
import { GET_SUBSCRIPTION_FEE } from "../../pages/Shared/Checkout/queries";
import { AuthContext } from "../AuthProvider";
import { MessageContext } from "../MessageContext";
import * as ReactGA from "react-ga";

export const CartContext = createContext();
export const defaultLicenseType = "Podcast";
export const defaultLevel = "Standard";

const CartProvider = ({ children, client, history, location }) => {
  const levelCoefficients = { Basic: 1, Standard: 2, Professional: 4 };

  const auth = useContext(AuthContext);
  const { showMessage } = useContext(MessageContext);

  const [cartItems, setCartItems] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [total, setTotal] = useState(0);
  const [payments, setPayment] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [licenseType, setLicenseType] = useState(defaultLicenseType);
  const [level, setLevel] = useState(defaultLevel);
  const [purchasing, setPurchasing] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState({});
  const [selectedProject, setSelectedProject] = useState(null);
  const [discountCode, setDiscountCode] = useState(null);
  const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState(null);
  const [coupon, setCoupon] = useState(null);

  // If user logs out, set selected project to null.
  useEffect(() => {
    setSelectedProject(null);
  }, [auth.user.role]);

  // update plan details when subscription level changes (regular subscriptions)
  useEffect(() => {
    const isSubscription = currentSubscriptionPlan !== null;
    if (isSubscription && level in subscriptionPlans) {
      const subscriptionPlan = subscriptionPlans[level];
      setCurrentSubscriptionPlan({
        planId: subscriptionPlan.planId,
        subscriptionFee: subscriptionPlan.price,
        interval: subscriptionPlan.interval,
        intervalCount: subscriptionPlan.intervalCount
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionPlans, level]);

  /*
   * Calculates cart total and updates
   * Considers subscription plan, level, cart items, discount codes
   *
   * Note: if subscription selected, don't add cart items to the total, the blanket
   *       subscription covers everything.
   */
  const updateTotal = useCallback(() => {
    let totalPrice = 0;

    // blanket subscriptions cover everything, so we don't need to calculate cart items in total
    if (currentSubscriptionPlan) {
      totalPrice = currentSubscriptionPlan.subscriptionFee;
    } else {
      if (cartItems.length > 0) {
        cartItems.forEach(item => {
          totalPrice += item.price;
        });
      }
    }

    // consider level
    if (!currentSubscriptionPlan) totalPrice *= levelCoefficients[level] ?? 1;

    // consider discounts
    if (discountCode !== null && discountCode.valid) {
      if (discountCode.amount_off !== null) {
        totalPrice -= discountCode.amount_off;
      }
      if (discountCode.percent_off !== null) {
        totalPrice *= Math.max(0, 1 - discountCode.percent_off / 100.0);
      }
    }

    const roundedTotal = Math.round(totalPrice * 100) / 100;
    setTotal(roundedTotal);
    return roundedTotal;
  }, [
    currentSubscriptionPlan,
    cartItems,
    levelCoefficients,
    level,
    discountCode
  ]);

  // update pricing whenever cart items, subscription, discount code, or level changes
  useEffect(() => {
    updateTotal();
  }, [cartItems, currentSubscriptionPlan, discountCode, level, updateTotal]);

  /*
   * Rehydrate cart details from local storage
   * Cart items stored in local storage so it persists across session, user can come back to cart
   */
  useEffect(() => {
    // project
    const project = localStorage.getItem("selectedProject");
    setSelectedProject(JSON.parse(project));

    // cart items
    const items = JSON.parse(localStorage.getItem("cartItems")) ?? [];
    setCartItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Fetches payments made by the user
   */
  const fetchPayments = async () => {
    try {
      const res = await client.query({
        query: GET_PAYMENTS,
        fetchPolicy: "network-only"
      });
      setPayment(res.data.payments);
    } catch (err) {
      console.error(err);
    }
  };

  /*
   * Fetches subscriptions made by the user.
   */
  const fetchUserSubscriptions = async () => {
    try {
      const res = await client.query({
        query: GET_USER_SUBSCRIPTIONS,
        variables: { userId: auth.user.id },
        fetchPolicy: "network-only"
      });
      setSubscriptions(res.data.userSubscriptions);
    } catch (err) {
      console.error(err);
    }
  };

  /*
   * Fetch subscription plan details
   */
  const fetchSubscriptionPlans = async callback => {
    try {
      const { data } = await client.query({
        query: GET_SUBSCRIPTION_FEE
      });

      let plans = {};
      data.getPrice.forEach(item => {
        plans = { ...plans, [item.license]: { ...item } };
      });

      const defaultPlan = plans[level];
      setCurrentSubscriptionPlan({
        planId: defaultPlan.planId,
        subscriptionFee: defaultPlan.price,
        interval: defaultPlan.interval,
        intervalCount: defaultPlan.intervalCount
      });
      setSubscriptionPlans({ ...plans });

      if (callback) {
        callback(plans);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const removeCartItem = async cartItem => {
    const updated = cartItems.filter(item => item.id !== cartItem.id);
    localStorage.setItem("cartItems", JSON.stringify(updated));
    setCartItems(updated);
  };

  const addCartItem = async cartItem => {
    const updated = [
      ...cartItems,
      {
        ...cartItem,
        itemId: cartItem.id,
        name: cartItem.title || cartItem.name,
        type: cartItem.stem ? "Track" : "Album"
      }
    ];
    localStorage.setItem("cartItems", JSON.stringify(updated));
    setCartItems(updated);
  };

  /*
   * Gets price at checkout as a display string
   *
   * For orders: ${total}
   * For subscriptions: ${total}/${intervalCount} ${interval}
   */
  const getPriceString = () => {
    const displayTotal = parseInt(total) === total ? total : total.toFixed(2);
    if (currentSubscriptionPlan) {
      // create subscription price string
      return `$${displayTotal} / ${currentSubscriptionPlan.intervalCount > 1
        ? currentSubscriptionPlan.intervalCount
        : ""
        } ${currentSubscriptionPlan.interval}${currentSubscriptionPlan.intervalCount > 1 ? "s" : ""
        }`;
    } else {
      // create order price string
      return `$${displayTotal}`;
    }
  };

  const isFoundInCart = cartItem => {
    return cartItems.find(item => item.itemId === cartItem.id);
  };

  /*
   * Apply discount code to the cart for orders
   *
   * Arguments:
   *   Code: discount code value
   */
  const applyOrderDiscountCode = async code => {
    try {
      const { data } = await client.query({
        query: VALIDATE_ORDER_DISCOUNT_CODE,
        variables: {
          discountCode: code.trim()
        }
      });
      if (data.discount === null) throw new Error("Invalid Discount Code");

      // the discount code is valid, apply it
      setCoupon(code);
      setDiscountCode({
        id: data.discount.id,
        name: code,
        amount_off: null,
        duration: "once",
        duration_in_months: null,
        percent_off: data.discount.percent,
        valid: true
      });

      showMessage({
        type: "success",
        duration: 5000,
        title: "Discount Applied",
        description: `We applied discount code ${code} for ${data.discount.percent}% off!`
      });
    } catch (err) {
      showMessage({
        type: "error",
        duration: 5000,
        title: "Error",
        description:
          "We failed to validate your discount code. Please try again. "
      });
    }
  };

  /*
   * Remove the discount code
   */
  const removeDiscountCode = async () => {
    setCoupon(null);
    setDiscountCode(null);
  };

  /*
    description: discount code for subscription
    arguments:
      discountCode: discount code value
  */
  const applySubscriptionDiscountCode = async discountCode => {
    if (!currentSubscriptionPlan) return;

    try {
      const { data } = await client.query({
        query: VALIDATE_COUPON,
        variables: { name: discountCode.trim() }
      });

      const subscriptionDiscount = data.subscriptionDiscount;
      if (subscriptionDiscount.valid) {
        setCoupon(discountCode);
        setDiscountCode(subscriptionDiscount);
        showMessage({
          type: "success",
          duration: 5000,
          title: "Discount Applied",
          description: `We applied discount code ${discountCode.trim()} for ${subscriptionDiscount.percent_off
            ? `${subscriptionDiscount.percent_off}%`
            : `$${subscriptionDiscount.amount_off}`
            } off.`
        });
      }
    } catch (err) {
      showMessage({
        type: "error",
        duration: 5000,
        title: "Error",
        description: "Invalid discount code. Please try again."
      });
    }
  };

  /*
   * Creates a pending order in the database and initiates Stripe 1-time charge for cart items.
   *
   * Arguments:
   *  formValues: checkout form field data (credit card and project details)
   */
  const payForOrder = async formValues => {
    // this flow is for orders only, not subscriptions
    if (currentSubscriptionPlan !== null) return;

    // prepare form data for payment API call
    // if orderId=null, create a new order, otherwise try to look up as custom order to fulfill it
    const orderData = {
      ...formValues,
      email: auth.user.email ? auth.user.email : formValues.email,
      orderId: orderId,
      level: level,
      licenseType: licenseType,
      price: total,
      items: cartItems.map(item => ({
        id: item.id,
        type: item.type,
        price: item.price
      })),
      discountCode: discountCode?.name
    };
    if (formValues.sameName) orderData.nameOnLicense = "";
    delete orderData.sameName;
    setProjectId(orderData.projectId);

    const { data, errors } = await client.mutate({
      mutation: PAYMENT,
      variables: orderData
    });
    if (errors) {
      throw new Error(errors[0].message);
    }
    // error handling is done in parent of this call (e.g., invalid card)
    // let thrown error bubble up
    // error handling is done in parent of this call (e.g., invalid card)
    // let thrown error bubble up

    if (data.orderPayment) {
      cleanUpCart();
      ReactGA.event({
        category: "User",
        action: "PurchasedTrack",
        label: "Successful",
        value: total
      });
      history.push(`/order-confirmation/${data.orderPayment.id}`);
      showMessage({
        type: "success",
        duration: 5000,
        title: "Order Successful",
        description:
          "Your order was successful and your license and receipt is now available for download."
      });
    } else {
      throw new Error("Payment failed.");
    }
  };

  /*
   * Initiates a Stripe subscription
   */
  const payForSubscription = async (formValues, id) => {
    if (!currentSubscriptionPlan) return;

    const formData = _.omit(formValues, "sameName");
    setProjectId(formData.projectId);

    const { data, errors } = await client.mutate({
      mutation: SUBSCRIPTION,
      variables: {
        ...formData,
        planId: currentSubscriptionPlan.planId,
        type: level,
        licenseType: licenseType,
        coupon: coupon,
        subId: id
      }
    });
    if (errors) {
      throw new Error(errors[0].message);
    }
    // error handling is done in parent of this call (e.g., invalid card)
    // let thrown error bubble up

    if (data.subscription?.id) {
      cleanUpCart();

      ReactGA.event({
        category: "User",
        action: "Subscribed",
        label: "Successful",
        value: total
      });

      if (auth.user.role === "guest") {
        history.push(`/signup/${formData.email}`, {
          subscriptionPath: true
        });
      } else {
        showMessage({
          type: "success",
          duration: 5000,
          title: "Subscription Successful",
          description:
            "You have subscribed to a blanket license for your project."
        });
        history.push(`/orders`);
      }
    } else {
      // we weren't able to form a subscription for some reason, throw general error
      throw new Error("Payment failed");
    }
  };

  /*
    description: after we're done making a purchase we reset the data to their 
    initial values.
  */
  const cleanUpCart = async () => {
    localStorage.removeItem("cartItems");
    setOrderId(null);
    setSubscriptionId(null);
    setCartItems([]);
    setLevel(defaultLevel);
    setLicenseType(defaultLicenseType);
    setCurrentSubscriptionPlan(null);
    setDiscountCode(null);
    setTotal(0);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        setCartItems,
        addCartItem,
        removeCartItem,
        total,
        setTotal,
        getPriceString,
        isFoundInCart,
        applyOrderDiscountCode,
        applySubscriptionDiscountCode,
        discountCode,
        setDiscountCode,
        removeDiscountCode,
        licenseType,
        setLicenseType,
        level,
        purchasing,
        setPurchasing,
        setLevel,
        cleanUpCart,
        payments,
        payForOrder,
        payForSubscription,
        subscriptions,
        updateTotal,
        currentSubscriptionPlan,
        setCurrentSubscriptionPlan,
        orderId,
        setOrderId,
        subscriptionId,
        setSubscriptionId,
        fetchPayments,
        projectId,
        fetchSubscriptionPlans,
        fetchUserSubscriptions,
        setSubscriptionPlans,
        subscriptionPlans,
        selectedProject,
        setSelectedProject,
        location
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default withRouter(withApollo(CartProvider));
