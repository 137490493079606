import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import { IconButton } from "../../editor/components/Buttons/IconButton";
import { Volume } from "../../assets/Volume";
import { VolumeMuted } from "../../assets/VolumeMuted";
import { VolumeSlider } from "../../editor/components/VolumeSlider";
import { MasterVolumeContainer } from "./styles";

export function MasterVolumeControl({ audioPlayerEl }) {
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);

  // changes the playback volume
  const applyVolume = newVolume => {
    if (audioPlayerEl.current) {
      audioPlayerEl.current.volume = newVolume;
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const onChange = val => {
    setVolume(val / 100);
  };

  useEffect(() => {
    applyVolume(isMuted ? 0 : volume);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMuted, volume]);

  return (
    <MasterVolumeContainer>
      <Popover
        placement="bottomLeft"
        getPopupContainer={trigger => trigger}
        content={
          <VolumeSlider
            onChange={onChange}
            onAfterChange={onChange}
            currentVolume={volume}
          />
        }
        {...(isMuted && { visible: false })}
      >
        <IconButton onClick={toggleMute} svgWidth="20px" svgHeight="20px">
          {isMuted ? <VolumeMuted /> : <Volume />}
        </IconButton>
      </Popover>
    </MasterVolumeContainer>
  );
}
