import React from "react";
import { TagContainer } from "./styles";

const Tag = props => {
  return (
    <TagContainer {...props}>
      <p className="text"> {props.children}</p>
    </TagContainer>
  );
};

export default Tag;
