import { max_array } from "../util/array_helpers";
import { Project, Region } from "./index";
import crypto from "crypto";

export function applyCrossFades(state: Project) {
  const lanes = state.lanes.laneIds.map(id => state.lanes.byId[id]);
  const newRegionById = { ...state.regions.byId };

  /**
   * Apply overlap fade logic
   */

  lanes.forEach(t => {
    const regions = t.regionIds.map(id => state.regions.byId[id]).slice();

    regions.sort((a, b) => {
      return a.visualStartTime - b.visualStartTime;
    });

    for (let i = 0; i < regions.length; i++) {
      const thisRegion = regions[i];
      const prevRegion = i - 1 >= 0 ? regions[i - 1] : null;
      const nextRegion = i + 1 < regions.length ? regions[i + 1] : null;

      const isOverlapPrev =
        prevRegion !== null &&
        thisRegion.visualStartTime <
          prevRegion.visualStartTime + prevRegion.visualDuration;
      const isOverlapNext =
        nextRegion !== null &&
        nextRegion.visualStartTime <
          thisRegion.visualStartTime + thisRegion.visualDuration;

      let newStartFadeTime = thisRegion.startFadeTime;
      if (isOverlapPrev) {
        newStartFadeTime =
          prevRegion.visualStartTime +
          prevRegion.visualDuration -
          thisRegion.visualStartTime;
      } else {
        newStartFadeTime = thisRegion.startFadeTimeOverlap
          ? 0
          : thisRegion.startFadeTime; // if go from overlapping to non, change time to 0
      }

      let newEndFadeTime = thisRegion.endFadeTime;
      if (isOverlapNext) {
        newEndFadeTime =
          thisRegion.visualStartTime +
          thisRegion.visualDuration -
          nextRegion.visualStartTime;
      } else {
        newEndFadeTime = thisRegion.endFadeTimeOverlap
          ? 0
          : thisRegion.endFadeTime;
      }

      // sanity assertion: ensure start fade time < end fade time
      if (newStartFadeTime > thisRegion.visualDuration - newEndFadeTime) {
        newStartFadeTime = Math.max(
          0,
          thisRegion.visualDuration - newEndFadeTime - 1e-6
        );
      }

      newRegionById[thisRegion.id] = {
        ...thisRegion,
        startFadeTime: newStartFadeTime,
        endFadeTime: newEndFadeTime,
        startFadeTimeOverlap: isOverlapPrev,
        endFadeTimeOverlap: isOverlapNext
      };
    }
  });

  return {
    ...state,
    regions: {
      ...state.regions,
      byId: newRegionById
    }
  };
}

export function applyNewSongLength(state: Project): Project {
  const song_buffer = 6; // amount of time at the end to buffer by

  const song_length =
    max_array(
      state.lanes.laneIds.map(t => {
        return max_array(
          state.lanes.byId[t].regionIds
            .map(id => state.regions.byId[id])
            .map(region => region.visualStartTime + region.visualDuration)
        );
      })
    ) + song_buffer;

  return {
    ...state,
    customMixInfo: {
      ...state.customMixInfo,
      length: song_length
    }
  };
}

/*
 *  Determine if regions are in ok state after a movement.
 *  - FAIL IF AT ANY POINT 3 OR MORE INTERVALS OVERLAP
 *  - FAIL IF ANY INTERVAL IS ENTIRELY WITHIN ANOTHER INTERVAL
 *  - FAIL IF ANY CROSSFADES OVERLAP
 */
export function isValidState(state: Project) {
  const lanes = state.lanes.laneIds.map(id => state.lanes.byId[id]);
  for (const t of lanes) {
    const regions = t.regionIds.map(id => state.regions.byId[id]).slice();

    regions.sort((a, b) => {
      return a.visualStartTime - b.visualStartTime;
    });

    interface Endpoint {
      time: number;
      isStart: boolean;
      region: Region;
    }

    const endpoints: Endpoint[] = [];
    for (let i = 0; i < regions.length; i++) {
      const region = regions[i];

      endpoints.push({
        time: region.visualStartTime,
        isStart: true,
        region
      });

      endpoints.push({
        time: region.visualStartTime + region.visualDuration,
        isStart: false,
        region
      });

      if (region.startFadeTime > region.visualDuration - region.endFadeTime) {
        // No overlapping crossfades
        console.log("invalidState: overlapping crossfades");
        return false;
      }
    }

    endpoints.sort((a, b) => a.time - b.time);

    let overlapCount = 0;
    let lastStartingRegion = null;

    for (let i = 0; i < endpoints.length; i++) {
      const endpoint = endpoints[i];
      if (endpoint.isStart) {
        overlapCount += 1;
        lastStartingRegion = endpoint.region;
      } else {
        // if last start point part of the same interval, and overlapCount > 1, we have an interval within an interval
        if (overlapCount > 1 && lastStartingRegion === endpoint.region) {
          console.log("invalidState: interval within interval");
          return false;
        }
        overlapCount -= 1;
      }
      if (overlapCount > 2) {
        console.log("invalidState: too many overlaps");
        return false; // too many overlapping intervals
      }
    }
  }
  return true; // no problems
}

const deterministicStringifyReplacer = (key, value) =>
  value instanceof Object && !(value instanceof Array)
    ? Object.keys(value)
        .sort()
        .reduce((sorted, key) => {
          sorted[key] = value[key];
          return sorted;
        }, {})
    : value;

/*
 * Calculates an MD5 hash of the editor internal data serialization
 * This hash is used by the editor to see if it differs from a CustomMixVersion
 * or initial load. From hash equality checks we can see if changes have been
 * made by the user.
 */
export function calculateDataHash(state: Project) {
  let editorDataDump = { ...state };
  // Prune out the CustomMixInfo & InitialTrackData, that's not part of the save serialization
  // save serialization just consists of lane and region data
  delete editorDataDump.customMixInfo;
  delete editorDataDump.initialTrackData;

  // TODO: sort keys of editorDataDump recursively before stringify
  const dataHash = crypto
    .createHash("md5")
    .update(JSON.stringify(editorDataDump, deterministicStringifyReplacer))
    .digest("hex");

  return dataHash;
}
