import * as React from "react";
import { theme } from "../globalStyles";

export const Close = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.21955 6.00483L11.7489 10.5359C12.0839 10.871 12.0837 11.4142 11.7483 11.749C11.413 12.0839 10.8696 12.0836 10.5346 11.7485L6.00485 7.21706L1.46471 11.7481C1.12929 12.0828 0.585858 12.0824 0.250932 11.7472C-0.083996 11.4119 -0.0835919 10.8688 0.251834 10.534L4.79162 6.00338L0.254076 1.46412C-0.0809496 1.12897 -0.0807038 0.585823 0.254624 0.250975C0.589952 -0.0838727 1.13338 -0.083627 1.46841 0.251525L6.0063 4.79114L10.5344 0.27216C10.8698 -0.0625899 11.4133 -0.0621861 11.7482 0.273063C12.0831 0.608311 12.0827 1.15145 11.7473 1.4862L7.21955 6.00483Z"
      fill={theme.editor.textLight}
    />
  </svg>
);