import moment from "moment";

const formatUnix = date => {
  return new Date(parseFloat(date));
};

export const formatFullDate = date => {
  if (typeof date === "object") {
    return (
      moment(date).format("MM/DD/YYYY") + " - " + moment(date).format("LT")
    );
  }
  return (
    moment(formatUnix(date)).format("MM/DD/YYYY") +
    " - " +
    moment(formatUnix(date)).format("LT")
  );
};
