import * as React from "react";
import { theme } from "../globalStyles";

export const PlayNext = () => (
  <svg
    width="23"
    height="15"
    viewBox="0 0 23 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1854 0.185937L21.7928 6.22927C22.0255 6.39193 22.1648 6.65722 22.1665 6.94094V7.66427C22.1648 7.94799 22.0255 8.21327 21.7928 8.37594L13.1854 14.4543C12.9017 14.6399 12.5348 14.6399 12.2511 14.4543L12.1109 14.3843C11.8454 14.223 11.682 13.9363 11.6788 13.6259L11.6788 1.01427C11.6797 0.703282 11.8437 0.415521 12.1109 0.255936L12.2511 0.185937C12.5348 0.000288963 12.9017 0.000288963 13.1854 0.185937ZM10.4875 6.94093C10.4858 6.65721 10.3465 6.39193 10.1138 6.22927L1.50642 0.139267C1.22271 -0.04638 0.855824 -0.04638 0.572107 0.139267L0.431961 0.209266C0.164759 0.368851 0.00078392 0.656612 -0.000162125 0.9676V13.5793C0.00305748 13.8896 0.166445 14.1764 0.431961 14.3376L0.572107 14.4076C0.855824 14.5932 1.22271 14.5932 1.50642 14.4076L10.1138 8.37593C10.3465 8.21327 10.4858 7.94799 10.4875 7.66427L10.4875 6.94093Z"
      fill={theme.editor.iconMedium}
    />
  </svg>
);
