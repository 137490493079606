import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Container = styled.div`
  width: 160px;
  background: ${props => props.theme.backgroundDark};
  padding-top: 24px;
  z-index: 100000;
  padding-bottom: 8px;
  box-shadow: ${props => props.theme.boxShadowBottom};

  .active-menu-item {
    p {
      color: ${props => props.theme.textLight};
    }
  }

  .break-line {
    opacity: 0.2;
    width: 85%;
    margin: auto;
    margin-bottom: 14px;
    margin-top: -4px;
    background-color: ${props => props.theme.textLight};
    height: 1.5px;
  }
`;

export const MenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  margin-left: 28px;

  ${props => props.$isSubscription && "margin-left: 16px;"}

  p {
    font-size: ${props => props.theme.p2b.fs};
    font-family: ${props => props.theme.p2b.ff};
    color: ${props => props.theme.textDark};
    text-align: left;
  }
`;
