import React from "react";
import { OverlayMask } from "./styles";

export const DragDropAudioOverlay = ({ show }) => {
  return (
    <OverlayMask show={show}>
      <div id="overlay-message-box">
        <div id="overlay-message">
          <h4>Drop an audio file to add a reference track to your mix</h4>
          <img
            src={require("../../../assets/sound-waves.svg")}
            alt="upload-waveform"
          />
        </div>
      </div>
    </OverlayMask>
  );
};
