import React, { useState } from "react";
import AntModal from "../../AntModal";
import { Container } from "./styles";
import { theme } from "../../../globalStyles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { client } from "../../../apolloClient";
import { CONFIRMED_ATTRIBUTION_ON_DOWNLOAD } from "./queries";

const DownloadAttributeModal = ({
  modalState,
  onClose,
  title = "",
  attributeId = 0,
  id,
  downloadable
}) => {
  let attributeText = `${title} by <a href="${window.location.origin}/browse/track/${attributeId}">Blue Dot Sessions</a>`;
  const [copied, setCopied] = useState(false);

  const givenCredit = async () => {
    try {
      await client.query({
        query: CONFIRMED_ATTRIBUTION_ON_DOWNLOAD,
        variables: {
          confirmed_attribution: true,
          id: id
        }
      });
    } catch (err) {
      console.error("confirm attribute", err);
    }

    modalState.close();
  };

  return (
    <AntModal
      modalState={modalState}
      onClose={onClose}
      backgroundColor={downloadable ? theme.subscribe : theme.highlight}
    >
      <Container downloadable={downloadable}>
        <div className="flex-row">
          <img
            className="logo"
            src={require("../../../assets/mic-logo.svg")}
            alt="blue-dot-logo"
          />
          <div className="flex-column">
            <p className="thanks">
              {downloadable
                ? "Thanks for giving us credit, we really appreciate it!"
                : "Thanks for using our music! Remember to credit the work"}
            </p>
            <p className="copy-below">
              Copy the text below to your website or media description to give
              credit
            </p>
          </div>
        </div>
        <div className="copy-container">
          <div className="copy-text-box">
            <p className="copy-text">{attributeText}</p>
            <CopyToClipboard
              text={attributeText}
              onCopy={() => setCopied(true)}
            >
              <button className="copy-text-button">
                {copied ? "Copied" : "Copy and Attribute"}
              </button>
            </CopyToClipboard>
          </div>
          <p>
            <span className="preview">Preview:</span>
            {` ${title} by `}
            <a
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              href={`${window.location.origin}/browse/track/${attributeId}`}
            >
              Blue Dot Sessions
            </a>
          </p>
        </div>
        {!downloadable && (
          <p className="give-credit" onClick={givenCredit}>
            I've already given credit
          </p>
        )}
      </Container>
    </AntModal>
  );
};

export default DownloadAttributeModal;
