import styled, { keyframes } from "styled-components";
import ReactSVG from "react-svg";

const slideLeft = keyframes`
  from{
    transform:translateX(500px)
  }
  to{
    transform:translateX(0px)
  }
`;

export const Container = styled.div`
  width: 254px;
  height: fit-content;
  position: relative;

  @media (max-width: 801px) {
    width: 100%;
    position: inherit;
  }

  .search-icon {
    margin: 5px 8px;
    margin-right: 0px;
  }

  .autocomplete-container {
    position: absolute;
    width: 100%;
    top: 0px;
    z-index: -100;

    @media (max-width: 801px) {
      left: 0;
      top: 40px;
      height: 100vh;
      animation: ${slideLeft} 0.2s ease-in-out;
    }
  }
`;

export const StyledSearch = styled.div`
  width: 254px;
  height: 100%;
  background-color: ${props => props.theme.backgroundTag};
  z-index: 100000;
  margin-top: 20px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;

  .input {
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.backgroundTag};
    color: ${props => props.theme.white};
    font-family: ${({ theme }) => theme.p1m.ff};
    font-size: 14px;
    letter-spacing: 0.2px;
    border: none;
    outline: none;
    margin-left: 16px;
    border-radius: 20px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;

    &::placeholder {
      color: ${({ theme }) => theme.textDark};
      padding-left: 2px;
    }
  }

  &:focus {
    outline: none;
    border: none;
  }

  &::placeholder {
    color: ${props => props.theme.textGrey};

    padding-bottom: -2px;
  }

  & svg path {
    fill: ${props => props.theme.white};
  }
`;

export const StyledSvg = styled(ReactSVG)`
  font-size: 8px;

  path {
    fill: ${props => props.theme.textGrey};
  }
`;

export const Results = styled.div`
  padding-top: 40px;
  background: ${props => props.theme.backgroundLight};
  border-radius: 16px;
  padding-bottom: 16px;
  box-shadow: 2px 1px 2px 0px ${props => props.theme.backgroundDark};
  max-height: 450px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
    opacity: 0;
    border-radius: 12px;
  }

  @media (max-width: 810px) {
    width: 100vw;
    border-radius: 0px;
    box-shadow: none;
    min-height: 100vh;
    padding-top: 20px;
    z-index: 200;
    background: ${props => props.theme.backgroundDark};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    opacity: 0;
    width: 15px;
    background-color: ${props => props.theme.backgroundDark};
    border-radius: 12px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.06);
    opacity: 0.6;
    border-radius: 12px;
  }

  .focused-result {
    background: rgb(91, 93, 102, 0.24);
  }

  .result {
    display: flex;
    align-items: center;
    padding: 6px;
    padding-left: 14px;
    cursor: pointer;

    &:hover {
      background: rgb(91, 93, 102, 0.5);
    }

    .name {
      flex: 1;
      margin-left: 16px;
      margin-right: 16px;
      font-size: 14px;
      font-family: ${({ theme }) => theme.p1m.ff};
      white-space: nowrap;
      overflow: hidden;
      width: 36px;
      text-overflow: ellipsis;
    }
    .type {
      font-family: ${({ theme }) => theme.p1m.ff};
      font-size: 12px;
      color: ${props => props.theme.textGrey};
      margin-top: 2px;
      text-transform: uppercase;
      padding-right: 6px;
    }
  }

  @media (max-width: 801px) {
    padding-left: 4px;
    .type {
      padding-right: 6vw;
      text-align: left;
    }

    .result {
      padding: 12px;
      border-bottom: 0.1px solid #252525;
    }
  }
`;

export const MobileSearchInput = styled.div`
  display: flex;
  align-items: center;
  & .ant-input {
    flex: 1;
    margin-left: 3vw;
    /* height: 72px; */
    width: 100%;
    background: ${props => props.theme.backgroundDark};
    border: none;
    color: ${props => props.theme.textLight};
    font-size: ${props => props.theme.h3.fs};
    padding-top: 12px;
  }

  & .ant-input::placeholder {
    margin-top: 16px;
    color: ${props => props.theme.textDark};
    font-size: ${props => props.theme.h3.fs};
  }

  & .ant-input:focus {
    border-width: 0px;
    box-shadow: none;
  }

  .search-icon {
    margin-top: 6px;
  }

  .close-icon {
    width: 16px;
    height: 16px;
    margin-top: 6px;
    margin-right: 12px;
  }
`;
