import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.backgroundDark};
  width: 306px;
  padding-top: 17px;
  padding-left: 24px;
  box-shadow: ${props => props.theme.boxShadowBottom};

  .checkout-button {
    align-self: flex-end;
    width: 110px;
    margin: 20px;
    margin-top: 0px;
  }

  .empty-cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 140px;
    margin-left: -12px;

    p {
      margin-top: -20px;
      font-size: 16px;
    }
  }
`;

export const CartItem = styled.div`
  display: grid;
  grid-template-columns: 4.5fr 1fr;
  align-content: center;
  align-items: center;
  margin-bottom: 14px;
  padding-top: 4px;

  .album-art {
    width: 38px;
    height: 38px;
  }

  .remove-icon {
    margin-left: 12px;
    cursor: pointer;
  }

  .track-container {
    display: flex;
    align-items: center;
  }

  .track-detail {
    display: flex;
    align-items: center;
    margin-left: 0.75rem;
  }
`;
