import React, { useEffect, useCallback, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_PLAYBACK_STATE,
  SetPlaybackStateAction,
  SEEK,
  SeekAction
} from "../../actions/actionTypes";
import { MainStore, PlaybackState } from "../../store";
import {
  bpm_to_seconds_per_measure,
  snap_to_nearest_bar
} from "../../util/quantization";
import { IconButton } from "../Buttons/IconButton";
import { Container, StateButton } from "./styles";
import { PauseCircle } from "../../../assets/PauseCircle";
import { PlayCircle } from "../../../assets/PlayCircle";
import { PlayPrevious } from "../../../assets/PlayPrevious";
import { PlayNext } from "../../../assets/PlayNext";
import { theme } from "../../../globalStyles";
import { pixels_to_second } from "../../util/time_position_conversion";
import { getZoomProportion } from "../../util/zoom_levels";
import { EditorContext } from "../../context/EditorContext/EditorProvider";

export function TransportControls() {
  const dispatch = useDispatch();
  const playbackState = useSelector<MainStore, PlaybackState>(
    state => state.editorState.playbackState
  );
  const bpm = useSelector<MainStore, number>(
    state => state.project.initialTrackData.bpm
  );
  const beatsPerMeasure = useSelector<MainStore, number>(
    state => state.project.initialTrackData.beatsPerMeasure
  );
  const songTime = useSelector<MainStore, PlaybackState>(
    state => state.editorState.locatorTime
  );
  const zoomLevel = useSelector<MainStore, number>(
    state => state.uiState.zoomLevel
  );
  const zoomProportion = getZoomProportion(zoomLevel);

  const editor = useContext(EditorContext);

  const togglePlayPause = useCallback(() => {
    dispatch<SeekAction>({
      type: SEEK,
      seconds: songTime
    });
    dispatch<SetPlaybackStateAction>({
      type: SET_PLAYBACK_STATE,
      state:
        playbackState === PlaybackState.PLAYING
          ? PlaybackState.PAUSED
          : PlaybackState.PLAYING,
      actionSongTime: songTime
    });
  }, [playbackState, songTime, dispatch]);

  const handleKeyDown = useCallback(
    e => {
      if (["text", "number", "textarea"].includes(e.target.type)) return;

      // handle complex keystrokes
      if (e.metaKey || e.ctrlKey) {
        switch (e.keyCode) {
          case 37:
            // seek to beginning of song
            dispatch<SeekAction>({
              type: SEEK,
              seconds: 0
            });
            break;
          case 39:
            // seek to end of song
            const timelineElement = document.getElementById("time-ticks");
            // the - 4px is just so you can visually see the locator cursor on the far right of screen
            // when it hits the right edge
            const scrollableSeconds = pixels_to_second(
              timelineElement.scrollWidth / zoomProportion - 4
            );
            dispatch<SeekAction>({
              type: SEEK,
              seconds: scrollableSeconds
            });
            break;
        }
      } else {
        // handle single hotkeys
        switch (e.keyCode) {
          case 32:
            // Space bar
            togglePlayPause();
            break;
          case 37:
            // Arrow left: seek 1 bar left
            const lastBarTargetSeconds = snap_to_nearest_bar(
              songTime - bpm_to_seconds_per_measure(bpm, beatsPerMeasure),
              bpm,
              beatsPerMeasure
            );
            dispatch<SeekAction>({
              type: SEEK,
              seconds: Math.max(0, lastBarTargetSeconds)
            });
            break;
          case 39:
            // Arrow right: seek 1 bar right
            const nextBarTargetSeconds = snap_to_nearest_bar(
              songTime + bpm_to_seconds_per_measure(bpm, beatsPerMeasure),
              bpm,
              beatsPerMeasure
            );

            const timelineElement = document.getElementById("time-ticks");
            // the - 4px is just so you can visually see the locator cursor on the far right of screen
            // when it hits the right edge
            const scrollableSeconds = pixels_to_second(
              timelineElement.scrollWidth / zoomProportion - 4
            );
            dispatch<SeekAction>({
              type: SEEK,
              seconds: Math.min(scrollableSeconds, nextBarTargetSeconds)
            });
            break;
          default:
            e.preventDefault();
        }
      }
    },
    [togglePlayPause, songTime, bpm, beatsPerMeasure, zoomProportion, dispatch]
  );

  // create a mutable ref to handler that gets refreshed with up-to-date redux store every rerender
  // this is necessary because of useEffect dependencies & redux
  // if you add songTime to useEffect dependencies the event listeners get added & removed way too often
  const handleKeyDownRef = useRef(handleKeyDown);
  useEffect(() => {
    handleKeyDownRef.current = handleKeyDown;
  }, [handleKeyDown]);

  useEffect(() => {
    const keyDownHandler = e => handleKeyDownRef.current(e);
    if (editor.drawerState.visible) {
      document.addEventListener("keydown", keyDownHandler);
    } else {
      document.removeEventListener("keydown", keyDownHandler);
    }
    return () => document.removeEventListener("keydown", keyDownHandler);
  }, [editor.drawerState.visible]);

  function onClickPlayback() {
    togglePlayPause();
  }

  function onStartAtBeginning() {
    dispatch<SetPlaybackStateAction>({
      type: SET_PLAYBACK_STATE,
      state: PlaybackState.PAUSED,
      actionSongTime: 0
    });
    dispatch<SeekAction>({
      type: SEEK,
      seconds: 0
    });
  }

  function onGoToEnd() {
    // seek to end of song
    const timelineElement = document.getElementById("time-ticks");
    // the - 4px is just so you can visually see the locator cursor on the far right of screen
    // when it hits the right edge
    const scrollableSeconds = pixels_to_second(
      timelineElement.scrollWidth / zoomProportion - 4
    );
    dispatch<SetPlaybackStateAction>({
      type: SET_PLAYBACK_STATE,
      state: PlaybackState.PAUSED,
      actionSongTime: scrollableSeconds
    });
    dispatch<SeekAction>({
      type: SEEK,
      seconds: scrollableSeconds
    });
  }

  return (
    <Container>
      <IconButton
        fillColor={theme.editor.iconMedium}
        onClick={onStartAtBeginning}
      >
        <PlayPrevious />
      </IconButton>
      <StateButton onClick={onClickPlayback}>
        {playbackState === PlaybackState.PLAYING ? (
          <PauseCircle />
        ) : (
          <PlayCircle />
        )}
      </StateButton>
      <IconButton fillColor={theme.editor.iconMedium} onClick={onGoToEnd}>
        <PlayNext />
      </IconButton>
    </Container>
  );
}
