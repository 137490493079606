import { AudioNodeChain } from "./AudioNodeChain";

/*
 * Sets up a sequence of processing nodes prior to master output
 * This includes compressors, limiters, etc. that effect the master output as a whole
 */
export class MasteringChain extends AudioNodeChain {
  /*
   * Params:
   *    context (AudioContext): AudioContext (from AudioMaster)
   */
  constructor(context: AudioContext | OfflineAudioContext) {
    // compressor
    const compressor = context.createDynamicsCompressor();
    compressor.attack.value = 0.016;
    compressor.release.value = 0.1;
    compressor.threshold.value = -11.0;
    compressor.ratio.value = 1.3;

    // just a compressor for now, but can become more complicated in the future
    const nodes = [compressor];
    super(context, nodes);
  }
}
