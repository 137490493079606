import {
  CustomMixInfo,
  InitialTrackData,
  InitialLanesData,
  Project,
  ProjectCustomMix,
  Instrument,
  Tag,
  CustomMixVersion,
  SaveSerialization,
  Envelopes,
  ThematicCategory
} from "../store";
import { genericInstrument } from "../store/test_initial_store";

const FLOAT_SERIALIZE_PRECISION = 3;

// TODO: more naming conflicts. the editor data store uses the name Project to refer
// to a custom mix project vs a DB Project which is a collection of CustomMix, really confusing
export interface UserProject {
  id: String;
  name: String;
  url: String;
  description: String;
  socialMedia: String;
  color: String;
  icon: String;
  creator: String;
}

export interface InitialTrackDataAPI extends InitialTrackData {
  stemEnvelopes: Map<string, Envelopes>;
}

export interface ProjectAPI extends Project {
  initialTrackData: InitialTrackDataAPI;
}

interface StemEnvelopeAPI {
  name: string;
  channelAmplitudeSamples: number[][]; // [channel][sample]
  windowSize: number;
  hopSize: number;
  sampleRate: number;
}

export const editor_api_key =
  process.env.REACT_APP_EDITOR_API_KEY ||
  window.localStorage.getItem("EDITOR_API_KEY")?.replace("\n", "");

const HOST =
  process.env.REACT_APP_BACKEND_URL || "https://staging-api.sessions.blue/";

async function blueDotApiCall(endpoint: string, body?: any) {
  const url = `${HOST}${endpoint}`;
  return fetch(url, {
    method: body ? "POST" : "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: editor_api_key
    },
    body: JSON.stringify(body)
  })
    .then(data => data.json())
    .then(data => {
      return data;
    });
}

export async function getInitialTrack(
  trackId: string
): Promise<InitialTrackDataAPI> {
  return blueDotApiCall("getInitialTrack", {
    trackId
  }).then(parseInitialTrack);
}

export async function getProjectTracks(
  projectId: string
): Promise<ProjectCustomMix[] | any> {
  return blueDotApiCall("getProjectTracks", {
    projectId
  }).then(jsonBlob => {
    const projectCustomMixes = jsonBlob.map(projectCustomMixJsonBlob => {
      return {
        ...projectCustomMixJsonBlob,
        createdAt: projectCustomMixJsonBlob.created_at,
        updatedAt: projectCustomMixJsonBlob.updated_at
      };
    });
    return projectCustomMixes;
  });
}

export async function getAvailableProjects(
  userId: string
): Promise<UserProject[] | any> {
  return blueDotApiCall(`getAvailableProjects?userId=${userId}`);
}

export async function getFile(path: string): Promise<string> {
  return blueDotApiCall("getFile", {
    key: path
  });
}

export function saveCustomMix(
  customMixId: string,
  projectId: string,
  trackId: string,
  userId: string,
  title: string,
  duration: number,
  editorDataDump: SaveSerialization
) {
  return blueDotApiCall("saveCustomMix", {
    customMixId,
    projectId,
    trackId,
    userId,
    title,
    duration,
    editorDataDump
  });
}

// This populates the main redux data store Project for the Custom Mix
export async function getCustomMix(
  customMixId: string,
  versionNumber?: Number
): Promise<ProjectAPI> {
  let getRequest = `getCustomMix?customMixId=${customMixId}`;
  if (versionNumber) getRequest += `&versionNumber=${versionNumber}`;
  return blueDotApiCall(getRequest).then(jsonBlob => {
    const project: ProjectAPI = {
      lanes: jsonBlob.version.editorDataDump.lanes,
      regions: jsonBlob.version.editorDataDump.regions,
      customMixInfo: parseCustomMixInfo(jsonBlob),
      initialTrackData: parseInitialTrack(jsonBlob.track)
    };
    return project;
  });
}

export async function getCustomMixVersions(
  customMixId: string
): Promise<CustomMixVersion[]> {
  return blueDotApiCall(`getCustomMixVersions?customMixId=${customMixId}`).then(
    jsonBlobs => {
      const customMixVersions: CustomMixVersion[] = jsonBlobs.map(jsonBlob => {
        return {
          id: jsonBlob.id,
          versionNumber: jsonBlob.versionNumber,
          editorDataDumpHash: jsonBlob.editorDataDumpHash,
          createdAt: new Date(jsonBlob.created_at),
          updatedAt: new Date(jsonBlob.updated_at)
        };
      });
      return customMixVersions;
    }
  );
}

export async function getTrackInfo(trackId: string) {
  return blueDotApiCall("getTrackInfo", {
    trackId
  });
}

export async function getTags(trackId: string) {
  return blueDotApiCall("trackTags", { trackId });
}

export async function postTrackInfo(
  initialTrackData: InitialTrackData,
  tagNames: String[],
  systemTagNames: String[],
  instrumentNames: String[],
  thematicCategories: String[]
) {
  return blueDotApiCall("updateTrackInfo", {
    trackId: initialTrackData.id,
    mood: initialTrackData.mood,
    density: initialTrackData.density,
    energy: initialTrackData.energy,
    gravity: initialTrackData.gravity,
    ensemble: initialTrackData.ensemble,
    melody: initialTrackData.melody,
    tension: initialTrackData.tension,
    rhythm: initialTrackData.rhythm,
    key: initialTrackData.key,
    bpm: initialTrackData.bpm,
    signature: initialTrackData.signature ?? initialTrackData.beatsPerMeasure,
    initialBeat: initialTrackData.initialBeat,
    gain: initialTrackData.gain,
    editorOpenable: initialTrackData.editorOpenable,
    freeStemFiles: initialTrackData.freeStemFiles,
    editorDemo: initialTrackData.editorDemo,
    tags: tagNames,
    systemTags: systemTagNames.filter(tag => tag !== ""),
    instruments: instrumentNames,
    thematicCategories: thematicCategories
  });
}

export async function postTrackEditorCache(
  trackId: string,
  editorDataDump: Project
) {
  return blueDotApiCall("updateTrackEditorCache", {
    trackId: trackId,
    editorDataDump: editorDataDump
  });
}

/*
 *  Update Track::stemEnvelopes to include the given stem envelope
 *  Note: before transmission, transcode envelope Float32Array to Array for storage in db
 *
 *  Parameters:
 *    trackId (string)
 *    cacheKey (string): id to recall stem (this is simply SD audioPath of stem file)
 *    envelopes (Envelopes): list of envelope data and metadata for each channel in stem
 */
export async function postTrackStemEnvelopes(
  trackId: string,
  cacheKey: string,
  envelopes: Envelopes
) {
  const serializedEnvelopes = { ...envelopes };
  serializedEnvelopes.channelAmplitudeSamples = serializedEnvelopes.channelAmplitudeSamples.map(
    sampleArray => {
      // transcodes Float32Arrays to normal Array with fixed precision for network transmission
      return Array.from(sampleArray).map(sample =>
        parseFloat(sample.toFixed(FLOAT_SERIALIZE_PRECISION))
      );
    }
  );
  return blueDotApiCall("upsertTrackStemEnvelopes", {
    trackId: trackId,
    cacheKey: cacheKey,
    envelopes: serializedEnvelopes
  });
}

// Parses CustomMixInfo from API call /getCustomMix
function parseCustomMixInfo(jsonBlob): CustomMixInfo {
  const customMixInfo = {
    id: jsonBlob.id,
    projectId: jsonBlob.projectId,
    trackId: jsonBlob.trackId,
    title: jsonBlob.title,
    length: jsonBlob.version.duration,
    numVersions: jsonBlob.numVersions,
    createdAt: new Date(jsonBlob.created_at),
    updatedAt: new Date(jsonBlob.version.updated_at),
    user: { ...jsonBlob.user },
    version: {
      id: jsonBlob.version.id,
      versionNumber: jsonBlob.version.versionNumber,
      editorDataDumpHash: jsonBlob.version.editorDataDumpHash,
      createdAt: new Date(jsonBlob.version.created_at),
      updatedAt: new Date(jsonBlob.version.updated_at)
    },
    currentDataHash: jsonBlob.version.editorDataDumpHash
  };
  return customMixInfo;
}

// Parses InitialTrackData from API call /getCustomMix
function parseInitialTrack(jsonBlob): InitialTrackDataAPI {
  // parse stem data
  const stems = jsonBlob.stem;
  if (!stems) {
    return null;
  }
  const initialLanes: InitialLanesData[] = stems.map(stem => {
    // Perform lookup of stem instrument information and attach it
    // If instrument metadata not found attached to original track, mark it as "other"
    let instrument = jsonBlob.instruments.filter(
      (instrument: Instrument) => stem.instrument === instrument.name
    );
    instrument =
      instrument.length > 0
        ? instrument[0]
        : { ...genericInstrument, name: stem.name };

    const lane: InitialLanesData = {
      audioPath: stem.mp3File, // TODO need to convert url
      audioPathHQ: stem.mp3_256File,
      name: stem.instrument,
      id: stem.name,
      instrument,
      gainCompensation: 0
    };
    return lane;
  });

  // parse stem audio envelope cache
  const stemEnvelopes = new Map<string, Envelopes>();
  const stemEnvelopesAPIData: StemEnvelopeAPI[] = (jsonBlob?.stemEnvelopes ??
    []) as StemEnvelopeAPI[];
  for (const envelopesData of stemEnvelopesAPIData) {
    const { name: envelopesName, ...envelopes } = envelopesData;
    stemEnvelopes.set(envelopesName, envelopes as Envelopes);
  }

  // parse tag data
  const tags: Tag[] = jsonBlob.tags.map(tagData => {
    const tag: Tag = {
      id: tagData.id,
      name: tagData.name
    };
    return tag;
  });

  // parse thematic categories
  const thematicCategories: ThematicCategory[] = jsonBlob.thematicCategories.map(
    tc => {
      const thematicCategory: ThematicCategory = {
        id: tc.id,
        name: tc.name
      };
      return thematicCategory;
    }
  );

  const initialTrackData: InitialTrackDataAPI = {
    albumName: jsonBlob?.albumName,
    albumId: jsonBlob?.album,
    beatsPerMeasure: jsonBlob.signature || 1,
    blockFiles: [], // TODO
    bpm: jsonBlob.bpm || 1,
    createdAt: jsonBlob?.created_at,
    density: jsonBlob?.density,
    duration: jsonBlob?.duration,
    editorOpenable: jsonBlob?.editorOpenable,
    editorDemo: jsonBlob?.editorDemo,
    energy: jsonBlob?.energy,
    ensemble: jsonBlob?.ensemble,
    freeStemFiles: jsonBlob?.freeStemFiles,
    gain: jsonBlob.gain || 0,
    gravity: jsonBlob?.gravity,
    id: jsonBlob.id,
    initialBeat: jsonBlob.initialBeat,
    instruments: jsonBlob.instruments,
    key: jsonBlob?.key,
    melody: jsonBlob?.melody,
    mood: jsonBlob?.mood,
    rhythm: jsonBlob?.rhythm,
    tags: tags,
    systemTags: (jsonBlob?.systemTags || []).filter(tag => tag.trim() !== ""),
    thematicCategories: thematicCategories,
    stemEnvelopes: stemEnvelopes,
    tension: jsonBlob?.tension,
    thumbnail: jsonBlob?.thumbnail,
    title: jsonBlob?.title,
    variationTitle: jsonBlob?.variationTitle,
    lanes: initialLanes,
    updatedAt: jsonBlob?.updated_at,
    editorDataDump: jsonBlob?.editorDataDump
  };

  return initialTrackData;
}
