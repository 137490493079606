/*
  We have 1-5 range filter values in the frontend, 
  and backend stores 0-9 values. 
  Due to this, we have to do some conversion logic when we send and 
  receive range filters.

  This file contains a logic for range filter value conversion
  between frontend and backend.



*/

import _ from "lodash";

//converts from frontend to backend value
// the array holds the backend converted values for 1-5 frontend values
const backendValue = [];

backendValue[1] = 0;
backendValue[2] = 2;
backendValue[3] = 4;
backendValue[4] = 6;
backendValue[5] = 9;

//converts from backend to frontend value
// the array holds the frontend converted values for 0-9 backend values
const frontendValue = [];
frontendValue[0] = 1;
frontendValue[1] = 1;
frontendValue[2] = 2;
frontendValue[3] = 2;
frontendValue[4] = 3;
frontendValue[5] = 3;
frontendValue[6] = 4;
frontendValue[7] = 4;
frontendValue[8] = 5;
frontendValue[9] = 5;

/*

  Logic for min === max values.

  Eg. If user slide Tension to have 0-0 value, 
  we will have to convert it to 0-1 to include tracks in 
  that range. Or backend won't send all the tracks.

  And this logic is called "relaxing". All it does is 
  add 1 to the max value.  

  
*/

//this object relaxes min===max ranges
const relaxEqualRange = [];
relaxEqualRange[0] = { min: 0, max: 1 };
relaxEqualRange[1] = { min: 0, max: 1 };
relaxEqualRange[2] = { min: 2, max: 3 };
relaxEqualRange[3] = { min: 2, max: 3 };
relaxEqualRange[4] = { min: 4, max: 5 };
relaxEqualRange[5] = { min: 4, max: 5 };
relaxEqualRange[6] = { min: 6, max: 7 };
relaxEqualRange[7] = { min: 6, max: 7 };
relaxEqualRange[8] = { min: 8, max: 9 };
relaxEqualRange[9] = { min: 8, max: 9 };

// function exposing the frontend conversion array
const convertToFrontend = value => {
  return frontendValue[value];
};

// a function the simply adds 1 to max value
// used on all range values (min !== max) before sending to backend.
const relaxRange = (min, max) => {
  if (max === 9) return;
  return { min, max: max + 1 };
};

/*
name - relaxEqualRangeValues
return type - []
description -   function that is run before sending filters array to backend
it relaxes min === max values for range filters,
 >>  >>      min !== max >>     >>   >>    >>,
relaxes max === 6sec+ value for duration.


param     type         desc

filters   []           array of search filters
*/

const relaxEqualRangeValues = (filters = []) => {
  let cleaned = [];
  if (filters) {
    const relaxedValues = filters.map(item => {
      if (item.min === item.max && filterIsCharacteristics(item)) {
        return { ...item, ...relaxEqualRange[item.min] };
      }
      if (filterIsCharacteristics(item) && item.min !== item.max) {
        return { ...item, ...relaxRange(item.min, item.max) };
      }
      if (filterIsDuration(item) && item.max === 360) {
        return {
          ...item,
          max: 1800 // include all tracks with 6sec+ duration value
        };
      }
      if (filterIsBpm(item) && item.max === 200) {
        return {
          ...item,
          max: 2000
        };
      }
      return item;
    });
    cleaned = cleanNonSearchFields(relaxedValues);
  }
  return cleaned;
};

/*
name - cleanNonSearchFields
return type - []
description -  clean all extra fields from search filters before sending to backend.

param     type         desc

filters    []          array of search filters 
*/

const cleanNonSearchFields = (filters = []) => {
  const cleanedFilters = filters.map(item => {
    let cleanedItem = _.omit(item, "hidden");
    cleanedItem = _.omit(cleanedItem, "aggregate");
    cleanedItem = _.omit(cleanedItem, "aggregateName");
    return cleanedItem;
  });

  return cleanedFilters;
};

/*
name - filterIsCharacteristics
return type - boolean
description -  returns true if given filter is a range filter.

param     type         desc

filter     []         any search filter
*/

const filterIsCharacteristics = filter => {
  if (
    filter.filterType === "range" &&
    filter.filterName.toLowerCase() !== "duration" &&
    filter.filterName.toLowerCase() !== "length" &&
    filter.filterName.toLowerCase() !== "bpm"
  )
    return true;

  return false;
};

/*

name - filterIsDuration
return type - boolean
description -  returns true if given filter is a duration filter.

param     type         desc

filter     {}         any search filter


*/
const filterIsDuration = filter => {
  if (filter.filterType !== "range") return false;
  if (
    filter.filterName.toLocaleLowerCase() === "duration" ||
    filter.filterName.toLocaleLowerCase() === "length"
  )
    return true;

  return false;
};

/*

/*

name - filterIsBpm
return type - boolean
description -  returns true if given filter is a bpm filter.

param     type         desc

filter     {}         any search filter


*/
const filterIsBpm = filter => {
  if (filter.filterType !== "range") return false;
  if (filter.filterName.toLocaleLowerCase() === "bpm") return true;

  return false;
};

/*



name - convertFiltersToFrontend
return type - []
description -  loop through search filters and convert range values to frontend.

param           type         desc

searchFilters     []         array of filters




*/
const convertFiltersToFrontend = searchFilters => {
  const convertedValues = searchFilters.map(item => {
    if (item.filterType === "range") {
      if (["Length", "BPM", "Duration"].includes(item.filterName)) return item;
      return {
        ...item,
        min: convertToFrontend(item.min),
        max: convertToFrontend(item.max)
      };
    } else {
      return item;
    }
  });
  return convertedValues;
};

// well
export {
  backendValue,
  convertToFrontend,
  relaxEqualRangeValues,
  cleanNonSearchFields,
  convertFiltersToFrontend
};
