import React, { useContext } from "react";
import styled from "styled-components";
import { Avatar } from "../Avatar";
import { AuthContext } from "../../context/AuthProvider";

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 220px;

  .text {
    font-size: ${props => props.theme.p2b.fs};
    font-family: ${props => props.theme.p2b.ff};
    margin-right: 12px;
    text-transform: capitalize;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Profile = () => {
  const { user } = useContext(AuthContext);
  return (
    <Container>
      <p className="text">{user.fullName}</p>
      <Avatar color={user.profileColor} size="24">
        <img
          style={{ width: 24, height: 24 }}
          className="selected-icon"
          src={require(`../../assets/profile_icons_new/${user.profileIcon}`)}
          alt="profile-icon"
        />
      </Avatar>
    </Container>
  );
};

export default Profile;
