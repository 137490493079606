import styled from "styled-components";
import ReactSVG from "react-svg";

export const filterX = "7px";
export const filterRight = "14px";
export const filterBottom = "10px";

export const filterMargins = `
  margin-right: ${filterRight};
  margin-bottom: ${filterBottom};
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: ${props => (props.mobileView ? "100%" : "97%")};
  margin-right: 24px;

  .more-icon {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  .more-icon-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${props => (props.mobileView ? "0px 12px 0px 12px" : "")};
    transform: rotate(90deg);
    transition: transform 0.5s ease;
  }

  .icon-true {
    transform: rotate(-90deg);
  }
`;

export const Inner = styled.div`
  max-height: ${props => `${props.heightBreakPoint}px`};
  overflow: hidden;
  transition: all 0.3s ease-out;
  background: ${props =>
    props.mobileView ? "none" : props.theme.backgroundDark};
  border-radius: 10px;
  padding: 0 8px;
  position: relative;

  ${props =>
    props.containerHeight > props.heightBreakPoint &&
    props.showMore &&
    ` 
      max-height: 80vh;
      padding-bottom: 12px;
      overflow-y: auto;
`}

  ${props =>
    props.mobileView &&
    props.containerHeight > props.heightBreakPoint &&
    props.showMore &&
    `    
      max-height: 1000px;
      overflow-y: visible;
  `}

  ::-webkit-scrollbar {
    width: 8px;
    opacity: 0;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    opacity: 0;
    width: 15px;
    background-color: ${props => props.theme.backgroundDark};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.06);
    opacity: 0.6;
    border-radius: 2px;
  }

  .selected-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .filter {
      ${filterMargins};
    }

    .this-is-an-aggregate-filter {
      padding: 0 ${filterX};
      margin-right: 0 !important;
      background-color: ${({ mobileView, theme }) =>
        mobileView ? theme.backgroundDark : theme.backgroundLight};
    }

    .last-filter {
      border-radius: 0 16px 16px 0;
      //Might want back in. But I think it makes it hard to tell where the ending agg filter is... padding-right: 0;
      margin-right: ${filterRight} !important;
      padding: 0 ${filterRight} 0 ${filterX};
    }
  }

  .aggregates-container {
    display: flex;
    align-items: center;
    height: 26px;
    border-radius: 12px;

    @media (max-width: 800px) {
      height: unset;
    }

    .selected-tags {
      margin-top: 18px;
    }

    .aggregate-tag {
      margin-right: 10px;
    }
  }
  .is-aggregate {
    background: ${props => props.theme.backgroundLight};
  }
`;

export const Button = styled.div`
  background: ${props => props.theme.highlight};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.theme.p4b.ff};
  font-size: ${props => props.theme.p4b.fs};
  color: ${props => props.theme.white};
  border-radius: ${props => props.theme.borderRadius};
  line-height: 10px;
  cursor: pointer;

  .icon {
    margin-right: 8px;
    margin-bottom: 3px;
  }
`;

export const Arrow = styled(ReactSVG)`
  svg {
    width: 9px;
    height: auto;
  }
`;
