import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SCROLL_WORKSPACE,
  ScrollWorkspaceAction,
  UPLOAD_LANE,
  UploadLaneAction,
  ClearAlertAction,
  CLEAR_ALERT,
  ResetStoreAction,
  RESET_STORE
} from "../../actions/actionTypes";
import { AlertMessage, EditorMode, MainStore } from "../../store";
import { bpm_to_seconds_per_measure } from "../../util/quantization";
import { seconds_to_pixels } from "../../util/time_position_conversion";
import { getZoomProportion } from "../../util/zoom_levels";
import { AddLane } from "../AddLane";
import { AddLaneModal } from "../AddLaneModal";
import { TimeTicks } from "../TimeTicks";
import { Locator } from "../TimeTicks/Locator";
import { LaneHeaders } from "../LaneHeaders";
import { Lane } from "../Lane";
import {
  Container,
  LeftPanelContainer,
  GridContainer,
  LanesAreaContainer
} from "./styles";
import { SnapControls } from "../SnapControls";
import { Header } from "../Header";
import { ZoomControls } from "../ZoomControls";
import { MessageContext } from "../../../context/MessageContext";
import { DragDropAudioOverlay } from "../DragDropAudioOverlay";
import useModal from "../../../hooks/useModal";
import { EditorContext } from "../../context/EditorContext/EditorProvider";
import { theme } from "../../../globalStyles";

export let Editor = () => {
  const SCREEN_COVERAGE_SNAP_PROPORTION = 0.825;

  const editor = useContext(EditorContext);

  const [dragActive, setDragActive] = useState(false);
  const [naturalDrawerHeight, setNaturalDrawerHeight] = useState(
    parseInt(theme.editor.editorHeaderHeightNoPx)
  );
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const addLaneModal = useModal();

  const bpm = useSelector<MainStore, number>(
    state => state.project.initialTrackData.bpm
  );
  const beatsPerMeasure = useSelector<MainStore, number>(
    state => state.project.initialTrackData.beatsPerMeasure
  );
  const seconds_per_measure = bpm_to_seconds_per_measure(bpm, beatsPerMeasure);
  const pixels_per_measure = seconds_to_pixels(seconds_per_measure);
  const zoomLevel = useSelector<MainStore, number>(
    state => state.uiState.zoomLevel
  );
  const zoomProportion = getZoomProportion(zoomLevel);

  const { showMessage, closeMessage } = useContext(MessageContext);
  const alert = useSelector<MainStore, AlertMessage>(
    state => state.uiState.alert
  );

  const dispatch = useDispatch();
  const laneIds = useSelector<MainStore, string[]>(
    state => state.project.lanes.laneIds
  );
  let className = "lanes-area";

  const mode = useSelector<MainStore, EditorMode>(
    state => state.editorState.mode
  );
  if (mode === EditorMode.SPLIT) {
    className += " split-mode";
  }

  const onScroll = e => {
    dispatch<ScrollWorkspaceAction>({
      type: SCROLL_WORKSPACE,
      x: e.currentTarget.scrollLeft
    });
  };

  const handleDrag = function(e) {
    if (addLaneModal.visible) return;
    e.preventDefault();
    e.stopPropagation();
    setDragActive(e.type === "dragenter" || e.type === "dragover");
  };

  const handleDrop = event => {
    if (addLaneModal.visible) return;
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    uploadAudio(event);
  };

  const uploadAudio = event => {
    const fileList: FileList = event.target.files || event.dataTransfer.files;
    if (fileList.length === 0) {
      return;
    }

    const file = fileList.item(0);

    try {
      if (
        file.name.toLowerCase().includes(".wav") ||
        file.name.toLowerCase().includes(".mp3")
      ) {
        const url = URL.createObjectURL(file);
        const fileName = file.name.replace(".wav", "").replace(".mp3", "");
        dispatch<UploadLaneAction>({
          type: UPLOAD_LANE,
          url,
          name: fileName
        });
      } else {
        throw Error("Invalid audio file, not a .mp3 or .wav");
      }
    } catch {
      closeMessage();
      showMessage({
        type: "error",
        duration: 5000,
        title: "Add Audio Fail",
        description:
          "Error loading audio file. Please check that it is a valid .wav or .mp3 file."
      });
    }
  };

  const inBetweenBarLines = pixels_per_measure * zoomProportion;
  const zoomInitialOffset = inBetweenBarLines;

  const drawerContainer = document.querySelector<HTMLElement>(
    ".editor-drawer .ant-drawer-content-wrapper"
  );

  /*
   * Snap the editor drawer to the top of the screen
   *
   * Params:
   *  screenCoverageProportion (number): [0,1] only snap if editor drawer covers at least this proportion of the screen
   */
  const snapEditorDrawerToTop = (screenCoverageProportion = 0) => {
    if (drawerContainer === null) return;
    const drawerAlmostFillsScreen =
      naturalDrawerHeight / window.innerHeight >= screenCoverageProportion;
    if (drawerAlmostFillsScreen) {
      drawerContainer.style.height = "100%";
    } else {
      drawerContainer.style.height = `${naturalDrawerHeight}px`;
    }
  };

  const unsnapEditorDrawerFromTop = () => {
    if (drawerContainer === null) return;
    drawerContainer.style.height = `${naturalDrawerHeight}px`;
  };

  const toggleSnapEditorDrawerTop = () => {
    if (drawerContainer === null) return;
    const isExpanded = drawerContainer.style.height === "100%";
    if (isExpanded) {
      unsnapEditorDrawerFromTop();
    } else {
      snapEditorDrawerToTop();
    }
  };

  // this hook clears the redux store when unmounting editor
  useEffect(() => {
    return () => {
      dispatch<ResetStoreAction>({
        type: RESET_STORE
      });
    };
  }, [dispatch]);

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const naturalDrawerHeight =
      parseInt(theme.editor.editorHeaderHeightNoPx) +
      laneIds.length * parseInt(theme.editor.tightLaneHeightNoPx) +
      parseInt(theme.editor.rulerHeightNoPx);
    setNaturalDrawerHeight(naturalDrawerHeight);
  }, [laneIds]);

  useEffect(() => {
    snapEditorDrawerToTop(SCREEN_COVERAGE_SNAP_PROPORTION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [naturalDrawerHeight, dimensions]);

  // this hook supports a generalized notification system triggered by the redux store
  // to display a notification to the user from anywhere (error, success, etc.) call dispatch<SetAlertAction>
  // show any alerts pending in the editor UI redux store
  useEffect(() => {
    if (alert) {
      closeMessage();
      showMessage({
        type: alert.role,
        duration: 5000,
        title: alert.title,
        description: alert.message
      });

      dispatch<ClearAlertAction>({
        type: CLEAR_ALERT
      });
    }
  }, [alert, closeMessage, showMessage, dispatch]);

  // DEBUG
  /*useEffect(() => {
    editor.requestCustomMixIdOpen("c75598d9-0ac3-49dd-bc49-8ebc594f522f");
  }, []);*/

  return (
    <>
      <Header onExpand={toggleSnapEditorDrawerTop} />
      <Container
        className="editor"
        onDragEnter={handleDrag}
        tightLayout={editor.tightLayout}
      >
        <LeftPanelContainer className="left-panel">
          {!editor.tightLayout && (
            <div className="top-bar">
              <SnapControls />
              <ZoomControls />
            </div>
          )}
          <LaneHeaders />
          <AddLane addLaneModal={addLaneModal} />
          <AddLaneModal modalState={addLaneModal} />
        </LeftPanelContainer>
        <LanesAreaContainer
          id="lanes-area-container"
          className={className}
          onScroll={onScroll}
          backgroundSize={inBetweenBarLines}
        >
          <GridContainer
            id="grid-container"
            backgroundSize={inBetweenBarLines}
            initialBeatOffset={zoomInitialOffset}
          >
            {!editor.tightLayout && <TimeTicks />}
            <Locator />
            {laneIds.map((laneId, i) => (
              <Lane laneIndex={i} laneId={laneId} key={laneId} />
            ))}
            {editor.tightLayout && <TimeTicks />}
          </GridContainer>
        </LanesAreaContainer>
        {dragActive && (
          <div
            id="drag-overlay"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
        <DragDropAudioOverlay show={dragActive && !addLaneModal.visible} />
      </Container>
    </>
  );
};
