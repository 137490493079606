import styled from "styled-components";

export const LaneNameContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.editor.textLight};
  background-color: ${({ theme }) => theme.backgroundTag};
  margin: 1em 1em 1em 0;
  padding: 2px 24px;
  border-radius: ${({ theme }) => theme.borderRadius};
  height: 32px;
  transition: all 0.3s ease-in-out;
  transition-property: transform, box-shadow;

  &&:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 2px 2px rgb(0, 0, 0, 0.2);
  }

  .lane-color-bubble {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: blue;
    height: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
  }

  .lane-name {
    font-family: ${({ theme }) => theme.fontMedium};
    font-size: 15px;
    padding-left: 1.2em;
  }
`;
