import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../context/AuthProvider";

const HelmetWrapper = ({ children, title, description, unfurl }) => {
  const auth = useContext(AuthContext);

  return (
    <div>
      <Helmet>
        <title>
          {title ||
            "Browse | Intro Music for Podcast | Buy Best Selling Music Files"}
        </title>
        {auth.user.role === "guest" ? (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        ) : (
          <meta name="viewport" content="initial-scale=0.25" />
        )}
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={
            description ||
            "Explore an extensive collection of royalty free music & find the perfect intro, outro, to establish your podcast's unique identity. Browse Now."
          }
        />

        {unfurl !== undefined && (
          <Helmet>
            <meta property="og:title" content={unfurl.title} />
            <meta property="og:description" content={unfurl.description} />
            <meta property="og:image" content={unfurl.image} />
          </Helmet>
        )}
      </Helmet>
      {children}
    </div>
  );
};

export default HelmetWrapper;
