import styled from "styled-components";
import { Menu } from "antd";

export const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.editor.backgroundDark};
`;

export const MainMenu = styled(Menu)`
  && {
    background-color: ${({ theme }) => theme.editor.backgroundDark};
    border-radius: ${({ theme }) => theme.borderRadiusContainers};
    padding: 0;
    margin-top: 18px;
  }
`;

export const MainMenuItem = styled(Menu.Item)`
  && {
    color: ${({ theme }) => theme.editor.textLight};
    font-family: ${({ theme }) => theme.fontMedium};
    text-size: 14px;
    padding: 4px 20px;
    cursor: pointer;
  }

  &&.disabled {
    color: ${({ theme }) => theme.editor.textDark};
  }

  &&.disabled:hover {
    cursor: not-allowed;
    color: ${({ theme }) => theme.editor.textDark};
    background-color: ${({ theme }) => theme.editor.backgroundDark};
  }

  &&:hover {
    background-color: ${({ theme }) => theme.editor.backgroundHighlight};
    color: ${({ theme }) => theme.editor.textLight};
    transition: none;
  }

  &&:active {
    background-color: ${({ theme }) =>
      theme.editor.backgroundSuperDuperHighlight};
  }

  &&:first-child:hover {
    border-top-left-radius: ${({ theme }) => theme.borderRadiusContainers};
    border-top-right-radius: ${({ theme }) => theme.borderRadiusContainers};
  }

  &&:last-child:hover {
    border-bottom-left-radius: ${({ theme }) => theme.borderRadiusContainers};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadiusContainers};
  }
`;
