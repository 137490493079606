import { Dropdown } from "antd";
import { DropDownProps } from "antd/lib/dropdown";
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback
} from "react";
import { useDispatch } from "react-redux";
import { EditorContext } from "../../../context/EditorContext/EditorProvider";
import {
  DELETE_REGION,
  DeleteRegionAction,
  DUPLICATE_REGION,
  DuplicateRegionAction,
  SET_EDITOR_MODE,
  SetEditorModeAction
} from "../../../actions/actionTypes";
import { EditorMode } from "../../../store";
import { genNewId } from "../../../util/ids";
import { RegionMenu, RegionMenuItem } from "./styles";

export interface RegionDropdownMenuProps {
  children: JSX.Element;
  regionId: string;
  laneId: string;
  isContext: boolean;
  placement: DropDownProps["placement"];
  onVisibilityChange?: (visible: boolean) => void;
}

export let RegionDropdownMenu = (props: RegionDropdownMenuProps) => {
  const [dropdownMenuActive, setDropdownMenuActive] = useState(false);
  const dispatch = useDispatch();

  const editor = useContext(EditorContext);

  const handleSplit = () => {
    setDropdownMenuActive(false);
    if (props.onVisibilityChange) {
      props.onVisibilityChange(false);
    }
    dispatch<SetEditorModeAction>({
      type: SET_EDITOR_MODE,
      mode: EditorMode.SPLIT
    });
  };

  const handleDelete = () => {
    setDropdownMenuActive(false);
    if (props.onVisibilityChange) {
      props.onVisibilityChange(false);
    }
    dispatch<DeleteRegionAction>({
      type: DELETE_REGION,
      regionId: props.regionId,
      laneId: props.laneId
    });
  };

  const handleDuplicate = () => {
    setDropdownMenuActive(false);
    if (props.onVisibilityChange) {
      props.onVisibilityChange(false);
    }
    dispatch<DuplicateRegionAction>({
      type: DUPLICATE_REGION,
      regionId: props.regionId,
      laneId: props.laneId,
      newRegionId: genNewId()
    });
  };

  const handleRegionEditDropdownToggled = (visible: boolean) => {
    setDropdownMenuActive(visible);
    if (props.onVisibilityChange) {
      props.onVisibilityChange(visible);
    }
  };

  const handleKeyDown = useCallback(
    e => {
      // close region dropdown menu on esc press
      if (e.keyCode === 27) {
        setDropdownMenuActive(false);
        e.preventDefault();
      }
    },
    [setDropdownMenuActive]
  );

  const handleKeyDownRef = useRef(handleKeyDown);
  useEffect(() => {
    handleKeyDownRef.current = handleKeyDown;
  }, [handleKeyDown]);

  useEffect(() => {
    const keyDownHandler = e => handleKeyDownRef.current(e);
    if (editor.drawerState.visible) {
      document.addEventListener("keydown", keyDownHandler);
    } else {
      document.removeEventListener("keydown", keyDownHandler);
    }
    return () => document.removeEventListener("keydown", keyDownHandler);
  }, [editor.drawerState.visible]);

  const regionEditDropdownMenu = (
    <RegionMenu>
      <RegionMenuItem onClick={handleSplit}>Split</RegionMenuItem>
      <RegionMenuItem onClick={handleDelete}>Delete</RegionMenuItem>
      <RegionMenuItem onClick={handleDuplicate}>Duplicate</RegionMenuItem>
    </RegionMenu>
  );

  return (
    <>
      <Dropdown
        overlay={regionEditDropdownMenu}
        placement={props.placement}
        trigger={[props.isContext ? "contextMenu" : "hover"]}
        visible={dropdownMenuActive}
        onVisibleChange={handleRegionEditDropdownToggled}
      >
        {props.isContext
          ? props.children
          : React.cloneElement(props.children, {
              className: `${props.children.props.className} ${
                dropdownMenuActive ? "active" : ""
              }`
            })}
      </Dropdown>
    </>
  );
};
