import styled from "styled-components";

export const Counter = styled.div`
  position: absolute;
  right: -8px;
  top: -8px;
  background: ${props => props.theme.highlight};
  width: 17px;
  height: 17px;
  border-radius: 50%;
  box-shadow: ${props => props.theme.boxShadow};
  .text {
    line-height: 16px;
    margin-top: 2px;
    text-align: center;
  }
`;
