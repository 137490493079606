import React, { useContext } from "react";
import { Provider } from "react-redux";
import { MasterNode } from "../../audio/MasterNode";
import { genOfflineAudio } from "../../audio/OfflineAudioComponent";
import { Editor } from "../../components/Editor";
import { Drawer } from "antd";
import { Container } from "./styles";
import { EditorContext } from "../../context/EditorContext/EditorProvider";

const EditorDrawer = ({ mask = true, maskClosable = true }) => {
  const editor = useContext(EditorContext);

  const OfflineAudioComponent = genOfflineAudio(
    editor.store,
    editor.audioMaster
  );

  return (
    <Container>
      <Drawer
        placement="bottom"
        getContainer={false}
        mask={mask}
        maskClosable={maskClosable}
        className={`editor-drawer editor-${editor.state}`}
        title={null}
        closable={false}
        onClose={editor.close}
        destroyOnClose={false}
        visible={editor.drawerState.visible}
        keyboard={false}
        zIndex={999}
      >
        <Provider store={editor.store}>
          <Editor />
          <MasterNode audioMaster={editor.audioMaster} />
          <OfflineAudioComponent />
        </Provider>
      </Drawer>
    </Container>
  );
};

export default EditorDrawer;
