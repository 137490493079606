import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback
} from "react";
import { withApollo } from "react-apollo";
import {
  GET_FAVORITES,
  ADD_TO_FAVORITES,
  REMOVE_FROM_FAVORITES
} from "./queries";
import { SearchContext } from "../SearchContext/SearchProvider";

import { AuthContext } from "../AuthProvider";

export const FavoritesContext = createContext();

const FavoritesProvider = ({ children, client }) => {
  const auth = useContext(AuthContext);
  const [favorites, setFavorites] = useState([]);
  const [loadingTracks, setLoadingTracks] = useState(true);

  const searchContext = useContext(SearchContext);

  const fetchFavorites = useCallback(async () => {
    if (auth.user.id) {
      try {
        const res = await client.query({
          query: GET_FAVORITES,
          variables: { userId: auth.user.id }
        });

        setFavorites(res.data.favorites);

        setLoadingTracks(false);
      } catch (err) {
        console.log(err);
      }
    }
  }, [client, auth.user.id]);

  const addToFavorites = async trackId => {
    try {
      const data = await client.mutate({
        mutation: ADD_TO_FAVORITES,
        variables: { userId: auth.user.id, trackId: trackId },
        refetchQueries: [
          {
            query: GET_FAVORITES,
            variables: { userId: auth.user.id }
          }
        ]
      });

      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const addTrackToFavoritelist = track => {
    const newFavorite = { track: track };
    setFavorites(prevState => [...prevState, newFavorite]);
  };

  const removeFromFavorites = async (trackId, index) => {
    try {
      const data = await client.mutate({
        mutation: REMOVE_FROM_FAVORITES,
        variables: { userId: auth.user.id, trackId: trackId },
        refetchQueries: [
          {
            query: GET_FAVORITES,
            variables: { userId: auth.user.id }
          }
        ]
      });

      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const removeTrackFromList = (track, favId) => {
    const newTracklistFromFavorites = favorites.filter(
      favorite => track.id !== favorite.track.id
    );

    setFavorites(newTracklistFromFavorites);

    if (favId) {
      searchContext.unFavoriteTrack(track.id);
    }
  };

  useEffect(() => {
    fetchFavorites();
  }, [fetchFavorites]);
  return (
    <FavoritesContext.Provider
      value={{
        favorites,
        addToFavorites,
        removeFromFavorites,
        fetchFavorites,
        removeTrackFromList,
        addTrackToFavoritelist,
        loadingTracks
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

export default withApollo(FavoritesProvider);
