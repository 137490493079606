import styled from "styled-components";

export const baseHeaderHeight = 72;
export const headerTablePlusHeight = 96;

export const Container = styled.div`
  height: ${baseHeaderHeight}px;
  width: 100%;
  background: ${props => props.theme.backgroundDark};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  z-index: 151;
  position: fixed;
  top: 0px;

  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}) {
    margin: 0 auto;
    padding: 15px 10px;
  }

  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding: 30px 60px;
    height: ${headerTablePlusHeight}px;
  }

  .drawer-container {
    width: 279px;
    position: fixed;
    right: 0;
    top: 72px;

    @media (min-width: ${({ theme }) => theme.mobileBreakpoint}) {
      top: 96px;
    }
  }

  .admin-content {
    display: flex;
    margin-left: 34px;

    .nav-item {
      margin-right: 34px;
      color: ${props => props.theme.textDark};
      font-size: ${props => props.theme.p1b.fs};
      font-family: ${props => props.theme.p1r.ff};
      letter-spacing: 0.5px;
    }
  }

  .search-icon img {
    width: 24px;
    margin-right: 24px;
  }
`;

export const TeamName = styled.div`
  background: ${props => props.color};
  min-width: 146px;
  padding: 0px 21px 0px 25px;
  width: auto;
  height: 24px;
  border-radius: 33px;
  display: flex;
  align-items: center;
  max-width: 250px;
  justify-content: center;
  cursor: pointer;

  .team-title {
    font-family: ${props => props.theme.p2b.ff};
    font-size: ${props => props.theme.p2b.fs};
    color: ${props => props.theme.textLight};
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const Inner = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;

  .always-align-top {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 20px;
    width: inherit;
    margin-top: ${({ customLogo }) => (customLogo ? "0" : "6px")};
  }

  .nav-items {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 801px) {
      margin-left: -100px;
    }
  }

  .logo-container,
  .header-container {
    margin-right: 75px;
  }

  .header-container {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-items: center;

    .blue-dot-title {
      width: 100%;
      justify-self: center;
    }

    .text {
      text-align: center;
      color: ${props => props.theme.textDark};
      margin-top: 2px;
    }
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  min-width: 100px;

  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}) {
    min-width: 110px;
  }
`;

export const Cart = styled.div`
  position: relative;
`;

export const Counter = styled.div`
  position: absolute;
  right: -3px;
  top: 2px;
  background: ${props => props.theme.highlightDark};
  width: 21px;
  height: 21px;
  font-size: ${props => props.theme.p2b.fs};
  font-family: ${props => props.theme.p2b.ff};
  line-height: ${props => props.theme.lineHeightL};
  text-align: center;
  border-radius: 50%;

  p {
    margin-top: 3.5px;
    margin-left: -2px;
  }
`;

export const SearchContainer = styled.div`
  flex-grow: 6;
  margin-left: 66px;
  margin-right: 24px;
  display: flex;
  align-items: flex-start;
  max-width: 90%;

  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}) {
    margin-left: 24px;
  }

  @media (max-width: 750px) {
    display: none;
  }
`;

export const Icon = styled.img`
  cursor: pointer;
`;

export const MobileView = styled.div`
  display: grid;
  grid-auto-columns: auto 1fr;
  grid-auto-flow: column;
  width: 100%;
  align-items: center;

  .mobile-items {
    justify-self: end;
    margin-right: 12px;
  }
`;
