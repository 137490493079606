import * as React from "react";
import { theme } from "../globalStyles";

export const Plus = () => (
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.08772 4.628V0.668H5.92372V4.628H9.88372V6.464H5.92372V10.424H4.08772V6.464H0.127719V4.628H4.08772Z"
      fill={theme.editor.iconLight}
    />
  </svg>
);
