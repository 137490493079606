import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;

  .stem-box {
    height: 16px;
    margin: 3px 0;
    border-radius: 5px;
    float: left;
  }

  .stem-box-0 {
    background-color: #AA65AB;
    margin-left: 18%;
    width: 70%;
  }

  .stem-box-1 {
    background-color: #5E5DAA;
    float: left;
    width: 80%;
  }

  .stem-box-2 {
    background-color: #5D95AA;
    float: right;
    width: 60%;
  }

  .stem-box-3 {
    background-color: #AA65AB;
    width: 40%;
    margin-left: 25%;
    float: left;
  }
`;
