import { Reducer } from "redux";
import {
  EMPTY_PROJECT,
  EmptyProjectAction,
  FINISH_OFFLINE_RENDER,
  FinishOfflineRenderAction,
  INITIALIZE_OFFLINE_RENDER,
  InitializeOfflineRenderAction,
  SEEK,
  SeekAction,
  SET_EDITOR_MODE,
  SET_LOCATOR_TIME,
  SET_PLAYBACK_STATE,
  SetEditorModeAction,
  SetLocatorTimeAction,
  SetPlaybackStateAction,
  SPLIT_REGION,
  SplitRegionAction,
  UPDATE_OFFLINE_RENDER_PROGRESS,
  UpdateOfflineRenderProgressAction
} from "../actions/actionTypes";
import { EditorMode, EditorState, PlaybackState } from "../store";

export let defaultEditorState: EditorState = {
  isRendering: false,
  lastPlayGlobalTime: 0,
  lastPlaySongTime: 0,
  locatorTime: 0,
  mode: EditorMode.NORMAL,
  nthProjectLoaded: 0,
  playbackState: PlaybackState.PAUSED,
  renderProgressPercentage: 0
};

export const editorStateReducer: Reducer<
  EditorState,
  | SetEditorModeAction
  | SplitRegionAction
  | SetPlaybackStateAction
  | SeekAction
  | SetLocatorTimeAction
  | InitializeOfflineRenderAction
  | FinishOfflineRenderAction
  | UpdateOfflineRenderProgressAction
  | EmptyProjectAction
> = (state = defaultEditorState, action) => {
  switch (action.type) {
    case SET_EDITOR_MODE:
      return {
        ...state,
        mode: action.mode
      };
    case SPLIT_REGION:
      return {
        ...state,
        mode: EditorMode.NORMAL
      };
    case SET_PLAYBACK_STATE:
      return {
        ...state,
        lastPlaySongTime: action.actionSongTime,
        playbackState: action.state
      };
    case SEEK:
      if (
        state.playbackState === PlaybackState.PLAYING &&
        state.lastPlaySongTime === action.seconds
      ) {
        // handles edge case where editor is playing and user reclicks the exact same locator time position
        // redux won't actually broadcast an update on lastPlaySongTime, so this insignificantly perturbs the
        // value to broadcast an update to listening components
        action.seconds += 1e-14;
      }
      return {
        ...state,
        lastPlaySongTime: action.seconds,
        locatorTime: action.seconds
      };
    case SET_LOCATOR_TIME:
      return {
        ...state,
        locatorTime: action.seconds
      };
    case INITIALIZE_OFFLINE_RENDER:
      return {
        ...state,
        isRendering: true,
        renderProgressPercentage: 0
      };
    case FINISH_OFFLINE_RENDER:
      return {
        ...state,
        isRendering: false,
        renderProgressPercentage: 0
      };
    case UPDATE_OFFLINE_RENDER_PROGRESS:
      return {
        ...state,
        renderProgressPercentage: action.progress
      };
    case EMPTY_PROJECT:
      return {
        ...state,
        nthProjectLoaded: state.nthProjectLoaded + 1
      };
    default:
      return state;
  }
};
