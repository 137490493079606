import { EncapsulatedAudioNode } from "./FX/EncapsulatedAudioNode";

/*
 *  This template allows for creation and encapsulation of a daisy chain of audio nodes
 *  Because it inherits from GainNode, it can be connected as a destination from another
 *  AudioNode just like it were a normal AudioNode. The instance of this class is a GainNode
 *  that is the input audio node.
 *  See https://github.com/g200kg/webaudio-customnode for the gist (I ported to typescript)
 */
export class AudioNodeChain extends EncapsulatedAudioNode {
  public daisyChain: AudioNode[];

  /*
   * Params:
   *    context (AudioContext | OfflineAudioContext): AudioContext (from AudioMaster)
   *    nodes (AudioNode[]): list of audio nodes to chain in sequence
   */
  constructor(context: AudioContext | OfflineAudioContext, nodes: AudioNode[]) {
    super(context);

    this.daisyChain = nodes;

    // connect up these nodes in sequence
    this.daisyChain.reduce((prev, next) => {
      if (prev) {
        prev.connect(next);
      }
      return next;
    }, null);

    if (this.daisyChain.length === 0) {
      this._inputConnect(this.outputNode);
    } else {
      // connect input gain node to start of internal chain
      const firstChainNode = this.daisyChain[0];
      this._inputConnect(firstChainNode);

      // and last node in internal chain to output gain node
      const lastChainNode = this.daisyChain[this.daisyChain.length - 1];
      lastChainNode.connect(this.outputNode);
    }
  }
}
