import styled from "styled-components";

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .tag-label {
    margin-bottom: 20px;
  }

  .tag-inner {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .tags {
    height: auto;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    ${({ hasLines }) => {
      return hasLines
        ? "border-top: 1px solid #464646; padding-top: 20px;"
        : "";
    }}
  }

  .tag {
    margin: 0 20px 0 0 !important;
  }

  .tag-1 .tag-inner {
    margin-bottom: 20px;
  }
`;
