import React, {
  useContext,
  useState,
  useCallback,
  createContext,
  useEffect
} from "react";
import useModal from "../../../hooks/useModal";
import { QueueContext } from "../../../context/QueueProvider";
import { applyMiddleware, createStore, Store } from "redux";
import thunk from "redux-thunk";
import { AudioMaster } from "../../audio/AudioMaster";
import { AudioMiddleWare } from "../../audio/AudioMiddleware";
import { ResolveGlobalsMiddleware } from "../../middlewares/ResolveGlobalsMiddleware";
import { rootReducer } from "../../reducers";
import {
  CustomMixInfo,
  InitialTrackData,
  MainStore,
  PlaybackState,
  Project
} from "../../store";
import { initialState } from "../../store/test_initial_store";
import {
  LoadCustomMixProjectAction,
  LoadMixFromInitialTrack,
  LOAD_CUSTOM_MIX_PROJECT,
  LOAD_MIX,
  SEEK,
  SeekAction,
  SetAlertAction,
  SetPlaybackStateAction,
  SET_ALERT,
  SET_PLAYBACK_STATE
} from "../../actions/actionTypes";
import { AuthContext } from "../../../context/AuthProvider";
import { getCustomMix, getTrackInfo } from "../../api/WebAPI";
import Hotjar from "@hotjar/browser";

const INITIALTRACK_LOAD_NO_STEMS_MESSAGE =
  "This track has no stem files. Please contact our support team to request stems and editor access for this track.";
const TRACK_OPEN_PERMISSIONS_DENIED_MSG =
  "You don't have permission to access this track. Get access by subscribing to our library or purchasing a single license for this track.";
const CUSTOMMIX_OPEN_PERMISSIONS_DENIED_MSG =
  "You don't have permission to access this custom mix. Ensure you have an active subscription license for the track and contact the custom mix owner for access.";

const audioMaster = new AudioMaster();

// this is where the main redux store for the entire editor is created
// it is then attached to EditorContext to be accessible within all components
const store: Store<MainStore> = createStore(
  rootReducer,
  initialState,
  applyMiddleware(
    thunk,
    AudioMiddleWare(audioMaster),
    ResolveGlobalsMiddleware(audioMaster)
  )
);

/*
 * All data provided by the EditorContext
 * can be accessed in components via `const editor = useContext(EditorContext);`
 */
export type EditorContextType = {
  store: Store<MainStore>;
  audioMaster: AudioMaster;
  drawerState: any;
  open: () => void;
  close: () => void;
  state: EditorState;
  requestTrackOpen: (
    track: InitialTrackData,
    loadFromCache: boolean,
    demo: boolean,
    onRehydrated?: (track: InitialTrackData) => void,
    onLoaded?: (track: InitialTrackData) => void
  ) => void;
  requestTrackIdOpen: (
    trackId: string,
    loadFromCache: boolean,
    demo: boolean,
    onRehydrated?: (track: InitialTrackData) => void,
    onLoaded?: (track: InitialTrackData) => void
  ) => void;
  requestCustomMixOpen: (
    customMix: Project,
    onRehydrated?: (customMix: Project) => void,
    onLoaded?: (customMix: Project) => void
  ) => void;
  requestCustomMixIdOpen: (
    customMixId: string,
    versionNumber?: Number,
    onRehydrated?: (customMix: Project) => void,
    onLoaded?: (customMix: Project) => void
  ) => void;
  editorPermissionsResult: EditorPermissionsResult;
  isDemo: boolean;
  tightLayout: boolean;
  setTightLayout: (arg0: boolean) => void;
  initialTrack: InitialTrackData;
  customMix: CustomMixInfo;
  isLoading: boolean;
  isRehydrating: boolean;
};

export interface EditorPermissionsResult {
  granted: boolean;
  customMix?: Project;
  track: InitialTrackData;
  accessDeniedMsg?: string;
}

export enum EditorState {
  CLOSED = "closed",
  LOADING = "loading",
  REHYDRATED = "rehydrated",
  LOADED = "loaded"
}

export const EditorContext = createContext<EditorContextType>(undefined);

/*
 * Context for the Editor.
 * It holds and disseminates editor metadata, including the redux store, AudioMaster, load state,
 * what's loaded (Track or CustomMix), user access permissions, etc.
 * It also provides functions to request loading the editor with a Track or CustomMix.
 * It also provides functions to control the underlying EditorDrawer
 */
const EditorProvider = props => {
  const drawerState = useModal();
  const [
    initialTrackData,
    setInitialTrackData
  ] = useState<InitialTrackData | null>(null);
  const [customMixInfo, setCustomMixInfo] = useState<CustomMixInfo | null>(
    null
  );
  const [isLoadedFromCache, setIsLoadedFromCache] = useState(null);
  const [editorPermissionsResult, seteditorPermissionsResult] = useState(null);
  const [isDemo, setIsDemo] = useState(false);
  const [isRehydrating, setIsRehydrating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState(EditorState.CLOSED);
  const [tightLayout, setTightLayout] = useState(true);

  const queue = useContext(QueueContext);
  const {
    user,
    hasPaidTrackAccess,
    canAccessTrackEditor,
    canAccessCustomMix
  } = useContext(AuthContext);

  const open = () => {
    drawerState.open();
    if (queue.playing) queue.setPlaying(false);
  };

  const close = () => {
    store.dispatch<SeekAction>({
      type: SEEK,
      seconds: 0
    });
    store.dispatch<SetPlaybackStateAction>({
      type: SET_PLAYBACK_STATE,
      state: PlaybackState.PAUSED,
      actionSongTime: 0
    });
    setState(EditorState.CLOSED);
    drawerState.close();
  };

  /*
   * Given a CustomMix ID and version , load the CustomMix from API, determine access permissions,
   * load up data in editor, show it
   */
  const requestCustomMixIdOpen = async (
    customMixId: string,
    versionNumber?: Number,
    onRehydrated?: (customMix: Project) => void,
    onLoaded?: (customMix: Project) => void
  ) => {
    getCustomMix(customMixId, versionNumber).then(customMix => {
      requestCustomMixOpen(customMix, onRehydrated, onLoaded);
    });
  };

  // Given a CustomMix, determine access permissions, load up data in editor, show it
  const requestCustomMixOpen = useCallback(
    (
      customMix: Project,
      onRehydrated?: (customMix: Project) => void,
      onLoaded?: (customMix: Project) => void
    ) => {
      if (
        customMixInfo === null ||
        customMixInfo.id !== customMix.customMixInfo.id ||
        customMixInfo.version.id !== customMix.customMixInfo.version.id
      ) {
        canAccessCustomMix(customMix).then(res => {
          res.granted = res.granted || user.adminUser || user.editorAlpha;
          seteditorPermissionsResult(res);
          if (res.granted) {
            setInitialTrackData(res.track);
            setCustomMixInfo(customMix.customMixInfo);
            setIsLoadedFromCache(true);
            setIsDemo(false);
            setIsRehydrating(true);
            setIsLoading(true);
            setState(EditorState.LOADING);
            // load the custom mix
            store.dispatch<LoadCustomMixProjectAction>({
              type: LOAD_CUSTOM_MIX_PROJECT,
              customMixId: customMix.customMixInfo.id,
              versionNumber: customMix.customMixInfo.version.versionNumber,
              paidAccess: hasPaidTrackAccess(
                customMix.initialTrackData,
                customMix.customMixInfo.projectId
              ),
              onRehydrated: () => {
                open();
                Hotjar.event("editorOpen");
                Hotjar.event("editorOpen-customMix");
                setIsRehydrating(false);
                setState(EditorState.REHYDRATED);
                if (onRehydrated) onRehydrated(customMix);
              },
              onLoaded: () => {
                setIsLoading(false);
                setState(EditorState.LOADED);
                if (onLoaded) onLoaded(customMix);
              }
            });
          } else {
            // access denied
            store.dispatch<SetAlertAction>({
              type: SET_ALERT,
              id: "CUSTOMMIX_OPEN_PERMISSIONS_DENIED",
              role: "error",
              title: "Permissions Error",
              message: CUSTOMMIX_OPEN_PERMISSIONS_DENIED_MSG
            });
          }
        });
      } else if (customMixInfo.id === customMix.customMixInfo.id) {
        drawerState.open();
        setState(EditorState.LOADED);
        if (onRehydrated) onRehydrated(customMix);
        if (onLoaded) onLoaded(customMix);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customMixInfo, drawerState, user.adminUser, user.editorAlpha]
  );

  /*
   * Given a Track ID, load the Track from API, determine access permissions, load up data
   * in editor, show it
   */
  const requestTrackIdOpen = async (
    trackId,
    loadFromCache,
    demo = false,
    onRehydrated?: (track: InitialTrackData) => void,
    onLoaded?: (track: InitialTrackData) => void
  ) => {
    getTrackInfo(trackId).then(track => {
      requestTrackOpen(track, loadFromCache, demo, onRehydrated, onLoaded);
    });
  };

  // Given a Track, determine access permissions, load up data in editor, show it
  const requestTrackOpen = useCallback(
    (
      track,
      loadFromCache,
      demo = false,
      onRehydrated?: (track: InitialTrackData) => void,
      onLoaded?: (track: InitialTrackData) => void
    ) => {
      if (
        initialTrackData === null ||
        initialTrackData.id !== track.id ||
        loadFromCache !== isLoadedFromCache
      ) {
        // the requested editor loadup is different, gather requested data and load it up
        const res = canAccessTrackEditor(track);
        res.granted = res.granted || user.adminUser || user.editorAlpha;
        seteditorPermissionsResult(res);
        if (res.granted) {
          // load from Track::editorDataDump cache or from scratch?
          setInitialTrackData(track);
          setCustomMixInfo(null);
          setIsLoadedFromCache(loadFromCache);
          setIsDemo(demo);
          setIsRehydrating(true);
          setIsLoading(true);
          setState(EditorState.LOADING);
          store.dispatch<LoadMixFromInitialTrack>({
            type: LOAD_MIX,
            projectId: track.id,
            fromCache: loadFromCache,
            paidAccess: hasPaidTrackAccess(track),
            onRehydrated: () => {
              setIsRehydrating(false);
              open();
              Hotjar.event("editorOpen");
              Hotjar.event("editorOpen-initialTrack");
              setState(EditorState.REHYDRATED);
              if (onRehydrated) onRehydrated(track);
            },
            onLoaded: () => {
              setIsLoading(false);
              setState(EditorState.LOADED);
              if (onLoaded) onLoaded(track);
            }
          });

          if (track.stem.length === 0) {
            store.dispatch<SetAlertAction>({
              type: SET_ALERT,
              id: "INITIALTRACK_LOAD_NO_STEMS",
              role: "error",
              title: "No Stems",
              message: INITIALTRACK_LOAD_NO_STEMS_MESSAGE
            });
          }
        } else {
          // access denied
          store.dispatch<SetAlertAction>({
            type: SET_ALERT,
            id: "TRACK_OPEN_PERMISSIONS_DENIED",
            role: "error",
            title: "Permissions Error",
            message: TRACK_OPEN_PERMISSIONS_DENIED_MSG
          });
        }
      } else if (
        initialTrackData.id === track.id ||
        loadFromCache === isLoadedFromCache
      ) {
        // everything's already loaded, just open the editor
        setState(EditorState.LOADED);
        drawerState.open();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      initialTrackData,
      isLoadedFromCache,
      drawerState,
      user.adminUser,
      user.editorAlpha
    ]
  );

  // integration with main site, ensure when track loaded in editor, playbar reflects that
  useEffect(() => {
    if (initialTrackData !== null && drawerState.visible) {
      // queue is empty or current song in queue does not match requested track, inject it
      queue.addToQueueAtCurrent(initialTrackData);
      queue.setPercentagePlayed(0);
      queue.setPlaying(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTrackData, drawerState.visible]);

  return (
    <EditorContext.Provider
      value={{
        store,
        audioMaster,
        drawerState,
        open,
        close,
        state,
        requestTrackOpen,
        requestTrackIdOpen,
        requestCustomMixOpen,
        requestCustomMixIdOpen,
        editorPermissionsResult,
        initialTrack: initialTrackData,
        customMix: customMixInfo,
        isDemo,
        tightLayout,
        setTightLayout,
        isLoading,
        isRehydrating
      }}
    >
      {props.children}
    </EditorContext.Provider>
  );
};

export default EditorProvider;
