import React from "react";
import "antd/dist/antd.css";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "./globalStyles";
import { client } from "./apolloClient";
import { ApolloProvider } from "@apollo/react-hooks";
import AppContainer from "./components/AppContainer";
import { BrowserRouter as Router } from "react-router-dom";

/* CONTEXT PROVIDERS */
import { StripeProvider } from "react-stripe-elements";
import AuthProvider from "./context/AuthProvider";
import CartProvider from "./context/CartContext/CartProvider";
import QueueProvider from "./context/QueueProvider";
import { ReactGAProvider } from "./context/ReactGAProvider";
import MessageProvider from "./context/MessageContext";
import {
  FavoritesProvider,
  NotificationsProvider,
  FilterProvider,
  SearchProvider
} from "./context";

const App = () => (
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <MessageProvider>
        <AuthProvider>
          <StripeProvider apiKey={`${process.env.REACT_APP_STRIPE_KEY}`}>
            <NotificationsProvider>
              <FavoritesProvider>
                <QueueProvider>
                  <GlobalStyle />
                  <Router>
                    <CartProvider>
                      <SearchProvider>
                        <ReactGAProvider>
                          <FilterProvider>
                            <AppContainer />
                          </FilterProvider>
                        </ReactGAProvider>
                      </SearchProvider>
                    </CartProvider>
                  </Router>
                </QueueProvider>
              </FavoritesProvider>
            </NotificationsProvider>
          </StripeProvider>
        </AuthProvider>
      </MessageProvider>
    </ApolloProvider>
  </ThemeProvider>
);

export default App;
