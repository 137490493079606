import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_MUTE_SOLO, SetMuteSoloAction } from "../../actions/actionTypes";
import { MainStore, MuteSoloState } from "../../store";
import { ControlButton } from "../Buttons/ControlButton";
import { FXPanel } from "../FXPanel";
import { PanKnob } from "../PanKnob";
import { LaneVolumeSlider } from "../LaneVolumeSlider";
import { Container } from "./styles";
import { LaneMenuControls } from "../LaneMenuControls";

interface LaneControlProps {
  laneId: string;
  startLaneRenameMode?: () => void;
}

export let LaneControls = (props: LaneControlProps) => {
  const muteSoloStatus = useSelector<MainStore, MuteSoloState>(
    state => state.project.lanes.byId[props.laneId].laneStatus.muteSoloState
  );
  let muteClass = "control-button mute ";
  if (muteSoloStatus === MuteSoloState.MUTE) {
    muteClass += " enabled";
  }

  let soloClass = "control-button solo";
  if (muteSoloStatus === MuteSoloState.SOLO) {
    soloClass += " enabled";
  }

  const dispatch = useDispatch();

  function mute() {
    const status =
      muteSoloStatus === MuteSoloState.MUTE
        ? MuteSoloState.DEFAULT
        : MuteSoloState.MUTE;
    dispatch<SetMuteSoloAction>({
      type: SET_MUTE_SOLO,
      laneId: props.laneId,
      muteSoloStatus: status
    });
  }

  function solo() {
    const status =
      muteSoloStatus === MuteSoloState.SOLO
        ? MuteSoloState.DEFAULT
        : MuteSoloState.SOLO;
    dispatch<SetMuteSoloAction>({
      muteSoloStatus: status,
      laneId: props.laneId,
      type: SET_MUTE_SOLO
    });
  }

  const anyFXEngaged = useSelector<MainStore, boolean>(state => {
    const status = state.project.lanes.byId[props.laneId].laneStatus;
    return status.fxSlot.some(param => param.val > 0);
  });

  let fxClass = "fx ";
  if (anyFXEngaged) {
    fxClass += " enabled";
  }

  return (
    <Container className="lane-controls">
      <LaneMenuControls
        laneId={props.laneId}
        startLaneRenameMode={props.startLaneRenameMode}
      />
      <FXPanel laneId={props.laneId}>
        <ControlButton className={`semi-hide-no-hover ${fxClass}`}>
          FX
        </ControlButton>
      </FXPanel>
      <ControlButton
        className={`semi-hide-no-hover ${muteClass}`}
        onClick={mute}
      >
        M
      </ControlButton>
      <ControlButton
        className={`semi-hide-no-hover ${soloClass}`}
        onClick={solo}
      >
        S
      </ControlButton>
      <PanKnob laneId={props.laneId} />
      <LaneVolumeSlider laneId={props.laneId} />
    </Container>
  );
};
