import styled, { keyframes } from "styled-components";

const menuAnimation = keyframes`
  0%{
    opacity: 0.6;
    transform: translateY(-15px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Container = styled.div`
  position: relative;
  outline: none;

  .menu {
    animation: ${menuAnimation} 0.5s ease;
    animation-direction: alternate;
    position: absolute;
    ${props => props.anchor}: ${props => props.yOffset};
    right: ${props => props.xOffset};
    z-index: 10000000;
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .clickable {
    cursor: pointer;
  }

  .triangle {
    margin-bottom: ${({ iconStyle }) => (iconStyle ? "0px" : "-3px")};
    margin-top: ${({ iconStyle }) => (iconStyle ? "-3px" : "0px")};
    width: 21px;
    height: 14px;
    margin-right: ${({ iconStyle }) => (iconStyle ? "-4px" : "10px")};
  }

  .down-triangle {
    width: 21px;
    height: 14px;
    margin-top: -3px;
    margin-right: 10px;
    transform: rotate(180deg);
  }
`;

export const Icon = styled.img`
  margin: 19px;
  cursor: pointer;
`;
