/*
  This is the core code for the search feature.  
*/

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import {
  advancedCharacteristics,
  advancedCharacteristics2,
  bpm,
  characteristics,
  duration,
  keys
} from "../../utils/searchFilters";

import {
  backendValue,
  convertFiltersToFrontend,
  convertToFrontend,
  relaxEqualRangeValues
} from "../../utils/rangeConversion";
import {
  filterDuplicate,
  filterIsDefault,
  formatResponse,
  generateDefaultRanges,
  isItemLengthOrBpm,
  removeDefault
} from "../../utils/searchHelpers";
import { AuthContext } from "../AuthProvider";

import {
  FETCH_SEARCH_TEXT_RESULT,
  FILTER_QUERY,
  GET_ALBUM,
  GET_AUTOCOMPLETE,
  GET_TRACK,
  SUGGEST_QUERY
} from "./queries";
import { withRouter } from "react-router-dom";

export const SearchContext = React.createContext();

const PAGINATION_SIZE = 50;
const FILTER_PAGINATION_SIZE = 50;

const formatAllTracks = array => {
  return array.map(item => item._source);
};

const sortModes = ["max", "sum", "avg"];

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

// seed is used to filter out duplicate tracks. (logic is done in backend)
localStorage.setItem("seed", Math.floor(Math.random() * 1000000));
let seed = parseInt(localStorage.getItem("seed"));

// seed used to have some randomization on sort options
localStorage.setItem("randomSortMode", sortModes[randomIntFromInterval(0, 2)]);
let randomSortMode = localStorage.getItem("randomSortMode");

const changeSearchSeeds = () => {
  localStorage.setItem("seed", Math.floor(Math.random() * 1000000));
  localStorage.setItem(
    "randomSortMode",
    sortModes[randomIntFromInterval(0, 2)]
  );
  seed = parseInt(localStorage.getItem("seed"));
  randomSortMode = localStorage.getItem("randomSortMode");
};

const SearchProvider = ({ children, location, history }) => {
  // for graphql requests
  const client = useApolloClient();

  // array of filters applied
  const [searchFilters, setFilters] = useState([]);

  // array of filters applied converted to frontend
  const [frontendSearchFilters, setFrontendFilters] = useState([]);

  // holds filters that is displayed when hovered on
  const [hoveredFilter, setHoveredFilter] = useState(null);

  const [selectedAggregate] = useState({});

  // search term, text input
  const [searchValue, setSearchValue] = useState("");

  // array of autocomplete suggestions
  const [autoCompleteSuggestions, setAutoCompleteSuggestions] = useState([]);

  // filters to be displayed in the sidebar;

  const all = [
    ...characteristics,
    ...advancedCharacteristics,
    ...advancedCharacteristics2
  ];
  const [allCharacteristics, setChars] = useState(all);

  // this is to display the tag for album and playlist on the header.
  const [selectedTrackList, setSelectedTrackList] = useState(undefined);
  const [allTags, setAllTags] = useState([]);
  const [aggregates, setAllAggregates] = useState([]);
  const [allInstruments, setAllInstruments] = useState([]);
  const [allKeys] = useState(keys);

  const [allTracks, setAllTracks] = useState([]);

  const [exactTracks, setExactTracks] = useState([]);
  const [suggestedTracks, setSuggestedTracks] = useState([]);
  const [filteredSuggestedTracks, setFilteredSuggestedTracks] = useState([]);
  const [loadingTracks, setLoadingTracks] = useState(false);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  // data from frontend search tags & instruments
  const [tagSearchResults, setTagSearchResults] = useState([]);
  const [instrumentSearchResults, setInstrumentSearchResult] = useState([]);
  const [aggregateResults, setAggregateResults] = useState([]);

  // TODO: refactor to tracks context
  const [trackToBeDownloaded, setDownloadTrackState] = useState({});

  const [isLoadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const [allTracksPaginator, setAllPaginator] = useState(true);
  const [suggestPaginator, setSuggestPaginator] = useState(undefined);
  const [exactTracksPaginator, setExactTracksPaginator] = useState(undefined);
  const [selectableSuggestPaginator, setSelectablePaginator] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingMoreExact, setLoadingMoreExact] = useState(false);
  const [textSearching, setTextSearching] = useState(false);
  const [selectedAggregates, setAggregates] = useState([]);

  const [singleTrack, setSingleTrack] = useState({});
  const [tracksCache, setTrackCache] = useState({});

  const [fetchingDownloadTrack, setFetchingDownloadTrack] = useState(false);

  const allSearchResults = [
    ...allTracks,
    ...autoCompleteSuggestions,
    ...allTags,
    ...aggregateResults,
    ...instrumentSearchResults
  ];

  const auth = useContext(AuthContext);

  /*
    receive tags, instruments and aggregates from auth context.
  */
  useEffect(() => {
    setAllAggregates(auth.aggregates);
    setAllTags(auth.allTags);
    setAllInstruments(auth.allInstruments);
  }, [auth.aggregates, auth.allTags, auth.allInstruments]);

  /*
  convert applied filters to frontend.
  */
  useEffect(() => {
    const convertedValues = convertFiltersToFrontend(searchFilters);
    setFrontendFilters(convertedValues);
  }, [searchFilters]);

  /*
    fetch unfiltered tracks.
  */
  const fetchTracks = useCallback(async () => {
    setAllTracks([]);
    let variables = {
      filters: [],
      size: PAGINATION_SIZE,
      seed,
      randomSortMode: randomSortMode,
      from: 0
    };

    try {
      setLoadingTracks(true);
      const res = await client.query({
        query: SUGGEST_QUERY,
        fetchPolicy: "network-only",
        variables
      });

      let tracks = [];

      tracks = res.data.suggest.map(item => item._source);
      if (tracks < PAGINATION_SIZE) setAllPaginator(false);

      setAllTracks(tracks);
      setLoadingTracks(false);
    } catch (err) {
      console.error("fetch tracks ", err);
    }
    // eslint-disable-next-line
  }, [client]);

  /*
    this object contains pagination related properties for easy access for
    "true" for search/filtering pagination and
    "false" for unfiltered track pagination
   */
  const pagination = {
    paginator: {
      true: suggestPaginator,
      false: allTracks.length + 1
    },
    tracks: {
      true: suggestedTracks,
      false: allTracks
    },
    setPaginator: {
      true: setSuggestPaginator,
      false: setAllPaginator
    },
    setTracks: {
      true: setSuggestedTracks,
      false: setAllTracks
    },
    filters: {
      true: relaxEqualRangeValues(searchFilters),
      false: []
    },
    formatter: {
      true: formatResponse,
      false: formatAllTracks
    },
    seed: {
      true: seed,
      false: seed
    },
    randomSortMode: {
      true: randomSortMode
    }
  };

  const fetchMoreTracks = async () => {
    // using isSearching boolean to distinguish between
    // browse tracks and searched tracks
    // object mapping fields is above this function
    // if searching
    if (isSearching && !pagination.paginator[isSearching]) return;

    // is not searching and paginator is set to false
    if (!isSearching && !allTracksPaginator) return;

    setLoadingMore(true);

    try {
      const res = await client.query({
        query: SUGGEST_QUERY,
        fetchPolicy: "network-only",
        variables: {
          filters: pagination.filters[isSearching] || [],
          from: pagination.paginator[isSearching],
          randomSortMode: randomSortMode,
          size: PAGINATION_SIZE,
          seed: pagination.seed[isSearching]
        }
      });

      const tracks = pagination.formatter[isSearching](res.data.suggest);
      const added = [...pagination.tracks[isSearching], ...tracks];

      if (
        // if we have after key and it's the same as the previous
        res.data.suggest.after_key &&
        res.data.suggest.after_key.parentTrackId ===
          pagination.paginator[isSearching].parentTrackId
      ) {
        return setLoadingMore(false);
      }

      if (!isSearching && tracks.length < PAGINATION_SIZE)
        setAllPaginator(false);

      if (isSearching) {
        pagination.setPaginator[isSearching](res.data.suggest.after_key);
        let exactTracksHash = {};
        exactTracks.forEach(item => (exactTracksHash[item.id] = item.title));
        const filteredSuggestions = added.filter(
          item => !exactTracksHash[item.id]
        );

        pagination.setTracks[isSearching](filteredSuggestions);
        setLoadingMore(false);
        return;
      }

      pagination.setTracks[isSearching](added);
      setLoadingMore(false);
    } catch (err) {
      setLoadingMore(false);
      console.error("search provider fetch more", err);
    }
  };

  const fetchMoreExactTracks = async () => {
    // return if we don't have a paginator (meaning we have fetched all tracks);
    if (!exactTracksPaginator) {
      setLoadingMoreExact(false);
      return;
    }
    setLoadingMoreExact(true);

    const relaxedValues = relaxEqualRangeValues(searchFilters);

    try {
      const res = await client.query({
        query: FILTER_QUERY,
        variables: {
          filters: relaxedValues,
          from: exactTracksPaginator,
          randomSortMode,
          size: FILTER_PAGINATION_SIZE
        }
      });
      const organizedExactTracks = formatResponse(res.data.filter);
      const combined = [...exactTracks, ...organizedExactTracks];
      setExactTracks(combined);
      setExactTracksPaginator(res.data.filter.after_key);
      setLoadingMoreExact(false);
    } catch (err) {
      console.error("Search provider: fetchMoreExactTrack ", err);
    }
  };

  /*
    When the user applies only selectable filters, 
    we use a separate function to paginate the suggested responses
  */
  const fetchMoreSelectableSuggest = async () => {
    // pagination for all selectable search filters
    if (!selectableSuggestPaginator) return;
    setLoadingMore(true);
    const relaxedValues = relaxEqualRangeValues(searchFilters);

    try {
      const res = await client.query({
        query: SUGGEST_QUERY,
        fetchPolicy: "network-only",
        variables: {
          filters: relaxedValues,
          from: suggestedTracks.length + 1,
          randomSortMode: randomSortMode,
          size: PAGINATION_SIZE,
          seed
        }
      });

      const tracks = formatAllTracks(res.data.suggest);
      if (tracks.length < PAGINATION_SIZE) setSelectablePaginator(false);

      const filteredSuggestions = filterDuplicate(exactTracks, tracks);

      const combined = [...suggestedTracks, ...filteredSuggestions];
      setSuggestedTracks(combined);
      setLoadingMore(false);
    } catch (err) {
      console.error("Search Provider: fetchMoreSelectableSuggest ", err);
    }
  };

  useEffect(() => {
    fetchTracks();
  }, [fetchTracks]);

  useEffect(() => {
    const { pathname } = location;
    if (searchFilters.length !== 0) {
      if (
        pathname.includes("playlist") ||
        pathname.includes("track") ||
        pathname.includes("album") ||
        pathname.includes("favorites")
      ) {
        history.push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters]);

  useEffect(() => {
    // filter out duplicate tracks from suggest
    const filteredSuggestions = filterDuplicate(exactTracks, suggestedTracks);
    setFilteredSuggestedTracks(filteredSuggestions);
  }, [suggestedTracks, exactTracks]);

  /*
  This is the main effect that is fired when the search filters is changed. 
  It fires a back-end request.
  */
  useEffect(() => {
    changeSearchSeeds();

    if (
      searchFilters.length === 1 &&
      (searchFilters[0].filterName === "track" ||
        searchFilters[0].filterName === "session")
    ) {
      return;
    }

    if (searchFilters.length === 0) {
      setIsSearching(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    setIsSearching(true);

    const removeDuplicateTracks = tracks => {
      let result = {};
      if (tracks.length < 0) {
        return [];
      }
      tracks.forEach(track => {
        const id = track.parentTrackId ? track.parentTrackId : track.id;
        result = {
          ...result,
          [id]: result[id] ? [...result[id], track] : [track]
        };
      });

      let finalFilteredResults = {};
      Object.keys(result).forEach(parentId => {
        result[parentId].forEach(track => {
          if (!finalFilteredResults[parentId]) {
            finalFilteredResults = {
              ...finalFilteredResults,
              [parentId]: track
            };
          }
          if (!track.variation) {
            finalFilteredResults = {
              ...finalFilteredResults,
              [parentId]: track
            };
          }
        });
      });

      const finalResult = Object.keys(finalFilteredResults).map(parentId => {
        return finalFilteredResults[parentId];
      });

      return finalResult;
    };

    const runFilters = async () => {
      setLoadingTracks(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      wipeData();
      const relaxedValues = relaxEqualRangeValues(searchFilters);

      try {
        if (textSearching) {
          const searchText = await client.query({
            query: FETCH_SEARCH_TEXT_RESULT,
            variables: { q: searchFilters[0].filterValue }
          });
          setLoadingTracks(false);
          let formattedData = [];
          if (searchText.data.textSearch.length > 0) {
            searchText.data.textSearch.forEach(item => {
              if (item.tracks) {
                formattedData = [...formattedData, ...item.tracks];
              } else formattedData = [...formattedData, item];
            });
          }

          const result = await removeDuplicateTracks(formattedData);

          setExactTracks(result);
          setLoadingTracks(false);
        }

        //  Fetch exact matches.
        const filterRes = await client.query({
          query: FILTER_QUERY,
          variables: {
            filters: relaxedValues,
            from: undefined,
            size: FILTER_PAGINATION_SIZE
          }
        });

        setExactTracksPaginator(filterRes.data.filter.after_key);
        const organizedExactTracks = formatResponse(filterRes.data.filter);

        if (!textSearching) {
          setExactTracks(organizedExactTracks);
          setLoadingTracks(false);
          setLoadingSuggestions(true);
        }

        // fetch suggested tracks
        const suggestRes = await client.query({
          query: SUGGEST_QUERY,
          fetchPolicy: "network-only",
          variables: {
            filters: relaxedValues,
            from: onlySelectableFilters() ? 0 : undefined,
            randomSortMode: randomSortMode,
            seed: seed,
            size: PAGINATION_SIZE
          }
        });

        setSuggestPaginator(suggestRes.data.suggest.after_key);
        const organizedSuggestTracks = onlySelectableFilters()
          ? formatAllTracks(suggestRes.data.suggest)
          : formatResponse(suggestRes.data.suggest);

        if (
          onlySelectableFilters() &&
          organizedSuggestTracks.length < PAGINATION_SIZE
        )
          setSuggestPaginator(false);

        setSuggestedTracks(organizedSuggestTracks);

        setLoadingSuggestions(false);
      } catch (err) {
        setLoadingTracks(false);
        setLoadingSuggestions(false);
      }
    };

    runFilters();
    // eslint-disable-next-line
  }, [searchFilters, client, fetchTracks]);

  const wipeData = () => {
    setSuggestedTracks([]);
    setExactTracks([]);
  };

  /*
    name - handleHoveredRange
    return type - void
    description - if user hovers over a range filer on the track,
     this function deals with displaying a hovered filter on the header.

     params       type      description

    filter         {}         a filter object that contains filterName, filterType, filterValue
  */
  const handleHoveredRange = (name, type, value, min, max) => {
    const isInFilters = searchFilters.some(item => item.filterName === name);
    if (isInFilters) return;
    setHoveredFilter({
      min: min || value,
      max: max || value,
      filterType: type,
      filterName: name,
      filterValue: value
    });
  };

  /*
    name - handleHoveredSelectable
    return type - void
    description - if user hovers over a selectable filer on the track,
      this function deals with displaying a hovered filter on the header.

    params       type          description

    name           string         name of the selectable
    type           string         type  >> >>   >>

  */
  const handleHoveredSelectable = (name, type) => {
    const isInFilters = searchFilters.some(
      item => item.filterValue === name || item.filterName === name
    );
    if (isInFilters) return;
    setHoveredFilter({
      filterType: "selectable",
      filterName: type,
      filterValue: name
    });
  };

  const clearHoveredFilter = () => {
    setHoveredFilter(undefined);
  };

  /*
    name - handleChars
    return type - void
    description - makes the sidebar characteristics array in sync with 
      applied filter given as a param.

     params       type          description

     filter        {}            a filter object
     value         []            [min, max] 
  */
  const handleChars = (filter, value) => {
    const chars = allCharacteristics.map(item => {
      if (item.filterName.toLowerCase() === filter.filterName.toLowerCase()) {
        return {
          ...item,
          min: value[0],
          max: value[1],
          filterType: "range",
          filterName: filter.filterName
        };
      }
      return item;
    });

    setChars(chars);
  };

  /*
    name - onCharChange
    return type - void
    description - this function is invoked whenever the user changes the range sliders.
      It sets the characteristics but it considers an aggregate that contains that characteristics.

     params       type          description

     filter        {}            a filter object
     value         []            [min, max] 
  */
  const onCharChange = (filter, value) => {
    setHoveredFilter(undefined);
    if (selectedTrackList) {
      setSelectedTrackList(undefined);
    }

    handleChars(filter, value);

    const containingAggregate = selectedAggregates.find(item =>
      item.filters.some(
        aggFilter =>
          aggFilter.filterName.toLocaleLowerCase() ===
          filter.filterName.toLowerCase()
      )
    );

    if (containingAggregate) {
      handleAggregateChange(containingAggregate, filter, value);
      return;
    }

    const isInFilters = searchFilters.some(
      item =>
        item.filterName.toLocaleLowerCase() ===
        filter.filterName.toLocaleLowerCase()
    );
    if (isInFilters) {
      let filters = searchFilters.map(item => {
        if (
          item.filterName.toLocaleLowerCase() ===
          filter.filterName.toLocaleLowerCase()
        )
          return {
            min: isItemLengthOrBpm(filter) ? value[0] : backendValue[value[0]],
            max: isItemLengthOrBpm(filter) ? value[1] : backendValue[value[1]],
            filterType: "range",
            filterName: filter.filterName
          };
        return item;
      });
      filters = removeDefault(filters, filter);
      setFilters(filters);
    } else {
      const isDefaultValue = filterIsDefault(filter, value);
      if (isDefaultValue) return;

      setFilters(prev => [
        ...prev,
        {
          min: isItemLengthOrBpm(filter) ? value[0] : backendValue[value[0]],
          max: isItemLengthOrBpm(filter) ? value[1] : backendValue[value[1]],
          filterType: "range",
          filterName: filter.filterName
        }
      ]);
    }
  };

  /*
  name - handleAggregateChange
  return type - void
  description - called when user applies a filter that's 
    already in an aggregate that is also applied.

  params                    type          description

  containingAggregate        {}            an aggregate object
  filter                     {}           a filter object
  value                      []            [min,max] 
  */
  const handleAggregateChange = (containingAggregate, filter, value) => {
    const filteredAggregates = selectedAggregates.filter(
      item => item.aggregateName !== containingAggregate.aggregateName
    );

    const addedFilter = {
      min: isItemLengthOrBpm(filter) ? value[0] : backendValue[value[0]],
      max: isItemLengthOrBpm(filter) ? value[1] : backendValue[value[1]],
      filterType: "range",
      filterName: filter.filterName
    };
    setAggregates(filteredAggregates);

    const decide = item => {
      if (!item.aggregate) return false;
      if (containingAggregate.aggregateName === item.aggregateName)
        return false;
      else return true;
    };

    const withAggregateFilters = searchFilters
      .map(item => ({
        ...item,
        aggregate: decide(item)
      }))
      .filter(
        item =>
          item.filterName.toLowerCase() !== addedFilter.filterName.toLowerCase()
      );

    setFilters([addedFilter, ...withAggregateFilters]);
    return;
  };

  /*
  name - handleAggregateChange
  return type - void
  description - this function handles adding a selectable and aggregate filter.
  It also handles the logic of removing clashing aggregates a from applied filters.

  params            type          description

  name              string        name of the selectable
  type              string        type of selectable (tags, instruments, keys)
  search            boolean       whether the function is called from autocomplete
  item              {}            aggregate object
*/
  const addSelectable = (name, type, search, item) => {
    setHoveredFilter(undefined);
    if (search) {
      if (type === "aggregate") {
        if (selectedAggregates.some(aggs => aggs.aggregateName === item.name))
          return;

        const aggregateTags = Object.keys(item)
          .map(key => {
            if (
              key !== "name" &&
              key !== "type" &&
              key !== "aggregateName" &&
              item[key] !== ""
            ) {
              if (key === "BPM" || key === "Length") {
                return {
                  min: item[key].min,
                  max: item[key].max,
                  filterName: key,
                  filterType: "range",
                  aggregate: true,
                  aggregateName: item.name
                };
              } else if (item[key].min || item[key].max) {
                return {
                  min: backendValue[item[key].min],
                  max: backendValue[item[key].max],
                  filterType: "range",
                  filterName: key,
                  aggregate: true,
                  aggregateName: item.name
                };
              } else if (item[key].filterType) {
                return {
                  filterType: "selectable",
                  filterName: item[key].filterName,
                  filterValue: item[key].filterValue,
                  aggregate: true,
                  aggregateName: item.name
                };
              }
              return null;
            }
            return null;
          })
          .filter(item => item !== null);
        setSelectedTrackList({ name: name, type: "Aggregate" });

        let hash = {};
        aggregateTags.forEach(item => {
          if (item.filterType === "range")
            hash[item.filterName.toLowerCase()] = true;
          else {
            hash[item.filterValue.toLowerCase()] = true;
          }
        });

        let filtered = searchFilters.filter(item => {
          if (item.filterType === "range")
            return !hash[item.filterName.toLowerCase()];
          else {
            return !hash[item.filterValue.toLowerCase()];
          }
        });

        const aggregate = {
          aggregateName: item.name,
          filters: aggregateTags
        };

        let aggHash = {};
        aggregate.filters.forEach(item => {
          if (item.filterType === "range")
            aggHash[item.filterName.toLowerCase()] = true;
          else {
            aggHash[item.filterValue.toLowerCase()] = true;
          }
        });

        const affectedAggregates = selectedAggregates.filter(item =>
          item.filters.some(filter => {
            if (filter.filterType === "range")
              return hash[filter.filterName.toLowerCase()];
            else {
              return hash[filter.filterValue.toLowerCase()];
            }
          })
        );

        if (affectedAggregates.length === 0) {
          setAggregates([...selectedAggregates, aggregate]);
          setSidebarRanges(aggregateTags);
          setFilters([...filtered, ...aggregateTags]);

          return;
        }

        const unAffectedAggregates = selectedAggregates.filter(
          item =>
            !item.filters.some(filter => {
              if (filter.filterType === "range")
                return hash[filter.filterName.toLowerCase()];
              else {
                return hash[filter.filterValue.toLowerCase()];
              }
            })
        );

        filtered = filtered.map(filter => ({
          ...filter,
          aggregate: !affectedAggregates.some(
            item => item.aggregateName === filter.aggregateName
          )
        }));
        setAggregates([aggregate, ...unAffectedAggregates]);
        setFilters([...filtered, ...aggregateTags]);
        setSidebarRanges([...aggregateTags]);

        return;
      }

      const itemExists = searchFilters.some(item => item.filterValue === name);
      if (!itemExists) {
        if (item.type === "dateRange") {
          setFilters(prev => [
            ...prev,
            {
              filterType: item.type,
              filterName: item.name,
              filterValue: item.value,
              dateMin: item.min,
              dateMax: item.max
            }
          ]);
          return;
        }
        setFilters(prev => [
          ...prev,
          {
            filterType: "selectable",
            filterName: type,
            filterValue: name,
            hidden: type === "track" || type === "session" ? true : false
          }
        ]);
      }

      return;
    }

    const itemExists = searchFilters.some(item => item.filterValue === name);
    if (type === "key") {
      const updated = searchFilters.filter(item => item.filterName !== "key");
      setFilters(updated);
    }
    if (itemExists) {
      const updated = searchFilters.filter(item => item.filterValue !== name);

      const containingAggregate = selectedAggregates.find(item =>
        item.filters.some(
          aggFilter =>
            aggFilter.filterType === "selectable" &&
            aggFilter.filterValue.toLocaleLowerCase() === name.toLowerCase()
        )
      );

      if (containingAggregate) {
        const filteredAggregates = selectedAggregates.filter(
          item => item.aggregateName !== containingAggregate.aggregateName
        );

        setAggregates(filteredAggregates);

        const withAggregateFilters = updated.map(item => ({
          ...item,
          aggregate: false
        }));
        setFilters(withAggregateFilters);
        return;
      }

      setFilters(updated);
    } else {
      setSelectedTrackList(undefined);
      setFilters(prev => [
        ...prev,
        {
          filterType: "selectable",
          filterName: type,
          filterValue: name
        }
      ]);
    }
  };

  /*
  name - addDateRangeFilter
  return type - void
  description - this function handles adding a date range filter.

  params            type          description

  item              Object        With the following properties:
  item.name         string        name of the filter
  item.value        string        value of filter to apply to elastic search (ex. created_at)
  item.dateMin      Date          earliest date to search
  item.dateMax      Date          latest date to search
  */
  const addDateRangeFilter = item => {
    setHoveredFilter(undefined);
    const itemExists = searchFilters.some(
      filter => filter.filterName === item.name
    );

    if (itemExists) {
      let removeFilter = searchFilters.filter(
        filter => filter.filterName !== item.name
      );

      setFilters(removeFilter);
    } else {
      setFilters(prev => [
        ...prev,
        {
          filterName: item.name,
          filterValue: item.value,
          filterType: "dateRange",
          dateMin: item.min.toString(),
          dateMax: item.max.toString()
        }
      ]);
    }
  };

  const unFavoriteTrack = trackId => {
    const newAllTracks = allTracks.map(item => {
      if (item.id === trackId) {
        item.favorite = false;

        return item;
      } else {
        return item;
      }
    });

    setAllTracks(newAllTracks);
  };

  /*
  name - removeFilter
  return type - void
  description - handles removing a filter from applied filters.
  There will be a case where the user can remove individual filter from an aggregate.
  When that happens, that aggregate is removed and 
  any remaining filters are applied as individual filters.

  params            type          description

  value              string        name of the filter
  type              string        type of filter
  aggregate           {}          aggregate object that the filter is being removed from

  */
  const removeFilter = (value, type, aggregate) => {
    if (textSearching) setTextSearching(false);
    setSearchValue("");

    // set sidebar
    if (["duration", "length"].includes(value.toLowerCase())) {
      handleChars({ filterName: value }, [duration.min, duration.max]);
    } else if (value.toLowerCase() === "bpm") {
      handleChars({ filterName: value }, [bpm.min, bpm.max]);
    } else {
      handleChars({ filterName: value }, [1, 5]);
    }

    if (aggregate) {
      const filteredAggregates = selectedAggregates.filter(
        item => item.aggregateName !== aggregate.aggregateName
      );
      let filtersLeft = [];

      if (type === "selectable") {
        filtersLeft = aggregate.filters
          .filter(item => {
            if (item.filterType === "range") return true;
            return item.filterValue !== value;
          })
          .map(item => ({
            ...item,
            aggregate: false
          }));
      } else {
        filtersLeft = aggregate.filters
          .filter(item => item.filterName.toLowerCase() !== value.toLowerCase())
          .map(item => ({
            ...item,
            aggregate: false
          }));
      }

      let hash = {};
      filtersLeft.forEach(item => {
        if (item.filterType === "range")
          hash[item.filterName.toLowerCase()] = true;
        else {
          hash[item.filterValue.toLowerCase()] = true;
        }
      });

      setAggregates(filteredAggregates);

      const rangeFiltersLeft = filtersLeft.filter(
        item => item.filterType === "range"
      );
      const selectableFiltersLeft = filtersLeft.filter(
        item => item.filterType === "selectable"
      );

      let updated = [];
      let rangeFilterLeftHash = {};
      rangeFiltersLeft.forEach(item => {
        rangeFilterLeftHash[item.filterName.toLowerCase()] = true;
      });

      updated = searchFilters.filter(item => {
        if (item.filterType === "selectable") return true;
        return !rangeFilterLeftHash[item.filterName.toLowerCase()];
      });

      let selectableFiltersLeftHash = {};
      selectableFiltersLeft.forEach(item => {
        selectableFiltersLeftHash[item.filterValue.toLowerCase()] = true;
      });

      updated = updated.filter(item => {
        if (item.filterType === "range") return true;
        return !selectableFiltersLeftHash[item.filterValue.toLowerCase()];
      });

      if (type === "selectable") {
        updated = updated.filter(item => {
          if (item.filterType === "range") return true;
          return value.toLowerCase() !== item.filterValue.toLowerCase();
        });

        return setFilters([...filtersLeft, ...updated]);
      }

      updated = updated.filter(item => {
        if (item.filterType === "selectable") return true;
        return value.toLowerCase() !== item.filterName.toLowerCase();
      });

      return setFilters([...filtersLeft, ...updated]);
    }

    if (type === "selectable") {
      const updated = searchFilters
        .filter(item => item.filterValue !== value)
        .map(item => ({
          ...item
        }));
      return setFilters(updated);
    }
    const updated = searchFilters
      .filter(item => item.filterName !== value)
      .map(item => ({
        ...item
      }));

    setFilters([...updated]);
  };

  /*
  name - removeAggregate
  return type - void
  description - handles removing an aggregate from applied filters.

  params            type          description

  aggregate           {}          aggregate object that is being removed 

  */
  const removeAggregate = aggregate => {
    const filteredAggregates = selectedAggregates.filter(
      item => item.aggregateName !== aggregate.aggregateName
    );
    let hash = {};
    aggregate.filters.forEach(item => {
      hash[item.filterName.toLowerCase()] = true;
    });
    const filteredSearchFilters = searchFilters.filter(
      item => !hash[item.filterName.toLowerCase()]
    );

    const sidebarFilters = generateDefaultRanges(aggregate.filters);

    //  aggregate.filters.map(item => {
    //   if (["length", "duration"].includes(item.filterName.toLowerCase())) {
    //     return {
    //       ...item,
    //       min: duration.min,
    //       max: duration.max
    //     };
    //   } else if (item.filterName.toLowerCase() === "bpm")
    //     return { ...item, min: bpm.min, max: bpm.max };

    //   return {
    //     ...item,
    //     min: 0,
    //     max: 9
    //   };
    // });

    setFilters(filteredSearchFilters);
    setSidebarRanges(sidebarFilters);
    setAggregates(filteredAggregates);
  };

  const fetchSearchResult = (query, type, item) => {
    console.log("FETCH SEARCH RESULT", query, type, item);
    if (!item) {
      let isAutoCompleteValue = allSearchResults.filter(x => {
        return x.name === query;
      });

      if (isAutoCompleteValue.length === 1) {
        setTextSearching(false);

        addSelectable(
          query,
          isAutoCompleteValue[0].type,
          true,
          isAutoCompleteValue[0]
        );
      } else {
        setLoadingTracks(false);
        setTextSearching(true);
        // Reset ranges
        const sidebarFilters = generateDefaultRanges(searchFilters);
        setSidebarRanges(sidebarFilters);

        setFilters([
          {
            filterType: "selectable",
            filterName: "selectable",
            filterValue: query,
            hidden: type === "track" || type === "session"
          }
        ]);
      }
    } else {
      setTextSearching(false);

      addSelectable(query, type, true, item);
    }
  };

  /*
  name - getSuggestions
  return type - void
  description - handles text search. It searches from filters (tags,instruments, aggregates...)
   and the database for tracks, albums etc...

  params            type          description
  input             string         search term
  */
  const getSuggestions = async input => {
    if (input.trim().length === 0) return;
    client.queryManager.stopQuery();
    client.stop();
    setLoadingAutocomplete(true);

    try {
      setLoadingAutocomplete(true);
      const response = await client.query({
        query: GET_AUTOCOMPLETE,
        variables: { q: input },
        fetchPolicy: "no-cache"
      });
      const { data } = response;
      const trackData = data.autocomplete
        .map(item => {
          if (!item.variation) {
            return {
              ...item,
              type: item.stem ? "track" : "session",
              name: item.name
            };
          } else return undefined;
        })
        .filter(item => Boolean(item));

      setAutoCompleteSuggestions([...trackData]);
      await searchFromFilters(input);
      setLoadingAutocomplete(false);
    } catch (err) {
      console.error("error:", err);
    }
  };

  /*
    description: searches from the array of tags, instruments and quick-filters
    stored on frontend.
    arguments:
      query: the search query
  */
  const searchFromFilters = query => {
    if (query.trim().length < 2) return;
    const tagResults = allTags
      .filter(tag => {
        if (typeof tag === "object")
          return tag.name.toLowerCase().includes(query.toLowerCase());
        return tag.toLowerCase().includes(query.toLowerCase());
      })
      .map(item => {
        if (typeof item === "object") return { ...item };
        return { name: item, type: "tags" };
      });

    const instrumentResults = allInstruments
      .filter(instrument =>
        instrument.toLowerCase().includes(query.toLowerCase())
      )
      .map(item => ({ name: item, type: "instruments" }));

    const resultAggregate = aggregates
      .filter(aggregate =>
        aggregate["aggregateName"].toLowerCase().includes(query.toLowerCase())
      )
      .map(item => ({
        name: item["aggregateName"],
        type: "aggregate",
        ...item
      }));

    setAggregateResults(resultAggregate);
    setTagSearchResults(tagResults);
    setInstrumentSearchResult(instrumentResults);
    setLoadingAutocomplete(false);
  };

  const queryAlbumDetail = async item => {
    try {
      setLoadingTracks(true);
      const { data } = await client.query({
        query: GET_ALBUM,
        variables: { id: item.id }
      });

      setLoadingTracks(false);
      setAllTracks([...data.album.tracks]);
    } catch (err) {
      console.error("error:", err);
    }
  };

  const hasAutoCompleteResults = () => {
    return (
      autoCompleteSuggestions.length > 0 ||
      aggregateResults.length > 0 ||
      instrumentSearchResults.length > 0 ||
      tagSearchResults.length > 0
    );
  };

  const clearSearchFilters = () => {
    if (searchFilters.length === 0) return;
    setFilters([]);
    setAggregates([]);
    setSidebarRanges(
      [
        ...characteristics,
        ...advancedCharacteristics,
        ...advancedCharacteristics2
      ],
      true
    );
  };

  const onlySelectableFilters = () => {
    const filteredFilters = searchFilters.filter(
      item => item.filterType === "range"
    );
    if (filteredFilters.length === 0) return true;
    return false;
  };

  /*
  name - setSidebarRanges
  return type - void
  description - given array of range filters (characteristics), it sets the sidebar
  to be in sync with their values.

  params            type          description

  rangeFilters      [filter]       array of filters
  useOriginal        boolean       whether to convert values or use give value 
  */
  const setSidebarRanges = (rangeFilters, useOriginal) => {
    if (!rangeFilters) return; // return is rangeFilters is null

    const filtered = rangeFilters.filter(
      // extract range filters out from rangeFilters
      item => item.filterType === "range"
    );

    const convertedToFrontend = filtered.map(item => ({
      ...item,
      min:
        isItemLengthOrBpm(item) || useOriginal
          ? item.min
          : convertToFrontend(item.min),
      max:
        isItemLengthOrBpm(item) || useOriginal
          ? item.max
          : convertToFrontend(item.max)
    }));

    const chars = allCharacteristics;

    convertedToFrontend.forEach(item => {
      allCharacteristics.forEach((filter, index) => {
        if (item.filterName.toLowerCase() === filter.filterName.toLowerCase()) {
          chars[index] = item;
        }
      });
    });

    setChars(chars);
  };

  const setDownloadTrack = async track => {
    let downloadTrack = track;
    if (track.variation) {
      const all = [
        ...allTracks,
        ...suggestedTracks,
        ...exactTracks,
        singleTrack
      ];
      const parentTrack = all.find(item => item.id === track.parentTrackId);
      downloadTrack = parentTrack;

      if (!downloadTrack) {
        const fetchedTrack = await fetchTrack(track.parentTrackId);
        downloadTrack = fetchedTrack;
      }
      setTrackCache(prev => ({ ...prev, [downloadTrack.id]: downloadTrack }));
    }
    setDownloadTrackState(downloadTrack);
  };

  const fetchTrack = async id => {
    setFetchingDownloadTrack(true);
    if (tracksCache[id]) {
      setFetchingDownloadTrack(false);
      return tracksCache[id];
    }
    try {
      const { data } = await client.query({
        query: GET_TRACK,
        variables: { id: id },
        fetchPolicy: "no-cache"
      });
      setTrackCache(prev => ({ ...prev, [data.track.id]: data.track }));
      setFetchingDownloadTrack(false);
      return data.track;
    } catch (err) {
      console.error("error:", err);
    }
  };

  return (
    <SearchContext.Provider
      value={{
        allCharacteristics,
        allInstruments,
        setLoadingAutocomplete,
        allKeys,
        allTags,
        searchFilters: frontendSearchFilters,
        setFilters,
        selectedAggregate,
        onCharChange,
        addDateRangeFilter,
        handleHoveredRange,
        handleHoveredSelectable,
        clearHoveredFilter,
        hoveredFilter,
        addSelectable,
        removeFilter,
        suggestions: autoCompleteSuggestions,
        setSuggestions: setAutoCompleteSuggestions,
        exactTracks,
        suggestedTracks: filteredSuggestedTracks,
        fetchSearchResult,
        loadingTracks,
        searchValue,
        setSearchValue,
        allTracks,
        isSearching,
        setIsSearching,
        fetchTracks,
        getSuggestions,
        tagSearchResults,
        setTagSearchResults,
        instrumentSearchResults,
        setInstrumentSearchResult,
        setAllTracks,
        queryAlbumDetail,
        trackToBeDownloaded,
        setDownloadTrack,
        aggregateResults,
        setSelectedTrackList,
        selectedTrackList,
        loadingSuggestions,
        hasAutoCompleteResults,
        isLoadingAutocomplete,
        clearSearchFilters,
        unFavoriteTrack,
        fetchMoreTracks,
        fetchMoreExactTracks,
        loadingMore,
        loadingMoreExact,
        seed,
        onlySelectableFilters,
        fetchMoreSelectableSuggest,
        selectedAggregates,
        removeAggregate,
        setLoadingMore,
        setLoadingMoreExact,
        textSearching,
        hasMoreExactTracks: exactTracksPaginator,
        setSingleTrack,
        fetchingDownloadTrack
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default withRouter(SearchProvider);

export const useSearch = () => useContext(SearchContext);
