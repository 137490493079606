import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const ReactGAContext = React.createContext();

export const ReactGAProvider = ({ children }) => {
  let location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <ReactGAContext.Provider
      value={{
        location
      }}
    >
      {children}
    </ReactGAContext.Provider>
  );
};
