import styled, { keyframes } from "styled-components";

const messageAnimation = keyframes`
  0%{
    transform:translateX(300px)
  }
  100%{
    transform:translateX(0px)
  }
`;

export const Container = styled.div`
  max-width: 400px;
  min-width: 300px;
  position: fixed;
  padding: 24px 24px;
  bottom: ${props => (props.tawkWidgetVisible ? "86px" : "30px")};
  right: 26px;
  background: ${props => props.theme.backgroundDark};
  z-index: 100000;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  animation: ${messageAnimation} 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

  #quicktip-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    color: ${({ theme }) => theme.editor.textLight};
  }

  #quicktip-header-icon > img {
    width: 30px;
    height: 30px;
  }

  #quicktip-header-title {
    font-size: 16px;
  }

  .key {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px;
    background-color: #d9d9d9;
    border-radius: 14px;
    color: ${({ theme }) => theme.editor.backgroundDark};
    font-size: 19px;
    font-family: ${({ theme }) => theme.fontHeavy};
    font-weight: 700;
    width: 40px;
    height: 40px;

    img {
      width: 20px;
    }
  }

  .key:before {
    content: "";
    border: 2px solid ${({ theme }) => theme.editor.backgroundLight};
    border-radius: 10px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    position: absolute;
    top: 3px;
    left: 3px;
  }

  .close {
    position: absolute;
    top: 14px;
    cursor: pointer;
    width: 22px;
    height: 14px;
    right: 14px;
  }

  #quicktip-footer {
    display: flex;
    justify-content: flex-end;

    .hide-prompt {
      font-size: 12px;
      cursor: pointer;
    }

    .hide-prompt:hover {
      color: ${({ theme }) => theme.editor.highlight};
    }
  }
`;

export const TipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  font-size: 14px;
  color: ${({ theme }) => theme.editor.textLight};

  .quicktip-btnify {
    font-family: ${({ theme }) => theme.fontHeavy};
    background-color: ${({ theme }) => theme.editor.highlight};
    padding: 1px 11px;
    border-radius: 12px;
    color: #fff;
  }

  .thicc-spacing {
    line-height: 22px;
  }

  .mock-btn-backdrop {
    background-color: ${({ theme }) => theme.editor.backgroundLight};
    padding: 7px;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }

  .quicktip-text-heavy {
    font-family: ${({ theme }) => theme.fontHeavy};
  }

  .text-muted {
    color: ${({ theme }) => theme.editor.textMedium};
  }
`;

export const SendFXQuickTip = styled(TipContent)`
  .sendfx-flex {
    display: flex;
    gap: 12px;
    align-items: center;

    > svg {
      width: 30px;
      height: 30px;
    }

    .sendfx-info {
      font-family: ${({ theme }) => theme.fontHeavy};
      background-color: ${({ theme }) => theme.editor.highlight};
      padding: 1px 12px;
      border-radius: 12px;
      color: #fff;
    }
  }
`;

export const ProjectSaveQuickTip = styled(TipContent)``;

export const SnapQuickTip = styled(TipContent)`
  #quicktip-snap-container {
    font-size: 16px;
    font-family: ${({ theme }) => theme.fontHeavy};
    color: $fff;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const CrossfadeQuickTip = styled(TipContent)`
  #crossfade-info-container {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  #crossfades-container {
    display: flex;
    align-items: center;
    gap: 2px;

    #quicktip-crossfade-arrow {
      font-size: 45px;
      color: white;
      margin-top: -17px;
      margin-left: 12px;
    }
  }

  .triangle {
    position: relative;
    background-color: orange;
    text-align: left;
  }
  .triangle:before,
  .triangle:after {
    content: "";
    position: absolute;
    background-color: inherit;
  }
  .triangle,
  .triangle:before,
  .triangle:after {
    width: 13px;
    height: 13px;
    border-top-right-radius: 50%;
  }

  .triangle {
    transform: rotate(29deg) skewX(-30deg) scale(1, 0.866);
  }
  .triangle:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translate(0, -50%);
  }
  .triangle:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }

  .quicktip-region {
    width: 126px;
    height: 24px;
    border-radius: 8px;
  }

  #quicktip-region-1 {
    position: relative;
    background-color: #a99962;

    .triangle {
      position: absolute;
      left: 121px;
      top: 5px;
      background-color: #a99962;
    }
  }

  #quicktip-region-2 {
    background-color: ${({ theme }) => theme.editor.highlight};
    transform: scaleX(-1);

    .triangle {
      position: absolute;
      left: 123px;
      top: 5px;
      background-color: ${({ theme }) => theme.editor.highlight};
    }
  }
`;

export const VersionsQuickTip = styled(TipContent)``;

export const UploadAudioQuickTip = styled(TipContent)`
  #quicktip-regions-container {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
  }

  .quicktip-region {
    height: 24px;
    border-radius: 8px;
    background-color: #a99962;
  }

  #quicktip-region-1 {
    width: 80px;
  }

  #quicktip-region-2 {
    width: 60px;
  }

  #quicktip-region-3 {
    width: 40px;
  }

  #quicktip-upload-region {
    width: 100%;
  }

  .quicktip-region-shell {
    background-color: transparent !important;
    border: 2px solid ${({ theme }) => theme.editor.textLight};
  }
`;

export const NavigationQuickTip = styled(TipContent)`
  #quicktip-keyboard-shortcuts {
    display: flex;
    gap: 12px;
  }

  #keyboard-shortcuts-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: space-evenly;
    flex-wrap: nowrap;
  }

  #keyboard-shortcut-keys {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
    justify-content: space-between;
    width: 100px;

    .space {
      width: 100%;
      height: 40px;
      padding: 10px 6px;
      color: ${({ theme }) => theme.editor.backgroundDark};
      font-size: 17px;
      font-family: ${({ theme }) => theme.fontHeavy};
      font-weight: 700;
    }
  }

  #keyboard-shortcut-descriptions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: flex-start;

    #shortcut-1-info {
      margin-top: 11px;
    }

    #shortcut-2-info {
      margin-top: 18px;
    }
  }
`;

export const KeyboardShortcutsQuickTip = styled(TipContent)`
  #quicktip-keyboard-shortcuts {
    display: flex;
    gap: 12px;
  }

  #keyboard-shortcuts-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: space-evenly;
    flex-wrap: nowrap;
  }

  #keyboard-shortcut-keys {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
    justify-content: center;
    width: 100px;

    .space {
      width: 100%;
      height: 40px;
      padding: 10px 6px;
    }
  }

  #keyboard-shortcut-descriptions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: flex-start;

    .shortcut-description {
      display: flex;
      gap: 10px;

      .key-name {
        width: 10px;
        font-family: ${({ theme }) => theme.fontHeavy};
      }
    }
  }
`;
