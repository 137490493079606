import { MixingBoardContextType } from "../../context/EditorContext/MixingBoardProvider";
import { InternalFX } from "../../store";
import { EncapsulatedAudioNode } from "./EncapsulatedAudioNode";

/*
 * An abstract class that represents an internal audio effect node within an effect slot. E.g.,
 * Overdrive effect under the hood is actually a daisy chain of: tuna.Overdrive > tuna.Filter >
 * tuna.Gain > send.Reverb_Hall Each of these fx under the hood is an instance of InternalFXNode.
 * This class is also an instance of GainNode and can be connected and (dis)connected using the
 * standard AudioNode interface.
 *
 * InternalFXNode
 * |____ TunaEffectNode
 * |____ BlueDotEffectNode
 * |____ PassthroughSendNode
 */
export abstract class InternalFXNode extends EncapsulatedAudioNode {
  protected fxConfig: InternalFX;
  protected _mixingBoard: MixingBoardContextType;

  /*
   * Parameters:
   *  mixingBoard: MixingBoardContext (has access to AudioMaster)
   *  fxConfig: json serialization of effect params
   */
  constructor(mixingBoard: MixingBoardContextType, fxConfig: InternalFX) {
    super(mixingBoard.audioMaster.context);
    this._mixingBoard = mixingBoard;
    this.fxConfig = fxConfig;
  }

  public setBPM(bpm: number) {}
  public abstract setValue(val: number);

  public cleanup() {
    return this;
  }
}
