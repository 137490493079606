import React, { useContext, useEffect, useState } from "react";
import AntModal from "../AntModal";
import { Container, FlexContainer } from "./styles";
import { AuthContext, CartContext, SearchContext } from "../../context";
import { useDownload } from "../../utils/downloadTrack";
import ProjectSelector from "../ProjectSelector";
import BlanketLicense from "../SubscriptionFunnels/BlanketLicense";
import CreativeCommonsLicense from "../CreativeCommonsLicense";
import ProjectLicense from "../ProjectLicense/ProjectLicense";
import TrackDetails from "../TrackDetails";
import Spinner from "../SpinnerSmall";
import DownloadAttributeModal from "../TrackDetails/DownloadAttributeModal/DownloadAttributeModal";
import useModal from "../../hooks/useModal";
import {
  CREATE_DOWNLOAD_MUTATION_WITH_TEAM,
  CREATE_DOWNLOAD_MUTATION_WITHOUT_TEAM
} from "../TrackCheckoutDetail/queries";
import { useApolloClient } from "react-apollo";

const DownloadModal = ({ modalState }) => {
  const search = useContext(SearchContext);
  const auth = useContext(AuthContext);
  const cart = useContext(CartContext);
  const client = useApolloClient();

  const track = search.trackToBeDownloaded;
  const [projectDownloadAccess, setProjectDownloadAccess] = useState(false);
  const [hasDownloadAccess, setDownloadAccess] = useState(false);

  const { selectedProject } = useContext(CartContext);
  const [loading, setLoading] = useState(true);

  const downloadAttributeModal = useModal();

  const { element } = useDownload(
    `${process.env.REACT_APP_FILES_URL}/${track?.mp3File}`,
    track?.title
  );

  // Download/Attribution
  const [createdDownload, setCreatedDownload] = useState({});

  const createDownload = async description => {
    let data = {
      user: auth.user.id,
      project: selectedProject?.id,
      track: track.id,
      details: description
    };

    if (auth.user.team) data = { ...data, team: auth.user.team.id };

    try {
      return await client.mutate({
        mutation: auth.user.team
          ? CREATE_DOWNLOAD_MUTATION_WITH_TEAM
          : CREATE_DOWNLOAD_MUTATION_WITHOUT_TEAM,
        variables: data
      });
    } catch (err) {
      console.error("create download mutation ", err);
    }
  };

  const creditingForLicensedUser = async () => {
    let response = await createDownload(track.title);
    setCreatedDownload(response.data.createDownload);
    downloadAttributeModal.open();
  };

  useEffect(() => {
    setDownloadAccess(auth.user.fullDownloadAccess || projectDownloadAccess);
  }, [auth.user.fullDownloadAccess, projectDownloadAccess]);

  useEffect(() => {
    if (!auth.isUserPrivate()) setLoading(false);
  }, [auth]);

  return (
    <AntModal modalState={modalState}>
      <Container>
        {loading && (
          <div className="loading-modal">
            <Spinner />
          </div>
        )}
        <div className="left-column project-select">
          {auth.isUserPrivate() && (
            <ProjectSelector
              setDownloadAccess={setProjectDownloadAccess}
              track={track}
              hasDownloadAccess={projectDownloadAccess}
              setLoading={setLoading}
              containingModalState={modalState}
            />
          )}
        </div>
        {(selectedProject || !auth.isUserPrivate()) && (
          <FlexContainer>
            {!hasDownloadAccess && !loading && (
              <div className="left-column">
                <CreativeCommonsLicense />
                <BlanketLicense />
                <ProjectLicense
                  onBtnClick={() => {
                    if (!cart.isFoundInCart(track)) cart.addCartItem(track);
                    modalState.close();
                  }}
                />
              </div>
            )}
            <div className="right-column">
              {search.fetchingDownloadTrack && <h3>Loading files....</h3>}
              {track.id && !loading && (
                <TrackDetails
                  track={track}
                  downloadable={hasDownloadAccess}
                  createDownload={createDownload}
                  setCreatedDownload={setCreatedDownload}
                  downloadAttributeModal={downloadAttributeModal}
                />
              )}
            </div>
            {hasDownloadAccess && !loading && (
              <div className="not-required-credit">
                <p>
                  While we don't require credit in licensed work, we always
                  appreciate creators giving credit in their websites and media
                  descriptions.
                </p>
                <p
                  onClick={() => creditingForLicensedUser()}
                  className="credit-option-link"
                >
                  Click here to give credit
                </p>
              </div>
            )}
          </FlexContainer>
        )}

        <DownloadAttributeModal
          modalState={downloadAttributeModal}
          onClose={() => {
            // this closes parent modal (DownloadModal) when attribution modal closes
            modalState.close();
          }}
          title={track.title}
          attributeId={createdDownload.attribute_id}
          id={createdDownload.id}
          downloadable={hasDownloadAccess}
        />
      </Container>
      {element}
    </AntModal>
  );
};

export default DownloadModal;
