import styled from "styled-components";
import { baseHeaderHeight } from "../../components/Header/styles";

const mainContentPadding = 8;

export const ContentPadding = styled.div`
  padding-top: ${baseHeaderHeight + mainContentPadding}px;
`;

export const BottomContentPadding = styled.div`
  padding-bottom: 120px;
`;
