import * as React from "react";
import { theme } from "../globalStyles";
import { Tooltip } from "antd";

export const SendFX = () => (
  <Tooltip
    title="Send effects are added after all others in the FX chain."
    placement="right"
  >
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 11"
      className="send-fx-icon"
    >
      <path
        d="M11.5,0.6c-0.3-0.3-0.6-0.3-0.9,0c-0.3,0.3-0.3,0.7,0,1c2,2.2,2,5.7,0,7.9c-0.3,0.3-0.3,0.7,0,1
	c0.2,0.3,0.6,0.3,0.9,0C14,7.7,14,3.3,11.5,0.6z"
        fill={theme.editor.iconLight}
      />
      <path
        d="M3.4,1.5c0.3-0.3,0.3-0.7,0-1c-0.3-0.3-0.6-0.3-0.9,0C0,3.3,0,7.7,2.5,10.4c0.3,0.3,0.7,0.3,0.9,0
	c0.3-0.3,0.3-0.7,0-1C1.4,7.2,1.4,3.7,3.4,1.5z"
        fill={theme.editor.iconLight}
      />
      <path
        d="M9.7,2.6C9.4,2.3,9,2.3,8.8,2.6c-0.3,0.3-0.3,0.7,0,1c1,1.1,1,2.9,0,3.9c-0.3,0.3-0.3,0.7,0,1
	C9,8.7,9.5,8.7,9.7,8.5C11.1,6.8,11.1,4.2,9.7,2.6z"
        fill={theme.editor.iconLight}
      />
      <path
        d="M5.2,3.4c0.3-0.3,0.3-0.7,0-1C5,2.2,4.6,2.2,4.3,2.5c-1.5,1.7-1.5,4.3,0,5.9C4.5,8.7,5,8.7,5.2,8.3
	c0.3-0.3,0.3-0.7,0-1C4.2,6.3,4.2,4.5,5.2,3.4z"
        fill={theme.editor.iconLight}
      />
      <path
        d="M7,6.7c0.7,0,1.3-0.6,1.3-1.3S7.7,4.2,7,4.2c-0.7,0-1.3,0.6-1.3,1.3S6.3,6.7,7,6.7z"
        fill={theme.editor.iconLight}
      />
    </svg>
  </Tooltip>
);
