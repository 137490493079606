import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  > div {
    margin-right: ${({ theme }) => theme.paddingContainersM};
  }

  .close {
    width: 16px;
  }

  .close-button {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 10;
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}) {
    min-width: 700px;
  }

  .left-column,
  .right-column {
    max-width: 360px;
  }

  .project-select {
    max-width: 508px;
  }

  .left-column {
    > *:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.paddingContainersS};
    }
  }

  .not-required-credit {
    width: 250px;
    align-self: flex-end;
    justify-content: flex-end;

    p {
      color: ${({ theme }) => theme.textGrey};
    }

    .credit-option-link {
      color: ${({ theme }) => theme.textLight};
      text-decoration: underline;
      text-align: left;
      margin-top: 14px;
      cursor: pointer;
    }
  }

  .loading-modal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FlexContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;

  > * {
    margin-right: ${({ theme }) => theme.paddingContainersM};
  }
`;
