import React from "react";
import Header from "../../components/Header";
import { ContentPadding, BottomContentPadding } from "./styles";
import PlayerContainer from "../../components/Player/PlayerContainer";
import EditorProvider from "../../editor/context/EditorContext/EditorProvider";
import EditorDrawer from "../../editor/components/EditorDrawer";
import { QuickTip } from "../../editor/components/QuickTip";

/*
 * Main Site layout for authenticated users, complete with header and Player
 */
const MainLayout = ({ children, showPlayer = true }) => {
  return (
    <>
      <EditorProvider>
        <Header />
        <ContentPadding />
        {children}
        <BottomContentPadding />
        {showPlayer && <PlayerContainer />}
        <QuickTip />
        <EditorDrawer />
      </EditorProvider>
    </>
  );
};

export default MainLayout;
