import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  flex: auto;

  .project-whatisit > p {
    color: ${({ theme }) => theme.textGrey};
    width: 140%;
  }

  .project-image,
  .select-project {
    margin: 0 20px 10px 0;
  }

  .project-image,
  .project-image-placeholder {
    border-radius: ${({ theme }) => theme.borderRadiusContainers};
    width: 82px;
    height: 82px;
    object-fit: cover;
    background-color: ${({ theme }) => theme.backgroundLight};
    flex: none;
  }

  .select-project {
    display: flex;
    flex-flow: column wrap;
    flex: auto;

    .select-header,
    .project-info,
    .project-select {
      margin-bottom: 10px;
      width: 100%;
      min-width: 100px;
    }

    .grey {
      color: ${({ theme }) => theme.textDark};
    }

    .underlined {
      text-decoration: underline;
    }

    .pointer {
      cursor: pointer;
    }

    .bold {
      font-family: AvenirLTPro-Heavy;
    }

    .project-info > p {
      color: ${({ theme }) => theme.textGrey};
    }

    .btn-text {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .ant-radio-group .ant-radio-wrapper {
    color: ${props => props.theme.textLight};
  }
  .ant-radio-group .ant-radio-wrapper-checked {
    color: ${props => props.theme.textLight};
  }

  .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
    background: ${props => props.theme.backgroundLight};
    border-color: ${props => props.theme.grey2};
  }

  .ant-radio-group .ant-radio-wrapper-checked .ant-radio-inner {
    background: ${props => props.theme.highlight};
    border-color: ${props => props.theme.highlight};
  }

  .ant-radio-group .ant-radio-checked .ant-radio-inner::after {
    background: ${props => props.theme.textLight};
  }

  .ant-radio-group .ant-radio-disabled + span {
    color: ${props => props.theme.textLight};
  }
`;
