import { Dropdown } from "antd";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  INITIALIZE_OFFLINE_RENDER,
  InitializeOfflineRenderAction
} from "../../actions/actionTypes";
import { CustomMixInfo, MainStore } from "../../store";
import { IconButton } from "../Buttons/IconButton";
import { LoadModal } from "../LoadModal";
import { ResetModal } from "../ResetModal/ResetModal";
import { SaveModal } from "../SaveModal/SaveModal";
import { TrackInfoModal } from "../TrackInfoModal";
import { Container, MainMenu, MainMenuItem } from "./styles";
import { Hamburger } from "../../../assets/Hamburger";
import useModal from "../../../hooks/useModal";
import { AuthContext } from "../../../context/AuthProvider";
import { saveCustomMixAction } from "../../actions/actionCreators";
import { EditorContext } from "../../context/EditorContext/EditorProvider";
import { VersionControl } from "../VersionControl";

export function MainMenuControls() {
  const dispatch = useDispatch();

  const versionDrawer = useModal();

  const isRendering = useSelector<MainStore, boolean>(
    state => state.editorState.isRendering
  );
  const customMixInfo = useSelector<MainStore, CustomMixInfo>(
    state => state.project.customMixInfo
  );

  const user = useContext(AuthContext);
  const editor = useContext(EditorContext);

  const saveModal = useModal();
  const loadModal = useModal();
  const resetModal = useModal();
  const trackInfoModal = useModal();

  const customMixSaved = useCallback(
    customMixJson => {
      if (
        customMixInfo.id !== customMixJson.id ||
        customMixInfo.version.versionNumber !==
          customMixJson.version.versionNumber
      ) {
        editor.requestCustomMixIdOpen(
          customMixJson.id,
          customMixJson.version.versionNumber
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customMixInfo]
  );

  const quickSave = useCallback(() => {
    if (!user.isUserPrivate()) return;
    if (customMixInfo.id === null || customMixInfo.projectId === null) {
      // this mix hasn't been saved before, spawn the Save As modal for first time save workflow
      saveModal.open();
    } else {
      // we have enough information to quicksave a new version
      dispatch(
        saveCustomMixAction(
          customMixInfo.id,
          customMixInfo.projectId,
          user.user.id,
          customMixSaved
        )
      );
    }
  }, [customMixInfo, saveModal, user, customMixSaved, dispatch]);

  const onMainMenuClick = ({ key }) => {
    switch (key) {
      case "download":
        dispatch<InitializeOfflineRenderAction>({
          type: INITIALIZE_OFFLINE_RENDER
        });
        break;
      case "save":
        if (user.isUserPrivate()) quickSave();
        break;
      case "saveAs":
        if (user.isUserPrivate()) saveModal.open();
        break;
      case "load":
        if (user.isUserPrivate()) loadModal.open();
        break;
      case "reset":
        resetModal.open();
        break;
      case "versions":
        versionDrawer.open();
        break;
      case "info":
        trackInfoModal.open();
        break;
    }
  };

  const handleKeyDown = useCallback(
    e => {
      if (["text", "number", "textarea"].includes(e.target.type)) return;
      if (!user.isUserPrivate()) return;

      if ((e.metaKey || e.ctrlKey) && e.shiftKey && e.keyCode === 83) {
        // ctrl + shift + s
        saveModal.open();
      } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 83) {
        // ctrl + s
        quickSave();
      }
    },
    [user, quickSave, saveModal]
  );

  // create a mutable ref to handler that gets refreshed with up-to-date redux store every rerender
  // this is necessary because of useEffect dependencies & redux
  const handleKeyDownRef = useRef(handleKeyDown);
  useEffect(() => {
    handleKeyDownRef.current = handleKeyDown;
  }, [handleKeyDown]);

  useEffect(() => {
    const keyDownHandler = e => handleKeyDownRef.current(e);
    if (editor.drawerState.visible) {
      document.addEventListener("keydown", keyDownHandler);
    } else {
      document.removeEventListener("keydown", keyDownHandler);
    }
    return () => document.removeEventListener("keydown", keyDownHandler);
  }, [editor.drawerState.visible]);

  const mainMenu = (
    <MainMenu onClick={onMainMenuClick}>
      {!isRendering && <MainMenuItem key="download">Download Mix</MainMenuItem>}
      <MainMenuItem
        key="save"
        className={!user.isUserPrivate() ? "disabled" : ""}
      >
        Save
      </MainMenuItem>
      <MainMenuItem
        key="saveAs"
        className={!user.isUserPrivate() ? "disabled" : ""}
      >
        Save As
      </MainMenuItem>
      <MainMenuItem
        key="load"
        className={!user.isUserPrivate() ? "disabled" : ""}
      >
        Load
      </MainMenuItem>
      {editor.customMix !== null && (
        <MainMenuItem key="versions">Versions</MainMenuItem>
      )}
      <MainMenuItem key="reset">Reset</MainMenuItem>
      <MainMenuItem key="info">Track Info</MainMenuItem>
    </MainMenu>
  );

  return (
    <Container>
      <Dropdown overlay={mainMenu} placement="bottomRight" trigger={["click"]}>
        <IconButton svgWidth="16px" svgHeight="16px">
          <Hamburger />
        </IconButton>
      </Dropdown>
      <SaveModal modalState={saveModal} />
      <LoadModal loadModalState={loadModal} saveModalState={saveModal} />
      <ResetModal modalState={resetModal} />
      <TrackInfoModal modalState={trackInfoModal} />
      {editor.customMix !== null && (
        <VersionControl drawerState={versionDrawer} />
      )}
    </Container>
  );
}
