import React from "react";
import Characteristics from "../Characteristics";
import ClosableTag from "../ClosableTag";
import {
  Container,
  SelectableItem,
  MobileContainer,
  StyledReactSVGMobile
} from "./styles";
import { Switch } from "antd";
import LongSlider from "../LongSlider";
import { newTagFilter } from "../Tag/newTagFilter";
import { useFilter } from "../../context/FilterProvider";

const MobileFilters = () => {
  let {
    allCharacteristics,
    allAdvancedCharacteristics,
    allAdvancedCharacteristics2,
    onPopoverChange,
    handleSwitch,
    popovers,
    context,
    popoverStatus,
    advPopoverStatus,
    advancedSearchOn
  } = useFilter();

  return (
    <Container advancedSearchOn={advancedSearchOn}>
      <MobileContainer>
        <div className="filters">
          {allCharacteristics.map(item => (
            <div
              className="selectable-container selectable-container-mobile"
              key={item.filterName}
            >
              <Characteristics
                mobileView={true}
                isActive={context.searchFilters.some(
                  filter => filter.filterName === item.filterName
                )}
                text={item.filterName}
                rangeValue={[item.min, item.max]}
                isRange={true}
                description={item.description}
                onRangeChange={value => context.onCharChange(item, value)}
              />
            </div>
          ))}
          {allAdvancedCharacteristics.map(item => (
            <div
              className="selectable-container  selectable-container-mobile"
              key={item.filterName}
              style={{
                height: advancedSearchOn ? "46px" : "0px",
                opacity: advancedSearchOn ? 1 : 0,
                zIndex: advancedSearchOn ? 0 : -100,
                transition: "all 0.5s ease-out"
              }}
            >
              <Characteristics
                mobileView={true}
                isActive={context.searchFilters.some(
                  filter => filter.filterName === item.filterName
                )}
                text={item.filterName}
                rangeValue={[item.min, item.max]}
                isRange={true}
                description={item.description}
                onRangeChange={value => context.onCharChange(item, value)}
              />
            </div>
          ))}

          {popovers.map((filter, index) => (
            <React.Fragment key={`${filter.filterName}-${index}`}>
              <SelectableItem
                onClick={() => onPopoverChange(!popoverStatus[index], index)}
                active={filter.values.some(item =>
                  context.searchFilters.some(
                    filter => item === filter.filterValue
                  )
                )}
              >
                <p className="item-name">{filter.name}</p>
                <StyledReactSVGMobile
                  open={popoverStatus[index]}
                  active={filter.values
                    .some(item =>
                      context.searchFilters.some(
                        filter => item === filter.filterValue
                      )
                    )
                    .toString()}
                  src={require("../../assets/arrow-right.svg")}
                  wrapper="span"
                  className="menu-icon"
                />
              </SelectableItem>
              {popoverStatus[index] && (
                <div className="tag-container">
                  {filter.values.map((item, fIndex) => {
                    if (item.type && item.type === "dateRange") {
                      return (
                        <ClosableTag
                          minHeight={24}
                          minWidth={136}
                          containerProps={{
                            style: {
                              marginBottom: 21,
                              width: 136
                            }
                          }}
                          key={`${item.name}-${fIndex}`}
                          closable={false}
                          range={[0, 0]}
                          text={item.name}
                          selected
                          onClick={() => {
                            context.addDateRangeFilter(newTagFilter);
                            if (filter.key === "keys")
                              onPopoverChange(false, index);
                          }}
                        />
                      );
                    }

                    return (
                      <ClosableTag
                        minHeight={24}
                        key={`${item.name}-${fIndex}`}
                        closable={false}
                        range={[0, 0]}
                        text={item}
                        selected={context.searchFilters.some(
                          selectable => selectable.filterValue === item
                        )}
                        onClick={() => {
                          context.addSelectable(item, filter.filterType);
                          if (filter.key === "keys") {
                            setTimeout(() => {
                              onPopoverChange(false, index);
                            }, 500);
                          }
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </React.Fragment>
          ))}

          {allAdvancedCharacteristics2.map((item, index) => (
            <React.Fragment key={index}>
              <SelectableItem
                style={{
                  height: advancedSearchOn ? "46px" : "0px",
                  opacity: advancedSearchOn ? 1 : 0,
                  transition: "all 0.5s ease-out",
                  zIndex: advancedSearchOn ? 1 : -10
                }}
                onClick={() =>
                  onPopoverChange(!advPopoverStatus[index], index, true)
                }
                active={context.searchFilters.some(
                  selectable => selectable.filterName === item.filterName
                )}
              >
                <p className="item-name">{item.filterName}</p>

                <StyledReactSVGMobile
                  open={advPopoverStatus[index]}
                  active={context.searchFilters
                    .some(
                      selectable => selectable.filterName === item.filterName
                    )
                    .toString()}
                  src={require("../../assets/arrow-right.svg")}
                  wrapper="span"
                  className="menu-icon"
                />
              </SelectableItem>

              {advPopoverStatus[index] && (
                <div style={{ marginLeft: 8 }}>
                  <LongSlider
                    rangeValue={[item.min, item.max]}
                    length={
                      item.filterName === "Length" ||
                      item.filterName === "Duration"
                    }
                    min={item.minValue}
                    max={item.maxValue}
                    onChange={value => context.onCharChange(item, value)}
                  />
                </div>
              )}
            </React.Fragment>
          ))}

          <div
            className="row"
            onClick={() => {
              handleSwitch(!advancedSearchOn);
            }}
            style={{ marginTop: 18 }}
          >
            <Switch
              defaultChecked={false}
              checked={advancedSearchOn}
              onChange={handleSwitch}
              className="filter-switch"
            />
            <p className="adv-toggle-text" style={{ marginLeft: 8 }}>
              Adv. search
            </p>
          </div>
        </div>
      </MobileContainer>
    </Container>
  );
};

export default MobileFilters;
