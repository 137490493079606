import { getRandomInt } from "./randomize";
import { InstrumentCategories } from "../store";

export const laneColors = [
  ["#EF6C9E", "#B59ED4"],
  ["#DB6D6A", "#F5A3BC"],
  ["#E37E67", "#F2908F"],
  ["#EC9470", "#F29E8C"],
  ["#EBB75E", "#F2AF94"],
  ["#ECE25B", "#F1C480"],
  ["#C7D469", "#F4EB9C"],
  ["#63B895", "#E6EBA7"],
  ["#65A8AF", "#85D1AF"],
  ["#7097DF", "#90C7CA"],
  ["#908AD4", "#A1BEE2"],
  ["#886FA2", "#B0ABE0"],
  ["#7097DF", "#90C7CA"], // repeat for atmospheric
  ["#908AD4", "#A1BEE2"],
  ["#886FA2", "#B0ABE0"],
  ["#97B8F0", "#97EDF0"]
];

export const getRandomColorCombo = () => {
  let number = getRandomInt(0, laneColors.length - 1);
  return laneColors[number];
};

/*
 * Assigns an instrument to a lane based on its instrument category. Category
 * described by the enum InstrumentCategories above. There's essentially 3 color
 * combinations for each instrument category (see laneColors above) + 1 for "Other".
 * Algorithm is to assign 1st lane in instrument category to first color combination,
 * then 2nd lane to next color combination within category and so on, so lane color
 * assignment is deterministic.
 *
 * iLaneInstrumentCategory (number): index of lane in instrument category
 */
export const getInstrumentColorCombo = (
  instrumentCategory: InstrumentCategories,
  iLaneInstrumentCategory: number
) => {
  let iCategory = Object.values(InstrumentCategories).indexOf(
    instrumentCategory
  );

  let iColorCombo = Math.min(
    iCategory * 3 + (iLaneInstrumentCategory % 3),
    laneColors.length - 1
  );

  return laneColors[iColorCombo];
};
