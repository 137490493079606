import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorContext } from "../../context/EditorContext/EditorProvider";
import {
  SEEK,
  SeekAction,
  SET_EDITOR_MODE,
  SetEditorModeAction
} from "../../actions/actionTypes";
import { EditorMode, MainStore } from "../../store";
import { pixels_to_second } from "../../util/time_position_conversion";
import { getZoomProportion } from "../../util/zoom_levels";
import { RegionBlock } from "../RegionBlock";
import { LaneContainer } from "./styles";

export interface LaneProps {
  laneId: string;
  laneIndex: number;
}

export let Lane = ({ laneId, laneIndex }: LaneProps) => {
  const regionIds = useSelector<MainStore, string[]>(
    state => state.project.lanes.byId[laneId].regionIds
  );

  const dispatch = useDispatch();
  const mode = useSelector<MainStore, EditorMode>(
    state => state.editorState.mode
  );
  const zoomLevel = useSelector<MainStore, number>(
    state => state.uiState.zoomLevel
  );

  const editor = useContext(EditorContext);

  function onMouseDown(e) {
    // only trigger if actually clicked self
    if (e.currentTarget !== e.target) {
      return;
    }

    if (mode === EditorMode.SPLIT) {
      dispatch<SetEditorModeAction>({
        type: SET_EDITOR_MODE,
        mode: EditorMode.NORMAL
      });
    } else {
      const boundingClientRect = e.currentTarget.getBoundingClientRect();
      const pixelsRelativeStart = e.clientX - boundingClientRect.x;
      const seconds = pixels_to_second(
        pixelsRelativeStart / getZoomProportion(zoomLevel)
      );
      dispatch<SeekAction>({
        type: SEEK,
        seconds
      });
    }
  }

  return (
    <LaneContainer onMouseDown={onMouseDown} tightLayout={editor.tightLayout}>
      {regionIds.map(id => (
        <RegionBlock
          key={id}
          regionId={id}
          laneIndex={laneIndex}
          laneId={laneId}
        />
      ))}
    </LaneContainer>
  );
};
