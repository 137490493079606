import styled from "styled-components";

export const AddLaneContainer = styled.div`
  font-weight: bold;
`;

export const AddLaneButton = styled.button`
  width: 110px;
  text-align: left;
  padding: 2px 2px 2px 10px;
  border-radius: ${({ theme }) => theme.editor.containerRadius};
  background-color: ${({ theme }) => theme.editor.backgroundDark};
  color: ${({ theme }) => theme.editor.textLight};
  transition: all 0.2s ease-in-out;
  margin: 2px 0 0 10px;
  font-family: ${({ theme }) => theme.fontHeavy};

  #btn-addlane-text {
    padding-left: 5px;
    opacity: 0;
  }

  &&:hover {
    background-color: ${({ theme }) => theme.editor.backgroundLight};
  }

  &&:hover #btn-addlane-text {
    opacity: 1;
  }
`;
