import * as React from "react";
import { theme } from "../globalStyles";

export const Edit = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.50514 2.83661L12.3532 5.6985L5.14442 12.9418L2.29797 10.081L9.50514 2.83661ZM14.5142 2.14616L13.2441 0.869983C13.1274 0.752721 12.9887 0.659671 12.8359 0.596178C12.6831 0.532685 12.5193 0.5 12.3539 0.5C12.1885 0.5 12.0247 0.532685 11.8719 0.596178C11.7192 0.659671 11.5804 0.752721 11.4637 0.869983L10.2472 2.09256L13.095 4.95445L14.5142 3.52843C14.6971 3.34493 14.7998 3.0964 14.7998 2.83729C14.7998 2.57819 14.6971 2.32966 14.5142 2.14616ZM0.807759 14.1037C0.79563 14.1575 0.797396 14.2135 0.81289 14.2664C0.828384 14.3193 0.857096 14.3675 0.896322 14.4062C0.935547 14.445 0.983994 14.4731 1.0371 14.488C1.0902 14.5028 1.14622 14.5039 1.19986 14.4912L4.37333 13.7179L1.52689 10.856L0.807759 14.1037Z"
      fill={theme.editor.textLight}
    />
  </svg>
);