import { Input } from "antd";
import * as React from "react";
import { useRef, useState, useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_LANE,
  DeleteLaneAction,
  RENAME_LANE,
  RenameLaneAction
} from "../../actions/actionTypes";
import { MainStore, Lane } from "../../store";
import { LaneControls } from "../LaneControls";
import { Container, TightContainer } from "./styles";
import { GripVerticalIcon } from "../../../assets/GripVerticalIcon";
import { Checkmark } from "../../../assets/Checkmark";
import { Edit } from "../../../assets/Edit";
import { Close } from "../../../assets/Close";
import { lightInputStyle, theme } from "../../../globalStyles";
import { EditorContext } from "../../context/EditorContext/EditorProvider";

interface LaneHeaderProps {
  laneId: string;
  key: string;
  index: number;
  dragActive: boolean;
}

export let LaneHeader = (props: LaneHeaderProps) => {
  const editor = useContext(EditorContext);

  const lane = useSelector<MainStore, Lane>(
    state => state.project.lanes.byId[props.laneId]
  );

  const [isEditing, setIsEditing] = useState(false);
  const [laneName, setLaneName] = useState(lane.name);
  let laneTitleInput = useRef(null);
  const dispatch = useDispatch();

  const deleteLane = () => {
    dispatch<DeleteLaneAction>({
      type: DELETE_LANE,
      laneId: lane.id
    });
  };

  const renameLane = () => {
    setIsEditing(true);
    setTimeout(() => {
      laneTitleInput.current.focus();
    }, 100);
  };

  const confirmRenameLane = () => {
    setIsEditing(false);
    dispatch<RenameLaneAction>({
      type: RENAME_LANE,
      laneId: lane.id,
      name: laneName
    });
  };

  const inputKeyDown = e => {
    switch (e.keyCode) {
      case 13:
        // Enter: confirm rename
        confirmRenameLane();
        break;
      case 27:
        // Escape: exit rename mode
        exitRenameLane();
        break;
    }
  };

  const onClickCheckmark = () => {
    confirmRenameLane();
  };

  const exitRenameLane = () => {
    setIsEditing(false);
    setLaneName(lane.name);
  };

  const inputStyles = {
    ...lightInputStyle,
    padding: "0 6px",
    backgroundColor: theme.editor.backgroundMedium,
    height: "21px"
  };

  if (editor.tightLayout) {
    return (
      <Draggable draggableId={props.laneId} index={props.index}>
        {(provided, snapshot) => (
          <TightContainer
            ref={provided.innerRef}
            {...provided.draggableProps}
            isEditing={isEditing}
            progress={
              lane.laneLoadingStatus?.loadingProgressPercentage * 100 || 0
            }
            className={`${
              snapshot.isDragging
                ? "dragging"
                : props.dragActive
                ? "dragged-over"
                : "incognito"
            }`}
          >
            <div id="background-progress-bar" />
            <div
              {...provided.dragHandleProps}
              className="btn-drag hide-no-hover"
            >
              <GripVerticalIcon />
            </div>

            <div className="lane-title">
              {lane.laneLoadingStatus?.isLoading ? (
                <span className="progressLoading">Loading...</span>
              ) : isEditing ? (
                <Input
                  value={laneName}
                  onKeyDown={inputKeyDown}
                  onChange={e => setLaneName(e.target.value)}
                  style={inputStyles}
                  ref={laneTitleInput}
                />
              ) : (
                <span>{lane.name}</span>
              )}
              {isEditing && (
                <div className="title-edit-controls">
                  <div className="btn-edit">
                    <span onClick={onClickCheckmark}>
                      <Checkmark />
                    </span>
                  </div>
                  <div onClick={exitRenameLane} className="btn-close">
                    <Close />
                  </div>
                </div>
              )}
            </div>
            {!isEditing && (
              <LaneControls
                laneId={props.laneId}
                startLaneRenameMode={renameLane}
              />
            )}
          </TightContainer>
        )}
      </Draggable>
    );
  } else {
    return (
      <Draggable draggableId={props.laneId} index={props.index}>
        {(provided, snapshot) => (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            isEditing={isEditing}
            progress={
              lane.laneLoadingStatus?.loadingProgressPercentage * 100 || 0
            }
            className={snapshot.isDragging ? "dragging" : ""}
          >
            <div className="lane-title-region">
              <div className="progressBar" />
              <div {...provided.dragHandleProps} className="btn-drag">
                <GripVerticalIcon />
              </div>
              <div className="lane-title">
                {lane.laneLoadingStatus?.isLoading ? (
                  <span className="progressLoading">Loading...</span>
                ) : isEditing ? (
                  <Input
                    value={laneName}
                    onKeyDown={inputKeyDown}
                    onChange={e => setLaneName(e.target.value)}
                    style={inputStyles}
                    ref={laneTitleInput}
                  />
                ) : (
                  <span>{lane.name}</span>
                )}
              </div>
              <div className="btn-edit">
                {isEditing ? (
                  <span onClick={onClickCheckmark}>
                    <Checkmark />
                  </span>
                ) : (
                  <span onClick={renameLane}>
                    <Edit />
                  </span>
                )}
              </div>
              <div
                onClick={isEditing ? exitRenameLane : deleteLane}
                className="btn-close"
              >
                <Close />
              </div>
            </div>
            <LaneControls laneId={props.laneId} />
          </Container>
        )}
      </Draggable>
    );
  }
};
