import styled from "styled-components";
import ReactSVG from "react-svg";

const buttonPadding = "10px";

export const Container = styled.div`
  width: 100%;

  ${({ downloadable }) =>
    downloadable &&
    `
    display: flex;
    flex-flow: row nowrap;
  `}

  .details {
    padding-top: 8px;
  }

  .grey-text {
    color: ${props => props.theme.textDark};
    font-size: ${props => props.theme.p3m.fs};
  }

  .file {
    color: ${props => props.theme.highlight};
    font-size: ${props => props.theme.p4r.fs};
    text-decoration: underline;
    width: fit-content;
  }

  .pointer {
    cursor: pointer;
  }

  .btn-icon {
    margin-right: 4px;
    width: 16px;
    height: 16px;
  }

  .underline {
    text-decoration: underline;
  }

  .track-image {
    width: 77px;
    height: 75px;
    margin-right: ${({ theme }) => theme.paddingContainersS};
    margin-bottom: ${({ theme }) => theme.paddingContainersS};
    margin-left: ${({ downloadable }) => !downloadable && buttonPadding};
  }

  .button {
    justify-content: end;
    width: fit-content;
  }

  .title {
    color: ${props => props.theme.textLight};
    font-size: ${props => props.theme.h2.fs};
    font-family: ${props => props.theme.p1b.ff};
    padding: 0 ${({ downloadable }) => !downloadable && buttonPadding};
  }

  .flex-row {
    display: flex;
    flex-flow: row nowrap;
  }

  .flex-item {
    flex: 0 0 auto;
  }

  .download-all {
    margin-left: 44px;
  }
`;

export const Files = styled.div`
  .section {
    margin-top: ${({ theme }) => theme.paddingContainersS};
  }

  .sub-title {
    padding: 0 ${({ downloadable }) => !downloadable && buttonPadding};
    display: flex;
    font-family: ${({ theme }) => theme.fontHeavy};
    color: ${props => props.theme.textLight};
    font-size: ${props => props.theme.p2b.fs};
  }
`;

export const File = styled.div`
  color: ${({ downloadable, theme }) =>
    downloadable ? theme.subscribe : theme.textDark};
  padding: 3px ${({ downloadable }) => !downloadable && buttonPadding};
  text-decoration: underline;
  cursor: ${({ downloadable }) => downloadable && "pointer"};
`;

export const CCFile = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme, downloadable }) =>
    downloadable ? theme.subscribe : theme.textLight};
  background: ${({ theme, downloadable }) => !downloadable && theme.highlight};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${({ downloadable }) =>
    downloadable ? "3px" : "0 0 0 " + buttonPadding};
  text-decoration: underline;
  cursor: pointer;

  .cc-icons {
    display: ${({ downloadable }) => (downloadable ? "none" : "flex")};
    height: fit-content;
    padding: 4px 0 4px 10px;

    div {
      height: 18px;
    }
  }
`;

export const Icon = styled(ReactSVG)`
  display: inline-block;
  padding: 0 5px;
  height: 13px;
  width: 13px;

  svg {
    height: 13px;
    width: 13px;

    path {
      fill: ${({ theme }) => theme.textGrey};
    }
  }
`;
