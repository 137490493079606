import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-right: 10px;
  width: 260px;
`;
