import styled from "styled-components";

export const Container = styled.div`
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1em;
  margin: 1.5em 0;
  background-color: ${({ theme }) => theme.editor.backgroundLight};
  border-radius: ${({ theme }) => theme.editor.containerRadius};

  &&::-webkit-scrollbar {
    width: 12px;
  }

  &&::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.editor.backgroundMedium};
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.editor.backgroundHighlight};
    border-radius: 20px;
    border: 2px solid ${({ theme }) => theme.editor.backgroundMedium};
  }

  #project-selector-headers {
    width: calc(100% - 35px);
    margin-left: 35px;
  }

  #project-selector-headers > thead {
    width: 100%;
  }

  #project-selector-headers th {
    color: ${({ theme }) => theme.editor.textMedium};
    font-family: ${({ theme }) => theme.fontLight};
  }

  .project-selector-tree {
    .ant-tree-node-content-wrapper,
    .ant-tree-title,
    .ant-tree-title > table {
      width: 98%;
    }

    .ant-tree-title td.file-detail:not(:last-child) {
      width: 33%;
    }

    .ellipsis {
      position: relative;
    }

    .ellipsis:before {
      content: "&nbsp;";
      visibility: hidden;
    }

    .ellipsis span {
      position: absolute;
      left: 0;
      right: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    li > * {
      font-family: ${({ theme }) => theme.fontMedium} !important;
      color: ${({ theme }) => theme.editor.textMedium} !important;
    }

    li.ant-tree-treenode-selected > *,
    li span.ant-tree-node-content-wrapper-open {
      font-family: ${({ theme }) => theme.fontHeavy} !important;
      color: ${({ theme }) => theme.editor.textLight} !important;
    }

    li span.ant-tree-node-content-wrapper::before {
      background: transparent !important;
    }

    li span.ant-tree-node-content-wrapper.ant-tree-node-selected::before {
      background: ${({ theme }) => theme.editor.highlight} !important;
    }

    .ant-tree-iconEle,
    .ant-tree-switcher {
      stroke: currentColor;
      stroke-width: 25px;
      margin-right: 3px;
    }

    .ant-tree-switcher {
      transform: scale(1.15);
    }

    .ant-tree-node-content-wrapper {
      display: inline-flex;
    }

    td.file-last-modified {
      display: flex;
    }

    span.last-modified-date {
      width: calc(100% - 35px);
      margin-right: 10px;
    }

    .file-detail-last-modified-container {
      display: flex;
      width: 100%;
    }
  }

  #save-modal-custom-mix-name-label {
    color: ${({ theme }) => theme.editor.textMedium};
    font-family: ${({ theme }) => theme.fontMedium};
    margin: 0.5em 0;
  }

  .input-container {
    margin-right: 14px;
    flex: 1;
  }

  input.ant-input {
    font-family: ${({ theme }) => theme.fontHeavy} !important;
  }
`;
