import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { MainStore, VolumeIndicators } from "../../store";
import { IconButton } from "./IconButton";
import { Volume } from "../../../assets/Volume";
import { VolumeMuted } from "../../../assets/VolumeMuted";
import { VolumeSlider } from "../VolumeSlider";
import {
  SetMasterMuteAction,
  SetMasterVolumeAction,
  SET_MASTER_MUTE,
  SET_MASTER_VOLUME
} from "../../actions/actionTypes";
import { valToDb } from "../LaneVolumeSlider";
import { MasterVolumeContainer } from "./styles";

export function MasterVolumeControl() {
  const isMuted = useSelector<MainStore, boolean>(
    state => state.uiState.masterMute
  );
  const volume = useSelector<MainStore, number>(
    state => state.uiState.masterVolume
  );
  let volumeIndicators = useSelector<MainStore, VolumeIndicators>(
    state =>
      state.uiState.masterVolumeIndicator || { currentPeak: 0, recentPeak: 0 }
  );
  const dispatch = useDispatch();

  const toggleMute = () => {
    dispatch<SetMasterMuteAction>({
      type: SET_MASTER_MUTE,
      isMuted: !isMuted
    });
  };

  const onChange = val => {
    dispatch<SetMasterVolumeAction>({
      type: SET_MASTER_VOLUME,
      volumeValue: valToDb(-0.5 + (val / 100) * 1.5)
    });
  };

  return (
    <MasterVolumeContainer>
      <Popover
        placement="bottomLeft"
        getPopupContainer={trigger => trigger}
        content={
          <VolumeSlider
            onChange={onChange}
            onAfterChange={onChange}
            currentVolume={volume}
            volumeIndicators={volumeIndicators}
          />
        }
        {...(isMuted && { visible: false })}
      >
        <IconButton
          className="btn-master-volume"
          onClick={toggleMute}
          svgWidth="20px"
          svgHeight="20px"
        >
          {isMuted ? <VolumeMuted /> : <Volume />}
        </IconButton>
      </Popover>
    </MasterVolumeContainer>
  );
}
