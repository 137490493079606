import styled from "styled-components";
import { Select } from "antd";
import ReactSVG from "react-svg";

export const inBtwPadding = "18px";
export const elHeight = "24px";

export const Container = styled.div`
  position: relative;
  width: 600px;
  color: ${({ theme }) => theme.editor.textLight};

  hr.form-divider {
    border: 1px solid ${({ theme }) => theme.editor.backgroundLight};
  }

  input {
    font-family: ${({ theme }) => theme.fontHeavy} !important;
  }

  input.custommix-name {
    font-size: 16px !important;
    font-family: ${({ theme }) => theme.fontHeavy} !important;
  }

  .row {
    display: block;
    margin: 16px 0;
  }

  .title {
    font-size: 20px;
    padding-bottom: 10px;
  }

  .columns {
    display: grid;
    grid-template-columns: 172px auto;
    column-gap: 10px;
  }

  .inputs {
    margin-top: 16px;

    .track-attribute {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 16px;
      gap: 18px;

      label {
        display: inline-block;
        font-family: ${({ theme }) => theme.fontHeavy};
        color: ${({ theme }) => theme.editor.textLight};
        font-size: 14px;
        width: 40%;
        text-align: right;
      }

      .track-attribute-data {
        display: flex;
        align-items: center;
        font-family: ${({ theme }) => theme.fontMedium};
        color: ${({ theme }) => theme.editor.textMedium};
        font-size: 14px;
      }

      input {
        width: 80px;
        height: ${elHeight};
        font-family: ${({ theme }) => theme.fontHeavy};
      }
    }
  }

  .trackinfo-label {
    color: ${({ theme }) => theme.editor.textMedium};
    font-size: 12px;
  }

  .audio-status-msg {
    display: inline-block;
  }

  .trackinfo-data {
    color: ${({ theme }) => theme.editor.textLight};
    font-size: 14px;
  }

  .characteristic {
    margin: ${inBtwPadding} 0;
  }

  .characteristic-label {
    font-family: ${({ theme }) => theme.fontHeavy};
    color: ${({ theme }) => theme.editor.textLight};
    font-size: 14px;
    width: 30%;
    text-align: left;
  }

  .characteristic-slider {
    width: calc(70% - 18px);
  }

  .tags-container {
    margin-top: 24px;
    width: 100%;
  }

  .tag-selector {
    margin-bottom: 24px;
  }

  .tag-selector > * {
    width: 100%;
  }

  .header {
    font-family: ${({ theme }) => theme.fontHeavy};
    color: ${({ theme }) => theme.editor.textLight};
    font-size: 14px;
    text-align: left;
    margin-bottom: 12px;
  }

  .ant-tooltip-content {
    width: 325px;
  }
`;

export const FlexColumns = styled.div`
  display: flex;
  gap: 40px;

  > * {
    flex: 1 1 0;
  }

  @media (max-width: ${({ theme }) => theme.mobileBreakpoint}) {
    flex-flow: column nowrap;
    gap: 24px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: 18px;
`;

export const FlexRowTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .subscribe-cta-container {
    margin: 14px;
  }

  .subscribe-cta-container > * {
    margin-bottom: 14px;
  }
`;

export const FlexRowModified = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 18px;
`;

export const StyledSelect = styled(Select)`
  flex: 1;
  .ant-select-selection {
    background-color: ${({ theme }) => theme.editor.backgroundLight};
    color: ${({ theme }) => theme.editor.textLight};
  }
  margin: 5px 0px;
`;

export const Icon = styled(ReactSVG)`
  height: 14px;

  svg {
    height: 13px;
    width: 13px;

    path {
      fill: ${({ theme }) => theme.textGrey};
    }
  }
`;
