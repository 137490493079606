import React, { useRef, useEffect } from "react";
import { Container } from "./styles";

/*
 * A general popover styled to match the site along with display animations.
 * Can point up or down and show on click or hover.
 */
const GenericPopover = ({
  triggerComponent,
  triggerType = "click",
  contents,
  xOffset = "0px",
  yOffset = "82px",
  anchor = "top",
  containerStyle = {},
  popoverState,
  forceClickToDismiss = false,
  onChange = () => {}
}) => {
  const dropdownRef = useRef(null);
  const prevDropdownOpen = useRef();

  useEffect(() => {
    if (triggerType === "click" || forceClickToDismiss) {
      document.addEventListener("mousedown", closeDropdown, false);
      return () =>
        document.removeEventListener("mousedown", closeDropdown, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerType, forceClickToDismiss]);

  useEffect(() => {
    // only call onChange if the dropdown state actually changes
    // otherwise thits emits every render
    if (prevDropdownOpen.current !== popoverState.visible) {
      onChange(popoverState.visible);
    }
    prevDropdownOpen.current = popoverState.visible;
  }, [popoverState.visible, onChange]);

  const handleDropdown = () => {
    popoverState.toggleTo(!popoverState.visible);
  };
  const openDropdown = e => {
    popoverState.open();
  };
  const closeDropdown = e => {
    if (!dropdownRef.current.contains(e.target)) popoverState.close();
  };
  const closeDropdownUnspecific = e => {
    if (!forceClickToDismiss) popoverState.close();
  };

  const Clickable = React.cloneElement(triggerComponent, {
    onClick: handleDropdown,
    className: "clickable"
  });

  const Hoverable = React.cloneElement(triggerComponent, {
    onMouseEnter: openDropdown,
    onMouseLeave: closeDropdownUnspecific,
    classname: "clickable"
  });

  const Contents = React.cloneElement(contents);

  //TODO:
  /* This component doesn't fully replace antd's tooltip/popover since it doesn't use portal. */
  /* portal is an portal is useful because it appears above scrollbar and rules over all z-index*/

  return (
    <Container
      style={containerStyle}
      xOffset={xOffset}
      ref={dropdownRef}
      yOffset={yOffset}
      anchor={anchor}
    >
      {triggerType === "click" ? Clickable : Hoverable}
      {popoverState.visible && (
        <div className="menu">
          <div className="inner">
            {anchor === "top" && (
              <img
                src={require("../../assets/triangle.svg")}
                className="triangle"
                alt={"menu"}
              />
            )}
            {Contents}
            {anchor === "bottom" && (
              <img
                src={require("../../assets/triangle.svg")}
                className="down-triangle"
                alt={"menu"}
              />
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

export default GenericPopover;
