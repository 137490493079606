import styled from "styled-components";

export const TagContainer = styled.div`
  background: ${({ theme, newTag }) =>
    newTag ? theme.highlight : theme.backgroundTag};
  border-radius: ${props => props.theme.borderRadius};
  margin: ${props => (!props.margin ? "5px" : props.margin)};
  padding: 0px 15px;
  color: ${props => props.theme.textLight};
  display: inline-flex;
  justify-content: ${props => (props.ellipsis ? "center" : "center")};
  cursor: pointer;
  height: 24px;
  position: relative;
  transition: width 3s ease-in;
  width: auto;
  .text {
    font-family: ${props => props.theme.p3b.ff};
    white-space: nowrap;
    text-align: center !important;
    display: flex;
    align-items: center;

    ${props =>
      props.ellipsis &&
      ` text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      `}
  }
`;
