import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Icon } from "./styles";
import { SearchContext } from "../../context";
import { useMediaQuery } from "react-responsive";

const Aggregate = ({ history, location, name, type, aggregate, className }) => {
  // Media Query for Mobile version
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 800 });
  const {
    setSearchValue,
    // setFilters,
    selectedTrackList,
    setSelectedTrackList,
    removeAggregate
  } = useContext(SearchContext);

  useEffect(() => {
    if (!selectedTrackList) {
      history.push("/browse");
    }
  }, [selectedTrackList, history, setSelectedTrackList]);

  // const notAggregate = () =>
  //   selectedTrackList.type === "Track" ||
  //   selectedTrackList.type === "Playlist" ||
  //   selectedTrackList.type === "Album";

  return (
    <>
      <Container
        isAggregate={
          location.pathname === "/browse" || location.pathname === "/"
        }
        isMobile={!isDesktopOrLaptop}
        onClick={() => {
          if (type === "aggregate") return removeAggregate(aggregate);
          setSelectedTrackList(undefined);
          setSearchValue("");
          history.push("/browse");
        }}
        className={className}
      >
        <Icon
          src={
            type === "aggregate"
              ? require("../../assets/epic.svg")
              : require(`../../assets/${selectedTrackList.type.toLowerCase()}.svg`)
          }
          alt={"icon"}
        />
        <div>
          <p className="agr-name ws-nowrap">
            {type === "aggregate" ? name : selectedTrackList.name}
          </p>
        </div>
        {type !== "aggregate" && (
          <img
            className="clear"
            style={{ height: 10, width: 10 }}
            src={require("../../assets/Clear.svg")}
            alt={"icon"}
          />
        )}
      </Container>
    </>
  );
};

export default withRouter(Aggregate);
