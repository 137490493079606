import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_FX_VALUE, SetFXValueAction } from "../../actions/actionTypes";
import { MainStore } from "../../store";
import { theme } from "../../../globalStyles";
import { FXKnobContainer } from "./styles";
import FLStandardKnob from "../Knob/fl-controls/fl-standard-knob";

export interface FXKnobProps {
  laneId: string;
  slotId: number;
}

export const FXKnob = (props: FXKnobProps) => {
  const dispatch = useDispatch();

  const fxVal = useSelector<MainStore, number>(
    state =>
      state.project.lanes.byId[props.laneId].laneStatus.fxSlot[props.slotId].val
  );

  const [knob, setKnob] = useState(null);

  const panKnobRef = useRef(null);

  useEffect(() => {
    // wait for container dom ele to be set up
    // check knob hasn't already been instantiated (failsafe)
    // ensure redux store has been populated with saved values
    if (panKnobRef.current && knob === null) {
      const knob = new FLStandardKnob(panKnobRef.current, {
        indicatorDot: true,
        min: 0,
        max: 1,
        initial: 0,
        indicatorRingType: "positive",
        angleRange: 280,
        stickyCriticalPoints: true,
        color: theme.editor.highlight
      });
      knob._input.addEventListener("change", function(e) {
        onChange(e.target.value);
      });
      setKnob(knob);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panKnobRef, knob]);

  // this ensures value reflects redux store
  useEffect(() => {
    if (knob) {
      knob._input.value = fxVal;
      knob.updateToInputValue();
    }
  }, [knob, fxVal]);

  const onChange = newValue => {
    dispatch<SetFXValueAction>({
      slotId: props.slotId,
      laneId: props.laneId,
      type: SET_FX_VALUE,
      value: newValue
    });
  };

  return (
    <FXKnobContainer
      ref={panKnobRef}
      className="fx-knob-container"
    ></FXKnobContainer>
  );
};
