import styled from "styled-components";

export const ListContainer = styled.div`
  width: 442px;
  border-radius: 8px;
  height: auto;

  .read-all {
    cursor: pointer;
  }
  ::-webkit-scrollbar {
    width: 8px;

    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.06);
    opacity: 0.6;
    border-radius: 2px;
  }
  max-height: 520px;
  overflow-y: auto;
  background: ${props => props.theme.backgroundDark};
  box-shadow: ${props => props.theme.boxShadowBottom};

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px ${props => props.theme.backgroundTag};
    padding: 20px 24px;
  }
  .no-notifications {
    display: grid;
    justify-content: center;
    padding: 20px;
  }
`;

export const Badge = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props => props.theme.highlight};
  /* border: solid 1px ${props => props.theme.backgroundDark}; */
  position: absolute;
  top: -4px;
  right: -3px;
`;

export const CardContainer = styled.div`
  display: grid;

  width: 100%;
  grid-template-columns: auto 1fr 84px;
  padding: 30px 30px 20px 27px;
  align-content: center;
  align-items: center;
  margin-bottom: 10px;

  cursor: pointer;

  &:hover {
    background: ${props => props.theme.backgroundLight};
  }

  .grey {
    color: ${props => props.theme.textDark};
  }
  .link {
    color: ${props => props.theme.highlight};
  }

  .content {
    margin-left: 20px;

    width: 260px;

    .body p {
      color: ${props => props.theme.textDark};
      display: inline;
    }
    .body {
      width: 100%;
    }
  }

  .time {
    // text-align: right;
  }
  .name {
    margin-bottom: 4px;
  }

  .time-container {
    display: flex;

    height: 35px;
    flex-direction: column;
    align-items: center;
  }
  .time-sub-container {
    height: 25px;
  }
  .unread {
    background: ${props => props.theme.highlight};
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
`;
