import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import { LOAD_MIX, LoadMixFromInitialTrack } from "../../actions/actionTypes";
import { InitialTrackData, MainStore } from "../../store";
import "../Buttons/EditorButton";
import { EditorButton } from "../Buttons/EditorButton";
import { Container, FlexButtons } from "./styles";
import AntModal from "../../../components/AntModal";
import { theme } from "../../../globalStyles";
import { AuthContext } from "../../../context/AuthProvider";
import Spinner from "../../../components/SpinnerButton";

export const ResetModal = ({ modalState }) => {
  const dispatch = useDispatch();

  const { user, hasPaidTrackAccess } = useContext(AuthContext);

  const [loadWarningMessage, setLoadWarningMessage] = useState("");
  const [isResetting, setIsResetting] = useState(false);

  const initialTrackData = useSelector<MainStore, InitialTrackData>(
    state => state.project.initialTrackData
  );
  const currentDataHash = useSelector<MainStore, string>(
    state => state.project.customMixInfo.currentDataHash
  );
  const lastSaveDataHash = useSelector<MainStore, string>(
    state => state.project.customMixInfo.version.editorDataDumpHash
  );

  const resetModalAndClose = (newProjectState = null) => {
    setLoadWarningMessage("");
    setIsResetting(false);
    modalState.close();
  };

  const onConfirm = () => {
    setIsResetting(true);
    let queryParams = new URLSearchParams(window.location.search);
    const loadFromCache = user.adminUser
      ? (queryParams.get("cache") || "false").toLowerCase() === "true"
      : true;

    dispatch<LoadMixFromInitialTrack>({
      projectId: initialTrackData.id,
      type: LOAD_MIX,
      fromCache: loadFromCache,
      paidAccess: hasPaidTrackAccess(initialTrackData),
      onLoaded: resetModalAndClose
    });
  };

  useEffect(() => {
    if (currentDataHash !== lastSaveDataHash && !isResetting) {
      setLoadWarningMessage(
        "You have unsaved changes to your custom mix. Please save prior to resetting or your changes will be lost."
      );
    } else {
      setLoadWarningMessage("");
    }
  }, [currentDataHash, lastSaveDataHash, isResetting]);

  return (
    <AntModal modalState={modalState} onClose={resetModalAndClose}>
      <Container>
        <h1>Reset Mix</h1>
        <p className="message">
          This will reset the mix to its initial track layout from our recording
          studio. Continue?
        </p>
        {loadWarningMessage && (
          <Alert
            message={"Warning"}
            description={loadWarningMessage}
            type="warning"
            showIcon
          />
        )}
        <FlexButtons>
          <EditorButton
            backgroundColor={theme.editor.backgroundLight}
            onClick={resetModalAndClose}
          >
            Cancel
          </EditorButton>
          <EditorButton
            backgroundColor={theme.editor.highlight}
            onClick={onConfirm}
            disabled={isResetting}
          >
            Reset
            {isResetting && <Spinner />}
          </EditorButton>
        </FlexButtons>
      </Container>
    </AntModal>
  );
};
