import React, { useEffect, useCallback } from "react";
import { Container } from "./styles";
import { timeFormatterNum } from "../../utils/timeFormatter";
// import Overlay from "../Overlay";

const ClosableTag = ({
  text = "title",
  range,
  closable = true,
  hasRange = true,
  onClick = () => {},
  background,
  selected = false,
  onClose,
  minWidth = 93,
  minHeight,
  isActive = false,
  onHover = () => {},
  onMouseLeave,
  faded = false,
  onSearchFilter = false,
  isKey = false,
  containerProps
}) => {
  if (range && range[1] < range[0]) {
    throw new Error(
      "Invalid range value passed. Second value must be greater than the first"
    );
  }

  const validateValues = useCallback(() => {
    if (text.toLocaleLowerCase() === "bpm" && range[0] < 20) {
      range[0] = 20;
      return;
    }

    if (text.toLocaleLowerCase() === "bpm" && range[1] > 200) {
      range[1] = 200;
      return;
    }
    if (text.toLocaleLowerCase() === "duration" && range[0] < 0) {
      range[0] = 0;
      return;
    }
    if (text.toLocaleLowerCase() === "duration" && range[1] > 360) {
      range[1] = 360;
      return;
    }

    // if (range[0] < 1) range[0] = 1;
    // if (range[1] > 5) range[1] = 5;
  }, [range, text]);

  useEffect(() => {
    validateValues();
  }, [validateValues]);

  let starting = (range[0] - 1) * 20;
  let ending = (range[1] - 1) * 20;
  let width = (range[1] - range[0] + 1) * 20;
  if (range[0] === 0 && range[1] === 0) {
    starting = 0;
    ending = 0;
    width = 0;
  }
  if (
    selected ||
    text.toLocaleLowerCase() === "bpm" ||
    text.toLocaleLowerCase() === "duration"
  ) {
    starting = 0;
    ending = 100;
    width = 100;
    isActive = true;
  }

  const colon = () => {
    if (text === "Length" || text === "Duration" || text === "BPM") {
      return ":";
    }
    return null;
  };

  const showText = text => {
    if (text.toLowerCase() === "duration") return "Length";
    return text;
  };

  return (
    <Container
      minWidth={minWidth}
      range={range}
      starting={`${starting}%`}
      ending={`${ending}%`}
      width={`${width}%`}
      onClick={onSearchFilter ? onClose : onClick}
      background={background}
      selected={selected}
      isActive={isActive}
      minHeight={minHeight}
      onMouseEnter={onHover}
      onMouseOut={onMouseLeave}
      faded={faded}
      moveToRight={!["Length", "Duration", "BPM"].includes(text)}
      {...containerProps}
      closable={closable}
    >
      {hasRange && <div className="range" />}
      <div className="text" onMouseEnter={onHover} onMouseOut={onMouseLeave}>
        {isKey ? (
          <>Key: {text}</>
        ) : (
          <>
            {showText(text)}
            {colon()}
          </>
        )}
      </div>
      {text === "BPM" && (
        <div className="text value">
          {range[0] !== range[1] ? `${range[0]} - ${range[1]}` : range[0]}
        </div>
      )}
      {["Length", "Duration"].includes(text) && (
        <div className="text value">
          {timeFormatterNum(range[0])} - {timeFormatterNum(range[1])}{" "}
          {range[1] === 360 && "+"}
        </div>
      )}
      {closable && (
        <div className="close" onClick={onClose}>
          <img src={require("../../assets/Clear.svg")} alt={text} />
        </div>
      )}

      {/* <Overlay /> */}
    </Container>
  );
};

export default ClosableTag;
