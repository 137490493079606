import moment from "moment";

export const newTagAmountOfDays = 45;

export const newTagFilter = {
  name: "New",
  type: "dateRange",
  value: "publishDate",
  min: moment().subtract(45, "days"),
  max: moment()
};
