import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_VOLUME_INDICATOR_VALUES,
  SetVolumeIndicatorValuesAction
} from "../actions/actionTypes";
import { FX, MainStore, MuteSoloState } from "../store";
import { max_amplitude } from "../util/array_helpers";
import { dbToLinear } from "../util/extra_math";
import { piecewiseInterp } from "../util/interpolation";
import { AudioMaster } from "./AudioMaster";
import { InsertEffectNode } from "./InsertEffectNode";
import { PlaybackNode } from "./PlaybackNode";

export interface LaneNodeProps {
  audioMaster: AudioMaster;
  laneId: string;
  outputNode: any;
}

export function LaneNode(props: LaneNodeProps) {
  const output = props.outputNode;
  // eslint-disable-next-line
  const [stateLaneNodes, setStateLaneNodes] = useState(() => {
    const gainNode = props.audioMaster.context.createGain();
    const fx1Node = props.audioMaster.context.createGain();
    const fx2Node = props.audioMaster.context.createGain();
    const fx3Node = props.audioMaster.context.createGain();
    const panner = props.audioMaster.context.createStereoPanner();
    const analyzer = props.audioMaster.context.createAnalyser();
    panner.connect(gainNode);
    gainNode.connect(analyzer);

    return {
      gainNode,
      panner,
      fx1Node,
      fx2Node,
      fx3Node,
      analyzer
    };
  });

  const [statePollInterval, setStatePollInterval] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    stateLaneNodes.gainNode.connect(output);

    const analyzer = stateLaneNodes.analyzer;

    if (statePollInterval) {
      clearInterval(statePollInterval);
    }

    let recentPeak = 0;
    const smoothingRate = 0.9;
    const interval = setInterval(() => {
      const data = new Float32Array(analyzer.fftSize);
      analyzer.getFloatTimeDomainData(data);
      const maxAmp = max_amplitude(data);

      if (maxAmp > recentPeak) {
        recentPeak = maxAmp;
      } else {
        recentPeak *= smoothingRate;
      }

      dispatch<SetVolumeIndicatorValuesAction>({
        type: SET_VOLUME_INDICATOR_VALUES,
        laneId: props.laneId,
        currentPeak: maxAmp,
        recentPeak: recentPeak
      });
    }, 100);
    setStatePollInterval(interval);

    return () => {
      clearInterval(interval);
      clearInterval(statePollInterval);
      stateLaneNodes.gainNode.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.outputNode]);

  const volume = useSelector<MainStore, number>(
    state => state.project.lanes.byId[props.laneId].laneStatus.volume
  );
  const laneGainCompensation = useSelector<MainStore, number>(
    state => state.project.lanes.byId[props.laneId].laneStatus.gainCompensation
  );
  const pan = useSelector<MainStore, number>(
    state => state.project.lanes.byId[props.laneId].laneStatus.pan
  );

  const muteSoloState = useSelector<MainStore, number>(
    state => state.project.lanes.byId[props.laneId].laneStatus.muteSoloState
  );
  const soloLaneCount = useSelector<MainStore, number>(
    state => state.project.lanes.solodLaneIds.length
  );

  const insertsEffectData = useSelector<MainStore, FX[]>(
    state => state.project.lanes.byId[props.laneId].laneStatus.fxSlot
  );

  const finalOutputVolume = useMemo(() => {
    let finalVolume = dbToLinear(volume + laneGainCompensation);

    // take max of all dry attenuations from send FX and attenuate output volume accordingly
    // ensure non-additive, i.e. if 2 send fx are set to reduce the dry gain by 50%, it should be
    // reduced by 50% total, not 100%
    const laneDryGain = Math.min(
      ...insertsEffectData.map(insertEffectData => {
        return Math.min(
          ...insertEffectData.internalFXChain.map(internalEffect => {
            const isSendWithDryAttenuation =
              internalEffect.effectId.startsWith("send") &&
              internalEffect.mapping?.dry;
            if (isSendWithDryAttenuation) {
              const dryMapping = internalEffect.mapping.dry;
              // figure out where val is in step range
              const interpVal = piecewiseInterp(
                dryMapping.stopsX,
                dryMapping.stopsY,
                dryMapping.stopsInterp,
                insertEffectData.val
              );
              return interpVal;
            }
            return 1;
          })
        );
      })
    );
    finalVolume *= laneDryGain;

    if (muteSoloState === MuteSoloState.MUTE) {
      finalVolume = 0;
    }
    if (soloLaneCount > 0 && muteSoloState !== MuteSoloState.SOLO) {
      // other lanes soloed, mute this
      finalVolume = 0;
    }

    return finalVolume;
  }, [
    volume,
    laneGainCompensation,
    insertsEffectData,
    muteSoloState,
    soloLaneCount
  ]);

  useEffect(() => {
    stateLaneNodes.gainNode.gain.setTargetAtTime(
      finalOutputVolume,
      props.audioMaster.context.currentTime,
      0.01
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalOutputVolume]);

  useEffect(() => {
    stateLaneNodes.panner.pan.value = (pan ?? 0.5) * 2 - 1; // 0 - 1 to -1 to 1
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pan]);

  return (
    <>
      <PlaybackNode
        laneId={props.laneId}
        audioMaster={props.audioMaster}
        outputNode={stateLaneNodes.fx1Node}
      />
      <InsertEffectNode
        audioMaster={props.audioMaster}
        laneId={props.laneId}
        slotNumber={0}
        inputNode={stateLaneNodes.fx1Node}
        outputNode={stateLaneNodes.fx2Node}
      />
      <InsertEffectNode
        audioMaster={props.audioMaster}
        laneId={props.laneId}
        slotNumber={1}
        inputNode={stateLaneNodes.fx2Node}
        outputNode={stateLaneNodes.fx3Node}
      />
      <InsertEffectNode
        audioMaster={props.audioMaster}
        laneId={props.laneId}
        slotNumber={2}
        inputNode={stateLaneNodes.fx3Node}
        outputNode={stateLaneNodes.panner}
      />
    </>
  );
}
