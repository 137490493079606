import { Envelopes } from "../store";
import { PIXELS_PER_SECOND } from "../util/time_position_conversion";

/*
 *  Calculates envelopes for audio waveforms with provided parameters
 *
 *  Parameters:
 *      audioBuffer (AudioBuffer): audio samples & sample rate metadata
 *      windowSize (number): analysis window size (= hopSize)
 *      halfNormalize (boolean): move halfway between normalized point and actual point
 *      normalize (boolean): normalize in [0, 1]
 *      mixToMono (boolean): mix selected channels down to mono
 *      channels? (number[]): which channel indices to use, or undefined for all channels
 *
 *  Returns:
 *      Envelopes containing metadata and data for each channel
 */
export const envelopeGenerator = (
  audioBuffer: AudioBuffer,
  windowSize: number,
  halfNormalize: boolean = false,
  normalize: boolean = false,
  mixToMono: boolean = false,
  channels?: number[]
): Envelopes => {
  const channelsToProcess = Array.isArray(channels)
    ? channels.map(iChannel => Math.min(iChannel, audioBuffer.numberOfChannels))
    : Array.from(Array(audioBuffer.numberOfChannels)).map(
        (_, iChannel) => iChannel
      );

  const envelopes: Envelopes = {
    channelAmplitudeSamples: [],
    windowSize: windowSize,
    hopSize: windowSize,
    sampleRate: audioBuffer.sampleRate
  };

  let rawStemBuffers = channelsToProcess.map(iChannel =>
    audioBuffer.getChannelData(iChannel)
  );
  if (mixToMono) {
    const numChannels = rawStemBuffers.length;
    const numSamples = rawStemBuffers[0].length;
    let mergedMonoBuffer = new Float32Array(numSamples);
    for (let iSample = 0; iSample < numSamples; iSample++) {
      let sumSamples = 0;
      for (let iChannel = 0; iChannel < numChannels; iChannel++) {
        sumSamples += rawStemBuffers[iChannel][iSample];
      }
      mergedMonoBuffer[iSample] = sumSamples / numChannels;
    }
    rawStemBuffers = [mergedMonoBuffer];
  }

  for (let iChannel = 0; iChannel < rawStemBuffers.length; iChannel++) {
    const envelopeSamples = calcChannelEnvelope(
      rawStemBuffers[iChannel],
      windowSize,
      halfNormalize,
      normalize
    );
    envelopes.channelAmplitudeSamples.push(Array.from(envelopeSamples));
  }

  return envelopes;
};

/*
 * Utility function that maps seconds in original audio to envelope sample
 *
 * Parameters:
 *  audioSeconds (number): seconds in original audio
 *  audioSampleRate (number): original audio sample rate
 *  envelopeHopSize (number): hop size of envelope analysis window
 */
export const secondsToEnvelopeSample = (
  audioSeconds: number,
  audioSampleRate: number,
  envelopeHopSize: number
) => {
  return Math.floor((audioSeconds * audioSampleRate) / envelopeHopSize);
};

/*
 * Utility function that calculates a downsampling window size (for amplitude aggregation)
 * that depends on the editor PPS
 *
 * Parameters:
 *  sampleRate (number): original audio waveform sampling rate
 *  multiple (number): manipulate dynamic window size by this multiple
 *    0.5 means sample twice as often (smaller window), 2 means sample half as often (bigger window)
 */
export const calcDynamicWindowSize = (
  sampleRate: number,
  multiple: number = 1
) => {
  return Math.floor(multiple * (sampleRate / PIXELS_PER_SECOND));
};

/*
 * Takes in a raw buffer of audio, converts to amplitude buffer, downsamples (mean aggregation over frames), normalizes.
 *
 * Parameters:
 *  rawAudioBuffer (Float32Array)
 *  downsampleFrameLength (number): number of samples to bucket and aggregate when downsampling
 *  halfNormalize (boolean): move halfway between normalized point and actual point
 *  normalize (boolean): whether to apply normalization to the envelope
 *
 * Returns:
 *  envelope (Float32Array)
 */
const calcChannelEnvelope = (
  rawAudioBuffer: Float32Array,
  downsampleFrameLength: number,
  halfNormalize: boolean = false,
  normalize: boolean = false
): Float32Array => {
  const downsampledAmplitudeEnvelope = downsampleBufferAmplitude(
    rawAudioBuffer,
    downsampleFrameLength
  );
  if (halfNormalize) {
    return halfNormalizeAmplitudeBuffer(downsampledAmplitudeEnvelope);
  }
  if (normalize) {
    return normalizeAmplitudeBuffer(downsampledAmplitudeEnvelope);
  }
  return downsampledAmplitudeEnvelope;
};

/*
 * Audio buffer normalization
 *
 * Parameters:
 *  amplitudeBuffer (Float32Array): input buffer
 *
 * Returns normalized Float32Array
 */
export const normalizeAmplitudeBuffer = (
  amplitudeBuffer: Float32Array
): Float32Array => {
  let maxAmplitude = 0;
  for (let iSample = 0; iSample < amplitudeBuffer.length; iSample++) {
    const sampleAmplitude = Math.abs(amplitudeBuffer[iSample]);
    if (sampleAmplitude > maxAmplitude) maxAmplitude = sampleAmplitude;
  }
  const normalizedBuffer = new Float32Array(amplitudeBuffer.length);
  for (let iSample = 0; iSample < amplitudeBuffer.length; iSample++) {
    normalizedBuffer[iSample] =
      amplitudeBuffer[iSample] / (maxAmplitude === 0 ? 1 : maxAmplitude);
  }
  return normalizedBuffer;
};

/*
 * Audio buffer half normalization: normalize and split the difference
 *
 * Parameters:
 *  amplitudeBuffer (Float32Array): input buffer
 *
 * Returns half normalized Float32Array
 */
export const halfNormalizeAmplitudeBuffer = (
  amplitudeBuffer: Float32Array
): Float32Array => {
  let maxAmplitude = 0;
  for (let iSample = 0; iSample < amplitudeBuffer.length; iSample++) {
    const sampleAmplitude = Math.abs(amplitudeBuffer[iSample]);
    if (sampleAmplitude > maxAmplitude) maxAmplitude = sampleAmplitude;
  }
  const halfNormalizedBuffer = new Float32Array(amplitudeBuffer.length);
  for (let iSample = 0; iSample < amplitudeBuffer.length; iSample++) {
    const originalSample = amplitudeBuffer[iSample];
    const normalizedSample =
      amplitudeBuffer[iSample] / (maxAmplitude === 0 ? 1 : maxAmplitude);
    halfNormalizedBuffer[iSample] =
      originalSample + (normalizedSample - originalSample) / 2;
  }
  return halfNormalizedBuffer;
};

/*
 * Downsample buffer amplitude
 * windowing/bucketing samples and using aggregation (sample avg) across samples in bucket
 *
 * buffer (Float32Array): buffer to downsample
 * frameSize (number): number of samples in each frame to aggregate
 */
const downsampleBufferAmplitude = (
  inBuffer: Float32Array,
  frameSize: number
) => {
  const downsampledBufferSamples = Math.ceil(inBuffer.length / frameSize);
  const downsampledBuffer = new Float32Array(downsampledBufferSamples);

  // get average value in window
  for (let iFrame = 0; iFrame < downsampledBufferSamples; iFrame++) {
    // the location of the first sample frame in this pixel block
    const blockStart = iFrame * frameSize;
    let frameAmplitudeSum = 0;
    for (let j = 0; j < frameSize; j++) {
      // find the sum of all the sample frames in this pixel block
      frameAmplitudeSum += Math.abs(
        inBuffer[Math.min(blockStart + j, inBuffer.length - 1)]
      );
    }
    const frameAmplitudeAverage = frameAmplitudeSum / frameSize;
    downsampledBuffer[iFrame] = frameAmplitudeAverage;
  }

  return downsampledBuffer;
};
