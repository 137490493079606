import styled from "styled-components";

export const Container = styled.div`
  padding: ${({ padding }) => padding || "42px 64px"};
  background: ${props =>
    props.isBlue
      ? props.theme.highlight
      : props.backgroundColor
      ? props.backgroundColor
      : props.theme.backgroundLight};
  overflow-y: auto;
  max-height: ${({ maxHeight }) => maxHeight};
  border-radius: ${({ theme }) => theme.borderRadiusContainers};

  ::-webkit-scrollbar {
    width: 10px;
    opacity: 0;
  }

  ::-webkit-scrollbar-track {
    opacity: 0;
    width: 15px;
    background-color: ${props => props.theme.backgroundDark};
    border-radius: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.06);
    opacity: 0.6;
    border-radius: 12px;
  }
`;
