import React, { useContext, useState } from "react";
import Popover from "../Popover";
import { Counter } from "../Counter";
import NotificationList from "./NotificationList";
import { NotificationsContext } from "../../context";

const Notification = () => {
  const notificationContext = useContext(NotificationsContext);

  const [badgeActive, setBadgeActive] = useState(true);
  const [active, setActive] = useState(false);

  function toggleBadge() {
    setBadgeActive(!badgeActive);
  }
  const notificationIcon = (
    <div style={{ position: "relative" }} onClick={toggleBadge}>
      <img
        src={require("../../assets/notification.svg")}
        onClick={() => {
          notificationContext.clearUnreadCount();
        }}
        alt="notification"
      />
      {badgeActive && notificationContext.unread > 0 && (
        <Counter>
          <p className="p4b text"> {notificationContext.unread}</p>
        </Counter>
      )}
    </div>
  );

  return (
    <Popover
      top={"66px"}
      right={"-10px"}
      triggerComponent={notificationIcon}
      dropdown={<NotificationList setVisible={setActive} />}
      showDropdown={active}
    />
  );
};

export default Notification;
