/*

  This file contains the data for the sidebar filters.
  There are range, advanced range, bpm , duration
  and keys. 

  P.S  Tags and instruments are pulled from the backend.
  
*/

// array containing characteristics/range filters
const characteristics = [
  {
    key: "mood",
    filterType: "range",
    filterName: "Mood",
    min: 1,
    max: 5,
    description:
      "How the music makes people feel. A measure of how happy/optimistic or sad/pessimistic a track is overall.",
    high: "Happy",
    low: "Sad"
  },
  {
    key: "density",
    filterType: "range",
    filterName: "Density",
    min: 1,
    max: 5,
    description:
      "How many sounds and instruments are present in the music. Density looks at how many elements are present throughout.",
    high: "Dense",
    low: "Sparse"
  },
  {
    key: "energy",
    filterType: "range",
    filterName: "Energy",
    min: 1,
    max: 5,
    description:
      "Overall how much energy there is. Not just tempo or loudness, but how much intensity the music holds.",
    high: "High",
    low: "Low"
  },
  {
    key: "gravity",
    filterType: "range",
    filterName: "Gravity",
    min: 1,
    max: 5,
    description:
      "How seriously the music takes itself. From comic and lighthearted to sincere and self-important.",
    low: "Playful",
    high: "Dramatic"
  },
  {
    key: "ensemble",
    filterName: "Ensemble",
    min: 1,
    max: 5,
    filterType: "range",
    description:
      "What kinds of sounds and instruments are playing. From orchestral music or traditional  ensembles, to pure synthesizers and electronica.",
    low: "Acoustic",
    high: "Electric"
  }
];

// the same as characteristics array but these are
// advanced range filters. They are accessed by
// toggling the advanced switch.

const advancedCharacteristics = [
  {
    key: "melody",
    filterType: "range",
    filterName: "Melody",
    min: 1,
    max: 5,
    description:
      "How important is a central melody to the music, could you hum the song? From pure tones and textures to thematic melodies.",
    low: "Tonal",
    high: "Thematic"
  },
  {
    key: "tension",
    filterName: "Tension",
    filterType: "range",
    description:
      "How relaxed or tense the music is. From calm and peaceful to edgy and nervous.",
    min: 1,
    max: 5,
    low: "Calm",
    high: "Tension"
  },
  {
    key: "rhythm",
    filterType: "range",
    filterName: "Rhythm",
    min: 1,
    max: 5,
    high: "Driving",
    low: "Ambient",
    description:
      "How much of a beat the song has and how important rhythm is to the song. Not a measure of speed or intensity, just how strong the pulse is."
  }
];

// this is a collective name for bpm and duration
// called advanced because they are toggled by the advanced switch
const advancedCharacteristics2 = [
  {
    key: "bpm",
    filterName: "BPM",
    filterType: "range",
    min: 50,
    max: 200,
    minValue: 50,
    maxValue: 200
  },
  {
    key: "duration",
    filterName: "Duration",
    filterType: "range",
    min: 0,
    max: 360,
    minValue: 0,
    maxValue: 360
  }
];

// max and min values for bpm
const bpm = {
  min: advancedCharacteristics2[0].min,
  max: advancedCharacteristics2[0].max
};

// max and min values for duration
const duration = {
  min: advancedCharacteristics2[1].min,
  max: advancedCharacteristics2[1].max
};

// nothing bug keys here.
const keys = [
  "A",
  "Am",
  "B",
  "Bm",
  "C",
  "Cm",
  "D",
  "Dm",
  "E",
  "Em",
  "F",
  "Fm",
  "G",
  "Gm",
  "Ab",
  "Abm",
  "Bb",
  "Bbm",
  "Eb",
  "Ebm",
  "Db",
  "Dbm",
  "F#",
  "F#m"
];

// normalized data (obj/hash) for array of range filters.
// made for easy access
const rangeData = {};
[...characteristics, ...advancedCharacteristics].forEach(item => {
  rangeData[item.filterName.toLowerCase()] = item;
});

// here we export all the stuff above
export {
  characteristics,
  advancedCharacteristics,
  advancedCharacteristics2,
  keys,
  rangeData,
  bpm,
  duration
};
