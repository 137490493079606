import React, { lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "../permissions";

// lazy page loads
const FavoritesContainer = lazy(() =>
  import("../../pages/Private/Favorites/FavoritesContainer")
);
const SettingsContainer = lazy(() =>
  import("../../pages/Private/Settings/SettingsContainer")
);
const OrdersContainer = lazy(() =>
  import("../../pages/Private/Orders/OrdersContainer")
);
const ProjectContainer = lazy(() =>
  import("../../pages/Private/Project/ProjectContainer")
);
const Logout = lazy(() => import("../../pages/Shared/Logout"));
const DownloadHistoryContainer = lazy(() =>
  import("../../pages/Private/DownloadHistory/DownloadHistoryContainer")
);
const NotFound = lazy(() => import("../../components/404"));

/*
 * Add all routes specific to authenticated users navigating the main app here
 */
const RoutesUser = () => {
  return (
    <PrivateRoute>
      <Switch>
        <Route exact path="/favorites" component={FavoritesContainer} />
        <Route path="/settings" component={SettingsContainer} />
        <Route exact path="/orders" component={OrdersContainer} />
        <Route exact path="/project" component={ProjectContainer} />
        <Route exact path="/project/:id" component={ProjectContainer} />
        <Route exact path="/logout" component={Logout} />
        <Route
          exact
          path="/project/:id/downloads"
          component={DownloadHistoryContainer}
        />
        <Redirect exact from="/home" to="/project" />
        <Redirect from="/signup" to="/project" />
        <Route component={NotFound} />
      </Switch>
    </PrivateRoute>
  );
};

export default RoutesUser;
