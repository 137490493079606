import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";

export const Avatar = styled.div`
  width: ${props => props.size || "30px"};
  height: ${props => props.size || "30px"};
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.color};
`;

export const NotificationAvatar = styled.img`
  width: ${props => props.size || "30px"};
  height: ${props => props.size || "30px"};
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.color};

  .selected-icon {
    div {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }

  & svg {
    width: 60%;
    height: 60%;
    margin-left: 5px;
    margin-top: 4px;
  }

  & svg path {
    fill: white;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
  background: ${({ background }) => background};
`;

const AvatarTooltipName = ({
  name = "",
  size = 24,
  className,
  profileColor = "#607C99",
  profileIcon = "27.svg"
}) => {
  return (
    <Tooltip placement="top" title={name} overlayClassName="avatar-tooltip">
      <Container className={className} background={profileColor} size={size}>
        {profileIcon && (
          <img
            style={{ width: "100%" }}
            className="avatar-svg"
            src={require(`../../assets/profile_icons_new/${profileIcon}`)}
            alt="icon"
          />
        )}
      </Container>
    </Tooltip>
  );
};

export default AvatarTooltipName;
