import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import * as React from "react";
import { formatDecimalAsPercent } from "../../util/text_formatting";
import { Container } from "./styles";
import { theme } from "../../../globalStyles";
import { dbToVal } from "../LaneVolumeSlider";

export function VolumeSlider({
  onChange,
  onAfterChange,
  currentVolume,
  volumeIndicators = { currentPeak: 0, recentPeak: 0 }
}) {
  const sliderVal = dbToVal(currentVolume);

  const HEIGHT = 20;
  const WIDTH = HEIGHT;
  return (
    <div
      style={{
        height: HEIGHT,
        position: "relative",
        width: 120
      }}
    >
      <Container>
        <div
          className="volume-level"
          style={{
            width: formatDecimalAsPercent(volumeIndicators.currentPeak)
          }}
        />
        <div
          className="volume-max"
          style={{
            width: formatDecimalAsPercent(volumeIndicators.recentPeak)
          }}
        />
      </Container>
      <Slider
        min={0}
        max={100}
        defaultValue={sliderVal * 100}
        onAfterChange={onAfterChange}
        onChange={onChange}
        railStyle={{
          height: HEIGHT,
          marginTop: -5, // this is to get rails aligned
          opacity: 0
        }}
        trackStyle={{
          height: HEIGHT,
          marginTop: -5,
          opacity: 0
        }}
        handleStyle={{
          height: HEIGHT,
          width: WIDTH,
          backgroundColor: theme.editor.textMedium
        }}
      />
    </div>
  );
}
