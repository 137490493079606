import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MainStore } from "../store";
import { dbToLinear } from "../util/extra_math";
import { AudioMaster } from "./AudioMaster";
import { LaneNode } from "./LaneNode";
import { max_amplitude } from "../util/array_helpers";
import {
  SET_MASTER_VOLUME_INDICATOR_VALUES,
  SetMasterVolumeIndicatorvaluesAction
} from "../actions/actionTypes";
import { MetronomeNode } from "./MetronomeNode";
import { AuthContext } from "../../context/AuthProvider";
import { MasteringChain } from "./MasteringChain";
import MixingBoardProvider from "../context/EditorContext/MixingBoardProvider";

export interface MasterNodeProps {
  audioMaster: AudioMaster;
}

export function MasterNode(props: MasterNodeProps) {
  const laneIds = useSelector<MainStore, string[]>(
    state => state.project.lanes.laneIds
  );
  const { user } = useContext(AuthContext);

  // eslint-disable-next-line
  const [stateGainNode, setStateGainNode] = useState(() => {
    const masterGainNode = props.audioMaster.context.createGain();
    const analyzer = props.audioMaster.context.createAnalyser();

    const masteringChain = new MasteringChain(props.audioMaster.context);
    masteringChain.connect(masterGainNode);

    masterGainNode.connect(analyzer);
    masterGainNode.connect(props.audioMaster.context.destination);
    return {
      masteringChain,
      masterGainNode,
      analyzer
    };
  });

  const [statePollInterval, setStatePollInterval] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const analyzer = stateGainNode.analyzer;

    if (statePollInterval) {
      clearInterval(statePollInterval);
    }

    let recentPeak = 0;
    const smoothingRate = 0.9;
    const interval = setInterval(() => {
      const data = new Float32Array(analyzer.fftSize);
      analyzer.getFloatTimeDomainData(data);
      const maxAmp = max_amplitude(data);

      if (maxAmp > recentPeak) {
        recentPeak = maxAmp;
      } else {
        recentPeak *= smoothingRate;
      }

      dispatch<SetMasterVolumeIndicatorvaluesAction>({
        type: SET_MASTER_VOLUME_INDICATOR_VALUES,
        currentPeak: maxAmp,
        recentPeak: recentPeak
      });
    }, 100);
    setStatePollInterval(interval);

    return () => {
      clearInterval(interval);
      clearInterval(statePollInterval);
      stateGainNode.masteringChain.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  const volume = useSelector<MainStore, number>(
    state => state.uiState.masterVolume
  );
  const gainCompensation = useSelector<MainStore, number>(
    state => state.project.initialTrackData.gain
  );
  const isMuted = useSelector<MainStore, boolean>(
    state => state.uiState.masterMute
  );

  let finalVolume = isMuted ? 0 : dbToLinear(volume + gainCompensation);
  stateGainNode.masterGainNode.gain.value = finalVolume;

  return (
    <MixingBoardProvider
      outputNode={stateGainNode.masteringChain}
      audioMaster={props.audioMaster}
    >
      {laneIds.map(id => (
        <LaneNode
          key={id}
          outputNode={stateGainNode.masteringChain}
          audioMaster={props.audioMaster}
          laneId={id}
        />
      ))}
      {user.adminUser && !props.audioMaster.isOffline && (
        <MetronomeNode
          outputNode={stateGainNode.masteringChain}
          audioMaster={props.audioMaster}
        />
      )}
    </MixingBoardProvider>
  );
}
