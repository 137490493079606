import styled from "styled-components";
import ReactSVG from "react-svg";

export const Container = styled.div`
  #editor-popover-container {
    background: ${({ theme }) => theme.editor.backgroundDark};
    border-radius: ${({ theme }) => theme.editor.containerRadius};
    box-shadow: ${({ theme }) => theme.boxShadowBottom};
    transition: width 0.4s ease-in-out;

    .free-tier-ctas {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 12px;

      button {
        padding: 0 20px;
        margin-top: 12px;
        color: ${({ theme }) => theme.textLight};
        transition: all 0.3s ease-in-out;
        transition-property: transform, box-shadow;
      }

      button:hover {
        cursor: pointer;
        transform: scale(1.05);
        box-shadow: 2px 2px rgb(0, 0, 0, 0.2);
      }
    }

    .btn-subscribe {
      background-color: ${({ theme }) => theme.subscribe};
    }

    .btn-editor-demo {
      background-color: ${({ theme }) => theme.editor.backgroundLight};
    }

    .stems-emblem {
      width: 100%;
    }

    .stems-emblem > *:last-child {
      margin-top: 8px;
    }
  }

  #editor-popover-container.sm {
    width: 200px;
    padding: 12px;
  }

  #editor-popover-container.lg {
    width: 300px;
    padding: 18px;
  }

  #editor-popover-title {
    color: ${({ theme }) => theme.textLight};
    font-family: ${({ theme }) => theme.fontHeavy};

    .editor-new-text {
      margin-left: 8px;
      font-family: ${({ theme }) => theme.fontMedium};
      color: ${({ theme }) => theme.highlight};
    }
  }

  #editor-popover-content {
    margin-top: 8px;
    color: ${({ theme }) => theme.grey2};
    font-family: ${({ theme }) => theme.fontMedium};
    opacity: 0;
    transition: opacity 1.5s ease-in-out;
  }

  #editor-popover-content.visible {
    opacity: 100%;
  }

  #editor-popover-content:empty {
    margin-top: 0;
  }
`;

export const EditorButton = styled.div`
  cursor: pointer;

  &&.disabled {
    cursor: not-allowed;
  }
`;

export const StyledReactSVG = styled(ReactSVG)`
  display: block;

  &.action-icon > span {
    display: flex;
  }

  &.action-icon svg {
    width: ${props => props.size};
    height: ${props => props.size};
  }

  &.action-icon svg path {
    fill: ${props => props.fill};
  }
`;
