import React from "react";
import { Button } from "../Button";
import { ProjectLicenseContainer } from "./styles";

const ProjectLicense = ({ onBtnClick }) => {
  return (
    <ProjectLicenseContainer>
      <h3>Project License</h3>
      <p>
        You can purchase a one-time commercial license for projects like films,
        games and audiobooks by adding this track to your cart. The license
        includes all full quality files, stems, and access to our online editor
        for the track. &nbsp;
      </p>
      <Button className="add-to-cart-button" onClick={() => onBtnClick()}>
        Add To Cart
      </Button>
    </ProjectLicenseContainer>
  );
};

export default ProjectLicense;
