import styled from "styled-components";

export const IconButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "transparent"};
  border: 0;
  cursor: pointer;
  padding: 0;
  font-size: ${props => (props.fontSize ? props.fontSize : "24px")};
  width: ${({ width }) => (width ? width : "auto")};

  &:focus {
    outline: none;
  }

  path {
    fill: ${({ theme, fillColor }) =>
      fillColor ? fillColor : theme.editor.textLight};
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.editor.textGrey};
    }
  }

  svg {
    width: ${({ svgWidth }) => (svgWidth ? svgWidth : "auto")};
    height: ${({ svgHeight }) => (svgHeight ? svgHeight : "auto")};
  }
`;
