import React, { useContext, useEffect } from "react";
import { ListContainer } from "./styles";
import NotificationCard from "./NotificationCard";
import { NotificationsContext } from "../../context";
import { EditorContext } from "../../editor/context/EditorContext/EditorProvider";

const NotificationList = props => {
  const { notifications, markAllAsRead, readNotification } = useContext(
    NotificationsContext
  );
  const editor = useContext(EditorContext);

  useEffect(() => {
    const customMixNotifications = document.getElementsByClassName(
      "notification-editor-custommix"
    );
    for (let notificationEl of customMixNotifications) {
      notificationEl.onclick = () => {
        const idArr = notificationEl.id.split("_");
        const idVersionPair = idArr[idArr.length - 1];
        const customMixId = idVersionPair.split(":")[0];
        const versionNumber = idVersionPair.split(":")[1];
        editor.requestCustomMixIdOpen(customMixId, versionNumber, () => {
          props.setVisible(false);
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, props.setVisible]);

  return (
    <ListContainer>
      <div className="header">
        <p className="p2b">Activity</p>{" "}
        <div className="read-all">
          <p className="p4m" onClick={() => markAllAsRead(notifications)}>
            Mark all as read
          </p>
        </div>
      </div>

      {notifications &&
        notifications.map((item, index) => (
          <NotificationCard
            key={index}
            onClick={() => {
              readNotification(notifications, item.id);
            }}
            notification={item}
            index={index}
          />
        ))}
      {notifications.length === 0 && (
        <div className="no-notifications">
          <p> No new notifications</p>{" "}
        </div>
      )}
    </ListContainer>
  );
};

export default NotificationList;
