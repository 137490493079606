import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { Container, CartItem } from "./styles";
import { CartContext } from "../../context/CartContext/CartProvider";
import removeIcon from "../../assets/remove.svg";
import FlatButton from "../FlatButton";
import { Button } from "../Button";

const CartDropdown = ({ history, toggleDropdown }) => {
  const { cartItems, removeCartItem } = useContext(CartContext);

  const goToTrack = id => {
    history.push(`/browse/track/${id}`);
    toggleDropdown();
  };
  return (
    <Container>
      {cartItems.length > 0 ? (
        cartItems.map((item, index) => (
          <CartItem key={index}>
            <FlatButton onClick={() => goToTrack(item.id)}>
              <div className="track-container">
                <img
                  className="album-art image-border-radius"
                  src={
                    item.type === "Album"
                      ? `${process.env.REACT_APP_FILES_URL}${item.thumbnail}`
                      : item.thumbnail
                  }
                  alt="album-art"
                />
                <div className="track-detail">
                  <h4>{`${item.name}${
                    item.type === "Album" ? " (Album)" : ""
                  }`}</h4>
                  <p>
                    {item.type === "Album"
                      ? item.tracks.length + " tracks"
                      : item.artist}
                  </p>
                </div>
              </div>
            </FlatButton>
            <img
              onClick={() => removeCartItem(item)}
              src={removeIcon}
              alt="remove"
              className="remove-icon"
            />
          </CartItem>
        ))
      ) : (
        <div className="empty-cart">
          <p>Cart is empty </p>
        </div>
      )}

      {cartItems.length > 0 && (
        <Button
          className="checkout-button"
          width={110}
          onClick={() => history.push("/order")}
        >
          Checkout
        </Button>
      )}
    </Container>
  );
};

export default withRouter(CartDropdown);
