import React from "react";
import { Slider, Handles, Tracks, Rail } from "react-compound-slider";
import { Handle, Track, SliderRail } from "./components";
import { Container } from "./styles";

/*
 * A simple one handle slider styled to conform to blue dot design guidelines
 */
const SimpleSlider = ({
  value,
  min,
  max,
  onChange,
  memoryValue = null,
  disabled = false
}) => {
  return (
    <Container>
      <Slider
        mode={1}
        step={1}
        domain={[min, max]}
        rootStyle={{ position: "relative", width: "100%" }}
        onSlideEnd={onChange}
        values={value}
        disabled={disabled}
      >
        <Rail>
          {({ getRailProps }) => (
            <SliderRail getRailProps={getRailProps} disabled={disabled} />
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={[min, max]}
                  disabled={disabled}
                  getHandleProps={getHandleProps}
                />
              ))}
              {memoryValue !== null && (
                <Handle
                  handle={{
                    id: "memory-handle",
                    value: memoryValue,
                    percent: Math.floor(
                      ((memoryValue - min) / (max - min)) * 100
                    )
                  }}
                  domain={[min, max]}
                  disabled={true}
                  memory={true}
                  getHandleProps={getHandleProps}
                />
              )}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </Container>
  );
};

export default SimpleSlider;
