import styled from "styled-components";
import { filterBottom } from "../SearchFilters/styles";

export const Container = styled.div`
  background: ${({ isMobile, theme }) =>
    isMobile ? theme.backgroundDark : theme.backgroundLight};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 6px 15px;
  border-radius: ${props => props.theme.borderRadius};
  height: 24px;
  border-top-right-radius: ${({ isAggregate }) => isAggregate && "0px"};
  border-bottom-right-radius: ${({ isAggregate }) => isAggregate && "0px"};
  position: relative;

  margin-bottom: ${filterBottom};

  .close-icon {
    width: 10px;
    margin-bottom: 1px;
    cursor: pointer;
    color: white;
    margin-right: 12px;
  }

  .agr-name {
    font-family: ${props => props.theme.p3b.ff};
    margin: 0px 20px;
    margin-right: ${props => props.isAggregate && "0px"};
  }
`;

export const Icon = styled.img`
  height: 14px;
  width: 14px;
`;
