import { Chorus } from "./Chorus";
import { PitchShifter } from "./PitchShifter";
import { StereoDelay } from "./StereoDelay";
import { Tremolo } from "./Tremolo";
import { WahWah } from "./WahWah";

// Add new custom fx here to add to factory plan
const customFXMap = {
  StereoDelay: StereoDelay,
  Tremolo: Tremolo,
  WahWah: WahWah,
  Chorus: Chorus,
  PitchShifter: PitchShifter
};
type customFXKeys = keyof typeof customFXMap;
type customFXTypes = typeof customFXMap[customFXKeys];

/*
 *  Factory design pattern for constructing custom fx from strings
 */
class CustomFXFactory {
  static getCustomFX(k: string): customFXTypes {
    return customFXMap[k];
  }
}

export default CustomFXFactory;
