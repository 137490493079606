import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ZOOM_CHANGE, ZoomChangeAction } from "../../actions/actionTypes";
import { MainStore } from "../../store";
import { Container, SliderRailingContainer, ZoomIcon } from "./styles";
import { getZoomProportion } from "../../util/zoom_levels";
import { seconds_to_pixels } from "../../util/time_position_conversion";
import { theme } from "../../../globalStyles";
import { Zoom } from "../../../assets/Zoom";
import { Popover } from "antd";
import { EditorContext } from "../../context/EditorContext/EditorProvider";
import { bpm_to_seconds_per_measure } from "../../util/quantization";

export const MIN_ZOOM_LEVEL = 1;
export const MAX_ZOOM_LEVEL = 5;

export function ZoomControls() {
  const [zoomSliderVisible, setZoomSliderVisible] = useState(false);

  const dispatch = useDispatch();
  const zoomLevel = useSelector<MainStore, number>(
    state => state.uiState.zoomLevel
  );

  const scrollX = useSelector<MainStore, number>(
    state => state.uiState.projectScrollX
  );
  const trackDurationSeconds = useSelector<MainStore, number>(
    state => state.project.customMixInfo.length
  );
  const bpm = useSelector<MainStore, number>(
    state => state.project.initialTrackData.bpm
  );
  const beatsPerMeasure = useSelector<MainStore, number>(
    state => state.project.initialTrackData.beatsPerMeasure
  );
  const measureTimeSeconds = bpm_to_seconds_per_measure(bpm, beatsPerMeasure);
  const zoomProportion = getZoomProportion(zoomLevel);

  const editor = useContext(EditorContext);

  const onChange = newZoomLevel => {
    setZoomLevel(newZoomLevel);
  };

  /*
   * Performing zoom while maintaining scroll position gets tricky
   * particularly when a zoom out happens and the current pixel scroll position
   * would overflow the container and clamps to the end scroll position instead
   * of maintaining proportion. Ex., you're scrolled 60% through the viewport,
   * then zoom out, it would quickly flash the content at the end of the viewport,
   * then snap to 60% scroll in the viewport making for poor UX. The algorithm here
   * is to manually elongate or shrink the viewport content and perform the
   * proportional scroll prior to zooming. So basically, we're performing the scroll
   * action before the zoom, making sure the correct container scrollLeft is set to
   * the correct position such that when the zoom occurs it's in the correct place
   * already.
   */
  function setZoomLevel(newZoomLevel: number) {
    // handle case where zoom rc-slider is focused and user switches tab and comes back
    if (newZoomLevel === 0) return;

    const clampedNewZoomLevel = Math.max(
      MIN_ZOOM_LEVEL,
      Math.min(MAX_ZOOM_LEVEL, newZoomLevel)
    );

    const lanesAreaElement = document.getElementById("lanes-area-container");
    const gridContainerElement = document.getElementById("grid-container");
    const scrollLeft = lanesAreaElement.scrollLeft;

    // calculate scrollable areas and position at current zoom level
    const lanesAreaWidth = lanesAreaElement.clientWidth;
    const pixelWidth = Math.ceil(
      seconds_to_pixels(trackDurationSeconds) * zoomProportion
    );
    //const scrollablePixels = Math.ceil(pixelWidth - lanesAreaWidth);
    const pixelCenterLanesArea = Math.min(
      scrollX + lanesAreaWidth / 2,
      pixelWidth
    );
    const centerXProportion = pixelCenterLanesArea / pixelWidth;

    // calculate scrollable areas and position at new zoom level
    const newZoomProportion = getZoomProportion(clampedNewZoomLevel);
    const newPixelWidth = Math.ceil(
      seconds_to_pixels(trackDurationSeconds + 2 * measureTimeSeconds) *
        newZoomProportion
    );
    const newScrollablePixels = Math.ceil(newPixelWidth - lanesAreaWidth);
    const newPixelCenterLanesArea = centerXProportion * newPixelWidth;
    const newScrollLeftPosition = Math.min(
      newPixelCenterLanesArea - lanesAreaWidth / 2,
      newPixelWidth - lanesAreaWidth
    );

    if (scrollLeft > newScrollablePixels) {
      // scroll position after zoom would overflow container
      // it would auto snap to end of scroll view instead of maintaining current centered view
      // first scroll to correct scroll proportion then shrink width
      lanesAreaElement.scrollLeft = Math.ceil(newScrollLeftPosition);
      gridContainerElement.style.width = `${newPixelWidth}px`;
    } else {
      // scrollLeft <= newScrollablePixels: this can occur on zoom in
      gridContainerElement.style.width = `${newPixelWidth}px`;
      lanesAreaElement.scrollLeft = Math.ceil(newScrollLeftPosition);
    }

    dispatch<ZoomChangeAction>({
      type: ZOOM_CHANGE,
      newZoomLevel: clampedNewZoomLevel
    });
  }

  /*
   * Zoom in the editor by a specific amount in [0,5]
   *
   * Parameters:
   *  increment (number): amount to zoom in
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const zoomIn = (increment = 0.5) => {
    setZoomLevel(zoomLevel + increment);
  };

  /*
   * Zoom out the editor by a specific amount in [0,5]
   *
   * Parameters:
   *  decrement (number): amount to zoom out
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const zoomOut = (decrement = 0.5) => {
    setZoomLevel(zoomLevel - decrement);
  };

  // recalculates horizontal scrollbar bounds on load, and when track duration changes
  useEffect(() => {
    setZoomLevel(zoomLevel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackDurationSeconds]);

  const handleKeyDown = useCallback(
    e => {
      if (["text", "number", "textarea"].includes(e.target.type)) return;

      if (e.metaKey || e.ctrlKey) {
        // ctrl -
        if (e.key === "-") setZoomLevel(zoomLevel - 0.5);
        // ctrl +
        else if (e.key === "=" || e.key === "+") setZoomLevel(zoomLevel + 0.5);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [zoomLevel]
  );

  // create a mutable ref to handler that gets refreshed with up-to-date redux store every rerender
  // this is necessary because of useEffect dependencies & redux
  // if you add songTime to useEffect dependencies the event listeners get added & removed way too often
  const handleKeyDownRef = useRef(handleKeyDown);
  useEffect(() => {
    handleKeyDownRef.current = handleKeyDown;
  }, [handleKeyDown]);

  useEffect(() => {
    const keyDownHandler = e => handleKeyDownRef.current(e);
    if (editor.drawerState.visible) {
      document.addEventListener("keydown", keyDownHandler);
    } else {
      document.removeEventListener("keydown", keyDownHandler);
    }
    return () => document.removeEventListener("keydown", keyDownHandler);
  }, [editor.drawerState.visible]);

  const HEIGHT = 20;
  const WIDTH = HEIGHT;
  if (editor.tightLayout) {
    return (
      <Popover
        placement="bottomRight"
        getPopupContainer={trigger => trigger}
        content={
          <div
            style={{
              height: HEIGHT,
              position: "relative",
              width: 190
            }}
          >
            <SliderRailingContainer></SliderRailingContainer>
            <Slider
              min={MIN_ZOOM_LEVEL}
              max={MAX_ZOOM_LEVEL}
              value={zoomLevel}
              step={0.01}
              onChange={onChange}
              railStyle={{
                height: HEIGHT,
                marginTop: -5, // this is to get rails aligned
                opacity: 0
              }}
              trackStyle={{
                height: HEIGHT,
                marginTop: -5,
                opacity: 0
              }}
              handleStyle={{
                height: HEIGHT,
                width: WIDTH,
                backgroundColor: theme.editor.textMedium
              }}
            />
          </div>
        }
      >
        <Container className="zoom-button tight">
          <ZoomIcon className="zoom-icon">
            <Zoom />
          </ZoomIcon>
        </Container>
      </Popover>
    );
  } else {
    return (
      <Container
        onMouseOver={e => setZoomSliderVisible(true)}
        onMouseOut={e => setZoomSliderVisible(false)}
        className={`thicc ${zoomSliderVisible ? "active" : null}`}
      >
        <ZoomIcon className="zoom-icon">
          <Zoom />
        </ZoomIcon>
        <div
          style={{
            height: HEIGHT,
            position: "relative",
            width: 181,
            marginLeft: 18
          }}
        >
          <SliderRailingContainer></SliderRailingContainer>
          <Slider
            min={MIN_ZOOM_LEVEL}
            max={MAX_ZOOM_LEVEL}
            value={zoomLevel}
            step={0.01}
            onChange={onChange}
            railStyle={{
              height: HEIGHT,
              marginTop: -5, // this is to get rails aligned
              opacity: 0
            }}
            trackStyle={{
              height: HEIGHT,
              marginTop: -5,
              opacity: 0
            }}
            handleStyle={{
              height: HEIGHT,
              width: WIDTH,
              backgroundColor: theme.editor.textMedium
            }}
          />
        </div>
      </Container>
    );
  }
}
