import styled from "styled-components";

export const ProjectLicenseContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadiusContainers};
  padding: 0 ${({ theme }) => theme.paddingContainersS};

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.paddingS};
  }

  .add-to-cart-button {
    background-color: ${({ theme }) => theme.textLight};
    color: ${({ theme }) => theme.backgroundDark};
    margin-left: auto;
  }
`;
