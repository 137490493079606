import styled from "styled-components";
import ReactSVG from "react-svg";

export const DesktopContainer = styled.div`
  position: fixed;
  width: 220px;
  overflow-y: auto;
  height: calc(100vh - 210px);
  padding-bottom: 30px;
  margin-bottom: 200px;

  ::-webkit-scrollbar {
    width: 8px;
    opacity: 0;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    opacity: 0;
    width: 15px;
    background-color: ${props => props.theme.backgroundDark};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.06);
    opacity: 0.6;
    border-radius: 2px;
  }
`;

export const Container = styled.div`
  .filters {
    margin-bottom: 60px;
    align-self: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-bottom: 16px;
      font-size: ${props => props.theme.p2b.fs};
      font-family: ${props => props.theme.p2b.ff};
      width: 67px;
    }

    .clear-all-button {
      margin-bottom: 16px;
    }

    .advanced-search {
      display: flex;
      margin-top: 27px;

      p {
        margin-left: 8px;
      }
    }
  }

  .selectable-container {
    width: 100%;
    cursor: pointer;
    position: relative;
    /* margin-left: -12px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-item {
    width: 93px;
    cursor: pointer;
  }

  .adv-toggle-text {
    color: ${props =>
      props.advancedSearchOn ? props.theme.textLight : props.theme.grey2};
  }

  .filter-switch::after {
    background-color: ${props =>
      props.advancedSearchOn ? props.theme.textLight : props.theme.grey2};
  }
`;

export const MobileContainer = styled.div`
  .filters {
    align-items: flex-start;
  }
  .selectable-container-mobile {
    justify-content: flex-start;
    margin-left: 0px;
  }

  .tag-container {
    background-color: ${props => props.theme.backgroundDark};
    width: 100%;
    max-width: 500px;
    padding: 18px 0px 48px 0px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    grid-row-gap: 21px;
    grid-template-rows: 26px;
    grid-auto-rows: 26px;
    justify-content: center;
    display: grid;

    /* @media (max-width: 385px) {
      grid-template-columns: 1fr;
      max-width: 140px;
    } */
  }
`;

export const PopoverList = styled.div`
  width: 330px;

  background: ${props => props.theme.backgroundDark};
  position: absolute;
  left: 200px;
  top: ${props => (props.small ? "-20%" : "-950%")};
  margin-bottom: 150px;
  max-height: 90vh;
  overflow-y: auto;
  grid-auto-flow: dense;
  /* z-index: 100; */

  ::-webkit-scrollbar {
    width: 8px;
    opacity: 0;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    opacity: 0;
    width: 15px;
    background-color: ${props => props.theme.backgroundDark};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.06);
    opacity: 0.6;
    border-radius: 2px;
  }
`;

export const SelectableItem = styled.div`
  width: 124px;
  display: flex;
  justify-content: center;
  height: 46px;
  align-items: center;

  /* .menu-icon {
    margin-left: auto;
  } */
  .item-name {
    text-align: center;
    white-space: nowrap;
    margin-left: auto;

    color: ${props =>
      props.active || props.open
        ? props.theme.textLight
        : props.theme.textDark};
    font-family: ${props => props.theme.p3b.ff};
  }
`;

export const StyledReactSVG = styled(ReactSVG)`
  width: 20px;
  height: 20px;
  margin-left: ${props => (props.open ? "0px" : "12px")};
  margin-top: ${props => (props.open ? "2px" : "-2px")};
  margin-right: ${props => (props.open ? "15px" : "0px  ")}
  transform: ${props => (props.open ? "rotate(180deg)" : "")};
  transition: all 0.2s ease;
  &.menu-icon svg path {
    fill: ${props =>
      props.active === "true" || props.open
        ? props.theme.textLight
        : props.theme.textGrey};
  }
`;

export const StyledReactSVGMobile = styled(ReactSVG)`
  width: 20px;
  height: 20px;
  margin-left: 12px;
  margin-top: ${props => (props.open ? "-12px" : "12px")};
  transform: ${props => (props.open ? "rotate(270deg)" : "rotate(90deg)")};
  transition: all 0.1s ease;
  &.menu-icon svg path {
    fill: ${props =>
      props.active === "true" ? props.theme.textLight : props.theme.textGrey};
  }
`;
