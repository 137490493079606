import styled, { keyframes } from "styled-components";

const messageAnimation = keyframes`
  0%{
    transform:translateX(300px)
  }
  100%{
    transform:translateX(0px)
  }
`;

export const Container = styled.div`
  max-width: 450px;
  font-size: 18px;
  position: fixed;
  padding: 24px 12px;
  top: 120px;
  right: 70px;
  background: ${props => props.theme.backgroundDark};
  color: #fff;
  z-index: 100000;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.25); */
  animation: ${messageAnimation} 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  cursor: ${props => (props.clickable ? "pointer" : "default")};

  .message-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .title-container {
      /* align-items:center; */
      flex: 1;
      justify-content: space-between;
      margin-left: 24px;
      display: flex;
    }

    .title {
      width: 100%;
      font-family: ${props => props.theme.p2m.ff};
      font-size: ${props => props.theme.p2m.fs};
      margin-left: 24px;
      line-height: 25px;
    }
  }

  .close {
    margin-top: 8px;
    cursor: pointer;
    width: 22px;
    height: 14px;
    margin-right: 16px;
    margin-left: 12px;
  }
`;
