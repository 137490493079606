import styled from "styled-components";
import { Select } from "antd";

export const Container = styled.div`
  .bulk-reindex {
    margin: 54px;

    h1 {
      margin-bottom: 22px;
    }

    p {
      margin-bottom: 12px;
      font-family: ${props => props.theme.p3m.ff};
      font-size: ${props => props.theme.p3m.fs};
      letter-spacing: 1px;
      color: ${props => props.theme.textLight};
    }

    form {
      width: 40%;
      margin: 20px 20px;

      .form-row {
        display: flex;
        align-items: center;
        margin: 20px 0;

        .form-row-label {
          width: 69px;
          color: ${props => props.theme.textLight};
        }

        .form-row-input {
          width: 100%;
        }
      }
    }

    div.ant-alert {
      width: 60%;
      margin-top: 20px;
    }

    .reindex-btn {
      margin-left: -12px;
    }
  }
`;

export const IndexSelect = styled(Select)`
  background-color: ${({ theme }) => theme.editor.backgroundLight};
  color: ${({ theme }) => theme.editor.textLight};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontMedium};
`;
