import * as React from "react";
import { theme } from "../globalStyles";

export const TriangleDown = () => (
  <svg
    className="triangle-down"
    viewBox="0 0 9 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.39708 6.54028L0.10141 0.00967026L8.69275 0.00967026L4.39708 6.54028Z"
      fill={theme.editor.iconLight}
    />
  </svg>
);
