import { Reducer } from "redux";
import {
  CHANGE_CUSTOM_MIX_PROJECT,
  ChangeCustomMixProject,
  UPDATE_CUSTOM_MIX_META,
  UpdateCustomMixMetaAction,
  UPDATE_PROJECT_TITLE,
  UpdateProjectTitle,
  UpdateCustomMixVersionAction,
  UPDATE_CUSTOM_MIX_VERSION,
  UPDATE_MIX_DATA_HASH,
  UpdateMixDataHashAction
} from "../actions/actionTypes";
import { CustomMixInfo, CustomMixVersion, PublicUser } from "../store";

export const initialCustomMixVersion: CustomMixVersion = {
  id: null,
  versionNumber: 0,
  editorDataDumpHash: null,
  createdAt: new Date(),
  updatedAt: new Date()
};

export const initialPublicUser: PublicUser = {
  id: null,
  fullName: "",
  profileColor: "",
  profileIcon: ""
};

export const initialCustomMixInfo: CustomMixInfo = {
  id: null,
  projectId: null,
  trackId: null,
  title: "",
  length: 100,
  numVersions: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  user: initialPublicUser,
  version: initialCustomMixVersion,
  currentDataHash: null
};

export const projectInfoReducer: Reducer<
  CustomMixInfo,
  | UpdateCustomMixMetaAction
  | UpdateCustomMixVersionAction
  | UpdateProjectTitle
  | ChangeCustomMixProject
  | UpdateMixDataHashAction
> = (state = initialCustomMixInfo, action) => {
  switch (action.type) {
    case UPDATE_CUSTOM_MIX_META:
      return {
        ...state,
        id: action.id,
        projectId: action.projectId,
        trackId: action.trackId,
        title: action.title,
        length: action.length,
        numVersions: action.numVersions
      };
    case UPDATE_CUSTOM_MIX_VERSION:
      return {
        ...state,
        version: {
          id: action.id,
          versionNumber: action.versionNumber,
          editorDataDumpHash: action.editorDataDumpHash,
          createdAt: action.createdAt,
          updatedAt: action.updatedAt
        }
      };
    case UPDATE_MIX_DATA_HASH:
      return {
        ...state,
        currentDataHash: action.editorDataDumpHash
      };
    case UPDATE_PROJECT_TITLE:
      return {
        ...state,
        title: action.title
      };
    case CHANGE_CUSTOM_MIX_PROJECT:
      return {
        ...state,
        projectId: action.projectId
      };
    default:
      return state;
  }
};
