import { useState } from "react";

/*
 *  Hook that sets up modal state and helper functions to control
 *  state of modal
 */
const useModal = () => {
  const [visible, setVisible] = useState(false);

  const toggleTo = state => setVisible(state);

  const close = () => setVisible(false);
  const open = () => setVisible(true);

  return { visible, close, open, toggleTo };
};

export default useModal;
