import React, { useContext, useState, useEffect } from "react";
import { Input, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  UPDATE_PROJECT_TITLE,
  UpdateProjectTitle
} from "../../actions/actionTypes";
import { CustomMixInfo, InitialTrackData, MainStore } from "../../store";
import { Container, FlexRow } from "./styles";
import { AuthContext } from "../../../context/AuthProvider";
import AntModal from "../../../components/AntModal";
import { Button } from "../Button";
import { lightInputStyle } from "../../../globalStyles";
import useModal from "../../../hooks/useModal";
import CreateProjectModal from "../../../components/CreateProjectModal";
import { saveCustomMixAction } from "../../actions/actionCreators";
import { CustomMixPicker } from "../CustomMixPicker";
import { EditorContext } from "../../context/EditorContext/EditorProvider";

export const SaveModal = ({ modalState }) => {
  const user = useContext(AuthContext);
  const editor = useContext(EditorContext);

  const dispatch = useDispatch();
  const createProjectModal = useModal();

  const customMixInfo = useSelector<MainStore, CustomMixInfo>(
    state => state.project.customMixInfo
  );
  const initialTrack = useSelector<MainStore, InitialTrackData>(
    state => state.project.initialTrackData
  );

  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedCustomMix, setSelectedCustomMix] = useState(null);
  const [customMixPickerData, setCustomMixPickerData] = useState([]);
  const [saveWarningMessage, setSaveWarningMessage] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const OVERWRITE_WARNING_MESSAGE =
    "This will overwrite the selected custom mix.";
  const OVERWRITE_PERMISSIONS_WARNING_MESSAGE =
    "You are not the owner of the selected custom mix and therefore it can't be overwritten. Please select a unique name for your custom mix.";
  const PROJECT_LICENSING_MESSAGE = (
    <>
      <div className="message">
        The selected project does not have a subscription or license for this
        track and therefore your custom mix can't be saved to this project.
      </div>
      <Link to={"/subscription"} target="_blank">
        <Button className="btn-subscribe">Subscribe Now</Button>
      </Link>
    </>
  );

  useEffect(() => {
    if (
      selectedProject &&
      !user.user.adminUser &&
      !user.user.editorAlpha &&
      !user.canAccessTrackEditor(initialTrack, selectedProject.key).granted
    ) {
      setSaveWarningMessage(PROJECT_LICENSING_MESSAGE);
      setSaveDisabled(true);
    } else {
      setSaveWarningMessage(null);
      setSaveDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, initialTrack, user]);

  useEffect(() => {
    if (selectedCustomMix !== null) {
      dispatch<UpdateProjectTitle>({
        title: selectedCustomMix.title,
        type: UPDATE_PROJECT_TITLE
      });

      // check write permissions
      if (selectedCustomMix.lastModifiedUser.id === user.user.id) {
        setSaveWarningMessage(OVERWRITE_WARNING_MESSAGE);
      } else {
        setSaveWarningMessage(OVERWRITE_PERMISSIONS_WARNING_MESSAGE);
        setSaveDisabled(true);
      }
    } else {
      setSaveWarningMessage(null);
      setSaveDisabled(false);
    }
  }, [selectedCustomMix, user.user.id, dispatch]);

  const onTitleChange = e => {
    const newTitle = e.target.value;
    dispatch<UpdateProjectTitle>({
      title: newTitle,
      type: UPDATE_PROJECT_TITLE
    });
  };

  const saveAs = () => {
    // need at least a selected project (folder) to save in
    if (selectedProject) {
      dispatch(
        saveCustomMixAction(
          selectedCustomMix ? selectedCustomMix.key : null,
          selectedProject.key,
          user.user.id,
          customMixSaved
        )
      );
    }
  };

  const customMixSaved = customMixJson => {
    closeAndResetModal();
    if (
      customMixInfo.id !== customMixJson.id ||
      customMixInfo.version.versionNumber !==
        customMixJson.version.versionNumber
    ) {
      editor.requestCustomMixIdOpen(
        customMixJson.id,
        customMixJson.version.versionNumber
      );
    }
  };

  const closeAndResetModal = () => {
    setSelectedProject(null);
    setSelectedCustomMix(null);
    setSaveWarningMessage(null);
    setSaveDisabled(false);
    setCustomMixPickerData(null);
    modalState.close();
  };

  const spawnCreateProjectWorkflow = () => {
    closeAndResetModal();
    createProjectModal.open();
  };

  const handleNewProject = newProject => {
    createProjectModal.close();
    modalState.open();
  };

  return (
    <>
      <AntModal modalState={modalState} onClose={closeAndResetModal}>
        <Container>
          <div id="save-as-title">
            <h2>Save Custom Mix</h2>
          </div>
          {customMixPickerData !== null && customMixPickerData.length === 0 && (
            <div id="project-create">
              <p>
                You need to create a new project (folder) to save this custom
                mix in.
              </p>
              <Button onClick={spawnCreateProjectWorkflow}>
                Create Project
              </Button>
            </div>
          )}
          <CustomMixPicker
            onDataLoaded={setCustomMixPickerData}
            onSelectProject={setSelectedProject}
            onSelectCustomMix={setSelectedCustomMix}
            titleQuery={customMixInfo.title}
            highlightDirs={true}
          ></CustomMixPicker>
          {saveWarningMessage && (
            <Alert
              message={"Warning"}
              description={saveWarningMessage}
              type="warning"
              showIcon
            />
          )}
          {selectedProject && (
            <FlexRow>
              <div className="input-container">
                <div id="save-modal-custom-mix-name-label">Custom Mix Name</div>
                <Input
                  value={customMixInfo.title}
                  onChange={onTitleChange}
                  style={{ ...lightInputStyle }}
                />
              </div>
              <FlexRow>
                <Button
                  secondary
                  width="100"
                  styles="margin-right: 14px;"
                  onClick={modalState.close}
                >
                  Cancel
                </Button>
                <Button width="100" onClick={saveAs} disabled={saveDisabled}>
                  {selectedCustomMix ? "Overwrite" : "Save New"}
                </Button>
              </FlexRow>
            </FlexRow>
          )}
        </Container>
      </AntModal>
      <CreateProjectModal
        modalState={createProjectModal}
        onComplete={handleNewProject}
      />
    </>
  );
};
