import styled from "styled-components";

export const SubscribeAndSaveContainer = styled.div`
  background-color: ${props => props.theme.subscribe};
  border-radius: ${props => props.theme.borderRadiusContainers};
  padding: ${({ theme }) => theme.paddingContainersS};
  color: ${props => props.theme.textLight};

  .subscribe-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .info-icon {
      padding-right: 10px;
    }
  }

  .button-container {
    display: flex;
    flex-flow: row no-wrap;
  }

  .learn-more-button {
    background-color: ${({ theme }) => theme.subscribe};
    border: 2px solid ${({ theme }) => theme.textLight};
    text: ${({ theme }) => theme.textLight};
    font-family: ${({ theme }) => theme.font};
    padding: 0 20px;
  }

  .subscribe-button {
    background-color: ${props => props.theme.textLight};
    color: ${props => props.theme.subscribe};
    padding: 0 20px;
    margin-right: 10px;
  }

  .description {
    padding: ${({ theme }) => theme.paddingM};
  }
`;

export const BlanketLicenseContainer = styled.div`
  background-color: ${({ theme }) => theme.subscribe};
  border-radius: ${({ theme }) => theme.borderRadiusContainers};
  padding: ${({ theme }) => theme.paddingContainersS};

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.paddingS};
  }

  .blanket-license-button {
    background-color: ${({ theme }) => theme.textLight};
    color: ${({ theme }) => theme.subscribe};
    margin-left: auto;
  }
`;
