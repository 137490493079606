import { Reducer } from "redux";
import {
  FINISH_LANE_LOADING,
  FinishLaneLoading,
  INITIALIZE_LANE_LOADING,
  InitializeLaneLoading,
  UPDATE_LANE_LOADING_PROGRESS,
  UpdateLaneLoadingProgress
} from "../actions/actionTypes";
import { LaneLoadingStatus } from "../store";

export let laneLoadingDefaultState: LaneLoadingStatus = {
  isLoading: false,
  loadingProgressPercentage: 0
};

export const laneLoadingStatusReducer: Reducer<
  LaneLoadingStatus,
  InitializeLaneLoading | UpdateLaneLoadingProgress | FinishLaneLoading
> = (state = laneLoadingDefaultState, action) => {
  switch (action.type) {
    case INITIALIZE_LANE_LOADING:
      return {
        isLoading: true,
        loadingProgressPercentage: 0.01
      };
    case UPDATE_LANE_LOADING_PROGRESS:
      return {
        ...state,
        isLoading: true,
        loadingProgressPercentage: action.progress
      };
    case FINISH_LANE_LOADING:
      return {
        isLoading: false,
        loadingProgressPercentage: 0
      };
    default:
      return state;
  }
};
