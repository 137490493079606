import React from "react";

export const MessageContext = React.createContext();

export const MessageConsumer = props => {
  return (
    <MessageContext.Consumer>
      {context => {
        if (!context) {
          throw new Error(
            "Message component can not be used outside of a message provider"
          );
        }
        return props.children(context);
      }}
    </MessageContext.Consumer>
  );
};

class MessageProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      duration: 3000,
      type: "error",
      title: "",
      description: "Hey Default Message Hey Default Message ",
      redirectLink: undefined
    };
  }

  showMessage = config => {
    const { type, duration, description, title, redirectLink } = config;
    this.setState({
      show: true,
      type,
      title,
      description,
      duration,
      redirectLink
    });
    if (duration !== 0) {
      setTimeout(() => {
        this.closeMessage();
      }, duration || this.state.duration);
    }
  };

  closeMessage = () => {
    this.setState({ show: false });
  };

  render() {
    return (
      <MessageContext.Provider
        value={{
          ...this.state,
          showMessage: this.showMessage,
          closeMessage: this.closeMessage
        }}
      >
        {this.props.children}
      </MessageContext.Provider>
    );
  }
}

export default MessageProvider;
