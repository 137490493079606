/**
 * BPM: Beats Per Minute
 * SPB: Seconds Per Beat
 * MSPB: Milliseconds Per Beat
 */

// make freetime tracks appear/interact as if they have this bpm
const FREETIME_BPM_OVERRIDE = 100;

export function bpm_to_spb(bpm: number) {
  return 60 / (bpm in [0, 1] ? FREETIME_BPM_OVERRIDE : bpm);
}

export function bpm_to_mspb(bpm: number) {
  return bpm_to_spb(bpm) * 1000;
}

export function beats_to_spb(numOfBeats: number, bpm: number) {
  const secondPerBeat = bpm_to_spb(bpm);
  return numOfBeats * secondPerBeat;
}

export function bpm_to_seconds_per_measure(
  bpm: number,
  beatsPerMeasure: number
) {
  return bpm_to_spb(bpm) * beatsPerMeasure;
}

export function quantize(time: number, quantum: number) {
  return Math.round(time / quantum) * quantum;
}

export function quantizeCeil(time: number, quantum: number) {
  return Math.ceil(time / quantum) * quantum;
}

export function quantizeFloor(time: number, quantum: number) {
  return Math.floor(time / quantum) * quantum;
}

export function snap_to_nearest_beat(time: number, bpm: number) {
  return quantize(time, bpm_to_spb(bpm));
}

export function snap_to_nearest_bar(
  time: number,
  bpm: number,
  beatsPerMeasure: number
) {
  const secondsPerMeasure = bpm_to_seconds_per_measure(bpm, beatsPerMeasure);
  return quantize(time, secondsPerMeasure);
}
