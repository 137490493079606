import { AudioMaster } from "../../AudioMaster";
import { EncapsulatedAudioNode } from "../EncapsulatedAudioNode";

interface ChorusFXParams {
  frequency: number;
  depth: number;
  delay: number;
  dryLevel: number;
  wetLevel: number;
}

/*
 * A custom audio effect that implements a Chorus effect
 *
 * Reference implementation found here:
 * https://github.com/cwilso/Audio-Input-Effects/blob/main/js/effects.js#L522
 */
export class Chorus extends EncapsulatedAudioNode {
  private delayNode: DelayNode;
  private oscillator: OscillatorNode;
  private gainNode: GainNode;
  private dryGain: GainNode;
  private wetGain: GainNode;

  /*
   * Construct the Chorus effect
   *
   * Parameters:
   *  audioMaster: contains audio context
   *  fxParams: json serialization of effect parameters and controls
   */
  constructor(audioMaster: AudioMaster, fxParams: ChorusFXParams) {
    super(audioMaster.context);

    this.delayNode = audioMaster.context.createDelay();

    this.oscillator = audioMaster.context.createOscillator();
    this.oscillator.type = "sine";
    this.oscillator.frequency.value = fxParams.frequency;
    this.oscillator.start(0);

    this.gainNode = audioMaster.context.createGain();
    this.gainNode.gain.value = fxParams.depth;

    this.dryGain = audioMaster.context.createGain();
    this.dryGain.gain.value = fxParams.dryLevel;
    this.wetGain = audioMaster.context.createGain();
    this.wetGain.gain.value = fxParams.wetLevel;

    this.oscillator.connect(this.gainNode);
    this.gainNode.connect(this.delayNode.delayTime);

    this._inputConnect(this.dryGain).connect(this.outputNode);
    this._inputConnect(this.delayNode)
      .connect(this.wetGain)
      .connect(this.outputNode);
  }

  public get frequency() {
    return this.oscillator.frequency.value;
  }

  public set frequency(value: number) {
    this.oscillator.frequency.setTargetAtTime(
      value,
      this.oscillator.context.currentTime,
      0.01
    );
  }

  public get depth() {
    return this.gainNode.gain.value;
  }

  public set depth(value: number) {
    this.gainNode.gain.setTargetAtTime(
      value,
      this.gainNode.context.currentTime,
      0.01
    );
  }

  public get dryLevel() {
    return this.dryGain.gain.value;
  }

  public set dryLevel(value: number) {
    this.dryGain.gain.setTargetAtTime(
      value,
      this.dryGain.context.currentTime,
      0.01
    );
  }

  public get wetLevel() {
    return this.wetGain.gain.value;
  }

  public set wetLevel(value: number) {
    this.wetGain.gain.setTargetAtTime(
      value,
      this.wetGain.context.currentTime,
      0.01
    );
  }
}
