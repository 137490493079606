import { AudioMaster } from "../../AudioMaster";
import { EncapsulatedAudioNode } from "../EncapsulatedAudioNode";

interface TremoloFXParams {
  frequency: number;
  depth: number;
}

/*
 * A custom audio effect that implements a Tremolo effect
 *
 * Reference implementation found here:
 * https://github.com/cwilso/Audio-Input-Effects/blob/main/js/effects.js#L451
 */
export class Tremolo extends EncapsulatedAudioNode {
  private oscillator: OscillatorNode;
  private depthGain: GainNode;

  /*
   * Construct the Tremolo effect
   *
   * Parameters:
   *  audioMaster: contains audio context
   *  fxParams: json serialization of effect parameters and controls
   */
  constructor(audioMaster: AudioMaster, fxParams: TremoloFXParams) {
    super(audioMaster.context);

    const gain = audioMaster.context.createGain();
    this.oscillator = audioMaster.context.createOscillator();
    this.oscillator.type = "sine";
    this.oscillator.start(0);
    this.depthGain = new GainNode(audioMaster.context);

    this.oscillator.connect(this.depthGain);
    this.depthGain.connect(gain.gain);
    gain.connect(this.outputNode);
    this._inputConnect(gain);
  }

  public get frequency() {
    return this.oscillator.frequency.value;
  }

  public set frequency(value: number) {
    this.oscillator.frequency.setTargetAtTime(
      value,
      this.oscillator.context.currentTime,
      0.01
    );
  }

  public get depth() {
    return this.depthGain.gain.value;
  }

  public set depth(value: number) {
    this.depthGain.gain.setTargetAtTime(
      value,
      this.depthGain.context.currentTime,
      0.01
    );
  }
}
