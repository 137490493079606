import gql from "graphql-tag";

export const REQUEST_SIGNED_URL = gql`
  query($key: String) {
    requestSignedUrl(key: $key)
  }
`;

export const CREATE_DOWNLOAD_MUTATION_WITH_TEAM = gql`
  mutation($project: ID, $team: ID, $track: ID, $user: ID, $details: String) {
    createDownload(
      data: {
        project: $project
        user: $user
        team: $team
        track: $track
        details: $details
      }
    ) {
      id
      attribute_id
      track {
        id
        name
      }
      project {
        id
        name
      }
    }
  }
`;

export const CREATE_DOWNLOAD_MUTATION_WITHOUT_TEAM = gql`
  mutation($project: ID, $track: ID, $user: ID, $details: String) {
    createDownload(
      data: { project: $project, user: $user, track: $track, details: $details }
    ) {
      id
      attribute_id
      track {
        id
        name
      }
      project {
        id
        name
      }
    }
  }
`;
