import { bpm, duration } from "./searchFilters";
import { convertToFrontend } from "./rangeConversion";

/*
   We have all the helping/util function for search context here.
*/

/*
name - filterDuplicate
return type - []
description -  given two arrays, it will filter out duplicate items form array arrayTwo
used to filter duplicate tracks in suggested tracks that are found in exact matches.

param     type         desc

arrayOne   []          
arrayTwo   []          
*/
const filterDuplicate = (arrayOne, arrayTwo) => {
  let hash = {};
  arrayOne.forEach(item => (hash[item.id] = item.title));
  const filtered = arrayTwo.filter(item => !hash[item.id]);
  return filtered;
};

/*


    name - isItemLengthOrBpm
    return type - boolean
    description - tells if a filter is length or bpm.

    param     type         desc

    filter       {}         a filter object

*/
const isItemLengthOrBpm = filter =>
  filter.filterName === "Duration" ||
  filter.filterName === "Length" ||
  filter.filterName === "BPM";

/*


    name - formatResponse
    return type - []
    description - In some cases backend sends tracks as the elastic search format.
    this function returns an suitable array for the frontend.

    param     type         desc

    data       []            elastic search response

    

*/
const formatResponse = data => {
  const tracks = data.buckets.map(item => {
    const trackData = item.top_tracks.hits.hits.map(source => source._source);
    return trackData;
  });

  let organizedTracks = tracks.map(item => {
    let parentTrack = item.find(item => !item.variation);
    let displayTrack = item[0];
    let variations = item.slice(1);
    if (parentTrack) {
      displayTrack = parentTrack;
      variations = item.filter(track => track.id !== parentTrack.id);
    }

    let track = {
      ...displayTrack,
      searchVariations: variations
    };
    return track;
  });

  return organizedTracks;
};

/*
  name -removeDefault .
  return type - [] 
  description - if user slides a range values 1-5 / to the min and max value
  we will have to remove that item from the search filters.
  That's because it's the default value. 
  
  param     type         desc

  filters     []      array of filters
  filter      {}      item that's about to be added
*/
const removeDefault = (filters, filter) => {
  // following conditions are to used to remove ranges with default value from searchFilters

  let defaultRemoved = [];
  if (filter.filterName === "BPM") {
    defaultRemoved = filters.filter(
      item =>
        item.min > bpm.min || item.max < bpm.max || item.filterType !== "range"
    );
  }
  if (filter.filterName === "Length" || filter.filterName === "Duration") {
    defaultRemoved = filters.filter(
      item =>
        item.min > duration.min ||
        item.max < duration.max ||
        item.filterType !== "range"
    );
  }
  if (
    filter.filterName !== "BPM" &&
    filter.filterName !== "Length" &&
    filter.filterName !== "Duration"
  ) {
    defaultRemoved = filters.filter(
      item =>
        item.min > 0 ||
        item.max < 9 ||
        item.filterType !== "range" ||
        isItemLengthOrBpm(item)
    );
  }

  return defaultRemoved;
};

/*
  name - filterIsDefault
  return type- boolean
  description - whether a filter is a default value. 

  param     type         desc
  
  filter     {}         range filter object with filterName and other values
  value      []         [0] and [1] form min and max values    

*/
const filterIsDefault = (filter, value) => {
  if (!isItemLengthOrBpm(filter) && value[0] === 1 && value[1] === 5)
    return true; // this line is to avoid adding default values to filters
  if (
    filter.filterName.toLowerCase() === "bpm" &&
    value[0] === bpm.min &&
    value[1] === bpm.max
  )
    return true;
  if (
    ["length", "duration"].includes(filter.filterName.toLowerCase()) &&
    value[0] === duration.min &&
    value[1] === duration.max
  )
    return true;
};

/*

  name - filterIsDefault
  return type- []
  description -   given a track object extracts and returns 
  array of range filters.


  param     type         desc
  
  track     {}          single track obj returned from backend
  advancedSearchOn  bool  whether to include all filters
  frontendDisplay bool  whether to map [0,9] backend values to [1,5] frontend values

*/

const generateRanges = (track, advancedSearchOn, frontendDisplay = true) => {
  let ranges = {
    mood: track ? track.mood : 0,
    density: track ? track.density : 0,
    energy: track ? track.energy : 0,
    gravity: track ? track.gravity : 0,
    ensemble: track ? track.ensemble : 0
  };

  if (advancedSearchOn) {
    ranges = {
      ...ranges,
      melody: track ? track.melody : 0,
      tension: track ? track.tension : 0,
      rhythm: track ? track.rhythm : 0
    };
  }

  const keys = Object.keys(ranges);
  const values = Object.values(ranges);

  const done = keys.map((item, index) => ({
    filterType: "range",
    filterName: item.charAt(0).toUpperCase() + item.slice(1),
    min: frontendDisplay ? convertToFrontend(values[index]) : values[index],
    max: frontendDisplay ? convertToFrontend(values[index]) : values[index]
  }));

  return done;
};

const generateDefaultRanges = filters => {
  let defaultRanges = filters.map(item => {
    if (["length", "duration"].includes(item.filterName.toLowerCase())) {
      return {
        ...item,
        min: duration.min,
        max: duration.max
      };
    } else if (item.filterName.toLowerCase() === "bpm")
      return { ...item, min: bpm.min, max: bpm.max };

    return {
      ...item,
      min: 0,
      max: 9
    };
  });

  return defaultRanges;
};

export {
  filterDuplicate,
  isItemLengthOrBpm,
  formatResponse,
  removeDefault,
  filterIsDefault,
  generateRanges,
  generateDefaultRanges
};
