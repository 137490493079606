import styled from "styled-components";

export const TooltipContainer = styled.div`
  padding: 8px;
  margin-bottom: 12px;

  .tooltip-title {
    margin-top: 6px;
    font-size: ${props => props.theme.p2b.fs};
    font-family: ${props => props.theme.p3b.ff};
    margin-bottom: 12px;
  }

  .interval {
    margin-top: 12px;
  }
  .tooltip-description {
    margin-top: 12px;
    line-height: 22px;
  }
`;
