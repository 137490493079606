import * as React from "react";

export const GripVerticalIcon = () => (
    <svg width="9" height="15" viewBox="0 0 9 15" fill="" xmlns="http://www.w3.org/2000/svg">
        <path fill="#D8D8D8"
              d="M2.27782 12.7805C1.91754 12.7805 1.60792 12.6502 1.34897 12.3895C1.09002 12.1175 0.960541 11.7945 0.960541 11.4205C0.960541 11.0579 1.08439 10.7405 1.33208 10.4685C1.57977 10.1965 1.88376 10.0605 2.24404 10.0605C2.60432 10.0605 2.91394 10.1965 3.17289 10.4685C3.43184 10.7292 3.56132 11.0465 3.56132 11.4205C3.56132 11.6019 3.52754 11.7775 3.45999 11.9475C3.39244 12.1062 3.30236 12.2479 3.18978 12.3725C3.07719 12.4972 2.94208 12.5992 2.78446 12.6785C2.62684 12.7465 2.45796 12.7805 2.27782 12.7805Z"/>
        <path fill="#D8D8D8"
              d="M2.27782 7.75024C1.91754 7.75024 1.60792 7.61991 1.34897 7.35924C1.09002 7.08724 0.960541 6.76424 0.96054 6.39024C0.96054 6.02758 1.08439 5.71024 1.33208 5.43824C1.57977 5.16624 1.88376 5.03024 2.24404 5.03024C2.60432 5.03024 2.91394 5.16624 3.17289 5.43824C3.43184 5.69891 3.56132 6.01624 3.56132 6.39024C3.56132 6.57158 3.52754 6.74724 3.45999 6.91724C3.39244 7.07591 3.30236 7.21758 3.18978 7.34224C3.07719 7.46691 2.94208 7.56891 2.78446 7.64824C2.62684 7.71624 2.45796 7.75024 2.27782 7.75024Z"/>
        <path fill="#D8D8D8"
              d="M2.27782 2.71997C1.91754 2.71997 1.60792 2.58964 1.34897 2.32897C1.09002 2.05697 0.96054 1.73397 0.96054 1.35997C0.96054 0.997303 1.08439 0.679969 1.33208 0.407969C1.57977 0.135968 1.88376 -3.15119e-05 2.24404 -3.15277e-05C2.60432 -3.15436e-05 2.91394 0.135968 3.17289 0.407968C3.43184 0.668635 3.56132 0.985969 3.56132 1.35997C3.56132 1.5413 3.52754 1.71697 3.45999 1.88697C3.39243 2.04564 3.30236 2.1873 3.18978 2.31197C3.07719 2.43664 2.94208 2.53864 2.78446 2.61797C2.62684 2.68597 2.45796 2.71997 2.27782 2.71997Z"/>
        <path fill="#D8D8D8"
              d="M7.24492 12.7805C6.88464 12.7805 6.57502 12.6502 6.31607 12.3895C6.05712 12.1175 5.92765 11.7945 5.92765 11.4205C5.92765 11.0579 6.05149 10.7405 6.29918 10.4685C6.54688 10.1965 6.85086 10.0605 7.21114 10.0605C7.57143 10.0605 7.88104 10.1965 8.13999 10.4685C8.39895 10.7292 8.52842 11.0465 8.52842 11.4205C8.52842 11.6019 8.49464 11.7775 8.42709 11.9475C8.35954 12.1062 8.26947 12.2479 8.15688 12.3725C8.04429 12.4972 7.90919 12.5992 7.75157 12.6785C7.59394 12.7465 7.42506 12.7805 7.24492 12.7805Z"/>
        <path fill="#D8D8D8"
              d="M7.24492 7.75024C6.88464 7.75024 6.57502 7.61991 6.31607 7.35924C6.05712 7.08724 5.92764 6.76424 5.92764 6.39024C5.92764 6.02758 6.05149 5.71024 6.29918 5.43824C6.54688 5.16624 6.85086 5.03024 7.21114 5.03024C7.57143 5.03024 7.88104 5.16624 8.13999 5.43824C8.39895 5.69891 8.52842 6.01624 8.52842 6.39024C8.52842 6.57158 8.49464 6.74724 8.42709 6.91724C8.35954 7.07591 8.26947 7.21758 8.15688 7.34224C8.04429 7.46691 7.90919 7.56891 7.75157 7.64824C7.59394 7.71624 7.42506 7.75024 7.24492 7.75024Z"/>
        <path fill="#D8D8D8"
              d="M7.24492 2.71997C6.88464 2.71997 6.57502 2.58964 6.31607 2.32897C6.05712 2.05697 5.92764 1.73397 5.92764 1.35997C5.92764 0.997303 6.05149 0.679969 6.29918 0.407968C6.54688 0.135968 6.85086 -3.17304e-05 7.21114 -3.17463e-05C7.57142 -3.17621e-05 7.88104 0.135968 8.13999 0.407968C8.39894 0.668635 8.52842 0.985969 8.52842 1.35997C8.52842 1.5413 8.49464 1.71697 8.42709 1.88697C8.35954 2.04564 8.26947 2.1873 8.15688 2.31197C8.04429 2.43664 7.90919 2.53864 7.75157 2.61797C7.59394 2.68597 7.42506 2.71997 7.24492 2.71997Z"/>
    </svg>

)