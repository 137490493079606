import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_FX_EFFECT, SetFXEffectAction } from "../../../actions/actionTypes";
import {
  getAllFxSubCategoryTypesByParentCategory,
  getDistinctFxCategoryTypes,
  getFxValue
} from "../../../audio/FX/fxValues";
import { FX, MainStore } from "../../../store";
import { FXKnob } from "../../FXKnob";
import {
  Container,
  FXSelector,
  FXSlotMenu,
  FXSlotMenuItem,
  FXSlotSubMenu
} from "./styles";
import { Dropdown } from "antd";
import { TriangleDown } from "../../../../assets/TriangleDown";
import { SendFX } from "../../../../assets/SendFX";

export interface FXSlotProps {
  laneId: string;
  slotId: number;
}

export function FXSlot(props: FXSlotProps) {
  const activeEffect = useSelector<MainStore, FX>(
    state =>
      state.project.lanes.byId[props.laneId].laneStatus.fxSlot[props.slotId]
  );

  const [selectorVisible, setSelectorVisible] = useState(false);

  const dispatch = useDispatch();

  function onChangeEffect(selection) {
    const newEffect = getFxValue(selection.key);
    // ensure new effect retains same activation value (knob value) as previous
    newEffect.val = activeEffect.val;
    dispatch<SetFXEffectAction>({
      effect: newEffect,
      slotNumber: props.slotId,
      laneId: props.laneId,
      type: SET_FX_EFFECT
    });
  }

  const fxCategories = getDistinctFxCategoryTypes();

  const fxMenu = (
    <FXSlotMenu
      className="fx-slot-menu"
      getPopupContainer={triggerNode => triggerNode}
    >
      {fxCategories.map(fxCategory => {
        const fxCategoryChildren = getAllFxSubCategoryTypesByParentCategory(
          fxCategory
        );
        return (
          <FXSlotSubMenu
            key={fxCategory}
            className="fx-slot-submenu"
            title={fxCategory}
          >
            {fxCategoryChildren.map(fx => {
              return (
                <FXSlotMenuItem
                  key={fx.name}
                  className="fx-slot-menu-item"
                  onClick={onChangeEffect}
                >
                  <div className="fx-slot-fx-name">
                    {fx.name}
                    {fx.isSend && <SendFX />}
                  </div>
                </FXSlotMenuItem>
              );
            })}
          </FXSlotSubMenu>
        );
      })}
    </FXSlotMenu>
  );

  return (
    <Container>
      <FXKnob laneId={props.laneId} slotId={props.slotId} />
      <Dropdown
        overlay={fxMenu}
        overlayClassName={"fx-slot-dropdown"}
        placement="bottomLeft"
        getPopupContainer={triggerNode => triggerNode}
        trigger={["click"]}
        onVisibleChange={visible => setSelectorVisible(visible)}
      >
        <FXSelector className={`${selectorVisible && "visible"}`}>
          <div className="fx-slot-name">
            {activeEffect.category} - {activeEffect.name}
          </div>
          <TriangleDown />
        </FXSelector>
      </Dropdown>
    </Container>
  );
}
