import React from "react";

/*
 * Customizable template that's barebones (no headers, footers, etc.)
 * Here the children do all the work to fill out content
 */
const BareLayout = props => {
  return <>{props.children}</>;
};

export default BareLayout;
