import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useApolloClient } from "react-apollo";

import {
  Cart,
  Container,
  Icon,
  Inner,
  Logo,
  MobileView,
  SearchContainer,
  TeamName
} from "./styles";
import { Counter } from "../Counter";
import { Button } from "../Button";
import { USER_TEAM_QUERY } from "./queries";

import { SearchContext } from "../../context";
import { AuthContext } from "../../context/AuthProvider";
import { CartContext } from "../../context";

import Searchbar from "../SearchInput";
import CartDropdown from "../CartDropdown";
import Aggregate from "../Aggregate";
import SearchFilters from "../SearchFilters";
import Menu from "../Menu";
import Popover from "../Popover";
import Notification from "../Notification";
import Drawer from "../../components/Drawer";

const Header = props => {
  const client = useApolloClient();
  const context = useContext(SearchContext);
  const { cartItems } = useContext(CartContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearchBarOpen, toggleSearchBar] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const auth = useContext(AuthContext);
  const isMobileScreen = useMediaQuery({ query: "(max-width: 800px)" });
  const history = useHistory();

  const fetchLogo = useCallback(async () => {
    if (auth.user.id) {
      const { data } = await client.query({
        query: USER_TEAM_QUERY,
        variables: { userId: auth.user.id },
        fetchPolicy: "network-only"
      });
      if (data) {
        setTeamData(data.user.team);
      }
    }
  }, [client, auth.user.id]);

  useEffect(() => {
    fetchLogo();
  }, [fetchLogo]);

  const showAggregate = () => {
    const isOnPlaylistScreen = props.location.pathname.includes("/playlist/");
    const isOnAlbumScreen = props.location.pathname.includes("/album");
    const isOnTrackScreen = props.location.pathname.includes("/browse/track");
    const hasSelectedTracks = context.selectedTrackList;

    if (!hasSelectedTracks) return false;

    return isOnPlaylistScreen || isOnAlbumScreen || isOnTrackScreen;
  };

  const showSearchComponents = () =>
    props.location.pathname.includes("/browse") ||
    props.location.pathname === "/";

  const showCart = () => {
    const isNotCheckoutScreen = props.location.pathname !== "/order";
    const isNotOrderScreen =
      !props.location.pathname.includes("/subscription/") &&
      !props.location.pathname.includes("/order-confirmation");

    const hasCartItems = cartItems.length > 0;

    return isNotCheckoutScreen && isNotOrderScreen && hasCartItems;
  };

  const showSubscribeCTA = () =>
    !isMobileScreen &&
    (auth.user.role === "guest" ||
      (auth.user.role === "private" &&
        !auth.user.projects.some(p => p.downloadAccess || p.subscription)));

  const showProfile = () => auth.isUserPrivate();

  const showNotification = () => auth.isUserPrivate();

  const cartIcon = (
    <Cart
      onClick={isMobileScreen ? () => props.history.push("/order") : undefined}
    >
      <Icon src={require("../../assets/cart.svg")} alt="cart" />
      {cartItems.length > 0 && (
        <Counter>
          <p className="p4b text">{cartItems.length}</p>
        </Counter>
      )}
    </Cart>
  );

  return (
    <Container>
      {!isMobileScreen && (
        <Inner customLogo={auth.user.customLogo && teamData}>
          {auth.user.customLogo && teamData ? (
            <div className="header-container">
              <TeamName
                color={teamData.color}
                onClick={() => props.history.push("/")}
              >
                <p className="team-title ws-nowrap">{teamData.name}</p>
              </TeamName>
              <div className="blue-dot-title ">
                <p className="p4b text"> Blue Dot Sessions</p>
              </div>
            </div>
          ) : (
            <div className="logo-container">
              <Logo
                onClick={() => props.history.push("/")}
                src={require("../../assets/logo.svg")}
                alt="blue-dot"
              />
            </div>
          )}

          <div className="always-align-top">
            <SearchContainer>
              {showSearchComponents() && (
                <React.Fragment>
                  <span
                    style={{
                      width: "fit-content",
                      marginRight: 24
                    }}
                  >
                    <Searchbar />
                  </span>
                </React.Fragment>
              )}
              {showAggregate() && (
                <span>
                  <Aggregate />
                </span>
              )}

              {showSearchComponents() && (
                <span style={{ width: "100%" }}>
                  <SearchFilters />
                </span>
              )}
            </SearchContainer>

            {showSubscribeCTA() && (
              <Button
                onClick={() => history.push("/subscription")}
                subscribe={true}
                className="button ws-nowrap"
                height="24"
              >
                Subscribe
              </Button>
            )}
            {showCart() && (
              <Popover
                right={"-10px"}
                top="66px"
                triggerComponent={cartIcon}
                dropdown={<CartDropdown />}
              />
            )}
            {showNotification() && <Notification />}
            <Menu showProfile={showProfile} />
          </div>
        </Inner>
      )}

      {isMobileScreen && (
        <MobileView>
          {!isSearchBarOpen && (
            <React.Fragment>
              <Logo
                onClick={() => props.history.push("/")}
                src={require("../../assets/logo.svg")}
                alt="blue dot sessions logo"
              />
              <div className="row mobile-items">
                {showSearchComponents() && (
                  <div className="search-icon">
                    <img
                      onClick={() => toggleSearchBar(true)}
                      src={require("../../assets/search.svg")}
                      alt="blue-dot-search"
                    />
                  </div>
                )}
                {showCart() && cartIcon}
                <Menu
                  onMenuClick={() => setIsDrawerOpen(!isDrawerOpen)}
                  isDrawerOpen={isDrawerOpen}
                />
              </div>
            </React.Fragment>
          )}
          {isSearchBarOpen && (
            <Searchbar toggleSearchBar={toggleSearchBar} mobile={true} />
          )}

          <div className="drawer-container">
            {isDrawerOpen && (
              <Drawer closeDrawer={() => setIsDrawerOpen(false)} />
            )}
          </div>
        </MobileView>
      )}
    </Container>
  );
};

export default withRouter(Header);
