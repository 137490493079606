// @flow weak

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { theme } from "../../globalStyles";

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = disabled => {
  return {
    position: "absolute",
    left: "-12px",
    width: "calc(100% + 24px)",
    height: 42,
    transform: "translate(0%, -50%)",
    borderRadius: 7,
    cursor: disabled ? "default" : "pointer"
  };
};

const railInnerStyle = {
  position: "absolute",
  left: "-12px",
  width: "calc(100% + 24px)",
  height: 24,
  transform: "translate(0%, -50%)",
  borderRadius: 11,
  pointerEvents: "none",
  backgroundColor: theme.backgroundTag
};

export function SliderRail({ getRailProps, disabled = false }) {
  return (
    <Fragment>
      <div style={railOuterStyle(disabled)} {...getRailProps()} />
      <div style={railInnerStyle} />
    </Fragment>
  );
}

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({
  domain: [min, max],
  handle: { id, value, percent },
  disabled,
  memory,
  getHandleProps
}) {
  return (
    <Fragment>
      <div
        style={{
          left: `${percent}%`,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
          zIndex: memory ? 1 : 5,
          width: 28,
          height: 42,
          cursor: disabled ? "default" : "ew-resize"
        }}
        {...getHandleProps(id)}
      />
      <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          zIndex: memory ? 1 : 2,
          width: 22,
          height: 22,
          borderRadius: "50%",
          boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.1)",
          backgroundColor: theme.highlight,
          opacity: memory ? 0.25 : 1,
          cursor: disabled ? "default" : "ew-resize"
        }}
      />
    </Fragment>
  );
}

Handle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  memory: PropTypes.bool
};

Handle.defaultProps = {
  disabled: false,
  memory: false
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps, disabled }) {
  return (
    <div
      style={{
        position: "absolute",
        transform: "translate(0%, -50%)",
        height: 24,
        zIndex: 1,
        backgroundColor: disabled ? "#999" : "#5D95AA",
        borderRadius: 12,
        cursor: disabled ? "default" : "pointer",
        left: `${source.percent - 12}%`,
        width: `${target.percent - source.percent + 24}%`
      }}
      {...getTrackProps()}
    />
  );
}

Track.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  getTrackProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

Track.defaultProps = {
  disabled: false
};
