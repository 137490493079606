import React from "react";
import {
  CrossfadeQuickTip,
  KeyboardShortcutsQuickTip,
  NavigationQuickTip,
  ProjectSaveQuickTip,
  SendFXQuickTip,
  SnapQuickTip,
  UploadAudioQuickTip,
  VersionsQuickTip
} from "./styles";
import { SendFX } from "../../../assets/SendFX";
import { SnapMagnetBar } from "../../../assets/SnapMagnetBar";
import { SnapMagnetBeat } from "../../../assets/SnapMagnetBeat";

const sendFXQuickTip = (
  <SendFXQuickTip>
    <p>
      Certain audio effects are "send" effects and operate independently from
      the rest of your audio chain.
    </p>
    <div className="sendfx-flex">
      <SendFX /> <div className="sendfx-info">Indicates a send effect</div>
    </div>
  </SendFXQuickTip>
);

const projectSaveQuickTip = (
  <ProjectSaveQuickTip>
    <p>Your Custom Mixes are saved under your project.</p>
    <p className="thicc-spacing">
      Click on <span className="quicktip-btnify">Projects</span> &#9654;{" "}
      <span className="quicktip-btnify">Share Project</span> to share your mix
      with collaborators.
    </p>
  </ProjectSaveQuickTip>
);

const snapQuickTip = (
  <SnapQuickTip>
    <p>Use the Snap feature to keep your music synced up to the beat.</p>
    <div id="quicktip-snap-container">
      <div className="mock-btn-backdrop">
        <SnapMagnetBar />
      </div>
      <div>Sync to bar</div>
      <div className="mock-btn-backdrop">
        <SnapMagnetBeat />
      </div>{" "}
      <div>Sync to beat</div>
    </div>
  </SnapQuickTip>
);

const crossfadeQuickTip = (
  <CrossfadeQuickTip>
    <p>Drag one region over another to automatically create a crossfade.</p>
    <div id="crossfade-info-container">
      <div id="crossfades-container">
        <div id="quicktip-region-1" className="quicktip-region">
          <div className="triangle"></div>
        </div>
        <div id="quicktip-region-2" className="quicktip-region">
          <div className="triangle"></div>
        </div>
        <div id="quicktip-crossfade-arrow">&#767;</div>
      </div>
    </div>
  </CrossfadeQuickTip>
);

const versionQuickTip = (
  <VersionsQuickTip>
    <p className="thicc-spacing">
      Quickly revert to a previously saved version of your mix by selecting{" "}
      <span className="quicktip-btnify">Versions</span> in the editor menu.
    </p>
    <p className="quicktip-text-heavy">
      Each time you save, a version is made.
    </p>
  </VersionsQuickTip>
);

const uploadAudioQuickTip = (
  <UploadAudioQuickTip>
    <p>
      Drag your own project audio directly onto the timeline for your reference.
    </p>
    <div id="quicktip-regions-container">
      <div id="quicktip-region-1" className="quicktip-region"></div>
      <div id="quicktip-region-2" className="quicktip-region"></div>
      <div id="quicktip-region-3" className="quicktip-region"></div>
    </div>
    <div
      id="quicktip-upload-region"
      className="quicktip-region quicktip-region-shell"
    ></div>
    <p className="text-muted">
      We care about not leaking your audio before release. Your audio won't be
      uploaded or saved.
    </p>
  </UploadAudioQuickTip>
);

const navigationQuickTip = (
  <NavigationQuickTip>
    <p>Use the keyboard for navigation.</p>
    <div id="quicktip-keyboard-shortcuts">
      <div id="keyboard-shortcuts-container">
        <div id="keyboard-shortcut-keys">
          <div className="key">
            <img
              src={require("../../../assets/arrow-left-full.svg")}
              alt="left arrow"
            />
          </div>
          <div className="key">
            <img
              src={require("../../../assets/arrow-right-full.svg")}
              alt="right arrow"
            />
          </div>
          <div className="key space">SPACE</div>
        </div>
        <div id="keyboard-shortcut-descriptions">
          <p id="shortcut-1-info">Forward / Backward (1 Bar)</p>
          <p id="shortcut-2-info">Start / Stop</p>
        </div>
      </div>
    </div>
  </NavigationQuickTip>
);

const keyboardShortcutsQuickTip = (
  <KeyboardShortcutsQuickTip>
    <p>Use keyboard shortcuts for quick edits.</p>
    <div id="quicktip-keyboard-shortcuts">
      <div id="keyboard-shortcuts-container">
        <div id="keyboard-shortcut-keys">
          <div className="key">S</div>
          <div className="key">D</div>
          <div className="key">X</div>
        </div>
        <div id="keyboard-shortcut-descriptions">
          <div className="shortcut-description">
            <div className="key-name">S</div>
            <div className="description">Split</div>
          </div>
          <div className="shortcut-description">
            <div className="key-name">D</div>
            <div className="description">Duplicate</div>
          </div>
          <div className="shortcut-description">
            <div className="key-name">X</div>
            <div className="description">Delete</div>
          </div>
        </div>
      </div>
    </div>
  </KeyboardShortcutsQuickTip>
);

const quickTipContents = [
  sendFXQuickTip,
  projectSaveQuickTip,
  snapQuickTip,
  crossfadeQuickTip,
  versionQuickTip,
  uploadAudioQuickTip,
  navigationQuickTip,
  keyboardShortcutsQuickTip
];

export default quickTipContents;
