export const UPDATE_KEY = "UPDATE_KEY";
export const UPDATE_BPM = "UPDATE_BPM";
export const UPDATE_BAR = "UPDATE_BAR";
export const UPDATE_INITIAL_BEAT = "UPDATE_INITIAL_BEAT";
export const UPDATE_GAIN = "UPDATE_GAIN";

export interface UpdateKeyAction {
  type: typeof UPDATE_KEY;
  key: string;
}

export interface UpdateBpmAction {
  type: typeof UPDATE_BPM;
  bpm: number;
}

export interface UpdateBarAction {
  type: typeof UPDATE_BAR;
  bar: number;
}

export interface UpdateInitialBarAction {
  type: typeof UPDATE_INITIAL_BEAT;
  initialBeat: number;
}

export interface UpdateGainAction {
  type: typeof UPDATE_GAIN;
  gain: number;
}
