import styled from "styled-components";

export const Container = styled.div`
  width: 735px;
  .title {
    margin-bottom: 24px;
  }
`;

export const TeamDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 17px;

  .cover {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-left: 0px;
  }

  .mt-4 {
    margin-top: 4px;
  }

  .mr-4 {
    margin-right: 4px;
  }

  .mr-12 {
    margin-right: 12px;
  }

  .mr-14 {
    margin-right: 14px;
  }

  .checkbox {
    margin-top: 4px;
    color: ${props => props.theme.textDark};
    font-family: ${props => props.theme.p4m.ff};
    font-size: ${props => props.theme.p4m.fs};

    .ant-checkbox-inner {
      background-color: ${props => props.theme.backgroundTag};
      border: none;
      width: 16px;
      height: 16px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${props => props.theme.highlight};
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      margin-top: -1px;
    }
  }
`;
