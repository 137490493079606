import { AudioMaster } from "../../AudioMaster";
import { EncapsulatedAudioNode } from "../EncapsulatedAudioNode";

interface PitchShifterFXParams {
  pitchOffset: number;
  delay: number;
  dryLevel: number;
  wetLevel: number;
}

/*
 * A custom audio effect that implements a PitchShifter effect ranging +1/-1 octaves
 *
 * Reference implementation found here:
 * https://github.com/cwilso/Audio-Input-Effects/blob/main/js/effects.js#L757
 * https://github.com/cwilso/Audio-Input-Effects/blob/main/js/jungle.js#L99
 */
export class PitchShifter extends EncapsulatedAudioNode {
  private mod1Gain: GainNode;
  private mod2Gain: GainNode;
  private mod3Gain: GainNode;
  private mod4Gain: GainNode;
  private modGain1: GainNode;
  private modGain2: GainNode;
  private dryGain: GainNode;
  private wetGain: GainNode;

  private pitchOffsetVal: number;

  private static delayTime = 0.1;
  private static fadeTime = 0.05;
  private static bufferTime = 0.1;

  /*
   * Construct the PitchShifter effect
   *
   * Parameters:
   *  audioMaster: contains audio context
   *  fxParams: json serialization of effect parameters and controls
   */
  constructor(audioMaster: AudioMaster, fxParams: PitchShifterFXParams) {
    super(audioMaster.context);

    this.dryGain = audioMaster.context.createGain();
    this.dryGain.gain.value = 1;
    this._inputConnect(this.dryGain).connect(this.outputNode);

    // Delay modulation.
    var mod1 = audioMaster.context.createBufferSource();
    var mod2 = audioMaster.context.createBufferSource();
    var mod3 = audioMaster.context.createBufferSource();
    var mod4 = audioMaster.context.createBufferSource();
    const shiftDownBuffer = createDelayTimeBuffer(
      audioMaster.context,
      PitchShifter.bufferTime,
      PitchShifter.fadeTime,
      false
    );
    const shiftUpBuffer = createDelayTimeBuffer(
      audioMaster.context,
      PitchShifter.bufferTime,
      PitchShifter.fadeTime,
      true
    );
    mod1.buffer = shiftDownBuffer;
    mod2.buffer = shiftDownBuffer;
    mod3.buffer = shiftUpBuffer;
    mod4.buffer = shiftUpBuffer;
    mod1.loop = true;
    mod2.loop = true;
    mod3.loop = true;
    mod4.loop = true;

    // for switching between oct-up and oct-down
    this.mod1Gain = audioMaster.context.createGain();
    this.mod2Gain = audioMaster.context.createGain();
    this.mod3Gain = audioMaster.context.createGain();
    this.mod3Gain.gain.value = 0;
    this.mod4Gain = audioMaster.context.createGain();
    this.mod4Gain.gain.value = 0;

    mod1.connect(this.mod1Gain);
    mod2.connect(this.mod2Gain);
    mod3.connect(this.mod3Gain);
    mod4.connect(this.mod4Gain);

    // Delay amount for changing pitch.
    this.modGain1 = audioMaster.context.createGain();
    this.modGain2 = audioMaster.context.createGain();

    var delay1 = audioMaster.context.createDelay();
    var delay2 = audioMaster.context.createDelay();
    this.mod1Gain.connect(this.modGain1);
    this.mod2Gain.connect(this.modGain2);
    this.mod3Gain.connect(this.modGain1);
    this.mod4Gain.connect(this.modGain2);
    this.modGain1.connect(delay1.delayTime);
    this.modGain2.connect(delay2.delayTime);

    // Crossfading.
    var fade1 = audioMaster.context.createBufferSource();
    var fade2 = audioMaster.context.createBufferSource();
    var fadeBuffer = createFadeBuffer(
      audioMaster.context,
      PitchShifter.bufferTime,
      PitchShifter.fadeTime
    );
    fade1.buffer = fadeBuffer;
    fade2.buffer = fadeBuffer;
    fade1.loop = true;
    fade2.loop = true;

    var mix1 = audioMaster.context.createGain();
    var mix2 = audioMaster.context.createGain();
    mix1.gain.value = 0;
    mix2.gain.value = 0;

    fade1.connect(mix1.gain);
    fade2.connect(mix2.gain);

    // Connect processing graph.
    this._inputConnect(delay1);
    this._inputConnect(delay2);
    delay1.connect(mix1);
    delay2.connect(mix2);
    mix1.connect(this.outputNode);
    mix2.connect(this.outputNode);

    // Start
    var t = audioMaster.context.currentTime + 0.05;
    var t2 = t + PitchShifter.bufferTime - PitchShifter.fadeTime;
    mod1.start(t);
    mod2.start(t2);
    mod3.start(t);
    mod4.start(t2);
    fade1.start(t);
    fade2.start(t2);

    this.delay = PitchShifter.delayTime;

    this.wetGain = audioMaster.context.createGain();
    this.wetGain.gain.value = 0;
  }

  public get pitchOffset() {
    return this.pitchOffsetVal;
  }

  public set pitchOffset(value: number) {
    if (value > 0) {
      // pitch up
      this.mod1Gain.gain.value = 0;
      this.mod2Gain.gain.value = 0;
      this.mod3Gain.gain.value = 1;
      this.mod4Gain.gain.value = 1;
    } else {
      // pitch down
      this.mod1Gain.gain.value = 1;
      this.mod2Gain.gain.value = 1;
      this.mod3Gain.gain.value = 0;
      this.mod4Gain.gain.value = 0;
    }
    this.delay = PitchShifter.delayTime * Math.abs(value);
  }

  public get delay() {
    return PitchShifter.delayTime;
  }

  public set delay(value: number) {
    this.modGain1.gain.setTargetAtTime(
      0.5 * value,
      this.modGain1.context.currentTime,
      0.01
    );
    this.modGain2.gain.setTargetAtTime(
      0.5 * value,
      this.modGain2.context.currentTime,
      0.01
    );
  }

  public get dryLevel() {
    return this.dryGain.gain.value;
  }

  public set dryLevel(value: number) {
    this.dryGain.gain.setTargetAtTime(
      value,
      this.dryGain.context.currentTime,
      0.01
    );
  }

  public get wetLevel() {
    return this.wetGain.gain.value;
  }

  public set wetLevel(value: number) {
    this.wetGain.gain.setTargetAtTime(
      value,
      this.wetGain.context.currentTime,
      0.01
    );
  }
}

function createFadeBuffer(context, activeTime, fadeTime) {
  var length1 = activeTime * context.sampleRate;
  var length2 = (activeTime - 2 * fadeTime) * context.sampleRate;
  var length = length1 + length2;
  var buffer = context.createBuffer(1, length, context.sampleRate);
  var p = buffer.getChannelData(0);

  var fadeLength = fadeTime * context.sampleRate;

  var fadeIndex1 = fadeLength;
  var fadeIndex2 = length1 - fadeLength;

  // 1st part of cycle
  for (var i = 0; i < length1; ++i) {
    var value;

    if (i < fadeIndex1) {
      value = Math.sqrt(i / fadeLength);
    } else if (i >= fadeIndex2) {
      value = Math.sqrt(1 - (i - fadeIndex2) / fadeLength);
    } else {
      value = 1;
    }

    p[i] = value;
  }

  // 2nd part
  for (var j = length1; j < length; ++j) {
    p[j] = 0;
  }

  return buffer;
}

function createDelayTimeBuffer(context, activeTime, fadeTime, shiftUp) {
  var length1 = activeTime * context.sampleRate;
  var length2 = (activeTime - 2 * fadeTime) * context.sampleRate;
  var length = length1 + length2;
  var buffer = context.createBuffer(1, length, context.sampleRate);
  var p = buffer.getChannelData(0);

  // 1st part of cycle
  for (var i = 0; i < length1; ++i) {
    if (shiftUp)
      // This line does shift-up transpose
      p[i] = (length1 - i) / length;
    // This line does shift-down transpose
    else p[i] = i / length1;
  }

  // 2nd part
  for (var j = length1; j < length; ++j) {
    p[j] = 0;
  }

  return buffer;
}
