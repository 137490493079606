import { Icon as AntIcon, Input, Select, Switch, Tooltip, Alert } from "antd";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_EDITOR_OPENABLE,
  SET_FREE_STEMS,
  SET_EDITOR_DEMO,
  UPDATE_PROJECT_TITLE,
  SET_ALERT,
  SetEditorOpenableAction,
  SetEditorDemoAction,
  SetFreeStemsAction,
  UpdateProjectTitle,
  SetAlertAction,
  SaveInitialTrackCacheAction,
  SAVE_INITIAL_TRACK_CACHE,
  LoadMixFromInitialTrack,
  LOAD_MIX
} from "../../actions/actionTypes";
import {
  UPDATE_BAR,
  UPDATE_BPM,
  UPDATE_GAIN,
  UPDATE_INITIAL_BEAT,
  UPDATE_KEY,
  UpdateBarAction,
  UpdateBpmAction,
  UpdateGainAction,
  UpdateInitialBarAction,
  UpdateKeyAction
} from "../../actions/EditorStateTypes";
import {
  UPDATE_DENSITY,
  UPDATE_ENERGY,
  UPDATE_ENSEMBLE,
  UPDATE_GRAVITY,
  UPDATE_MELODY,
  UPDATE_MOOD,
  UPDATE_RHYTHM,
  UPDATE_TENSION,
  UpdateDensityAction,
  UpdateEnergyAction,
  UpdateEnsembleAction,
  UpdateGravityAction,
  UpdateMelodyAction,
  UpdateMoodAction,
  UpdateRhythmAction,
  UpdateTensionAction,
  SetTagsAction,
  SET_TAGS,
  SetSystemTagsAction,
  SET_SYSTEM_TAGS,
  SetInstrumentsAction,
  SET_THEMATIC_CATEGORIES,
  SetThematicCategoriesAction,
  SET_INSTRUMENTS
} from "../../actions/InitialTrackDataTypes";
import { postTrackInfo } from "../../api/WebAPI";
import { Button } from "../Button";
import { CustomMixInfo, InitialTrackData, MainStore } from "../../store";
import {
  Container,
  FlexColumns,
  FlexRow,
  FlexRowModified,
  FlexRowTooltip,
  Icon
} from "./styles";
import { AuthContext } from "../../../context/AuthProvider";
import AntModal from "../../../components/AntModal";
import { newTagAmountOfDays } from "../../../components/Tag/newTagFilter";
import Spinner from "../../../components/Spinner";
import Tags from "../../../components/Track/Tags";
import { lightInputStyle } from "../../../globalStyles";
import { formatFullDate } from "../../../utils/formatDateHelper";
import { generateRanges } from "../../../utils/searchHelpers";
import AvatarTooltipName from "../../../components/Avatar";
import SimpleSlider from "../../../components/SimpleSlider";
import { keys } from "../../../utils/searchFilters";
import { IndexSelect } from "../../../pages/Admin/reindex/styles";
import { MetronomeVolumeControl } from "../Buttons/MetronomeVolumeControl";
import { EditorContext } from "../../context/EditorContext/EditorProvider";

const INITIALBEAT_RANGE = [0, 12];
const BEATSPERMEASURE_RANGE = [1, 12];
const GAIN_RANGE = [-12, 12];

const { Option } = Select;

export const TrackInfoModal = ({ modalState }) => {
  const TRACK_CUSTOMMIX_CACHE_SAVE_SUCCESS =
    "Custom mix cache for this track saved successfully";
  const TRACK_CUSTOMMIX_CACHE_SAVE_ERROR =
    "There was an error saving the custom mix cache for this track. Please try again or contact the development team for assistance.";
  const TRACK_METADATA_SAVE_SUCCESS =
    "The metadata for this track has been saved successfully.";
  const TRACK_METADATA_SAVE_ERROR =
    "There was an error saving the metadata for this track. Please try again or contact the development team for assistance.";
  const TRACK_METADATA_FREESTEMS_ERROR =
    "If Editor Demo is enabled, then Free Stems must also be enabled.";
  const TRACK_CACHE_SET_BUT_INACCESSIBLE =
    "An initial CustomMix cache has been saved for this track but the editor is disabled and will be inaccessible to users.";

  const dispatch = useDispatch();

  const customMixInfo = useSelector<MainStore, CustomMixInfo>(
    state => state.project.customMixInfo
  );
  const initialTrackData = useSelector<MainStore, InitialTrackData>(
    state => state.project.initialTrackData
  );
  const hdAudioLoaded = useSelector<MainStore, Boolean>(
    state => state.waveformBuffers.hdLoaded
  );

  const editor = useContext(EditorContext);
  const auth = useContext(AuthContext);
  const isAdmin = auth.user ? auth.user.adminUser : false;
  const paidAccess = auth.hasPaidTrackAccess(initialTrackData);

  const { push } = useHistory();

  const [trackCharacteristics, setTrackCharacteristics] = useState(null);
  const [tagNames, setTagNames] = useState(null);
  const [instrumentNames, setInstrumentNames] = useState(null);
  const [systemTagNames, setSystemTagNames] = useState(null);
  const [thematicCategoryNames, setThematicCategoryNames] = useState(null);
  const [saveWarningMessage, setSaveWarningMessage] = useState(null);
  const [isMetadataSaving, setIsMetadataSaving] = useState(false);
  const [isCustomMixCacheSaving, setIsCustomMixCacheSaving] = useState(false);

  const oldBPM = useRef(null);
  const oldBeatsPerMeasure = useRef(null);
  const oldInitialBeat = useRef(null);
  const beatsPerMeasureEle = useRef(null);
  const initialBeatEle = useRef(null);

  useEffect(() => {
    oldBPM.current = null;
    oldBeatsPerMeasure.current = null;
    oldInitialBeat.current = null;
  }, [initialTrackData.id]);

  useEffect(() => {
    if (initialTrackData.id) {
      if (oldBPM.current === null) oldBPM.current = initialTrackData.bpm;
      if (oldBeatsPerMeasure.current === null)
        oldBeatsPerMeasure.current = initialTrackData.beatsPerMeasure;
      if (oldInitialBeat.current === null)
        oldInitialBeat.current = initialTrackData.initialBeat;

      if (initialTrackData.editorDataDump && !initialTrackData.editorOpenable) {
        setSaveWarningMessage(TRACK_CACHE_SET_BUT_INACCESSIBLE);
      } else {
        setSaveWarningMessage(null);
      }
    }
  }, [initialTrackData]);

  useEffect(() => {
    if (initialTrackData.id) {
      const characteristics = generateRanges(initialTrackData, true, !isAdmin);
      setTrackCharacteristics(characteristics);
    }
  }, [initialTrackData, isAdmin]);

  useEffect(() => {
    setTagNames(initialTrackData.tags.map(tag => tag.name));
  }, [initialTrackData.tags]);

  useEffect(() => {
    setSystemTagNames(initialTrackData.systemTags);
  }, [initialTrackData.systemTags]);

  useEffect(() => {
    setInstrumentNames(
      initialTrackData.instruments.map(instrument => instrument.name)
    );
  }, [initialTrackData.instruments]);

  const handleTrackMetadataSave = async () => {
    setIsMetadataSaving(true);
    await postTrackInfo(
      initialTrackData,
      tagNames,
      systemTagNames,
      instrumentNames,
      thematicCategoryNames
    )
      .then(res => {
        if (typeof res === "object" && "id" in res) {
          // everything is synced with redux store except tags/instruments/thematicCategories
          // we sync now instead of immediately onChange after user interaction
          // like other fields because we need server UUIDs
          dispatch<SetTagsAction>({
            type: SET_TAGS,
            tags: res.tags
          });
          dispatch<SetSystemTagsAction>({
            type: SET_SYSTEM_TAGS,
            systemTags: res.systemTags
          });
          dispatch<SetInstrumentsAction>({
            type: SET_INSTRUMENTS,
            instruments: res.instruments
          });
          dispatch<SetThematicCategoriesAction>({
            type: SET_THEMATIC_CATEGORIES,
            thematicCategories: res.thematicCategories
          });

          // update Track characteristic slider memories
          const characteristics = generateRanges(
            initialTrackData,
            true,
            !isAdmin
          );
          setTrackCharacteristics(characteristics);

          let msgSuccess = TRACK_METADATA_SAVE_SUCCESS;
          // only reload if BPM, Beats Per Bar, or Initial Beat changed
          if (
            oldBPM.current !== initialTrackData.bpm ||
            oldBeatsPerMeasure.current !== initialTrackData.beatsPerMeasure ||
            oldInitialBeat.current !== initialTrackData.initialBeat
          ) {
            dispatch<LoadMixFromInitialTrack>({
              type: LOAD_MIX,
              projectId: initialTrackData.id,
              fromCache: false,
              paidAccess: true
            });
            msgSuccess += "  Reloading regions ...";
            oldBPM.current = initialTrackData.bpm;
            oldBeatsPerMeasure.current = initialTrackData.beatsPerMeasure;
            oldInitialBeat.current = initialTrackData.initialBeat;
          }

          dispatch<SetAlertAction>({
            type: SET_ALERT,
            id: "TRACK_METADATA_SAVE",
            role: "success",
            title: "Saved",
            message: msgSuccess
          });
        } else {
          dispatch<SetAlertAction>({
            type: SET_ALERT,
            id: "TRACK_METADATA_SAVE",
            role: "error",
            title: "Error Saving",
            message: TRACK_METADATA_SAVE_ERROR
          });
        }
        setIsMetadataSaving(false);
      })
      .catch(() => {
        dispatch<SetAlertAction>({
          type: SET_ALERT,
          id: "TRACK_METADATA_SAVE",
          role: "error",
          title: "Error Saving",
          message: TRACK_METADATA_SAVE_ERROR
        });
        setIsMetadataSaving(false);
      });
  };

  const handleTrackCustomMixCacheSave = async () => {
    setIsCustomMixCacheSaving(true);
    dispatch<SaveInitialTrackCacheAction>({
      type: SAVE_INITIAL_TRACK_CACHE,
      trackId: initialTrackData.id,
      onSuccess: () => {
        dispatch<SetAlertAction>({
          type: SET_ALERT,
          id: "TRACK_CUSTOMMIX_CACHE_SAVE",
          role: "success",
          title: "Saved",
          message: TRACK_CUSTOMMIX_CACHE_SAVE_SUCCESS
        });
        setTimeout(() => {
          setIsCustomMixCacheSaving(false);
          handleTrackMetadataSave();
        }, 1500);
      },
      onFail: () => {
        dispatch<SetAlertAction>({
          type: SET_ALERT,
          id: "TRACK_CUSTOMMIX_CACHE_SAVE",
          role: "error",
          title: "Error Saving",
          message: TRACK_CUSTOMMIX_CACHE_SAVE_ERROR
        });
        setIsCustomMixCacheSaving(false);
      }
    });
  };

  const onTitleChange = e => {
    dispatch<UpdateProjectTitle>({
      title: e.target.value,
      type: UPDATE_PROJECT_TITLE
    });
  };

  const onRangeClick = (characteristicName, sliderVals) => {
    const value = sliderVals[0];
    switch (characteristicName.toLowerCase()) {
      case "mood":
        dispatch<UpdateMoodAction>({
          mood: value,
          type: UPDATE_MOOD
        });
        break;
      case "density":
        dispatch<UpdateDensityAction>({
          density: value,
          type: UPDATE_DENSITY
        });
        break;
      case "energy":
        dispatch<UpdateEnergyAction>({
          energy: value,
          type: UPDATE_ENERGY
        });
        break;
      case "gravity":
        dispatch<UpdateGravityAction>({
          gravity: value,
          type: UPDATE_GRAVITY
        });
        break;
      case "ensemble":
        dispatch<UpdateEnsembleAction>({
          ensemble: value,
          type: UPDATE_ENSEMBLE
        });
        break;
      case "melody":
        dispatch<UpdateMelodyAction>({
          melody: value,
          type: UPDATE_MELODY
        });
        break;
      case "tension":
        dispatch<UpdateTensionAction>({
          tension: value,
          type: UPDATE_TENSION
        });
        break;
      case "rhythm":
        dispatch<UpdateRhythmAction>({
          rhythm: value,
          type: UPDATE_RHYTHM
        });
        break;
    }
  };

  const onKeyChange = e => {
    let key = e.target.value;
    if (key.length > 0) {
      key = key.charAt(0).toUpperCase() + key.slice(1);
      if (!keys.includes(key)) {
        key = keys[0];
      }
    }
    dispatch<UpdateKeyAction>({
      key: key,
      type: UPDATE_KEY
    });
  };

  const onBpmChange = e => {
    let bpmVal = e.target.value;
    bpmVal = Math.max(0, Math.floor(bpmVal));
    dispatch<UpdateBpmAction>({
      bpm: bpmVal,
      type: UPDATE_BPM
    });
  };

  const onBarChange = e => {
    const bar = Math.max(
      BEATSPERMEASURE_RANGE[0],
      Math.min(Math.floor(e.target.value), BEATSPERMEASURE_RANGE[1])
    );
    if (initialBeatEle.current.state.value > bar) {
      dispatch<UpdateInitialBarAction>({
        initialBeat: bar,
        type: UPDATE_INITIAL_BEAT
      });
    }

    dispatch<UpdateBarAction>({
      bar: bar,
      type: UPDATE_BAR
    });
  };

  const onInitialBeatChange = e => {
    const initialBeat = Math.max(
      INITIALBEAT_RANGE[0],
      Math.min(e.target.value, beatsPerMeasureEle.current.state.value)
    );
    dispatch<UpdateInitialBarAction>({
      initialBeat: initialBeat,
      type: UPDATE_INITIAL_BEAT
    });
  };

  const onGainChange = e => {
    const gain = Math.max(
      GAIN_RANGE[0],
      Math.min(parseFloat(e.target.value), GAIN_RANGE[1])
    );
    dispatch<UpdateGainAction>({
      gain: gain,
      type: UPDATE_GAIN
    });
  };

  const onEditorOpenableChange = bool => {
    dispatch<SetEditorOpenableAction>({
      editorOpenable: bool,
      type: SET_EDITOR_OPENABLE
    });
  };

  const onEditorDemoChange = bool => {
    dispatch<SetEditorDemoAction>({
      editorDemo: bool,
      type: SET_EDITOR_DEMO
    });

    if (bool && !initialTrackData.freeStemFiles) {
      dispatch<SetFreeStemsAction>({
        freeStems: bool,
        type: SET_FREE_STEMS
      });
    }
  };

  const onFreeStemsChange = bool => {
    if (!bool && initialTrackData.editorDemo) {
      dispatch<SetAlertAction>({
        type: SET_ALERT,
        id: "TRACK_FREESTEMS_CHANGE",
        role: "error",
        title: "Can't Update Free Stems",
        message: TRACK_METADATA_FREESTEMS_ERROR
      });
    } else {
      dispatch<SetFreeStemsAction>({
        freeStems: bool,
        type: SET_FREE_STEMS
      });
    }
  };

  return (
    <AntModal modalState={modalState}>
      {initialTrackData.id ? (
        <Container id="track-info-container">
          <div className="row">
            <h2 className="title">Track Info {isAdmin && "| Admin"}</h2>
          </div>
          {(isAdmin || auth.user.role === "private") && (
            <div className="row">
              <p className="trackinfo-label">Custom Mix Name</p>
              <Input
                className="custommix-name"
                value={
                  customMixInfo.title
                    ? customMixInfo.title
                    : initialTrackData.title
                }
                style={{ ...lightInputStyle }}
                onChange={onTitleChange}
              />
            </div>
          )}
          <div className="row">
            <FlexColumns>
              <div className="left-column">
                <p className="trackinfo-label">Original Track</p>
                <p className="trackinfo-data">
                  {initialTrackData.title}
                  {initialTrackData.variationTitle &&
                    ` | ${initialTrackData.variationTitle}`}
                </p>
              </div>
              <div className="right-column">
                <p className="trackinfo-label">Last Modified</p>
                <FlexRowModified>
                  <p className="trackinfo-data">
                    {formatFullDate(customMixInfo?.updatedAt)}
                  </p>
                  {customMixInfo.id && (
                    <AvatarTooltipName
                      name={customMixInfo.user.fullName}
                      className={"icon"}
                      profileColor={customMixInfo.user.profileColor}
                      profileIcon={customMixInfo.user.profileIcon}
                      size={16}
                    />
                  )}
                </FlexRowModified>
              </div>
            </FlexColumns>
          </div>
          <div className="row">
            <div className="trackinfo-label">
              <FlexRowTooltip>
                {`${
                  hdAudioLoaded === null
                    ? ""
                    : hdAudioLoaded || paidAccess || isAdmin
                    ? "HD"
                    : "SD"
                } Audio ${hdAudioLoaded === null ? "Loading ..." : "Loaded"}`}
                {hdAudioLoaded === false && !paidAccess && (
                  <Tooltip
                    title={
                      <div className="subscribe-cta-container">
                        <p>
                          High-definition stems for this track are only
                          available to subscribers and license holders.
                        </p>
                        <Button
                          className="btn-subscribe"
                          onClick={() => {
                            modalState.close();
                            editor.close();
                            push("/subscription");
                          }}
                        >
                          Subscribe
                        </Button>
                      </div>
                    }
                    placement="right"
                    getPopupContainer={trigger => trigger}
                  >
                    <Icon src={require("../../../assets/info.svg")} />
                  </Tooltip>
                )}
              </FlexRowTooltip>
            </div>
          </div>
          <div className="row">
            <hr className="form-divider" />
            <FlexColumns>
              <div className="left-column">
                {trackCharacteristics &&
                  trackCharacteristics.map((characteristic, index) => {
                    return (
                      <div
                        className="characteristic"
                        key={`${characteristic}-${index}`}
                      >
                        <FlexRowModified>
                          <div className="characteristic-label">
                            {characteristic.filterName}
                          </div>
                          <div className="characteristic-slider">
                            <SimpleSlider
                              /*
                               * Users see filter (track characteristic) values in [1,5]
                               * These filter values are converted to [1,9] for database storage to give greater granularity
                               * Admins see filter (track characteristic) values in [1,9]
                               * (see rangeConversion.js for filter mappings)
                               */
                              value={[characteristic.min]}
                              min={isAdmin ? 0 : 1}
                              max={isAdmin ? 9 : 5}
                              onChange={value => {
                                if (isAdmin) {
                                  onRangeClick(
                                    characteristic.filterName,
                                    value
                                  );
                                }
                              }}
                              memoryValue={isAdmin ? characteristic.min : null}
                              disabled={!isAdmin}
                            />
                          </div>
                        </FlexRowModified>
                      </div>
                    );
                  })}
              </div>
              <div className="right-column">
                <div className="inputs">
                  <div className="track-attribute">
                    <label htmlFor="key">Key</label>
                    {isAdmin ? (
                      <Input
                        id="key"
                        value={initialTrackData.key}
                        style={{ ...lightInputStyle }}
                        onChange={onKeyChange}
                      />
                    ) : (
                      <div className="track-attribute-data">
                        {initialTrackData.key}
                      </div>
                    )}
                  </div>
                  <div className="track-attribute">
                    <label htmlFor="bpm">BPM</label>
                    {isAdmin ? (
                      <Input
                        id="bpm"
                        type="number"
                        min={0}
                        max={500}
                        value={initialTrackData.bpm}
                        style={{ ...lightInputStyle }}
                        onChange={onBpmChange}
                      />
                    ) : (
                      <div className="track-attribute-data">
                        {initialTrackData.bpm}
                      </div>
                    )}
                  </div>
                  <div className="track-attribute">
                    <label htmlFor="beats">Beats Per Bar</label>
                    {isAdmin ? (
                      <Input
                        id="beats-per-measure"
                        type="number"
                        ref={beatsPerMeasureEle}
                        min={1}
                        max={12}
                        value={initialTrackData.beatsPerMeasure}
                        style={{ ...lightInputStyle }}
                        onChange={onBarChange}
                      />
                    ) : (
                      <div className="track-attribute-data">
                        {initialTrackData.beatsPerMeasure}
                      </div>
                    )}
                  </div>
                  {isAdmin && (
                    <>
                      <div className="track-attribute">
                        <label htmlFor="initialBeat">Initial Beat</label>
                        <Input
                          id="initialBeat"
                          type="number"
                          ref={initialBeatEle}
                          min={0}
                          max={initialTrackData.beatsPerMeasure}
                          step={1 / 32}
                          value={initialTrackData.initialBeat}
                          style={{ ...lightInputStyle }}
                          onChange={onInitialBeatChange}
                        />
                      </div>
                      <div className="track-attribute">
                        <label htmlFor="gain">Gain</label>
                        <Input
                          id="gain"
                          type="number"
                          min={-12}
                          max={12}
                          step={1 / 4}
                          value={initialTrackData.gain}
                          style={{ ...lightInputStyle }}
                          onChange={onGainChange}
                        />
                      </div>
                      <div className="track-attribute">
                        <label htmlFor="editor-openable">Editor Enabled</label>
                        <Switch // id="editor-openable"
                          checked={initialTrackData.editorOpenable}
                          onChange={onEditorOpenableChange}
                        />
                      </div>
                      <div className="track-attribute">
                        <label htmlFor="free-stems">Free Stems</label>
                        <Switch // id="free-stems"
                          checked={initialTrackData.freeStemFiles}
                          onChange={onFreeStemsChange}
                        />
                      </div>
                      <div className="track-attribute">
                        <label htmlFor="editor-demo">Editor Demo</label>
                        <Switch // id="editor-demo"
                          checked={initialTrackData.editorDemo}
                          onChange={onEditorDemoChange}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </FlexColumns>
          </div>
          {isAdmin && (
            <div className="row">
              <FlexRow>
                <p className="header">Metronome</p>
                <MetronomeVolumeControl />
              </FlexRow>
            </div>
          )}
          {isAdmin ? (
            <>
              <div className="row">
                <p className="header">Tags</p>
                <div className="tag-selector">
                  <IndexSelect
                    mode="tags"
                    placeholder="Select tags"
                    defaultValue={initialTrackData.tags.map(tag => tag.name)}
                    value={tagNames}
                    getPopupContainer={trigger => {
                      return trigger;
                    }}
                    onChange={tags => setTagNames(tags)}
                  >
                    {auth.allTags
                      .filter(tag => {
                        return typeof tag === "string";
                      })
                      .map(tagName => {
                        return (
                          <Option key={tagName} value={tagName}>
                            {tagName}
                          </Option>
                        );
                      })}
                  </IndexSelect>
                </div>
              </div>
              <div className="row">
                <p className="header">System Tags</p>
                <div className="tag-selector">
                  <IndexSelect
                    mode="tags"
                    placeholder="Add system tags (Example: Spooky, Scary, Skeletons)"
                    defaultValue={initialTrackData.systemTags}
                    getPopupContainer={trigger => {
                      return trigger;
                    }}
                    tokenSeparators={[","]}
                    onChange={tags => setSystemTagNames(tags)}
                  />
                </div>
              </div>
              <div className="row">
                <p className="header">Instruments</p>
                <div className="tag-selector">
                  <IndexSelect
                    mode="multiple"
                    placeholder="Select instruments"
                    defaultValue={initialTrackData.instruments.map(
                      instrument => instrument.name
                    )}
                    getPopupContainer={trigger => {
                      return trigger;
                    }}
                    onChange={instruments => setInstrumentNames(instruments)}
                  >
                    {auth.allInstruments.map(instrument => {
                      return (
                        <Option key={instrument} value={instrument}>
                          {instrument}
                        </Option>
                      );
                    })}
                  </IndexSelect>
                </div>
              </div>
              <div className="row">
                <p className="header">Thematic Categories</p>
                <div className="tag-selector">
                  <IndexSelect
                    mode="tags"
                    placeholder="Select thematic categories"
                    defaultValue={initialTrackData.thematicCategories.map(
                      tc => tc.name
                    )}
                    getPopupContainer={trigger => {
                      return trigger;
                    }}
                    onChange={tcs => setThematicCategoryNames(tcs)}
                  >
                    {auth.allThematicCategories
                      .filter(tc => {
                        return typeof tc === "string";
                      })
                      .map(tcName => {
                        return (
                          <Option key={tcName} value={tcName}>
                            {tcName}
                          </Option>
                        );
                      })}
                  </IndexSelect>
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <div className="tags-container">
                <Tags
                  instruments={initialTrackData.instruments}
                  tags={initialTrackData.tags}
                  publishDate={initialTrackData.createdAt}
                  newTagAmountOfDays={newTagAmountOfDays}
                  hasLines={false}
                  hasLabels={true}
                  addButton={false}
                  onAddTagClick={() => {}}
                  onAddInstrumentClick={() => {}}
                />
              </div>
            </div>
          )}
          {isAdmin && (
            <>
              <hr className="form-divider" />
              {saveWarningMessage && (
                <Alert
                  message={"Warning"}
                  description={saveWarningMessage}
                  type="warning"
                  showIcon
                />
              )}
              <div className="row"></div>
              <div className="row">
                <FlexRow>
                  <Button
                    height="auto"
                    px="24"
                    onClick={handleTrackMetadataSave}
                  >
                    {isMetadataSaving && (
                      <AntIcon style={{ marginRight: 10 }} type="loading" />
                    )}
                    Save Track Metadata
                  </Button>
                  <Button
                    height="auto"
                    px="24"
                    onClick={handleTrackCustomMixCacheSave}
                  >
                    {isCustomMixCacheSaving && (
                      <AntIcon style={{ marginRight: 10 }} type="loading" />
                    )}
                    Save CustomMix Cache
                  </Button>
                </FlexRow>
              </div>
            </>
          )}
        </Container>
      ) : (
        <Container id="track-info-container">
          <Spinner height="50px" />
        </Container>
      )}
    </AntModal>
  );
};
