import React, { useState, useEffect, useContext } from "react";
import useModal from "../../../hooks/useModal";
import { Container } from "./styles";
import { EditorContext } from "../../context/EditorContext/EditorProvider";
import quickTipContents from "./tips";
import Cookies from "js-cookie";

export const QuickTip = () => {
  const QUICKTIP_DURATION_MS = 6000;
  const QUICKTIP_SHOW_PERCENTAGE = 0.2;
  const QUICKTIP_COOKIE_NAME = "quicktip-enabled";

  const quickTipMessage = useModal();

  const editor = useContext(EditorContext);

  const [quickTipIndex, setQuickTipIndex] = useState(0);

  const tawkWidgetVisible =
    document.getElementsByClassName("widget-visible").length >= 1;

  const rollQuickTip = () => {
    return Math.floor(Math.random() * quickTipContents.length);
  };

  const setQuickTipEnabledCookie = (show: boolean) => {
    Cookies.set(QUICKTIP_COOKIE_NAME, show);
  };

  const getQuickTipEnabledCookie = () => {
    return (Cookies.get(QUICKTIP_COOKIE_NAME) ?? "true") === "true";
  };

  useEffect(() => {
    const quickTipRollSucceeded = Math.random() <= QUICKTIP_SHOW_PERCENTAGE;
    const quickTipsEnabled = getQuickTipEnabledCookie();
    if (
      editor.drawerState.visible &&
      !quickTipMessage.visible &&
      quickTipsEnabled &&
      quickTipRollSucceeded
    ) {
      setQuickTipIndex(rollQuickTip());
      quickTipMessage.open();
      const timer = setTimeout(() => {
        quickTipMessage.close();
      }, QUICKTIP_DURATION_MS);
      return () => clearTimeout(timer);
    } else if (!editor.drawerState.visible) {
      quickTipMessage.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.drawerState.visible]);

  return (
    <>
      {quickTipMessage.visible && (
        <Container id="message-quicktip" tawkWidgetVisible={tawkWidgetVisible}>
          <div id="quicktip-header">
            <div id="quicktip-header-icon">
              <img src={require("../../../assets/quicktip_info.svg")} alt="" />
            </div>
            <div id="quicktip-header-title">Quick Tip</div>
          </div>
          <div id="quicktip-body">{quickTipContents[quickTipIndex]}</div>
          <div id="quicktip-footer">
            <div
              className="hide-prompt text-muted"
              onClick={() => {
                setQuickTipEnabledCookie(false);
                quickTipMessage.close();
              }}
            >
              stop showing me quicktips
            </div>
          </div>
          <img
            className="close"
            onClick={() => {
              quickTipMessage.close();
            }}
            src={require("../../../assets/close.svg")}
            alt="close"
          />
        </Container>
      )}
    </>
  );
};
