import React, { createContext, ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import { AudioMaster } from "../../audio/AudioMaster";
import { Effect } from "../../audio/FX/Effect";
import { sendFX } from "../../audio/FX/fxValues";
import { FX, MainStore } from "../../store";

// GLOBALS
/*
 * There are two Send FX Banks (arrays of global send effects), one for online and offline
 * (rendering audio bounces)
 */
export var sendFXBank: Effect[] = [];
export var sendFXBankOffline: Effect[] = [];

/*
 * Disconnect and clear the offline send fx bank
 */
export const clearOfflineSendFXBank = () => {
  sendFXBankOffline.forEach(offlineSendEffect => {
    offlineSendEffect.disconnect();
  });
  sendFXBankOffline = [];
};

/*
 * All data provided by the MixingBoardContext
 * can be accessed in components via `const sendFX = useContext(MixingBoardContext);`
 */
export type MixingBoardContextType = {
  masteringChain: AudioNode;
  audioMaster: AudioMaster;
  getSendFXBank: () => Effect[];
  upsertSendEffect: (effectId: string) => Effect;
};

export const MixingBoardContext = createContext<MixingBoardContextType>(
  undefined
);

interface MixingBoardProviderProps {
  outputNode: AudioNode;
  audioMaster: AudioMaster;
  children: ReactNode;
}

/*
 * React context provider acting like a global mixing board for the audio node routing. It knows
 * about the master output, mastering chain (compressor, limiter, etc.) prior to hitting the master
 * output. It also has knowledge of the global send fx banks that are connected to the mastering
 * chain and provides utility functions to interact with the send fx banks (online and offline)
 */
const MixingBoardProvider = (props: MixingBoardProviderProps) => {
  const masteringChain = props.outputNode;
  const audioMaster = props.audioMaster;

  const bpm = useSelector<MainStore, number>(
    state => state.project.initialTrackData.bpm
  );

  // update send effect BPM for tempo-locked fx
  useEffect(() => {
    [sendFXBank, sendFXBankOffline].forEach(fxBank => {
      fxBank.forEach(sendEffect => {
        sendEffect.setBPM(bpm);
      });
    });
  }, [bpm]);

  /*
   * Instantiates and adds the send effect to the bank of send fx if it doesn't already exist
   *
   * Parameters:
   *  effectId (string): id of the send effect
   *
   * Returns:
   *  the send effect (Effect) or null if the effect is unknown (effectId unknown)
   */
  const upsertSendEffect = (effectId: string): Effect => {
    const theSendFXBank = getSendFXBank();

    // see if send effect is already instantiated and hooked up to output
    let sendEffect = theSendFXBank.find(
      effect => effect.fxData.id === effectId
    );
    if (sendEffect !== undefined) return sendEffect;

    // it doesn't exist, check effect is supported
    const fxData: FX = sendFX.find(effectData => effectData.id === effectId);
    if (fxData === undefined) return null;

    // effect is supported: instantiate it and connect to mastering chain output
    sendEffect = new Effect(compileProviderData(), fxData);
    sendEffect.connect(masteringChain);
    theSendFXBank.push(sendEffect);

    return sendEffect;
  };

  /*
   * Retrieve either the online or offline send fx bank depending on the hooked up
   * AudioMaster
   */
  const getSendFXBank = (): Effect[] => {
    return audioMaster.isOffline ? sendFXBankOffline : sendFXBank;
  };

  const compileProviderData = (): MixingBoardContextType => {
    return { masteringChain, audioMaster, getSendFXBank, upsertSendEffect };
  };

  return (
    <MixingBoardContext.Provider value={compileProviderData()}>
      {props.children}
    </MixingBoardContext.Provider>
  );
};

export default MixingBoardProvider;
