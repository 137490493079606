import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { EditorContext } from "../../context/EditorContext/EditorProvider";
import { MainStore } from "../../store";
import {
  format_seconds_to_measure_beat,
  format_seconds_to_smpte
} from "../../util/text_formatting";
import { SnapControls } from "../SnapControls";
import { ZoomControls } from "../ZoomControls";
import { Container } from "./styles";

export let ProjectInfoGrid = () => {
  const editor = useContext(EditorContext);

  const time = useSelector<MainStore, number>(
    state => state.editorState.locatorTime
  );
  const bpm = useSelector<MainStore, number>(
    state => state.project.initialTrackData.bpm
  );
  const beatsPerMeasure = useSelector<MainStore, number>(
    state => state.project.initialTrackData.beatsPerMeasure
  );

  return (
    <Container>
      <div className="time">
        <div className="smpte">{format_seconds_to_smpte(time)}</div>
        <div className="measures-beats">
          {format_seconds_to_measure_beat(time, bpm, beatsPerMeasure)}
        </div>
      </div>
      {editor.tightLayout && (
        <>
          <SnapControls />
          <ZoomControls />
        </>
      )}
    </Container>
  );
};
