import { inputStyle, theme } from "../../globalStyles";

const labelStyle = {
  display: "block",
  color: theme.textLight,
  fontSize: 15
};

export const projectSchema = [
  {
    type: "text",
    name: "projectName",
    fieldProps: {
      style: inputStyle,
      placeholder: "Project Name *",
      className: "project-input"
    },
    labelStyle: labelStyle,
    validation: {
      required: true,
      errorMessage: "Please add project name"
    }
  },
  {
    type: "text",
    name: "projectUrl",
    labelStyle: labelStyle,
    fieldProps: {
      style: inputStyle,
      placeholder: "Project URL",
      className: "project-input"
    },
    validation: {
      required: false
    }
  },
  {
    type: "textarea",
    name: "description",
    // required: true,
    labelStyle: labelStyle,
    fieldProps: {
      placeholder: "Project Description *",
      className: "project-input",
      style: { ...inputStyle, height: "auto", minHeight: 146 }
    },
    validation: {
      required: true,
      validate: value => {
        const words = value.split(" ").filter(Boolean).length;
        return words > 10 && words < 100;
      },
      errorMessage:
        "Please describe your project in detail. We require this for our license records. (10-100 words)."
    }
  }
];

export const paymentSchema = [
  {
    type: "text",
    name: "fullName",
    fieldProps: {
      className: "payment-input",
      placeholder: "Full Name",
      style: inputStyle
    },
    validation: {
      required: true,
      errorMessage: "Please make sure you added a valid full name"
    }
  },
  {
    type: "checkbox",
    name: "sameName",
    checkboxText: "Same Name on License",
    defaultValue: true,
    fieldProps: {
      style: {
        color: theme.textDark,
        fontSize: 16
      },
      className: "name-checkbox"
    },
    unregister: [
      {
        isNot: false,
        fieldsToRemove: ["licenseName"]
      }
    ]
  },
  {
    type: "textarea",
    name: "nameOnLicense",
    label: "Name/Address/Company on License",
    isConditional: true,
    conditions: [{ when: "sameName", is: false }],
    fieldProps: {
      className: "payment-input",
      placeholder: "Name/Address/Company on License",
      style: { ...inputStyle, height: 120 }
    },
    validation: {
      required: true,
      errorMessage: "Please make sure you added a valid license name"
    }
  },
  {
    type: "email",
    name: "email",
    fieldProps: {
      className: "payment-input",
      placeholder: "Email",
      style: inputStyle
    },
    validation: {
      required: true,
      errorMessage: "Please add a valid email address",
      validate: email => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }
    }
  }
];
