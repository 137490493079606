import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import Hotjar from "@hotjar/browser";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// Google Tag Manager
TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_ID });

// Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA, {
  gaOptions: {
    siteSpeedSampleRate: 100,
    sampleRate: 100
  }
});

// Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

// Hotjar
Hotjar.init(1925201, 6);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
