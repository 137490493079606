import styled from "styled-components";

export const Container = styled.div`
  .version-drawer {
    .ant-drawer-header {
      color: ${({ theme }) => theme.editor.textLight};
      background: ${({ theme }) => theme.editor.backgroundDark};
      border-bottom: 0;
      border-radius: 0;

      .ant-drawer-close {
        color: ${({ theme }) => theme.editor.textLight};
      }

      .ant-drawer-title {
        color: ${({ theme }) => theme.editor.textLight};
        font-family: ${({ theme }) => theme.fontHeavy};
      }
    }

    .ant-drawer-body {
      padding: 0px 24px 16px 24px;

      .ant-list-item {
        border-bottom-color: ${({ theme }) => theme.editor.backgroundMedium};
      }

      .ant-list-item-meta-title .version-date {
        color: ${({ theme }) => theme.editor.textLight};
        font-family: ${({ theme }) => theme.fontHeavy};
      }

      .ant-list-item-meta-title .version-date:hover {
        color: ${({ theme }) => theme.editor.highlight};
        cursor: pointer;
      }

      .ant-list-item-meta-description {
        color: ${({ theme }) => theme.editor.textMedium};
        font-family: ${({ theme }) => theme.fontMedium};
      }

      .current-version {
        font-family: ${({ theme }) => theme.fontHeavy};
      }
    }
  }
`;
