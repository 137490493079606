import { Middleware } from "redux";
import {
  loadCustomMix,
  loadMixFromInitialTrack,
  loadProjectLaneAction,
  loadLaneAction,
  loadMixFromInitialTrackCache,
  saveInitialTrackCache,
  loadAllHDAudioFiles
} from "../actions/actionCreators";
import {
  LOAD_CUSTOM_MIX_PROJECT,
  LOAD_MIX,
  LOAD_LANE,
  UPLOAD_LANE,
  SAVE_INITIAL_TRACK_CACHE,
  LOAD_HD_STEMS
} from "../actions/actionTypes";
import { AudioMaster } from "../audio/AudioMaster";
import { MainStore } from "../store";
import { genericInstrument } from "../store/test_initial_store";

/**
 * Provides access to global variables like audioMaster
 * @param audioMaster
 * @constructor
 */
export function ResolveGlobalsMiddleware(audioMaster: AudioMaster): Middleware {
  return store => next => action => {
    switch (action.type) {
      case LOAD_MIX:
        if (action.fromCache) {
          const loadAction = loadMixFromInitialTrackCache(
            action.projectId,
            action.paidAccess,
            audioMaster,
            action.onRehydrated,
            action.onLoaded
          );
          // @ts-ignore
          store.dispatch(loadAction);
        } else {
          const loadAction = loadMixFromInitialTrack(
            action.projectId,
            audioMaster,
            action.onRehydrated,
            action.onLoaded
          );
          // @ts-ignore
          store.dispatch(loadAction);
        }
        break;
      case LOAD_HD_STEMS:
        loadAllHDAudioFiles(audioMaster, store.dispatch, store.getState);
        break;
      case LOAD_CUSTOM_MIX_PROJECT:
        const loadCustomMixAction = loadCustomMix(
          action.customMixId,
          action.versionNumber,
          action.paidAccess,
          audioMaster,
          action.onRehydrated,
          action.onLoaded
        );
        // @ts-ignore
        store.dispatch(loadCustomMixAction);
        break;
      case SAVE_INITIAL_TRACK_CACHE:
        const saveInitialTrackCacheAction = saveInitialTrackCache(
          action.trackId,
          action.onSuccess,
          action.onFail
        );
        //@ts-ignore
        store.dispatch(saveInitialTrackCacheAction);
        break;
      case LOAD_LANE: {
        const state: MainStore = store.getState();
        const projectSpec = state.project.initialTrackData;

        const blockData = state.project.initialTrackData.blockFiles.find(
          x => x.id === action.id
        );
        const laneData = state.project.initialTrackData.lanes.find(
          x => x.id === action.id
        );

        const laneInfo = action.isBlock ? blockData : laneData;

        if (laneInfo) {
          const loadLane = loadProjectLaneAction(
            projectSpec,
            laneInfo,
            audioMaster,
            state.editorState.nthProjectLoaded
          );
          // @ts-ignore
          store.dispatch(loadLane);
        } else {
          console.warn("COULD'T FIND LANE WITH ID: ", action.id);
        }
        break;
      }
      case UPLOAD_LANE: {
        const state: MainStore = store.getState();
        const projectSpec = state.project.initialTrackData;
        const defaultInstrument = {
          ...genericInstrument,
          name: action.name
        };
        const loadLane = loadLaneAction(
          action.name,
          projectSpec.bpm,
          projectSpec.beatsPerMeasure,
          0,
          action.url,
          action.url,
          null,
          defaultInstrument,
          audioMaster,
          state.editorState.nthProjectLoaded
        );
        // @ts-ignore
        store.dispatch(loadLane);
        break;
      }
    }

    const result = next(action);
    return result;
  };
}
