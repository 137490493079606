import styled from "styled-components";

export const Container = styled.div`
  .label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 108%;
    margin-left: -8px;
    margin-top: 12px;
  }

  .ant-slider .ant-slider-handle {
    width: 19px;
    height: 19px;
  }

  .ant-slider .ant-slider-track {
    height: 8px;
  }

  .ant-slider .ant-slider-rail {
    height: 7px;
  }

  @media (max-width: 800px) {
    max-width: 250px;
  }
`;
