import * as React from "react";
import { IconButton } from "./IconButton";
import { Close } from "../../../assets/Close";

export function ExitButton(props:{onClick: ()=>void}) {
  return (
    <IconButton onClick={props.onClick}>
      <Close />
    </IconButton>
  );
}
