/*
 *  This template allows for creation of a custom encapsulated arrangement of
 *  AudioNodes that has an input (actually this class, a GainNode) and and output GainNode.
 *
 *  Because it inherits from GainNode, it can be connected as a destination from another
 *  AudioNode just like it were a normal AudioNode. The instance of this class is a GainNode
 *  that is the input audio node.
 *  See https://github.com/g200kg/webaudio-customnode for the gist (I ported to typescript)
 *
 *  Instructions for use:
 *  1. Inherit from EncapsulatedAudioNode
 *  2. Connect input node to encapsulated configuration of AudioNodes
 *      this._inputConnect(internalNodes);
 *  3. Connect output of internal audio nodes to output GainNode
 *      internalNodeOutput.connect(this.outputNode);
 *  4. Rejoice in the simplicity of it all
 */
export abstract class EncapsulatedAudioNode extends GainNode {
  public outputNode: GainNode;
  protected _inputConnect: (connect: AudioNode) => any;

  /*
   * Params:
   *    context (AudioContext | OfflineAudioContext): AudioContext (from AudioMaster)
   */
  constructor(context: AudioContext | OfflineAudioContext) {
    super(context, { gain: 1.0 });

    this.outputNode = context.createGain();

    // map functions to mimic normal AudioNode interface
    this._inputConnect = this.connect;
    this.connect = this._outputConnect;
  }

  // essentially overloads connect method using the output gain node as basis
  _outputConnect(
    destination: AudioNode,
    output?: number,
    input?: number
  ): AudioNode;
  _outputConnect(destinationParam: AudioParam, output?: number): void;

  _outputConnect(destination: any, output?: number, input?: number): any {
    return this.outputNode.connect(destination, output, input);
  }
}
