import { MixingBoardContextType } from "../../context/EditorContext/MixingBoardProvider";
import { FX, InternalFX } from "../../store";
import { genNewId } from "../../util/ids";
import { AudioNodeChain } from "../AudioNodeChain";
import { BlueDotEffectNode } from "./BlueDotEffectNode";
import { EncapsulatedAudioNode } from "./EncapsulatedAudioNode";
import { InternalFXNode } from "./InternalFXNode";
import { PassthroughSendNode } from "./PassthroughSendNode";
import { TunaEffectNode } from "./TunaEffectNode";

/*
 * An audio effect. Insert or Send.
 * Has a variable length internal effect chain wired in series
 * The underlying fx chain is controlled by a single knob value in [0,1] for simplicity.
 * This class itself is a GainNode and can be (dis)connected using standard AudioNode interface
 */
export class Effect extends EncapsulatedAudioNode {
  public readonly id: string = genNewId();
  public readonly internalFXChain: AudioNodeChain = null;
  public fxData: FX = null;

  /*
   * Construct an audio effect
   *
   * Parameters:
   *  mixingBoard: MixingBoardContext, encapsulates AudioContext
   *  fxData: json serialization of effect parameters and controls
   */
  constructor(mixingBoard: MixingBoardContextType, fxData: FX) {
    super(mixingBoard.audioMaster.context);

    this.fxData = fxData;
    this.internalFXChain = new AudioNodeChain(
      mixingBoard.audioMaster.context,
      fxData.internalFXChain.map(internalFXData =>
        internalFXFactory(mixingBoard, internalFXData)
      )
    );

    this._inputConnect(this.internalFXChain).connect(this.outputNode);
  }

  /*
   * Updates the bpm to be used for bpm-locked fx
   *
   *  Parameters:
   *    bpm: the new bpm to set (called when redux updates it)
   */
  public setBPM(bpm: number) {
    this.internalFXChain.daisyChain.forEach(
      (internalFXNode: InternalFXNode) => {
        internalFXNode.setBPM(bpm);
      }
    );
  }

  /*
   * When knob controlling insert effect updates, set the value
   *
   * Parameters:
   *  val: [0,1] knob value
   */
  public setValue(val: number) {
    this.internalFXChain.daisyChain.forEach(
      (internalFXNode: InternalFXNode) => {
        internalFXNode.setValue(val);
      }
    );
  }

  /*
   * Performs all disconnections, including disconnects to sends from internalFXChain
   */
  public disconnect() {
    this.internalFXChain.daisyChain.forEach(
      (internalFXNode: InternalFXNode) => {
        internalFXNode.cleanup().disconnect();
      }
    );
    this.internalFXChain.disconnect();
  }
}

/*
 * Factory design pattern for constructing different types of InternalFXNodes
 */
const internalFXFactory = (
  mixingBoard: MixingBoardContextType,
  fxConfig: InternalFX
) => {
  // effectId example: tuna.Overdrive
  const effectNamespace = fxConfig.effectId.split(".")[0];
  switch (effectNamespace) {
    case "tuna":
      return new TunaEffectNode(mixingBoard, fxConfig);
    case "bds":
      return new BlueDotEffectNode(mixingBoard, fxConfig);
    case "send":
      return new PassthroughSendNode(mixingBoard, fxConfig);
    default:
      return new GainNode(mixingBoard.audioMaster.context);
  }
};
