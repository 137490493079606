import * as React from "react";
import { useState, useCallback, useRef, useContext } from "react";
import { DraggableCore, DraggableData, DraggableEvent } from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import {
  CHANGE_REGION_DURATION,
  CHANGE_REGION_START,
  ChangeRegionDurationAction,
  ChangeRegionStartAction,
  MOVE_REGION,
  MOVE_REGION_DIFFERENT_LANE,
  MoveRegionAction,
  MoveRegionToDifferentLaneAction,
  SPLIT_REGION,
  SplitRegionAction,
  DELETE_REGION,
  DeleteRegionAction,
  DUPLICATE_REGION,
  DuplicateRegionAction,
  SET_EDITOR_MODE,
  SetEditorModeAction
} from "../../actions/actionTypes";
import {
  EditorMode,
  MainStore,
  Region,
  SnapMode,
  MuteSoloState
} from "../../store";
import { clamp, inRange } from "../../util/extra_math";
import { genNewId } from "../../util/ids"; // The default
import {
  bpm_to_seconds_per_measure,
  bpm_to_spb,
  quantize
} from "../../util/quantization";
import {
  pixels_to_second,
  seconds_to_pixels
} from "../../util/time_position_conversion";
import { getZoomProportion } from "../../util/zoom_levels";
import { RegionDropdownMenu } from "./RegionDropdownMenu";
import { RegionFade } from "./RegionFade";
import { RegionWaveform } from "./RegionWaveform";
import { Container } from "./styles";
import { GripVerticalLines } from "../../../assets/GripVerticalLines";
import { CircleArrow } from "../../../assets/CircleArrow";
import { theme } from "../../../globalStyles";
import { EditorContext } from "../../context/EditorContext/EditorProvider";

export interface RegionProps {
  regionId: string;
  laneIndex: number;
  laneId: string;
}

/**
 * Notes:
 * In order to do our zoom correctly, we have to always use the zoom proportion.
 * When getting the pixels to display we *
 * When getting the pixels to changes time, we have to /
 */
export let RegionBlock = (props: RegionProps) => {
  const editor = useContext(EditorContext);

  const LANE_HEIGHT = editor.tightLayout
    ? theme.editor.tightLaneHeightNoPx
    : theme.editor.laneHeightNoPx;

  const region = useSelector<MainStore, Region>(state => {
    return state.project.regions.byId[props.regionId];
  });
  const visualStartTime = useSelector<MainStore, number>(state => {
    return state.project.regions.byId[props.regionId].visualStartTime;
  });
  const visualDuration = useSelector<MainStore, number>(state => {
    return state.project.regions.byId[props.regionId].visualDuration;
  });
  const audioStartTime = useSelector<MainStore, number>(
    state => state.project.regions.byId[props.regionId].audioStartTime
  );
  const laneMuteSoloState = useSelector<MainStore, number>(
    state => state.project.lanes.byId[props.laneId].laneStatus.muteSoloState
  );
  const solodLaneIds = useSelector<MainStore, string[]>(
    state => state.project.lanes.solodLaneIds
  );
  // calculate whether region is sounding or not: whether lane the region is on is muted or another lane is solod but not this lane
  const regionIsMuted =
    laneMuteSoloState === MuteSoloState.MUTE ||
    (solodLaneIds.length > 0 && solodLaneIds.indexOf(props.laneId) === -1);

  const snapMode = useSelector<MainStore, SnapMode>(
    state => state.uiState.snap
  );
  const bpm = useSelector<MainStore, number>(
    state => state.project.initialTrackData.bpm
  );
  const beatsPerMeasure = useSelector<MainStore, number>(
    state => state.project.initialTrackData.beatsPerMeasure
  );
  const zoomLevel = useSelector<MainStore, number>(
    state => state.uiState.zoomLevel
  );
  const numLanes = useSelector<MainStore, number>(
    state => state.project.lanes.laneIds.length
  );
  const mode = useSelector<MainStore, EditorMode>(
    state => state.editorState.mode
  );

  const [isDragging, setIsDragging] = useState(false);

  const zoomProportion = getZoomProportion(zoomLevel);
  const secondsPerMeasure = bpm_to_seconds_per_measure(bpm, beatsPerMeasure);
  const secondsPerBeat = bpm_to_spb(bpm);

  const pixel_offset_start = seconds_to_pixels(visualStartTime);
  const pixel_width = seconds_to_pixels(visualDuration);
  const pixel_offset_end = pixel_offset_start + pixel_width;
  const dispatch = useDispatch();

  const audioSrcDuration = useSelector<MainStore, number>(
    state => state.project.customMixInfo.length
  );

  const maxDurationPossible = audioSrcDuration - audioStartTime;
  const maxWidthPossible = seconds_to_pixels(maxDurationPossible);

  const [stateDeltaX, setStateDeltaX] = React.useState(0);
  const [stateDeltaY, setStateDeltaY] = React.useState(0);

  const [stateOffsetX, setStateOffsetX] = React.useState(pixel_offset_start);
  const [stateOffsetY, setStateOffsetY] = React.useState(0);

  const [stateEndDeltaX, setStateEndDeltaX] = React.useState(0);

  const [stateStartDeltaX, setStateStartDeltaX] = React.useState(0);
  const [stateStartDeltaXLocked, setStateStartDeltaXLocked] = React.useState(0); // the actual clamped value

  const [stateWidth, setStateWidth] = React.useState(pixel_width);

  const [stateRegionIsHovered, setStateRegionIsHovered] = React.useState(false);
  const [stateRegionEngaged, setStateRegionEngaged] = React.useState(false);

  React.useEffect(() => {
    setStateOffsetX(pixel_offset_start);
    setStateOffsetY(0);
    setStateWidth(pixel_width);
    // eslint-disable-next-line
  }, [region, pixel_offset_start, pixel_width]);

  function pixelToTransform(px: number, py: number = 0) {
    return `translateX(${px}px) translateY(${py}px)`;
  }

  function onStart(e: DraggableEvent, data: DraggableData) {
    // Reset before we change anything or else the region will move on stop even if the user didn't drag.
    setStateDeltaX(0);
    setStateDeltaY(0);
    setIsDragging(true);
  }

  function onStop() {
    const locked =
      stateDeltaX !== 0
        ? lockPositionX(pixel_offset_start, stateDeltaX, true)
        : pixel_offset_start;
    const newStartTime = pixels_to_second(locked);

    // @ts-ignore
    const laneDelta = lockPositionY(stateOffsetY) / LANE_HEIGHT;
    const newLaneIndex = clamp(0, props.laneIndex + laneDelta, numLanes - 1);

    setIsDragging(false);

    if (newLaneIndex !== props.laneIndex) {
      dispatch<MoveRegionToDifferentLaneAction>({
        type: MOVE_REGION_DIFFERENT_LANE,
        newLaneIndex,
        oldLaneIndex: props.laneIndex,
        regionId: props.regionId,
        newStartTime
      });
    } else {
      dispatch<MoveRegionAction>({
        type: MOVE_REGION,
        newStartTime,
        regionId: props.regionId
      });
    }
  }

  function onDrag(e: DraggableEvent, data: DraggableData) {
    const newDeltaX = stateDeltaX + data.deltaX / zoomProportion;
    setStateDeltaX(newDeltaX);
    const locked_x = lockPositionX(pixel_offset_start, newDeltaX, true);

    const newDeltaY = stateDeltaY + data.deltaY;
    setStateDeltaY(newDeltaY);

    const locked_y = lockPositionY(newDeltaY);

    setStateOffsetX(locked_x);
    setStateOffsetY(locked_y);
  }

  function lockPositionY(y) {
    // @ts-ignore
    const lowest = -props.laneIndex * LANE_HEIGHT;
    // @ts-ignore
    const highest = (numLanes - props.laneIndex - 1) * LANE_HEIGHT;
    // @ts-ignore
    return clamp(quantize(y, LANE_HEIGHT), lowest, highest);
  }

  /*
   * Returns absolute pixel position after movement incorporating snapping (bar/beat/none)
   *
   * Parameters
   * ----------
   * x (number): pixel position
   * dX (number): pixel offset (positive or negative)
   * isRelative (boolean): whether snapping should be relative to current position (snapping dX)
   *    or snap absolutely to barlines / beats (snapping x + dX)
   *
   * Note: always return true for equality fn because we are always getting fresh data
   */
  function lockPositionX(x: number, dX: number, isRelative: boolean = false) {
    const timeToSnap = pixels_to_second(isRelative ? dX : x + dX);
    let snappedTime = null;
    switch (snapMode) {
      case SnapMode.BAR:
        snappedTime =
          Math.round(timeToSnap / secondsPerMeasure) * secondsPerMeasure;
        break;
      case SnapMode.BEAT:
        snappedTime = Math.round(timeToSnap / secondsPerBeat) * secondsPerBeat;
        break;
      default:
        snappedTime = timeToSnap;
    }

    if (isRelative) {
      snappedTime += pixels_to_second(x);
    }

    return seconds_to_pixels(Math.max(snappedTime, 0));
  }

  const regionStartHandlers = {
    getLockedPosition(deltaX) {
      const locked_x = lockPositionX(pixel_offset_start, deltaX, false);

      // make sure not out of bounds of actual audio file
      const min_x_allowed = seconds_to_pixels(visualStartTime - audioStartTime);
      return Math.max(locked_x, min_x_allowed);
    },

    onStart: (e: DraggableEvent, data: DraggableData) => {
      setStateStartDeltaX(0);
      setStateStartDeltaXLocked(0);
      setStateRegionEngaged(true);
    },
    onDrag: (e: MouseEvent, data: DraggableData) => {
      // Where we were at + how we moved divided by the zoom to get real correct pixel to second conversion.
      const moved = stateStartDeltaX + e.movementX / zoomProportion;
      const newStartDeltaX = clamp(moved, -pixel_offset_start, pixel_width);
      setStateStartDeltaX(newStartDeltaX);

      const locked_x = regionStartHandlers.getLockedPosition(newStartDeltaX);
      const locked_delta = locked_x - pixel_offset_start;

      setStateStartDeltaXLocked(locked_delta);
      setStateWidth(pixel_offset_end - locked_x);
      setStateOffsetX(locked_x);
    },
    onStop(e: DraggableEvent, data: DraggableData) {
      const newStartDeltaX = clamp(
        stateStartDeltaX,
        -pixel_offset_start,
        pixel_width
      );
      const locked_x = regionStartHandlers.getLockedPosition(newStartDeltaX);

      dispatch<ChangeRegionStartAction>({
        newStartTime: pixels_to_second(locked_x),
        newStartTimeOffsetPixels: newStartDeltaX,
        regionId: props.regionId,
        type: CHANGE_REGION_START
      });
      setStateRegionEngaged(false);
      setStateStartDeltaX(0);
      setStateStartDeltaXLocked(0);
    }
  };

  const regionEndHandlers = {
    getWidth(deltaX) {
      const lockedEnd = lockPositionX(
        pixel_offset_start + pixel_width,
        deltaX,
        false
      );
      const width = lockedEnd - pixel_offset_start;
      // need to make sure not going over actual audio length
      return Math.min(width, maxWidthPossible);
    },
    onStart: (e: DraggableEvent, data: DraggableData) => {
      setStateEndDeltaX(0);
      setStateRegionEngaged(true);
    },
    onDrag: (e: MouseEvent, data: DraggableData) => {
      const newEndDeltaX = stateEndDeltaX + e.movementX / zoomProportion;
      setStateEndDeltaX(newEndDeltaX);
      setStateWidth(regionEndHandlers.getWidth(newEndDeltaX));
    },
    onStop(e: DraggableEvent, data: DraggableData) {
      const newDuration = pixels_to_second(
        regionEndHandlers.getWidth(stateEndDeltaX)
      );
      setStateRegionEngaged(false);

      dispatch<ChangeRegionDurationAction>({
        type: CHANGE_REGION_DURATION,
        newDuration,
        regionId: props.regionId
      });
    }
  };

  const isSplitMode = mode === EditorMode.SPLIT;
  const [splitMarkRef, setSplitMarkRef] = useState(null);

  function getPixelsXOffsetNormalizedRelativeToCurrentTarget(e) {
    // The size of the box on the screen. ALl based on the viewport... Which might not work for the scroll..
    const boundingClientRect = e.currentTarget.getBoundingClientRect();
    const region_client_x = boundingClientRect.x;
    // based on the viewport.
    const clientX = e.clientX;
    const deltaX = clientX - region_client_x;

    return deltaX / zoomProportion;
  }

  // When showing the visual bar, we don't want to take the zoom proportion into account..
  function getVisualOffset(e) {
    // The size of the box on the screen. ALl based on the viewport... Which might not work for the scroll..
    const boundingClientRect = e.currentTarget.getBoundingClientRect();
    const region_client_x = boundingClientRect.x;
    // based on the viewport.
    const clientX = e.clientX;
    return clientX - region_client_x;
  }

  /**
   * SPLITTING
   */
  const onMouseMove = e => {
    if (!isSplitMode) {
      return;
    }

    const x_offset = getVisualOffset(e);
    // Fixes issue on zoom - we need to be able to split any section of lane.
    if (x_offset > pixel_width * zoomProportion) {
      return;
    }

    splitMarkRef.style.display = "initial";
    splitMarkRef.style.transform = pixelToTransform(x_offset, 0);
  };

  const onMouseLeave = e => {
    if (!stateRegionEngaged) {
      setStateRegionIsHovered(false);
    }

    if (!isSplitMode) {
      return;
    }

    splitMarkRef.style.display = "none";
  };

  const onMouseEnter = e => {
    setStateRegionIsHovered(true);

    if (!isSplitMode) {
      return;
    }

    splitMarkRef.style.display = "initial";
  };

  const onClick = e => {
    if (!isSplitMode) {
      return;
    }
    const offset = getPixelsXOffsetNormalizedRelativeToCurrentTarget(e);
    const secondsSplit = pixels_to_second(offset);

    if (!inRange(secondsSplit, 0, visualDuration)) {
      return;
    }
    dispatch<SplitRegionAction>({
      newRegionId: genNewId(),
      regionId: props.regionId,
      split_offset_seconds: secondsSplit,
      laneId: props.laneId,
      type: SPLIT_REGION
    });
    splitMarkRef.style.display = "none";
  };

  const handleKeyDown = useCallback(
    e => {
      if (["text", "number", "textarea"].includes(e.target.type)) return;

      switch (e.keyCode) {
        case 27:
          // Esc: exit split mode
          if (isSplitMode) {
            dispatch<SetEditorModeAction>({
              type: SET_EDITOR_MODE,
              mode: EditorMode.NORMAL
            });
            if (stateRegionIsHovered) {
              splitMarkRef.style.display = "none";
            }
          }
          break;
        case 8:
        case 46:
        case 88:
          // Backspace, Delete, X: delete hovered region
          if (stateRegionIsHovered) {
            dispatch<DeleteRegionAction>({
              type: DELETE_REGION,
              regionId: props.regionId,
              laneId: props.laneId
            });
          }
          break;
        case 68:
          // D: duplicate region
          if (stateRegionIsHovered) {
            dispatch<DuplicateRegionAction>({
              type: DUPLICATE_REGION,
              regionId: props.regionId,
              laneId: props.laneId,
              newRegionId: genNewId()
            });
          }
          break;
        case 83:
          // S: split region
          if (stateRegionIsHovered) {
            dispatch<SetEditorModeAction>({
              type: SET_EDITOR_MODE,
              mode: EditorMode.SPLIT
            });
          }
          break;
        default:
          e.preventDefault();
      }
    },
    [
      isSplitMode,
      stateRegionIsHovered,
      splitMarkRef,
      dispatch,
      props.regionId,
      props.laneId
    ]
  );

  const handleKeyDownRef = useRef(handleKeyDown);
  React.useEffect(() => {
    handleKeyDownRef.current = handleKeyDown;
  }, [handleKeyDown]);

  React.useEffect(() => {
    const keyDownHandler = e => handleKeyDownRef.current(e);
    if (editor.drawerState.visible) {
      document.addEventListener("keydown", keyDownHandler);
    } else {
      document.removeEventListener("keydown", keyDownHandler);
    }
    return () => document.removeEventListener("keydown", keyDownHandler);
  }, [editor.drawerState.visible]);

  const handleDropdownActive = (visible: boolean) => {
    setDropdownActive(visible);
  };

  const [points, setPoints] = useState("");
  const [dropdownActive, setDropdownActive] = useState(false);

  React.useEffect(() => {
    const polygonBuilder = [];
    const stateWidthZoom = stateWidth * zoomProportion;
    // @ts-ignore
    const center = LANE_HEIGHT / 2;

    const pixelStartOffset =
      seconds_to_pixels(region.startFadeTime) * zoomProportion;
    if (pixelStartOffset > 0) {
      polygonBuilder.push(`0,${center} ${pixelStartOffset},${LANE_HEIGHT}`);
    } else {
      polygonBuilder.push(`0,0 0,${LANE_HEIGHT}`);
    }

    const pixelEndOffset =
      seconds_to_pixels(region.endFadeTime) * zoomProportion;
    if (pixelEndOffset > 0) {
      const x = stateWidth * zoomProportion - pixelEndOffset;

      polygonBuilder.push(
        `${x},${LANE_HEIGHT} ${stateWidthZoom},${center} ${x},0`
      );
    } else {
      polygonBuilder.push(
        `${stateWidthZoom},${LANE_HEIGHT} ${stateWidthZoom},0`
      );
    }

    if (pixelStartOffset > 0) {
      polygonBuilder.push(`${pixelStartOffset},0 0,${center}`);
    }

    const polygonString = polygonBuilder.join(" ");
    setPoints(polygonString);
    // eslint-disable-next-line
  }, [
    region.startFadeTime,
    region.startFadeTimeOverlap,
    region.endFadeTime,
    region.endFadeTimeOverlap,
    stateWidth,
    zoomProportion
  ]);

  // show left (start) and right (end) toolsets on hover
  const toolsVisible =
    stateRegionIsHovered || stateRegionEngaged || dropdownActive;

  // hide right (end) region toolset if region width is too small
  const endRegionToolsetVisible = stateWidth * zoomProportion > 50;

  const className = `region ${isSplitMode ? "split-mode " : ""} ${
    isDragging ? "dragging " : ""
  } ${toolsVisible ? "hovering " : ""} ${regionIsMuted ? "muted " : ""}`;

  return (
    <RegionDropdownMenu
      regionId={props.regionId}
      laneId={props.laneId}
      placement="bottomLeft"
      isContext={true}
      onVisibilityChange={handleDropdownActive}
    >
      <div className="region-container">
        <DraggableCore
          onStart={onStart}
          onStop={onStop}
          onDrag={onDrag}
          cancel=".region-sub-handle"
          disabled={isSplitMode}
        >
          <Container
            id={`region-${props.regionId}`}
            onClick={onClick}
            className={className}
            style={{
              transform: pixelToTransform(
                stateOffsetX * zoomProportion,
                stateOffsetY
              ),
              width: stateWidth * zoomProportion
            }}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
            showHoverState={toolsVisible}
            tightLayout={editor.tightLayout}
          >
            <svg className="background-fill">
              <polygon points={points} />
            </svg>

            <div className="region-toolset start">
              <RegionFade
                visible={toolsVisible}
                laneId={props.laneId}
                regionId={props.regionId}
                isStart={true}
              />
              <DraggableCore
                onStart={regionStartHandlers.onStart}
                onStop={regionStartHandlers.onStop}
                onDrag={regionStartHandlers.onDrag}
              >
                <div className="region-start region-sub-handle">
                  <GripVerticalLines />
                </div>
              </DraggableCore>
              <RegionDropdownMenu
                regionId={props.regionId}
                laneId={props.laneId}
                placement="bottomLeft"
                isContext={false}
              >
                <span className="region-toolset btn-toolset region-menu start">
                  <CircleArrow />
                </span>
              </RegionDropdownMenu>
            </div>

            <div
              className={`region-toolset end ${
                !endRegionToolsetVisible ? "hidden" : ""
              }`}
            >
              <RegionFade
                visible={toolsVisible}
                laneId={props.laneId}
                regionId={props.regionId}
                isStart={false}
              />
              <DraggableCore
                onStart={regionEndHandlers.onStart}
                onStop={regionEndHandlers.onStop}
                onDrag={regionEndHandlers.onDrag}
              >
                <div className="region-end region-sub-handle">
                  <GripVerticalLines />
                </div>
              </DraggableCore>
              <RegionDropdownMenu
                regionId={props.regionId}
                laneId={props.laneId}
                placement="bottomRight"
                isContext={false}
              >
                <span className="region-toolset btn-toolset region-menu end">
                  <CircleArrow />
                </span>
              </RegionDropdownMenu>
            </div>

            <div className="region-split-marker" ref={setSplitMarkRef} />

            <RegionWaveform
              regionId={props.regionId}
              audioStartTimeDeviation={pixels_to_second(stateStartDeltaXLocked)}
            />
          </Container>
        </DraggableCore>
      </div>
    </RegionDropdownMenu>
  );
};
