import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { MessageContext } from "../../context/MessageContext";
import AppRouter from "../../routers";
import Message from "../Message";
import { Container } from "./styles";

const AppContainer = () => {
  const messageContext = useContext(MessageContext);

  // if ?creatorLicense is in the URL, set the creatorLicenseAvailable in localStorage
  const queryString = useLocation().search;
  const searchParams = new URLSearchParams(queryString);
  if (searchParams.has("creatorLicense")) {
    localStorage.setItem(
      "creatorLicenseAvailable",
      searchParams.get("creatorLicense").toLowerCase() === "true"
        ? "true"
        : "false"
    );
  }

  return (
    <Container>
      {messageContext.show && (
        <div className="message-container">
          <Message {...messageContext} />
        </div>
      )}
      <AppRouter />
    </Container>
  );
};

export default AppContainer;
