import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InitialLanesData, MainStore, Lane } from "../../store";
import { UPLOAD_LANE, UploadLaneAction } from "../../actions/actionTypes";
import AntModal from "../../../components/AntModal";
import { Container } from "./styles";
import { Button } from "../Button";
import { LaneName } from "../LaneName";
import { laneColors } from "../../util/lane_color_helpers";
import { Alert } from "antd";

export const AddLaneModal = ({ modalState }) => {
  const [dragActive, setDragActive] = React.useState(false);
  const [uploadError, setUploadError] = React.useState("");

  const fileInputRef = useRef(null);

  const originalLanes = useSelector<MainStore, InitialLanesData[]>(
    state => state.project.initialTrackData.lanes
  );
  const lanes = useSelector<MainStore, Lane[]>(state =>
    Object.values(state.project.lanes.byId)
  );
  const originalLaneColors = originalLanes.map(origLane => {
    const correspondingLane = lanes.filter(lane => {
      return lane.originalId === origLane.id;
    });
    return correspondingLane.length > 0
      ? correspondingLane[0].color
      : laneColors[laneColors.length - 1][0];
  });
  const dispatch = useDispatch();

  const openFileSelect = () => {
    setUploadError("");
    fileInputRef.current.click();
  };

  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    setUploadError("");
    setDragActive(false);
    uploadAudio(event);
  };

  const uploadAudio = event => {
    const fileList: FileList = event.target.files || event.dataTransfer.files;
    if (fileList.length === 0) {
      return;
    }

    const file = fileList.item(0);

    try {
      if (
        file.name.toLowerCase().includes(".wav") ||
        file.name.toLowerCase().includes(".mp3")
      ) {
        const url = URL.createObjectURL(file);
        const fileName = file.name.replace(".wav", "").replace(".mp3", "");
        dispatch<UploadLaneAction>({
          type: UPLOAD_LANE,
          url,
          name: fileName
        });
        modalState.close();
      } else {
        throw Error("Invalid audio file, not a .mp3 or .wav");
      }
    } catch {
      setUploadError(
        "Error loading audio file. Please check that it is a valid .wav or .mp3 file"
      );
    }
  };

  return (
    <AntModal modalState={modalState}>
      <Container>
        {uploadError !== "" && (
          <Alert message={uploadError} type="error" showIcon />
        )}
        <div id="lane-upload-container">
          <div className="equal-columns">
            <div id="upload-btn-container">
              <Button height="auto" px="24" onClick={openFileSelect}>
                Upload Audio File
              </Button>
              <p style={{ paddingTop: "1em" }}>
                Audio from your computer will not be uploaded to our server,
                only used locally
              </p>
              <input
                id="files"
                type="file"
                accept=".mp3,.wav"
                ref={fileInputRef}
                onChange={uploadAudio}
                hidden
              />
            </div>
            <div id="upload-or">OR</div>
            <div
              id="upload-drag-drop-container"
              className={dragActive ? "drag-active" : ""}
              onDragEnter={handleDrag}
            >
              <p>Drag and drop audio file here (.wav, .mp3)</p>
              <div
                id="drag-overlay"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            </div>
          </div>
        </div>
        <div id="original-lanes-container">
          <div id="original-lanes-header">Original Tracks</div>
          <div id="original-lanes-list-container">
            {originalLanes.map((lane, iLane) => (
              <LaneName
                key={lane.id}
                lane={lane}
                color={originalLaneColors[iLane]}
                afterAdd={modalState.close}
              />
            ))}
          </div>
        </div>
      </Container>
    </AntModal>
  );
};
