import { Middleware } from "redux";
import {
  SEEK,
  SET_LOCATOR_TIME,
  SET_PLAYBACK_STATE
} from "../actions/actionTypes";
import { MainStore, PlaybackState } from "../store";
import { AudioMaster } from "./AudioMaster";

/**
 * Syncs state with WebAudio clock
 *
 */
export function AudioMiddleWare(audioMaster: AudioMaster): Middleware {
  let _store = null;
  const loop = () => {
    requestAnimationFrame(loop);
    if (audioMaster.getLastPlayAbsoluteTime() != null) {
      const songTime = audioMaster.getCurrentSongTime();
      const state: MainStore = _store.getState();

      if (songTime >= state.project.customMixInfo.length) {
        _store.dispatch({
          type: SET_PLAYBACK_STATE,
          state: PlaybackState.PAUSED,
          actionSongTime: 0
        });
        _store.dispatch({
          type: SEEK,
          seconds: 0
        });
      } else {
        _store.dispatch({
          type: SET_LOCATOR_TIME,
          seconds: songTime
        });
      }
    }
  };
  requestAnimationFrame(loop);

  return store => next => action => {
    switch (action.type) {
      case SET_PLAYBACK_STATE:
        if (action.state === PlaybackState.PLAYING) {
          audioMaster.play(action.actionSongTime);
          _store = store;
        } else {
          audioMaster.pause();
        }
        break;
      case SEEK:
        if (audioMaster.isPlaying()) {
          audioMaster.play(action.seconds);
        }
    }

    const result = next(action);
    return result;
  };
}
