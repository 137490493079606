import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, List } from "antd";
import { Container } from "./styles";
import { CustomMixInfo, CustomMixVersion, MainStore } from "../../store";
import { SetAlertAction, SET_ALERT } from "../../actions/actionTypes";
import { getCustomMixVersions } from "../../api/WebAPI";
import Spinner from "../../../components/Spinner";
import AvatarTooltipName from "../../../components/Avatar";
import { formatFullDate } from "../../../utils/formatDateHelper";
import { EditorContext } from "../../context/EditorContext/EditorProvider";

export let VersionControl = props => {
  const dispatch = useDispatch();

  const [customMixVersions, setCustomMixVersions] = useState(null);

  const customMixInfo = useSelector<MainStore, CustomMixInfo>(
    state => state.project.customMixInfo
  );

  const editor = useContext(EditorContext);

  // Get the CustomMixVersion on render
  useEffect(() => {
    if (customMixInfo.id) {
      getCustomMixVersions(customMixInfo.id)
        .then((customMixVersions: CustomMixVersion[]) => {
          setCustomMixVersions(customMixVersions);
        })
        .catch(error => {
          dispatch<SetAlertAction>({
            type: SET_ALERT,
            id: "QUERY_CUSTOM_MIX_VERSIONS_ERROR",
            role: "error",
            title: "Error Loading Saves",
            message:
              "There was an error retrieving your projects. Please try again or contact our support team for assistance."
          });
        });
    }
  }, [customMixInfo.id, customMixInfo.version.versionNumber, dispatch]);

  const openVersion = (versionNumber: number) => {
    editor.requestCustomMixIdOpen(customMixInfo.id, versionNumber);
    props.drawerState.close();
  };

  return (
    <Container>
      <Drawer
        title="Version History"
        placement="right"
        getContainer={false}
        closable={true}
        onClose={e => {
          props.drawerState.close();
        }}
        visible={props.drawerState.visible}
        width={400}
        className={"version-drawer"}
      >
        {customMixVersions === null && <Spinner height={"50px"}></Spinner>}
        {customMixVersions !== null && (
          <List
            itemLayout="horizontal"
            dataSource={customMixVersions}
            renderItem={(customMixVersion: CustomMixVersion) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <AvatarTooltipName
                      name={customMixInfo.user.fullName}
                      className={"icon"}
                      profileColor={customMixInfo.user.profileColor}
                      profileIcon={customMixInfo.user.profileIcon}
                      size={22}
                    />
                  }
                  title={
                    <div
                      className="version-date"
                      onClick={() =>
                        openVersion(customMixVersion.versionNumber)
                      }
                    >
                      {formatFullDate(customMixVersion.createdAt)}
                    </div>
                  }
                  description={
                    customMixInfo.version.versionNumber ===
                    customMixVersion.versionNumber ? (
                      <span className="current-version">Current Version</span>
                    ) : (
                      `Version ${customMixVersion.versionNumber}`
                    )
                  }
                />
              </List.Item>
            )}
          />
        )}
      </Drawer>
    </Container>
  );
};
