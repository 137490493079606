import React, { useEffect, useState } from "react";
import { seconds_to_pixels } from "../../util/time_position_conversion";
import { linearInterp, realLogInterp } from "../../util/interpolation";
import { linearToDB } from "../../util/extra_math";
import { Envelopes } from "../../store";
import pSBC from "shade-blend-color";

/*
 * Parameters controlling aesthetic of top (main) waveform
 *
 * timeSkipRandSeconds: range to randomize seconds skip between top envelope samples
 * gateThresholdDB: dB threshold to consider amplitude as silence
 * percentSignificant: what % difference between envelope samples constitutes a significant point
 * percentPeakSignificant: what % difference between envelope samples constitutes a peak
 * logSamples: whether to log the envelope sample (in general, this makes waveforms more beefy)
 */
export interface TopWaveAesthetics {
  timeSkipRandSeconds: number[];
  gateThresholdDB: number;
  percentSignificant: number;
  percentPeakSignificant: number;
  logSamples: boolean;
}

/*
 * Parameters controlling aesthetic of bottom (outline) waveform
 *
 * timeSkipRandSeconds: range to randomize seconds skip between bottom envelope samples
 * gateThresholdDB: dB threshold to consider amplitude as silence
 * percentSignificant: what % difference between envelope samples constitutes a significant point
 * logSamples: whether to log the envelope sample (in general, this makes waveforms more beefy)
 */
export interface BottomWaveAesthetics {
  timeSkipRandSeconds: number[];
  gateThresholdDB: number;
  percentSignificant: number;
  logSamples: boolean;
}

/*
 * Parameters controlling gems drawn over the waveform
 *
 * threads: number of sweeps to run (will overlap gems)
 * waveformSkipSecondsRange: randomly choose seconds to skip forward in waveform envelope
 * gateThresholdDB: dB threshold that makes sample virtually silent
 * initialCliffChance: probability gem starts with a vertical line, hard cliff
 * gemSizeRand: range of number of vertices for each gem
 * vertexSampleSkipSecondsRange: once drawing a gem, random range to skip between samples for each vertex
 * gemBothChance: chance to draw sample value on both top and bottom of gem polygon
 * hueShadeRange: range to randomly tweak color shade of gem from original waveform color
 * rotationRange: range in degrees to rotate gems
 * opacityRange: range of opacities for each gem
 * scaleRange: range of scales for each gem
 * logSamples: whether to use log sample values
 * lightenBlendColor: hex color string to blend towards when manipulating waveform/gem hues
 */
export interface GemAesthetics {
  threads: number;
  waveformSkipSecondsRange: number[];
  gateThresholdDB: number;
  initialCliffChance: number;
  gemSizeRand: number[];
  vertexSampleSkipSecondsRange: number[];
  gemBothChance: number;
  hueShadeRange: number[];
  rotationRange: number[];
  opacityRange: number[];
  scaleRange: number[];
  logSamples: boolean;
  lightenBlendColor: string;
}

/*
 * Parameters controlling the aesthetics of the waveform. Divided into aesthetics for the top (main)
 * waveform, the bottom (outline) waveform, and superimposed gems.
 *
 * colors: list of two hex colors for the waveform (usually one dark one lighter)
 * topWave: aesthetics for the top (main) waveform (see @TopWaveAesthetics)
 * bottomWave: aesthetics for the bottom (outline) waveform (see @BottomWaveAesthetics)
 * gems: aesthetics for the gems (see @GemAesthetics)
 * referenceWave: show high-res reference waveform
 */
export interface WaveAesthetics {
  colors: string[];
  referenceWave: boolean;
  topWave?: TopWaveAesthetics;
  bottomWave?: BottomWaveAesthetics;
  bottomWaveGems?: GemAesthetics;
  gems?: GemAesthetics;
}

/*
 * Parameters for the Wave component, controlling how a waveform of samples is visualized
 *
 * startTime: start time in seconds of the wave render in the underlying stem
 * audioStartTimeDeviation: seconds offset
 * zoom: zoom proportion modified when the user zooms in or out
 * seed: PRNG seed, this keeps waveform renders consistent across runs
 * maxHeightFromCenter: height of half-wave rectified signal (half of drawing space)
 * aesthetics: parameters detailing how to visually render each layer of the waveform
 */
export interface WaveVisualizationParams {
  startTime: number;
  audioStartTimeDeviation: number;
  zoom: number;
  envelope: Envelopes;
  seed: number;
  maxHeightFromCenter: number;
  aesthetics: WaveAesthetics;
}

/*
 * Internal interface for a point with x, y coords
 */
interface Point {
  x: number;
  y: number;
}

/*
 * Internal object interface for a gem render
 * points: list of polygon points composing gem
 * color: base hex color of gem
 * scale: float scale (1 = normal, <1 = smaller, >1 = bigger)
 * rotation: float rotation in degrees (0 = normal)
 * opacity: float opacity (1 = normal, <1 = more transparent)
 */
interface Gem {
  points: Point[];
  color: string;
  scale: number;
  rotation: number;
  opacity: number;
}

// Interface for the gem sweep function
interface GemSweepParams {
  aesthetics: GemAesthetics;
  baseColor: string;
}

/*
 * This component visualizes a waveform envelope by drawing polygons on a number of layers.
 * see @WaveVisualizationParams for component parameter details
 */
export function Wave({
  startTime,
  audioStartTimeDeviation,
  zoom,
  envelope,
  seed,
  maxHeightFromCenter,
  aesthetics
}: WaveVisualizationParams) {
  const waveformTimeSeconds =
    (envelope.channelAmplitudeSamples[0].length * envelope.hopSize +
      envelope.windowSize) /
    envelope.sampleRate;

  const getEmptyLayers = () => {
    return {
      reference: [],
      top: [],
      bottom: [],
      bottomGems: [],
      gems: []
    };
  };

  // Polygon point caches are split into two caches:
  // 1. waveformLayers: position of points in polygons for each layer of the waveform render
  // 2. waveformPolygonPoints: list of svg strings for each polygon of each layer of waveform
  // There are 2 caches for performance: waveformLayers is updated whenever the envelope changes,
  // these calculations are heavy, looping over the envelope to form the polygons
  // while the waveformPolygonPoints cache is updated when the region / zoom changes and consists
  // of lighter calculations translating the points to svg point strings for the actual render
  const [waveformLayers, setWaveformLayers] = useState(getEmptyLayers());
  const [waveformPolygonPoints, setWaveformPolygonPoints] = useState(
    getEmptyLayers()
  );

  /*
   * Pseudo-random number generator, generates deterministic stream of pseudo random numbers
   * in the given range, provided a global seed that it manipulates each draw.
   */
  const prng = (min: number, max: number): number => {
    seed = (seed * 9301 + 49297) % 233280;
    const rnd = seed / 233280.0;
    return min + rnd * (max - min);
  };

  /*
   * Helper that returns a linear interpolated sample value from envelope. This allows subsampling.
   *
   * Parameters:
   *  seekTimeSeconds: at what time in envelope to retrieve interpolated sample
   *  log (bool): whether to log the sample value
   *
   * Returns:
   *  point {x: seconds, y: amplitude}
   */
  const getInterpSampleAmp = (seekTimeSeconds: number, log: boolean): Point => {
    const envelopeBuffer = envelope.channelAmplitudeSamples[0];
    const iEnvSample = Math.max(
      0,
      Math.min(
        (seekTimeSeconds * envelope.sampleRate) / envelope.hopSize,
        envelopeBuffer.length - 1.001
      )
    );
    const iEnvSampleBefore = Math.floor(iEnvSample);
    const envSampleBefore = envelopeBuffer[iEnvSampleBefore];
    const iEnvSampleAfter = Math.ceil(iEnvSample);
    const envSampleAfter = envelopeBuffer[iEnvSampleAfter];
    const p = iEnvSample - iEnvSampleBefore;
    let envInterpSample = linearInterp(envSampleBefore, envSampleAfter, p);
    let envInterpSamplePreLog = envInterpSample;
    // log amplitude if desired
    envInterpSample = log
      ? realLogInterp(1e-6, 1, envInterpSample)
      : envInterpSample;
    // boost/attenuate and clip
    envInterpSample = Math.max(
      0,
      Math.min(1, (envInterpSamplePreLog * 4 + envInterpSample) / 3)
    );
    const sampleSeconds = (iEnvSample * envelope.hopSize) / envelope.sampleRate;
    return { x: sampleSeconds, y: envInterpSample };
  };

  /*
   * Linear sweeps through the waveform envelope and generates polygonal shapes according to the
   * waveform sample values.
   *
   * Returns:
   *  Gem[]: list of gems
   */
  const gemEnvelopeSweeper = (params: GemSweepParams): Gem[] => {
    const gems = [];
    for (
      let iGemThread = 0;
      iGemThread < params.aesthetics.threads;
      iGemThread++
    ) {
      let seekTimeSeconds = 0;
      let iStep = -1;
      while (seekTimeSeconds < waveformTimeSeconds) {
        // move forward in time
        const stepSeconds = prng(
          params.aesthetics.waveformSkipSecondsRange[0],
          params.aesthetics.waveformSkipSecondsRange[1]
        );
        if (iStep >= 0) seekTimeSeconds += stepSeconds;
        iStep++;
        if (seekTimeSeconds > waveformTimeSeconds) break;

        const sample = getInterpSampleAmp(
          seekTimeSeconds,
          params.aesthetics.logSamples
        );
        const dbSample = linearToDB(sample.y);
        if (dbSample <= params.aesthetics.gateThresholdDB) continue;

        // draw initial gem polygon points
        let gemPoints: Point[] = [];
        if (prng(0, 1) <= params.aesthetics.initialCliffChance) {
          gemPoints.push({ x: sample.x, y: sample.y });
          gemPoints.push({ x: sample.x, y: -sample.y });
        } else {
          if (prng(0, 1) > 0.5) {
            gemPoints.push({ x: sample.x, y: sample.y });
          } else {
            gemPoints.push({ x: sample.x, y: -sample.y });
          }
        }

        // now draw rest of shape
        const gemVertices = Math.round(
          prng(
            params.aesthetics.gemSizeRand[0],
            params.aesthetics.gemSizeRand[1]
          )
        );
        while (gemPoints.length < gemVertices) {
          let gemStepSeconds = prng(
            params.aesthetics.vertexSampleSkipSecondsRange[0],
            params.aesthetics.vertexSampleSkipSecondsRange[1]
          );
          seekTimeSeconds += gemStepSeconds;
          const gemSample = getInterpSampleAmp(
            seekTimeSeconds,
            params.aesthetics.logSamples
          );
          const gemDBSample = linearToDB(sample.y);
          if (gemDBSample < params.aesthetics.gateThresholdDB) break;

          const lastPointTopWeight =
            gemPoints[gemPoints.length - 1].y >= 0 ? 0.5 : 1.5;
          if (prng(0, 1) <= params.aesthetics.gemBothChance) {
            gemPoints.push({ x: gemSample.x, y: gemSample.y });
            gemPoints.push({ x: gemSample.x, y: -gemSample.y });
          } else if (
            prng(0, 1) <=
            lastPointTopWeight *
              ((prng(0, 1) - params.aesthetics.gemBothChance) / 2)
          ) {
            gemPoints.push({ x: gemSample.x, y: gemSample.y });
          } else {
            gemPoints.push({ x: gemSample.x, y: -gemSample.y });
          }
        }

        // add the gem along with randomizations
        if (gemPoints.length >= 3) {
          const gemHueShade = prng(
            params.aesthetics.hueShadeRange[0],
            params.aesthetics.hueShadeRange[1]
          );
          const gemColor = pSBC(
            gemHueShade,
            params.baseColor,
            params.aesthetics.lightenBlendColor
          );
          const gemScale = prng(
            params.aesthetics.scaleRange[0],
            params.aesthetics.scaleRange[1]
          );
          const gemRotation = Math.round(
            prng(
              params.aesthetics.rotationRange[0],
              params.aesthetics.rotationRange[1]
            )
          );
          const gemOpacity = prng(
            params.aesthetics.opacityRange[0],
            params.aesthetics.opacityRange[1]
          );
          // sort points clockwise for rendering polygon
          gemPoints.sort((p1, p2) => {
            if (p1.y <= 0 && p2.y > 0) return 1; // top before bottom
            if (p1.y >= 0 && p2.y < 0) return -1; // top before bottom
            if (p1.y >= 0 && p2.y >= 0) return p1.x - p2.x; // L to R on top
            if (p1.y <= 0 && p2.y <= 0) return p2.x - p1.x; // R to L on bottom
            return 0;
          });
          gems.push({
            points: gemPoints,
            color: gemColor,
            scale: gemScale,
            rotation: gemRotation,
            opacity: gemOpacity
          });
        }
      }
    }
    return gems;
  };

  /*
   * Calculate polygon points for each layer of waveform when the envelope changes
   */
  useEffect(() => {
    const computedWaveformLayers = getEmptyLayers();
    setWaveformPolygonPoints(getEmptyLayers());

    //------ MAIN WAVEFORM LARGE-POLYGON TOP
    if (aesthetics.topWave) {
      let samples = [];
      let seekTimeSeconds = 0;
      while (seekTimeSeconds < waveformTimeSeconds) {
        // move forward in time
        const stepSeconds = prng(
          aesthetics.topWave.timeSkipRandSeconds[0],
          aesthetics.topWave.timeSkipRandSeconds[1]
        );
        if (samples.length > 0) seekTimeSeconds += stepSeconds; // start at 0
        if (seekTimeSeconds > waveformTimeSeconds) break;

        const sample = getInterpSampleAmp(
          seekTimeSeconds,
          aesthetics.topWave.logSamples
        );
        const dbSample = linearToDB(sample.y);

        const prevPoint =
          samples.length > 0 ? samples[samples.length - 1] : null;
        const prevPrevPoint =
          samples.length > 1 ? samples[samples.length - 2] : null;

        samples.push(sample);

        // handle silent sections
        if (dbSample < aesthetics.topWave.gateThresholdDB) {
          if (
            prevPoint === null ||
            linearToDB(prevPoint.y) >= aesthetics.topWave.gateThresholdDB
          ) {
            computedWaveformLayers.top.push({ x: sample.x, y: 1e-6 });
          }
          computedWaveformLayers.top.push({ x: sample.x, y: "0" }); //GH Added to adjust look of silent segments
          continue;
        }

        if (prevPoint !== null && prevPrevPoint !== null) {
          // linear extrapolate this point from previous 2 points
          const m =
            (prevPoint.y - prevPrevPoint.y) / (prevPoint.x - prevPrevPoint.x);
          const b = prevPoint.y - m * prevPoint.x;
          const projectedSample = m * sample.x + b;
          const percentDiff = (sample.y - projectedSample) / sample.y;
          if (Math.abs(percentDiff) < aesthetics.topWave.percentSignificant)
            continue;
          if (percentDiff > aesthetics.topWave.percentPeakSignificant) {
            // draw a sharp peak
            const xMiddle = prevPoint.x + (sample.x - prevPoint.x) / 2;

            computedWaveformLayers.top.push({ x: xMiddle, y: "0" });

            computedWaveformLayers.top.push({ x: xMiddle, y: sample.y });
          } else {
            computedWaveformLayers.top.push({ x: sample.x, y: sample.y });
          }
        }
      }
      // mirror bottom waveform points (symmetrical about x-axis)
      for (
        let iPoint = computedWaveformLayers.top.length - 1;
        iPoint >= 0;
        iPoint--
      ) {
        const topPoint = computedWaveformLayers.top[iPoint];
        computedWaveformLayers.top.push({
          x: topPoint.x,
          y: -topPoint.y
        });
      }
    }

    //------ MAIN WAVEFORM LARGE-POLYGON BOTTOM
    if (aesthetics.bottomWave) {
      for (let iPass = 0; iPass < 2; iPass++) {
        let samples = [];
        let seekTimeSeconds = 0;
        while (seekTimeSeconds < waveformTimeSeconds) {
          // move forward in time
          const stepSeconds = prng(
            aesthetics.bottomWave.timeSkipRandSeconds[0],
            aesthetics.bottomWave.timeSkipRandSeconds[1]
          );
          if (samples.length > 0) seekTimeSeconds += stepSeconds; // start at 0
          if (seekTimeSeconds > waveformTimeSeconds) break;

          const sample = getInterpSampleAmp(
            seekTimeSeconds,
            aesthetics.bottomWave.logSamples
          );
          const dbSample = linearToDB(sample.y);

          const prevPoint =
            samples.length > 0 ? samples[samples.length - 1] : null;
          const prevPrevPoint =
            samples.length > 1 ? samples[samples.length - 2] : null;

          samples.push(sample);

          // handle silent sections
          if (dbSample < aesthetics.bottomWave.gateThresholdDB) {
            if (
              prevPoint === null ||
              linearToDB(prevPoint.y) >= aesthetics.bottomWave.gateThresholdDB
            ) {
              computedWaveformLayers.bottom.push({
                x: sample.x,
                y: 1e-6
              });
            }
            continue;
          }

          if (prevPoint !== null && prevPrevPoint !== null) {
            // linear extrapolate this point from previous 2 points
            const m =
              (prevPoint.y - prevPrevPoint.y) / (prevPoint.x - prevPrevPoint.x);
            const b = prevPoint.y - m * prevPoint.x;
            const projectedSample = m * sample.x + b;
            const percentDiff = (sample.y - projectedSample) / sample.y;
            if (percentDiff < aesthetics.bottomWave.percentSignificant)
              continue;
            computedWaveformLayers.bottom.push({
              x: sample.x,
              y: (iPass === 0 ? 1 : -1) * sample.y
            });
          }
        }
      }
      computedWaveformLayers.bottom.sort((p1, p2) => {
        if (p1.y <= 0 && p2.y > 0) return 1; // top before bottom
        if (p1.y >= 0 && p2.y < 0) return -1; // top before bottom
        if (p1.y >= 0 && p2.y >= 0) return p1.x - p2.x; // L to R on top
        if (p1.y <= 0 && p2.y <= 0) return p2.x - p1.x; // R to L on bottom
        return 0;
      });
    }

    //------  GEMS
    if (aesthetics.gems) {
      computedWaveformLayers.gems = gemEnvelopeSweeper({
        aesthetics: aesthetics.gems,
        baseColor: aesthetics.colors[1]
      });
    }

    //------  BOTTOM GEMS: larger underlying polygonal shapes formed from stretched, overlaid gems
    if (aesthetics.bottomWaveGems) {
      computedWaveformLayers.bottomGems = gemEnvelopeSweeper({
        aesthetics: aesthetics.bottomWaveGems,
        baseColor: aesthetics.colors[0]
      });
    }

    //------  ORIGINAL REFERENCE WAVEFORM
    if (aesthetics.referenceWave) {
      const referencePoints = [];
      for (let i = 0; i < envelope.channelAmplitudeSamples[0].length; i++) {
        const x = (i * envelope.hopSize) / envelope.sampleRate;
        let rawSample = envelope.channelAmplitudeSamples[0][i];
        rawSample = rawSample === 0 ? 1e-6 : rawSample;
        referencePoints.push({ x: x, y: rawSample });
        referencePoints.push({ x: x, y: -rawSample });
      }
      referencePoints.sort((p1, p2) => {
        if (p1.y <= 0 && p2.y > 0) return 1; // top before bottom
        if (p1.y >= 0 && p2.y < 0) return -1; // top before bottom
        if (p1.y >= 0 && p2.y >= 0) return p1.x - p2.x; // L to R on top
        if (p1.y <= 0 && p2.y <= 0) return p2.x - p1.x; // R to L on bottom
        return 0;
      });
      computedWaveformLayers.reference = referencePoints;
    }

    setWaveformLayers(computedWaveformLayers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envelope, aesthetics]);

  useEffect(() => {
    const computedWaveformPoints = getEmptyLayers();

    const waveformStartTime = startTime + audioStartTimeDeviation;

    // GEMSCAPE - BOTTOM LAYER - MARKER SWOOSH
    if (aesthetics.bottomWave) {
      let bottomPolyPoints = ` 0,${maxHeightFromCenter}`;
      for (let iPoint = 0; iPoint < waveformLayers.bottom.length; iPoint++) {
        const point = waveformLayers.bottom[iPoint];
        if (point.x < waveformStartTime) continue;
        const x = seconds_to_pixels(point.x - waveformStartTime) * zoom;
        const y = maxHeightFromCenter - point.y * maxHeightFromCenter;
        bottomPolyPoints += ` ${x.toFixed(3)},${y.toFixed(3)} `;
      }
      computedWaveformPoints.bottom.push(bottomPolyPoints);
    }

    // GEMSCAPE - TOP LAYER - CHONKY STYLIZED WAVES
    if (aesthetics.topWave) {
      let topPolyPoints = ` 0,${maxHeightFromCenter}`;
      for (let iPoint = 0; iPoint < waveformLayers.top.length; iPoint++) {
        const point = waveformLayers.top[iPoint];
        if (point.x < waveformStartTime) continue;
        const x = seconds_to_pixels(point.x - waveformStartTime) * zoom;
        const y = maxHeightFromCenter - point.y * maxHeightFromCenter;
        topPolyPoints += ` ${x.toFixed(3)},${y.toFixed(3)} `;
      }
      computedWaveformPoints.top.push(topPolyPoints);
    }

    // GEMSCAPE - GEMS
    if (aesthetics.gems) {
      let gemPolygons = [];
      for (let iGem = 0; iGem < waveformLayers.gems.length; iGem++) {
        let gem = waveformLayers.gems[iGem];
        let gemPolyPoints = "";
        for (let iGemPoint = 0; iGemPoint < gem.points.length; iGemPoint++) {
          const point = gem.points[iGemPoint];
          if (point.x < waveformStartTime) continue;
          const x = seconds_to_pixels(point.x - waveformStartTime) * zoom;
          const y = maxHeightFromCenter - point.y * maxHeightFromCenter;
          gemPolyPoints += ` ${x.toFixed(3)},${y.toFixed(3)} `;
        }
        gemPolygons.push(gemPolyPoints);
      }
      computedWaveformPoints.gems = gemPolygons;
    }

    // BOTTOM LAYER OF GEMS
    if (aesthetics.bottomWaveGems) {
      let bottomShapePolygons = [];
      for (
        let iShape = 0;
        iShape < waveformLayers.bottomGems.length;
        iShape++
      ) {
        let shape = waveformLayers.bottomGems[iShape];
        let shapePolyPoints = "";
        for (
          let iShapePoint = 0;
          iShapePoint < shape.points.length;
          iShapePoint++
        ) {
          const point = shape.points[iShapePoint];
          if (point.x < waveformStartTime) continue;
          const x = seconds_to_pixels(point.x - waveformStartTime) * zoom;
          const y = maxHeightFromCenter - point.y * maxHeightFromCenter;
          shapePolyPoints += ` ${x.toFixed(3)},${y.toFixed(3)} `;
        }
        bottomShapePolygons.push(shapePolyPoints);
      }
      computedWaveformPoints.bottomGems = bottomShapePolygons;
    }

    // REFERENCE WAVEFORM
    if (aesthetics.referenceWave) {
      let refPolyPoints: string = "";
      for (let iPoint = 0; iPoint < waveformLayers.reference.length; iPoint++) {
        const point = waveformLayers.reference[iPoint];
        if (point.x < waveformStartTime) continue;
        const x = seconds_to_pixels(point.x - waveformStartTime) * zoom;
        const y = maxHeightFromCenter - point.y * maxHeightFromCenter;
        refPolyPoints += ` ${x.toFixed(3)},${y.toFixed(3)} `;
      }
      computedWaveformPoints.reference.push(refPolyPoints);
    }

    setWaveformPolygonPoints(computedWaveformPoints);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioStartTimeDeviation, startTime, waveformLayers, zoom, aesthetics]);

  return (
    <>
      {// GEMSCAPE - BOTTOM LAYER - MARKER SWOOSH
      waveformPolygonPoints.bottom.length > 0 && (
        <polygon
          fill={aesthetics.colors[0]}
          points={waveformPolygonPoints.bottom[0]}
          opacity={0.35}
        />
      )}

      {// BOTTOM LAYER OF GEMS
      waveformPolygonPoints.bottomGems.length > 0 &&
        waveformPolygonPoints.bottomGems.length ===
          waveformLayers.bottomGems.length &&
        waveformPolygonPoints.bottomGems.map((gemPoints, iShape) => {
          const gemAttributes = waveformLayers.bottomGems[iShape];
          return (
            <polygon
              key={`bottom-gem-${iShape}`}
              fill={gemAttributes.color}
              points={gemPoints}
              opacity={gemAttributes.opacity}
              style={{
                transform: `scaleY(${gemAttributes.scale}) rotate(${gemAttributes.rotation}deg)`,
                transformOrigin: "50%, 50%",
                transformBox: "fill-box"
              }}
            />
          );
        })}

      {// GEMSCAPE - TOP LAYER - CHONKY STYLIZED WAVES
      waveformPolygonPoints.top.length > 0 && (
        <polygon
          fill={aesthetics.colors[1]}
          points={waveformPolygonPoints.top[0]}
          opacity={0.8}
        />
      )}

      {// GEMSCAPE - GEMS
      waveformPolygonPoints.gems.length > 0 &&
        waveformPolygonPoints.gems.length === waveformLayers.gems.length &&
        waveformPolygonPoints.gems.map((gemPoints, iGem) => {
          const gemAttributes = waveformLayers.gems[iGem];
          return (
            <polygon
              key={`gem-${iGem}`}
              fill={gemAttributes.color}
              points={gemPoints}
              opacity={gemAttributes.opacity}
              style={{
                transform: `scale(${gemAttributes.scale}) rotate(${gemAttributes.rotation}deg)`,
                transformOrigin: "50%, 50%",
                transformBox: "fill-box"
              }}
            />
          );
        })}

      {// ORIGINAL WAVEFORM UNDERLAY (RED)
      waveformPolygonPoints.reference.length > 0 && (
        <polygon
          fill={"#fc0303"}
          points={waveformPolygonPoints.reference[0]}
          opacity={0.6}
        />
      )}
    </>
  );
}

export default Wave;
