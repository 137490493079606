import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 25px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.editor.backgroundDark};
  color: ${({ theme }) => theme.editor.textLight};

  .time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: ${({ theme }) => theme.fontHeavy};
    background-color: ${({ theme }) => theme.editor.backgroundLight};
    border-radius: 6px;
    padding: 2px 16px;
    height: 100%;
    width: 100%;

    .smpte {
      color: ${({ theme }) => theme.editor.textLight};
    }

    .measures-beats {
      color: ${({ theme }) => theme.editor.textMedium};
    }
  }

  @media only screen and (max-width: 625px) {
    visibility: hidden;
    width: 0px;
  }
`;
