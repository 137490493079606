import styled from "styled-components";
import ReactSVG from "react-svg";

export const CreativeCommonsContainer = styled.div`
  background-color: ${({ theme }) => theme.highlight};
  border-radius: ${({ theme }) => theme.borderRadiusContainers};
  padding: ${({ theme }) => theme.paddingContainersS};

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.paddingS};
  }

  .more-info {
    text-decoration: underline;
    color: ${({ theme }) => theme.textLight};
    cursor: pointer;
  }
`;

export const CCIcon = styled(ReactSVG)`
  width: fit-content;
  height: fit-content;
  display: inline-block;
  padding-right: 5px;

  path {
    fill: ${({ theme }) => theme.textLight};
  }
`;
