import styled from "styled-components";

export const Container = styled.div`
  overflow-y: auto;
  height: calc(100% - ${({ theme }) => theme.editor.editorHeaderHeight});
  overflow-x: clip;
  font-family: ${({ theme }) => theme.fontMedium};
  background-color: ${({ theme }) => theme.editor.backgroundDark};

  grid-row-start: 2;
  grid-row-end: 3;
  display: grid;
  grid-template-columns: ${({ tightLayout, theme }) =>
      tightLayout
        ? theme.editor.tightLaneControlsW
        : theme.editor.laneControlsW} auto;

  .rc-slider-handle {
    border: 0;
    background-color: ${({ theme }) => theme.editor.textDark};

    &:hover {
      background-color: ${({ theme }) => theme.editor.light};
      transform: scale(1.2);
    }
  }

  .zoom-button,
  .snap-button {
    width: 26px;
  }

  #drag-overlay {
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    z-index: 9999;
  }

  &&::-webkit-scrollbar {
    width: 12px;
  }

  &&::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.editor.backgroundDark};
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.editor.backgroundHighlight};
    border-radius: 20px;
    border: 2px solid ${({ theme }) => theme.editor.backgroundDark};
  }
`;

export const LanesAreaContainer = styled.div`
  overflow-x: auto;
  height: 100%;
  width: calc(
    100vw -
      ${({ tightLayout, theme }) =>
        tightLayout
          ? theme.editor.tightLaneControlsW
          : theme.editor.laneControlsW}}
  );
  position: relative;

  .split-mode {
    cursor: col-resize;
  }

  &&::-webkit-scrollbar {
    height: 12px;
  }

  &&::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.editor.backgroundDark};
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.editor.backgroundHighlight};
    border-radius: 20px;
    border: 2px solid ${({ theme }) => theme.editor.backgroundDark};
  }
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: clip;
  transform-origin: left;
  background-color: ${({ theme }) => theme.editor.backgroundMedium};
  background-size: ${({ backgroundSize }) => backgroundSize}px;
  background-position: ${({ initialBeatOffset }) => initialBeatOffset}px 0;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.editor.backgroundLight} 1px,
    transparent 2px
  );
  width: fit-content;
  min-width: calc(100vw - ${({ theme }) => theme.editor.laneControlsW});
  height: 100%;
  position: relative;
`;

export const LeftPanelContainer = styled.div`
  background-color: ${({ theme }) => theme.editor.backgroundDark};

  .top-bar {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 10px 0 15px;
  }
`;
