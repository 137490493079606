import React, { useState, useContext, useEffect } from "react";
import { SearchContext } from "./SearchContext/SearchProvider";
import { AuthContext } from "./AuthProvider";
import { withRouter } from "react-router-dom";

export const FilterContext = React.createContext();

const FilterProvider = ({ children }) => {
  const context = useContext(SearchContext);
  const auth = useContext(AuthContext);
  const [popoverStatus, setPopoverStat] = useState({});
  const [advPopoverStatus, setAdvPopoverStat] = useState({});
  const [advancedSearchOn, setAdvancedSearchOn] = useState(false);

  const popovers = [
    {
      values: context.allInstruments,
      name: "Instruments",
      key: "instruments",
      filterType: "instruments"
    },
    {
      values: context.allTags,
      name: "Tags",
      key: "tags",
      filterType: "tags"
    },
    {
      values: context.allKeys,
      name: "Keys",
      filterType: "key",
      key: "keys"
    }
  ];

  useEffect(() => {
    setAdvancedSearchOn(auth.user.AdvancedSearch);
  }, [auth.user]);

  const handleSwitch = checked => {
    setAdvancedSearchOn(checked);
  };

  const onPopoverChange = (value, index, advanced) => {
    if (advanced) return setAdvPopoverStat({ [index]: value });
    setPopoverStat({ [index]: value });
  };

  const allCharacteristicsContext = [...context.allCharacteristics];
  const allCharacteristics = allCharacteristicsContext.splice(0, 5);
  const allAdvancedCharacteristics = allCharacteristicsContext.splice(0, 3);
  const allAdvancedCharacteristics2 = allCharacteristicsContext.splice(0, 2);

  return (
    <FilterContext.Provider
      value={{
        allCharacteristicsContext,
        allCharacteristics,
        allAdvancedCharacteristics,
        allAdvancedCharacteristics2,
        onPopoverChange,
        handleSwitch,
        popovers,
        context,
        popoverStatus,
        advPopoverStatus,
        advancedSearchOn,
        setPopoverStat,
        setAdvPopoverStat,
        setAdvancedSearchOn
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default withRouter(FilterProvider);

export const useFilter = () => useContext(FilterContext);
