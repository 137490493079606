import styled from "styled-components";

export const Container = styled.div`
  max-width: 600px;

  .message {
    margin-top: 25px;
  }

  .ant-alert {
    margin-top: 25px;
  }
`;

export const FlexButtons = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 25px;

  button {
    margin-left: 10px;
  }
`;
