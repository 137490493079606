import * as React from "react";
import { AddLaneButton, AddLaneContainer } from "./styles";
import { Plus } from "../../../assets/Plus";

export function AddLane({ addLaneModal }) {
  return (
    <AddLaneContainer>
      <AddLaneButton onClick={addLaneModal.open}>
        <Plus /> <span id="btn-addlane-text">Add Track</span>
      </AddLaneButton>
    </AddLaneContainer>
  );
}
