import React, { useRef, useState, useEffect } from "react";

let timeout;

export const useDownload = (downloadUrl, title) => {
  const ref = useRef();
  const [element, setElement] = useState();

  useEffect(() => {
    if (!title) return;
    const el = React.createElement("a", {
      style: { display: "none" },
      href: downloadUrl,
      download: title,
      ref
    });
    setElement(el);
  }, [title, downloadUrl]);

  const downloadFromRef = () => {
    if (!ref.current) {
      timeout = setTimeout(() => {
        downloadFromRef();
      }, 2000);
      return;
    }
    if (ref.current) {
      ref.current.click();
      clearTimeout(timeout);
    }
  };

  return { element, downloadFromRef };
};

export const download = (downloadUrl, title) => {
  const url = downloadUrl;
  const fileName = title;
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.download = fileName;
  a.click();
  document.body.removeChild(a);
};
