import React, { useRef } from "react";
import { DraggableCore, DraggableData, DraggableEvent } from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_FADE_END,
  SET_FADE_START,
  SetFadeEndAction,
  SetFadeStartAction
} from "../../../actions/actionTypes";
import { FadeHandler } from "../../../../assets/FadeHandler";
import { MainStore, Region } from "../../../store";
import { clamp } from "../../../util/extra_math";
import {
  pixels_to_second,
  seconds_to_pixels
} from "../../../util/time_position_conversion";
import { getZoomProportion } from "../../../util/zoom_levels";
import { theme } from "../../../../globalStyles";

export interface RegionFadeProps {
  laneId: string;
  regionId: string;
  isStart: boolean;
  visible: boolean;
}

export let RegionFade = (props: RegionFadeProps) => {
  const fadeMarkerRef = useRef(null);

  const zoomLevel = useSelector<MainStore, number>(
    state => state.uiState.zoomLevel
  );
  const zoomProportion = getZoomProportion(zoomLevel);
  const region = useSelector<MainStore, Region>(state => {
    return state.project.regions.byId[props.regionId];
  });
  const pixel_width = seconds_to_pixels(region.visualDuration);
  const dispatch = useDispatch();

  const pixel_fade_offset = seconds_to_pixels(
    props.isStart ? region.startFadeTime : region.endFadeTime
  );

  const [onStartOffset, setOnStartOffset] = React.useState(pixel_fade_offset);
  const [stateOffset, setStateOffset] = React.useState(pixel_fade_offset);
  const [stateCumuOffset, setStateCumuOffset] = React.useState(
    pixel_fade_offset
  );
  const [activeDrag, setActiveDrag] = React.useState(false);

  React.useEffect(() => {
    setStateOffset(pixel_fade_offset);
    setStateCumuOffset(pixel_fade_offset * (props.isStart ? 1 : -1));
    // eslint-disable-next-line
  }, [region]);

  const fadeHandler = {
    onStart: (e: DraggableEvent, data: DraggableData) => {
      setOnStartOffset(stateCumuOffset);
      setActiveDrag(true);
    },
    onDrag: (e: DraggableEvent, data: DraggableData) => {
      let newCumuOffset = stateCumuOffset + data.deltaX / zoomProportion;
      if (props.isStart) {
        newCumuOffset = clamp(newCumuOffset, 0, pixel_width);
      } else {
        newCumuOffset = clamp(newCumuOffset, -pixel_width, 0);
      }

      setStateCumuOffset(newCumuOffset);
      setStateOffset(Math.abs(newCumuOffset));

      const seconds = pixels_to_second(Math.abs(newCumuOffset));
      if (props.isStart) {
        dispatch<SetFadeStartAction>({
          type: SET_FADE_START,
          regionId: props.regionId,
          fadeTime: seconds
        });
      } else {
        dispatch<SetFadeEndAction>({
          type: SET_FADE_END,
          regionId: props.regionId,
          fadeTime: seconds
        });
      }
    },
    onStop(e: DraggableEvent, data: DraggableData) {
      let seconds = pixels_to_second(Math.abs(stateCumuOffset));
      if (seconds === 0 && stateCumuOffset === onStartOffset) {
        seconds = 5;
      }

      if (props.isStart) {
        dispatch<SetFadeStartAction>({
          type: SET_FADE_START,
          regionId: props.regionId,
          fadeTime: seconds
        });
      } else {
        dispatch<SetFadeEndAction>({
          type: SET_FADE_END,
          regionId: props.regionId,
          fadeTime: seconds
        });
      }

      setActiveDrag(false);
    }
  };

  const className = `region-fade ${props.isStart ? "start" : "end"}`;

  function fadeHandle() {
    // hide start fade handler if overlap with previous RegionBlock is present
    if (region.startFadeTimeOverlap && props.isStart) {
      return null;
    }
    // hide end fade handler if overlap with subsequent RegionBlock is present
    if (region.endFadeTimeOverlap && !props.isStart) {
      return null;
    }

    return (
      <span
        className={`fade-marker ${
          (props.isStart && region.startFadeTime > 0) ||
          (!props.isStart && region.endFadeTime > 0)
            ? "region-sub-handle"
            : ""
        }`}
        style={{
          display: props.visible ? "initial" : "none"
        }}
        onMouseDown={e =>
          fadeMarkerRef.current.classList.add("region-sub-handle")
        }
        ref={fadeMarkerRef}
      >
        <FadeHandler />
      </span>
    );
  }

  const fadeWidth = stateOffset * zoomProportion;
  return (
    <React.Fragment>
      <div
        className={className}
        style={{
          background: activeDrag ? "" : theme.editor.highlight,
          width: fadeWidth
        }}
      />
      <DraggableCore
        onStart={fadeHandler.onStart}
        onStop={fadeHandler.onStop}
        onDrag={fadeHandler.onDrag}
        handle=".fade-marker"
      >
        <span
          className={`btn-toolset fade-icon ${props.isStart ? "start" : "end"}`}
          style={{
            left: fadeWidth * (props.isStart ? 1 : -1)
          }}
        >
          {fadeHandle()}
        </span>
      </DraggableCore>
    </React.Fragment>
  );
};
