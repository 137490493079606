import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  max-width: 595px;

  .logo {
    width: 80px;
    height: auto;
    margin-right: 22px;
  }

  .flex-row {
    display: flex;
    flex-flow: row nowrap;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .thanks {
    font-size: 16px;
    font-family: ${({ theme }) => theme.fontHeavy};
  }

  .copy-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .copy-below {
    font-size: 14px;
    font-family: ${({ theme }) => theme.fontMedium};
  }

  .copy-text-box {
    position: relative;
    border-radius: ${({ theme }) => theme.borderRadius};
    width: 100%;
    height: 31px;
    display: flex;
    flex-flow: row nowrap;
    margin: 22px 0;
    background-color: ${({ theme }) => theme.whiteTransparent};
    justify-content: space-between;

    .copy-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: ${({ theme }) => theme.textLight};
      padding: 6px 12px;
    }

    .copy-text-button {
      background-color: ${({ theme }) => theme.realWhite};
      color: ${({ theme, downloadable }) =>
        downloadable ? theme.subscribe : theme.highlight};
      border-radius: ${({ theme }) => theme.borderRadius};
      padding: 6px 12px;
      line-height: 19px;
      font-family: ${({ theme }) => theme.fontHeavy};
      flex: 1 0 auto;
    }
  }

  .preview {
    color: ${({ theme }) => theme.whiteTransparent};
  }

  .link {
    text-decoration: underline;
    color: ${({ theme }) => theme.textLight};
  }

  .give-credit {
    text-align: right;
    text-decoration: underline;
    color: ${({ theme }) => theme.whiteTransparent};
    cursor: pointer;
    padding: 6px 0 0;
  }
`;
