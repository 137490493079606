import * as React from "react";
import { theme } from "../globalStyles";

export const CircleArrow = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8431 8.48424C16.8431 13.0065 13.177 16.6726 8.65471 16.6726C4.13241 16.6726 0.46637 13.0065 0.46637 8.48424C0.46637 3.96194 4.13241 0.295898 8.65471 0.295898C13.177 0.295898 16.8431 3.96194 16.8431 8.48424ZM12.9222 11.1947L8.65472 4.66406L4.38727 11.1947H12.9222Z"
      fill={theme.editor.textDark}
    />
    <path
      d="M8.65472 4.66302L12.9222 11.1937H4.38727L8.65472 4.66302Z"
      fill={theme.editor.textLight}
    />
  </svg>
);
