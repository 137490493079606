import * as React from "react";
import { theme } from "../globalStyles";

export const GripVerticalLines = () => (
  <svg viewBox="0 0 11 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.298828"
      y="0.94165"
      width="2"
      height="30"
      rx="2"
      fill={theme.editor.textLight}
    />
    <rect
      x="4.29883"
      y="0.94165"
      width="2"
      height="30"
      rx="2"
      fill={theme.editor.textLight}
    />
    <rect
      x="8.29883"
      y="0.94165"
      width="2"
      height="30"
      rx="2"
      fill={theme.editor.textLight}
    />
  </svg>
);
