import * as React from "react";
import { theme } from "../globalStyles";

export const Hamburger = () => (
  <svg
    width="23"
    height="18"
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.875 2.5H0.625C0.279822 2.5 0 2.22018 0 1.875V0.625C0 0.279822 0.279822 0 0.625 0H21.875C22.2202 0 22.5 0.279822 22.5 0.625V1.875C22.5 2.22018 22.2202 2.5 21.875 2.5ZM22.5 9.375V8.125C22.5 7.77982 22.2202 7.5 21.875 7.5H0.625C0.279822 7.5 0 7.77982 0 8.125V9.375C0 9.72018 0.279822 10 0.625 10H21.875C22.2202 10 22.5 9.72018 22.5 9.375ZM22.5 15.625V16.875C22.5 17.2202 22.2202 17.5 21.875 17.5H0.625C0.279822 17.5 0 17.2202 0 16.875V15.625C0 15.2798 0.279822 15 0.625 15H21.875C22.2202 15 22.5 15.2798 22.5 15.625Z"
      fill={theme.editor.iconLight}
    />
  </svg>
);
