import * as React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { REORDER_LANES, ReorderLanesAction } from "../../actions/actionTypes";
import { MainStore } from "../../store";
import { LaneHeader } from "../LaneHeader";

export let LaneHeaders = () => {
  const laneIds = useSelector<MainStore, string[]>(
    state => state.project.lanes.laneIds
  );

  const dispatch = useDispatch();

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    dispatch<ReorderLanesAction>({
      type: REORDER_LANES,
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {laneIds.map((laneId, i) => (
              <LaneHeader
                index={i}
                key={laneId}
                laneId={laneId}
                dragActive={snapshot.isDraggingOver}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
