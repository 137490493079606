import {
  EditorMode,
  FX,
  InitialTrackData,
  MainStore,
  Project,
  Lanes,
  Regions,
  MuteSoloState,
  PlaybackState,
  SnapMode,
  Instrument,
  SaveSerialization,
  Envelopes
} from "../store";

export const REORDER_LANES = "REORDER_LANES";
export const DELETE_LANE = "DELETE_LANE";
export const DUPLICATE_LANE = "DUPLICATE_LANE";
export const RENAME_LANE = "RENAME_LANE";
export const SET_SNAP_MODE = "SET_SNAP_MODE";
export const MOVE_REGION = "MOVE_REGION";
export const MOVE_REGION_DIFFERENT_LANE = "MOVE_REGION_DIFFERENT_LANE";
export const CHANGE_REGION_DURATION = "CHANGE_REGION_DURATION";
export const CHANGE_REGION_START = "CHANGE_REGION_START";
export const ZOOM_CHANGE = "ZOOM_CHANGE";
export const SET_MUTE_SOLO = "SET_MUTE_SOLO";
export const SET_MUTE_SOLO_OVERRIDE = "SET_MUTE_SOLO_OVERRIDE";
export const SET_PAN = "SET_PAN";
export const SET_VOLUME = "SET_VOLUME";
export const SET_MASTER_VOLUME = "SET_MASTER_VOLUME";
export const SET_MASTER_MUTE = "SET_MASTER_MUTE";
export const SET_METRONOME_VOLUME = "SET_METRONOME_VOLUME";
export const SET_METRONOME_MUTE = "SET_METRONOME_MUTE";
export const SET_ALERT = "SET_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const SET_FX_EFFECT = "SET_FX_EFFECT";
export const SET_FX_VALUE = "SET_FX_VALUE";
export const UI_SHOW_FX_PANEL = "UI_SHOW_FX_PANEL";
export const SET_EDITOR_MODE = "SET_EDITOR_MODE";
export const SPLIT_REGION = "SPLIT_REGION";
export const DUPLICATE_REGION = "DUPLICATE_REGION";
export const DELETE_REGION = "DELETE_REGION";
export const SET_FADE_END = "SET_FADE_END";
export const SET_FADE_START = "SET_FADE_START";
export const EMPTY_PROJECT = "EMPTY_PROJECT";
export const REHYDRATE_INITIAL_MIX = "REHYDRATE_INITIAL_MIX";
export const RESET_STORE = "RESET_STORE";
export const SET_EDITOR_DATA_DUMP = "SET_EDITOR_DATA_DUMP";
export const SET_EDITOR_OPENABLE = "SET_EDITOR_OPENABLE";
export const SET_EDITOR_DEMO = "SET_EDITOR_DEMO";
export const SET_FREE_STEMS = "SET_FREE_STEMS";
export const CREATE_LANE = "CREATE_LANE";
export const CREATE_REGION = "CREATE_REGION";
export const ADD_REGION_TO_LANE = "ADD_REGION_TO_LANE";
export const SET_PLAYBACK_STATE = "SET_PLAYBACK_STATE";
export const SCROLL_WORKSPACE = "SCROLL_WORKSPACE";
export const UPDATE_CUSTOM_MIX_META = "UPDATE_CUSTOM_MIX_META";
export const UPDATE_CUSTOM_MIX_VERSION = "UPDATE_CUSTOM_MIX_VERSION";
export const UPDATE_MIX_DATA_HASH = "UPDATE_MIX_DATA_HASH";
export const LOAD_INITIAL_TRACK_DATA = "LOAD_INITIAL_TRACK_DATA";
export const SEEK = "SEEK";
export const SET_LOCATOR_TIME = "SET_LOCATOR_TIME";
export const WAVEFORM_BUFFER_LOADED = "WAVEFORM_BUFFER_LOADED";
export const WAVEFORM_ENVELOPES_LOADED = "WAVEFORM_ENVELOPES_LOADED";
export const SET_HD_BUFFERS_LOADED = "SET_HD_BUFFERS_LOADED";
export const SET_VOLUME_INDICATOR_VALUES = "SET_VOLUME_INDICATOR_VALUES";
export const SET_MASTER_VOLUME_INDICATOR_VALUES =
  "SET_MASTER_VOLUME_INDICATOR_VALUES";
export const CLONE_PROJECT_FOR_OFFLINE = "CLONE_PROJECT_FOR_OFFLINE";
export const INITIALIZE_OFFLINE_RENDER = "INITIALIZE_OFFLINE_RENDER";
export const FINISH_OFFLINE_RENDER = "FINISH_OFFLINE_RENDER";
export const UPDATE_OFFLINE_RENDER_PROGRESS = "UPDATE_OFFLINE_RENDER_PROGRESS";
export const UPDATE_PROJECT_TITLE = "UPDATE_PROJECT_TITLE";
export const CHANGE_CUSTOM_MIX_PROJECT = "CHANGE_CUSTOM_MIX_PROJECT";
export const LOAD_MIX = "LOAD_MIX";
export const LOAD_HD_STEMS = "LOAD_HD_STEMS";
export const LOAD_CUSTOM_MIX_PROJECT = "LOAD_CUSTOM_MIX_PROJECT";
export const SAVE_INITIAL_TRACK_CACHE = "SAVE_INITIAL_TRACK_CACHE";
export const REHYDRATE_CUSTOM_MIX = "REHYDRATE_CUSTOM_MIX";
export const LOAD_LANE = "LOAD_LANE";
export const UPLOAD_LANE = "UPLOAD_LANE";
export const SOLO_RECALL_DISABLE = "SOLO_RECALL_DISABLE";
export const SOLO_RECALL = "SOLO_RECALL";
export const MUTE_RECALL_DISABLE = "MUTE_RECALL_DISABLE";
export const MUTE_RECALL = "MUTE_RECALL";
export const INITIALIZE_LANE_LOADING = "INITIALIZE_LANE_LOADING";
export const FINISH_LANE_LOADING = "FINISH_LANE_LOADING";
export const UPDATE_LANE_LOADING_PROGRESS = "UPDATE_LANE_LOADING_PROGRESS";

export interface ReorderLanesAction {
  type: typeof REORDER_LANES;
  sourceIndex: number;
  destinationIndex: number;
}

export interface SetSnapModeAction {
  type: typeof SET_SNAP_MODE;
  snapMode: SnapMode;
}

export interface MoveRegionAction {
  type: typeof MOVE_REGION;
  newStartTime: number;
  regionId: string;
}

export interface MoveRegionToDifferentLaneAction {
  type: typeof MOVE_REGION_DIFFERENT_LANE;
  newLaneIndex: number;
  oldLaneIndex: number;
  regionId: string;
  newStartTime: number;
}

export interface ChangeRegionDurationAction {
  type: typeof CHANGE_REGION_DURATION;
  newDuration: number;
  regionId: string;
}

export interface ChangeRegionStartAction {
  type: typeof CHANGE_REGION_START;
  newStartTime: number;
  regionId: string;
  newStartTimeOffsetPixels: number;
}

export interface ZoomChangeAction {
  type: typeof ZOOM_CHANGE;
  newZoomLevel: number;
}

export interface SetMuteSoloAction {
  type: typeof SET_MUTE_SOLO;
  muteSoloStatus: MuteSoloState;
  laneId: string;
}

export interface SetPanAction {
  type: typeof SET_PAN;
  panValue: number;
  laneId: string;
}

export interface SetVolumeAction {
  type: typeof SET_VOLUME;
  volumeValue: number;
  laneId: string;
  isTemp?: boolean;
}

export interface SetMasterVolumeAction {
  type: typeof SET_MASTER_VOLUME;
  volumeValue: number;
}

export interface SetMasterMuteAction {
  type: typeof SET_MASTER_MUTE;
  isMuted: boolean;
}

export interface SetMetronomeVolumeAction {
  type: typeof SET_METRONOME_VOLUME;
  volumeValue: number;
}

export interface SetMetronomeMuteAction {
  type: typeof SET_METRONOME_MUTE;
  isMuted: boolean;
}

export interface SetAlertAction {
  type: typeof SET_ALERT;
  id: string;
  role: string;
  title: string;
  message: string;
}

export interface ClearAlertAction {
  type: typeof CLEAR_ALERT;
}

export interface DeleteLaneAction {
  type: typeof DELETE_LANE;
  laneId: string;
}

export interface DuplicateLaneAction {
  type: typeof DUPLICATE_LANE;
  laneId: string;
}

export interface RenameLaneAction {
  type: typeof RENAME_LANE;
  laneId: string;
  name: string;
}

export interface UIShowFXPanelAction {
  type: typeof UI_SHOW_FX_PANEL;
  laneId: string;
}

export interface SetFXEffectAction {
  type: typeof SET_FX_EFFECT;
  effect: FX;
  laneId: string;
  slotNumber: number;
}

export interface SetFXValueAction {
  type: typeof SET_FX_VALUE;
  value: number;
  laneId: string;
  slotId: number;
}

export interface SetEditorModeAction {
  type: typeof SET_EDITOR_MODE;
  mode: EditorMode;
}

export interface SplitRegionAction {
  type: typeof SPLIT_REGION;
  split_offset_seconds: number;
  regionId: string;
  laneId: string;
  newRegionId: string;
}

export interface DeleteRegionAction {
  type: typeof DELETE_REGION;
  regionId: string;
  laneId: string;
}

export interface DuplicateRegionAction {
  type: typeof DUPLICATE_REGION;
  regionId: string;
  laneId: string;
  newRegionId: string;
}

export interface SetFadeStartAction {
  type: typeof SET_FADE_START;
  regionId: string;
  fadeTime: number;
}

export interface SetFadeEndAction {
  type: typeof SET_FADE_END;
  regionId: string;
  fadeTime: number;
}

export interface EmptyProjectAction {
  type: typeof EMPTY_PROJECT;
}

export interface RehydrateInitialMixAction {
  type: typeof REHYDRATE_INITIAL_MIX;
  lanes: Lanes;
  regions: Regions;
  initialTrackData: InitialTrackData;
}

export interface SetEditorDataDumpAction {
  type: typeof SET_EDITOR_DATA_DUMP;
  editorDataDump: SaveSerialization;
}
export interface SetEditorOpenableAction {
  type: typeof SET_EDITOR_OPENABLE;
  editorOpenable: boolean;
}

export interface SetEditorDemoAction {
  type: typeof SET_EDITOR_DEMO;
  editorDemo: boolean;
}

export interface SetFreeStemsAction {
  type: typeof SET_FREE_STEMS;
  freeStems: boolean;
}

/*
 * newId: hash id for editor lane (stem)
 * laneId: hash id link to original track's stem so we can link an editor lane with original track stem
 */
export interface CreateLaneAction {
  type: typeof CREATE_LANE;
  newId: string;
  originalId: string;
  name: string;
  instrument: Instrument;
  color: string;
  gainCompensation: number;
}

export interface UpdateCustomMixMetaAction {
  type: typeof UPDATE_CUSTOM_MIX_META;
  id: string;
  projectId: string;
  trackId: string;
  title: string;
  length: number;
  numVersions: number;
}

export interface UpdateCustomMixVersionAction {
  type: typeof UPDATE_CUSTOM_MIX_VERSION;
  id: string;
  versionNumber: number;
  editorDataDumpHash: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface UpdateMixDataHashAction {
  type: typeof UPDATE_MIX_DATA_HASH;
  editorDataDumpHash: string;
}

export interface LoadInitialTrackDataAction {
  type: typeof LOAD_INITIAL_TRACK_DATA;
  initialTrackData: InitialTrackData;
}

export interface CreateRegionAction {
  type: typeof CREATE_REGION;
  newId: string;
  visualStartTime: number;
  visualDuration: number;
  audioPath: string;
  audioPathHQ: string;
  audioStartTime: number;
  audioSrcDuration: number;
  audioSrcColors: string[];
  audioSrcSeed: number;
}

export interface AddRegionToLaneAction {
  type: typeof ADD_REGION_TO_LANE;
  laneId: string;
  regionId: string;
}

export interface SetPlaybackStateAction {
  type: typeof SET_PLAYBACK_STATE;
  state: PlaybackState;
  actionSongTime: number;
}

export interface ScrollWorkspaceAction {
  type: typeof SCROLL_WORKSPACE;
  x: number;
}

export interface SeekAction {
  type: typeof SEEK;
  seconds: number;
}

export interface SetLocatorTimeAction {
  type: typeof SET_LOCATOR_TIME;
  seconds: number;
}

export interface WaveformBufferLoadedAction {
  type: typeof WAVEFORM_BUFFER_LOADED;
  audioPath: string;
  audioBuffer: AudioBuffer;
}

export interface WaveformEnvelopesLoadedAction {
  type: typeof WAVEFORM_ENVELOPES_LOADED;
  audioPath: string;
  envelopes: Envelopes;
}

export interface SetHDBuffersLoadedAction {
  type: typeof SET_HD_BUFFERS_LOADED;
  hdLoaded: Boolean;
}

export interface SetVolumeIndicatorValuesAction {
  type: typeof SET_VOLUME_INDICATOR_VALUES;
  laneId: string;
  currentPeak: number;
  recentPeak: number;
}

export interface SetMasterVolumeIndicatorvaluesAction {
  type: typeof SET_MASTER_VOLUME_INDICATOR_VALUES;
  currentPeak: number;
  recentPeak: number;
}

export interface CloneProjectForOfflineAction {
  type: typeof CLONE_PROJECT_FOR_OFFLINE;
  project: MainStore;
}

export interface InitializeOfflineRenderAction {
  type: typeof INITIALIZE_OFFLINE_RENDER;
}

export interface FinishOfflineRenderAction {
  type: typeof FINISH_OFFLINE_RENDER;
}

export interface UpdateOfflineRenderProgressAction {
  type: typeof UPDATE_OFFLINE_RENDER_PROGRESS;
  progress: number;
}

export interface UpdateProjectTitle {
  type: typeof UPDATE_PROJECT_TITLE;
  title: string;
}

export interface ChangeCustomMixProject {
  type: typeof CHANGE_CUSTOM_MIX_PROJECT;
  projectId: string;
}

export interface LoadMixFromInitialTrack {
  type: typeof LOAD_MIX;
  projectId: string;
  fromCache: boolean;
  paidAccess: boolean;
  onRehydrated?: (project: Project) => void;
  onLoaded?: (project: Project) => void;
}

export interface LoadHDStems {
  type: typeof LOAD_HD_STEMS;
}

export interface LoadCustomMixProjectAction {
  type: typeof LOAD_CUSTOM_MIX_PROJECT;
  customMixId: string;
  versionNumber: number;
  paidAccess: boolean;
  onRehydrated?: (customMix: Project) => void;
  onLoaded?: (customMix: Project) => void;
}

export interface SaveInitialTrackCacheAction {
  type: typeof SAVE_INITIAL_TRACK_CACHE;
  trackId: string;
  onSuccess: Function;
  onFail: Function;
}

export interface RehydrateCustomMixAction {
  type: typeof REHYDRATE_CUSTOM_MIX;
  project: Project;
}

export interface ResetStoreAction {
  type: typeof RESET_STORE;
}

export interface LoadLaneAction {
  type: typeof LOAD_LANE;
  isBlock: boolean;
  id: string;
}

export interface UploadLaneAction {
  type: typeof UPLOAD_LANE;
  url: string;
  name: string;
}

export interface SoloRecallAction {
  type: typeof SOLO_RECALL;
}

export interface SoloRecallDisableAction {
  type: typeof SOLO_RECALL_DISABLE;
}

export interface MuteRecallAction {
  type: typeof MUTE_RECALL;
}

export interface MuteRecallDisableAction {
  type: typeof MUTE_RECALL_DISABLE;
}

export interface InitializeLaneLoading {
  type: typeof INITIALIZE_LANE_LOADING;
  laneId: string;
}

export interface FinishLaneLoading {
  type: typeof FINISH_LANE_LOADING;
  laneId: string;
}

export interface UpdateLaneLoadingProgress {
  type: typeof UPDATE_LANE_LOADING_PROGRESS;
  progress: number;
  laneId: string;
}

export type LaneActions =
  | ReorderLanesAction
  | SetMuteSoloAction
  | MoveRegionToDifferentLaneAction
  | SetPanAction
  | SetVolumeAction
  | SetFXEffectAction
  | SetFXValueAction
  | UpdateProjectTitle
  | ChangeCustomMixProject
  | DeleteRegionAction
  | SplitRegionAction
  | DuplicateRegionAction
  | SetFadeStartAction
  | SetFadeEndAction
  | CreateLaneAction
  | AddRegionToLaneAction
  | RenameLaneAction
  | DeleteLaneAction
  | DuplicateLaneAction
  | SoloRecallAction
  | SoloRecallDisableAction
  | MuteRecallAction
  | MuteRecallDisableAction
  | InitializeLaneLoading
  | UpdateLaneLoadingProgress
  | FinishLaneLoading;
