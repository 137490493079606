import styled from "styled-components";

export const Container = styled.div`
  .fx-panel {
    position: absolute;
    left: 0 !important;
    width: ${({ theme, tightLayout }) =>
      tightLayout
        ? theme.editor.tightLaneControlsW
        : theme.editor.laneControlsW};
    max-width: unset;
    margin: -5px 0;
  }

  .fx-panel .ant-tooltip-arrow {
    display: none;
  }

  .fx-panel .ant-tooltip-inner {
    bottom: 0px;
    background-color: ${({ theme }) => theme.editor.backgroundDark};
  }

  .fx-panel-children > * {
    padding: 0 3px;
    width: 100%;
  }
`;
