import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";

const Landing1 = lazy(() => import("../../pages/Shared/Landings/1"));

/*
 * Add all routes specific to the main app here
 *
 * NB: this also handles different routing depending on user authentication
 * e.g., redirects if not logged in, etc.
 */
const RoutesLanding = ({ root }) => {
  return (
    <Switch>
      <Route exact path={root} component={Landing1} />
    </Switch>
  );
};

export default RoutesLanding;
