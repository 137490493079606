import styled from "styled-components";

export const Container = styled.div`
  color: ${({ theme }) => theme.editor.textLight};
  font-family: ${({ theme }) => theme.fontMedium};
  max-width: 33vw;
  min-width: 500px;

  && .ant-alert-error {
    border: 0;
    margin-bottom: 1.5em;
    border-radius: ${({ theme }) => theme.borderRadiusContainers};
  }

  .equal-columns {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    column-gap: 10px;
  }

  #upload-or {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
  }

  #upload-drag-drop-container {
    position: relative;
    border: 3px dashed ${({ theme }) => theme.editor.backgroundLight};
    border-radius: 5px;
    padding: 1em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
  }

  #drag-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  #upload-drag-drop-container.drag-active {
    border-color: ${({ theme }) => theme.editor.textLight};
    transform: scale(1.05);
  }

  #original-lanes-container {
    margin-top: 3em;
  }

  #original-lanes-header {
    font-family: ${({ theme }) => theme.fontHeavy};
    font-size: 18px;
    font-weight: 700;
  }

  #original-lanes-list-container {
    display: flex;
    flex-wrap: wrap;
  }
`;
