import React from "react";
import { Container } from "./styles";

/*
 * Displays the emblem of the stem editor (4 stem boxes) within
 * an auto-stretching horizontal container.
 */
const EditorStemsEmblem = () => {
  return (
    <Container>
      <div className="stem-box stem-box-0"></div>
      <div className="stem-box stem-box-1"></div>
      <div className="stem-box stem-box-2"></div>
      <div className="stem-box stem-box-3"></div>
    </Container>
  );
};

export default EditorStemsEmblem;
