import styled from "styled-components";

export const Button = styled.button`
  background-color: ${props =>
    props.secondary
      ? props.theme.backgroundTag
      : props.subscribe
        ? props.theme.subscribe
        : props.textButton
          ? props.theme.backgroundLight
          : props.theme.highlight};
  height: ${props => (props.height ? `${props.height}px` : "31px")};
  width: ${props => (props.width ? `${props.width}px` : "auto")};
  padding: ${props => (props.py ? `${props.py}px` : "6px")}
    ${props => (props.px ? `${props.px}px` : "12px")};
  color: ${props =>
    props.mutedText ? props.theme.grey2 : props.theme.textLight};
  font-size: ${props =>
    props.textButton ? props.theme.p2.fs : props.theme.p3b.fs};
  font-family: ${props => props.theme.p3b.ff};
  letter-spacing: 0px;
  line-height: 100%;
  border-radius: ${props => props.theme.borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 300ms ease-in-out;

  &&:hover {
    transform: scale(1.03);
  }

  &:disabled {
    background-color: ${props => props.theme.grey} !important;
    color: ${props => props.mutedText} !important;
    cursor: default !important;
  }
`;
