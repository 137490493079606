import { Reducer } from "redux";
import { WaveformBuffers } from "../store";
import {
  WAVEFORM_BUFFER_LOADED,
  SET_HD_BUFFERS_LOADED,
  WaveformBufferLoadedAction,
  SetHDBuffersLoadedAction,
  WAVEFORM_ENVELOPES_LOADED,
  WaveformEnvelopesLoadedAction
} from "../actions/actionTypes";

export let initialWaveFormBufferState = { byAudioPath: {}, hdLoaded: null };
export const waveformBufferReducer: Reducer<
  WaveformBuffers,
  | WaveformBufferLoadedAction
  | WaveformEnvelopesLoadedAction
  | SetHDBuffersLoadedAction
> = (state = initialWaveFormBufferState, action) => {
  switch (action.type) {
    case WAVEFORM_BUFFER_LOADED:
      return {
        ...state,
        byAudioPath: {
          ...state.byAudioPath,
          [action.audioPath]: {
            ...state.byAudioPath[action.audioPath],
            audioBuffer: action.audioBuffer
          }
        }
      };
    case WAVEFORM_ENVELOPES_LOADED:
      return {
        ...state,
        byAudioPath: {
          ...state.byAudioPath,
          [action.audioPath]: {
            ...state.byAudioPath[action.audioPath],
            envelopes: action.envelopes
          }
        }
      };
    case SET_HD_BUFFERS_LOADED:
      return {
        ...state,
        hdLoaded: action.hdLoaded
      };
    default:
      return state;
  }
};
