import React, { useContext } from "react";
import Tag from "../../Tag";
import { SearchContext } from "../../../context";
import { TagsContainer } from "./styles";
import moment from "moment";
import { newTagFilter } from "../../Tag/newTagFilter";

const Tags = ({
  instruments,
  tags,
  publishDate,
  newTagAmountOfDays,
  hasLines = true,
  hasLabels = false,
  addButton = false,
  onAddTagClick,
  onAddInstrumentClick
}) => {
  const search = useContext(SearchContext);

  const correctPublishDate = moment(
    moment(
      publishDate,
      moment(publishDate).format() === "Invalid date" ? "x" : ""
    ).format()
  );

  return (
    <TagsContainer hasLines={hasLines}>
      {hasLabels && <p className="tag-label">Tags</p>}
      {((tags && tags.filter(Boolean).length > 0) ||
        correctPublishDate >=
          moment().subtract(newTagAmountOfDays, "days")) && (
        <div className="tags tag-1">
          <div className="tag-inner">
            {correctPublishDate >=
              moment().subtract(newTagAmountOfDays, "days") && (
              <Tag
                onMouseEnter={() => {
                  search.handleHoveredRange(
                    newTagFilter.name,
                    newTagFilter.type,
                    newTagFilter.value,
                    1,
                    5
                  );
                }}
                onMouseLeave={() => {
                  search.clearHoveredFilter();
                }}
                onClick={() => {
                  search.addDateRangeFilter(newTagFilter);
                }}
                className="tag"
                newTag
              >
                New
              </Tag>
            )}
            {tags &&
              tags.filter(Boolean).length > 0 &&
              tags.map((item, index) => {
                if (typeof item === "object") item = item.name;
                return (
                  <Tag
                    key={index}
                    onMouseEnter={() =>
                      search.handleHoveredSelectable(item, "selectable")
                    }
                    onMouseLeave={() => {
                      search.clearHoveredFilter();
                    }}
                    onClick={() => {
                      search.addSelectable(item, "tags");
                    }}
                    className="tag"
                  >
                    {item}
                  </Tag>
                );
              })}
            {addButton && (
              <Tag
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
                onClick={onAddTagClick}
                className="tag"
                newTag
              >
                Add
              </Tag>
            )}
          </div>
        </div>
      )}
      {hasLabels && <p className="tag-label">Instruments</p>}
      {instruments && instruments.filter(Boolean).length > 0 && (
        <div className="tags">
          <div className="tag-inner">
            {instruments.map((item, index) => {
              if (typeof item === "object") item = item.name;
              return (
                <Tag
                  key={index}
                  onMouseEnter={() =>
                    search.handleHoveredSelectable(item, "selectable")
                  }
                  onMouseLeave={() => {
                    search.clearHoveredFilter();
                  }}
                  onClick={() => {
                    search.addSelectable(item, "instruments");
                  }}
                  className="tag"
                >
                  {item}
                </Tag>
              );
            })}
            {addButton && (
              <Tag
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
                onClick={onAddInstrumentClick}
                className="tag"
                newTag
              >
                Add
              </Tag>
            )}
          </div>
        </div>
      )}
    </TagsContainer>
  );
};

export default Tags;
