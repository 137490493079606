import React, { useState, useEffect } from "react";
import { CardContainer } from "./styles";
import moment from "moment";
import parse from "html-react-parser";
import { Avatar } from "../../components/Avatar";

const NotificationCard = ({ notification, onClick }) => {
  const [time, setTime] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      let time = notification.created_at.includes("-")
        ? moment(notification.created_at).fromNow()
        : moment(parseInt(notification.created_at)).fromNow();

      let formatedTime = time;
      formatedTime.includes("hours") &&
        (formatedTime = time.replace(" hours", "hrs"));
      formatedTime.includes("years") &&
        (formatedTime = time.replace(" years", "yrs"));
      formatedTime.includes("days") &&
        (formatedTime = time.replace(" days", "d"));

      formatedTime.includes(" minutes") &&
        (formatedTime = time.replace(" minutes", "m"));
      formatedTime.includes(" seconds") &&
        (formatedTime = time.replace(" seconds", "s"));
      formatedTime.includes("an hour ago") &&
        (formatedTime = time.replace("an hour ago", "1hr ago"));
      formatedTime.includes("a minute ago") &&
        (formatedTime = time.replace("a minute ago", "1m ago"));
      formatedTime.includes("a day ago") &&
        (formatedTime = time.replace("a day ago", "1d ago"));
      formatedTime.includes("a year ago") &&
        (formatedTime = time.replace("a year ago", "1yr ago"));
      formatedTime.includes("a year ago") &&
        (formatedTime = time.replace("a year ago", "1yr ago"));
      time.includes("a few seconds ago") &&
        (formatedTime = time.replace("a few seconds ago", "just now"));
      setTime(formatedTime);
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <CardContainer onClick={onClick} color={notification.payload.color}>
      <Avatar color={notification.payload.color}>
        {notification.payload.icon && (
          <img
            className="selected-icon"
            src={require(`../../assets/profile_icons_new/${notification.payload.icon}`)}
            alt="icon"
            style={{ width: "100%" }}
          />
        )}
      </Avatar>
      <div className="content">
        <p className="p2b name">{notification.payload.name}</p>
        <div className="p3m body">{parse(notification.payload.body)}</div>{" "}
      </div>
      <div>
        <div className="time-container">
          <div className="time-sub-container">
            <p className="p3r grey time">{time}</p>
          </div>

          {!notification.status && <div className="unread"></div>}
        </div>
      </div>
    </CardContainer>
  );
};

export default NotificationCard;
