import gql from "graphql-tag";
import { fragments } from "../../utils/fragments";

export const CREATE_PROJECT_MUTATION = gql`
  mutation(
    $projectName: String
    $description: String
    $projectUrl: String
    $creator: ID!
    $team: ID
  ) {
    createProject(
      data: {
        name: $projectName
        description: $description
        url: $projectUrl
        creator: $creator
        team: $team
      }
    ) {
      ...ProjectData
    }
  }
  ${fragments.projectQuery}
`;
