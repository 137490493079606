import { Instrument, Tag, ThematicCategory } from "../store";

export const UPDATE_MOOD = "UPDATE_MOOD";
export const UPDATE_DENSITY = "UPDATE_DENSITY";
export const UPDATE_ENERGY = "UPDATE_ENERGY";
export const UPDATE_GRAVITY = "UPDATE_GRAVITY";
export const UPDATE_ENSEMBLE = "UPDATE_ENSEMBLE";
export const UPDATE_MELODY = "UPDATE_MELODY";
export const UPDATE_TENSION = "UPDATE_TENSION";
export const UPDATE_RHYTHM = "UPDATE_RHYTHM";
export const SET_TAGS = "SET_TAGS";
export const SET_SYSTEM_TAGS = "SET_SYSTEM_TAGS";
export const SET_INSTRUMENTS = "SET_INSTRUMENTS";
export const SET_THEMATIC_CATEGORIES = "SET_THEMATIC_CATEGORIES";

export interface UpdateMoodAction {
  type: typeof UPDATE_MOOD;
  mood: number;
}

export interface UpdateDensityAction {
  type: typeof UPDATE_DENSITY;
  density: number;
}

export interface UpdateEnergyAction {
  type: typeof UPDATE_ENERGY;
  energy: number;
}

export interface UpdateMelodyAction {
  type: typeof UPDATE_MELODY;
  melody: number;
}

export interface UpdateGravityAction {
  type: typeof UPDATE_GRAVITY;
  gravity: number;
}

export interface UpdateEnsembleAction {
  type: typeof UPDATE_ENSEMBLE;
  ensemble: number;
}

export interface UpdateTensionAction {
  type: typeof UPDATE_TENSION;
  tension: number;
}

export interface UpdateRhythmAction {
  type: typeof UPDATE_RHYTHM;
  rhythm: number;
}

export interface SetTagsAction {
  type: typeof SET_TAGS;
  tags: Tag[];
}

export interface SetSystemTagsAction {
  type: typeof SET_SYSTEM_TAGS;
  systemTags: string[];
}

export interface SetInstrumentsAction {
  type: typeof SET_INSTRUMENTS;
  instruments: Instrument[];
}

export interface SetThematicCategoriesAction {
  type: typeof SET_THEMATIC_CATEGORIES;
  thematicCategories: ThematicCategory[];
}
