import { Reducer } from "redux";
import {
  SCROLL_WORKSPACE,
  ScrollWorkspaceAction,
  SET_VOLUME_INDICATOR_VALUES,
  SetVolumeIndicatorValuesAction,
  SET_MASTER_VOLUME_INDICATOR_VALUES,
  SetMasterVolumeIndicatorvaluesAction,
  SET_MASTER_VOLUME,
  SetMasterVolumeAction,
  SET_MASTER_MUTE,
  SetMasterMuteAction,
  SET_METRONOME_VOLUME,
  SetMetronomeVolumeAction,
  SET_METRONOME_MUTE,
  SetMetronomeMuteAction,
  UI_SHOW_FX_PANEL,
  UIShowFXPanelAction,
  SetAlertAction,
  ClearAlertAction,
  CLEAR_ALERT,
  SET_ALERT,
  ZoomChangeAction,
  SetSnapModeAction,
  SET_SNAP_MODE,
  ZOOM_CHANGE
} from "../actions/actionTypes";
import { UIState, SnapMode } from "../store";

export let initialUiState: UIState = {
  activeFxLane: null,
  projectScrollX: 0,
  volumeIndicators: {},
  masterVolumeIndicator: {
    currentPeak: 0,
    recentPeak: 0
  },
  masterVolume: 1,
  masterMute: false,
  metronomeVolume: 1,
  metronomeMute: true,
  alert: null,
  snap: SnapMode.BAR,
  zoomLevel: 1.9
};

export const uiStateReducer: Reducer<
  UIState,
  | ZoomChangeAction
  | SetSnapModeAction
  | UIShowFXPanelAction
  | ScrollWorkspaceAction
  | SetVolumeIndicatorValuesAction
  | SetMasterVolumeIndicatorvaluesAction
  | SetMasterVolumeAction
  | SetMasterMuteAction
  | SetMetronomeVolumeAction
  | SetMetronomeMuteAction
  | SetAlertAction
  | ClearAlertAction
> = (state = initialUiState, action) => {
  switch (action.type) {
    case SET_SNAP_MODE:
      return {
        ...state,
        snap: action.snapMode
      };
    case ZOOM_CHANGE:
      return {
        ...state,
        zoomLevel: action.newZoomLevel
      };
    case UI_SHOW_FX_PANEL:
      return {
        ...state,
        activeFxLane: action.laneId
      };
    case SCROLL_WORKSPACE:
      return {
        ...state,
        projectScrollX: action.x
      };
    case SET_VOLUME_INDICATOR_VALUES:
      return {
        ...state,
        volumeIndicators: {
          ...state.volumeIndicators,
          [action.laneId]: {
            currentPeak: action.currentPeak,
            recentPeak: action.recentPeak
          }
        }
      };
    case SET_MASTER_VOLUME_INDICATOR_VALUES:
      return {
        ...state,
        masterVolumeIndicator: {
          currentPeak: action.currentPeak,
          recentPeak: action.recentPeak
        }
      };
    case SET_MASTER_VOLUME:
      return {
        ...state,
        masterVolume: action.volumeValue
      };
    case SET_MASTER_MUTE:
      return {
        ...state,
        masterMute: action.isMuted
      };
    case SET_METRONOME_VOLUME:
      return {
        ...state,
        metronomeVolume: action.volumeValue
      };
    case SET_METRONOME_MUTE:
      return {
        ...state,
        metronomeMute: action.isMuted
      };
    case SET_ALERT:
      return {
        ...state,
        alert: {
          id: action.id,
          role: action.role,
          title: action.title,
          message: action.message
        }
      };
    case CLEAR_ALERT:
      return {
        ...state,
        alert: null
      };
    default:
      return state;
  }
};
