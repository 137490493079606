import { MixingBoardContextType } from "../../context/EditorContext/MixingBoardProvider";
import { InternalFX } from "../../store";
import { piecewiseInterp } from "../../util/interpolation";
import { bpm_to_mspb } from "../../util/quantization";
import CustomFXFactory from "./CustomFX/CustomFXFactory";
import { InternalFXNode } from "./InternalFXNode";

/*
 * Encapsulates a very custom effect (one that can't be created by simply daisy chaining together
 * tuna fx or send fx nodes) allowing for parameter and tempo manipulation.
 */
export class BlueDotEffectNode extends InternalFXNode {
  private customEffectNode: AudioNode;

  /*
   * Parameters:
   *  mixingBoard: MixingBoardContext (has access to AudioMaster)
   *  fxConfig: json serialization of effect params
   */
  constructor(mixingBoard: MixingBoardContextType, fxConfig: InternalFX) {
    super(mixingBoard, fxConfig);

    const effectName = fxConfig.effectId.split(".")[1];
    const CustomEffect = CustomFXFactory.getCustomFX(effectName);
    this.customEffectNode = new CustomEffect(
      mixingBoard.audioMaster,
      fxConfig.params
    );

    // patch to GainNode first for tuna shim
    this._inputConnect(this.customEffectNode).connect(this.outputNode);
  }

  /*
   * Updates the bpm to be used for bpm-locked fx
   *
   *  Parameters:
   *    bpm: the new bpm to set (called when redux updates it)
   */
  public setBPM(bpm: number) {
    for (let param in this.fxConfig.tempoLock ?? {}) {
      const msDelay = bpm_to_mspb(bpm) * this.fxConfig.tempoLock[param];
      this.customEffectNode[param] = msDelay;
    }
  }

  /*
   * When knob controlling insert effect updates, set the value
   *
   * Parameters:
   *  val: [0,1] knob value
   */
  public setValue(val: number) {
    // loop through mappings and apply to each internal param
    for (let param in this.fxConfig.mapping ?? {}) {
      const pMapping = this.fxConfig.mapping[param];
      // figure out where val is in step range
      const interpVal = piecewiseInterp(
        pMapping.stopsX,
        pMapping.stopsY,
        pMapping.stopsInterp,
        val
      );

      this.customEffectNode[param] = interpVal;
    }
  }
}
