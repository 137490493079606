import * as React from "react";
import { useDispatch } from "react-redux";
import { LaneNameContainer } from "./styles";
import { LOAD_LANE, LoadLaneAction } from "../../actions/actionTypes";

/*
 * This component is used in the AddLaneModal to represent an original
 * lane (stem) from the original track
 */
export function LaneName({ lane, color, afterAdd }) {
  const dispatch = useDispatch();

  function addOriginalLane() {
    dispatch<LoadLaneAction>({
      type: LOAD_LANE,
      isBlock: false,
      id: lane.id
    });
    afterAdd();
  }

  return (
    <LaneNameContainer onClick={addOriginalLane}>
      <div
        className="lane-color-bubble"
        style={{ backgroundColor: color }}
      ></div>
      <div className="lane-name">{lane.name}</div>
    </LaneNameContainer>
  );
}
