import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MixingBoardContext } from "../context/EditorContext/MixingBoardProvider";
import { FX, MainStore, MuteSoloState } from "../store";
import { AudioMaster } from "./AudioMaster";
import { Effect } from "./FX/Effect";

interface InsertEffectNodeProps {
  laneId: string;
  slotNumber: number;
  outputNode: AudioNode;
  inputNode: AudioNode;
  audioMaster: AudioMaster;
}

/*
 * An insert effect with bypass control. This is a React Component
 * so it has access the Context providers (like MixingBoard) and redux store.
 *
 * INPUT -------------> GAIN ----------------> OUTPUT
 *   |                                           ^
 *   |                                           |
 *    ----> Effect <fx1 -> fx2 -> fxn> ----------
 *                          |
 *                          |
 *                           ---------------> SEND FX
 */
export function InsertEffectNode(props: InsertEffectNodeProps) {
  const insertEffectData = useSelector<MainStore, FX>(
    state =>
      state.project.lanes.byId[props.laneId].laneStatus.fxSlot[props.slotNumber]
  );
  const bpm = useSelector<MainStore, number>(
    state => state.project.initialTrackData.bpm
  );
  const muteSoloState = useSelector<MainStore, number>(
    state => state.project.lanes.byId[props.laneId].laneStatus.muteSoloState
  );
  const soloLaneCount = useSelector<MainStore, number>(
    state => state.project.lanes.solodLaneIds.length
  );

  const mixingBoard = useContext(MixingBoardContext);

  // eslint-disable-next-line
  const [dryGain, setDryGain] = useState<GainNode>(
    new GainNode(props.audioMaster.context)
  );
  const [effect, setEffect] = useState<Effect>(null);

  useEffect(() => {
    // init with dry passthrough on gain control for bypass
    props.inputNode.connect(dryGain).connect(props.outputNode);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      insertEffectData &&
      (effect === null || effect.fxData.id !== insertEffectData.id)
    ) {
      // the effect is ready to be initialized or has changed
      const newEffect = new Effect(mixingBoard, insertEffectData);
      setEffect(newEffect);
      props.inputNode.connect(newEffect).connect(props.outputNode);

      return () => {
        // we've switched out the effect, disconnect and allow garbage connection of nodes
        // take care to disconnect from global send nodes otherwise there will be mem leak
        newEffect.outputNode.gain.setTargetAtTime(
          0,
          props.audioMaster.context.currentTime,
          0.01
        );
        // wait for a bit for the tail before disconnecting to avoid clicks/pops
        setTimeout(() => {
          // this call disconnects from any global sends
          newEffect.disconnect();
        }, 250);
      };
    }
    // eslint-disable-next-line
  }, [props.inputNode, props.outputNode, insertEffectData.id]);

  // update effect BPM setting for tempo-locked fx
  useEffect(() => {
    if (effect && bpm) effect.setBPM(bpm);
  }, [bpm, effect]);

  // update the effect value when it changes
  // Crossfades when switching in/out of bypass state (val = 0)
  useEffect(() => {
    const isMuted =
      (soloLaneCount > 0 && muteSoloState !== MuteSoloState.SOLO) ||
      muteSoloState === MuteSoloState.MUTE;
    const bypassed = insertEffectData.val === 0 || isMuted;

    if (effect !== null) {
      effect.setValue(bypassed ? 0 : insertEffectData.val);

      // ramp up or down fx gain quickly to avoid clicks when changing bypass state
      effect.outputNode.gain.setTargetAtTime(
        bypassed ? 0 : 1,
        props.audioMaster.context.currentTime,
        0.01
      );
    }

    // quickly ramp up or down dry gain quickly to avoid clicks when changing bypass state
    dryGain.gain.setTargetAtTime(
      bypassed ? 1 : 0,
      props.audioMaster.context.currentTime,
      0.01
    );
    // eslint-disable-next-line
  }, [effect, dryGain, insertEffectData.val, muteSoloState, soloLaneCount]);

  return null;
}
