import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Download } from "../../../assets/Download";
import GenericPopover from "../../../components/GenericPopover";
import { AuthContext } from "../../../context/AuthProvider";
import { EditorContext } from "../../context/EditorContext/EditorProvider";
import {
  InitializeOfflineRenderAction,
  INITIALIZE_OFFLINE_RENDER
} from "../../actions/actionTypes";
import { InitialTrackData, MainStore } from "../../store";
import { Button } from "../Button";
import { ExitButton } from "../Buttons/ExitButton";
import { IconButton } from "../Buttons/IconButton";
import { MasterVolumeControl } from "../Buttons/MasterVolumeControl";
import { DynamicWaveform } from "../DynamicWaveform";
import { LoadingBar } from "../LoadingBar/LoadingBar";
import { MainMenuControls } from "../MainMenuControls";
import { ProjectInfoGrid } from "../ProjectInfoGrid";
import { TransportControls } from "../TransportControls";
import { Container, RightControls } from "./styles";
import { ExpandCollapseButton } from "../Buttons/ExpandCollapseButton";
import useModal from "../../../hooks/useModal";

export let Header = props => {
  const dispatch = useDispatch();

  const user = useContext(AuthContext);
  const editor = useContext(EditorContext);

  const demoPopover = useModal();

  const initialTrackData = useSelector<MainStore, InitialTrackData>(
    state => state.project.initialTrackData
  );
  const progress = useSelector<MainStore, number>(
    state => state.editorState.renderProgressPercentage
  );
  const isRendering = useSelector<MainStore, boolean>(
    state => state.editorState.isRendering
  );

  const { push } = useHistory();

  const downloadMix = () => {
    dispatch<InitializeOfflineRenderAction>({
      type: INITIALIZE_OFFLINE_RENDER
    });
  };

  return (
    <Container tightLayout={editor.tightLayout}>
      <div className="player-container">
        <div className="details">
          {initialTrackData.thumbnail && (
            <img
              className="thumbnail"
              src={initialTrackData.thumbnail}
              alt="Album cover"
            />
          )}
          <div
            className="title"
            onClick={() => {
              editor.close();
              push(`/browse/track/${initialTrackData.id}`);
            }}
          >
            {initialTrackData.title}
          </div>
          <div
            className="album"
            onClick={() => {
              editor.close();
              push(`/album/${initialTrackData.albumId}`);
            }}
          >
            <div className="album-name">
              {initialTrackData.variationTitle
                ? `| ${initialTrackData.variationTitle} `
                : ` ${initialTrackData.albumName} `}
            </div>
          </div>
        </div>
        <TransportControls />
        <DynamicWaveform height={64} />
        <ProjectInfoGrid />
        <RightControls>
          {isRendering && (
            <LoadingBar
              progress={Math.round(progress * 100)}
              title={"Rendering audio"}
              width={115}
              isLoading={isRendering}
            />
          )}
          {editor.isDemo &&
            (!user.isUserPrivate() ||
              (!user.user.paidUser && !user.user.adminUser)) && (
              <GenericPopover
                xOffset="0px"
                yOffset="60px"
                anchor="top"
                popoverState={demoPopover}
                triggerComponent={
                  <Button className="btn-demo" height="auto" px="12">
                    Demo
                  </Button>
                }
                contents={
                  <div id="demo-subscribe-popover">
                    <h4>Subscribe for access to all tracks</h4>
                    <p>
                      The Multitrack Editor is only available to subscribers and
                      license holders.
                    </p>
                    <Button
                      className="btn-subscribe"
                      onClick={() => {
                        editor.close();
                        push("/subscription");
                      }}
                    >
                      Subscribe
                    </Button>
                  </div>
                }
              />
            )}
          <MasterVolumeControl />
          {!isRendering && (
            <IconButton svgWidth="16px" svgHeight="16px" onClick={downloadMix}>
              <Download />
            </IconButton>
          )}
          <MainMenuControls />
          <ExpandCollapseButton onClick={props.onExpand} />
          <ExitButton onClick={editor.close} />
        </RightControls>
      </div>
    </Container>
  );
};
