import reduceReducers from "reduce-reducers";
import { combineReducers, Reducer } from "redux";
import {
  CREATE_LANE,
  DELETE_LANE,
  MOVE_REGION_DIFFERENT_LANE,
  MUTE_RECALL,
  MUTE_RECALL_DISABLE,
  MuteRecallAction,
  MuteRecallDisableAction,
  REORDER_LANES,
  SET_MUTE_SOLO,
  SetMuteSoloAction,
  SOLO_RECALL,
  SOLO_RECALL_DISABLE,
  SoloRecallAction,
  SoloRecallDisableAction,
  LaneActions
} from "../actions/actionTypes";
import { ByIdLane, MuteSoloState, Lanes } from "../store";
import {
  appendToList,
  appendToListIfNotExist,
  removeFromList,
  removeFromListIfExists,
  reorder
} from "../util/util";
import { laneReducer } from "./lane";
import { initialLaneParamters } from "./laneStatus";
import { laneLoadingDefaultState } from "./laneLoadingStatus";

const initialLanes: Lanes = {
  byId: {},
  laneIds: [],
  solodLaneIds: [],
  muteLaneIds: [],
  soloRecall: [],
  muteRecall: []
};

const byIdReducer: Reducer<ByIdLane, LaneActions> = (
  state: ByIdLane = {},
  action: LaneActions
) => {
  // @ts-ignore
  const laneId = action.laneId;
  if (laneId) {
    return {
      ...state,
      [laneId]: laneReducer(state[laneId], action)
    };
  }
  return state;
};

const laneIdsReducer: Reducer<string[], LaneActions> = (
  state: string[] = [],
  action: LaneActions
) => {
  switch (action.type) {
    case REORDER_LANES:
      return reorder(state, action.sourceIndex, action.destinationIndex);
    default:
      return state;
  }
};

const lanesCombinedReducer = combineReducers<Lanes>({
  byId: byIdReducer,
  laneIds: laneIdsReducer,
  solodLaneIds: (state = [], action: SetMuteSoloAction) => {
    switch (action.type) {
      case SET_MUTE_SOLO:
        if (action.muteSoloStatus === MuteSoloState.SOLO) {
          return appendToListIfNotExist(state, action.laneId);
        } else {
          return removeFromListIfExists(state, action.laneId);
        }
      default:
        return state;
    }
  },
  muteLaneIds: (state = [], action: SetMuteSoloAction) => {
    switch (action.type) {
      case SET_MUTE_SOLO:
        if (action.muteSoloStatus === MuteSoloState.MUTE) {
          return appendToListIfNotExist(state, action.laneId);
        } else {
          return removeFromListIfExists(state, action.laneId);
        }
      default:
        return state;
    }
  },
  soloRecall: (state = [], action) => {
    return state;
  },
  muteRecall: (state = [], action) => {
    return state;
  }
});

const helperSoloMuteRecall = (
  state: Lanes,
  action: SoloRecallAction | MuteRecallAction
) => {
  const isSolo = action.type === SOLO_RECALL;
  const isMute = !isSolo;

  const stored = isSolo ? state.soloRecall : state.muteRecall;
  const clonedById = { ...state.byId };

  stored.forEach(laneId => {
    clonedById[laneId] = {
      ...clonedById[laneId],
      laneStatus: {
        ...clonedById[laneId].laneStatus,
        muteSoloState: isSolo ? MuteSoloState.SOLO : MuteSoloState.MUTE
      }
    };
  });
  return {
    ...state,
    byId: clonedById,
    soloRecall: isSolo ? [] : state.soloRecall,
    solodLaneIds: isSolo ? stored : state.solodLaneIds,
    muteRecall: isMute ? [] : state.muteRecall,
    muteLaneIds: isMute ? stored : state.muteLaneIds
  };
};

const helperSoloMuteDisable = (
  state: Lanes,
  action: SoloRecallDisableAction | MuteRecallDisableAction
) => {
  const isSolo = action.type === SOLO_RECALL_DISABLE;
  const isMute = !isSolo;

  const clonedById = { ...state.byId };
  const relevantLanes = state.laneIds.filter(laneId => {
    const muteSoloState = clonedById[laneId].laneStatus.muteSoloState;
    return muteSoloState === (isSolo ? MuteSoloState.SOLO : MuteSoloState.MUTE);
  });

  // clear all
  relevantLanes.forEach(laneId => {
    clonedById[laneId] = {
      ...clonedById[laneId],
      laneStatus: {
        ...clonedById[laneId].laneStatus,
        muteSoloState: MuteSoloState.DEFAULT
      }
    };
  });
  return {
    ...state,
    byId: clonedById,
    soloRecall: isSolo ? relevantLanes : state.soloRecall,
    solodLaneIds: isSolo ? [] : state.solodLaneIds,
    muteRecall: isMute ? relevantLanes : state.muteRecall,
    muteLaneIds: isMute ? [] : state.muteLaneIds
  };
};

const lanesSpecialReducer: Reducer<Lanes, LaneActions> = (
  state: Lanes = initialLanes,
  action
) => {
  switch (action.type) {
    case CREATE_LANE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.newId]: {
            id: action.newId,
            name: action.name,
            originalId: action.originalId,
            instrument: action.instrument,
            color: action.color,
            regionIds: [],
            laneLoadingStatus: {
              ...laneLoadingDefaultState
            },
            laneStatus: {
              ...initialLaneParamters,
              gainCompensation: action.gainCompensation
            }
          }
        },
        laneIds: appendToList(state.laneIds, action.newId)
      };
    case DELETE_LANE:
      const { [action.laneId]: _, ...removedById } = state.byId;
      return {
        ...state,
        laneIds: removeFromList(state.laneIds, action.laneId),
        solodLaneIds: removeFromList(state.solodLaneIds, action.laneId),
        muteLaneIds: removeFromList(state.muteLaneIds, action.laneId),
        soloRecall: removeFromList(state.soloRecall, action.laneId),
        muteRecall: removeFromList(state.muteRecall, action.laneId),
        byId: removedById
      };
    case MOVE_REGION_DIFFERENT_LANE:
      const new_lane_id = state.laneIds[action.newLaneIndex];
      const old_lane_id = state.laneIds[action.oldLaneIndex];
      return {
        ...state,
        byId: {
          ...state.byId,
          [new_lane_id]: {
            ...state.byId[new_lane_id],
            regionIds: appendToList(
              state.byId[new_lane_id].regionIds,
              action.regionId
            )
          },
          [old_lane_id]: {
            ...state.byId[old_lane_id],
            regionIds: removeFromList(
              state.byId[old_lane_id].regionIds,
              action.regionId
            )
          }
        }
      };
    case SOLO_RECALL:
    case MUTE_RECALL:
      return helperSoloMuteRecall(state, action);
    case SOLO_RECALL_DISABLE:
    case MUTE_RECALL_DISABLE:
      return helperSoloMuteDisable(state, action);
    default:
      return state;
  }
};

export const lanesReducer: Reducer<Lanes, LaneActions> = reduceReducers(
  lanesCombinedReducer,
  lanesSpecialReducer
);
