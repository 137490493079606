import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props => (props.mobileView ? "flex-start" : "center")};
  height: 48px;
  align-items: center;
`;

export const Inner = styled.div`
  width: ${props => (props.isRange ? "124px" : "fit-content")};
  height: 24px;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${(
    props // TODO: end this nightmare (remove nested ifs)
  ) =>
    props.isActive
      ? props.theme.backgroundTag
      : props.mobileView
      ? props.theme.backgroundDark
      : props.theme.backgroundTag};
  cursor: pointer;
  position: relative;
  display: flex;

  .tooltip-overlay {
    border-radius: 0px;
  }

  & .ant-tooltip-inner {
    border-radius: 0px;
  }

  .range {
    position: absolute;
    background-color: ${props =>
      props.mobileView ? props.theme.backgroundTag2 : props.theme.grey3};
    position: absolute;
    height: 100%;
    z-index: 1;
    left: ${props => props.starting};
    right: ${props => props.ending};
    width: ${props => props.width};
    border-radius: ${props => (props.width === "30%" ? "100%" : "16px")};
  }
`;

export const Tag = styled.div`
  width: 100%;
  border-radius: ${props => props.theme.borderRadius};
  border: 0.7px solid props;
  display: ${props => (!props.showRange ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  text-align: center;

  .text {
    font-size: ${props => props.theme.p3b.fs};
    font-family: ${props => props.theme.p3b.ff};
    color: ${props =>
      props.isActive
        ? props.theme.textLight
        : props.mobileView
        ? props.theme.textLight
        : props.theme.textDark};
    z-index: 1;
    text-transform: capitalize;
    line-height: 18px;
  }
`;

export const FilterRange = styled.div`
  width: 100%;
  height: 100%;
  opacity: ${props => (props.showRange ? 1 : 0)};
  bottom: 0.5px;
  position: absolute;
  transition: all 0.3s ease-in;
`;
