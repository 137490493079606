import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useApolloClient } from "react-apollo";
import { CCFile, Container, File, Files, Icon } from "./styles";
import { download } from "../../utils/downloadTrack";
import IconButton from "../IconButton";
import { FETCH_VARIATIONS } from "../../context/SearchContext/queries";
import { MessageContext } from "../../context/MessageContext";
import { REQUEST_SIGNED_URL } from "../TrackCheckoutDetail/queries";
import { CCIcon } from "../CreativeCommonsLicense/styles";
import { Tooltip } from "antd";

const icons = [
  require("../../assets/cc.svg"),
  require("../../assets/dollar-not.svg"),
  require("../../assets/person.svg")
];

const TrackDetails = ({
  downloadable = false,
  track = {},
  createDownload,
  setCreatedDownload,
  downloadAttributeModal
}) => {
  const client = useApolloClient();
  const { showMessage } = useContext(MessageContext);
  const [loading, setLoading] = useState(true);
  const [variationTracks, setVariations] = useState([]);
  const [wavFileSize, setWavFileSize] = useState(500);
  // const [mp3FileSize, setMp3FileSize] = useState(420);

  const fileRef = useRef(null);

  const fetchVariations = useCallback(async () => {
    try {
      const res = await client.query({
        query: FETCH_VARIATIONS,
        variables: { id: track.id }
      });

      setVariations(res.data.track.variationTracks);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [client, track.id]);

  useEffect(() => {
    fetchVariations();
  }, [client, fetchVariations]);

  useEffect(() => {
    if (track.fileMetaData.wavFileSize) {
      setWavFileSize(parseFloat(track.fileMetaData.wavFileSize).toFixed(0));
    }
    // if (track.fileMetaData.mp3FileSize) {
    //   setMp3FileSize(parseFloat(track.fileMetaData.mp3FileSize).toFixed(0));
    // }
  }, [track.fileMetaData.wavFileSize, track.fileMetaData.mp3FileSize]);

  const onFileClick = async (isMainTrackMp3 = "false", downloadUrl, title) => {
    // If they can't download return so they don't create a download record.
    if (!downloadable && isMainTrackMp3 !== "true") {
      return showMessage({
        type: "error",
        duration: 5000,
        title: "License Required",
        description: "Please purchase this track to access this file."
      });
    }

    // Don't create a download record unless they are allowed to download.
    let response = await createDownload(title);

    setCreatedDownload(response.data.createDownload);

    if (isMainTrackMp3 === "true" && !downloadable) {
      // do download logic
      download(`${process.env.REACT_APP_FILES_URL}/${downloadUrl}`, title);

      downloadAttributeModal.open();
      return response;
    }

    try {
      const res = await client.query({
        query: REQUEST_SIGNED_URL,
        variables: {
          key: downloadUrl
        }
      });

      download(res.data.requestSignedUrl, title);
    } catch (err) {
      showMessage({
        type: "error",
        duration: 5000,
        title: "Download Error",
        description:
          "Sorry, there was an issue downloading. Please try again later."
      });

      console.error("download modal", err);
    }
  };

  const tooltipText =
      "Please purchase this track or subscribe to access this file.",
    tooltipStem =
      "A Stem file is an audio file that contains a track split on each musical instrument.";

  return (
    <Container downloadable={downloadable}>
      <img
        src={track.thumbnail}
        alt={track.title}
        className="track-image image-border-radius"
      />
      <div className="details">
        <span className="flex-row">
          <h2 className="title flex-item">{track.title}</h2>
          {downloadable && (
            <IconButton
              className="download-all flex-item"
              icon={require("../../assets/download.svg")}
              iconBackground="subscribe"
              text={`Download All (${wavFileSize} MB)`}
              onClick={() =>
                onFileClick(
                  "false",
                  track.fileMetaData.wavZip,
                  `${track.title} | All WAV Files`
                )
              }
            />
          )}
        </span>
        <Files downloadable={downloadable}>
          <div className="section">
            <p className="sub-title">Master Track</p>
            <CCFile
              onClick={() =>
                onFileClick("true", track.mp3File, `${track.name}`)
              }
              downloadable={downloadable}
            >
              {track.name}.mp3
              <div className="cc-icons">
                {icons.map(item => (
                  <CCIcon src={item} alt={"icon"} key={item} />
                ))}
              </div>
            </CCFile>
            <Tooltip
              overlayStyle={{
                position: "fixed",
                display: downloadable && "none"
              }}
              title={tooltipText}
              placement="right"
            >
              <File
                id="musicFile"
                ref={fileRef}
                onClick={() =>
                  onFileClick("false", track.wavFile, `${track.name}`)
                }
                downloadable={downloadable}
              >
                {track.name}.wav
              </File>
            </Tooltip>
          </div>
          <div className="section">
            {loading && <p> Loading variations...</p>}
            {!loading && variationTracks.length !== 0 && (
              <p className="sub-title">Variations</p>
            )}

            {!loading &&
              variationTracks.map((item, index) => (
                <Tooltip
                  overlayStyle={{
                    position: "fixed",
                    display: downloadable && "none"
                  }}
                  title={tooltipText}
                  key={index}
                  placement="right"
                >
                  <File
                    key={item.id}
                    onClick={() =>
                      onFileClick("false", item.wavFile, item.name)
                    }
                    downloadable={downloadable}
                  >
                    {item.name}.wav
                  </File>
                </Tooltip>
              ))}
          </div>
          <div className="section">
            {track.stem.length > 0 && (
              <>
                <Tooltip
                  overlayStyle={{
                    position: "fixed",
                    left: 0
                  }}
                  title={tooltipStem}
                  placement="right"
                >
                  <div className="sub-title">
                    Stems
                    <Icon src={require("../../assets/info.svg")} />
                  </div>
                </Tooltip>
                <Tooltip
                  overlayStyle={{
                    position: "fixed",
                    display: downloadable && "none"
                  }}
                  title={tooltipText}
                  placement="right"
                >
                  <File
                    onClick={() =>
                      onFileClick(
                        "false",
                        track.fileMetaData.wavStemZip,
                        `${track.name} All Stem Files`
                      )
                    }
                    downloadable={downloadable}
                  >
                    All Stem Files.zip
                  </File>
                </Tooltip>
              </>
            )}
            {track.stem &&
              track.stem.map((item, index) => {
                let regexForNum = /[0-9]+_/;
                let regexForUnderScore = /_/;

                return (
                  <Tooltip
                    overlayStyle={{
                      position: "fixed",
                      display: downloadable && "none"
                    }}
                    title={tooltipText}
                    key={index}
                    placement="right"
                  >
                    <File
                      key={item.name}
                      onClick={() =>
                        onFileClick("false", item.wavFile, track.name)
                      }
                      downloadable={downloadable}
                    >
                      {item.name
                        .replace(regexForNum, "")
                        .replace(regexForUnderScore, " ")}
                      .wav
                    </File>
                  </Tooltip>
                );
              })}
          </div>
        </Files>
      </div>
    </Container>
  );
};

export default TrackDetails;
