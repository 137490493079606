import * as React from "react";
import { theme } from "../globalStyles";

export const FadeHandler = () => (
  <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.65471 16.5874C13.177 16.5874 16.8431 12.9213 16.8431 8.39903C16.8431 3.87674 13.177 0.210693 8.65471 0.210693C4.13241 0.210693 0.46637 3.87674 0.46637 8.39903C0.46637 12.9213 4.13241 16.5874 8.65471 16.5874Z"
      fill={theme.editor.textDark}
    />
    <path
      d="M14.4447 2.60901L2.86468 14.1891C4.34648 15.6709 6.39358 16.5874 8.65474 16.5874C13.177 16.5874 16.8431 12.9214 16.8431 8.39906C16.8431 6.1379 15.9266 4.09081 14.4447 2.60901Z"
      fill={theme.editor.textLight}
    />
  </svg>
);
