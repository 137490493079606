/* 
  This file contains one huge array of quick filters.
*/

export const quickFilters = [
  {
    aggregateName: "Accoustic Group",
    ranges: [
      { name: "Ensemble", min: 0, max: 2 },
      { name: "Melody", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Abstract beat",
    ranges: [
      { name: "Ensemble", min: 8, max: 9 },
      { name: "Tension", min: 6, max: 9 },
      { name: "Rhythm", min: 5, max: 9 }
    ]
  },
  {
    aggregateName: "Adrenaline",
    ranges: [
      { name: "Density", min: 8, max: 9 },
      { name: "Rhythm", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Adventure",
    ranges: [
      { name: "Gravity", min: 6, max: 9 },
      { name: "Energy", min: 7, max: 9 },
      { name: "Ensemble", min: 0, max: 3 },
      { name: "Melody", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Aerobics",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Energy", min: 6, max: 9 },
      { name: "Tension", min: 0, max: 5 },
      { name: "Rhythm", min: 6, max: 9 }
    ],
    BPM: { min: 115, max: 130 }
  },
  {
    aggregateName: "Aggressive",
    ranges: [
      { name: "Energy", min: 7, max: 9 },
      { name: "Tension", min: 7, max: 9 },
      { name: "Rhythm", min: 5, max: 9 }
    ]
  },
  {
    aggregateName: "Angry ",
    ranges: [
      { name: "Energy", min: 7, max: 9 },
      { name: "Tension", min: 7, max: 9 },
      { name: "Rhythm", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Aliens",
    ranges: [
      { name: "Ensemble", min: 8, max: 9 },
      { name: "Tension", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Anthem",
    ranges: [
      { name: "Density", min: 6, max: 9 },
      { name: "Melody", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Anxious",
    ranges: [
      { name: "Mood", min: 0, max: 3 },
      { name: "Gravity", min: 6, max: 8 },
      { name: "Tension", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Atmoshperic",
    ranges: [
      { name: "Energy", min: 0, max: 3 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Awe-Inspiring",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Gravity", min: 6, max: 9 },
      { name: "Melody", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Attitude",
    ranges: [
      { name: "Density", min: 0, max: 3 },
      { name: "Melody", min: 5, max: 7 },
      { name: "Tension", min: 5, max: 6 }
    ]
  },
  {
    aggregateName: "Bold",
    ranges: [
      { name: "Gravity", min: 7, max: 9 },
      { name: "Melody", min: 8, max: 9 },
      { name: "Rhythm", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Bouncy",
    ranges: [
      { name: "Gravity", min: 0, max: 3 },
      { name: "Rhythm", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Bright",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Gravity", min: 0, max: 4 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  { aggregateName: "Busy", ranges: [{ name: "Mood", min: 8, max: 9 }] },
  // {
  //   aggregateName: "Calm",
  //   ranges: [
  //     { name: "Energy", min: 0, max: 4 },
  //     { name: "Tension", min: 0, max: 3 },
  //     { name: "Rhythm", min: 0, max: 4 }
  //   ]
  // },
  {
    aggregateName: "Carefree",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Gravity", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Chaotic",
    ranges: [
      { name: "Density", min: 7, max: 9 },
      { name: "Energy", min: 7, max: 9 },
      { name: "Rhythm", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Cheerful",
    ranges: [
      { name: "Mood", min: 8, max: 9 },
      { name: "Gravity", min: 0, max: 3 },
      { name: "Energy", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Children",
    ranges: [
      { name: "Mood", min: 8, max: 9 },
      { name: "Gravity", min: 0, max: 3 }
    ],
    tags: ["Kids"]
  },
  {
    aggregateName: "Chill",
    ranges: [
      { name: "Energy", min: 0, max: 4 },
      { name: "Tension", min: 0, max: 4 },
      { name: "Rhythm", min: 0, max: 5 }
    ]
  },
  {
    aggregateName: "Cinematic",
    ranges: [
      { name: "Gravity", min: 7, max: 9 },
      { name: "Energy", min: 5, max: 9 },
      { name: "Melody", min: 8, max: 9 }
    ]
  },
  { aggregateName: "Circuits", ranges: [{ name: "Ensemble", min: 8, max: 9 }] },
  {
    aggregateName: "Comedy",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Gravity", min: 0, max: 1 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Comforting",
    ranges: [
      { name: "Mood", min: 4, max: 7 },
      { name: "Gravity", min: 1, max: 4 },
      { name: "Melody", min: 6, max: 8 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Comic",
    ranges: [
      { name: "Mood", min: 8, max: 9 },
      { name: "Gravity", min: 0, max: 1 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Complex",
    ranges: [
      { name: "Density", min: 8, max: 9 },
      { name: "Gravity", min: 7, max: 9 },
      { name: "Rhythm", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Contemplative",
    ranges: [
      { name: "Gravity", min: 2, max: 4 },
      { name: "Energy", min: 0, max: 4 },
      { name: "Rhythm", min: 3, max: 5 }
    ]
  },
  {
    aggregateName: "Creepy",
    ranges: [
      { name: "Gravity", min: 6, max: 9 },
      { name: "Energy", min: 0, max: 4 },
      { name: "Tension", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Crime",
    ranges: [
      { name: "Mood", min: 0, max: 3 },
      { name: "Gravity", min: 7, max: 9 },
      { name: "Tension", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Crowdfunding",
    ranges: [
      { name: "Mood", min: 5, max: 9 },
      { name: "Energy", min: 5, max: 9 },
      { name: "Rhythm", min: 5, max: 9 }
    ]
  },
  {
    aggregateName: "Dance",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Gravity", min: 0, max: 3 },
      { name: "Rhythm", min: 8, max: 9 }
    ],
    BPM: { min: 115, max: 130 }
  },
  {
    aggregateName: "Dark",
    ranges: [
      { name: "Mood", min: 0, max: 1 },
      { name: "Gravity", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Dawn",
    ranges: [
      { name: "Mood", min: 5, max: 9 },
      { name: "Density", min: 0, max: 4 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Delicate",
    ranges: [
      { name: "Density", min: 0, max: 4 },
      { name: "Energy", min: 0, max: 3 },
      { name: "Tension", min: 0, max: 2 },
      { name: "Rhythm", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Discovery",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Gravity", min: 6, max: 9 },
      { name: "Melody", min: 5, max: 9 }
    ]
  },
  {
    aggregateName: "Disturbing",
    ranges: [
      { name: "Density", min: 8, max: 9 },
      { name: "Tension", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Downtempo",
    ranges: [
      { name: "Energy", min: 0, max: 3 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 3, max: 4 }
    ],
    BPM: { min: 70, max: 100 }
  },
  {
    aggregateName: "Documentary",
    ranges: [
      { name: "Ensemble", min: 3, max: 6 },
      { name: "Melody", min: 0, max: 5 }
    ]
  },
  {
    aggregateName: "Drama",
    ranges: [
      { name: "Gravity", min: 6, max: 9 },
      { name: "Melody", min: 6, max: 9 }
    ]
  },
  // {
  //   aggregateName: "Dramatic",
  //   ranges: [
  //     { name: "Gravity", min: 6, max: 9 },
  //     { name: "Tension", min: 7, max: 9 },
  //     { name: "Rhythm", min: 6, max: 9 }
  //   ]
  // },
  {
    aggregateName: "Drone",
    ranges: [
      { name: "Energy", min: 0, max: 3 },
      { name: "Ensemble", min: 7, max: 9 },
      { name: "Melody", min: 0, max: 1 }
    ],
    instruments: ["Synth Pads"]
  },
  {
    aggregateName: "Dreamy",
    ranges: [
      { name: "Mood", min: 2, max: 7 },
      { name: "Gravity", min: 0, max: 3 },
      { name: "Melody", min: 5, max: 9 }
    ]
  },
  {
    aggregateName: "Easy Listening ",
    ranges: [
      { name: "Gravity", min: 0, max: 4 },
      { name: "Energy", min: 0, max: 4 },
      { name: "Tension", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Eccentric",
    ranges: [
      { name: "Melody", min: 0, max: 3 },
      { name: "Tension", min: 6, max: 9 }
    ]
  },
  { aggregateName: "Edgy", ranges: [{ name: "Tension", min: 7, max: 9 }] },
  {
    aggregateName: "Educational",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Density", min: 3, max: 6 },
      { name: "Melody", min: 0, max: 5 }
    ]
  },
  {
    aggregateName: "Eerie",
    ranges: [
      { name: "Mood", min: 0, max: 3 },
      { name: "Energy", min: 0, max: 5 },
      { name: "Tension", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Elegant",
    ranges: [
      { name: "Density", min: 0, max: 4 },
      { name: "Energy", min: 0, max: 4 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Empowering",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Gravity", min: 6, max: 9 },
      { name: "Melody", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Emotional",
    ranges: [
      { name: "Mood", min: 0, max: 4 },
      { name: "Density", min: 0, max: 4 },
      { name: "Energy", min: 0, max: 6 },
      { name: "Melody", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Energizing",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Density", min: 7, max: 9 },
      { name: "Energy", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Epic",
    ranges: [
      { name: "Gravity", min: 8, max: 9 },
      { name: "Energy", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Ethereal",
    ranges: [
      { name: "Melody", min: 0, max: 3 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Euphoric",
    ranges: [
      { name: "Mood", min: 2, max: 7 },
      { name: "Gravity", min: 0, max: 3 },
      { name: "Melody", min: 5, max: 9 }
    ]
  },
  {
    aggregateName: "Experimental",
    ranges: [
      { name: "Ensemble", min: 6, max: 9 },
      { name: "Tension", min: 5, max: 9 }
    ]
  },
  {
    aggregateName: "Explosive",
    ranges: [
      { name: "Density", min: 8, max: 9 },
      { name: "Gravity", min: 8, max: 9 },
      { name: "Energy", min: 8, max: 9 },
      { name: "Tension", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Feel Good",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Gravity", min: 0, max: 3 },
      { name: "Melody", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Floating",
    ranges: [
      { name: "Energy", min: 0, max: 2 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Frantic",
    ranges: [
      { name: "Mood", min: 0, max: 4 },
      { name: "Gravity", min: 7, max: 9 },
      { name: "Tension", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Funny",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Gravity", min: 0, max: 1 },
      { name: "Tension", min: 0, max: 2 }
    ]
  },
  { aggregateName: "Future", ranges: [{ name: "Ensemble", min: 7, max: 9 }] },
  {
    aggregateName: "Galactic",
    ranges: [
      { name: "Gravity", min: 7, max: 9 },
      { name: "Ensemble", min: 8, max: 9 },
      { name: "Melody", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Gentle",
    ranges: [
      { name: "Energy", min: 0, max: 1 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Gloomy",
    ranges: [
      { name: "Mood", min: 2, max: 4 },
      { name: "Gravity", min: 4, max: 6 }
    ]
  },
  {
    aggregateName: "Gospel",
    ranges: [{ name: "Energy", min: 5, max: 9 }],
    tags: ["Church"]
  },
  {
    aggregateName: "Graceful",
    ranges: [
      { name: "Gravity", min: 0, max: 4 },
      { name: "Melody", min: 7, max: 9 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Groovy",
    ranges: [
      { name: "Gravity", min: 0, max: 4 },
      { name: "Rhythm", min: 5, max: 9 }
    ]
  },
  {
    aggregateName: "Happy",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Gravity", min: 0, max: 3 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Haunting",
    ranges: [
      { name: "Mood", min: 1, max: 1 },
      { name: "Gravity", min: 8, max: 9 },
      { name: "Tension", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Heartwarming",
    ranges: [
      { name: "Melody", min: 7, max: 9 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Hopeful",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Gravity", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Heavenly",
    ranges: [
      { name: "Gravity", min: 0, max: 4 },
      { name: "Melody", min: 7, max: 9 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Impact",
    ranges: [
      { name: "Melody", min: 0, max: 3 },
      { name: "Tension", min: 6, max: 9 },
      { name: "Rhythm", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Industrial",
    ranges: [
      { name: "Gravity", min: 5, max: 9 },
      { name: "Melody", min: 0, max: 5 },
      { name: "Tension", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Innocent",
    ranges: [
      { name: "Density", min: 0, max: 4 },
      { name: "Melody", min: 6, max: 9 },
      { name: "Tension", min: 0, max: 2 }
    ]
  },
  {
    aggregateName: "Inspiring",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Gravity", min: 6, max: 9 },
      { name: "Melody", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Instrumental ",
    ranges: [
      { name: "Ensemble", min: 0, max: 3 },
      { name: "Melody", min: 5, max: 9 }
    ]
  },
  {
    aggregateName: "Intense",
    ranges: [
      { name: "Gravity", min: 8, max: 9 },
      { name: "Energy", min: 8, max: 9 },
      { name: "Tension", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Interview Underscore",
    ranges: [
      { name: "Density", min: 0, max: 3 },
      { name: "Melody", min: 0, max: 4 },
      { name: "Rhythm", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Jogging",
    ranges: [
      { name: "Energy", min: 4, max: 9 },
      { name: "Rhythm", min: 6, max: 9 }
    ],
    BPM: { min: 115, max: 130 }
  },
  {
    aggregateName: "Journey",
    ranges: [
      { name: "Gravity", min: 5, max: 9 },
      { name: "Ensemble", min: 0, max: 5 },
      { name: "Melody", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Joyful",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Gravity", min: 0, max: 4 },
      { name: "Melody", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Kickstarter",
    ranges: [
      { name: "Mood", min: 5, max: 9 },
      { name: "Energy", min: 5, max: 9 },
      { name: "Rhythm", min: 5, max: 9 }
    ]
  },
  {
    aggregateName: "Laid Back",
    ranges: [
      { name: "Energy", min: 0, max: 4 },
      { name: "Tension", min: 0, max: 3 }
    ],
    BPM: { min: 0, max: 90 }
  },
  {
    aggregateName: "Horror",
    ranges: [
      { name: "Gravity", min: 8, max: 9 },
      { name: "Tension", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Landscape",
    ranges: [
      { name: "Tension", min: 0, max: 4 },
      { name: "Rhythm", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Lazy",
    ranges: [
      { name: "Energy", min: 0, max: 3 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 2 }
    ]
  },
  {
    aggregateName: "Lounge",
    ranges: [
      { name: "Gravity", min: 0, max: 4 },
      { name: "Energy", min: 0, max: 4 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 5 }
    ]
  },
  {
    aggregateName: "Lullabies",
    ranges: [
      { name: "Gravity", min: 0, max: 4 },
      { name: "Melody", min: 8, max: 9 },
      { name: "Tension", min: 0, max: 2 }
    ]
  },
  {
    aggregateName: "Light",
    ranges: [
      { name: "Mood", min: 1, max: 1 },
      { name: "Energy", min: 0, max: 4 },
      { name: "Tension", min: 0, max: 2 }
    ]
  },
  {
    aggregateName: "Holiday",
    ranges: [
      { name: "Mood", min: 8, max: 9 },
      { name: "Gravity", min: 0, max: 2 },
      { name: "Melody", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Magical",
    ranges: [
      { name: "Melody", min: 4, max: 9 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Magnificent",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Gravity", min: 6, max: 9 },
      { name: "Melody", min: 7, max: 9 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Marching Band",
    ranges: [
      { name: "Energy", min: 7, max: 9 },
      { name: "Ensemble", min: 0, max: 5 },
      { name: "Rhythm", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Mechanical",
    ranges: [
      { name: "Ensemble", min: 7, max: 9 },
      { name: "Melody", min: 0, max: 4 },
      { name: "Tension", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Meditation",
    ranges: [
      { name: "Energy", min: 0, max: 1 },
      { name: "Melody", min: 0, max: 3 },
      { name: "Tension", min: 0, max: 1 }
    ]
  },
  {
    aggregateName: "Melancholic",
    ranges: [
      { name: "Mood", min: 0, max: 4 },
      { name: "Gravity", min: 5, max: 9 },
      { name: "Energy", min: 0, max: 3 },
      { name: "Tension", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Military",
    ranges: [
      { name: "Gravity", min: 6, max: 9 },
      { name: "Tension", min: 6, max: 9 },
      { name: "Rhythm", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Mellow",
    ranges: [
      { name: "Energy", min: 0, max: 4 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 5 }
    ]
  },
  {
    aggregateName: "Modern Blues",
    ranges: [{ name: "Ensemble", min: 6, max: 9 }],
    tags: ["Blues"]
  },
  {
    aggregateName: "Minimal",
    ranges: [
      { name: "Density", min: 0, max: 3 },
      { name: "Energy", min: 0, max: 2 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Modern Classical",
    ranges: [
      { name: "Ensemble", min: 0, max: 1 },
      { name: "Melody", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Modern Jazz",
    ranges: [{ name: "Ensemble", min: 5, max: 9 }],
    tags: ["Jazz"]
  },
  {
    aggregateName: "Mystical",
    ranges: [
      { name: "Mood", min: 3, max: 5 },
      { name: "Energy", min: 0, max: 5 },
      { name: "Rhythm", min: 0, max: 4 }
    ],
    tags: ["Mystery"]
  },
  {
    aggregateName: "Mysterious",
    ranges: [
      { name: "Mood", min: 3, max: 5 },
      { name: "Gravity", min: 0, max: 5 },
      { name: "Tension", min: 6, max: 9 }
    ],
    tags: ["Mystery"]
  },
  {
    aggregateName: "Muzak",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Gravity", min: 0, max: 3 },
      { name: "Melody", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "New Age",
    ranges: [
      { name: "Gravity", min: 0, max: 2 },
      { name: "Ensemble", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Nightlife",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Density", min: 7, max: 9 },
      { name: "Gravity", min: 0, max: 4 },
      { name: "Energy", min: 6, max: 9 },
      { name: "Rhythm", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Nostalgic",
    ranges: [
      { name: "Mood", min: 2, max: 7 },
      { name: "Gravity", min: 0, max: 3 }
    ]
  },
  // {
  //   aggregateName: "Orchestral",
  //   ranges: [{ name: "Ensemble", min: 0, max: 1 }]
  // },
  {
    aggregateName: "Ominous",
    ranges: [
      { name: "Gravity", min: 7, max: 9 },
      { name: "Tension", min: 7, max: 9 }
    ]
  },
  // {
  //   aggregateName: "Optimistic",
  //   ranges: [
  //     { name: "Mood", min: 6, max: 9 },
  //     { name: "Gravity", min: 0, max: 4 },
  //     { name: "Tension", min: 0, max: 3 }
  //   ]
  // },
  {
    aggregateName: "Patriotic",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Density", min: 6, max: 9 },
      { name: "Gravity", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Otherworldly ",
    ranges: [{ name: "Ensemble", min: 8, max: 9 }]
  },
  {
    aggregateName: "Peaceful",
    ranges: [
      { name: "Energy", min: 0, max: 5 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 6 }
    ]
  },
  {
    aggregateName: "Pulsing",
    ranges: [
      { name: "Energy", min: 0, max: 2 },
      { name: "Rhythm", min: 5, max: 9 }
    ]
  },
  // {
  //   aggregateName: "Playful",
  //   ranges: [
  //     { name: "Mood", min: 6, max: 9 },
  //     { name: "Gravity", min: 0, max: 3 }
  //   ]
  // },
  {
    aggregateName: "Quirky",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Gravity", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Quiet",
    ranges: [
      { name: "Density", min: 0, max: 2 },
      { name: "Energy", min: 0, max: 1 },
      { name: "Tension", min: 0, max: 1 },
      { name: "Rhythm", min: 0, max: 1 }
    ]
  },
  {
    aggregateName: "Reflective",
    ranges: [
      { name: "Mood", min: 2, max: 7 },
      { name: "Gravity", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Relaxation",
    ranges: [
      { name: "Energy", min: 0, max: 2 },
      { name: "Tension", min: 0, max: 1 },
      { name: "Rhythm", min: 0, max: 5 }
    ]
  },
  // {
  //   aggregateName: "Rhythmic",
  //   ranges: [
  //     { name: "Energy", min: 4, max: 9 },
  //     { name: "Rhythm", min: 6, max: 9 }
  //   ],
  //   BPM: { min: 90, max: 110 }
  // },
  {
    aggregateName: "Romance",
    ranges: [
      { name: "Density", min: 4, max: 6 },
      { name: "Energy", min: 1, max: 3 },
      { name: "Tension", min: 0, max: 3 }
    ],
    tags: ["Romantic"]
  },
  {
    aggregateName: "Restless",
    ranges: [
      { name: "Energy", min: 0, max: 5 },
      { name: "Ensemble", min: 7, max: 9 },
      { name: "Tension", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Running",
    ranges: [
      { name: "Energy", min: 4, max: 9 },
      { name: "Rhythm", min: 6, max: 9 }
    ],
    BPM: { min: 130, max: 145 }
  },
  {
    aggregateName: "Sci-Fi",
    ranges: [{ name: "Ensemble", min: 8, max: 9 }],
    instruments: ["Volda Syn", "Volda RingSyn"]
  },
  {
    aggregateName: "Scary",
    ranges: [
      { name: "Gravity", min: 5, max: 9 },
      { name: "Tension", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Sensual",
    ranges: [
      { name: "Energy", min: 0, max: 3 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 5 }
    ],
    tags: ["Romantic"]
  },
  {
    aggregateName: "Sexy",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Energy", min: 0, max: 3 },
      { name: "Tension", min: 6, max: 9 }
    ],
    tags: ["Romantic"]
  },
  {
    aggregateName: "Sentimental",
    ranges: [
      { name: "Mood", min: 2, max: 7 },
      { name: "Gravity", min: 0, max: 5 },
      { name: "Melody", min: 5, max: 9 }
    ]
  },
  {
    aggregateName: "Silly",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Gravity", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Simple",
    ranges: [
      { name: "Density", min: 0, max: 2 },
      { name: "Energy", min: 0, max: 2 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Sinister",
    ranges: [
      { name: "Density", min: 5, max: 9 },
      { name: "Tension", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Smooth",
    ranges: [
      { name: "Density", min: 0, max: 4 },
      { name: "Energy", min: 0, max: 4 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 5 }
    ]
  },
  {
    aggregateName: "Smooth Jazz",
    ranges: [
      { name: "Energy", min: 0, max: 4 },
      { name: "Tension", min: 0, max: 3 }
    ],
    tags: ["Jazz"]
  },
  {
    aggregateName: "Soft",
    ranges: [
      { name: "Density", min: 0, max: 4 },
      { name: "Gravity", min: 0, max: 3 },
      { name: "Tension", min: 0, max: 2 },
      { name: "Rhythm", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Soaring",
    ranges: [
      { name: "Density", min: 6, max: 9 },
      { name: "Energy", min: 8, max: 9 },
      { name: "Rhythm", min: 5, max: 9 }
    ]
  },
  {
    aggregateName: "Somber",
    ranges: [
      { name: "Mood", min: 0, max: 1 },
      { name: "Energy", min: 0, max: 3 }
    ],
    BPM: { min: 0, max: 80 }
  },
  {
    aggregateName: "Solo Instruments",
    ranges: [{ name: "Density", min: 1, max: 1 }]
  },
  {
    aggregateName: "Solo Piano",
    ranges: [
      { name: "Density", min: 0, max: 1 },
      { name: "Melody", min: 6, max: 9 }
    ],
    instruments: ["Piano", "Felt Piano", "Toy Piano"]
  },
  {
    aggregateName: "Soothing",
    ranges: [
      { name: "Energy", min: 0, max: 3 },
      { name: "Melody", min: 6, max: 9 },
      { name: "Tension", min: 0, max: 1 }
    ],
    BPM: { min: 0, max: 100 }
  },
  {
    aggregateName: "Soulful",
    ranges: [{ name: "Energy", min: 0, max: 5 }],
    tags: ["Funky"]
  },
  {
    aggregateName: "Soundscape",
    ranges: [
      { name: "Energy", min: 0, max: 3 },
      { name: "Melody", min: 0, max: 4 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Space",
    ranges: [
      { name: "Ensemble", min: 8, max: 9 },
      { name: "Rhythm", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Sparkling",
    ranges: [
      { name: "Density", min: 0, max: 5 },
      { name: "Gravity", min: 0, max: 3 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Spiritual",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Sports",
    ranges: [
      { name: "Mood", min: 3, max: 5 },
      { name: "Density", min: 4, max: 5 },
      { name: "Gravity", min: 4, max: 5 },
      { name: "Energy", min: 5, max: 5 }
    ],
    BPM: { min: 115, max: 200 }
  },
  {
    aggregateName: "Spy",
    ranges: [
      { name: "Mood", min: 0, max: 3 },
      { name: "Gravity", min: 5, max: 9 },
      { name: "Energy", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Suspense",
    ranges: [
      { name: "Gravity", min: 6, max: 9 },
      { name: "Energy", min: 7, max: 9 },
      { name: "Tension", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Sunny",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Gravity", min: 0, max: 3 },
      { name: "Tension", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Technology",
    ranges: [
      { name: "Ensemble", min: 8, max: 9 },
      { name: "Melody", min: 0, max: 4 },
      { name: "Tension", min: 5, max: 9 }
    ]
  },
  {
    aggregateName: "Television Scores",
    ranges: [
      { name: "Gravity", min: 8, max: 9 },
      { name: "Energy", min: 5, max: 9 },
      { name: "Melody", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Thriller",
    ranges: [
      { name: "Gravity", min: 8, max: 9 },
      { name: "Energy", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Touching",
    ranges: [
      { name: "Mood", min: 0, max: 4 },
      { name: "Density", min: 0, max: 4 },
      { name: "Energy", min: 0, max: 6 },
      { name: "Melody", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Traditional Blues",
    ranges: [{ name: "Ensemble", min: 0, max: 5 }],
    tags: ["Blues"]
  },
  {
    aggregateName: "Tragic",
    ranges: [
      { name: "Mood", min: 0, max: 2 },
      { name: "Gravity", min: 8, max: 9 },
      { name: "Melody", min: 7, max: 9 }
    ]
  },
  {
    aggregateName: "Tragedy",
    ranges: [
      { name: "Mood", min: 0, max: 3 },
      { name: "Gravity", min: 7, max: 9 },
      { name: "Energy", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Trailer",
    ranges: [
      { name: "Gravity", min: 7, max: 9 },
      { name: "Energy", min: 5, max: 9 },
      { name: "Melody", min: 8, max: 9 }
    ]
  },
  {
    aggregateName: "Travel",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Gravity", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Tranquil",
    ranges: [
      { name: "Mood", min: 8, max: 9 },
      { name: "Tension", min: 0, max: 1 },
      { name: "Rhythm", min: 0, max: 4 }
    ]
  },
  {
    aggregateName: "Uplifting",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Gravity", min: 0, max: 4 },
      { name: "Melody", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Upbeat",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Gravity", min: 0, max: 4 },
      { name: "Energy", min: 6, max: 9 },
      { name: "Rhythm", min: 7, max: 9 }
    ],
    BPM: { min: 115, max: 130 }
  },
  {
    aggregateName: "Urban ",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Gravity", min: 0, max: 3 },
      { name: "Energy", min: 5, max: 9 },
      { name: "Rhythm", min: 6, max: 9 }
    ]
  },
  {
    aggregateName: "Vibrant",
    ranges: [
      { name: "Mood", min: 6, max: 9 },
      { name: "Gravity", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Walking",
    ranges: [
      { name: "Mood", min: 2, max: 7 },
      { name: "Gravity", min: 0, max: 3 },
      { name: "Rhythm", min: 7, max: 9 }
    ],
    BPM: { min: 0, max: 115 }
  },
  { aggregateName: "Vintage", tags: ["Retro"] },
  {
    aggregateName: "Video Game",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Gravity", min: 0, max: 3 },
      { name: "Ensemble", min: 8, max: 9 }
    ]
  },
  { aggregateName: "Wedding", ranges: [{ name: "Mood", min: 7, max: 9 }] },
  {
    aggregateName: "Whimsical",
    ranges: [
      { name: "Mood", min: 8, max: 9 },
      { name: "Gravity", min: 0, max: 2 }
    ]
  },
  {
    aggregateName: "Workout",
    ranges: [
      { name: "Mood", min: 7, max: 9 },
      { name: "Energy", min: 6, max: 9 },
      { name: "Tension", min: 0, max: 5 },
      { name: "Rhythm", min: 6, max: 9 }
    ],
    BPM: { min: 115, max: 130 }
  },
  {
    aggregateName: "Wistful",
    ranges: [
      { name: "Mood", min: 2, max: 7 },
      { name: "Gravity", min: 0, max: 3 }
    ]
  },
  {
    aggregateName: "Yoga",
    ranges: [
      { name: "Energy", min: 0, max: 2 },
      { name: "Tension", min: 0, max: 3 },
      { name: "Rhythm", min: 0, max: 1 }
    ]
  },
  {
    aggregateName: "World Music",
    ranges: [{ name: "Ensemble", min: 0, max: 3 }]
  },
  { aggregateName: "Sad", ranges: [{ name: "Mood", min: 0, max: 1 }] },
  { aggregateName: "Optimistic", ranges: [{ name: "Mood", min: 8, max: 9 }] },
  { aggregateName: "Sparse", ranges: [{ name: "Density", min: 0, max: 1 }] },
  { aggregateName: "Dense", ranges: [{ name: "Density", min: 8, max: 9 }] },
  { aggregateName: "Playful", ranges: [{ name: "Gravity", min: 0, max: 1 }] },
  { aggregateName: "Low-Energy", ranges: [{ name: "Energy", min: 0, max: 1 }] },
  { aggregateName: "Dramatic", ranges: [{ name: "Gravity", min: 8, max: 9 }] },
  {
    aggregateName: "High-Energy",
    ranges: [{ name: "Energy", min: 8, max: 9 }]
  },
  {
    aggregateName: "Orchestral",
    ranges: [{ name: "Ensemble", min: 0, max: 1 }]
  },
  {
    aggregateName: "Atmospheric",
    ranges: [{ name: "Melody", min: 0, max: 1 }]
  },
  {
    aggregateName: "Electronic",
    ranges: [{ name: "Ensemble", min: 8, max: 9 }]
  },
  { aggregateName: "Thematic", ranges: [{ name: "Melody", min: 8, max: 9 }] },
  { aggregateName: "Calm", ranges: [{ name: "Tension", min: 0, max: 1 }] },
  { aggregateName: "Tense", ranges: [{ name: "Tension", min: 8, max: 9 }] },
  { aggregateName: "Textural", ranges: [{ name: "Rhythm", min: 0, max: 1 }] },
  { aggregateName: "Rhythmic", ranges: [{ name: "Rhythm", min: 8, max: 9 }] }
];
