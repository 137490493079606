import styled from "styled-components";

export const OverlayMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: ${({ show }) => (show ? "1" : "0")};
  transition: opacity 0.5s;
  pointer-events: none;

  #overlay-message-box {
    display: flex;
    position: fixed;
    bottom: 50px;
    background-color: ${({ theme }) => theme.backgroundDark};
    border-radius: ${({ theme }) => theme.borderRadiusContainers};
    max-height: 20%;
    left: 50%;
    transform: translateX(-50%);
  }

  #overlay-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5em;
    text-align: center;
    color: ${({ theme }) => theme.editor.textLight};
    font-family: ${({ theme }) => theme.fontMedium};
  }

  #overlay-message > h4 {
    font-size: 16px;
  }

  #overlay-message > img {
    vertical-align: middle;
    border-style: none;
    max-height: 90%;
    padding-top: 0.5em;
  }

  @media only screen and (max-height: 500px) {
    #overlay-message > img {
      display: none;
    }
  }
`;
