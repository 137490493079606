import React, {
  useState,
  useEffect,
  createContext,
  useCallback,
  useContext
} from "react";

import {
  GET_NOTIFICATIONS,
  READ_NOTIFICATION,
  READ_ALL_NOTIFICATIONS
} from "./queries";
import { withApollo } from "react-apollo";
import { AuthContext } from "../../context/AuthProvider";

export const NotificationsContext = createContext();

const NotificationProvider = ({ children, client }) => {
  const auth = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);

  const [unread, setUnRead] = useState(0);
  const Limit = useCallback(() => {
    if (auth.user.adminUser) {
      return 100;
    } else {
      return 50;
    }
  }, [auth.user.adminUser]);

  const getNotifications = useCallback(async () => {
    if (auth.user.id) {
      try {
        await client
          .watchQuery({
            query: GET_NOTIFICATIONS,
            variables: {
              id: auth.user.id,
              limit: Limit(),
              offset: 0
            },
            fetchPolicy: "network-only",
            pollInterval: 2000
          })
          .subscribe({
            next: body => {
              setNotifications(body.data.getNotifications);

              let count = 0;

              body.data.getNotifications.forEach(item => {
                if (!item.status) {
                  count++;
                }
              });

              setUnRead(count);
            }
          });

        return notifications;
      } catch (err) {
        console.log(err);
      }
    }
  }, [auth.user.id, client, notifications, Limit]);

  // eslint-disable-next-line
  const readNotification = useCallback(
    async (noti, notificationId) => {
      setAsRead(noti, notificationId);

      try {
        await client.mutate({
          mutation: READ_NOTIFICATION,
          variables: { id: notificationId }
        });
      } catch (err) {
        console.log(err);
      }
    },
    // eslint-disable-next-line
    [auth.user.id, client]
  );

  const setAsRead = (noti, notificationId) => {
    const updatedState = noti.map(item => {
      if (item.id === notificationId) {
        item.status = true;
        return item;
      }
      return item;
    });

    setNotifications(updatedState);
  };

  const markAllAsRead = async noti => {
    const updatedState = noti.map(item => {
      item.status = true;
      return item;
    });
    try {
      await client.mutate({
        mutation: READ_ALL_NOTIFICATIONS,
        variables: { id: auth.user.id }
      });

      setNotifications(updatedState);
    } catch (err) {
      console.log(err);
    }
  };

  const clearUnreadCount = () => {
    setUnRead(0);
  };

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        getNotifications,
        readNotification,
        unread,
        clearUnreadCount,
        markAllAsRead
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default withApollo(NotificationProvider);
