/*
 * Performs linear interpolation between 2 values
 *
 * Parameters:
 *  min (number): min of range to interpolate between
 *  max (number): max of range to interpolate between
 *  value (number): proportion in [0,1]
 */
export function linearInterp(min, max, value) {
  return (max - min) * value + min;
}

/*
 * Performs log interpolation between 2 values.
 * Note that log interpolation mathematically doesn't work in certain ranges returning NaN and inf
 * Instead, perform polynomial interpolation: f(x) = -1x**2 + 2x to mimic this curve.
 * This doesn't cause inf and NaNs, but isn't exactly log interpolation. For real log interpolation
 * see @realLogInterp below.
 *
 * Parameters:
 *  min (number): min of range to interpolate between
 *  max (number): max of range to interpolate between
 *  value (number): proportion in [0,1]
 */
export function logInterp(min, max, value) {
  const t = -1 * Math.pow(value, 2) + 2 * value;
  return min + t * (max - min);
}

/*
 * Performs log interpolation between 2 values. Note that log interpolation mathematically doesn't
 * work in certain ranges returning NaN and inf. This does legit log interpolation, it may
 * return NaNs and infs.
 *
 * Log Interp code:
 * const linVal = linearInterp(min, max, value);
 * const minLog = Math.log(min);
 * const maxLog = Math.log(max);
 * const t = (Math.log(linVal) - minLog) / (maxLog - minLog);
 * return min + t * (max - min);
 *
 * Instead, perform polynomial interpolation: f(x) = -1x**2 + 2x to mimic this curve.
 * Performs way more stable and still is the essence of what we want
 *
 * Parameters:
 *  min (number): min of range to interpolate between
 *  max (number): max of range to interpolate between
 *  value (number): proportion in [0,1]
 */
export function realLogInterp(min, max, value) {
  const linVal = linearInterp(min, max, value);
  const minLog = Math.log(min);
  const maxLog = Math.log(max);
  const t = (Math.log(linVal) - minLog) / (maxLog - minLog);
  return min + t * (max - min);
}

/*
 * Performs exponential interpolation between 2 values.
 * Note that exp interpolation mathematically doesn't work in certain ranges returning NaN and inf
 *
 * Exp Interp code:
 * return Math.pow(max, value) * Math.pow(min, 1 - value);
 *
 * Instead, perform polynomial interpolation: f(x) = x**2 to mimic this curve.
 * Performs way more stable and still is the essence of what we want
 *
 * Parameters:
 *  min (number): min of range to interpolate between
 *  max (number): max of range to interpolate between
 *  value (number): proportion in [0,1]
 */
export function expInterp(min, max, value) {
  const t = Math.pow(value, 2);
  return min + t * (max - min);
}

/*
 * Performs piecewise interpolation between multiple stop points between 2 values
 *
 * Parameters:
 *  stopsX ([number]): list of monotonically increasing numbers in [0,1]
 *  stopsY ([number]): range mapped stop x points (can be any numbers)
 *  stopsInterp ([{linear, log}]): linear or log interpolate between stop points
 *  x (number): interpolation value somewhere in range of stopsX
 */
export function piecewiseInterp(stopsX, stopsY, stopsInterp, x) {
  const xClamped = Math.max(stopsX[0], Math.min(stopsX[stopsX.length - 1], x));
  let iStop = stopsX.findIndex(stopX => stopX > xClamped);
  if (iStop === -1) iStop = stopsX.length - 1;
  iStop -= 1;

  const interpFuncMap = {
    linear: linearInterp,
    log: logInterp,
    exp: expInterp
  };
  const interpFunc = interpFuncMap[stopsInterp[iStop]];
  const proportionThroughStop =
    (xClamped - stopsX[iStop]) / (stopsX[iStop + 1] - stopsX[iStop]);
  const interpVal = interpFunc(
    stopsY[iStop],
    stopsY[iStop + 1],
    proportionThroughStop
  );
  return interpVal;
}
