import React from "react";
import styled from "styled-components";

const IconButton = ({
  icon,
  text,
  onClick = () => {},
  iconBackground,
  background,
  style,
  interactive = false,
  active,
  className
}) => {
  return (
    <Button
      className={`row pointer ${className}`}
      iconBackground={iconBackground}
      background={background}
      onClick={onClick}
      style={style}
      active={active}
      interactive={interactive}
    >
      <div className={`arrow-container ${interactive && "fade"}`}>
        <img
          className={`arrow-img  ${interactive && "rotate"}`}
          src={icon}
          alt="open-close"
        />
      </div>
      <p className="p3b mr-18 ml-12 mt-3 ws-nowrap">{text}</p>
    </Button>
  );
};

export default IconButton;

export const Button = styled.div`
  background: ${props => props.background || props.theme.backgroundDark};
  height: 28px;
  border-radius: ${props => props.theme.borderRadius};
  width: fit-content;
  align-items: center;

  .arrow-container {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: ${props => props.theme[props.iconBackground]};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrow-img {
    width: 14px;
    height: 14px;
  }

  .rotate {
    transform: ${props => (props.active ? "rotate(-180deg)" : "")};
    transition: all 0.2s ease-in;
    padding-top: ${props => (props.active ? "0" : "2px")};
  }
  .fade {
    background: ${props => props.theme[props.iconBackground]};
    transition: all 0.2s ease-in;
  }

  .mr-18 {
    margin-right: 18px;
  }

  .ml-12 {
    margin-left: 12px;
  }

  .mt-4 {
    margin-top: 4px;
  }
`;
