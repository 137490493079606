import styled from "styled-components";

export const Container = styled.div`
  flex-grow: 1;
  background-color: #1f1f1f;
  opacity: 0.5;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 3;
  transform-origin: left;
  position: relative;
`;
