import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { Route } from "react-router-dom";

/*
 * Targets guest (public) users
 */
const GuestRoute = props => {
  const { user } = useContext(AuthContext);

  return user.role === "guest" ? (
    <Route {...props}>
      {props.component ? <props.component /> : props.children}
    </Route>
  ) : null;
};

export default GuestRoute;
