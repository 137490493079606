import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 64px;

  &:hover {
    cursor: pointer;
  }

  .waveform {
    position: absolute;
    opacity: 0.7;
  }

  #background-waveform {
    opacity: 0.4;
  }

  #background-waveform polygon:first-of-type {
    display: none;
  }
  .waveform polygon {
    transition: all 0.15s ease-in-out;
  }

  .waveform polygon:not(:first-of-type):hover {
    opacity: 1;
    filter: drop-shadow(0px 0px 6px #5d95aa);
  }

  .waveform polygon:not(:first-of-type):active {
    filter: brightness(1.25);
  }

  #background-waveform {
    filter: grayscale(1);
  }

  .skeleton-box {
    position: absolute;
    top: 27px;
    right: 0;
    left: 0;
    overflow: hidden;
    height: 12px;
    border-radius: ${({ theme }) => theme.borderRadiusContainers};
    opacity: 0.5;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(100, 100, 100, 0) 0,
        rgba(100, 100, 100, 0.2) 20%,
        rgba(100, 100, 100, 0.5) 60%,
        rgba(100, 100, 100, 0)
      );
      animation: shimmer 2s infinite;
      content: "";
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
