import { bpm_to_spb } from "./quantization";

export function format_seconds_to_minute_seconds(seconds: number) {
  const min = Math.floor(seconds / 60);
  const s = Math.floor(seconds % 60);
  return `${min}:${pad_zeros(s, 2)}`;
}

export function format_seconds_to_smpte(seconds: number) {
  const min = Math.floor(seconds / 60);
  const s = Math.floor(seconds % 60);

  const frame = Math.floor((seconds % 1) * 30);
  return `${min}:${pad_zeros(s, 2)}:${pad_zeros(frame, 2)}`;
}

export function format_seconds_to_measure_beat(
  seconds: number,
  bpm: number,
  beatsPerMeasure: number
) {
  const spb = bpm_to_spb(bpm);
  const numBeats = seconds / spb;

  const measures = Math.floor(numBeats / beatsPerMeasure);
  const beat = Math.floor(mod(numBeats, beatsPerMeasure));
  return `${measures + 1}:${beat + 1}`;
}

function mod(n, m) {
  return ((n % m) + m) % m;
}

function pad_zeros(num, size) {
  let s = num + "";
  while (s.length < size) {
    s = "0" + s;
  }
  return s;
}

export function formatDecimalAsPercent(num: number) {
  return num * 100 + "%";
}

export const formatDecimalAsPercentWithoutSign = (
  num: number,
  percentage: number
) => {
  return num * percentage;
};
