import gql from "graphql-tag";

//TODO: Uncomment favorite(user) later
export const fragments = {
  trackQuery: gql`
    fragment TrackData on Track {
      id
      title
      favorite
      variationTitle
      fileMetaData
      featuredTags
      name
      album {
        id
        name
      }
      thumbnail
      waveform
      status
      trackOrder
      duration
      stem {
        name
        mp3File
        wavFile
      }
      key
      bpm
      signature
      initialBeat
      ensemble
      mood
      gravity
      energy
      melody
      tension
      rhythm
      density
      isrc
      publisherId
      publisher
      publishDate
      proAffiliation
      price
      systemTags
      tags
      instruments
      thematicCategories
      variation
      updated
      editorOpenable
      editorDemo
      freeStemFiles
      type
      blockFile {
        name
        mp3File
        wavFile
      }
      description
      parentTrackId
      mp3File
      wavFile
      fileMetaData
      variationTracks {
        id
      }
      gain
      created_at
      updated_at
    }
  `,
  userQuery: gql`
    fragment UserData on User {
      id
      fullName
      email
      last4
      stripeId
      migratedUser
      profileColor
      profileIcon
      licenseDetail
      mediaType
      jobTitle
      company
      AdvancedSearch
      projects {
        id
        name
        url
        description
        downloadAccess
        purchasedTracks
        subscription
        team {
          id
          name
          enterprise
        }
      }
      billingNotification
      sessionNotification
      userNotification
      newsletterNotification
      unsubscribedAll
      fullDownloadAccess
      customLogo
      completedRegistration
      adminUser
      paidUser
      editorAlpha
      team {
        id
        color
        name
        image
        teamMembers {
          email
          id
        }
        enterprise
      }
      socialMedia {
        Twitter
        Spotify
        Facebook
        Youtube
        Vimeo
        Instagram
      }
    }
  `,
  projectQuery: gql`
    fragment ProjectData on Project {
      id
      name
      url
      description
      image
      downloadAccess
      purchasedTracks
      socialMedia {
        Twitter
        Facebook
        Instagram
        Youtube
        Instagram
        Spotify
        RssFeed
        otherSocialMedia
      }
      creator
      team {
        id
        # icon
        color
        name
        image
        teamMembers {
          email
          id
          teamAdmin
        }
      }
      order
      subscription
      generalSubscription
      projectMember {
        id
        user {
          id
          fullName
          profileIcon
          profileColor
          email
        }
      }
      playlists {
        name
        id
        description
        updated_at
        playlistTracks {
          id
        }
        lastEditor {
          id
          profileIcon
          profileColor
          fullName
        }
      }
      customMixes {
        id
        projectId
        title
        latestVersionId
        track {
          id
          title
          variationTitle
          name
          album {
            id
            name
          }
          key
          bpm
          signature
          initialBeat
          ensemble
          mood
          gravity
          energy
          melody
          tension
          rhythm
          density
        }
        customMixVersions {
          id
          versionNumber
          duration
          editorDataDumpHash
        }
        creator {
          id
          profileIcon
          profileColor
          fullName
        }
        lastEditor {
          id
          profileIcon
          profileColor
          fullName
        }
        updated_at
      }
    }
  `,
  orderQuery: gql`
    fragment OrdersData on Order {
      id
      licenseType
      level
      discountCode
      license
      price
      receipt
      tracks {
        id
        title
        variationTitle
        name
        album {
          id
        }
        thumbnail
        waveform
        status
        trackOrder
        duration
        stem {
          name
          mp3File
          wavFile
        }
        key
        bpm
        signature
        initialBeat
        ensemble
        mood
        gravity
        energy
        melody
        tension
        rhythm
        density
        isrc
        publisherId
        publisher
        publishDate
        proAffiliation
        price
        systemTags
        tags
        instruments
        variation
        updated
        freeStemFiles
        type
        blockFile {
          name
          mp3File
          wavFile
        }
        description
        parentTrackId
        mp3File
        fileMetaData
        variationTracks {
          id
        }
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  `
};
