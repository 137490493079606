import React, { useContext, useEffect } from "react";
import { QueueContext } from "../../context/QueueProvider";
import { Drawer } from "antd";
import Player from "./";
import HelmetWrapper from "../HelmetWrapper";
import { EditorContext } from "../../editor/context/EditorContext/EditorProvider";
import { DrawerContainer } from "./styles";
import { useLocation } from "react-router-dom";

const PLAYER_DRAWER_HEIGHT = "110px";

const PlayerContainer = () => {
  const queue = useContext(QueueContext);
  const editor = useContext(EditorContext);

  // see if we should spawn editor drawer (demo) from GET params
  const queryString = useLocation().search;
  useEffect(() => {
    const searchParams = new URLSearchParams(queryString);
    if (searchParams.get("spawnEditor") && searchParams.has("trackId")) {
      const trackId = searchParams.get("trackId");
      const demo = searchParams.get("demo");
      editor.requestTrackIdOpen(trackId, true, demo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    queue.playlist.length > 0 && (
      <HelmetWrapper>
        <DrawerContainer className="drawer-container">
          <Drawer
            height={PLAYER_DRAWER_HEIGHT}
            placement="bottom"
            getContainer={false}
            mask={false}
            className="player-drawer"
            title={null}
            closable={false}
            destroyOnClose={false}
            visible={!editor.drawerState.visible}
            zIndex={998}
          >
            <Player />
          </Drawer>
        </DrawerContainer>
      </HelmetWrapper>
    )
  );
};

export default PlayerContainer;
