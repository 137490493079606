import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 26px;
  transition: all 0.25s ease-in-out;
  border-radius: 6px;

  &&.tight {
    background-color: ${({ theme }) => theme.editor.backgroundLight};
    height: 100%;
    padding: 0 3px;

    && > .zoom-icon {
      justify-content: center;

      svg path {
        fill: ${({ theme }) => theme.editor.textLight};
      }
    }

    .ant-popover-inner-content {
      padding: 0;
    }
  }

  &&.thicc {
    overflow-x: clip;

    && > .zoom-icon {
      margin-left: 3px;
    }

    &&:hover {
      width: 181px;
      overflow-x: visible;

      .zoom-icon > svg path {
        fill: ${({ theme }) => theme.editor.textLight};
      }
    }

    &&.active {
      width: 181px;
      overflow-x: visible;
    }
  }
`;

export const ZoomIcon = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  width: 22px;
  transition: all 0.25s ease-in-out;

  && > svg path {
    fill: ${({ theme }) => theme.editor.textMedium};
  }
`;

export const SliderRailingContainer = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.editor.backgroundLight};
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 0;
  border-radius: 20px;
  overflow: hidden;
`;
