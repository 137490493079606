import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  width: ${({width}) => width}px;
  background-color: ${({theme}) => theme.editor.backgroundHighlight};
  border-radius: ${({theme}) => theme.editor.buttonRadius};

  .progress-bar {
    position: absolute;
    height: 100%;
    width: ${({progress}) => progress}%;
    min-width: 16%;
    background-color: ${({theme}) => theme.editor.highlight};
    border-radius: ${({theme}) => theme.editor.buttonRadius};
  }

  .title {
    position: relative;
    z-index: 10;
    margin: auto;
    font-size: 12px;
    color: ${({theme}) => theme.editor.textLight};
  }
`;
