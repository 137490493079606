import styled from "styled-components";

export const Container = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ theme, tightLayout }) =>
    tightLayout ? theme.editor.tightLaneHeight : theme.editor.laneHeight};

  svg {
    width: 100%;
    height: ${({ theme, tightLayout }) =>
      tightLayout ? theme.editor.tightLaneHeight : theme.editor.laneHeight};
    position: absolute;
  }

  svg > polygon {
    transition: filter 0.3s ease-in-out;
  }
`;
