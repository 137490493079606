import React, { useContext, useRef, useEffect, useState } from "react";
import { QueueContext } from "../../context/QueueProvider";
import { Container } from "./styles";

const Waveform = ({
  visualizationSVG,
  index,
  isMainPlayer,
  playlist,
  id,
  hovered,
  noFormat,
  isVariation,
  track
}) => {
  const [waveformWidth, setWaveformWidth] = useState(0);
  const [lastPlayedTrack, setLastPlayedTrack] = useState();
  const queue = useContext(QueueContext);
  const svgContainer = useRef(null);
  const svgWidth = useRef(null);

  // Handle the Waveform resize on opening the details dropdown
  if (
    svgContainer.current &&
    svgWidth.current !== svgContainer.current.clientWidth
  ) {
    svgWidth.current = svgContainer.current.clientWidth;
    setWaveformWidth(svgWidth.current);
  }

  useEffect(() => {
    if (queue.currentTrack && queue.currentTrack.track) {
      let newCurrentTrack = { ...queue.currentTrack.track };

      setLastPlayedTrack(newCurrentTrack);
    }
  }, [queue.currentTrack]);

  const handleWaveformWidth = () => {
    setWaveformWidth(svgContainer.current.clientWidth);
  };

  useEffect(() => {
    const currentSVG = svgContainer.current;
    setWaveformWidth(currentSVG.clientWidth);
    if (!waveformWidth) {
      window.addEventListener("resize", handleWaveformWidth);
    }
    return () => {
      window.removeEventListener("resize", handleWaveformWidth);
    };
  }, [waveformWidth]);

  const seek = evt => {
    evt.preventDefault();
    let e = evt.currentTarget;
    let dim = e.getBoundingClientRect();
    let x = evt.clientX - dim.left;
    let y = evt.clientY - dim.top;

    if (y <= 2 || y >= 63) {
      // !This comparison is not needed if the dragging functinality is not here
      // setSeeking(false);
    }
    const seekTo = (x / dim.width) * 100;

    queue.setPercentagePlayed(seekTo);
    queue.setAudioLoaded(false);
  };

  return (
    <Container
      isMainPlayer={isMainPlayer}
      ref={svgContainer}
      hovered={hovered}
      isCurrentTrack={
        queue.currentTrack && queue.currentTrack.track
          ? id === queue.currentTrack.track.id
          : false
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width={`${waveformWidth}px`}
        height="64"
        preserveAspectRatio="none"
        className="waveform"
        id="background-waveform"
        onClick={event => {
          if (isMainPlayer === "main-player") {
            queue.setSeeked(true);
            seek(event);
            return;
          }

          queue.setPlaying(true);
          if (
            queue.currentTrack &&
            queue.currentTrack.track &&
            id === queue.currentTrack.track.id
          ) {
            if (!isVariation) {
              queue.setPlaylist(playlist, noFormat);
            } else {
              queue.playVariation(track);
            }
            queue.setSeeked(true);
            seek(event);
          } else {
            if (!isVariation) {
              queue.setPlaylist(playlist, noFormat);
              queue.setCurrent(index);
            } else {
              queue.playVariation(track);
            }
            queue.setPercentagePlayed(0);
          }
        }}
      >
        {/* Background SVG - The grayscale SVG */}
        {visualizationSVG ? (
          // Track or Variation Background SVG
          <image
            href={visualizationSVG}
            width="100%"
            height={isMainPlayer === "main-player" ? "64px" : "64px"}
          />
        ) : (
          // Main Player Background SVG
          <image
            href={
              queue.currentTrack.track
                ? queue.currentTrack.track.waveform
                : lastPlayedTrack.waveform
            }
            width="100%"
            height={isMainPlayer === "main-player" ? "64px" : "64px"}
          />
        )}
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width={`${waveformWidth}px`}
        height="64"
        preserveAspectRatio="none"
        clipPath={`url(#clip${
          isMainPlayer === "main-player" ? "-main-player" : "-track-list"
        })`}
        className="waveform"
        onClick={event => {
          if (isMainPlayer === "main-player") {
            queue.setSeeked(true);
            seek(event);
            return;
          }

          queue.setPlaying(true);
          if (
            queue.currentTrack &&
            queue.currentTrack.track &&
            id === queue.currentTrack.track.id
          ) {
            // Playing the same track (Might be different track index but the same song)
            if (!isVariation) {
              queue.setPlaylist(playlist, noFormat);
            } else {
              queue.playVariation(track);
            }
            queue.setSeeked(true);
            seek(event);
          } else {
            if (!isVariation) {
              queue.setPlaylist(playlist, noFormat);
              queue.setCurrent(index);
            } else {
              queue.playVariation(track);
            }
            queue.setPercentagePlayed(0);
          }
        }}
      >
        {/* Foreground SVG - The colored SVG showing track progress */}
        {/* 
            Only the currently playing track's foreground SVG <image> element is rendered.
            The clipPath ID's effect is not shown as a result.
            HOWEVER; The SVGs and the respective ClipPath SVG are being rendered - just not shown!
          */}
        {visualizationSVG ? (
          // Track or Variation Foreground SVG
          queue.currentTrack &&
          queue.currentTrack.track &&
          id === queue.currentTrack.track.id && (
            <image
              href={visualizationSVG}
              width="100%"
              height={isMainPlayer === "main-player" ? "64px" : "64px"}
            />
          )
        ) : (
          // Main Player Foreground SVG
          <image
            href={
              queue.currentTrack.track
                ? queue.currentTrack.track.waveform
                : lastPlayedTrack.waveform
            }
            width="100%"
            height={isMainPlayer === "main-player" ? "64px" : "64px"}
          />
        )}
      </svg>
      {(isMainPlayer ||
        (queue.currentTrack &&
          queue.currentTrack.track &&
          id === queue.currentTrack.track.id)) && (
        <svg
          className="waveform-container"
          preserveAspectRatio="none"
          width={`${waveformWidth}px`}
          height="64px"
        >
          <defs>
            <clipPath
              id={`clip${
                isMainPlayer === "main-player" ? "-main-player" : "-track-list"
              }`}
            >
              <rect
                className="seekbar"
                x="0"
                y="0"
                width={(queue.percentagePlayed * waveformWidth) / 100}
                height="100%"
              ></rect>
            </clipPath>
          </defs>
        </svg>
      )}
    </Container>
  );
};

export default Waveform;
