import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { TooltipContainer } from "./styles";
import { Tooltip as AntTooltip } from "antd";
import { useMediaQuery } from "react-responsive";
import { rangeData } from "../../utils/searchFilters";

const Tooltip = ({
  text,
  placement,
  children,
  visible,
  mouseEnterDelay = 0.1
}) => {
  const isMobile = useMediaQuery({ maxWidth: 801 });
  const location = useLocation();
  const [className, setClass] = useState("");

  useEffect(() => {
    if (location.pathname.includes("browse"))
      return setClass("range-tooltip-browse");
    if (location.pathname.includes("settings/search"))
      return setClass("range-tooltip-setting");
  }, [location.pathname]);

  return (
    <AntTooltip
      overlayStyle={{ position: "fixed" }}
      placement={placement}
      mouseEnterDelay={mouseEnterDelay}
      overlayClassName={`range-tooltip ${className}`}
      style={{ left: 188 }}
      title={
        isMobile || !visible
          ? ""
          : () => (
              <TooltipContainer>
                <p className="tooltip-title">{text}</p>
                <p className="interval">
                  {rangeData[text.toLowerCase()].low}
                  <img
                    src={require("../../assets/both-sides-arrow.svg")}
                    alt="blue-dot"
                    style={{ marginRight: 8, marginLeft: 8 }}
                  />{" "}
                  {rangeData[text.toLowerCase()].high}
                </p>
                <p className="tooltip-description">
                  {rangeData[text.toLowerCase()].description}
                </p>
              </TooltipContainer>
            )
      }
    >
      {children}
    </AntTooltip>
  );
};

export default Tooltip;
