import * as React from "react";
import { theme } from "../globalStyles";

export const Download = () => (
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.8 14C8.6 14 8.4 13.9 8.3 13.8L3.2 8.6C3 8.4 3 8.1 3.2 7.9L3.9 7.2C4 7 4.4 7 4.6 7.3L8 10.7V0.5C8 0.2 8.2 0 8.5 0H9.5C9.8 0 10 0.2 10 0.5V10.7L13.4 7.3C13.6 7.1 13.9 7.1 14.1 7.3L14.8 8C15 8.2 15 8.5 14.8 8.7L9.7 13.8C9.6 13.9 9.4 14 9.2 14H8.8ZM18 12.5V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V12.5C0 12.2 0.2 12 0.5 12H1.5C1.8 12 2 12.2 2 12.5V16H16V12.5C16 12.2 16.2 12 16.5 12H17.5C17.8 12 18 12.2 18 12.5Z"
      fill={theme.editor.textLight}
    />
  </svg>
);
