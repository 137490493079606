import React, { lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// lazy page loads
const NotFound = lazy(() => import("../../components/404"));

/*
 * Add all routes specific to unauthenticated (public) users navigating the main app here
 */
const RoutesGuest = props => {
  return (
    <Route>
      <Switch>
        <Redirect exact from="/home" to="/browse" />
        <Redirect from="/login" to="/signin" />
        {["/settings", "/orders", "/favorites", "/project"].map((path, i) => {
          return (
            <Redirect
              key={`guest-${i}`}
              from={path}
              to={{
                pathname: "/signin",
                state: {
                  redirect: props.location.pathname
                }
              }}
            />
          );
        })}
        <Route component={NotFound} />
      </Switch>
    </Route>
  );
};

export default RoutesGuest;
