import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback
} from "react";
import { useHistory } from "react-router-dom";
import { Container, EditorButton, StyledReactSVG } from "./styles";
import { AuthContext } from "../../context/AuthProvider";
import { theme } from "../../globalStyles";
import GenericPopover from "../GenericPopover";
import { Button } from "../Button";
import EditorStemsEmblem from "../EditorStemsEmblem";
import { EditorContext } from "../../editor/context/EditorContext/EditorProvider";
import gql from "graphql-tag";
import { useLazyQuery } from "react-apollo";
import useModal from "../../hooks/useModal";

const GET_EDITOR_DEMOS = gql`
  {
    demoTracks {
      id
    }
  }
`;

/*
 * This component is a button to access the multitrack editor. It's on
 * the bottom play bar, action bars, /browse, etc. Internally it checks
 * for the current user's access permissions for the given track, lights
 * up the button if accessible or disables if inaccessible. Includes hover
 * tooltips and popovers.
 */
const EditorPopoverButton = ({
  track,
  size = "21px",
  yOffset = "75px",
  anchor = "bottom",
  accessibleColor = theme.highlight,
  inaccessibleColor = theme.textDark
}) => {
  const auth = useContext(AuthContext);
  const editor = useContext(EditorContext);

  const editorPermissionsPopover = useModal();

  const [isFullMessage, setIsFullMessage] = useState(false);
  const [hasSeenFullMessage, setHasSeenFullMessage] = useState(
    localStorage.getItem("user-seen-full-editor-popover") || false
  );
  const [
    canAccessCurrentTrackEditor,
    setCanAccessCurrentTrackEditor
  ] = useState(false);
  const timerId = useRef(null);

  // get a list of demo tracks we could load up
  const [getDemoTrackIds, resDemoTracks] = useLazyQuery(GET_EDITOR_DEMOS, {
    onCompleted: res => {
      spawnEditorDemo();
    }
  });

  const { push } = useHistory();

  const onShowPopover = isDropdownOpen => {
    if (!isDropdownOpen) {
      setIsFullMessage(false);
      clearTimeout(timerId.current);
    } else {
      const showFullMessage = () => {
        setIsFullMessage(true);
        setHasSeenFullMessage(true);
        localStorage.setItem("user-seen-full-editor-popover", true);
      };
      if (canAccessCurrentTrackEditor && !hasSeenFullMessage) {
        showFullMessage();
      } else if (track.editorOpenable) {
        timerId.current = setTimeout(showFullMessage, 1500);
      }
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editor.drawerState.visible) {
      clearTimeout(timerId.current);
      setIsFullMessage(false);
      editorPermissionsPopover.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.drawerState.visible]);

  useEffect(() => {
    setCanAccessCurrentTrackEditor(auth.canAccessTrackEditor(track).granted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, track.id]);

  const spawnEditorDemo = useCallback(() => {
    if (!resDemoTracks.data) return;
    if (resDemoTracks.data.demoTracks.length > 0) {
      const demoTrackId =
        resDemoTracks.data.demoTracks[
          Math.floor(Math.random() * resDemoTracks.data.demoTracks.length)
        ].id;
      editor.requestTrackIdOpen(demoTrackId, true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resDemoTracks.data]);

  return (
    <Container>
      <GenericPopover
        xOffset="-10px"
        yOffset={yOffset}
        anchor={anchor}
        triggerType="hover"
        forceClickToDismiss={isFullMessage}
        popoverState={editorPermissionsPopover}
        showDropdown={
          canAccessCurrentTrackEditor &&
          !hasSeenFullMessage &&
          !editor.drawerState.visible
        }
        onChange={onShowPopover}
        triggerComponent={
          <EditorButton
            className={`editor-button ${
              !canAccessCurrentTrackEditor ? "disabled" : ""
            }`}
            onClick={() => {
              if (track.editorOpenable && track.stem.length > 0) {
                editor.requestTrackOpen(track, true);
              }
            }}
          >
            <StyledReactSVG
              fill={
                track && canAccessCurrentTrackEditor
                  ? accessibleColor
                  : inaccessibleColor
              }
              size={size}
              src={require("../../assets/editor-open.svg")}
              wrapper="span"
              className="action-icon"
            />
          </EditorButton>
        }
        contents={
          <div
            id="editor-popover-container"
            className={isFullMessage ? "lg" : "sm"}
          >
            <div id="editor-popover-title">
              {isFullMessage ? (
                canAccessCurrentTrackEditor ? (
                  <div>
                    Multitrack Editor
                    <span className="editor-new-text">NEW</span>
                  </div>
                ) : (
                  "Subscribe for access to this track"
                )
              ) : canAccessCurrentTrackEditor ? (
                "Open in Multitrack Editor"
              ) : track.editorOpenable ? (
                "Subscribe for access to the multitrack editor"
              ) : (
                "Track is not available in the multitrack editor"
              )}
            </div>
            <div
              id="editor-popover-content"
              className={isFullMessage ? "visible" : ""}
            >
              {isFullMessage &&
                (canAccessCurrentTrackEditor ? (
                  <div className="stems-emblem">
                    Make quick edits to the track from the stem files and
                    download your high-quality Custom Mix.
                    <EditorStemsEmblem />
                  </div>
                ) : (
                  <div>
                    The Multitrack Editor for this track is only available to
                    subscribers and license holders.
                    <div className="free-tier-ctas">
                      <Button
                        className="btn-editor-demo"
                        onClick={() => {
                          if (!resDemoTracks.called) getDemoTrackIds();
                          if (resDemoTracks.data) {
                            spawnEditorDemo();
                          }
                        }}
                      >
                        Try out editor
                      </Button>
                      <Button
                        className="btn-subscribe"
                        onClick={() => {
                          editor.close();
                          push("/subscription");
                        }}
                      >
                        Subscribe
                      </Button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        }
      />
    </Container>
  );
};

export default EditorPopoverButton;
