import React, { useState } from "react";
import { Container, Tag, FilterRange, Inner } from "./styles";
import Tooltip from "../Tooltip/index";
import RangeSliderCompound from "../RangeSliderCompound";

const Characteristics = ({
  rangeValue,
  text,
  description,
  isRange,
  isActive = false,
  onRangeChange = () => {},
  minValue = 1,
  maxValue = 5,
  mobileView = false,
  hidden = false
}) => {
  const [showRange, setShowRange] = useState(false);
  const [time, setClearTimeout] = useState(null);

  let starting = (rangeValue[0] - 1) * 20;
  let ending = (rangeValue[1] - 1) * 20;
  let width = (rangeValue[1] - rangeValue[0] + 1) * 20;
  if (rangeValue[0] === 0 && rangeValue[1] === 0) {
    starting = 0;
    ending = 0;
    width = 0;
  }

  const toggleRange = () => {
    // setShowRange(true);
    const resetter = setTimeout(() => {
      setShowRange(prev => !prev);
    }, 300);

    setClearTimeout(resetter);
  };

  return (
    <Container
      mobileView={mobileView}
      onMouseEnter={isRange && !hidden ? () => toggleRange() : undefined}
      onMouseLeave={
        isRange && !hidden
          ? () => {
              clearTimeout(time);
              setShowRange(false);
            }
          : undefined
      }
    >
      <Tooltip
        placement={"rightTop"}
        visible={!hidden}
        text={text}
        description={description}
        mouseEnterDelay={0.25}
      >
        <Inner
          isRange={isRange}
          isActive={isActive}
          starting={`${starting}%`}
          ending={`${ending}%`}
          width={`${width}%`}
        >
          {!showRange && isActive && <div className="range"></div>}
          <Tag showRange={showRange} isActive={isActive}>
            <p className="text">{text}</p>
          </Tag>

          <FilterRange showRange={showRange}>
            {showRange && (
              <RangeSliderCompound
                onChange={onRangeChange}
                min={minValue}
                max={maxValue}
                value={rangeValue}
              />
            )}
          </FilterRange>
        </Inner>
      </Tooltip>
    </Container>
  );
};

export default Characteristics;
