import { AudioMaster } from "../../AudioMaster";
import { EncapsulatedAudioNode } from "../EncapsulatedAudioNode";

interface WahWahFXParams {
  frequency: number;
  depth: number;
  Q: number;
}

/*
 * A custom audio effect that implements a WahWah effect.
 *
 * Reference implementation found here:
 * https://github.com/cwilso/Audio-Input-Effects/blob/main/js/effects.js#L778
 */
export class WahWah extends EncapsulatedAudioNode {
  private awFollower: BiquadFilterNode;
  private awDepth: GainNode;
  private awFilter: BiquadFilterNode;

  /*
   * Construct the WahWah effect
   *
   * Parameters:
   *  audioMaster: contains audio context
   *  fxParams: json serialization of effect parameters and controls
   */
  constructor(audioMaster: AudioMaster, fxParams: WahWahFXParams) {
    super(audioMaster.context);

    const inputGain = audioMaster.context.createGain();
    const waveshaper = audioMaster.context.createWaveShaper();
    this.awFollower = audioMaster.context.createBiquadFilter();
    this.awFollower.type = "lowpass";
    this.awFollower.frequency.value = 10.0;

    var curve = new Float32Array(65536);
    for (var i = -32768; i < 32768; i++)
      curve[i + 32768] = (i > 0 ? i : -i) / 32768;
    waveshaper.curve = curve;
    waveshaper.connect(this.awFollower);

    this.awDepth = audioMaster.context.createGain();
    this.awDepth.gain.value = 11585;
    this.awFollower.connect(this.awDepth);

    this.awFilter = audioMaster.context.createBiquadFilter();
    this.awFilter.type = "lowpass";
    this.awFilter.Q.value = 15;
    this.awFilter.frequency.value = 50;
    this.awDepth.connect(this.awFilter.frequency);
    this.awFilter.connect(this.outputNode);

    this._inputConnect(inputGain);
    inputGain.connect(waveshaper);
    inputGain.connect(this.awFilter);
  }

  public get frequency() {
    return this.awFollower.frequency.value;
  }

  public set frequency(value: number) {
    this.awFollower.frequency.setTargetAtTime(
      value,
      this.awFollower.context.currentTime,
      0.01
    );
  }

  public get depth() {
    return this.awDepth.gain.value;
  }

  public set depth(value: number) {
    this.awDepth.gain.setTargetAtTime(
      Math.pow(2, 10 + value),
      this.awDepth.context.currentTime,
      0.01
    );
  }

  public get Q() {
    return this.awFilter.Q.value;
  }

  public set Q(value: number) {
    this.awFilter.Q.setTargetAtTime(
      value,
      this.awFilter.context.currentTime,
      0.01
    );
  }
}
