import * as React from "react";
import { theme } from "../globalStyles";

export const PlayPrevious = () => (
  <svg
    width="23"
    height="15"
    viewBox="0 0 23 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.98112 14.4076L0.373727 8.36424C0.141016 8.20157 0.00170457 7.93629 0 7.65257V6.92924C0.00170457 6.64552 0.141016 6.38023 0.373727 6.21757L8.98112 0.139235C9.26484 -0.0464118 9.63172 -0.0464118 9.91544 0.139235L10.0556 0.209235C10.3211 0.370463 10.4845 0.657191 10.4877 0.967569V13.5792C10.4868 13.8902 10.3228 14.178 10.0556 14.3376L9.91544 14.4076C9.63172 14.5932 9.26484 14.5932 8.98112 14.4076ZM11.679 7.65257C11.6807 7.93629 11.82 8.20158 12.0527 8.36424L20.6601 14.4542C20.9438 14.6399 21.3107 14.6399 21.5944 14.4542L21.7345 14.3842C22.0017 14.2247 22.1657 13.9369 22.1667 13.6259V1.01424C22.1634 0.703864 22.0001 0.417135 21.7345 0.255908L21.5944 0.185908C21.3107 0.000261036 20.9438 0.000261036 20.6601 0.185908L12.0527 6.21757C11.82 6.38024 11.6807 6.64552 11.679 6.92924V7.65257Z"
      fill={theme.editor.iconMedium}
    />
  </svg>
);
