import { dbToLinear } from "../util/extra_math";
import {
  bpm_to_seconds_per_measure,
  quantize,
  quantizeCeil,
  quantizeFloor
} from "../util/quantization";
import { REGION_PREROLL_TIME_S } from "./RegionNode";

export interface SubRegion {
  visualStartTime: number;
  visualEndTime: number;
  audioStartTime: number;
}

// tag onsets where amplitude > ABS_ZERO_THRESHOLD_ONSET
const ABS_ZERO_THRESHOLD_ONSET = 0.01;
// tag offsets where amplitude < ABS_ZERO_THRESHOLD_OFFSET
// note: be more lax with offset timings, let the energy wind down a bit more
const ABS_ZERO_THRESHOLD_OFFSET = 0.0025;
// amount of silence that must occur for region offset to be considered
const SILENCE_HOLD_TIME_S = 10;

/*
 * Takes a linear walk through the signal amplitude and returns timestamps of
 * events where signal exceeds ABS_ZERO_THRESHOLD_ONSET and then where signal dips
 * below ABS_ZERO_THRESHOLD_OFFSET. Offset timestamp has to be followed by
 * SILENCE_HOLD_TIME_S of silence to be output.
 *
 * Note: [GB] this is a pretty primitive algorithm for activity/silence detection.
 * A better algorithm would be energy flux when looking at onsets or an algorithm
 * that considers some context like RMS with some smoothing.
 */
function findOnsetAndOffsetTimestamps(
  arr: Float32Array,
  samplingRate: number,
  gainCompensation: number
) {
  const zeroThresholdOnset =
    ABS_ZERO_THRESHOLD_ONSET * dbToLinear(gainCompensation);
  const zeroThresholdOffset =
    ABS_ZERO_THRESHOLD_OFFSET * dbToLinear(gainCompensation);

  const regionSeparators = [];

  // only consider region end if silent for enough time
  const HOLDTIME = samplingRate * SILENCE_HOLD_TIME_S;
  let ZERO_CROSSINGS = 0;

  let stateInRegion = false;
  for (let i = 0; i < arr.length; i++) {
    if (!stateInRegion) {
      if (arr[i] > zeroThresholdOnset) {
        regionSeparators.push(i);
        stateInRegion = true;
      }
    } else {
      if (arr[i] < zeroThresholdOffset) {
        ZERO_CROSSINGS += 1;
      } else {
        ZERO_CROSSINGS = 0;
      }

      if (ZERO_CROSSINGS > HOLDTIME) {
        // we've been silent for long enough
        regionSeparators.push(i - ZERO_CROSSINGS);
        stateInRegion = false;
        ZERO_CROSSINGS = 0;
      }
    }
  }

  if (stateInRegion) {
    // leftovers
    regionSeparators.push(arr.length - 1);
  }

  const regionSeparatorTimestamps = regionSeparators.map(
    iSample => iSample / samplingRate
  );

  return regionSeparatorTimestamps;
}

export function RegionSplitter(
  audioBuffer: AudioBuffer,
  bpm: number,
  beatsPerMeasure: number,
  initialBeat: number,
  gainCompensation: number = 0
) {
  const secondsPerMeasure = bpm_to_seconds_per_measure(bpm, beatsPerMeasure);
  const secondsPerBeat = secondsPerMeasure / beatsPerMeasure;

  // projects timestamp (ts) from stem timeline to editor timeline
  // considering initialBeat offsets
  // don't perform any projection for freetime tracks (don't offset anything)
  const projectToEditorTimeline = (ts: number) => {
    return bpm in [0, 1]
      ? ts
      : ts + secondsPerBeat * (beatsPerMeasure - initialBeat);
  };

  // projects timestamp (ts) from editor timeline to stem timeline
  // considering initialBeat offsets
  // don't perform any projection for freetime tracks (don't offset anything)
  const projectToStemTimeline = (ts: number) => {
    return bpm in [0, 1]
      ? ts
      : ts - secondsPerBeat * (beatsPerMeasure - initialBeat);
  };

  // transform omnichannel to mono amplitude (all samples in [0,1])
  const summedChannels = new Float32Array(audioBuffer.length);
  for (let c = 0; c < audioBuffer.numberOfChannels; c++) {
    const buffer = audioBuffer.getChannelData(c);
    for (let i = 0; i < buffer.length; i++) {
      summedChannels[i] += Math.abs(buffer[i]);
    }
  }
  const timestamps = findOnsetAndOffsetTimestamps(
    summedChannels,
    audioBuffer.sampleRate,
    gainCompensation
  );

  const regions: SubRegion[] = [];
  for (let i = 0; i < timestamps.length; i += 2) {
    // these are timestamps of detected audio activity onsets/offsets within the stem file
    // stem files have some amount of rendered silence before the first beat of audio (often 1 beat)
    // so E.g., if there was a pickup measure containing 2 beats before the first full measure,
    // initialBeat = 2 + preroll silence beats (often 1) = 3
    const audioActivityStemTimeline = {
      onsetTime: timestamps[i],
      offsetTime: timestamps[i + 1]
    };

    // these are timestamps of audio activity onsets/offsets projected into the editor timeline
    // these timestamps include a pickup measure (anacrusis) if initialBeat > 0
    const audioActivityEditorTimeline = {
      onsetTime: projectToEditorTimeline(audioActivityStemTimeline.onsetTime),
      offsetTime: projectToEditorTimeline(audioActivityStemTimeline.offsetTime)
    };

    // quantize region boundaries to barlines
    const regionBarOverhangSeconds =
      audioActivityEditorTimeline.offsetTime -
      quantizeFloor(audioActivityEditorTimeline.offsetTime, secondsPerMeasure);
    const regionEndQuantizer =
      regionBarOverhangSeconds > REGION_PREROLL_TIME_S
        ? quantizeCeil
        : quantizeFloor;
    const regionEditorTimeline = {
      startTime: audioActivityEditorTimeline.onsetTime,
      endTime: regionEndQuantizer(
        audioActivityEditorTimeline.offsetTime,
        secondsPerMeasure
      )
    };
    if (
      secondsPerMeasure -
        (audioActivityEditorTimeline.onsetTime % secondsPerMeasure) <=
      REGION_PREROLL_TIME_S
    ) {
      // onset is a little before the downbeat but within acceptable bounds
      // to not warrant extending back a full measure
      regionEditorTimeline.startTime = quantize(
        regionEditorTimeline.startTime,
        secondsPerMeasure
      );
    } else {
      // there's enough audio information before the downbeat to warrant extending region start back a measure
      regionEditorTimeline.startTime = quantizeFloor(
        audioActivityEditorTimeline.onsetTime,
        secondsPerMeasure
      );
    }

    // transform quantized region boundaries back to original stem timeline
    const regionStemTimeline = {
      startTime: projectToStemTimeline(regionEditorTimeline.startTime),
      endTime: projectToStemTimeline(regionEditorTimeline.endTime)
    };

    // form the region with the quantized visual and auditory boundaries
    const region: SubRegion = {
      audioStartTime: regionStemTimeline.startTime,
      visualStartTime: regionEditorTimeline.startTime,
      visualEndTime: regionEditorTimeline.endTime
    };

    regions.push(region);
  }

  return regions;
}
