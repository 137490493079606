import React, { useContext, useState } from "react";
import {
  Container,
  MobileSearchInput,
  Results,
  StyledSearch as Search,
  StyledSvg
} from "./styles";
import AutoComplete from "../AutoComplete";
import { SearchContext } from "../../context";
import album from "../../assets/album.svg";
import instrument from "../../assets/instrument.svg";
import playCircle from "../../assets/play-circle.svg";
import tag from "../../assets/tag.svg";
import aggregate from "../../assets/aggregate.svg";
import { Input } from "antd";
import { useHistory } from "react-router-dom";
import { CustomSpinner } from "../SpinnerSmall/styles";

const Searchbar = ({ mobile, toggleSearchBar }) => {
  const {
    suggestions,
    fetchSearchResult,
    searchValue: value,
    setSearchValue: setValue,
    getSuggestions,
    tagSearchResults,
    instrumentSearchResults,
    setIsSearching,
    aggregateResults,
    setSelectedTrackList,
    isLoadingAutocomplete,
    setLoadingAutocomplete
  } = useContext(SearchContext);
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [textSearching, setTextSearching] = useState(true);
  const { push } = useHistory();

  const capFirstLetter = str => {
    let splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  const handleChange = async e => {
    if (e.target.value.length === 0) {
      setShowSuggestions(false);
    }

    let newValues = capFirstLetter(e.target.value);
    setLoadingAutocomplete(true);
    setTextSearching(true);
    setActiveSuggestion(0);
    setValue(newValues);
    setShowSuggestions(true);

    await getSuggestions(newValues);
  };

  const onEnter = async item => {
    setShowSuggestions(false);

    if (value.length > 0) {
      if (typeof item !== "string") {
        setValue("");
        setIsSearching(false);
        if (item.type === "track") {
          setIsSearching(false);
          redirect(item, "Track");
        } else if (item.type === "session") {
          setIsSearching(false);
          redirect(item, "Session");
        } else {
          setIsSearching(false);
          fetchSearchResult(item.name, item.type, item);
        }
      } else {
        let isTrackOrSession = suggestions.filter(x => {
          return x.title && x.title.toLowerCase() === value.toLowerCase();
        });
        if (isTrackOrSession.length === 1) {
          if (isTrackOrSession[0].type === "track") {
            setIsSearching(false);
            redirect(isTrackOrSession[0], "Track");
          } else if (isTrackOrSession[0].type === "session") {
            setIsSearching(false);
            redirect(isTrackOrSession[0], "Session");
          }
        } else {
          fetchSearchResult(item);
          setLoadingAutocomplete(false);
          setValue("");
        }
      }
    }
  };

  const redirect = (track, type) => {
    if (type === "Track") {
      push(`/browse/track/${track.id}`);
      setSelectedTrackList({ name: track.title, type: "Track" });
    } else if (type === "Session") {
      push(`/album/${track.id}`);
    }
    setValue("");
  };

  const renderItem = (icon, item, type, index) => {
    return (
      <div
        onClick={() => onEnter(item, type)}
        className={`result ${
          index === activeSuggestion ? "focused-result" : ""
        }`}
        key={index}
      >
        <StyledSvg src={icon} wrapper="span" className="result-icon" />
        <p className="name">{item.name}</p>
        <p className="type">{type}</p>
      </div>
    );
  };

  const renderResult = suggestionsAll => {
    let count = 0;

    if (isLoadingAutocomplete && value.length > 0) {
      return (
        <Results className="RESULTS CONTAINER">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 12
            }}
          >
            <CustomSpinner />
          </div>
        </Results>
      );
    }

    return (
      <Results>
        {suggestionsAll.map(item => {
          if (item.type === "track") {
            count++;
            return renderItem(playCircle, item, "Track", count);
          } else if (item.type === "session") {
            count++;
            return renderItem(album, item, "Session", count);
          }
          return null;
        })}
        {tagSearchResults.map(item => {
          count++;
          if (item.type.toLowerCase() === "daterange") {
            return renderItem(tag, item, "Date Range", count);
          }
          return renderItem(tag, item, "Tag", count);
        })}
        {instrumentSearchResults.map(item => {
          count++;
          return renderItem(instrument, item, "Instrument", count);
        })}
        {aggregateResults.map(item => {
          count++;
          return renderItem(aggregate, item, "Quick Filter", count);
        })}
      </Results>
    );
  };

  const shouldShowAutocomplete = suggestionsAll => {
    if (
      (isLoadingAutocomplete && value.length > 0) ||
      (showSuggestions && suggestionsAll.length > 0 && value.length > 0)
    ) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <AutoComplete
        value={value}
        suggestions={[
          ...suggestions,
          ...tagSearchResults,
          ...instrumentSearchResults,
          ...aggregateResults
        ]}
        onEnter={onEnter}
        activeSuggestion={activeSuggestion}
        setActiveSuggestion={setActiveSuggestion}
        showSuggestions={showSuggestions}
        setShowSuggestions={setShowSuggestions}
        textSearching={textSearching}
        setTextSearching={setTextSearching}
      >
        {(onKeyDown, suggestionsAll) => {
          /** Desktop view */
          if (!mobile) {
            return (
              <div className="search">
                <Search>
                  <img
                    className="search-icon"
                    src={require("../../assets/search_grey.svg")}
                    alt="search"
                  />
                  <input
                    className="input"
                    value={value}
                    onChange={handleChange}
                    placeholder="Search by title, keyword, etc."
                    onKeyDown={onKeyDown}
                  />
                </Search>
                {shouldShowAutocomplete(suggestionsAll) && (
                  <div className="autocomplete-container">
                    {renderResult(suggestionsAll)}
                  </div>
                )}
              </div>
            );
          }
          return (
            /** Mobile view */
            <div>
              <MobileSearchInput>
                <img
                  className="search-icon"
                  src={require("../../assets/search.svg")}
                  alt="search"
                />
                <Input
                  placeholder="Search"
                  autoFocus
                  className="focus-visible"
                  value={value}
                  onChange={handleChange}
                />
                <img
                  alt="close-btn"
                  src={require("../../assets/close.svg")}
                  className="close-icon"
                  onClick={() => toggleSearchBar(false)}
                />
              </MobileSearchInput>
              {shouldShowAutocomplete(suggestionsAll) && (
                <div className="autocomplete-container">
                  {renderResult(suggestionsAll)}
                </div>
              )}
            </div>
          );
        }}
      </AutoComplete>
    </Container>
  );
};

export default Searchbar;
