import styled from "styled-components";
import { Menu } from "antd";

export const RegionMenu = styled(Menu)`
  && {
    background-color: ${({ theme }) => theme.editor.backgroundDark};
    border-radius: ${({ theme }) => theme.borderRadiusContainers};
    padding: 0;
  }
`;

export const RegionMenuItem = styled(Menu.Item)`
  && {
    color: ${({ theme }) => theme.editor.textLight};
    font-family: ${({ theme }) => theme.fontMedium};
    text-size: 14px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.4s;
  }

  &&:hover {
    background-color: ${({ theme }) => theme.editor.backgroundHighlight};
    color: ${({ theme }) => theme.editor.textLight};
    transition: none;
  }

  &&:active {
    background-color: ${({ theme }) =>
      theme.editor.backgroundSuperDuperHighlight};
    transition: none;
  }

  &&:first-child:hover {
    border-top-left-radius: ${({ theme }) => theme.borderRadiusContainers};
    border-top-right-radius: ${({ theme }) => theme.borderRadiusContainers};
  }

  &&:last-child:hover {
    border-bottom-left-radius: ${({ theme }) => theme.borderRadiusContainers};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadiusContainers};
  }
`;
