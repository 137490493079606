import * as React from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SEEK, SeekAction } from "../../actions/actionTypes";
import { MainStore } from "../../store";
import { pixels_to_second } from "../../util/time_position_conversion";
import { getZoomProportion } from "../../util/zoom_levels";
import { Container } from "./styles";

export let TimeTicks = () => {
  const ticksRef = useRef(null);
  const zoomLevel = useSelector<MainStore, number>(
    state => state.uiState.zoomLevel
  );
  const dispatch = useDispatch();

  const onClick = e => {
    const boundingClientRect = e.currentTarget.getBoundingClientRect();
    const pixelsRelativeStart = e.clientX - boundingClientRect.x;
    const seconds = pixels_to_second(
      pixelsRelativeStart / getZoomProportion(zoomLevel)
    );
    dispatch<SeekAction>({
      type: SEEK,
      seconds
    });
  };

  return (
    <Container id="time-ticks" ref={ticksRef} onClick={onClick}></Container>
  );
};
