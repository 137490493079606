import { MixingBoardContextType } from "../../context/EditorContext/MixingBoardProvider";
import { InternalFX } from "../../store";
import { piecewiseInterp } from "../../util/interpolation";
import { InternalFXNode } from "./InternalFXNode";

/*
 * This encapsulation of audio nodes implements:
 * 1. A straight passthrough of "dry" signal from input to output
 *      Can control dry level by modifying this.outputNode.gain
 * 2. A connection to a send effect with gain control (amount of send)
 *
 * INPUT ------------> OUTPUT
 *   |
 *   |
 *    ----> GAIN ----> SEND FX (MixingBoard::SendFXBank)
 *
 */
export class PassthroughSendNode extends InternalFXNode {
  private sendGainNode: GainNode;

  /*
   * Parameters:
   *  mixingBoard: MixingBoardContext (has access to AudioMaster)
   *  fxConfig: json serialization of effect params
   */
  constructor(mixingBoard: MixingBoardContextType, fxConfig: InternalFX) {
    super(mixingBoard, fxConfig);

    // passthrough signal
    this._inputConnect(this.outputNode);

    // send signal (start with 0 send)
    this.sendGainNode = new GainNode(mixingBoard.audioMaster.context, {
      gain: 0
    });
    this._inputConnect(this.sendGainNode);
    const effectName = fxConfig.effectId.split(".")[1];
    const sendEffect = mixingBoard.upsertSendEffect(effectName);
    this.sendGainNode.connect(sendEffect);
  }

  /*
   * Sets the amount (gain) to send to the connected send effect
   *
   * Parameters:
   *  val: [0,1] GainNode.value to set on route to send effect
   */
  public setSend(val: number) {
    this.sendGainNode.gain.setTargetAtTime(
      val,
      this._mixingBoard.audioMaster.context.currentTime,
      0.01
    );
  }

  /*
   * When knob controlling insert effect updates, set the value
   *
   * Parameters:
   *  val: [0,1] knob value
   */
  public setValue(val: number) {
    const pMapping = this.fxConfig.mapping.send;
    // figure out where val is in step range
    const interpVal = piecewiseInterp(
      pMapping.stopsX,
      pMapping.stopsY,
      pMapping.stopsInterp,
      val
    );

    this.setSend(val === 0 ? 0 : interpVal);
  }

  // disconnects from global send effect
  public cleanup() {
    this.sendGainNode.disconnect();
    return this;
  }
}
