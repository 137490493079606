import React from "react";
import { Button } from "../Button";
import styled from "styled-components";

const Container = styled.div`
  .empty-playlist {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    align-items: center;

    .empty-message-header {
      color: ${props => props.theme.textLight};
      font-size: ${props => props.theme.h2.fs};
      font-family: ${props => props.theme.h2.ff};
      margin-top: 96px;
    }
    .empty-message-content {
      color: ${props => props.theme.textGrey};
      font-size: ${props => props.theme.p2r.fs};
      font-family: ${props => props.theme.p2r.ff};
      margin-bottom: 36px;
    }
  }
`;

const EmptyMessage = props => {
  return (
    <Container>
      <div className="empty-playlist">
        <div className="empty-message-header">{props.title}</div>
        <div className="empty-message-content">{props.message}</div>
        <Button onClick={props.onClick}>{props.buttonText}</Button>
      </div>
    </Container>
  );
};

export default EmptyMessage;
