import React, { useContext, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider";
import { RoutesUser, RoutesGuest } from "./index";

const BrowseContainer = lazy(() =>
  import("../../pages/Shared/Browse/BrowseContainer")
);
const Landing = lazy(() => import("../../pages/Shared/Landings/1"));
const SingleTrack = lazy(() => import("../../pages/Shared/SingleTrack"));
const Checkout = lazy(() => import("../../pages/Shared/Checkout"));
const PlaylistContainer = lazy(() =>
  import("../../pages/Shared/Playlists/PlaylistsContainer")
);
const AlbumContainer = lazy(() => import("../../pages/Shared/Album/Album"));
const PlaylistContentContainer = lazy(() =>
  import("../../pages/Shared/PlaylistContent/PlaylistContentContainer")
);
const OrderConfirmationContainer = lazy(() =>
  import("../../pages/Shared/OrderConfirmation")
);

/*
 * Add all routes specific to the main app here
 *
 * NB: this also handles different routing depending on user authentication
 * e.g., redirects if not logged in, etc.
 */
const RoutesMain = () => {
  const { user } = useContext(AuthContext);

  return (
    <Switch>
      <Route exact path="/" component={BrowseContainer} />
      <Route exact path="/landing" component={Landing} />
      <Route exact path="/browse" component={BrowseContainer} />
      <Route exact path="/browse/track/:id" component={SingleTrack} />
      <Route exact path="/order" component={Checkout} />
      <Route exact path="/order/:orderId" component={Checkout} />
      <Route exact path="/subscription" component={Checkout} />
      <Route exact path="/subscription/:subscriptionId" component={Checkout} />
      <Route exact path="/playlists" component={PlaylistContainer} />
      <Route exact path="/album/:id" component={AlbumContainer} />
      <Route exact path="/playlist/:id" component={PlaylistContentContainer} />
      <Route
        exact
        path="/order-confirmation/:id"
        component={OrderConfirmationContainer}
      />
      {/* ! private vs public route injection must be last route entry */}
      {user.role === "private" ? <RoutesUser /> : <RoutesGuest />}
    </Switch>
  );
};

export default RoutesMain;
