import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_VOLUME, SetVolumeAction } from "../../actions/actionTypes";
import { MainStore, VolumeIndicators } from "../../store";
import { formatDecimalAsPercentWithoutSign } from "../../util/text_formatting";
import { Container } from "./styles";
import { theme } from "../../../globalStyles";

export interface LaneVolumeSliderProps {
  laneId: string;
}
// const A = -26.666;
// const B = 102.666;
// const C = -62;

const A = -12;
const B = 55;
const C = -30;

// y = ax^2 + bx + c
// 0 = ax^2 + bx + (c-y)
// a = (-b + sqrt(b^2 - 4a(c-y)))/(2a)

export function valToDb(x: number) {
  return A * Math.pow(x, 2) + B * x + C;
}

export function dbToVal(db: number) {
  return (-B + Math.sqrt(B * B - 4 * A * (C - db))) / (2 * A);
}

/*
 *  This component is the slider that appears in each lane to control its volume
 */
export function LaneVolumeSlider(props: LaneVolumeSliderProps) {
  const volume = useSelector<MainStore, number>(
    state => state.project.lanes.byId[props.laneId].laneStatus.volume
  );
  const sliderVal = dbToVal(volume);
  const onAfterChange = val => {
    dispatch<SetVolumeAction>({
      laneId: props.laneId,
      type: SET_VOLUME,
      volumeValue: valToDb(val / 100)
    });
  };
  const onChange = val => {
    dispatch<SetVolumeAction>({
      isTemp: true,
      laneId: props.laneId,
      type: SET_VOLUME,
      volumeValue: valToDb(val / 100)
    });
  };
  const dispatch = useDispatch();

  let volumeIndicators = useSelector<MainStore, VolumeIndicators>(
    state => state.uiState.volumeIndicators[props.laneId]
  );

  volumeIndicators = volumeIndicators || { currentPeak: 0, recentPeak: 0 };

  const HEIGHT = 20;
  const WIDTH = HEIGHT;
  return (
    <div
      style={{
        height: HEIGHT,
        position: "relative",
        width: 80
      }}
    >
      <Container>
        <div
          className="volume-level"
          style={{
            width:
              formatDecimalAsPercentWithoutSign(
                volumeIndicators.currentPeak,
                100
              ) > 1
                ? formatDecimalAsPercentWithoutSign(
                    volumeIndicators.currentPeak,
                    60
                  ) + 22
                : 0,
            maxWidth: sliderVal * 100
          }}
        />
        <div
          className="volume-max"
          style={{
            width:
              formatDecimalAsPercentWithoutSign(
                volumeIndicators.recentPeak,
                100
              ) > 5
                ? formatDecimalAsPercentWithoutSign(
                    volumeIndicators.recentPeak,
                    60
                  ) + 18
                : 0,
            maxWidth: sliderVal * 100
          }}
        />
      </Container>
      <Slider
        min={0}
        max={100}
        defaultValue={sliderVal * 100}
        onAfterChange={onAfterChange}
        onChange={onChange}
        railStyle={{
          height: HEIGHT,
          marginTop: -5, // this is to get rails aligned
          opacity: 0
        }}
        trackStyle={{
          height: HEIGHT,
          marginTop: -5,
          opacity: 0
        }}
        handleStyle={{
          height: HEIGHT,
          width: WIDTH,
          backgroundColor: theme.editor.textMedium
        }}
      />
    </div>
  );
}
