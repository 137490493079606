import * as React from "react";
import { useSelector } from "react-redux";
import { MainStore } from "../store";
import { AudioMaster } from "./AudioMaster";
import { RegionNode } from "./RegionNode";

export interface PlaybackNodeProps {
  audioMaster: AudioMaster;
  outputNode: AudioNode;
  laneId: string;
}

export function PlaybackNode(props: PlaybackNodeProps) {
  const regionIds = useSelector<MainStore, string[]>(
    state => state.project.lanes.byId[props.laneId].regionIds
  );
  return (
    <>
      {regionIds.map(id => (
        <RegionNode
          key={id}
          regionId={id}
          ouputNode={props.outputNode}
          audioMaster={props.audioMaster}
        />
      ))}
    </>
  );
}
