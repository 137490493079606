export function clamp(x, low, upp) {
  return Math.min(upp, Math.max(low, x));
}

export function inRange(x, low, upp) {
  return x >= low && x <= upp;
}

export function dbToLinear(db) {
  return Math.pow(10, db / 20);
}

export function linearToDB(amplitude) {
  return 20 * Math.log10(amplitude);
}
