import {
  InitialTrackData,
  MainStore,
  Instrument,
  InstrumentCategories
} from "./index";
import { genNewId } from "../util/ids";
import { defaultEditorState } from "../reducers/editorState";
import { initialUiState } from "../reducers/uiState";
import { insertFX } from "../audio/FX/fxValues";

const initFXSlotIds = ["Delay_Stereo", "EQ_Low-Pass", "Reverb_Hall"];
export const initFXSlots = initFXSlotIds.map(effectId =>
  insertFX.find(effectData => effectData.id === effectId)
);

export const emptyInitialTrackData: InitialTrackData = {
  albumName: "",
  albumId: null,
  beatsPerMeasure: 1,
  blockFiles: [],
  bpm: null,
  createdAt: undefined,
  density: 0,
  duration: 0,
  editorOpenable: false,
  editorDemo: false,
  energy: 0,
  ensemble: 0,
  freeStemFiles: false,
  gain: 0,
  gravity: 0,
  id: null,
  initialBeat: 0,
  instruments: [],
  key: "",
  melody: 0,
  mood: 0,
  rhythm: 0,
  tags: [],
  systemTags: [],
  thematicCategories: [],
  tension: 0,
  title: "Untitled",
  variationTitle: null,
  lanes: [],
  updatedAt: undefined,
  editorDataDump: null,
  thumbnail: null
};

export let initialState: MainStore = {
  editorState: {
    ...defaultEditorState
  },
  project: {
    customMixInfo: {
      id: null,
      projectId: null,
      trackId: null,
      title: "",
      length: 100,
      numVersions: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
      user: {
        id: null,
        fullName: "",
        profileColor: "",
        profileIcon: ""
      },
      version: {
        id: null,
        versionNumber: 0,
        editorDataDumpHash: null,
        createdAt: null,
        updatedAt: null
      },
      currentDataHash: null
    },
    initialTrackData: {
      ...emptyInitialTrackData
    },
    regions: {
      byId: {}
    },
    lanes: {
      byId: {},
      muteRecall: [],
      muteLaneIds: [],
      soloRecall: [],
      solodLaneIds: [],
      laneIds: []
    }
  },
  uiState: initialUiState,
  waveformBuffers: {
    byAudioPath: {},
    hdLoaded: null
  }
};

export let genericInstrument: Instrument = {
  id: genNewId(),
  name: "instrument",
  category: InstrumentCategories.OTHER
};
