import styled from "styled-components";

export const Button = styled.button`
  background-color: ${props =>
    props.secondary
      ? props.theme.backgroundTag
      : props.subscribe
      ? props.theme.subscribe
      : props.theme.highlight};
  height: ${props => (props.height ? `${props.height}px` : "31px")};
  width: ${props => `${props.width}px` || "auto"};
  padding: ${props => (props.py ? `${props.py}px` : "6px")}
    ${props => (props.px ? `${props.px}px` : "12px")};

  color: ${props => props.theme.textLight};
  font-size: ${props => props.theme.p3b.fs};
  font-family: ${props => props.theme.p3b.ff};
  letter-spacing: 0px;
  line-height: 100%;
  border-radius: ${props => props.theme.borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.styles && props.styles}
  transition: all 0.3s ease-in-out;
  transition-property: transform, box-shadow;

  &&:not(:disabled):hover {
    transform: scale(1.05);
    box-shadow: 2px 2px rgb(0,0,0,0.2);
  }

  &&:disabled {
    background-color: ${props => props.theme.backgroundTag};
    cursor: not-allowed; 
  }
`;
