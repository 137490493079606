import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};
const userPool = new CognitoUserPool(poolData);

const uploadLink = createUploadLink({
  credentials: "same-origin",
  uri: process.env.REACT_APP_BACKEND_GRAPHQL_URL
  // uri: "https://68bb3a88.ngrok.io/graphql",
});

const getAuthToken = async () => {
  const authPromise = new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser === null) {
      // localStorage.clear();
      return resolve("GuestUser");
    }
    cognitoUser.getSession((err, session) => {
      if (err) reject(err);
      if (!session || !session.isValid()) {
        // localStorage.clear();
        return resolve("GuestUser");
      }
      const accessToken = session.getIdToken().jwtToken;
      resolve(accessToken);
    });
  });
  try {
    const res = await authPromise;
    return res;
  } catch (e) {
    console.log(e);
  }
};

const authLink = setContext(async (_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: await getAuthToken()
    }
  };
});

const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        // return notification.error({
        //   message:
        //     "GRAPHQL: Something went wrong. Please refresh the page and try again."
        // });
      });
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // return notification.open({
      //   message:
      //     "NETWORK: Something went wrong. Please refresh the page and try again."
      // });
    }
  }),

  authLink.concat(uploadLink)
]);

const defaultOptions = {
  query: {
    fetchPolicy: "no-cache"
  },
  mutate: {
    errorPolicy: "all"
  }
};

export const client = new ApolloClient({
  defaultOptions,
  link: link,
  cache: new InMemoryCache()
});
