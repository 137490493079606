import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Envelopes,
  Lanes,
  MainStore,
  Regions,
  WaveformBuffers
} from "../../store";
import { Wave } from "../Wave";
import { Container } from "./styles";
import { seconds_to_pixels } from "../../util/time_position_conversion";
import { SEEK, SeekAction } from "../../actions/actionTypes";
import { EditorContext } from "../../context/EditorContext/EditorProvider";
import {
  normalizeAmplitudeBuffer,
  secondsToEnvelopeSample
} from "../../audio/EnvelopeGenerator";
import { getRandomInt } from "../../util/randomize";

// height of the svg waveform viewbox
// this is then scaled to accommodate desired parent height (height prop below)
const VIEWBOX_RENDER_HEIGHT = 80;
const SEED_BOUNDS = [1, 1000000];
const DYNAMIC_WAVE_COLORS = ["#5E95AA", "#5E95AA"];

export let DynamicWaveform = ({ height }) => {
  const dispatch = useDispatch();

  const currentDataHash = useSelector<MainStore, string>(
    state => state.project.customMixInfo.currentDataHash
  );
  const lanes = useSelector<MainStore, Lanes>(state => state.project.lanes);
  const regions = useSelector<MainStore, Regions>(
    state => state.project.regions
  );
  const waveforms = useSelector<MainStore, WaveformBuffers>(
    state => state.waveformBuffers
  );
  const locatorTime = useSelector<MainStore, number>(
    state => state.editorState.locatorTime
  );

  const [globalEnvelope, setGlobalEnvelope] = useState(null);
  const [globalEnvelopesSeed, setGlobalEnvelopesSeed] = useState(null);

  const waveformContainer = useRef(null);

  const songLength = useMemo(() => {
    let maxRegionEndTime = 0;
    Object.values(regions.byId).forEach(region => {
      const regionEndTime = region.visualStartTime + region.visualDuration;
      if (regionEndTime > maxRegionEndTime) maxRegionEndTime = regionEndTime;
    });
    return maxRegionEndTime;
  }, [regions]);

  const editor = useContext(EditorContext);

  const pseudoRandomNumberGenerator = (seed, min, max) => {
    const rnd = ((seed * 9301 + 49297) % 233280) / 233280.0;
    return Math.round(min + rnd * (max - min));
  };

  // clears the global waveform render when editor data changes
  useEffect(() => {
    setGlobalEnvelope(null);
    const generatorSeed = getRandomInt(SEED_BOUNDS[0], SEED_BOUNDS[1]);
    const envelopeSeed = pseudoRandomNumberGenerator(
      generatorSeed,
      SEED_BOUNDS[0],
      SEED_BOUNDS[1]
    );
    setGlobalEnvelopesSeed(envelopeSeed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.initialTrack, editor.customMix]);

  useEffect(() => {
    if (
      Object.keys(waveforms.byAudioPath).length > 0 &&
      currentDataHash !== null
    ) {
      // calculate new global waveform envelope and display
      // get muted regions (from mute/solo status of parent lane) to filter these out
      const audibleLaneIds =
        lanes.solodLaneIds.length > 0
          ? lanes.solodLaneIds
          : lanes.laneIds.filter(laneId => !lanes.muteLaneIds.includes(laneId));

      let regionIdToLaneIdMap = {};
      for (let laneId in lanes.byId) {
        lanes.byId[laneId].regionIds.forEach(regionId => {
          regionIdToLaneIdMap[regionId] = laneId;
        });
      }

      // global envelope buffer and stem envelope buffers must have exact same window size,
      // hop size and sampling rate
      const allEnvelopes = Object.values(waveforms.byAudioPath)
        .filter(w => w.envelopes)
        .map(w => w.envelopes);
      if (allEnvelopes.length === 0) return;
      const globalWindowSize = Math.max(...allEnvelopes.map(e => e.windowSize));
      const globalHopSize = Math.max(...allEnvelopes.map(e => e.hopSize));
      const globalSampleRate = Math.max(...allEnvelopes.map(e => e.sampleRate));

      const desiredDownsampledLength = Math.ceil(
        (songLength * globalSampleRate) / globalHopSize
      );

      // mono envelope for dynamic waveform, always use first channel (stored is L/R balanced mono)
      let globalEnvelopeSamples = [new Float32Array(desiredDownsampledLength)];

      Object.values(regions.byId).forEach(region => {
        // if region is not audible, skip it
        if (!audibleLaneIds.includes(regionIdToLaneIdMap[region.id])) return;

        // if we don't have waveform envelope available for the first channel of region, skip it
        if (
          !(region.audioPath in waveforms.byAudioPath) ||
          !waveforms.byAudioPath[region.audioPath].envelopes ||
          waveforms.byAudioPath[region.audioPath].envelopes
            .channelAmplitudeSamples.length === 0
        )
          return;

        const stemEnvelopes: Envelopes =
          waveforms.byAudioPath[region.audioPath].envelopes;
        const stemSamples = stemEnvelopes.channelAmplitudeSamples[0].length;

        const iRegionStemEnvelopeStart = Math.max(
          0,
          secondsToEnvelopeSample(
            region.audioStartTime,
            stemEnvelopes.sampleRate,
            stemEnvelopes.hopSize
          )
        );
        const iRegionStemEnvelopeEnd = Math.min(
          stemSamples,
          secondsToEnvelopeSample(
            region.audioStartTime + region.visualDuration,
            stemEnvelopes.sampleRate,
            stemEnvelopes.hopSize
          )
        );
        const iRegionDurationSamples =
          iRegionStemEnvelopeEnd - iRegionStemEnvelopeStart;
        const iRegionGlobalEnvelopeStart = Math.max(
          0,
          secondsToEnvelopeSample(
            region.visualStartTime,
            globalSampleRate,
            globalHopSize
          )
        );

        stemEnvelopes.channelAmplitudeSamples.forEach(
          (channelEnvelopeSamples, iChannel) => {
            if (iChannel > globalEnvelopeSamples.length - 1) return;
            for (let iSample = 0; iSample < iRegionDurationSamples; iSample++) {
              const regionEnvelopeSample =
                channelEnvelopeSamples[iRegionStemEnvelopeStart + iSample];
              const iSampleGlobalEnvelope =
                iRegionGlobalEnvelopeStart + iSample;
              if (
                iSampleGlobalEnvelope < globalEnvelopeSamples[iChannel].length
              ) {
                globalEnvelopeSamples[iChannel][
                  iSampleGlobalEnvelope
                ] += regionEnvelopeSample;
              }
            }
          }
        );
      });

      // always display normalized waveform for header seek wave
      const envelope: Envelopes = {
        channelAmplitudeSamples: globalEnvelopeSamples.map(env =>
          Array.from(normalizeAmplitudeBuffer(env))
        ),
        windowSize: globalWindowSize,
        hopSize: globalHopSize,
        sampleRate: globalSampleRate
      };
      setGlobalEnvelope(envelope);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataHash, waveforms.byAudioPath]);

  /*
   * seek from waveform click
   */
  const seek = event => {
    event.preventDefault();
    let e = event.currentTarget;
    let dim = e.getBoundingClientRect();
    let x = event.clientX - dim.left;
    const seekProportion = x / dim.width;
    const seekTimeSeconds = seekProportion * songLength;
    dispatch<SeekAction>({
      type: SEEK,
      seconds: seekTimeSeconds
    });
  };

  const getGlobalEnvelopePixels = () => {
    if (globalEnvelope) {
      const numSamples = globalEnvelope.channelAmplitudeSamples[0].length;
      return Math.ceil(
        seconds_to_pixels(
          (numSamples * globalEnvelope.hopSize) / globalEnvelope.sampleRate
        )
      );
    }
    return 0;
  };

  const waveformComponent = useMemo(() => {
    return (
      <Wave
        startTime={0}
        audioStartTimeDeviation={0}
        zoom={1}
        envelope={globalEnvelope}
        seed={globalEnvelopesSeed}
        maxHeightFromCenter={VIEWBOX_RENDER_HEIGHT / 2}
        aesthetics={{
          colors: DYNAMIC_WAVE_COLORS,
          bottomWave: {
            timeSkipRandSeconds: [1, 2],
            gateThresholdDB: -45,
            percentSignificant: 0.6,
            logSamples: true
          },
          bottomWaveGems: {
            threads: 3,
            waveformSkipSecondsRange: [4, 12],
            gateThresholdDB: -20,
            initialCliffChance: 0.5,
            gemSizeRand: [5, 10],
            vertexSampleSkipSecondsRange: [4, 12],
            gemBothChance: 0.5,
            hueShadeRange: [-0.4, 0.4],
            rotationRange: [-2, 2],
            opacityRange: [0.3, 0.6],
            scaleRange: [0.8, 1.3],
            logSamples: false,
            lightenBlendColor: "#f2f255"
          },
          gems: {
            threads: 2,
            waveformSkipSecondsRange: [6, 12],
            gateThresholdDB: -45,
            initialCliffChance: 0.4,
            gemSizeRand: [4, 7],
            vertexSampleSkipSecondsRange: [3, 6],
            gemBothChance: 0.4,
            hueShadeRange: [-0.5, 0.5],
            rotationRange: [-2, 2],
            opacityRange: [0.6, 0.8],
            scaleRange: [1, 1],
            logSamples: false,
            lightenBlendColor: "#f2f255"
          },
          referenceWave: false
        }}
      />
    );
  }, [globalEnvelope, globalEnvelopesSeed]);

  return (
    <Container ref={waveformContainer}>
      {globalEnvelope !== null && !editor.isRehydrating ? (
        <>
          <svg
            viewBox={`0 0 ${getGlobalEnvelopePixels()} ${VIEWBOX_RENDER_HEIGHT}`}
            width="100%"
            height={height}
            preserveAspectRatio="none"
            className="waveform"
            id="background-waveform"
            onClick={event => seek(event)}
          >
            {waveformComponent}
          </svg>
          <svg
            viewBox={`0 0 ${getGlobalEnvelopePixels()} ${VIEWBOX_RENDER_HEIGHT}`}
            width="100%"
            height={height}
            preserveAspectRatio="none"
            className="waveform"
            id="foreground-waveform"
            clipPath={"url(#clip-editor-waveform)"}
            onClick={event => seek(event)}
          >
            {waveformComponent}
          </svg>
          <svg
            className="editor-waveform-container"
            preserveAspectRatio="none"
            width="100%"
            height={height}
          >
            <defs>
              <clipPath id="clip-editor-waveform">
                <rect
                  className="editor-seekbar"
                  x="0"
                  y="0"
                  width={
                    songLength > 0
                      ? (locatorTime / songLength) *
                        waveformContainer.current.getBoundingClientRect().width
                      : 0
                  }
                  height="100%"
                ></rect>
              </clipPath>
            </defs>
          </svg>
        </>
      ) : (
        <div className="skeleton-box" />
      )}
    </Container>
  );
};
