import styled from "styled-components";

export const headerFadeTransition = `
    transition: all .15s ease;
`;

export const BaseContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.editor.backgroundMedium};
  display: flex;
  flex-direction: column;
  position: relative;

  // show/hide more details on hover, drag
  &:is(.incognito):not(:hover),
  &:is(.dragged-over):not(.dragging) {
    .semi-hide-no-hover {
      color: transparent !important;
    }

    .hide-no-hover {
      visibility: hidden !important;
    }
  }

  .btn-drag {
    cursor: grab;
  }
  .btn-edit, .btn-close {
    cursor: pointer;
  }
  .btn-edit, .btn-close {
    margin: 0 5px;
    width: fit-content;
    height: fit-content;

    svg {
        vertical-align: middle;

        path {
          fill: ${({ theme }) => theme.editor.textMedium};
            ${headerFadeTransition}
        }
    }
  }

  .btn-drag {
    margin: 0 6px 0 2px;
    width: fit-content;
    height: fit-content;

    svg {
        vertical-align: middle;

        path {
          fill: ${({ theme }) => theme.editor.textDark};
            ${headerFadeTransition}
        }
    }
  }

  .btn-drag svg {
    height: 15px;
    margin-left: 4px;
  }

  .btn-edit svg {
    width: 14px;
    height: 14px;
  }

  .btn-close svg {
    width: 12px;
    height: 12px;
  }

   .btn-edit:hover, .btn-close:hover {
    svg path {
      fill: ${({ theme }) => theme.editor.textLight};
    }
  }
  .btn-drag:hover {
    svg path {
      fill: ${({ theme }) => theme.editor.textLight};
    }
  }

  .lane-title-region {
    background-color: ${({ theme }) => theme.editor.backgroundLight};
    padding: 2px;
    display: flex;
    align-items: center;
    position: relative;
    height: 33%;

    .progressBar {
      position: absolute;
      height: 100%;
      width: ${({ progress }) => progress}%;
      background-color: ${({ theme }) => theme.editor.highlightDark}
    }

    .progressLoading {
      color: ${({ theme }) => theme.editor.textMedium};
      padding: 0 15px;
      position: relative;
      z-index: 100;
    }
    .lane-title {
      flex-grow: 1;
      color: ${({ theme }) => theme.editor.textLight};
      font-family: ${({ theme }) => theme.fontHeavy};
      vertical-align: middle;
      padding: ${({ isEditing }) => (isEditing ? "2px" : "2px 6px")};
      ${({ isEditing }) => (isEditing ? "margin-left: -2px" : "")};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:hover {
        position:relative;
        z-index: 200;
        overflow: visible;
        white-space: normal;
        background-color: ${({ theme }) => theme.editor.backgroundLight};
      }
  }
`;

export const Container = styled(BaseContainer)`
  height: ${({ theme }) => theme.editor.laneHeight};
`;

export const TightContainer = styled(BaseContainer)`
  height: ${({ theme }) => theme.editor.tightLaneHeight};
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.editor.backgroundDark};
  border-color: ${({ theme }) => theme.editor.backgroundMedium};
  border-style: solid;
  border-width: 1px 0;

  &&:first-child {
    border-top-width: 2px;
  }

  &&:last-child {
    border-bottom-width: 2px;
  }

  #background-progress-bar {
    position: absolute;
    background-color: ${({ theme }) => theme.editor.backgroundLight};
    opacity: 0.33;
    top: 0;
    left: 0;
    width: ${({ progress }) => progress | 0}%;
    height: 100%;
  }

  .lane-title {
    display: flex;
    flex-grow: 1;
    color: ${({ theme }) => theme.editor.textLight};
    font-family: ${({ theme }) => theme.fontHeavy};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    align-items: center;
    padding: ${({ isEditing }) => (isEditing ? "2px" : "2px 0px")};
    ${({ isEditing }) => (isEditing ? "margin-left: -2px" : "")};

    .title-edit-controls {
      display: flex;
      padding: 0 6px;
    }
    &:hover {
      overflow: visible;
    }
  }

  .lane-title input {
    display: flex;
    flex-grow: 1;
    color: ${({ theme }) => theme.editor.textLight};
    font-family: ${({ theme }) => theme.fontHeavy} !important;
    text-overflow: ellipsis;
    padding: 0 !important;
    box-shadow: none !important;
  }
`;
