import styled from "styled-components";

export const Container = styled.div`
  background-color: ${props => props.theme.backgroundLight};
  color: ${props => props.theme.textLight};
  box-sizing: content-box;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  .close {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .close-button {
    width: 100px;
    height: 100px;
    position: relative;
    top: -40px;
    right: -50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .content {
    display: grid;
    grid-template-columns: 400px 180px;
  }

  div:first-child {
    grid-column: 1/2;
  }
  div:last-child {
    grid-column: 2/3;
    place-self: end;
  }
  .thumbnail {
    justify-content: start;
    display: flex;
    padding-bottom: 24px;
  }
  .playlist-thumbnail {
    width: 70px;
    margin-right: 24px;
  }
  .playlist-name {
    padding-bottom: 24px;
  }
  label {
    display: block;
    padding-bottom: 12px;
    font-family: ${props => props.theme.p3m.ff};
    font-size: ${props => props.theme.p3m.fs};
  }
  .button-container {
    width: 120px;
    display: flex;
    justify-content: flex-end;
  }
  .primary-button {
    margin-right: -12px;
    box-sizing: border-box;
  }
  select {
    appearance: none;
    background: url(${require("../../assets/select-dropdown.svg")}) no-repeat
      95% center;
    width: 250px;
    height: 36px;
    background-color: ${props => props.theme.backgroundTag};
    padding: 7px 12px;
    border: none;
    border-radius: 8px;
    font-family: ${props => props.theme.p3b.ff};
    font-size: ${props => props.theme.p3m.fs};
  }
  option {
    padding: 7px 12px;
  }
  .ant-input {
    background-color: ${props => props.theme.backgroundDark};
    width: 276px;
    height: 36px;
    padding: 7px 12px;
    border: none;
    border-radius: 5px;
    color: ${({ theme }) => theme.textLight};
  }
  textarea[id="playlist-description"] {
    height: 96px;
    background-color: ${props => props.theme.backgroundDark};
    width: 276px;
    padding: 7px 12px;
    border: none;
    border-radius: 5px;
  }
`;
