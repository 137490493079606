import { combineReducers } from "redux";
import {
  ADD_REGION_TO_LANE,
  AddRegionToLaneAction,
  DELETE_REGION,
  DeleteRegionAction,
  DUPLICATE_REGION,
  DuplicateRegionAction,
  RENAME_LANE,
  RenameLaneAction,
  SPLIT_REGION,
  SplitRegionAction
} from "../actions/actionTypes";
import { Lane } from "../store";
import { appendToList, removeFromList } from "../util/util";
import { laneLoadingStatusReducer } from "./laneLoadingStatus";
import { laneStatusReducer } from "./laneStatus";
import { genericInstrument } from "../store/test_initial_store";
import { laneColors } from "../util/lane_color_helpers";

export const laneReducer = combineReducers<Lane>({
  id: (state = "ID") => state,
  originalId: (state = "ORIGINAL_ID") => state,
  name: (state = "UNTITLED", action: RenameLaneAction) => {
    switch (action.type) {
      case RENAME_LANE:
        return action.name;
      default:
        return state;
    }
  },
  instrument: (state = genericInstrument) => state,
  color: (state = laneColors[laneColors.length - 1][0]) => state,
  regionIds: (
    state = [],
    action:
      | DeleteRegionAction
      | SplitRegionAction
      | DuplicateRegionAction
      | AddRegionToLaneAction
  ) => {
    switch (action.type) {
      case DELETE_REGION:
        return removeFromList(state, action.regionId);
      case SPLIT_REGION:
        return appendToList(state, action.newRegionId);
      case DUPLICATE_REGION:
        return appendToList(state, action.newRegionId);
      case ADD_REGION_TO_LANE:
        return appendToList(state, action.regionId);
      default:
        return state;
    }
  },
  laneLoadingStatus: laneLoadingStatusReducer,
  laneStatus: laneStatusReducer
});
