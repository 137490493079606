import styled from "styled-components";

export const Container = styled.div`
  height: ${props => props.height || "80vh"};
  width: 100%;
  display: "flex";
  justify-content: "center";
  align-items: "center";

  .spinner {
    width: 40px;
    height: 40px;

    position: relative;
    margin: ${props => props.height || "100px"} auto;
    -webkit-perspective: 1000;
  }

  .double-bounce1,
  .double-bounce2 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${props => props.theme.highlight};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`;
