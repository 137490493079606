import React, { useContext, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useMediaQuery } from "react-responsive";

import { QueueContext } from "../../../context/QueueProvider";
import { AuthContext } from "../../../context/AuthProvider";

import { Container, MobileContainer } from "./styles";

import nowPlaying from "../../../assets/now-playing.svg";
import playlistReorder from "../../../assets/playlist-reorder.svg";
import playlistRemove from "../../../assets/remove.svg";
import playlistClose from "../../../assets/playlist-close.svg";

const QueuePane = ({
  playlistEl,
  setTracksToSave,
  setPlaylistVisible,
  openModal,
  queuePanePosition
}) => {
  const [playlistItemHovered, setplaylistItemHovered] = useState();
  const queue = useContext(QueueContext);
  const auth = useContext(AuthContext);
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 800 });

  // ! This divides the queue.playlist array into 3 sections as shown by the 3 objects in queueSections array
  // ? History section -> From 0th index until the current track
  // ? Queue section -> From the currently playing track until the end of the user added tracks
  // ? Next Up section -> From the end of the user added tracks until the end of the playlist

  // This is to make dragging and dropping organized, clean, and understandable
  const queueSections = [
    {
      showIf: queue.currentTracker > 0,
      queueHeader: "History",
      droppableId: "history",
      start: 0,
      end: queue.currentTracker
    },
    {
      showIf: true,
      queueHeader: "Queue",
      droppableId: "queue",
      start: queue.currentTracker,
      end: queue.currentTracker + queue.queueTracker + 1
    },
    {
      showIf: queue.playlist[queue.currentTracker + queue.queueTracker + 1],
      queueHeader: "Next Up",
      droppableId: "next-up",
      start: queue.currentTracker + queue.queueTracker + 1,
      end: queue.playlist.length
    }
  ];

  const playFromPlaylist = index => {
    if (index !== queue.currentTracker) {
      const newPlaylist = Array.from(queue.playlist);

      const selectedTrack = newPlaylist.splice(index, 1);

      if (index > queue.currentTracker) {
        // Play track from "queue" or "next up"
        newPlaylist.splice(queue.currentTracker + 1, 0, ...selectedTrack);

        queue.setPlaylist(newPlaylist, true);
        queue.setCurrent(queue.currentTracker + 1);

        if (
          index > queue.currentTracker &&
          index <= queue.currentTracker + queue.queueTracker
        ) {
          // Play track from "queue"
          queue.moveQueueTracker(-1);
        }
      } else if (index < queue.currentTracker) {
        // Play track from history section
        newPlaylist.splice(queue.currentTracker, 0, ...selectedTrack);

        queue.setPlaylist(newPlaylist, true);
      }

      queue.setPercentagePlayed(0);
    }
    queue.setPlaying(true);
  };

  const onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newQueue = Array.from(queue.playlist);

    newQueue.splice(source.index, 1);

    if (source.index === queue.currentTracker) {
      // Select the currently playing track
      newQueue.splice(destination.index, 0, queue.playlist[source.index]);
    } else if (destination.index === queue.currentTracker) {
      // Drop ahead of the currently playing track
      if (
        source.droppableId === "next-up" &&
        destination.droppableId === "queue"
      ) {
        newQueue.splice(destination.index + 1, 0, queue.playlist[source.index]);
      } else {
        newQueue.splice(destination.index, 0, queue.playlist[source.index]);
      }
    } else if (
      source.droppableId === "queue" &&
      destination.droppableId === "next-up"
    ) {
      newQueue.splice(destination.index - 1, 0, queue.playlist[source.index]);
    } else if (
      source.index < destination.index &&
      source.index <= queue.currentTracker &&
      destination.index >= queue.currentTracker
    ) {
      // Select the current track OR a track from history and
      // DRAG it to a position lower than the currently playing track
      newQueue.splice(destination.index - 1, 0, queue.playlist[source.index]);
    } else {
      newQueue.splice(destination.index, 0, queue.playlist[source.index]);
    }

    if (source.index === queue.currentTracker) {
      queue.setCurrent(destination.index);
      if (queue.queueTracker) {
        if (destination.index > queue.currentTracker) {
          const steps =
            queue.queueTracker + (queue.currentTracker - destination.index) < 0
              ? -queue.queueTracker
              : queue.currentTracker - destination.index;

          queue.moveQueueTracker(steps);
        } else if (destination.index < queue.currentTracker) {
          queue.moveQueueTracker(queue.currentTracker - destination.index);
        }
      }
    } else if (source.index < queue.currentTracker) {
      // Selected previous
      if (destination.index < queue.currentTracker) {
        // Move to previous
      } else if (destination.index >= queue.currentTracker) {
        if (
          destination.index <= queue.currentTracker + queue.queueTracker ||
          (destination.droppableId === "queue" &&
            destination.index === queue.currentTracker + queue.queueTracker + 1)
        ) {
          // Move to user queue
          queue.setCurrent(queue.currentTracker - 1);
          queue.moveQueueTracker(1);
        } else if (
          destination.index >
          queue.currentTracker + queue.queueTracker
        ) {
          // move to normal queue
          queue.setCurrent(queue.currentTracker - 1);
          queue.queueTracker && queue.moveQueueTracker(-1);
        }
      }
    } else if (
      source.index > queue.currentTracker &&
      source.index <= queue.currentTracker + queue.queueTracker
    ) {
      // Selected from User queue
      if (destination.index <= queue.currentTracker) {
        queue.setCurrent(queue.currentTracker + 1);
        queue.moveQueueTracker(-1);
      } else if (
        destination.index >
        queue.currentTracker + queue.queueTracker
      ) {
        queue.moveQueueTracker(-1);
      }
    } else if (source.index > queue.currentTracker + queue.queueTracker) {
      // Selected from Normal queue
      if (destination.index <= queue.currentTracker) {
        !(destination.index === queue.currentTracker) &&
          queue.setCurrent(queue.currentTracker + 1);
        destination.index === queue.currentTracker && queue.moveQueueTracker(1);
      } else if (
        (destination.droppableId === "queue" &&
          destination.index ===
            queue.currentTracker + queue.queueTracker + 1) ||
        (destination.index > queue.currentTracker &&
          destination.index <= queue.currentTracker + queue.queueTracker)
      ) {
        queue.moveQueueTracker(1);
      }
    }
    queue.setPlaylist(newQueue, true);
  };

  return isDesktopOrLaptop ? (
    <>
      <Container ref={playlistEl} queuePanePosition={queuePanePosition}>
        {/* <div className="playlist"> */}
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="playlist-close">
            <img
              src={playlistClose}
              alt="Close Playlist"
              onClick={() => setPlaylistVisible(false)}
            />
          </div>
          <div className="playlist-header">
            {auth.isUserPrivate() && (
              <div
                className="save-button"
                onClick={() => {
                  let newTracksToSave = queue.playlist.slice(
                    0,
                    queue.currentTracker + queue.queueTracker + 1
                  );
                  setTracksToSave(newTracksToSave);
                  openModal();
                }}
              >
                Save as Playlist
              </div>
            )}
            <div
              className="clear-button"
              onClick={() => {
                queue.clearPlaylist();
                setPlaylistVisible();
              }}
            >
              Clear All
              <img src={playlistRemove} alt="Empty current playlist" />
            </div>
          </div>

          {queue.playlist && queue.playlist.length ? (
            <div className="playlist-items-container">
              {queueSections.map(
                (section, index) =>
                  section.showIf && (
                    <React.Fragment key={section.start + index}>
                      <div
                        className="queue-header"
                        id={
                          section.queueHeader === "Queue" ? "queue-section" : ""
                        }
                      >
                        {section.queueHeader}
                      </div>
                      <Droppable droppableId={section.droppableId}>
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {queue.playlist
                              .slice(section.start, section.end)
                              .map((queueItem, index) => (
                                <React.Fragment
                                  key={
                                    queueItem.track.id + section.start + index
                                  }
                                >
                                  <Draggable
                                    key={`${queueItem.track.id} + ${section.start} + ${index}`}
                                    draggableId={`${queueItem.track.id}+ ${section.start} + ${index}`}
                                    index={section.start + index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        className={`playlist-item dragging-${
                                          snapshot.isDragging
                                        } ${
                                          section.start + index <
                                          queue.currentTracker
                                            ? "played-track played-track-actions"
                                            : ""
                                        }`}
                                        key={queueItem.track.id}
                                        {...provided.draggableProps}
                                        ref={provided.innerRef}
                                        onClick={() =>
                                          playFromPlaylist(
                                            section.start + index
                                          )
                                        }
                                        onMouseEnter={() =>
                                          setplaylistItemHovered(
                                            section.start + index
                                          )
                                        }
                                        onMouseLeave={() =>
                                          setplaylistItemHovered(null)
                                        }
                                      >
                                        {/* 1 */}
                                        <div
                                          className="reorder-handle"
                                          {...provided.dragHandleProps}
                                        >
                                          {playlistItemHovered ===
                                            section.start + index && (
                                            <img
                                              src={playlistReorder}
                                              alt="Reorder handle"
                                            />
                                          )}
                                        </div>
                                        {/* 2 */}
                                        <div>
                                          {queue.currentTracker ===
                                            section.start + index && (
                                            <img
                                              className="now-playing"
                                              src={nowPlaying}
                                              alt="now playing indicator"
                                            />
                                          )}
                                          {queueItem.track.name}
                                        </div>
                                        {/* 3 */}
                                        <div>
                                          {queueItem.track.albumName ||
                                            queueItem.track.album.name}
                                        </div>
                                        {/* 4 */}
                                        <div>
                                          {`${Math.floor(
                                            queueItem.track.duration / 60
                                          )}:${
                                            queueItem.track.duration % 60 >= 10
                                              ? ""
                                              : "0"
                                          }${Math.floor(
                                            queueItem.track.duration % 60
                                          )}`}
                                        </div>
                                        {/* 5 */}
                                        <div>
                                          {playlistItemHovered ===
                                            section.start + index && (
                                            <div
                                              className="remove-button"
                                              onClick={event => {
                                                event.stopPropagation();
                                                queue.removeFromQueue(
                                                  section.start + index
                                                );
                                              }}
                                            >
                                              <img
                                                src={playlistRemove}
                                                alt="Remove a track"
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                </React.Fragment>
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </React.Fragment>
                  )
              )}
            </div>
          ) : (
            <div className="empty-playlist">No tracks in your queue</div>
          )}
        </DragDropContext>
        {/* </div> */}
      </Container>
      <div
        style={{
          position: "absolute",
          left: `${queuePanePosition}px`,
          bottom: "117px"
        }}
        // className="queue-pane-triangle"
      >
        <img
          style={{ width: "auto", transform: "rotateX(180deg)" }}
          src={require("../../../assets/triangle.svg")}
          alt="Queue Pane"
        />
      </div>
    </>
  ) : (
    <MobileContainer>
      {/* <div className="mobile-playlist"> */}
      <DragDropContext onDragEnd={onDragEnd}>
        {queue.playlist && queue.playlist.length ? (
          <div className="playlist-items-container">
            {queueSections.map(
              (section, index) =>
                section.showIf && (
                  <React.Fragment key={section.start + index}>
                    <div
                      className="queue-header"
                      id={
                        section.queueHeader === "Queue" ? "queue-section" : ""
                      }
                    >
                      {section.queueHeader}
                    </div>
                    <Droppable droppableId={section.droppableId}>
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {queue.playlist
                            .slice(section.start, section.end)
                            .map((queueItem, index) => (
                              <React.Fragment
                                key={queueItem.track.id + section.start + index}
                              >
                                <Draggable
                                  key={`${queueItem.track.id} + ${section.start} + ${index}`}
                                  draggableId={`${queueItem.track.id}+ ${section.start} + ${index}`}
                                  index={section.start + index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={`playlist-item dragging-${
                                        snapshot.isDragging
                                      } ${
                                        section.start + index <
                                        queue.currentTracker
                                          ? "played-track played-track-actions"
                                          : ""
                                      }`}
                                      key={queueItem.track.id}
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      onClick={() =>
                                        playFromPlaylist(section.start + index)
                                      }
                                      onMouseEnter={() =>
                                        setplaylistItemHovered(
                                          section.start + index
                                        )
                                      }
                                      onMouseLeave={() =>
                                        setplaylistItemHovered(null)
                                      }
                                    >
                                      <div>
                                        {queue.currentTracker ===
                                          section.start + index && (
                                          <img
                                            className="now-playing"
                                            src={nowPlaying}
                                            alt="now playing indicator"
                                          />
                                        )}
                                        {queueItem.track.name}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              </React.Fragment>
                            ))}
                        </div>
                      )}
                    </Droppable>
                  </React.Fragment>
                )
            )}
          </div>
        ) : (
          <div className="empty-playlist">No tracks in your queue</div>
        )}
      </DragDropContext>
    </MobileContainer>
  );
};

export default QueuePane;
