import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { theme } from "../../../../globalStyles";
import { EditorContext } from "../../../context/EditorContext/EditorProvider";
import { Envelopes, MainStore } from "../../../store";
import { seconds_to_pixels } from "../../../util/time_position_conversion";
import { getZoomProportion } from "../../../util/zoom_levels";
import { Wave } from "../../Wave";
import { Container } from "./styles";

export interface RegionWaveFormProps {
  regionId: string;
  audioStartTimeDeviation: number; // this needs to be updated real time, don't rely on store
}

export function RegionWaveform(props: RegionWaveFormProps) {
  const audioPath = useSelector<MainStore, string>(
    state => state.project.regions.byId[props.regionId].audioPath
  );
  const envelopes = useSelector<MainStore, Envelopes>(
    state => state.waveformBuffers.byAudioPath[audioPath]?.envelopes
  );
  const audioStartTime = useSelector<MainStore, number>(
    state => state.project.regions.byId[props.regionId].audioStartTime
  );
  const regionDuration = useSelector<MainStore, number>(
    state => state.project.regions.byId[props.regionId].visualDuration
  );
  const zoomLevel = useSelector<MainStore, number>(
    state => state.uiState.zoomLevel
  );
  const zoomProportion = getZoomProportion(zoomLevel);
  const pixelWidth = seconds_to_pixels(regionDuration);
  const colors = useSelector<MainStore, string[]>(
    state => state.project.regions.byId[props.regionId].audioSrcColors
  );
  const seed = useSelector<MainStore, number>(
    state => state.project.regions.byId[props.regionId].audioSrcSeed
  );

  const editor = useContext(EditorContext);

  const maxHeightFromCenter = Math.floor(
    Number(
      editor.tightLayout
        ? theme.editor.tightLaneHeightNoPx
        : theme.editor.laneHeightNoPx
    ) / 2
  );

  /**
   * The initial seed comes from redux. This will generate the same numbers when passes the same info.
   * https://softwareengineering.stackexchange.com/questions/260969/original-source-of-seed-9301-49297-233280-random-algorithm
   */
  const pseudoRandomNumberGenerator = (min, max) => {
    const rnd = ((seed * 9301 + 49297) % 233280) / 233280.0;
    return Math.round(min + rnd * (max - min));
  };

  return (
    <>
      {envelopes && (
        <Container
          className="waveform"
          width={pixelWidth}
          tightLayout={editor.tightLayout}
        >
          <svg>
            <Wave
              startTime={audioStartTime}
              audioStartTimeDeviation={props.audioStartTimeDeviation}
              zoom={zoomProportion}
              envelope={envelopes}
              seed={pseudoRandomNumberGenerator(1, 1000000)}
              maxHeightFromCenter={maxHeightFromCenter}
              aesthetics={{
                colors: colors,
                topWave: {
                  timeSkipRandSeconds: [0.2, 0.4],
                  gateThresholdDB: -16,
                  percentSignificant: 0.4,
                  percentPeakSignificant: 0.65,
                  logSamples: true
                },
                bottomWave: {
                  timeSkipRandSeconds: [1, 2],
                  gateThresholdDB: -45,
                  percentSignificant: 0.4,
                  logSamples: true
                },
                gems: {
                  threads: 2,
                  waveformSkipSecondsRange: [1, 8],
                  gateThresholdDB: -12,
                  initialCliffChance: 0.4,
                  gemSizeRand: [3, 8],
                  vertexSampleSkipSecondsRange: [0.8, 2],
                  gemBothChance: 0.3,
                  hueShadeRange: [-0.3, 0.3],
                  rotationRange: [-3, 3],
                  opacityRange: [0.4, 0.6],
                  scaleRange: [1, 1],
                  logSamples: true,
                  lightenBlendColor: "#f2f255"
                },
                referenceWave: false
              }}
            />
          </svg>
        </Container>
      )}
    </>
  );
}
