import gql from "graphql-tag";

export const GET_NOTIFICATIONS = gql`
  query($id: ID!, $limit: Int!, $offset: Int!) {
    getNotifications(id: $id, limit: $limit, offset: $offset) {
      payload
      id
      status
      created_at
    }
  }
`;

export const READ_NOTIFICATION = gql`
  mutation ReadNotification($id: ID!) {
    readNotification(id: $id) {
      id
    }
  }
`;

export const READ_ALL_NOTIFICATIONS = gql`
  mutation ReadAllNotifications($id: ID!) {
    readAllNotifications(userId: $id)
  }
`;
