import styled from "styled-components";

export const regionToolMarginX = "3px";
export const regionToolMarginY = "1px";

export const Container = styled.div`
  transform-origin: left;
  position: absolute;
  border-radius: ${({ theme }) => theme.editor.containerRadius};
  height: ${({ tightLayout, theme }) =>
    tightLayout ? theme.editor.tightLaneHeight : theme.editor.laneHeight};
  cursor: all-scroll;
  filter: grayscale(0.3);
  transition: all 0.3s ease-in-out 0s;
  transition-property: box-shadow, filter;

  &&.hovering {
    filter: grayscale(0);
  }
  &&.hovering .background-fill > polygon {
    fill: ${({ theme }) => theme.editor.backgroundLight};
  }

  @keyframes verticalPop {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }

  @keyframes glowUp {
    0% {
      transform: scale(0);
      filter: brightness(1);
    }
    75% {
      transform: scale(1.25);
      filter: brightness(1.25);
    }
    100% {
      transform: scale(1);
      filter: brightness(1);
    }
  }

  .waveform polygon:nth-of-type(1),
  .waveform polygon:nth-of-type(2) {
    transform-origin: center;
    transition: transform 0.2s cubic-bezier(0.28, -0.45, 0.23, 1.89);
    animation: 0.7s cubic-bezier(0.28, -0.45, 0.23, 1.89) verticalPop;
  }

  .waveform polygon:nth-child(3n),
  .waveform polygon:nth-child(3n) {
    transform-origin: center;
    transition: transform 0.2s cubic-bezier(0.28, -0.45, 0.23, 1.89);
    animation: 1.3s glowUp;
  }

  .waveform polygon:nth-of-type(2) {
    animation-delay: 0.3s;
  }

  .waveform polygon:first-of-type {
    opacity: 0.4;
  }

  &&.dragging .waveform polygon:first-of-type {
    transform: scaleY(1.125);
    opacity: 0.4;
  }
  &&.dragging .waveform polygon:nth-of-type(2) {
    transform: scaleY(1.25);
    opacity: 0.6;
    transition-delay: 100ms;
  }

  &&.muted {
    filter: grayscale(1) opacity(0.8);
  }

  &&.dragging .background-fill > polygon {
    fill: ${({ theme }) => theme.editor.backgroundHighlight};
  }

  .background-fill {
    position: absolute;
    height: ${({ tightLayout, theme }) =>
      tightLayout ? theme.editor.tightLaneHeight : theme.editor.laneHeight};
    width: inherit;
    border-radius: ${({ theme }) => theme.borderRadiusContainers};
  }
  .background-fill > polygon {
    transition: fill 0.3s ease-in-out;
    fill: ${({ theme }) => theme.editor.backgroundLight};
  }

  &.split-mode:hover {
    .background-fill polygon {
      fill: ${({ theme }) => theme.editor.highlight};
    }
  }

  .region-toolset {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 98%;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    ${({ tightLayout }) => tightLayout && "transform: scale(0.9)"};
    ${({ showHoverState }) => showHoverState && "display: flex;"}

    &.start {
      left: 0;
    }

    &.end {
      right: 0;
    }

    .btn-toolset {
      width: 14px;
      height: 14px;
      border-radius: ${({ theme }) => theme.editor.circleRadius};
      background-color: ${({ theme }) => theme.editor.grey3};
      box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.049033);
      position: relative;

      svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.editor.grey1};
      }
    }
  }

  .region-toolset.hidden {
    display: none;
  }

  .region-split-marker {
    transform-origin: left;
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: ${({ theme }) => theme.editor.danger};
    display: none;
    opacity: 1;
  }

  .region-waveform {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-repeat: no-repeat; /* Do not repeat the image */
  }

  .region-fade {
    z-index: -1;
    position: absolute;
    opacity: 0.13;
    height: 100%;
    margin: 2px auto 0 ${regionToolMarginX};
  }
  .region-fade.start {
    left: 0;
  }
  .region-fade.end {
    right: 0;
    .fade-marker {
      transform-origin: right;
      position: absolute;
      left: -25px;
    }
  }

  .fade-icon {
    cursor: pointer;
  }
  .fade-icon:hover {
    filter: brightness(1.2);
    filter: drop-shadow(1px 1px 8px #5d95aa50);
  }
  .fade-icon.start {
    margin: ${regionToolMarginY} 0 0 ${regionToolMarginX};
  }
  .fade-icon.end {
    margin: ${regionToolMarginY} ${regionToolMarginX} 0 0;
    transform: scaleX(-1);
  }

  .region-menu {
    cursor: pointer;
    transform: scale(1.1);
  }
  .region-menu.active {
    transform: scale(1.1) rotate(180deg);
  }
  .region-menu.start {
    margin: 0 0 ${regionToolMarginY} ${regionToolMarginX};
  }
  .region-menu.end {
    margin: 0 ${regionToolMarginX} ${regionToolMarginY} 0;
  }

  .region-sub-handle {
    cursor: ew-resize;
    padding-top: 3px;
  }
  .region-sub-handle > svg {
    width: 11px;
    height: 25px;
  }

  .region-start {
    margin: auto 0 auto ${regionToolMarginX};
    height: 31px;
  }
  .region-end {
    margin: auto ${regionToolMarginX} auto 0;
    height: 31px;
  }
`;
