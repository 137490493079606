import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { Redirect, Route, useLocation } from "react-router-dom";

const PrivateRoute = props => {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  return (
    <Route {...props}>
      {user.role === "private" ? (
        props.component ? (
          <props.component />
        ) : (
          props.children
        )
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: location } }} />
      )}
    </Route>
  );
};

export default PrivateRoute;
