import * as React from "react";
import { theme } from "../globalStyles";

export const Zoom = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill={theme.editor.textLight}
        shapeRendering="geometricPrecision"
        d="M21.1,18.8l-3.8-3.8c-0.3-0.3-0.7-0.4-1-0.5c1.2-1.4,1.9-3.3,1.9-5.3c0-4.7-3.8-8.4-8.4-8.4S1.2,4.5,1.2,9.2
	s3.8,8.4,8.4,8.4c1.8,0,3.4-0.5,4.7-1.5c0,0.4,0.2,0.8,0.5,1.2l3.8,3.8c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5
	C21.7,20.5,21.7,19.4,21.1,18.8z M9.6,14.8C6.5,14.8,4,12.3,4,9.2c0-3.1,2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6
	C15.3,12.3,12.7,14.8,9.6,14.8z"
      />
    </g>
  </svg>
);
