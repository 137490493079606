import { useEffect } from "react";
import { useSelector } from "react-redux";
import { MainStore, PlaybackState, Waveform } from "../store";
import { clamp, dbToLinear } from "../util/extra_math";
import { logInterp } from "../util/interpolation";
import { AudioMaster } from "./AudioMaster";

export interface RegionNodeProps {
  regionId: string;
  ouputNode: AudioNode;
  audioMaster: AudioMaster;
}

const SILENCE = dbToLinear(-30);
// seconds to begin playing region before region visually starts
// this is to combat truncating onset audio when quantizing to bar
export const REGION_PREROLL_TIME_S = 0.05; // 50ms
// seconds to fade in region audio, combats clicks/pops
const MICRO_FADE_IN_TIME = 0.075;
// seconds to fade out region audio, combats clicks/pops
const MICRO_FADE_OUT_TIME = 0.05;
// TODO: audio playback is offset by this amount (compare bounces to actual).
// investigate why and fix systemic issue
const LATENCY_TIME_S = 0.031;

export function RegionNode(props: RegionNodeProps) {
  const path = useSelector<MainStore, string>(
    state => state.project.regions.byId[props.regionId].audioPath
  );
  const hqAudioPath = useSelector<MainStore, string>(
    state => state.project.regions.byId[props.regionId].audioPathHQ
  );
  const audioStartTime = useSelector<MainStore, number>(
    state =>
      state.project.regions.byId[props.regionId].audioStartTime -
      REGION_PREROLL_TIME_S
  );
  const startTime = useSelector<MainStore, number>(
    state =>
      state.project.regions.byId[props.regionId].visualStartTime -
      REGION_PREROLL_TIME_S -
      LATENCY_TIME_S
  );
  const duration = useSelector<MainStore, number>(
    state =>
      state.project.regions.byId[props.regionId].visualDuration +
      REGION_PREROLL_TIME_S
  );
  const playbackState = useSelector<MainStore, PlaybackState>(
    state => state.editorState.playbackState
  );
  const lastPlaySongTime = useSelector<MainStore, PlaybackState>(
    state => state.editorState.lastPlaySongTime
  );
  const fadeInTime = useSelector<MainStore, PlaybackState>(
    state => state.project.regions.byId[props.regionId].startFadeTime
  );
  const fadeOutTime = useSelector<MainStore, PlaybackState>(
    state => state.project.regions.byId[props.regionId].endFadeTime
  );
  const startFadeTimeOverlap = useSelector<MainStore, boolean>(
    state => state.project.regions.byId[props.regionId].startFadeTimeOverlap
  );
  const endFadeTimeOverlap = useSelector<MainStore, boolean>(
    state => state.project.regions.byId[props.regionId].endFadeTimeOverlap
  );
  const waveform = useSelector<MainStore, Waveform>(
    state => state.waveformBuffers.byAudioPath[path]
  );
  const isAudioLoaded =
    waveform && "audioBuffer" in waveform && waveform.audioBuffer !== null;

  useEffect(() => {
    // prefer hq stems for offline rendering if available
    const buffer =
      props.audioMaster.isOffline && props.audioMaster.hasAudio(hqAudioPath)
        ? props.audioMaster.getAudio(hqAudioPath)
        : props.audioMaster.getAudio(path);

    const state = {
      buffer: null,
      microFade: null
    };

    if (playbackState === PlaybackState.PLAYING && isAudioLoaded) {
      const deltaRegionStartTime = startTime - lastPlaySongTime;

      let regionClipDuration = duration;
      let regionStemStartTime = audioStartTime;

      // adjust duration and start time if region start is before locator position
      const PLAYING_FROM_MIDDLE = deltaRegionStartTime < 0;
      if (PLAYING_FROM_MIDDLE) {
        regionStemStartTime -= deltaRegionStartTime;
        regionClipDuration += deltaRegionStartTime;
      }

      // AudioBufferSourceNode spawning requires timestamps relative to AudioContext time
      // contextualize timestamps and adjust to spawn the audio at the correct timings/offsets
      const contextCurrentTime = props.audioMaster.context.currentTime;
      let contextRegionStartTime =
        props.audioMaster.getLastPlayAbsoluteTime() +
        Math.max(0, deltaRegionStartTime);
      const deltaRegionStartTimeFromContextTime =
        contextRegionStartTime - contextCurrentTime;
      if (deltaRegionStartTimeFromContextTime < 0) {
        // scheduled start time is in the past: adjust
        // we want to schedule clip to start playing instantly but just tweak the seek time into stem
        contextRegionStartTime = contextCurrentTime; // when: scheduling start time
        regionStemStartTime -= deltaRegionStartTimeFromContextTime; // offset
        regionClipDuration += deltaRegionStartTimeFromContextTime; // duration
      }

      if (regionStemStartTime < 0) {
        // this is an edge case where the start of region extends earlier than underlying audio stem
        // we can't offset playing something in the past, adjust the scheduling to incorporate silence preroll
        const secondsLeftToPlayInSilencePreroll =
          deltaRegionStartTime - audioStartTime;
        if (secondsLeftToPlayInSilencePreroll > 0) {
          contextRegionStartTime =
            contextCurrentTime + secondsLeftToPlayInSilencePreroll;
          regionStemStartTime = 0;
          regionClipDuration = duration;
        }
      }

      // don't play clips that end behind locator
      if (regionClipDuration > 0) {
        const bufferSource = props.audioMaster.context.createBufferSource();
        bufferSource.buffer = buffer;
        // notes: if audio start time is negative (i.e., we need some preroll silence before the first audio samples),
        // then we need to play silence for a bit. The equivalence of this is just delaying the audio playback for a
        // bit, that's what's happening below
        bufferSource.start(
          contextRegionStartTime,
          regionStemStartTime,
          regionClipDuration
        );

        // for fades
        const gainNode = props.audioMaster.context.createGain();
        // for prevent clicks and pops
        const microFadeOut = props.audioMaster.context.createGain();
        const microFadeIn = props.audioMaster.context.createGain();
        microFadeIn.gain.value = 0;
        microFadeOut.gain.value = 1;
        bufferSource.connect(gainNode);
        gainNode.connect(microFadeIn);
        microFadeIn.connect(microFadeOut);
        microFadeOut.connect(props.ouputNode);

        // microfade in
        // technically, this only reaches 63.2% gain at MICRO_FADE_IN_TIME
        microFadeIn.gain.setTargetAtTime(
          1,
          contextRegionStartTime,
          MICRO_FADE_IN_TIME
        );

        const clipStartAbsoluteTime =
          startTime -
          lastPlaySongTime +
          props.audioMaster.getLastPlayAbsoluteTime();
        const absoluteFadeInStartTime = clipStartAbsoluteTime;
        const absoluteFadeInEndTime = clipStartAbsoluteTime + fadeInTime;
        const absoluteFadeOutStartTime =
          clipStartAbsoluteTime + duration - fadeOutTime;
        const absoluteFadeOutEndTime = clipStartAbsoluteTime + duration;

        if (fadeInTime > 0 && contextCurrentTime < absoluteFadeInEndTime) {
          const fadeinOverShoot = Math.max(
            contextCurrentTime - absoluteFadeInStartTime,
            0
          );
          const startingFadeValue = clamp(
            logInterp(SILENCE, 1, fadeinOverShoot / fadeInTime),
            0,
            1
          );
          gainNode.gain.setValueAtTime(
            startingFadeValue,
            Math.max(absoluteFadeInStartTime, contextCurrentTime)
          );

          if (startFadeTimeOverlap) {
            gainNode.gain.linearRampToValueAtTime(
              1,
              Math.max(absoluteFadeInEndTime, contextCurrentTime)
            );
          } else {
            gainNode.gain.exponentialRampToValueAtTime(
              1,
              Math.max(absoluteFadeInEndTime, contextCurrentTime)
            );
          }
        }

        if (fadeOutTime > 0 && contextCurrentTime < absoluteFadeOutEndTime) {
          const fadeoutOverShoot = Math.max(
            contextCurrentTime - absoluteFadeOutStartTime,
            0
          );
          const startingFadeValue = clamp(
            logInterp(1, SILENCE, fadeoutOverShoot / fadeOutTime),
            0,
            1
          );
          gainNode.gain.setValueAtTime(
            startingFadeValue,
            Math.max(absoluteFadeOutStartTime, contextCurrentTime)
          );

          if (endFadeTimeOverlap) {
            gainNode.gain.linearRampToValueAtTime(
              SILENCE,
              Math.max(absoluteFadeOutEndTime, contextCurrentTime)
            );
          } else {
            gainNode.gain.exponentialRampToValueAtTime(
              SILENCE,
              Math.max(absoluteFadeOutEndTime, contextCurrentTime)
            );
          }
        }
        state.buffer = bufferSource;
        state.buffer.addEventListener("ended", () => {
          microFadeOut.disconnect();
        });
        state.microFade = microFadeOut;
      }
    }

    return () => {
      if (state.buffer && state.microFade) {
        // give enough time for a good exp decay before stopping buffer
        // 99.3% through fade at 5*timeConstant
        const fadeEndTime =
          props.audioMaster.context.currentTime + 5 * MICRO_FADE_OUT_TIME;
        state.buffer.stop(fadeEndTime);
        state.microFade.gain.setTargetAtTime(
          0,
          props.audioMaster.context.currentTime,
          MICRO_FADE_OUT_TIME
        );
      }
    };
    // eslint-disable-next-line
  }, [
    startTime,
    audioStartTime,
    duration,
    playbackState,
    props.ouputNode,
    props.audioMaster,
    lastPlaySongTime,
    fadeInTime,
    fadeOutTime,
    isAudioLoaded
  ]);
  return null;
}
