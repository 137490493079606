import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.editor.backgroundMedium};
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 0;
  border-radius: 20px;
  overflow: hidden;

  .volume-level,
  .volume-max {
    position: absolute;
    height: 100%;
    background-color: ${({ theme }) => theme.editor.highlight};
    border-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    opacity: 0.5;
    transition: width 0.02s ease;
  }

  .volume-level {
    z-index: 10;
  }

  .volume-max {
    background-color: ${({ theme }) => theme.editor.highlightDark};
    z-index: 5;
  }
`;
