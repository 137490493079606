import React, { useEffect, useState, useContext } from "react";
import useProjects from "../../hooks/project/useProjects";
import useModal from "../../hooks/useModal";
import { ProjectSelect as StyledSelect } from "../Select";
import CreateProjectModal from "../CreateProjectModal";
import { Container } from "./styles";
import { AuthContext, CartContext } from "../../context";
import { Icon } from "antd";

const { Option } = StyledSelect;

const ProjectSelector = ({
  setDownloadAccess,
  track,
  hasDownloadAccess,
  setLoading,
  containingModalState = undefined
}) => {
  const { projectLists, loading, fetchProjects } = useProjects();
  const createProjectModal = useModal();
  const [newlyCreatedProject, setNewProject] = useState(null);

  const auth = useContext(AuthContext);
  const {
    selectedProject,
    setSelectedProject,
    isFoundInCart,
    addCartItem
  } = useContext(CartContext);

  // returns true if project has access to the track
  const hasTrackAccess = project => {
    // if project has blanket license (downloadAccess: true)
    if (project.downloadAccess || project.subscription) return true;

    // if project has no purchased tracks
    if (!project.purchasedTracks) return project.downloadAccess;

    // if project has purchased track
    let purchasedTracks = project.purchasedTracks;
    if (!Array.isArray(purchasedTracks)) {
      //TODO: this is to fix a bug caused by backend (rare case)
      purchasedTracks = [project.purchasedTracks];
    }

    const projectBoughtTrack = purchasedTracks.some(item => {
      return item === track.id;
    });
    return projectBoughtTrack;
  };

  // open new project modal automatically for user if no projects
  useEffect(() => {
    if (loading) return;
    if (projectLists.length === 0) {
      createProjectModal.open();
      setLoading(false);
      return;
    }
    // eslint-disable-next-line
  }, [loading, projectLists.length]);

  // auto select project with access to content for user
  useEffect(() => {
    if (loading || projectLists.length === 0) return;
    if (!newlyCreatedProject) {
      const iProjectWithAccess = projectLists.findIndex(project =>
        hasTrackAccess(project)
      );
      // fall back to first project in list if no project has content access
      setSelectedProject(projectLists[Math.max(iProjectWithAccess, 0)]);
    }
    setLoading(false);
    // eslint-disable-next-line
  }, [loading, newlyCreatedProject, projectLists]);

  // update project download access for this track
  useEffect(() => {
    // if radio is toggled to new project
    if (projectLists.length === 0 || !selectedProject)
      return setDownloadAccess(false);

    setDownloadAccess(hasTrackAccess(selectedProject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectLists, selectedProject, track]);

  return (
    <Container>
      {!loading && selectedProject && selectedProject.image && (
        <img
          src={`${process.env.REACT_APP_FILES_URL}${selectedProject.image}`}
          alt="User Project"
          className="project-image"
        />
      )}

      {!loading && (
        <div className="select-project">
          <p className="select-header">
            {projectLists.length > 0 ? (
              <>
                <span className="bold">Select Project </span>
                <span className="grey">or </span>
              </>
            ) : (
              <>
                <span className="bold">You have no projects. </span>
              </>
            )}
            <span
              className="underlined pointer grey"
              onClick={() => {
                createProjectModal.open();
                setDownloadAccess(false);
              }}
            >
              Create new project
            </span>
          </p>

          {projectLists.length !== 0 && selectedProject && (
            <StyledSelect
              defaultValue={selectedProject.id}
              onSelect={projectId => {
                if (projectLists.length > 0) {
                  const project = projectLists.find(
                    item => item.id === projectId
                  );
                  setSelectedProject(project);
                }
              }}
              value={selectedProject.id}
              className="project-select"
            >
              {projectLists.map(item => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </StyledSelect>
          )}

          {selectedProject && (
            <div className="project-info">
              {hasDownloadAccess && (
                <>
                  <p>Selected project has a license for this download.</p>
                  <p
                    className="btn-text"
                    onClick={() => {
                      if (!isFoundInCart(track)) addCartItem(track);
                      if (containingModalState) containingModalState.close();
                    }}
                  >
                    Add to cart anyway
                  </p>
                </>
              )}
              {!hasDownloadAccess && (
                <p>
                  Selected project does not have a license for this download
                  {auth.user.fullDownloadAccess &&
                    " but your account has full download access enabled"}
                  .
                </p>
              )}
              {projectLists.length === 0 && <p>You have no projects.</p>}
            </div>
          )}
          {projectLists.length === 0 && (
            <div className="project-whatisit">
              <p>
                <Icon type="info-circle" /> A project is exactly what it sounds
                like! It could be a new podcast, a youtube channel, an app or
                game, a crowdfunding or advertising campaign, a film or tv
                series, an art installation, or an exhibition. Think of a
                project as a folder that can be shared with a team and can
                contain playlists, orders, licenses, and custom mixes made
                within the editor.
              </p>
            </div>
          )}
        </div>
      )}

      <CreateProjectModal
        modalState={createProjectModal}
        onComplete={project => {
          fetchProjects();
          setNewProject(project);
          setSelectedProject(project);
        }}
        redirectOnComplete={false}
      />
    </Container>
  );
};

export default ProjectSelector;
