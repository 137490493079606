import * as React from "react";
import { IconButton } from "./IconButton";
import { ExpandCollapse } from "../../../assets/ExpandCollapse";

export function ExpandCollapseButton(props: { onClick: () => void }) {
  return (
    <IconButton onClick={props.onClick}>
      <ExpandCollapse />
    </IconButton>
  );
}
