import * as React from "react";
import { theme } from "../globalStyles";

export const SnapMagnetNone = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill={theme.editor.textLight}
        shapeRendering="geometricPrecision"
        d="M11.8,7.8l3,3.4c0.2,0.3,0.2,0.7,0,0.9c-0.2,0.3-0.6,0.3-0.8,0l-3-3.4l-3,3.4c-0.2,0.3-0.6,0.2-0.8,0
			c-0.2-0.3-0.2-0.7,0-0.9l3-3.4l-3-3.4c-0.2-0.3-0.2-0.7,0-0.9c0.2-0.3,0.6-0.3,0.8,0l3,3.4l3-3.4c0.2-0.3,0.6-0.2,0.8,0
			c0.2,0.3,0.2,0.7,0,0.9L11.8,7.8z"
      />
      <path
        fill={theme.editor.textLight}
        shapeRendering="geometricPrecision"
        d="M14.9,11.1l0.5-0.5l0,0L14.9,11.1z M11.8,7.8l-0.5-0.5l-0.4,0.5l0.4,0.5L11.8,7.8z M14.9,12.1l-0.5-0.5l0,0
			L14.9,12.1z M14,12.1l-0.5,0.5l0,0L14,12.1z M11,8.7l0.5-0.5L11,7.6l-0.5,0.6L11,8.7z M8,12.1l0.5,0.5l0,0L8,12.1z M7.1,12.1
			l-0.5,0.5L7.1,12.1z M7.1,11.1l0.5,0.5l0,0L7.1,11.1z M10.2,7.8l0.5,0.5l0.4-0.5l-0.4-0.5L10.2,7.8z M7.1,4.4l0.5-0.5l0,0L7.1,4.4
			z M7.1,3.5L6.6,3L7.1,3.5z M8,3.5L8.5,3l0,0L8,3.5z M11,6.9l-0.5,0.5L11,7.9l0.5-0.6L11,6.9z M14,3.5L13.5,3L14,3.5z M14.9,3.5
			l-0.5,0.5L14.9,3.5z M14.9,4.4l-0.5-0.5l0,0L14.9,4.4z M15.4,10.7l-3-3.4l-1,0.9l3,3.4L15.4,10.7z M15.4,12.5
			c0.5-0.5,0.5-1.3,0-1.8l-1,0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0L15.4,12.5z M13.5,12.5c0.5,0.6,1.3,0.6,1.8,0
			l-1-0.9c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0L13.5,12.5z M10.5,9.1l3,3.4l1-0.9l-3-3.4L10.5,9.1z M8.5,12.5l3-3.4l-1-0.9l-3,3.4
			L8.5,12.5z M6.6,12.5c0.5,0.6,1.3,0.6,1.8,0l-1-0.9c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0L6.6,12.5z M6.6,10.7
			c-0.5,0.5-0.5,1.3,0,1.8l1-0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0L6.6,10.7z M9.7,7.3l-3,3.4l1,0.9l3-3.4L9.7,7.3z
			 M6.6,4.8l3,3.4l1-0.9l-3-3.4L6.6,4.8z M6.6,3c-0.5,0.5-0.5,1.3,0,1.8l1-0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			L6.6,3z M8.5,3C8,2.4,7.1,2.4,6.6,3l1,0.9c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0L8.5,3z M11.5,6.4L8.5,3l-1,0.9l3,3.4L11.5,6.4z
			 M13.5,3l-3,3.4l1,0.9l3-3.4L13.5,3z M15.4,3c-0.5-0.6-1.3-0.6-1.8,0l1,0.9c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0L15.4,3z M15.4,4.8
			c0.5-0.5,0.5-1.3,0-1.8l-1,0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0L15.4,4.8z M12.3,8.2l3-3.4l-1-0.9l-3,3.4
			L12.3,8.2z"
      />
      <path
        fill={theme.editor.textLight}
        shapeRendering="geometricPrecision"
        d="M1,8c0,4,0,4.4,0.1,5.2c0.3,1.9,1.2,3.8,2.4,5.3c0.9,1,1.9,1.8,3,2.4c3.9,2,8.6,1.1,11.6-2.3
			c1.5-1.7,2.4-3.7,2.7-6C21,12.2,21,11.4,21,8V4h-1.8H17v3.6c0,4,0,4-0.3,4.9c-0.6,2.1-2,3.8-3.9,4.4c-0.8,0.3-1.1,0.3-2,0.3
			c-0.7,0-0.9,0-1.3-0.1c-1.1-0.3-2-0.8-2.7-1.6c-0.9-0.9-1.5-2.2-1.8-3.6C5,11.5,5,11.2,5,7.7L5,4H3.1H1L1,8z"
      />
      <rect
        x="1"
        fill={theme.editor.textLight}
        shapeRendering="geometricPrecision"
        width="4"
        height="3"
      />
      <rect
        x="17"
        fill={theme.editor.textLight}
        shapeRendering="geometricPrecision"
        width="4"
        height="3"
      />
    </g>
  </svg>
);
