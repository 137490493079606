import * as React from "react";
import { theme } from "../globalStyles";

export const ExpandCollapse = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5,2.5l-1-1C7.4,1.4,7.4,1.3,7.4,1.1C7.5,1,7.6,0.9,7.8,0.9l3,0c0.2,0,0.4,0.2,0.4,0.4l0,3
	c0,0.1-0.1,0.3-0.2,0.3c-0.1,0.1-0.3,0-0.4-0.1l-1-1l-6,6l1,1c0.1,0.1,0.1,0.3,0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.2l-3,0
	c-0.2,0-0.4-0.2-0.4-0.4l0-3c0-0.1,0.1-0.3,0.2-0.3c0.1-0.1,0.3,0,0.4,0.1l1,1L8.5,2.5z"
      fill={theme.editor.textLight}
    />
  </svg>
);
