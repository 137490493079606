export function reorder<T>(list: Array<T>, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

export function replace<T>(list: Array<T>, index, value) {
    let cloned = list.slice();
    cloned[index] = value;
    return cloned;
}


export function removeFromList<T>(list: Array<T>, element: T){
    const index = list.indexOf(element);
    console.assert(index !== -1);
    let copy = list.slice();
    copy.splice(index, 1);
    return copy;
}
export function removeFromListIfExists<T>(list: Array<T>, element: T){
    const index = list.indexOf(element);
    if(index !== -1){
        return removeFromList(list, element);
    }else{
        return list;
    }
}

export function appendToList<T>(list: Array<T>, element: T){
    let copy = list.slice();
    copy.push(element);
    return copy;
}

export function appendToListIfNotExist<T>(list: Array<T>, element: T){
    const index = list.indexOf(element);
    if(index === -1){
        return appendToList(list, element);
    }else{
        return list;
    }
}