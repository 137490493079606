import React, { useState, useRef, useEffect } from "react";
import { Container } from "./styles";

const Popover = ({
  triggerComponent,
  dropdown,
  right = 0,
  top = "82px",
  containerStyle,
  showDropdown,
  menuIconStyle,
  onChange = () => {}
}) => {
  const dropdownRef = useRef(null);
  const [isDropdownOpen, toggleDropdown] = useState(showDropdown || false);

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown, false);
    return () =>
      document.removeEventListener("mousedown", closeDropdown, false);
  }, []);

  useEffect(() => {
    toggleDropdown(showDropdown);
  }, [showDropdown]);

  useEffect(() => {
    onChange(isDropdownOpen);
  }, [isDropdownOpen, onChange]);

  const handleDropdown = () => {
    toggleDropdown(isDropdownOpen => !isDropdownOpen);
  };
  const closeDropdown = e => {
    if (!dropdownRef.current.contains(e.target)) toggleDropdown(false);
  };

  const Clickable = React.cloneElement(triggerComponent, {
    onClick: handleDropdown,
    className: "clickable"
    // isOpen: isDropdownOpen
  });

  const DropDown = React.cloneElement(dropdown, {
    toggleDropdown: handleDropdown,
    setVisible: toggleDropdown
    // isOpen: isDropdownOpen
  });

  //TODO:
  /* This component doesn't fully replace antd's tooltip/popover since it doesn't use portal. */
  /* portal is an portal is useful because it appears above scrollbar and rules over all z-index*/

  return (
    <Container
      style={containerStyle}
      right={right}
      ref={dropdownRef}
      top={top}
      iconStyle={Boolean(menuIconStyle)}
    >
      {Clickable}
      {isDropdownOpen && (
        <div className="menu">
          <div className="inner">
            <img
              src={require("../../assets/triangle.svg")}
              className="triangle"
              alt={"menu"}
            />

            {DropDown}
          </div>
        </div>
      )}
    </Container>
  );
};

export default Popover;
