import styled from "styled-components";
import { Menu } from "antd";

const { SubMenu } = Menu;

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding: 0 12px;
  width: ${({ theme }) => theme.editor.laneControlsW};
  gap: 10px;

  .info {
    align-self: flex-start;
    margin-left: 3px;
  }

  .send-fx-icon {
    width: 16px;
    height: 16px;
  }
`;

export const FXSelector = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4px 12px;
  border-radius: 6px;
  color: ${({ theme }) => theme.editor.textLight};

  .fx-slot-name {
    font-family: ${({ theme }) => theme.fontHeavy};
  }

  &&.visible svg.triangle-down {
    transform: rotate(-180deg);
  }

  svg.triangle-down {
    align-self: center;
    width: 12px;
    height: 12px;
    visibility: hidden;
    transition: transform 200ms ease-in-out;
  }

  &:hover {
    background-color: ${({ theme }) => theme.editor.backgroundLight};
    cursor: pointer;

    svg.triangle-down {
      visibility: visible;
    }
  }
`;

export const FXSlotMenu = styled(Menu)`
  && {
    background-color: ${({ theme }) => theme.editor.backgroundMedium};
    border-radius: ${({ theme }) => theme.borderRadiusContainers};
    padding: 0;
    width: 166px;
  }

  &&:hover {
    cursor: pointer;
  }

  &&:first-child:hover {
    border-top-left-radius: ${({ theme }) => theme.borderRadiusContainers};
    border-top-right-radius: ${({ theme }) => theme.borderRadiusContainers};
  }

  &&:last-child:hover {
    border-bottom-left-radius: ${({ theme }) => theme.borderRadiusContainers};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadiusContainers};
  }
`;

export const FXSlotSubMenu = styled(SubMenu)`
  && {
    background-color: ${({ theme }) => theme.editor.backgroundMedium};
    border-radius: ${({ theme }) => theme.borderRadiusContainers};
    padding: 0;

    .ant-dropdown-menu-submenu-title {
      color: ${({ theme }) => theme.editor.textLight};
    }

    .ant-dropdown-menu-submenu-arrow svg {
      color: ${({ theme }) => theme.editor.textLight};
    }
  }

  &&:hover {
    cursor: pointer;

    .ant-dropdown-menu-submenu-title {
      background-color: ${({ theme }) => theme.editor.backgroundHighlight};
    }
  }

  &&:first-child:hover {
    .ant-dropdown-menu-submenu-title {
      border-top-left-radius: ${({ theme }) => theme.borderRadiusContainers};
      border-top-right-radius: ${({ theme }) => theme.borderRadiusContainers};
    }
  }

  &&:last-child:hover {
    .ant-dropdown-menu-submenu-title {
      border-bottom-left-radius: ${({ theme }) => theme.borderRadiusContainers};
      border-bottom-right-radius: ${({ theme }) =>
        theme.borderRadiusContainers};
    }
  }
`;

export const FXSlotMenuItem = styled(Menu.Item)`
  && {
    background-color: ${({ theme }) => theme.editor.backgroundMedium};
    color: ${({ theme }) => theme.editor.textLight};
    cursor: pointer;
    width: 166px;
  }

  .fx-slot-fx-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  &&:hover {
    background-color: ${({ theme }) => theme.editor.backgroundHighlight};
    color: ${({ theme }) => theme.editor.textLight};
  }

  &&:first-child {
    border-top-left-radius: ${({ theme }) => theme.borderRadiusContainers};
    border-top-right-radius: ${({ theme }) => theme.borderRadiusContainers};
  }

  &&:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.borderRadiusContainers};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadiusContainers};
  }
`;
