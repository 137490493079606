import styled from "styled-components";
import { headerFadeTransition } from "../LaneHeader/styles";

export const ControlButton = styled.button`
  width: 22px;
  height: 22px;
  line-height: 22px;
  font-size: 14px;

  color: ${({ theme }) => theme.editor.textLight};
  background-color: ${({ theme }) => theme.editor.backgroundLight};
  border-radius: ${({ theme }) => theme.editor.containerRadius};
  ${headerFadeTransition}

  font-family: ${({ theme }) => theme.fontHeavy};
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.6s ease-in-out;

  &:hover:enabled {
    background-color: ${props => props.theme.editor.backgroundHighlight};
    box-shadow: 0px 0px 20px 2px rgba(93, 149, 170, 0.2);
    transition: all 0.01s ease-in-out;
  }

  &.enabled {
    background-color: ${({ theme }) => theme.editor.highlightDark};
    color: ${({ theme }) => theme.editor.textLight};
  }

  &.enabled:hover {
    background-color: ${({ theme }) => theme.highlight};
    transform: scale(1);
    box-shadow: 0px 0px 4px 2px rgba(93, 149, 170, 0.25);
    transition: all 0.01s ease-in-out;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.editor.backgroundMedium};
  }

  &.fx {
    font-weight: bold;
    font-size: 11px;
  }

  &.zoom-button {
    border-radius: ${({ theme }) => theme.editor.circleRadius};
  }
`;
