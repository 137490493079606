import gql from "graphql-tag";

export const USER_TEAM_QUERY = gql`
  query($userId: ID!) {
    user(id: $userId) {
      id
      team {
        id
        name
        color
      }
    }
  }
`;
