import React, { useEffect, useContext } from "react";
import { Container } from "./styles";
import { ProjectSelect } from "../Select";
import { useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import { MessageContext } from "../../context/MessageContext";
import { AuthContext } from "../../context/AuthProvider";
import EmptyMessage from "../EmptyMessage";
import { withRouter } from "react-router-dom";
import AntModal from "../AntModal";
import { Button } from "../Button";
import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";

const { Option } = ProjectSelect;
const GET_USER_PROJECTS = gql`
  query userProjects($user: ID!) {
    userProjects(user: $user) {
      id
      name
      image
    }
  }
`;

const UPDATE_PLAYLIST = gql`
  mutation updatePlaylist(
    $id: ID!
    $name: String
    $description: String
    $project: ID
  ) {
    updatePlaylist(
      id: $id
      data: { name: $name, description: $description, project: $project }
    ) {
      id
      name
    }
  }
`;

const CREATE_PLAYLIST = gql`
  mutation createPlaylist(
    $name: String
    $creator: ID!
    $project: ID!
    $description: String
    # $thumbnail: String
    $status: PlaylistStatus
  ) {
    createPlaylist(
      data: {
        name: $name
        creator: $creator
        project: $project
        description: $description
        # thumbnail: $thumbnail
        status: $status
      }
    ) {
      id
    }
  }
`;

const CREATE_PLAYLIST_TRACK = gql`
  mutation createPlaylistTrack($playlistId: ID, $trackId: ID) {
    createPlaylistTrack(data: { playlistId: $playlistId, trackId: $trackId }) {
      id
      playlist_id
    }
  }
`;

const ADD_MULTIPLE_TO_PLAYLIST = gql`
  mutation createMultiplePlaylistTracks($playlistId: ID, $trackId: [ID]) {
    createMultiplePlaylistTracks(
      data: { playlistId: $playlistId, trackId: $trackId }
    ) {
      id
    }
  }
`;

const CreateEditPlaylistModal = props => {
  const { showMessage, closeMessage } = useContext(MessageContext);
  const auth = useContext(AuthContext);

  const [thumbnail, setThumbnail] = useState(props.thumbnail);
  const [projectID, setProjectID] = useState(props.project);
  const [playlistName, setPlaylistName] = useState(props.name);
  const [description, setDescription] = useState(props.description);
  const [isPlaylistCreated, setIsPlaylistCreated] = useState(false);
  const [isProjectsLoaded, setIsProjectsLoaded] = useState(false);

  useEffect(() => {
    if (!isPlaylistCreated) return;
    if (props.track && isPlaylistCreated) {
      !cpt_Loading &&
        !cpt_Data &&
        createPlaylistTrack({
          variables: {
            playlistId: cp_Data.createPlaylist.id,
            trackId: props.track.id
          }
        });
    }
    props.modalState.close();

    if (props.tracks && isPlaylistCreated) {
      const trackIDsToSave = props.tracks.map(item => item.track.id);

      !cmp_Loading &&
        !cmp_Data &&
        createMultiplePlaylistTracks({
          variables: {
            playlistId: cp_Data.createPlaylist.id,
            trackId: trackIDsToSave
          }
        });
      props.modalState.close();
    }
    props.fetchProject && props.fetchProject();
    showMessage({
      type: "success",
      duration: 5000,
      title: "Playlist Created",
      description: "You can access this playlist from the projects page.",
      redirectLink: `/playlist/${cp_Data.createPlaylist.id}`
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaylistCreated]);

  useEffect(() => {
    isProjectsLoaded &&
      data.userProjects.length &&
      setProjectID(props.currentProjectId || data.userProjects[0].id);
    let currentProject =
      data && data.userProjects.find(project => project.id === projectID);
    currentProject && setThumbnail(currentProject.image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectsLoaded]);

  useEffect(() => {
    let currentProject =
      data && data.userProjects.find(project => project.id === projectID);
    currentProject && setThumbnail(currentProject.image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectID]);

  const { data } = useQuery(GET_USER_PROJECTS, {
    variables: {
      user: auth && auth.user.id
    },
    onCompleted: () => {
      setIsProjectsLoaded(true);
    }
  });

  const [updatePlaylist, { data: up_Data, loading: up_Loading }] = useMutation(
    UPDATE_PLAYLIST,
    {
      refetchQueries: ["playlist"]
    }
  );
  useEffect(() => {
    if (up_Data) {
      props.modalState.close();
      showMessage({
        type: "success",
        duration: 5000,
        title: "Playlist Updated",
        description: "The playlist settings have been updated."
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [up_Data]);

  const [createPlaylist, { data: cp_Data, loading: cp_Loading }] = useMutation(
    CREATE_PLAYLIST,
    {
      refetchQueries: ["playlist", "projects"]
    }
  );
  useEffect(() => {
    if (cp_Data) {
      setIsPlaylistCreated(true);
      closeMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cp_Data]);

  const [
    createPlaylistTrack,
    { data: cpt_Data, loading: cpt_Loading }
  ] = useMutation(CREATE_PLAYLIST_TRACK, {
    refetchQueries: ["playlist"]
  });

  useEffect(() => {
    if (cpt_Data) {
      showMessage({
        type: "success",
        duration: 5000,
        title: "Track added to playlist",
        description: "You can access this track in the projects screen.",
        redirectLink: `/playlist/${cpt_Data.createPlaylistTrack.playlist_id}`
      });
      if (props.parentModalState) props.parentModalState.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpt_Data]);

  const [
    createMultiplePlaylistTracks,
    { data: cmp_Data, loading: cmp_Loading }
  ] = useMutation(ADD_MULTIPLE_TO_PLAYLIST, {
    refetchQueries: ["playlist"]
  });

  useEffect(() => {
    if (cmp_Data) {
      showMessage({
        type: "success",
        duration: 5000,
        title: "Tracks added to playlist",
        description: "You can access this playlist from the projects page.",
        redirectLink: `/playlist/${cmp_Data.createMultiplePlaylistTracks.id}`
      });
      props.modalState.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmp_Data]);

  return (
    <AntModal modalState={props.modalState}>
      {data && !data.userProjects.length ? (
        <div style={{ width: "645px", height: "350px" }}>
          <EmptyMessage
            title="Create a project first"
            message="You can create a new project by going to Projects"
            buttonText="Projects"
            onClick={() => props.history.push("/project")}
          />
        </div>
      ) : (
        <Container>
          <div className="content">
            <div>
              <div className="thumbnail">
                {thumbnail && (
                  <img
                    className="playlist-thumbnail"
                    // TODO: Get thumbnail from query
                    src={`${process.env.REACT_APP_FILES_URL}${thumbnail}`}
                    alt="Project Thumbnail"
                  />
                )}
                <div className="project-name">
                  <label htmlFor="project">Project</label>
                  {data && (
                    <ProjectSelect
                      onChange={value => setProjectID(value)}
                      defaultValue={
                        props.currentProjectId ||
                        (data &&
                          data.userProjects[0] &&
                          data.userProjects[0].id)
                      }
                    >
                      {data.userProjects.map(project => (
                        <Option
                          style={{ width: 250 }}
                          key={project.id}
                          value={project.id}
                        >
                          {project.name}
                        </Option>
                      ))}
                    </ProjectSelect>
                  )}
                </div>
              </div>
              <div className="playlist-name">
                <label htmlFor="playlist-name">Playlist Name</label>
                <Input
                  type="text"
                  id="playlist-name"
                  autoFocus
                  value={playlistName}
                  onChange={event => setPlaylistName(event.target.value)}
                />
              </div>
              <div>
                <label htmlFor="playlist-description">
                  Playlist Description
                </label>
                <TextArea
                  id="playlist-description"
                  value={description}
                  onChange={event => setDescription(event.target.value)}
                />
              </div>
            </div>
            <div className="button-container">
              <Button
                className="primary-button"
                onClick={() => {
                  if (props.type === "edit") {
                    showMessage({
                      type: "loading",
                      duration: 5000,
                      title: "Updating playlist",
                      description:
                        "Your playlist is being updated, please wait..."
                    });
                    !up_Loading &&
                      !up_Data &&
                      updatePlaylist({
                        variables: {
                          id: props.playlistID,
                          project: projectID,
                          name: playlistName,
                          description: description
                        }
                      });
                  }

                  if (props.type === "create") {
                    showMessage({
                      type: "loading",
                      duration: 5000,
                      title: "Creating playlist",
                      description:
                        "Your playlist is being created, please wait..."
                    });
                    !cp_Loading &&
                      !cp_Data &&
                      createPlaylist({
                        variables: {
                          name: playlistName,
                          creator: auth.user.id,
                          project: projectID,
                          description: description,
                          status: "Private"
                        }
                      });
                  }
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Container>
      )}
    </AntModal>
  );
};

export default withRouter(CreateEditPlaylistModal);
