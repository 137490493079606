import styled from "styled-components";

export const Container = styled.div`
  width: 700px;
  height: 100%;
  position: relative;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontMedium};

  .close {
    width: 16px;
  }

  .close-button {
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 10;
    margin: 0;
    margin-left: 10px;
  }

  .ant-alert {
    margin-bottom: 8px;
  }

  #project-create > * {
    margin: 1em 0;
  }

  #project-create > p {
    font-size: 15px;
  }

  #save-modal-custom-mix-name-label {
    color: ${({ theme }) => theme.editor.textMedium};
    font-family: ${({ theme }) => theme.fontMedium};
    margin: 0.5em 0;
  }

  .input-container {
    margin-right: 14px;
    flex: 1;
  }

  input.ant-input {
    font-family: ${({ theme }) => theme.fontHeavy} !important;
  }

  .btn-subscribe {
    margin-top: 8px;
    background-color: ${({ theme }) => theme.subscribe};
  }
}`;

export const FlexRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
`;
