import * as React from "react";
import { theme } from "../globalStyles";

export const PauseCircle = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
      fill={theme.editor.highlight}
    />
    <path d="M18.7969 12H14V29H18.7969V12Z" fill={theme.editor.iconLight} />
    <path
      d="M28.4193 12H23.6224V29H28.4193V12Z"
      fill={theme.editor.iconLight}
    />
  </svg>
);
