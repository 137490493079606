import * as React from "react";
import {Container} from "./styles";

export const LoadingBar = ({isLoading, progress, title, width}) => {
  return <Container width={width} progress={progress} isLoading={isLoading}>
    {isLoading && <>
      <div className="progress-bar"/>
      <span className="title">{title}</span>
    </>}
  </Container>;
};
