import styled from "styled-components";
import { Select } from "antd";

export const ProjectSelect = styled(Select)`
  color: ${props => props.theme.textLight} !important;
  font-size: ${props => props.theme.p3b.fs};
  font-family: ${props => props.theme.p3b.ff};
  min-width: ${({ width }) => width || "250px"};
  height: 36px;
  ${props => props.styles && props.styles}

  .ant-select-arrow {
    > i > svg {
      background: url(${require("../../assets/select-dropdown.svg")}) no-repeat
        95% center !important;
      fill: ${({ theme }) => theme.textLight};
    }

    > i > svg > path {
      opacity: 0;
    }
  }

  .ant-select-selection__rendered {
    padding-right: 15px;
  }

  .ant-select-selection.ant-select-selection.ant-select-selection--single {
    background-color: ${({ isSubscription, theme }) =>
    isSubscription ? theme.textLight : theme.backgroundTag};
    color: ${({ isSubscription, theme }) =>
    isSubscription ? theme.subscribe : theme.textLight};
  }

  & .ant-select-selection-selected-value {
    margin-top: 1.5px;
  }

  ${props =>
    props.noTopMargin &&
    `
  .ant-select-selection--single {
    margin-top: 0;
  }
  `}
`;
