import React from "react";
import { Button } from "../Button";
import { BlanketLicenseContainer } from "./styles";
import { Link } from "react-router-dom";

const BlanketLicense = () => {
  return (
    <BlanketLicenseContainer>
      <h3>Blanket License</h3>
      <p>
        For ongoing projects like Podcasts, Video Channels or Social Media you
        can subscribe to our blanket license that covers unlimited tracks and
        gives full access to our library including variations, stem files, and
        our online editor.
      </p>
      <Link to={"/subscription"}>
        <Button className="blanket-license-button">Subscribe Now</Button>
      </Link>
    </BlanketLicenseContainer>
  );
};

export default BlanketLicense;
