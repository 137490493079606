import { useState, useCallback, useEffect, useContext } from "react";
import { PROJECTS_QUERY } from "../../pages/Private/Project/queries";
import { useApolloClient } from "react-apollo";
import { AuthContext } from "../../context/AuthProvider";

const useProjects = () => {
  const [projects, setProjects] = useState([]);
  const [projectLists, setProjectLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const client = useApolloClient();
  const { user, setUser } = useContext(AuthContext);

  const fetchProjects = useCallback(async () => {
    try {
      const res = await client.query({
        query: PROJECTS_QUERY,
        variables: { id: user.id },
        fetchPolicy: "network-only"
      });
      if (res) {
        const userProjects = [...res.data.userProjects];
        const sorted = res.data.userProjects.sort((a, b) => {
          if (a.team) return 1;
          if (!a.team) return -1;
          return 0;
        });
        const subscriptionSorter = userProjects.sort((a, b) => {
          if (a.downloadAccess || a.subscription) return -1;
          if (!a.downloadAccess || !a.subscription) return 1;
          return 0;
        });
        setProjects(sorted);
        setProjectLists(subscriptionSorter);
        setUser({ ...user, projects: res.data.userProjects });
        setLoading(false);
      }
    } catch (err) {
      console.log("projects ", err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, user.id]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  return { loading, projects, projectLists, fetchProjects, setLoading };
};

export default useProjects;
