import styled from "styled-components";

export const EditorButton = styled.button`
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.highlight};
  height: ${props => (props.height ? `${props.height}px` : "31px")};
  width: ${props => `${props.width}px` || "auto"};
  padding: ${props => (props.py ? `${props.py}px` : "6px")}
    ${props => (props.px ? `${props.px}px` : "12px")};

  color: ${props =>
    props.mutedText ? props.theme.grey2 : props.theme.textLight};
  font-size: ${props =>
    props.textButton ? props.theme.p2.fs : props.theme.p3b.fs};
  font-family: ${props => props.theme.p3b.ff};
  letter-spacing: 0px;
  line-height: 100%;
  border-radius: ${props => props.theme.borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;

  &&:disabled {
    cursor: not-allowed;
  }
`;
